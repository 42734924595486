import React, { useState, useEffect, useContext, useCallback } from 'react'
import { Layout, Alert, Divider, Switch, Tooltip, Modal, message } from 'antd'
import SimpleHeader from '../override/SimpleHeader'
import { H4, P } from '../override/Typography'
import Button from '../../components/override/Button'
import LegacyConfigurationModal from './LegacyConfigurationModal'
import { useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getUserData, getUserAttributeValue } from '../../lib/cognito'
import { s3Get } from '../../lib/awsSDK'
import AuthContext from '../../contexts/AuthContext'
import VaultContext from '../../contexts/VaultContext'
import { decryptFilePromise } from '../../lib/crypto'
import LegacyInfoSummary from './LegacyInfoSummary'
import api from '../../lib/api'
import { onError } from '../../lib/sentry'
import { ThemeContext } from 'styled-components'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTranslation, Trans } from 'react-i18next'

const { Content } = Layout

const LegacyManagement = ({ history }) => {
  const { user } = useContext(AuthContext)
  const { masterKey, isLegacyRequest, fetchLegacyRequest } =
    useContext(VaultContext)
  const theme = useContext(ThemeContext)
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
  const { t } = useTranslation()
  const externalUser = localStorage.getItem('External_User')

  const [visibleModal, setVisibleModal] = useState(false)
  const [legacyInfo, setLegacyInfo] = useState({})
  const [legacyManagementEnabled, setLegacyManagementEnabled] =
    useState(undefined)
  const [isRequesting, setIsRequesting] = useState(false)

  const { deputies } = useSelector(state => state.deputies)
  const { activeContacts } = useSelector(state => state.contacts)

  const otherPerson = activeContacts.find(
    ac => ac._id === legacyInfo.otherPersonId
  )

  const hasProfessionalDeputy = deputies.some(
    d => d.professionalDeputyId && d.publicKey
  )

  const hasPendingProfessionalDeputy = deputies.some(
    d => d.professionalDeputyId && !d.publicKey
  )

  const fetchLegacyInfo = useCallback(
    async masterKey => {
      try {
        const res = await s3Get(
          user.username,
          `legacy/details`,
          {},
          { responseType: 'blob' }
        )
        const data = await decryptFilePromise(res, masterKey)
        const fileContent = String.fromCharCode(...data)

        setLegacyInfo(JSON.parse(fileContent))
      } catch (error) {
        onError(error)
        message.error(t('FAILED_TO_GET_LEGACY_MANAGEMENT_INFO'))
      }
    },
    [user, t]
  )

  useEffect(() => {
    if (
      legacyManagementEnabled !== undefined &&
      legacyManagementEnabled !== null &&
      masterKey
    ) {
      fetchLegacyInfo(masterKey)
    }
  }, [fetchLegacyInfo, legacyManagementEnabled, masterKey])

  const fetchLegacyStatus = useCallback(async () => {
    try {
      const res = await api.getUser(user.username)
      setLegacyManagementEnabled(res.data.legacyManagementEnabled)
    } catch (err) {
      onError(err)
      message.error(t('FAILED_TO_GET_LEGACY_MANAGEMENT_STATUS'))
    }
  }, [user, t])

  useEffect(() => {
    fetchLegacyStatus()
  }, [fetchLegacyStatus])

  const updateLegacyManagementStatus = async () => {
    try {
      await api.updateLegacyManagementStatus(
        user.username,
        JSON.stringify({
          legacyManagementEnabled: !legacyManagementEnabled
        })
      )

      await fetchLegacyStatus()
    } catch (error) {
      onError(error)
      message.error(t('FAILED_TO_CHANGE_LEGACY_MANAGEMENT'))
    }
  }

  const handleLegacyManagementToggle = () => {
    Modal.confirm({
      title: legacyManagementEnabled
        ? t('DISABLE_THE_LEGACY_MANAGEMENT')
        : t('ENABLE_THE_LEGACY_MANAGEMENT'),
      content: t('ARE_YOU_SURE_YOU_WANT_TO_CONTINUTE'),
      okText: t('OK'),
      cancelText: t('CANCEL'),
      onOk: async () => await updateLegacyManagementStatus()
    })
  }

  const handleSendRequestToRelease = async fullname => {
    await api.sendRequestToRelease(user.username, JSON.stringify({ fullname }))
    await api.setLegacyRequest(
      JSON.stringify({ userId: user.username, isLegacyRequest: true })
    )
    await fetchLegacyRequest()
    setIsRequesting(false)
    message.success(t('SUCCESSFULLY_SENT_REQUEST_TO_RELEASE_YOUR_VAULTBOX'))
  }

  const sendRequestToRelease = () => {
    setIsRequesting(true)
    try {
      if (externalUser) {
        const userAttributes = JSON.parse(
          localStorage.getItem('UserAttributes')
        )
        const fullname =
          userAttributes.find(a => a.Name === 'custom:full_name')?.Value || ''

        handleSendRequestToRelease(fullname)
      } else {
        getUserData(user, async (err, userData) => {
          if (err) throw err.message
          const fullname = getUserAttributeValue(
            userData.UserAttributes,
            'custom:full_name'
          )
          handleSendRequestToRelease(fullname)
        })
      }
    } catch (error) {
      onError(error)
      message.error(t('FAILED_TO_SENT_REQUEST_TO_RELEASE_YOUR_VAULTBOX'))
    }
  }

  const handleRequestToRelease = () => {
    Modal.confirm({
      title: t('REQUEST_TO_RELEASE_YOUR_VAULTBOX'),
      content: t('REQUEST_TO_RELEASE_YOUR_VAULTBOX_SUMNARY'),
      okText: t('OK'),
      cancelText: t('CANCEL'),
      onOk: async () => sendRequestToRelease()
    })
  }

  return (
    <Layout
      style={{
        height: '100%',
        padding: '0 20px 20px',
        backgroundColor: '#fff'
      }}
    >
      <SimpleHeader
        title={<H4 display="inline-block">{t('LEGACY_MANAGEMENT')}</H4>}
      />
      <Content>
        <div className="page-description">
          <>
            <P style={{ flexGrow: 1 }}>
              <Trans i18nKey="LEGACY_MANAGEMENT_SUMMARY"></Trans>
            </P>
            {!Object.keys(legacyInfo)?.length && (
              <Button
                disabled={!hasProfessionalDeputy}
                onClick={() => setVisibleModal(true)}
                type="primary"
                className="page-description-action"
              >
                {t('START')}
              </Button>
            )}
          </>
        </div>
        <Divider />
        {!!Object.keys(legacyInfo)?.length && hasProfessionalDeputy && (
          <div className="legacy-info">
            <div>
              <H4>{t('SUMMARY')}</H4>
              <P style={{ marginBottom: '1em' }}>
                {t('HERE_IS_A_SUMMARY_OF_YOUR_CONFIGURATION')}
              </P>
              <LegacyInfoSummary
                permissions={legacyInfo.permissions}
                selectedFilesFolders={legacyInfo.selectedFilesFolders}
                accessedBy={legacyInfo.accessedBy}
                effectiveUpon={legacyInfo.effectiveUpon}
                otherPerson={otherPerson}
              />
            </div>

            <div className="legacy-actions">
              <div>
                <Tooltip
                  title={
                    legacyManagementEnabled
                      ? t('DISABLE_THE_LEGACY_MANAGEMENT')
                      : t('ENABLE_THE_LEGACY_MANAGEMENT')
                  }
                >
                  <Switch
                    checked={legacyManagementEnabled}
                    onChange={handleLegacyManagementToggle}
                  />
                </Tooltip>
                {!isMdUp && (
                  <span style={{ marginLeft: 10 }}>
                    {t('TOGGLE_THE_LEGACY_MANAGEMENT')}
                  </span>
                )}
              </div>

              <Button
                disabled={
                  !legacyInfo.effectiveUpon?.requestByUser ||
                  !legacyManagementEnabled ||
                  isLegacyRequest
                }
                loading={isRequesting}
                size={isMdUp ? 'large' : 'default'}
                type="primary"
                onClick={handleRequestToRelease}
              >
                {isLegacyRequest
                  ? t('REQUESTED_TO_RELEASE')
                  : t('REQUEST_TO_RELEASE_NOW')}
              </Button>
              <Button
                size={isMdUp ? 'large' : 'default'}
                type="primary"
                onClick={() => setVisibleModal(true)}
              >
                {t('UPDATE_CONFIGURATION')}
              </Button>
            </div>
          </div>
        )}
        {!hasProfessionalDeputy && (
          <Alert
            type="warning"
            description={
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap'
                }}
              >
                {hasPendingProfessionalDeputy ? (
                  <P style={{ flexGrow: 1 }}>
                    {t('YOU_ARE_PENDING_ACCEPTANCE_FROM_YOUR_DEPUITES')}
                  </P>
                ) : (
                  <>
                    <P style={{ flexGrow: 1, marginRight: 10 }}>
                      {t(
                        'YOU_NEED_TO_HAVE_A_PROFESSIONAL_DEPUTY_TO_USE_THIS_FEATURE'
                      )}
                    </P>
                    <Button
                      onClick={() => history.push('/deputy')}
                      type="primary"
                      size={isMdUp ? 'default' : 'small'}
                    >
                      {t('APPOINT_PROFESSIONAL_DEPUTY_NOW')}
                    </Button>
                  </>
                )}
              </div>
            }
            showIcon={isMdUp}
          />
        )}
      </Content>

      <LegacyConfigurationModal
        legacyInfo={legacyInfo}
        setLegacyInfo={setLegacyInfo}
        visible={visibleModal}
        setVisible={setVisibleModal}
        fetchLegacyStatus={fetchLegacyStatus}
        fetchLegacyInfo={fetchLegacyInfo}
      />
    </Layout>
  )
}

export default withRouter(LegacyManagement)
