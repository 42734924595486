import { Button, List, Modal, message, Spin } from 'antd'
import StyledButton from '../override/Button'
import React, { useContext, useEffect, useState } from 'react'
import { ThemeContext } from 'styled-components'
import VaultContext from '../../contexts/VaultContext'
import api from '../../lib/api'
import {
  assetSubtypes,
  liabilitySubtypes
} from '../assets-liabilities/AssetLiabilitySubtypes'
import { useTranslation } from 'react-i18next'
import { H3 } from '../override/Typography'
import PieChartColorPickerModal from './PieChartColorPickerModal'
import { onError } from '../../lib/sentry'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUser } from '../../features/user/userSlice'

const PieChartColorModal = props => {
  const { visible, setVisible } = props
  const theme = useContext(ThemeContext)
  const { userId } = useContext(VaultContext)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [listAssetSubTypeColors, setListAssetSubTypeColors] = useState('')
  const [listLiabilitySubtypeColor, setListLiabilitySubtypeColor] = useState('')
  const [subType, setSubType] = useState('')
  const [colorPickerModalVisible, setColorPickerModalVisible] = useState(false)

  const { assetSubTypeColors, liabilitySubtypeColor } = useSelector(
    state => state.user
  ).user

  useEffect(() => {
    if (userId && visible) {
      setIsLoading(true)

      try {
        if (assetSubTypeColors && liabilitySubtypeColor) {
          setListAssetSubTypeColors(assetSubTypeColors)
          setListLiabilitySubtypeColor(liabilitySubtypeColor)
        } else {
          const listAssetSubTypeColors = assetSubtypes
            .map(st => ({
              type: 'asset',
              name: st.name,
              color: theme[st.color]
            }))
            .sort((a, b) => a.name.localeCompare(b.name))

          const listLiabilitySubtypeColor = liabilitySubtypes
            .map(st => ({
              type: 'liability',
              name: st.name,
              color: theme[st.color]
            }))
            .sort((a, b) => a.name.localeCompare(b.name))

          setListAssetSubTypeColors(listAssetSubTypeColors)
          setListLiabilitySubtypeColor(listLiabilitySubtypeColor)
        }
      } catch (error) {
        onError(error)
      } finally {
        setIsLoading(false)
      }
    }
  }, [visible, theme, userId, assetSubTypeColors, liabilitySubtypeColor])

  const handleOk = async () => {
    setIsSaving(true)
    try {
      await api.updatePieChartColor(
        userId,
        JSON.stringify({
          assetSubTypeColors: listAssetSubTypeColors,
          liabilitySubtypeColor: listLiabilitySubtypeColor
        })
      )
      dispatch(fetchUser(userId))
      message.success(t('SUCCESSFULLY_SET_COLOR'))
      setVisible(false)
    } catch (error) {
      onError(error)
      message.error(t('FAILED_TO_SET_COLOR'))
    } finally {
      setIsSaving(false)
    }
  }

  const handleResetToDefault = async () => {
    try {
      const listAssetSubTypeColors = assetSubtypes
        .map(st => ({
          type: 'asset',
          name: st.name,
          color: theme[st.color]
        }))
        .sort((a, b) => a.name.localeCompare(b.name))

      const listLiabilitySubtypeColor = liabilitySubtypes
        .map(st => ({
          type: 'liability',
          name: st.name,
          color: theme[st.color]
        }))
        .sort((a, b) => a.name.localeCompare(b.name))

      setListAssetSubTypeColors(listAssetSubTypeColors)
      setListLiabilitySubtypeColor(listLiabilitySubtypeColor)
    } catch (error) {
      onError(error)
      message.error(t('FAILED_TO_RESET_COLOR'))
    }
  }

  const handleSetcolor = item => {
    setSubType(item)
    setColorPickerModalVisible(true)
  }

  return (
    <>
      <Modal
        title={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ marginTop: 5 }}>{t('COLORS_CUSTOMIZATION')}</div>
            <div>
              <StyledButton onClick={handleResetToDefault}>
                {t('RESET_TO_DEFAULT')}
              </StyledButton>
            </div>
          </div>
        }
        closable={false}
        visible={visible}
        footer={[
          <Button key="cancel" onClick={() => setVisible(false)}>
            {t('CANCEL')}
          </Button>,
          <Button
            key="save"
            type="primary"
            loading={isSaving}
            onClick={handleOk}
          >
            {t('SAVE')}
          </Button>
        ]}
      >
        <Spin spinning={isLoading}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'columm',
              justifyContent: 'space-around'
            }}
          >
            <List
              header={<H3>{t('ASSETS')}</H3>}
              dataSource={listAssetSubTypeColors}
              renderItem={item => (
                <List.Item className="chart-color-item">
                  <span>{item.name}</span>
                  <Button
                    onClick={() => handleSetcolor(item)}
                    type="primary"
                    style={{ background: item.color, borderColor: item.color }}
                  ></Button>
                </List.Item>
              )}
              locale={{ emptyText: t('NO_DOCUMENTS') }}
            />
            <List
              header={<H3>{t('LIABILITIES')}</H3>}
              dataSource={listLiabilitySubtypeColor}
              renderItem={item => (
                <List.Item className="chart-color-item">
                  <span>{item.name}</span>
                  <Button
                    onClick={() => handleSetcolor(item)}
                    type="primary"
                    style={{ background: item.color, borderColor: item.color }}
                  ></Button>
                </List.Item>
              )}
              locale={{ emptyText: t('NO_DOCUMENTS') }}
            />
          </div>
        </Spin>
      </Modal>
      <PieChartColorPickerModal
        subType={subType}
        visible={colorPickerModalVisible}
        setVisible={setColorPickerModalVisible}
        listAssetSubTypeColors={listAssetSubTypeColors}
        listLiabilitySubtypeColor={listLiabilitySubtypeColor}
        setListAssetSubTypeColors={setListAssetSubTypeColors}
        setListLiabilitySubtypeColor={setListLiabilitySubtypeColor}
      />
    </>
  )
}

export default PieChartColorModal
