import React, { useContext } from 'react'
import { Dropdown, Icon, Menu, Modal } from 'antd'
import Button from '../override/Button'
import logo from '../../assets/dark.png'
//import { StringResources } from '../../share/StringResources'
import { exportToExcel, exportToPDF } from '../../share/helpers'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { ThemeContext } from 'styled-components'
import { useTranslation } from 'react-i18next'

export default function ExportDropdown({
  fileName,
  columns,
  source,
  selectedColumns
}) {
  const { t } = useTranslation()
  const theme = useContext(ThemeContext)
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'))
  const handleExportToPDF = (selectedColumns, columns, source, fileName) => {
    if (selectedColumns?.length > 10) {
      Modal.confirm({
        title: t('MORE_THAN_TEN_COLUMNS_SELECTED'),
        content: (
          <>
            <p>{t('EXPORT_TO_PDF_WARNING')}</p>
            <div>{t('ARE_YOU_SURE_YOU_WANT_TO_CONTINUTE')}</div>
          </>
        ),
        onOk() {
          exportToPDF(columns, source, fileName, logo)
        }
      })
    } else {
      exportToPDF(columns, source, fileName, logo)
    }
  }

  return (
    <Dropdown
      trigger={['hover', 'click']}
      overlay={
        <Menu>
          <Menu.Item
            onClick={() => {
              exportToExcel(fileName, columns, source)
            }}
          >
            {t('TO_EXCEL')}
          </Menu.Item>
          <Menu.Item
            onClick={() =>
              handleExportToPDF(selectedColumns, columns, source, fileName)
            }
          >
            {t('TO_PDF')}
          </Menu.Item>
        </Menu>
      }
    >
      <Button size={isSmUp ? 'default' : 'small'}>
        {t('EXPORT')} <Icon type="down" />
      </Button>
    </Dropdown>
  )
}
