import PouchDB from 'pouchdb'
import { uniq } from 'lodash'
import { uploadEncryptedData, getRecords } from '../../lib/pouchDb'
import { onError } from '../../lib/sentry'

export const updatePasswordsOnDb = async (
  newLinks,
  currentLinks,
  dbName,
  userId,
  masterKey,
  item
) => {
  const addedList = newLinks.filter(
    itemId => !currentLinks || !currentLinks.includes(itemId)
  )
  const removedList = currentLinks.filter(itemId => !newLinks.includes(itemId))
  const updatedList = [...addedList, ...removedList]

  if (updatedList?.length) {
    const db = new PouchDB(`${userId}_${dbName}`)
    db.crypto(masterKey)
    const docs = await db.allDocs({
      keys: updatedList,
      include_docs: true
    })

    const updatedDocs = docs.rows
      .filter(row => row.doc)
      .map(row => {
        const { doc } = row

        if (addedList.includes(doc._id)) {
          const newPasswords = uniq([...(doc.passwords || []), item._id])

          return { ...doc, passwords: newPasswords }
        } else if (removedList.includes(doc._id)) {
          const newPasswords = doc.passwords
            ? doc.passwords.filter(passwordId => passwordId !== item._id)
            : []
          return { ...doc, passwords: newPasswords }
        } else {
          return { ...doc }
        }
      })

    await db.bulkDocs(updatedDocs)
    await uploadEncryptedData(db, userId, dbName)
  }
}

export const unlinkPasswordFromItems = async (
  userId,
  masterKey,
  password,
  fieldName,
  dbName
) => {
  try {
    const db = new PouchDB(`${userId}_${dbName}`)
    db.crypto(masterKey)
    const linkedList = password[fieldName]?.length
      ? (await getRecords(userId, dbName, masterKey)).filter(item =>
          password[fieldName].includes(item._id)
        )
      : []

    if (linkedList.length) {
      const updatedList = linkedList.map(item => {
        return {
          ...item,
          passwords: item.passwords.filter(
            passwordId => passwordId !== password._id
          )
        }
      })
      await db.bulkDocs(updatedList)
      await uploadEncryptedData(db, userId, dbName)
    }
  } catch (error) {
    onError(error)
  }
}

export const unlinkPasswordFromDbs = async (
  password,
  userId,
  masterKey
) => {
  await unlinkPasswordFromItems(
    userId,
    masterKey,
    password,
    'assetsLiabilities',
    'assetsLiabilities'
  )

  await unlinkPasswordFromItems(
    userId,
    masterKey,
    password,
    'assetsLiabilities',
    'pendingAssetsLiabilities'
  )

  await unlinkPasswordFromItems(userId, masterKey, password, 'documents', 'documents')

  await unlinkPasswordFromItems(
    userId,
    masterKey,
    password,
    'documents',
    'pendingDocuments'
  )

  await unlinkPasswordFromItems(userId, masterKey, password, 'events', 'events')

  await unlinkPasswordFromItems(
    userId,
    masterKey,
    password,
    'events',
    'pendingEvents'
  )
}

