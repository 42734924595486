import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  Fragment
} from 'react'
import { Spin, message } from 'antd'
import AuthContext from '../../contexts/AuthContext'
import { useDispatch, useSelector } from 'react-redux'
import { fetchCustomer } from '../../features/payment/customerSlice'
import Invoices from '../payment/Invoices'
import { useTranslation } from 'react-i18next'
import { getMemberDeputies } from '../../share/helpers'
import api from '../../lib/api'
import { fetchUser } from '../../features/user/userSlice'
import { onError } from '../../lib/sentry'
import { revokeDeputy } from './../../features/deputies/deputiesSlice'
import VaultContext from './../../contexts/VaultContext'
import usePlans from '../../hooks/usePlans'
import GroupInvitations from './GroupInvitations'
import Members from './Members'
import PaymentMethod from './PaymentMethod'
import PromotionCodeInfo from './PromotionCodeInfo'
import PaymentHeader from './PaymentHeader'
import GroupOwner from './GroupOwner'

export default function Payment() {
  const { user } = useContext(AuthContext)
  const { masterKey } = useContext(VaultContext)
  const { t } = useTranslation()

  const [isMainUser, setIsMainUser] = useState(false)
  const [professionalDeputies, setProfessionalDeputies] = useState()
  const [disabledAddMember, setDisabledAddMember] = useState(false)
  const [getInvoices, setGetInvoices] = useState(false)
  const [groupInvitations, setGroupInvitations] = useState([])
  const { isLoading, subscription, allowedMembers, membersCode, schedule } =
    useSelector(state => state.customer)

  const { deputies } = useSelector(state => state.deputies)

  const [isLeaving, setIsLeaving] = useState(false)

  const { mainUserId, members, fullName, memberCode } = useSelector(
    state => state.user
  ).user

  const plans = usePlans(null, true)
  const { defaultPlans, customPlans } = plans
  const vaultboxPlans = customPlans?.length ? customPlans : defaultPlans

  const discount = subscription?.discount
  const promotionInfo = discount?.coupon

  const dispatch = useDispatch()

  useEffect(() => {
    setIsMainUser(!mainUserId)
  }, [mainUserId])

  useEffect(() => {
    const getProfessionalDeputies = async () => {
      try {
        const rs = await api.getProfessionalDeputies()
        setProfessionalDeputies(rs.data)
      } catch (err) {
        onError(err)
      }
    }
    getProfessionalDeputies()
  }, [])

  //disable AddMember Button when main user change subscription to Vaultbox Pro
  useEffect(() => {
    if (schedule?.phases?.length) {
      const nextPlanId = schedule.phases[1].plans[0].plan
      const nextPlan = vaultboxPlans.find(plan => plan.id === nextPlanId)
      setDisabledAddMember(
        nextPlan && !nextPlan.metadata.members ? true : false
      )
    } else {
      setDisabledAddMember(false)
    }
  }, [schedule, vaultboxPlans])

  const fetchGroupInvitations = useCallback(() => {
    api
      .getUser(user.username)
      .then(res => {
        const { groupInvitations } = res.data
        if (groupInvitations?.length) {
          setGroupInvitations(groupInvitations)
        } else {
          setGroupInvitations([])
        }
      })
      .catch(err => onError(err))
  }, [user])

  useEffect(() => {
    fetchGroupInvitations()
  }, [fetchGroupInvitations])

  const removeMember = async (
    email,
    memberId?,
    mainUserId,
    professionalDeputyId
  ) => {
    setIsLeaving(true)
    try {
      const data = {
        email,
        memberId,
        membersCode: membersCode || memberCode // user has Group Plan in the next phase doesn't have membersCode
      }

      //revoke deputy
      let memberUserDeputies = []
      let pendingJoinGroup = false

      if (!members?.length) {
        memberUserDeputies = deputies
      } else {
        const res = await api.getUsersByEmails(email)
        if (res.data?.length) {
          memberUserDeputies = res.data[0].deputies
          pendingJoinGroup = res.data[0].pendingJoinGroup
        }
      }

      if (memberUserDeputies?.length && !pendingJoinGroup) {
        const allDeputies = await getMemberDeputies(
          memberUserDeputies,
          professionalDeputies,
          professionalDeputyId
        )

        if (allDeputies?.length) {
          await Promise.all(
            allDeputies.map(deputy =>
              dispatch(
                revokeDeputy(
                  memberId,
                  deputy,
                  fullName,
                  professionalDeputyId ? null : masterKey
                )
              )
            )
          )
        }
      }

      //remove or leave group
      await api.removeMember(mainUserId || user.username, JSON.stringify(data))

      dispatch(fetchUser(user.username))
      dispatch(fetchCustomer(user.username, true))

      message.success(
        t(
          mainUserId
            ? 'SUCCESSFULLY_LEFT_GROUP'
            : 'SUCCESSFULLY_REMOVED_MEMBERS'
        )
      )
      setIsLeaving(false)
    } catch (error) {
      onError(error)
      message.error(
        t(mainUserId ? 'FAILED_TO_LEAVE_GROUP' : 'FAILED_TO_REMOVE_MEMBER')
      )
      setIsLeaving(false)
    }
  }

  return (
    <Spin spinning={isLoading}>
      <PaymentHeader
        removeMember={removeMember}
        setGetInvoices={setGetInvoices}
        isLeaving={isLeaving}
      />

      {promotionInfo && !mainUserId && <PromotionCodeInfo />}

      {groupInvitations?.length > 0 && !members?.length && (
        <GroupInvitations
          mainUserId={mainUserId}
          groupInvitations={groupInvitations}
          fetchGroupInvitations={fetchGroupInvitations}
        />
      )}

      {mainUserId && <GroupOwner mainUserId={mainUserId} />}

      {!!allowedMembers && isMainUser && (
        <Members
          members={members}
          removeMember={removeMember}
          disabledAddMember={disabledAddMember}
          professionalDeputies={professionalDeputies}
        />
      )}

      {subscription?.id && (
        <Fragment>
          <PaymentMethod />

          <div style={{ marginTop: 25 }}>
            <Invoices
              getInvoices={getInvoices}
              setGetInvoices={setGetInvoices}
            />
          </div>
        </Fragment>
      )}
    </Spin>
  )
}
