import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef
} from 'react'
import {
  Layout,
  Menu,
  Icon,
  Avatar,
  Tooltip,
  Popconfirm,
  Alert,
  message,
  Modal,
  Badge,
  Popover,
  Divider,
  Tag
} from 'antd'
import { Link, useParams, useLocation } from 'react-router-dom'
import { getUserAttributeValue, getUserData } from '../../lib/cognito'
import { destroyAllDbs } from '../../lib/pouchDb'
import SpeedDials from '../common/SpeedDials'
import AuthContext from '../../contexts/AuthContext'
import logo from '../../assets/white.svg'
import logoBox from '../../assets/white-box.svg'
import { useSelector, useDispatch } from 'react-redux'
import { ThemeContext } from 'styled-components'
import Button from '../override/Button'
import { P } from '../override/Typography'
import { loadRecords, reloadRecords } from '../../lib/pouchDb'
import {
  fetchOtherAssetsLiabilities,
  fetchOtherPendingAssetsLiabilities,
  fetchOtherValuations
} from '../../features/assets-liabilities/otherAssetsLiabilitiesSlice'
import {
  fetchOtherContacts,
  fetchOtherPendingContacts
} from '../../features/contacts/otherContactsSlice'
import {
  fetchOtherDocuments,
  fetchOtherPendingDocuments
} from '../../features/documents/otherDocumentsSlice'
import { fetchUser } from '../../features/user/userSlice'
import {
  fetchBaseCurrency,
  fetchRates
} from '../../features/settings/settingsSlice'
import { PREDEFINED_FOLDERS } from '../../share/Constants'
import api, { clearApiInterceptor } from '../../lib/api'
import moment from 'moment'
import {
  setBreadcrumb,
  setShowStarredDocsOnly
} from '../../features/documents/documentsSlice'
import { updateCancelSchedule } from '../../features/payment/customerSlice'
//import { StringResources } from '../../share/StringResources'
import { useIdleTimer } from 'react-idle-timer'
import { downloadExcelImportTemplate } from '../assets-liabilities/assetLiabilityHelpers'
import { getUserSession, isValidSession } from '../../lib/cognito'
import VaultContext from '../../contexts/VaultContext'
import { TourContextProvider } from '../../contexts/TourContext'
import {
  getExternalUserAttributes,
  formatBytes,
  getPlanNickname
} from '../../share/helpers'
import Overlay from '../override/Overlay'
import { AES, enc } from 'crypto-js'
import { storeReset } from '../../rootReducer'
import { onError, setSentryUser } from '../../lib/sentry'
import { debounce } from 'lodash'
import SharesThresholdModal from '../deputy/SharesThresholdModal'
import { useTranslation, Trans } from 'react-i18next'
import { fetchAccessLevel } from './../../features/settings/settingsSlice'
import { fetchCustomer } from './../../features/payment/customerSlice'
import { ACCESS_LEVEL } from './../../share/Constants'
import { useSubscription, useMutation } from 'react-apollo-hooks'
import { onCreateByUserId } from '../../graphql/subscriptions'
import { deleteS3Change } from '../../graphql/mutations'
import DisconectUserRequestModal from './../modals/DisconectUserRequestModal'
import EmailVerificationModal from '../../components/settings/EmailVerificationModal'
import RegisterModal from '../common/RegisterModal'
import DownloadVaultboxModal from '../modals/DownloadVaultboxModal'
import { s3Get } from '../../lib/awsSDK'
import AddLogoModal from '../modals/AddLogoModal'
import SubscriptionModal from '../payment/SubscriptionModal'
import usePrevious from '../../hooks/usePrev'
import proLogo from '../../assets/ic/pro_symbol.png'
import CardModal from '../payment/CardModal'
import config from '../../config'

import { Elements, StripeProvider } from 'react-stripe-elements'
import {
  fetchOtherPasswords,
  fetchOtherPendingPasswords
} from '../../features/passwords/otherPasswordsSlice'
import { fetchOtherEvents, fetchOtherPendingEvents } from '../../features/events/otherEventsSlice'

const { SubMenu } = Menu
const { Content, Sider } = Layout

const menuItem = (menuItem, onMenuItemClick) =>
  !menuItem.hidden && (
    <Menu.Item
      key={menuItem.url}
      className={`tour-menu-${menuItem.key}`}
      onClick={onMenuItemClick}
    >
      <Link to={menuItem.url}>
        <Icon type={menuItem.icon} />
        <span>{menuItem.title}</span>
      </Link>
    </Menu.Item>
  )

const menu = (
  menuItems,
  withQuickAccess,
  location,
  history,
  dispatch,
  setTourModalVisible,
  isReadonly,
  isDownloading,
  handleDownloadVaultbox,
  onMenuItemClick
) => (
  <Menu
    className="tour-menu-step"
    selectedKeys={[(location && location.pathname) || '/']}
    defaultOpenKeys={['quick-access']}
    mode="inline"
  >
    {menuItems.map(item =>
      item.subMenu ? (
        <SubMenu
          className={`tour-menu-${item.key}`}
          key={item.url}
          title={
            <span>
              <Icon type={item.icon} />
              <span>{item.title}</span>
            </span>
          }
          onTitleClick={({ key, domEvent }) => {
            if (
              !domEvent.target?.classList.contains('ant-menu-submenu-arrow')
            ) {
              if (key.indexOf('/files') === 0) dispatch(setBreadcrumb(''))
              history.push(key)
            }
          }}
          onClick={e => {
            if (e.key.indexOf('/files') === 0) {
              dispatch(setBreadcrumb(''))
              dispatch(setShowStarredDocsOnly(e.key.includes(`/starred`)))
            }
          }}
        >
          {item.subMenu.map(sub => menuItem(sub, onMenuItemClick))}
        </SubMenu>
      ) : (
        menuItem(item, onMenuItemClick)
      )
    )}
    {withQuickAccess && (
      <SubMenu
        title={
          <span>
            <Icon type="double-right" />
            <span>
              <Trans i18nKey="QUICK_ACCESS"></Trans>
            </span>
          </span>
        }
        style={{ marginTop: 30 }}
        onClick={e => {
          if (e.key === 'download-import-template') {
            downloadExcelImportTemplate()
          } else if (e.key === 'vault-tour') {
            setTourModalVisible(true)
          }
        }}
        key="quick-access"
      >
        <Menu.Item key="download-import-template">
          <Icon type="download" />
          <span>
            <Trans i18nKey="IMPORT_TEMPLATE"></Trans>
          </span>
        </Menu.Item>
        <Menu.Item key="vault-tour">
          <Icon type="star" />
          <span>
            <Trans i18nKey="VAULTBOX_TOUR"></Trans>
          </span>
        </Menu.Item>
      </SubMenu>
    )}
    {isReadonly && (
      <Menu.Item
        style={{ marginTop: 30 }}
        key="download-vaultbox"
        onClick={handleDownloadVaultbox}
      >
        <Icon type={isDownloading ? 'loading' : 'download'} />
        <span>
          <Trans i18nKey="DOWNLOAD_VAULTBOX"></Trans>
        </span>
      </Menu.Item>
    )}
  </Menu>
)

const primaryMenuItems = (
  pendingAssetsLiabilities,
  pendingContacts,
  pendingDocuments,
  pendingPasswords,
  pendingEvents,
  isPending = false,
  isDeputyOnly
) => [
  {
    title: isPending ? (
      <Badge
        className="custom-badge-size"
        style={{
          marginRight: -15
        }}
        count={pendingAssetsLiabilities?.length || 0}
      >
        <Trans i18nKey="REGISTRY"></Trans>
      </Badge>
    ) : (
      <Trans i18nKey="REGISTRY"></Trans>
    ),
    icon: 'home',
    url: '/',
    key: 'registry'
  },
  {
    title: isPending ? (
      <Badge
        className="custom-badge-size"
        style={{ marginRight: -15 }}
        count={pendingDocuments?.length || 0}
      >
        <Trans i18nKey="FILES"></Trans>
      </Badge>
    ) : (
      <Trans i18nKey="FILES"></Trans>
    ),
    icon: 'file',
    url: `/files`,
    key: 'files',
    subMenu: [
      ...[
        {
          title: <Trans i18nKey="FAVOURITES"></Trans>,
          icon: 'star',
          url: `/files/starred`,
          key: 'starred'
        },
        {
          title: <Trans i18nKey="ALL_FILES"></Trans>,
          icon: 'folder',
          url: `/files`,
          key: 'allFiles'
        }
      ],
      ...(isPending
        ? [
            {
              title: isPending ? (
                <Badge
                  className="custom-badge-size"
                  style={{ marginRight: -15 }}
                  count={pendingDocuments?.length || 0}
                >
                  <Trans i18nKey="PENDING"></Trans>
                </Badge>
              ) : (
                <Trans i18nKey="PENDING"></Trans>
              ),
              icon: 'question-circle',
              url: `/files/pending`,
              key: 'pending'
            }
          ]
        : [])
    ]
  },
  {
    title: isPending ? (
      <Badge
        className="custom-badge-size"
        style={{ marginRight: -15 }}
        count={pendingContacts?.length || 0}
      >
        <Trans i18nKey="CONTACTS"></Trans>
      </Badge>
    ) : (
      <Trans i18nKey="CONTACTS"></Trans>
    ),
    icon: 'contacts',
    url: '/contacts',
    key: 'contacts'
  },
  {
    title: (
      <span>
        <Trans i18nKey="DEPUTIES"></Trans>
        {isDeputyOnly && (
          <Tag
            style={{
              marginLeft: 10,
              borderRadius: 10,
              fontSize: 10,
              height: 18,
              backgroundColor: '#6d6d6d',
              opacity: 0.9,
              border: 'none',
              color: '#fff'
            }}
          >
            <img src={proLogo} width={14} height={14} alt="pro logo" /> Pro
          </Tag>
        )}
      </span>
    ),
    icon: 'user',
    url: '/deputy',
    key: 'deputies'
  },
  {
    title: (
      <>
        <Trans i18nKey="LEGACY_MANAGEMENT"></Trans>
        {isDeputyOnly && (
          <Tag
            style={{
              marginLeft: 10,
              borderRadius: 10,
              fontSize: 10,
              height: 18,
              backgroundColor: '#6d6d6d',
              opacity: 0.9,
              border: 'none',
              color: '#fff'
            }}
          >
            <img src={proLogo} width={14} height={14} alt="pro logo" /> Pro
          </Tag>
        )}
      </>
    ),
    icon: 'solution',
    url: '/legacy-management'
  },
  {
    title: isPending ? (
      <Badge
        className="custom-badge-size"
        style={{
          marginRight: -15
        }}
        count={pendingPasswords?.length || 0}
      >
        <Trans i18nKey="PASSWORDS"></Trans>
      </Badge>
    ) : (
      <Trans i18nKey="PASSWORDS"></Trans>
    ),
    icon: 'lock',
    url: '/passwords',
    key: 'passwords'
  },
  {
    title: isPending ? (
      <Badge
        className="custom-badge-size"
        style={{
          marginRight: -15
        }}
        count={pendingEvents?.length || 0}
      >
        <Trans i18nKey="CALENDAR"></Trans>
      </Badge>
    ) : (
      <Trans i18nKey="CALENDAR"></Trans>
    ),
    icon: 'calendar',
    url: '/calendar',
    key: 'calendar'
  },
  {
    title: <Trans i18nKey="TRASH"></Trans>,
    icon: 'delete',
    url: '/trash',
    key: 'trash'
  }
]

const deputyOnlyMenuItems = [
  {
    title: <Trans i18nKey="DEPUTIES"></Trans>,
    icon: 'user',
    url: '/deputy'
  }
]

const otherMenuItems = (
  userId,
  pendingAssetsLiabilities,
  pendingContacts,
  pendingDocuments,
  pendingPasswords,
  pendingEvents,
  isShowPending = false
) => [
  {
    title: isShowPending ? (
      <Badge
        className="custom-badge-size"
        style={{ marginRight: -15 }}
        count={pendingAssetsLiabilities?.length || 0}
      >
        <Trans i18nKey="REGISTRY"></Trans>
      </Badge>
    ) : (
      <Trans i18nKey="REGISTRY"></Trans>
    ),
    icon: 'home',
    url: `/${userId}`
  },
  {
    title: isShowPending ? (
      <Badge
        className="custom-badge-size"
        style={{ marginRight: -15 }}
        count={pendingDocuments?.length || 0}
      >
        <Trans i18nKey="FILES"></Trans>
      </Badge>
    ) : (
      <Trans i18nKey="FILES"></Trans>
    ),
    icon: 'file',
    url: `/files/${userId}`,
    subMenu: [
      ...[
        {
          title: <Trans i18nKey="FAVOURITES"></Trans>,
          icon: 'star',
          url: `/files/${userId}/starred`
        },
        {
          title: <Trans i18nKey="ALL_FILES"></Trans>,
          icon: 'folder',
          url: `/files/${userId}`
        }
      ],
      ...(isShowPending
        ? [
            {
              title: isShowPending ? (
                <Badge
                  className="custom-badge-size"
                  style={{ marginRight: -15 }}
                  count={pendingDocuments?.length || 0}
                >
                  <Trans i18nKey="PENDING"></Trans>
                </Badge>
              ) : (
                <Trans i18nKey="PENDING"></Trans>
              ),
              icon: 'question-circle',
              url: `/files/${userId}/pending`,
              key: 'pending'
            },
            {
              title: <Trans i18nKey="REJECTED"></Trans>,
              icon: 'close-circle',
              url: `/files/${userId}/rejected`,
              key: 'rejected'
            }
          ]
        : [])
    ]
  },
  {
    title: isShowPending ? (
      <Badge
        className="custom-badge-size"
        style={{ marginRight: -15 }}
        count={pendingContacts?.length || 0}
      >
        <Trans i18nKey="CONTACTS"></Trans>
      </Badge>
    ) : (
      <Trans i18nKey="CONTACTS"></Trans>
    ),
    icon: 'contacts',
    url: `/contacts/${userId}`
  },
  {
    title: isShowPending ? (
      <Badge
        className="custom-badge-size"
        style={{
          marginRight: -15
        }}
        count={pendingPasswords?.length || 0}
      >
        <Trans i18nKey="PASSWORDS"></Trans>
      </Badge>
    ) : (
      <Trans i18nKey="PASSWORDS"></Trans>
    ),
    icon: 'lock',
    url: `/passwords/${userId}`
  },
  {
    title: isShowPending ? (
      <Badge
        className="custom-badge-size"
        style={{
          marginRight: -15
        }}
        count={pendingEvents?.length || 0}
      >
        <Trans i18nKey="CALENDAR"></Trans>
      </Badge>
    ) : (
      <Trans i18nKey="CALENDAR"></Trans>
    ),
    icon: 'calendar',
    url: `/calendar/${userId}`
  },
  {
    title: <Trans i18nKey="TRASH"></Trans>,
    icon: 'delete',
    url: `/trash/${userId}`
  }
]

function broadcastTimerStatus(isTimerActive) {
  localStorage.setItem('isTimerActive', isTimerActive)
  localStorage.removeItem('isTimerActive')
}

var timeoutModal

export default function AuthLayout({ children, isPrimaryRoute, ...rest }) {
  const { resetAppStates, location, history, initUserData } = rest
  const { user, isProfessionalDeputy, userStorage } = useContext(AuthContext)
  const theme = useContext(ThemeContext)
  const vaultBoxLocation = useLocation()
  const [fullName, setFullName] = useState('')
  const [registerModalVisible, setRegisterModalVisible] = useState(false)
  const [emailVerified, setEmailVerified] = useState('')
  const [primaryUserEmail, setPrimaryUserEmail] = useState('')
  const [primaryUserFullName, setPrimaryUserFullName] = useState('')
  const [isLegacyRequest, setIsLegacyRequest] = useState()
  const [isHidingDeputiesReminder, setIsHidingDeputiesReminder] =
    useState(false)
  const [isHideRemovedGroupReminder, setIsHideRemovedGroupReminder] =
    useState(false)
  const [siderCollapsed, setSiderCollapsed] = useState(false)
  const [subscriptionModalVisible, setSubscriptionModalVisible] = useState()
  const { t } = useTranslation()

  const { deputies } = useSelector(state => state.deputies)
  const { hideDeputiesSetupReminder, sharesThreshold, isRemovedGroupReminder } =
    useSelector(state => state.user).user
  const {
    customer,
    subscription,
    vaultboxSubscription,
    limit,
    allowedStorage
  } = useSelector(state => state.customer)

  const { isDeputyOnly, mainUserId } = useSelector(state => state.user).user

  const isFirstRender = useRef(true)
  const externalUser = localStorage.getItem('External_User')
  const { baseCurrency, accessLevel } = useSelector(state => state.settings)
  const [tourRun, setTourRun] = useState(false)
  const [tourModalVisible, setTourModalVisible] = useState(false)
  const [isDownloading, setIsDownloading] = useState(false)
  const [masterKey, setMasterKey] = useState('')
  const [isSmallScreen, setIsSmallScreen] = useState(false)
  const [cardModalVisible, setCardModalVisible] = useState(false)
  const [sharesThresholdModalVisible, setSharesThresholdModalVisible] =
    useState(false)

  const [disconectUserModalVisible, setDisconectUserModalVisible] =
    useState(false)
  const [downloadVaultboxModalVisible, setDownloadVaultboxModalVisible] =
    useState(false)
  const [limitedRecord, setLimitedRecord] = useState()
  const [privateFolderKey, setPrivateFolderKey] = useState('')
  const [planId, setPlanId] = useState('')
  const [appliedPromo, setAppliedPromo] = useState()
  const [isReleasePassword, setIsReleasePassword] = useState(false)
  const [imageUrl, setImageUrl] = useState('')
  const [file, setFile] = useState('')
  const [isEditAvatarMode, setIsEditAvatarMode] = useState(false)
  const [addLogoModalVisible, setAddLogoModalVisible] = useState(false)
  const [isLockPrivateFolder, setIsLockPrivateFolder] = useState(false)

  const dispatch = useDispatch()
  let { userId } = useParams()
  const isValidUserId = !!localStorage.getItem(userId)

  const { data } = useSubscription(onCreateByUserId, {
    variables: {
      userId: userId
    }
  })

  const [removeS3Change] = useMutation(deleteS3Change)

  // Context readonly access: deputy accessing primary files => the userId taken from params (primary) is different from id of current login user (deputy)
  const isReadonly = userId && user.username !== userId && isValidUserId
  // when userId is not specified in the params, need to get it from current login user instead
  userId = userId || user.username

  const { pendingAssetsLiabilities } = useSelector(state =>
    isReadonly ? state.otherAssetsLiabilities : state.assetsLiabilities
  )
  const { pendingContacts } = useSelector(state =>
    isReadonly ? state.otherContacts : state.contacts
  )
  const { pendingDocuments } = useSelector(state =>
    isReadonly ? state.otherDocuments : state.documents
  )
  const { activeContacts } = useSelector(state =>
    isReadonly ? state.otherContacts : state.contacts
  )
  const { activeAssetsLiabilities } = useSelector(state =>
    isReadonly ? state.otherAssetsLiabilities : state.assetsLiabilities
  )
  const { activeFiles } = useSelector(state =>
    isReadonly ? state.otherDocuments : state.documents
  )
  const { activePasswords, pendingPasswords } = useSelector(state =>
    isReadonly ? state.otherPasswords : state.passwords
  )
  const { activeEvents, pendingEvents } = useSelector(state =>
    isReadonly ? state.otherEvents : state.events
  )
  const { usedStorage } = useSelector(state => state.documents)

  const prevLimitedRecord = usePrevious(limitedRecord)
  const prevUsedStorage = usePrevious(usedStorage)

  const showReminder =
    deputies.length < 2 &&
    !hideDeputiesSetupReminder &&
    !localStorage.getItem('hideDeputiesSetupReminder')

  const acceptedDeputies = deputies.filter(d => d.publicKey) || []

  const hasDisconectPDRequest = acceptedDeputies.some(d => d.pendingDisconect)

  const handleDownloadVaultbox = () => {
    setDownloadVaultboxModalVisible(true)
  }

  const handleOnIdle = () => {
    user.signOut()
    destroyAllDbs()
    localStorage.clear()

    if (!timeoutModal) {
      timeoutModal = Modal.info({
        title: t('SESSION_TIMEOUT'),
        content: t('SESSION_TIMEOUT_SUMMARY'),
        keyboard: false,
        onOk() {
          Modal.destroyAll()
          window.location.reload()
        }
      })
    }
  }

  const handleComplateAddCard = useCallback(async () => {
    try {
      const createData = {
        userId: user.username,
        customerId: customer?.id,
        items: [{ plan: planId }],
        coupon: appliedPromo || undefined
      }
      await api.createSubscription(JSON.stringify(createData))
      dispatch(fetchCustomer(user.username))
    } catch (error) {
      onError(error)
    }
  }, [appliedPromo, customer, user, dispatch, planId])

  const handleOnAction = e => {
    broadcastTimerStatus(true)
  }

  const { reset } = useIdleTimer({
    timeout: 30 * 60 * 1000,
    onIdle: handleOnIdle,
    onAction: handleOnAction,
    debounce: 2000
  })

  const receiveTimerStatus = useCallback(
    event => {
      if (event.key !== 'isTimerActive') return
      if (event.newValue === 'true') {
        reset()
      }
    },
    [reset]
  )

  useEffect(() => {
    if (user && isReadonly) {
      api
        .getUser(user.username)
        .then(async res => {
          const { lockPrivateFolderTimeArr } = res.data
          const currentTime = new Date().getTime()
          const currentUser = lockPrivateFolderTimeArr?.find(
            item => item.userId === userId
          )
          if (
            currentUser &&
            (currentTime - currentUser.lockTime) / (1000 * 24 * 60 * 60) < 1
          ) {
            setIsLockPrivateFolder(true)
          } else {
            setIsLockPrivateFolder(false)
          }
        })
        .catch(err => {
          onError(err)
        })
    }
  }, [user, userId, isReadonly])

  useEffect(() => {
    if (
      customer?.id &&
      !subscription?.id &&
      !isFirstRender.current &&
      planId !== 'free' &&
      !isDeputyOnly
    ) {
      if (mainUserId) {
        handleComplateAddCard()
      } else {
        setCardModalVisible(true)
      }
    } else {
      isFirstRender.current = false
      setCardModalVisible(false)
    }
  }, [
    customer,
    subscription,
    planId,
    mainUserId,
    handleComplateAddCard,
    isDeputyOnly
  ])

  useEffect(() => {
    const limitedRecordTimeout = setTimeout(() => {
      if (
        isDeputyOnly &&
        !!limitedRecord &&
        (limitedRecord >= 18 || usedStorage >= 1800000000)
      ) {
        api
          .getUser(user.username)
          .then(async res => {
            const { isSendVaultboxLiteLimitedMail, email } = res.data
            if (!isSendVaultboxLiteLimitedMail) {
              await api.sendVaultboxLiteLimitedMail(
                user.username,
                JSON.stringify({
                  email: email,
                  limitedRecord: limitedRecord,
                  usedStorage: formatBytes(usedStorage)
                })
              )
            }
          })
          .catch(err => {
            onError(err)
          })
      }

      if (
        (isDeputyOnly &&
          !!limitedRecord &&
          limitedRecord < 18 &&
          prevLimitedRecord >= 18) ||
        (usedStorage < 1800000000 && prevUsedStorage >= 1800000000)
      ) {
        api
          .updateIsSendVaultboxLiteLimitedMail(user.username)
          .catch(err => onError(err))
      }
    }, 5000)

    return () => clearTimeout(limitedRecordTimeout)
  }, [
    isDeputyOnly,
    limitedRecord,
    prevLimitedRecord,
    prevUsedStorage,
    usedStorage,
    user
  ])

  useEffect(() => {
    if (!isReadonly) {
      setLimitedRecord(
        activeContacts.length +
          activeEvents.length +
          activeFiles.length +
          activePasswords.length +
          activeAssetsLiabilities.length
      )
    }
  }, [
    activeAssetsLiabilities,
    activeContacts,
    activeEvents,
    activeFiles,
    activePasswords,
    isReadonly
  ])

  //prevent deputy receive subscription of primary user after they access to user's vault
  useEffect(() => {
    if (!isReadonly && isDeputyOnly) {
      dispatch(fetchCustomer(user.username))
    }
  }, [dispatch, user, isReadonly, isDeputyOnly])

  useEffect(() => {
    if (vaultBoxLocation.pathname === '/deputy') {
      localStorage.removeItem('privateFolderPassword')
    }
  }, [isReadonly, vaultBoxLocation])

  useEffect(() => {
    if (usedStorage !== userStorage) {
      api.updateUsedStorage(
        userId,
        JSON.stringify({
          usedStorage: usedStorage
        })
      )
    }
  }, [usedStorage, userStorage, userId])

  useEffect(() => {
    setDisconectUserModalVisible(hasDisconectPDRequest)
  }, [hasDisconectPDRequest])

  useEffect(() => {
    const fetchPrivateFolderKey = async () => {
      if (!userId) {
        return
      }
      const res = await api.getPrivateFolderKey(userId)
      if (res.data?.privateFolderKey) {
        setPrivateFolderKey(res.data.privateFolderKey)
      } else {
        await api.setPrivateFolderKey(userId)
        const response = await api.getPrivateFolderKey(userId)
        if (response.data?.privateFolderKey) {
          setPrivateFolderKey(response.data.privateFolderKey)
        }
      }
    }

    fetchPrivateFolderKey()
  }, [userId])

  const getLogo = useCallback(async () => {
    try {
      const rs = await api.getAvatar(userId)
      if (rs.data?.avatar?.fileId && rs.data.avatar.sub) {
        const resBody = await s3Get(
          userId,
          `avatar/${rs.data.avatar.fileId}`,
          { sub: rs.data.avatar.sub },
          { responseType: 'blob' }
        )

        const file = new File([resBody], rs.data.avatar.name, {
          type: rs.data.avatar.type
        })
        setFile(file)
        setImageUrl(URL.createObjectURL(file))
      }
    } catch (err) {
      onError(err)
    }
  }, [userId])

  useEffect(() => {
    getLogo()
  }, [getLogo])

  useEffect(() => {
    setIsSmallScreen(window.innerWidth < 992)

    const debouncedSetIsSmallScreen = debounce(() => {
      setIsSmallScreen(window.innerWidth < 992)
    }, 300)

    window.addEventListener('resize', debouncedSetIsSmallScreen)
    return () => {
      window.removeEventListener('resize', debouncedSetIsSmallScreen)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('storage', receiveTimerStatus)
    return () => {
      window.removeEventListener('storage', receiveTimerStatus)
    }
  }, [receiveTimerStatus])

  const fetchUserData = useCallback(() => {
    if (!user || !user.getUserData) {
      return
    }

    getUserData(
      user,
      (err, data) => {
        if (err) {
          console.log(`${t('FAILE_TO_GET_USER_DATA')}: `, err)
          onError(err)
          return
        }
        const plan = getUserAttributeValue(
          data.UserAttributes,
          'custom:plan_id'
        )

        const appliedPromo = getUserAttributeValue(
          data.UserAttributes,
          'custom:applied_promo'
        )

        const userFullName = getUserAttributeValue(
          data.UserAttributes,
          'custom:full_name'
        )
        const email = getUserAttributeValue(data.UserAttributes, 'email')
        const email_verified = getUserAttributeValue(
          data.UserAttributes,
          'email_verified'
        )
        setAppliedPromo(appliedPromo)
        setPlanId(plan)
        setFullName(userFullName)
        setEmailVerified(email_verified)
        setSentryUser({ id: user.username, username: user.username, email })
      },
      { bypassCache: true }
    )
  }, [user, t])

  useEffect(() => {
    const onWindowFocus = () => {
      if (externalUser) {
        const tokenExpired = localStorage.getItem('token_expired')
        if (!!tokenExpired && new Date().getTime() > tokenExpired) {
          user.signOut()
          destroyAllDbs()
          localStorage.clear()
          if (!timeoutModal) {
            timeoutModal = Modal.info({
              title: t('SESSION_TIMEOUT'),
              content: t('SESSION_TIMEOUT_SUMMARY'),
              onOk() {
                Modal.destroyAll()
                window.location.reload()
              }
            })
          }
        }
      } else if (user) {
        getUserSession(user, (err, session) => {
          if (err || !isValidSession(session)) {
            user.signOut()
            destroyAllDbs()
            localStorage.clear()

            if (!timeoutModal) {
              timeoutModal = Modal.info({
                title: t('SESSION_TIMEOUT'),
                content: t('SESSION_TIMEOUT_SUMMARY'),
                onOk() {
                  Modal.destroyAll()
                  window.location.reload()
                }
              })
            }
          }
        })
      }
    }

    window.addEventListener('focus', onWindowFocus)
    return () => {
      window.removeEventListener('focus', onWindowFocus)
    }
  }, [t, user, externalUser])

  const fetchLegacyRequest = useCallback(async () => {
    if (!isReadonly && userId) {
      const response = await api.getUser(userId)
      const isLegacyRequest = response.data.isLegacyRequest
      setIsLegacyRequest(isLegacyRequest)
    }
  }, [userId, isReadonly])

  useEffect(() => {
    fetchLegacyRequest()
  }, [fetchLegacyRequest])

  // when user signout on 1 tab, other tabs also listen to the accessToken change in localStorage to signout as well
  const fetchExternalUserData = useCallback(() => {
    const userAttributes = getExternalUserAttributes()
    const fullname = userAttributes.full_name
    const email = userAttributes.email
    const plan = userAttributes.plan_id
    const appliedPromo = userAttributes.applied_promo
    setFullName(fullname)
    setPlanId(plan)
    setAppliedPromo(appliedPromo)
    setEmailVerified(true)
    setSentryUser({ id: user.username, username: user.username, email })
  }, [user])

  useEffect(() => {
    if (externalUser) {
      fetchExternalUserData()
    } else if (user) {
      fetchUserData()
    }
  }, [fetchUserData, fetchExternalUserData, externalUser, user])

  // when user signout on 1 tab, other tabs also listen to the accessToken change in localStorage to signout as well
  const handleInvalidTokenCallback = useCallback(() => {
    if (!user?.username) {
      return
    }

    const handleInvalidToken = e => {
      const accessTokenKey = `CognitoIdentityServiceProvider.${user.pool.getClientId()}.${
        user.username
      }.accessToken`

      if (e.key === accessTokenKey && e.oldValue && !e.newValue) {
        user.signOut()
        resetAppStates()
        dispatch(storeReset())
        destroyAllDbs()
        localStorage.clear()
        clearApiInterceptor()
      }
    }

    window.addEventListener('storage', handleInvalidToken)
    return () => {
      window.removeEventListener('storage', handleInvalidToken)
    }
  }, [user, resetAppStates, dispatch])

  useEffect(() => {
    if (!externalUser) {
      handleInvalidTokenCallback()
    }
  }, [handleInvalidTokenCallback, externalUser])

  const initOtherDbs = useCallback(
    async (userId, masterKey) => {
      try {
        await Promise.all([
          loadRecords('assetsLiabilities', userId, masterKey),
          loadRecords('contacts', userId, masterKey),
          loadRecords('documents', userId, masterKey, PREDEFINED_FOLDERS),
          loadRecords('pendingAssetsLiabilities', userId, masterKey),
          loadRecords('pendingContacts', userId, masterKey),
          loadRecords('assetsLiabilitiesValuations', userId, masterKey),
          loadRecords('pendingDocuments', userId, masterKey),
          loadRecords('assetsLiabilitiesHistory', userId, masterKey),
          loadRecords('passwords', userId, masterKey),
          loadRecords('pendingPasswords', userId, masterKey),
          loadRecords('events', userId, masterKey),
          loadRecords('pendingEvents', userId, masterKey),
        ])

        dispatch(fetchOtherAssetsLiabilities(userId, masterKey))
        dispatch(fetchOtherPendingAssetsLiabilities(userId, masterKey))
        dispatch(fetchOtherPendingContacts(userId, masterKey))
        dispatch(fetchOtherContacts(userId, masterKey))
        dispatch(fetchOtherDocuments(userId, masterKey))
        dispatch(fetchBaseCurrency(userId))
        dispatch(fetchAccessLevel(userId))
        dispatch(fetchRates(userId, isProfessionalDeputy))
        dispatch(fetchOtherValuations(userId, masterKey))
        dispatch(fetchOtherPendingDocuments(userId, masterKey))
        dispatch(fetchCustomer(userId))
        dispatch(fetchOtherPasswords(userId, masterKey))
        dispatch(fetchOtherPendingPasswords(userId, masterKey))
        dispatch(fetchOtherEvents(userId, masterKey))
        dispatch(fetchOtherPendingEvents(userId, masterKey))
      } catch (e) {
        onError(e)
      }
    },
    [dispatch, isProfessionalDeputy]
  )

  useEffect(() => {
    const showRegisterModal = setTimeout(() => {
      if (externalUser) {
        const userAttributes = JSON.parse(
          localStorage.getItem('UserAttributes')
        )
        const country = userAttributes.find(
          a => a.Name === 'custom:country'
        )?.Value
        if (!country) {
          setRegisterModalVisible(true)
        }
      }
    }, 1000)

    return () => clearTimeout(showRegisterModal)
  }, [externalUser])

  useEffect(() => {
    const fetchDataFunction = async (dbName, userId, masterKey) => {
      switch (dbName) {
        case 'assetsLiabilities':
          return dispatch(fetchOtherAssetsLiabilities(userId, masterKey))
        case 'assetsLiabilitiesValuations':
          return dispatch(fetchOtherValuations(userId, masterKey))
        case 'contacts':
          return dispatch(fetchOtherContacts(userId, masterKey))
        case 'documents':
          return dispatch(fetchOtherDocuments(userId, masterKey))
        case 'pendingAssetsLiabilities':
          return dispatch(fetchOtherPendingAssetsLiabilities(userId, masterKey))
        case 'pendingContacts':
          return dispatch(fetchOtherPendingContacts(userId, masterKey))
        case 'pendingDocuments':
          return dispatch(fetchOtherPendingDocuments(userId, masterKey))
        case 'passwords':
          return dispatch(fetchOtherPasswords(userId, masterKey))
        case 'pendingPasswords':
          return dispatch(fetchOtherPendingPasswords(userId, masterKey))
        case 'events':
          return dispatch(fetchOtherEvents(userId, masterKey))
        case 'pendingEvents':
          return dispatch(fetchOtherPendingEvents(userId, masterKey))
        default:
          return null
      }
    }

    if (isProfessionalDeputy !== undefined) {
      api
        .getPrimaryUsers(user.username)
        .then(response => {
          if (response.data && response.data.length) {
            const primaryUsers = response.data
            const currentPrimaryUser = primaryUsers.find(
              user => user.key === userId
            )
            if (currentPrimaryUser) {
              setPrimaryUserEmail(currentPrimaryUser.email)
              setPrimaryUserFullName(currentPrimaryUser.fullName)
              setIsLegacyRequest(currentPrimaryUser.isLegacyRequest)
              setIsReleasePassword(currentPrimaryUser.isReleasePassword)
            }
          }
        })
        .catch(err => {
          console.log(`${t('FAILE_TO_GET_PRIMARY_USERS')}: `, err)
          onError(err)
        })

      api
        .getUser(user.username)
        .then(async res => {
          const { extraKey } = res.data
          const encryptedKey = localStorage.getItem(userId)
          const masterKey = AES.decrypt(encryptedKey, extraKey).toString(
            enc.Latin1
          )
          setMasterKey(masterKey)
          const isReadonly =
            userId && user.username !== userId && !!localStorage.getItem(userId)
          if (isReadonly && masterKey) {
            await initOtherDbs(userId, masterKey)
          }

          if (data?.onCreateByUserId?.userId === userId && isReadonly) {
            const isNotReload = localStorage.getItem('NotReload')
            if (isNotReload === 'true') {
              localStorage.setItem('NotReload', false)
            } else {
              const dbsReload = data?.onCreateByUserId?.message
                .split(', ')
                // .filter(dbName => dbName !== 'events')

              await Promise.all(
                dbsReload.map(dbName =>
                  reloadRecords(dbName, userId, masterKey)
                )
              )
              await Promise.all(
                dbsReload.map(dbName =>
                  fetchDataFunction(dbName, userId, masterKey)
                )
              )

              if (data?.onCreateByUserId?.id) {
                await removeS3Change({
                  variables: { id: data?.onCreateByUserId?.id }
                })
              }
            }
          }
        })
        .catch(err => {
          onError(err)
        })
    }
  }, [
    user,
    userId,
    isProfessionalDeputy,
    initOtherDbs,
    t,
    data,
    dispatch,
    removeS3Change
  ])

  const handleSignOut = () => {
    if (user != null) {
      user.signOut()
    }
    resetAppStates()
    dispatch(storeReset())
    destroyAllDbs()
    localStorage.clear()
    clearApiInterceptor()
  }

  const disableAccessOfDeputy = (event, disableClick) => {
    if (isPrimaryRoute && disableClick) {
      event.target.addEventListener('click', function (e) {
        e.stopPropagation()
        e.preventDefault()
      })
    }
    return
  }

  const onMenuItemClick = () => {
    if (isSmallScreen) setSiderCollapsed(true)
  }

  return (
    <VaultContext.Provider
      value={{
        userId,
        isReadonly,
        masterKey,
        fullName: !isReadonly ? fullName : primaryUserFullName,
        limitedRecord,
        privateFolderKey,
        isLegacyRequest,
        fetchLegacyRequest,
        isReleasePassword,
        setIsReleasePassword,
        isLockPrivateFolder,
        setIsLockPrivateFolder
      }}
    >
      <TourContextProvider
        {...{
          history,
          tourModalVisible,
          setTourModalVisible,
          tourRun,
          setTourRun,
          setSiderCollapsed
        }}
      >
        <Layout
          style={{ minHeight: '100vh' }}
          className={`page-wrapper very-first-step ${
            siderCollapsed && isSmallScreen ? 'small-screen' : ''
          }`}
        >
          <Sider
            className="main-sider"
            style={{ paddingBottom: 80 }}
            collapsible={!isSmallScreen}
            breakpoint="xl"
            onCollapse={setSiderCollapsed}
            collapsed={siderCollapsed}
            collapsedWidth={isSmallScreen ? 0 : 80}
            width={240}
          >
            <div className="logo">
              <Link to="/">
                <img
                  src={siderCollapsed ? logoBox : logo}
                  alt="vaultbox logo"
                  style={{ height: 30 }}
                />
              </Link>
            </div>
            <div className="profile">
              {!siderCollapsed &&
                (imageUrl ? (
                  <Popover
                    placement="bottomLeft"
                    content={
                      <>
                        <Button
                          onClick={() => {
                            setAddLogoModalVisible(true)
                          }}
                          type="link"
                          size="small"
                        >
                          <Icon type="upload" /> {t('UPLOAD')}
                        </Button>
                        <Divider type="vertical" />
                        <Button
                          onClick={() => {
                            setAddLogoModalVisible(true)
                            setIsEditAvatarMode(true)
                          }}
                          type="link"
                          size="small"
                        >
                          <Icon type="edit" /> {t('EDIT')}
                        </Button>
                      </>
                    }
                    trigger="click"
                  >
                    <Avatar
                      style={{ background: '#fff', cursor: 'pointer' }}
                      src={imageUrl}
                      icon="user"
                      size={54}
                    />
                  </Popover>
                ) : (
                  <Avatar
                    onClick={() => setAddLogoModalVisible(true)}
                    style={{ cursor: 'pointer' }}
                    icon="user"
                    size={54}
                  />
                ))}
              <div>
                {siderCollapsed ? (
                  <div style={{ marginBottom: 15, textAlign: 'center' }}>
                    <Tooltip title={fullName}>
                      {imageUrl ? (
                        <Popover
                          // visible={popoverVisible}
                          placement="bottomLeft"
                          content={
                            <>
                              <Button
                                onClick={() => {
                                  setAddLogoModalVisible(true)
                                }}
                                type="link"
                                size="small"
                              >
                                <Icon type="upload" />
                                {t('UPLOAD')}
                              </Button>
                              <Divider type="vertical" />
                              <Button
                                onClick={() => {
                                  setAddLogoModalVisible(true)
                                  setIsEditAvatarMode(true)
                                }}
                                type="link"
                                size="small"
                              >
                                <Icon type="edit" /> {t('EDIT')}
                              </Button>
                            </>
                          }
                          trigger="click"
                        >
                          <Avatar
                            style={{ background: '#fff', cursor: 'pointer' }}
                            // onClick={() => setPopoverVisible(true)}
                            src={imageUrl}
                            icon="user"
                            // size={54}
                          />
                        </Popover>
                      ) : (
                        <Avatar
                          onClick={() => setAddLogoModalVisible(true)}
                          icon="user"
                          style={{ cursor: 'pointer' }}
                        />
                      )}
                    </Tooltip>
                  </div>
                ) : (
                  <h4>{fullName}</h4>
                )}
                <div className="profile-links">
                  <Tooltip title={t('SETTINGS')}>
                    <Icon
                      type="setting"
                      onClick={() => history.push('/settings')}
                    />
                  </Tooltip>
                  <Popconfirm
                    title={t('ARE_YOU_SURE_YOU_WANT_TO_SIGN_OUT')}
                    onConfirm={handleSignOut}
                    okText={t('YES')}
                    cancelText={t('CANCEL')}
                    placement="right"
                  >
                    <Tooltip title={t('SIGN_OUT')}>
                      <Icon type="logout" />
                    </Tooltip>
                  </Popconfirm>
                </div>
              </div>
            </div>
            {isReadonly && (
              <>
                <div style={{ paddingLeft: 10 }}>
                  <Tooltip
                    title={t('BACK_TO_MY_VAULTBOX')}
                    placement="topLeft"
                    arrowPointAtCenter
                  >
                    <Link
                      to={isProfessionalDeputy ? '/deputy' : '/'}
                      style={{ padding: '0 10px' }}
                    >
                      <Icon type="arrow-left" />
                    </Link>
                  </Tooltip>
                  {t('VIEWING_VAULTBOX_OF')}:
                </div>
                <div style={{ paddingLeft: 20, marginBottom: 30 }}>
                  {primaryUserEmail}
                </div>
              </>
            )}
            {menu(
              isReadonly
                ? otherMenuItems(
                    userId,
                    pendingAssetsLiabilities,
                    pendingContacts,
                    pendingDocuments,
                    pendingPasswords,
                    pendingEvents,
                    isProfessionalDeputy &&
                      accessLevel === ACCESS_LEVEL.NEED_APPROVAL
                  )
                : isProfessionalDeputy
                ? deputyOnlyMenuItems
                : primaryMenuItems(
                    pendingAssetsLiabilities,
                    pendingContacts,
                    pendingDocuments,
                    pendingPasswords,
                    pendingEvents,
                    accessLevel === ACCESS_LEVEL.NEED_APPROVAL,
                    isDeputyOnly && !isProfessionalDeputy
                  ),
              !isReadonly && !isProfessionalDeputy,
              location,
              history,
              dispatch,
              setTourModalVisible,
              isReadonly,
              isDownloading,
              handleDownloadVaultbox,
              onMenuItemClick
            )}
            {!isProfessionalDeputy && (
              <div style={{ marginLeft: 25, marginBottom: 25, fontSize: 11 }}>
                {isDeputyOnly && (
                  <div>
                    {t('RECORDS')}
                    {': '}
                    {limitedRecord}
                    {' / '}
                    {limit}
                  </div>
                )}
                <div>
                  {t('STORAGE')}
                  {': '}
                  {formatBytes(usedStorage)}
                  {' / '}
                  {formatBytes(allowedStorage)}
                </div>
              </div>
            )}

            <div className="sider-footer">
              {siderCollapsed ? (
                <Menu mode="inline" style={{ margin: '0 -20px' }}>
                  <SubMenu title={<Icon type="ellipsis" />}>
                    <Menu.Item>
                      <a
                        href="https://www.vaultbox.tech/about-us"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t('ABOUT_US')}
                      </a>
                    </Menu.Item>
                    <Menu.Item>
                      <a
                        href="http://support.vaultbox.tech/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t('HELP')}
                      </a>
                    </Menu.Item>
                    <Menu.Item>
                      <a
                        href="https://www.vaultbox.tech/privacy-policy"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t('PRIVACY')}
                      </a>
                    </Menu.Item>
                  </SubMenu>
                </Menu>
              ) : (
                <div className="sider-footer-links">
                  <span>
                    <a
                      href="https://www.vaultbox.tech/about-us"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('ABOUT_US')}
                    </a>
                  </span>
                  <span>&bull;</span>
                  <span>
                    <a
                      href="http://support.vaultbox.tech/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('HELP')}
                    </a>
                  </span>
                  <span>&bull;</span>
                  <span>
                    <a
                      href="https://www.vaultbox.tech/privacy-policy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('PRIVACY')}
                    </a>
                  </span>
                </div>
              )}
              <div>
                &copy;{new Date().getFullYear()} <b>vaultbox</b>
              </div>
            </div>
          </Sider>
          <Layout
            style={{
              backgroundColor: theme.white,
              overflow: `${!tourRun && 'hidden'}`,
              overflowX: isSmallScreen ? 'auto' : '',
              position: 'relative'
            }}
          >
            {/* TODO: create custom Alert component if it's reused more */}
            {isDeputyOnly && isPrimaryRoute && !tourRun && <Overlay />}
            <Content
              className="main-content"
              style={{
                pointerEvents:
                  isDeputyOnly && isPrimaryRoute && !tourRun && 'none',
                minWidth: 280
              }}
              onMouseDown={event =>
                disableAccessOfDeputy(
                  event,
                  isDeputyOnly && isPrimaryRoute && !tourRun
                )
              }
            >
              <div style={{ margin: '0 20px' }}>
                {showReminder && !isReadonly && !isDeputyOnly && (
                  <Alert
                    style={{
                      margin: '10px 0',
                      backgroundColor: theme.redMinus10
                    }}
                    description={
                      <div className="appoint-deputies-alert">
                        <P style={{ flexGrow: 1 }} color={theme.red}>
                          <Trans i18nKey="PLEASE_APPOINT_YOUR_DEPUTIES"></Trans>
                        </P>
                        <div className="appoint-deputies-alert-actions">
                          <Button
                            onClick={() => history.push('/deputy')}
                            type="primary"
                          >
                            {t('APPOINT_DEPUTIES_NOW')}
                          </Button>
                          <Button
                            onClick={e => {
                              localStorage.setItem(
                                'hideDeputiesSetupReminder',
                                true
                              )
                              dispatch(fetchUser(user.username))
                            }}
                          >
                            {t('REMIND_ME_LATER')}
                          </Button>
                          <Button
                            onClick={e => {
                              setIsHidingDeputiesReminder(true)
                              api
                                .hideDeputiesSetupReminder(user.username)
                                .then(res => dispatch(fetchUser(user.username)))
                                .catch(err => onError(err))
                                .finally(() => {
                                  setIsHidingDeputiesReminder(false)
                                })
                            }}
                            loading={isHidingDeputiesReminder}
                          >
                            {t('DO_NOT_REMIND_ME_AGAIN')}
                          </Button>
                        </div>
                      </div>
                    }
                    type="error"
                    showIcon
                    icon={
                      <Icon
                        type="exclamation-circle"
                        theme="filled"
                        style={{ fontSize: 20, top: 24 }}
                      />
                    }
                  />
                )}

                {isRemovedGroupReminder && (
                  <Alert
                    style={{
                      margin: '10px 0'
                    }}
                    description={
                      <div className="appoint-deputies-alert">
                        <P>
                          {t(
                            'You have been removed from group and your account has been downgraded to vaultbox Essential.'
                          )}
                        </P>
                        <div className="appoint-deputies-alert-actions">
                          <Button
                            onClick={() => setSubscriptionModalVisible(true)}
                            type="primary"
                          >
                            {t('UPGRADE')}
                          </Button>
                          <Button
                            onClick={e => {
                              setIsHideRemovedGroupReminder(true)
                              api
                                .hideRemovedGroupReminder(user.username)
                                .then(res => dispatch(fetchUser(user.username)))
                                .catch(err => onError(err))
                                .finally(() => {
                                  setIsHideRemovedGroupReminder(false)
                                })
                            }}
                            loading={isHideRemovedGroupReminder}
                          >
                            {t('DO_NOT_REMIND_ME_AGAIN')}
                          </Button>
                        </div>
                      </div>
                    }
                    type="info"
                    showIcon
                    icon={
                      <Icon
                        type="exclamation-circle"
                        theme="filled"
                        style={{ fontSize: 20 }}
                      />
                    }
                  />
                )}

                {!isReadonly && !isDeputyOnly && baseCurrency === undefined && (
                  <Alert
                    style={{
                      margin: '10px 0'
                    }}
                    description={
                      <P color={theme.red}>
                        {t('YOU_HAVENT_SET_BASE_CURRENCY')}{' '}
                        <Link to="/settings/currency">{t('HERE')}</Link>.
                      </P>
                    }
                    type="error"
                    showIcon
                    icon={
                      <Icon
                        type="exclamation-circle"
                        theme="filled"
                        style={{ fontSize: 20 }}
                      />
                    }
                  />
                )}
                {subscription.cancel_at && (
                  <Alert
                    style={{ margin: '10px 0' }}
                    type="warning"
                    closable
                    description={
                      <>
                        <Trans
                          i18nKey="SUBSCRIPTION_HAS_BEEN_CANCEL_AT"
                          values={{
                            nickname: getPlanNickname(
                              vaultboxSubscription?.plan?.nickname,
                              vaultboxSubscription?.plan?.metadata?.membersCode
                            ),
                            cancel_at: moment(
                              subscription.cancel_at * 1000
                            ).format('LL')
                          }}
                        ></Trans>
                        .{' '}
                        <Trans i18nKey="YOU_STILL_CAN_REACTIVATE_SUBSCRIPTION">
                          You still can{' '}
                          {
                            <Button
                              type="link"
                              style={{ padding: 0 }}
                              onClick={() =>
                                Modal.confirm({
                                  title: t('REACTIVATE_SUBSCRIPTION'),
                                  content: (
                                    <Trans
                                      i18nKey="REACTIVATE_SUBSCRIPTION_CONFIRM"
                                      values={{
                                        nickname: getPlanNickname(
                                          vaultboxSubscription?.plan?.nickname,
                                          vaultboxSubscription?.plan?.metadata
                                            .membersCode
                                        )
                                      }}
                                    ></Trans>
                                  ),
                                  async onOk(close) {
                                    try {
                                      await updateCancelSchedule(
                                        subscription?.id,
                                        user.username,
                                        dispatch
                                      )
                                      close()
                                      message.success(
                                        t('REACTIVATE_SUBSCRIPTION_SUCCESS')
                                      )
                                    } catch (err) {
                                      message.error(
                                        t('REACTIVATE_SUBSCRIPTION_FAIL')
                                      )
                                      onError(err)
                                    }
                                  },
                                  onCancel() {}
                                })
                              }
                            >
                              reactivate
                            </Button>
                          }{' '}
                          your subscription before it ends.
                        </Trans>
                      </>
                    }
                  />
                )}

                {subscription.status === 'past_due' &&
                  !customer.default_source && (
                    <Alert
                      style={{
                        margin: '10px 0',
                        backgroundColor: theme.redMinus10,
                        color: theme.red
                      }}
                      description={
                        <>
                          <div>
                            <Trans
                              i18nKey="THE_PAYMENT_BECAME_PAST_DUE"
                              values={{
                                billing_cycle_anchor: moment(
                                  subscription.billing_cycle_anchor * 1000
                                ).format('LL')
                              }}
                            ></Trans>
                          </div>
                          <div>
                            <Trans i18nKey="UPDATE_PAYMENT_INFORMATION">
                              Please update your{' '}
                              <Link to="/settings/payment">
                                payment information
                              </Link>{' '}
                              within one week, otherwise your current
                              subscription will be canceled and downgraded to a
                              free plan.
                            </Trans>
                          </div>
                        </>
                      }
                      type="error"
                      showIcon
                      icon={
                        <Icon
                          type="exclamation-circle"
                          theme="filled"
                          style={{ fontSize: 20, top: 24 }}
                        />
                      }
                    />
                  )}
                {/* {!isReadonly && isDeputyOnly && !isProfessionalDeputy && (
                    <Alert
                      style={{
                        margin: '10px 0'
                      }}
                      description={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <P style={{ flexGrow: 1 }}>
                            Free accounts can only act as Deputy for other{' '}
                            <b>vaultbox</b> users. Upgrade your subscription plan to
                            have use of the other features of <b>vaultbox</b>.
                          </P>
                          <Button
                            onClick={() => history.push('/settings/payment')}
                            style={{ marginLeft: 75 }}
                            type="primary"
                          >
                            Upgrade
                          </Button>
                        </div>
                      }
                      type="info"
                      showIcon
                    />
                  )} */}
                {sharesThreshold > 2 &&
                  sharesThreshold > acceptedDeputies.length && (
                    <Alert
                      style={{
                        margin: '10px 0'
                      }}
                      description={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <P style={{ flexGrow: 1 }}>
                            <Trans
                              i18nKey="YOU_NEED_TO_ADD_MORE_DEPUTIES"
                              values={{
                                sharesThreshold,
                                acceptedDeputies: acceptedDeputies.length
                              }}
                            ></Trans>
                          </P>
                          <Button
                            onClick={() => setSharesThresholdModalVisible(true)}
                            style={{ marginLeft: 75 }}
                            type="primary"
                          >
                            {t('UPDATE')}
                          </Button>
                        </div>
                      }
                      type="warning"
                      showIcon
                    />
                  )}
                {isDeputyOnly &&
                  !isRemovedGroupReminder &&
                  !isReadonly &&
                  (limitedRecord >= 18 || usedStorage >= 1800000000) && (
                    <Alert
                      style={{
                        margin: '10px 0'
                      }}
                      description={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <P style={{ flexGrow: 1 }}>
                            <Trans i18nKey="YOU_NEED_TO_UPGRADE_YOUR_PLAN"></Trans>
                          </P>
                          <Button
                            onClick={() => setSubscriptionModalVisible(true)}
                            style={{ marginLeft: 75 }}
                            type="primary"
                          >
                            {t('UPGRADE')}
                          </Button>
                        </div>
                      }
                      type="warning"
                      showIcon
                    />
                  )}
              </div>
              {children}
            </Content>
          </Layout>
          {!isReadonly && isProfessionalDeputy === false && (
            <SpeedDials disabled={tourRun} hidden={isSmallScreen} />
          )}
          <SharesThresholdModal
            visible={sharesThresholdModalVisible}
            setVisible={setSharesThresholdModalVisible}
            maxSharesThreshold={
              sharesThreshold > acceptedDeputies.length
                ? sharesThreshold
                : acceptedDeputies.length
            }
          />
          <DisconectUserRequestModal
            visible={disconectUserModalVisible}
            setVisible={setDisconectUserModalVisible}
          />
          <EmailVerificationModal
            visible={emailVerified === 'false'}
            setVisible={() => {}}
            getUserInfo={() => {}}
            onVerificationComplete={() => setEmailVerified('true')}
          />
          <DownloadVaultboxModal
            setIsDownloading={setIsDownloading}
            visible={downloadVaultboxModalVisible}
            setVisible={setDownloadVaultboxModalVisible}
          />
          <RegisterModal
            initUserData={initUserData}
            visible={registerModalVisible}
            setVisible={setRegisterModalVisible}
          />
          <AddLogoModal
            visible={addLogoModalVisible}
            setVisible={setAddLogoModalVisible}
            file={file}
            getLogo={getLogo}
            isEditMode={isEditAvatarMode}
            setIsEditMode={setIsEditAvatarMode}
          />
          <SubscriptionModal
            visible={subscriptionModalVisible}
            setVisible={setSubscriptionModalVisible}
          />
        </Layout>
        <StripeProvider apiKey={config.stripe.PUBLISHABLE_KEY}>
          <Elements>
            <CardModal
              visible={cardModalVisible}
              setVisible={setCardModalVisible}
              customerId={customer.id}
              handleOkComplete={handleComplateAddCard}
              isRequired={true}
            />
          </Elements>
        </StripeProvider>
      </TourContextProvider>
    </VaultContext.Provider>
  )
}
