import React, { useContext } from 'react'
import { Avatar, Empty, Spin } from 'antd'
import VaultContext from '../../contexts/VaultContext'
import { ThemeContext } from 'styled-components'
import SimpleList from '../override/SimpleList'
import SimpleListItem from '../override/SimpleListItem'
import Button from '../override/Button'
import { useTranslation } from 'react-i18next'

export default function ContactsList({
  isLoading,
  activeContacts,
  filteredContacts,
  isGroupView,
  setContactId,
  setIsGroupView,
  contactId,
  contactsGroupsByFirstChar,
  history
}) {
  const { isReadonly } = useContext(VaultContext)
  const theme = useContext(ThemeContext)
  const { t } = useTranslation()

  return (
    <div
      style={{
        maxHeight: 'calc(100vh - 140px)',
        overflowY: 'auto',
        position: 'relative',
        height: '100%',
        paddingRight: 20
      }}
    >
      <Spin spinning={isLoading}>
        {activeContacts.length ? (
          filteredContacts.length ? (
            <div style={{ padding: '0 10px' }}>
              {filteredContacts.length > 1 && (
                <SimpleListItem
                  isSelected={isGroupView}
                  handleClick={() => {
                    setContactId('')
                    setIsGroupView(true)
                  }}
                >
                  {filteredContacts.map(contact => (
                    <Avatar
                      size={36}
                      style={{
                        backgroundColor: contact.background,
                        fontSize: 14,
                        marginRight: 6,
                        minWidth: 36
                      }}
                      key={contact._id}
                    >
                      {contact.shortName}
                    </Avatar>
                  ))}
                </SimpleListItem>
              )}
              <SimpleList
                data={filteredContacts}
                itemKey="_id"
                icon={contact => (
                  <Avatar
                    size={36}
                    style={{
                      backgroundColor: contact.background,
                      fontSize: 14
                    }}
                  >
                    {contact.shortName}
                  </Avatar>
                )}
                mainInfo={contact => contact.name}
                subInfo={contact =>
                  contact.emails &&
                  !!contact.emails.length &&
                  contact.emails[0].value
                }
                selectedItem={contactId}
                setSelectedItem={setContactId}
              />
            </div>
          ) : (
            contactsGroupsByFirstChar.map(group => (
              <div key={group.key}>
                <div
                  style={{
                    backgroundColor: theme.dark4,
                    borderRadius: 3,
                    padding: '6px 12px',
                    color: theme.dark2
                  }}
                >
                  {group.key}
                </div>
                <div style={{ padding: 10 }}>
                  <SimpleList
                    data={group.dataByKey}
                    itemKey="_id"
                    icon={contact => (
                      <Avatar
                        size={36}
                        style={{
                          backgroundColor: contact.background,
                          fontSize: 14,
                          minWidth: 36
                        }}
                      >
                        {contact.shortName}
                      </Avatar>
                    )}
                    mainInfo={contact => contact.name}
                    subInfo={contact =>
                      contact.emails &&
                      !!contact.emails.length &&
                      contact.emails[0].value
                    }
                    selectedItem={contactId}
                    setSelectedItem={setContactId}
                  />
                </div>
              </div>
            ))
          )
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%'
            }}
          >
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('NO_CONTACTS')}
            >
              {!isReadonly && (
                <Button
                  icon="plus"
                  onClick={() => history.push('/contacts/add')}
                >
                  {t('CREATE_NEW')}
                </Button>
              )}
            </Empty>
          </div>
        )}
      </Spin>
    </div>
  )
}
