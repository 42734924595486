import React, { useState, useEffect, useContext, useCallback } from 'react'
import { Modal, Descriptions, Spin, Divider } from 'antd'
import { s3Get } from '../../lib/awsSDK'
import AuthContext from '../../contexts/AuthContext'
import VaultContext from '../../contexts/VaultContext'
import { getUserAttributeValue, getUserData } from '../../lib/cognito'
import { AES, enc } from 'crypto-js'
import NodeRSA from 'node-rsa'
import Button from '../override/Button'
import { saveAs } from 'file-saver'
import api from '../../lib/api'
import { decryptFile } from '../../lib/crypto'
import LegacyInfoSummary from '../legacy-management/LegacyInfoSummary'
import { H4 } from '../override/Typography'
import { ThemeContext } from 'styled-components'
import { onError } from '../../lib/sentry'
import { useTranslation } from 'react-i18next'
import { loadRecords } from '../../lib/pouchDb'
import { fetchOtherDocuments } from '../../features/documents/otherDocumentsSlice'
import { useDispatch } from 'react-redux'

const getPrivateKey = (userData, masterKey, externalUser) => {
  const privateKey = externalUser
    ? userData.find(a => a.Name === 'custom:private_key')?.Value || ''
    : getUserAttributeValue(userData.UserAttributes, 'custom:private_key')
  const decryptedPrivateKey = AES.decrypt(privateKey, masterKey).toString(
    enc.Latin1
  )

  const key = new NodeRSA()
  key.importKey(decryptedPrivateKey, 'pkcs8')
  return key
}

function UserDetails({
  userDetails,
  downloadingIdPassport,
  downloadingAddressProof,
  showHeader,
  header,
  handleDownloadIdPassport,
  handleDownloadAddressProof
}) {
  const { t } = useTranslation()
  return (
    <div>
      {showHeader && <H4 style={{ marginBottom: 16 }}>{header}</H4>}
      <Descriptions column={1}>
        <Descriptions.Item label={t('FULLNAME')}>
          {userDetails.fullname}
        </Descriptions.Item>
        <Descriptions.Item label={t('CONTACT_NUMBER')}>
          {userDetails.contactNumberPrefix} {userDetails.contactNumber}
        </Descriptions.Item>
        <Descriptions.Item label={t('RESIDENTIAL_ADDRESS')}>
          {userDetails.address}
        </Descriptions.Item>
        <Descriptions.Item label={t('NATIONALITY')}>
          {userDetails.nationality}
        </Descriptions.Item>
        <Descriptions.Item label={t('ID_PASSPORT_NUMBER')}>
          {userDetails.idPassportNumber}
        </Descriptions.Item>
        <Descriptions.Item label={t('ID_PASSPORT')}>
          {userDetails.idPassport}
          <Button
            icon="download"
            type="link"
            onClick={handleDownloadIdPassport}
            loading={downloadingIdPassport}
          />
        </Descriptions.Item>
        <Descriptions.Item label={t('ADDRESS_PROOF')}>
          {userDetails.addressProof}
          {userDetails.addressProof && (
            <Button
              icon="download"
              type="link"
              onClick={handleDownloadAddressProof}
              loading={downloadingAddressProof}
            />
          )}
        </Descriptions.Item>
        {userDetails.idContainsAddress && (
          <Descriptions.Item>
            {t('ID_CONTAINS_RESIDENTIAL_ADDRESS')}
          </Descriptions.Item>
        )}
      </Descriptions>
    </div>
  )
}

export default function PrimaryUserDetailsModal({
  visible,
  setVisible,
  primaryUserId,
  showLegacyManagement,
  isPendingDetails,
  isPendingUpdateDetails,
  legacyManagementEnabled,
  handleDeputyRequest,
  handleUpdateUserDetailsRequest,
  selectedPrimaryUser
}) {
  const { user } = useContext(AuthContext)
  const { masterKey } = useContext(VaultContext)
  const theme = useContext(ThemeContext)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const externalUser = localStorage.getItem('External_User')
  const [userDetails, setUserDetails] = useState({})
  const [pendingUserDetails, setPendingUserDetails] = useState({})
  const [userLegacy, setUserLegacy] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [downloadingIdPassport, setDownloadingIdPassport] = useState(false)
  const [downloadingAddressProof, setDownloadingAddressProof] = useState(false)
  const [downloadingPendingIdPassport, setDownloadingPendingIdPassport] =
    useState(false)
  const [downloadingPendingAddressProof, setDownloadingPendingAddressProof] =
    useState(false)
  const [downloadingInstuction, setDownloadingInstruction] = useState(false)
  const initOtherDb = useCallback(
    async (userId, masterKey) => {
      try {
        await loadRecords('documents', userId, masterKey)
        dispatch(fetchOtherDocuments(userId, masterKey))
      } catch (e) {
        onError(e)
      }
    },
    [dispatch]
  )

  useEffect(() => {
    const fetchUserDetails = async (isPendingUserDetails = false) => {
      setIsLoading(true)
      try {
        const res = await s3Get(
          user.username,
          `identification/${primaryUserId}/${
            isPendingUserDetails ? 'pendingDetails' : 'details'
          }`
        )

        const onFetchUserDetails = (userData, externalUser = false) => {
          const key = getPrivateKey(userData, masterKey, externalUser)
          const detailsContent = Buffer.from(res).toString('ascii')
          const decryptedDetails = key.decrypt(detailsContent, 'utf-8')
          const userDetails = JSON.parse(decryptedDetails)
          isPendingUserDetails
            ? setPendingUserDetails(userDetails)
            : setUserDetails(userDetails)
        }

        if (externalUser) {
          const userAttributes = JSON.parse(
            localStorage.getItem('UserAttributes')
          )
          onFetchUserDetails(userAttributes, true)
        } else {
          getUserData(user, async (err, userData) => {
            if (err) {
              onError(err)
              return
            }
            onFetchUserDetails(userData)
          })
        }
      } catch (err) {
        onError(err)
      } finally {
        setIsLoading(false)
      }
    }

    const fetchUserLegacy = async () => {
      setIsLoading(true)
      try {
        const res = await s3Get(
          user.username,
          `legacy/${primaryUserId}/details`
        )

        const onFetchUserLegacy = (userData, externalUser = false) => {
          const key = getPrivateKey(userData, masterKey, externalUser)
          const detailsContent = Buffer.from(res).toString('ascii')
          const decryptedDetails = key.decrypt(detailsContent, 'utf-8')
          const userLegacy = JSON.parse(decryptedDetails)
          setUserLegacy(userLegacy)
        }
        if (externalUser) {
          const userAttributes = JSON.parse(
            localStorage.getItem('UserAttributes')
          )
          onFetchUserLegacy(userAttributes, true)
        } else {
          getUserData(user, async (err, userData) => {
            if (err) {
              onError(err)
              return
            }
            onFetchUserLegacy(userData)
          })
        }
      } catch (err) {
        onError(err)
      } finally {
        setIsLoading(false)
      }
    }

    if (primaryUserId && visible && masterKey) {
      if (isPendingUpdateDetails) {
        // fetching both current details & pending update details to compare
        fetchUserDetails(true)
      }
      fetchUserDetails()

      if (legacyManagementEnabled) {
        fetchUserLegacy()
      }
    }
  }, [
    user,
    primaryUserId,
    visible,
    selectedPrimaryUser.unlockedShares,
    isPendingUpdateDetails,
    legacyManagementEnabled,
    masterKey,
    initOtherDb,
    externalUser
  ])

  const handleDownloadIdPassport = async (isPendingUserDetails = false) => {
    isPendingUserDetails
      ? setDownloadingPendingIdPassport(true)
      : setDownloadingIdPassport(true)

    const onDownloadIdPassport = async (userData, external = false) => {
      const key = getPrivateKey(userData, masterKey, externalUser)
      const fileId = `identification/${primaryUserId}/${
        isPendingUserDetails ? 'pendingIdPassport' : 'idPassport'
      }`
      const fileKeyData = {
        fileId,
        deputyId: user.username
      }
      const fileKeyRes = await api.getFileKey(
        primaryUserId,
        JSON.stringify(fileKeyData)
      )

      const encryptedFileKey = fileKeyRes.data.key
      const fileKey = key.decrypt(encryptedFileKey, 'ascii')

      const idPassportRes = await s3Get(user.username, fileId, null, {
        responseType: 'blob'
      })

      decryptFile(idPassportRes, fileKey, uint8Array => {
        const blob = new Blob([uint8Array])
        saveAs(
          blob,
          isPendingUserDetails
            ? pendingUserDetails.idPassport
            : userDetails.idPassport
        )
        isPendingUserDetails
          ? setDownloadingPendingIdPassport(false)
          : setDownloadingIdPassport(false)
      })
    }

    try {
      if (externalUser) {
        const userAttributes = JSON.parse(
          localStorage.getItem('UserAttributes')
        )
        onDownloadIdPassport(userAttributes, true)
      } else {
        getUserData(user, async (err, userData) => {
          if (err) {
            onError(err)
            setDownloadingIdPassport(false)
            return
          }
          onDownloadIdPassport(userData)
        })
      }
    } catch (err) {
      setDownloadingIdPassport(false)
      onError(err)
    } finally {
    }
  }

  const handleDownloadAddressProof = async (isPendingUserDetails = false) => {
    isPendingUserDetails
      ? setDownloadingPendingAddressProof(true)
      : setDownloadingAddressProof(true)

    const onDownloadAddressProof = async (userData, externalUser = false) => {
      const key = getPrivateKey(userData, masterKey, externalUser)
      const fileId = `identification/${primaryUserId}/${
        isPendingUserDetails ? 'pendingAddressProof' : 'addressProof'
      }`
      const fileKeyData = {
        fileId,
        deputyId: user.username
      }
      const fileKeyRes = await api.getFileKey(
        primaryUserId,
        JSON.stringify(fileKeyData)
      )

      const encryptedFileKey = fileKeyRes.data.key
      const fileKey = key.decrypt(encryptedFileKey, 'ascii')

      const addressProofRes = await s3Get(user.username, fileId, null, {
        responseType: 'blob'
      })

      decryptFile(addressProofRes, fileKey, uint8Array => {
        const blob = new Blob([uint8Array])
        saveAs(
          blob,
          isPendingUserDetails
            ? pendingUserDetails.addressProof
            : userDetails.addressProof
        )
        isPendingUserDetails
          ? setDownloadingPendingAddressProof(false)
          : setDownloadingAddressProof(false)
      })
    }

    try {
      if (externalUser) {
        const userAttributes = JSON.parse(
          localStorage.getItem('UserAttributes')
        )
        onDownloadAddressProof(userAttributes, true)
      } else {
        getUserData(user, async (err, userData) => {
          if (err) {
            onError(err)
            setDownloadingAddressProof(false)
            return
          }
          onDownloadAddressProof(userData)
        })
      }
    } catch (err) {
      setDownloadingAddressProof(false)
      onError(err)
    } finally {
    }
  }

  const handleDownloadInstruction = async () => {
    setDownloadingInstruction(true)

    const onDownloadInstruction = async (userData, externalUser = false) => {
      const key = getPrivateKey(userData, masterKey, externalUser)
      const fileKeyData = {
        fileId: `legacy/${primaryUserId}/instruction`,
        deputyId: user.username
      }
      const fileKeyRes = await api.getFileKey(
        primaryUserId,
        JSON.stringify(fileKeyData)
      )
      if (fileKeyRes.data.message) throw Error(fileKeyRes.data.message)

      const encryptedFileKey = fileKeyRes.data.key
      const fileKey = key.decrypt(encryptedFileKey, 'ascii')

      const instructionRes = await s3Get(
        user.username,
        `legacy/${primaryUserId}/instruction`,
        null,
        { responseType: 'blob' }
      )

      decryptFile(instructionRes, fileKey, uint8Array => {
        const blob = new Blob([uint8Array])
        saveAs(blob, userLegacy.instructionFileName)
        setDownloadingInstruction(false)
      })
    }
    try {
      if (externalUser) {
        const userAttributes = JSON.parse(
          localStorage.getItem('UserAttributes')
        )
        onDownloadInstruction(userAttributes, true)
      } else {
        getUserData(user, async (err, userData) => {
          if (err) {
            onError(err)
            setDownloadingInstruction(false)
            return
          }
          onDownloadInstruction(userData)
        })
      }
    } catch (err) {
      setDownloadingInstruction(false)
      onError(err)
    } finally {
    }
  }

  return (
    <>
      <Modal
        title={t('PRIMARY_USER_DETAILS')}
        visible={visible}
        onCancel={() => {
          setVisible(false)
          setUserLegacy({})
        }}
        footer={
          isPendingDetails ? (
            <>
              <Button
                style={{ color: theme.red }}
                onClick={() => {
                  handleDeputyRequest(false, primaryUserId)
                  setVisible(false)
                }}
              >
                {t('DECLINE')}
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  handleDeputyRequest(true, primaryUserId)
                  setVisible(false)
                }}
              >
                {t('ACCEPT')}
              </Button>
            </>
          ) : isPendingUpdateDetails ? (
            <>
              <Button
                style={{ color: theme.red }}
                onClick={() => {
                  handleUpdateUserDetailsRequest(false, pendingUserDetails)
                  setVisible(false)
                }}
              >
                {t('REJECT')}
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  handleUpdateUserDetailsRequest(true, pendingUserDetails)
                  setVisible(false)
                }}
              >
                {t('APPROVE')}
              </Button>
            </>
          ) : null
        }
        width={750}
      >
        <Spin spinning={isLoading}>
          <div style={{ display: 'flex' }}>
            <UserDetails
              userDetails={userDetails}
              downloadingIdPassport={downloadingIdPassport}
              downloadingAddressProof={downloadingAddressProof}
              showHeader={isPendingUpdateDetails}
              header={t('CURRENT_DETAILS')}
              handleDownloadIdPassport={() => handleDownloadIdPassport()}
              handleDownloadAddressProof={() => handleDownloadAddressProof()}
            />
            {isPendingUpdateDetails && (
              <UserDetails
                userDetails={pendingUserDetails}
                downloadingIdPassport={downloadingPendingIdPassport}
                downloadingAddressProof={downloadingPendingAddressProof}
                showHeader={isPendingUpdateDetails}
                header={t('PENDING_UPDATE_DETAILS')}
                handleDownloadIdPassport={() => handleDownloadIdPassport(true)}
                handleDownloadAddressProof={() =>
                  handleDownloadAddressProof(true)
                }
              />
            )}
          </div>
          {showLegacyManagement && !!Object.keys(userLegacy).length && (
            <>
              <Divider />
              <H4>{t('LEGACY_MANAGEMENT')}</H4>
              <LegacyInfoSummary
                permissions={userLegacy.permissions}
                selectedFilesFolders={userLegacy.selectedFilesFolders}
                accessedBy={userLegacy.accessedBy}
                effectiveUpon={userLegacy.effectiveUpon}
                otherPerson={userLegacy.otherPerson}
                isDeputyView={true}
                selectedPrimaryUser={selectedPrimaryUser}
              />
              {userLegacy.instructionFileName && (
                <>
                  <span>
                    {t('INSTRUCTION')}: <b>{userLegacy.instructionFileName}</b>
                  </span>
                  <Button
                    icon="download"
                    type="link"
                    onClick={handleDownloadInstruction}
                    loading={downloadingInstuction}
                  />
                </>
              )}
            </>
          )}
        </Spin>
      </Modal>
    </>
  )
}
