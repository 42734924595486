import React from 'react'
import { Result } from 'antd'
import i18next from 'i18next'

export default function ErrorFallback({ error, componentStack, resetError }) {
  return (
    <div style={{ paddingTop: 100 }}>
      <Result status="error" title={i18next.t('AN_ERROR_HAS_OCCURRED')} />
    </div>
  )
}
