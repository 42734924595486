import Liability, { liabilityColumns } from './LiabilityModel'
import { referenceColumns } from './AssetLiabilityModel'
import { LIABILITY_TYPES, IMPORT_COL_TYPES } from '../share/Constants'
import i18next from 'i18next'

class CreditCard extends Liability {
  /**
   * Construct a new CreditCard instance
   * @param {object} params - CreditCard creation options:
   * @param {string} [params.interestRate]
   * @param {string} [params.expiryDate]
   * @param {string} [params.paymentDueDate]
   * @param {string} [params.issuingBank]
   * @param {number} [params.creditLimit]
   * @param {number} [params.recurringPaymentDueDay]
   *
   */
  constructor({
    _id,
    _rev,
    _deleted,
    title,
    description,
    descriptionWithMarkup,
    percentageOwnership,
    startDate,
    interestRate,
    expiryDate,
    paymentDueDate,
    maturityDate,
    currency,
    valuationDate,
    valuationDateInBaseCurrency,
    outstandingValueInLiabilityCurrency,
    outstandingValueInBaseCurrency,
    issuingBank,
    creditLimit,
    recurringPaymentDueDay,
    contacts,
    references,
    links,
    documents,
    includeValueInNetWorth,
    status,
    events,
    passwords,
    tags
  }) {
    super({
      _id,
      _rev,
      _deleted,
      title,
      description,
      descriptionWithMarkup,
      percentageOwnership,
      startDate,
      maturityDate,
      currency,
      valuationDate,
      valuationDateInBaseCurrency,
      outstandingValueInLiabilityCurrency,
      outstandingValueInBaseCurrency,
      contacts,
      references,
      links,
      documents,
      includeValueInNetWorth,
      status,
      events,
      passwords,
      tags
    })

    this.subType = LIABILITY_TYPES.CREDIT_CARD
    this.interestRate = interestRate
    this.expiryDate = expiryDate
    this.paymentDueDate = paymentDueDate
    this.issuingBank = issuingBank
    this.creditLimit = creditLimit
    this.recurringPaymentDueDay = recurringPaymentDueDay
  }
}

export default CreditCard

export const creditCardColumns = () => [
  ...liabilityColumns(),
  {
    key: 'interestRateValue',
    title: i18next.t('INTEREST_RATE_VALUE'),
    dataIndex: 'interestRate',
    objectKey: 'value',
    type: IMPORT_COL_TYPES.PERCENTAGE
  },
  {
    key: 'interestRateUnit',
    title: i18next.t('INTEREST_RATE_UNIT'),
    dataIndex: 'interestRate',
    objectKey: 'unit',
    type: IMPORT_COL_TYPES.LIST
  },
  {
    key: 'expiryDate',
    title: i18next.t('EXPIRY_DATE'),
    type: IMPORT_COL_TYPES.DATE
  },
  {
    key: 'paymentDueDate',
    title: i18next.t('PAYMENT_DUE_DATE'),
    type: IMPORT_COL_TYPES.DATE
  },
  {
    key: 'issuingBank',
    title: i18next.t('ISSUING_BANK')
  },
  {
    key: 'recurringPaymentDueDay',
    title: i18next.t('RECURRING_PAYMENT_DUE_DAY'),
    type: IMPORT_COL_TYPES.DAY_IN_MONTH
  },
  {
    key: 'creditLimit',
    title: i18next.t('CREDIT_LIMIT'),
    type: IMPORT_COL_TYPES.CURRENCY
  },
  ...referenceColumns()
]
