import { config as AWSConfig } from 'aws-sdk'
import config from '../config'
import api from './api'

AWSConfig.region = config.aws.REGION

const s3Get = async (userId, fileId, metadata, config) => {
  try {
    //use signed URL to get file content
    const urlRes = await api.getSignedUrl(userId, fileId, 'getObject', metadata)
    if (urlRes.data.message) throw Error(urlRes.data.message)

    const contentRes = await api.getFromS3Url(urlRes.data, config)
    return contentRes?.data
  } catch (err) {
    throw err
  }
}

const s3Put = async (userId, fileId, body, metadata) => {
  try {
    const urlRes = await api.getSignedUrl(userId, fileId, 'putObject', metadata)
    if (urlRes.data.message) throw Error(urlRes.data.message)

    const response = await api.uploadToS3Url(urlRes.data, body)
    return response
  } catch (err) {
    throw err
  }
}

export {
  AWSConfig,
  s3Get,
  s3Put
}
