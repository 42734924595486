import React, { useState, useContext } from 'react'
import { Icon, Alert } from 'antd'
import { H4, Span, StatusText } from '../override/Typography'
import { ThemeContext } from 'styled-components'
import ConfigInactivityModal from './ConfigInactivityModal'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

function ConfigInactivityState() {
  const theme = useContext(ThemeContext)
  const { t } = useTranslation()
  const [configModalVisible, setConfigModalVisible] = useState(false)
  const {
    inactivityReminderResponseDuration,
    inactivityDuration
  } = useSelector(state => state.user).user
  const inactivityStateConfig = {
    inactivityReminderResponseDuration,
    inactivityDuration
  }
  const { deputies } = useSelector(state => state.deputies)

  const activeDeputies =
    deputies?.filter(d => d.id && d.publicKey && d.shareKey) || []

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 10
        }}
      >
        <H4>{t('INACTIVITY_STATE_CONFIGURATION_TITLE')}</H4>
        {!!activeDeputies?.length && (
          <Span
            color={theme.primary}
            style={{ cursor: 'pointer' }}
            onClick={() => setConfigModalVisible(true)}
          >
            <Icon type="setting" style={{ marginRight: 8 }} />
            {t('CONFIGURE')}
          </Span>
        )}
      </div>

      <StatusText color={theme.dark2}>
        {t('INACTIVITY_STATE_CONFIGURATION_FIRST_SUMMARY')}
      </StatusText>
      <StatusText color={theme.dark2}>
        {t('INACTIVITY_STATE_CONFIGURATION_SECOND_SUMMARY')}
      </StatusText>
      {!activeDeputies?.length && (
        <Alert
          style={{ marginTop: 10 }}
          message={
            <Span>
              {t('YOU_NEED_TO_HAVE_DEPUTIES_TO_ABLE_TO_USE_THIS_FEATURE')}
            </Span>
          }
          type="warning"
          showIcon
        />
      )}

      <ConfigInactivityModal
        configModalVisible={configModalVisible}
        setConfigModalVisible={setConfigModalVisible}
        inactivityStateConfig={inactivityStateConfig}
      />
    </>
  )
}

export default ConfigInactivityState
