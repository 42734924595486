import {
  TYPE_OF_POLICY_DEFAULT_OPTIONS,
  INTEREST_RATE_UNITS,
  ACCOUNT_TYPES,
  BUILDING_TYPES,
  SQUARE_UNITS,
  HOLDING_TYPES,
  RENT_AMOUNT_UNITS,
  PREMIUM_UNITS,
  LOAN_PERIOD_UNITS,
  //LOAN_PERIOD_UNITS,
  DEFAULT_INTEREST_RATE_TYPES,
  DEFAULT_CLASS_OF_SHARES,
  DEFAULT_OTHER_ASSET_TYPES,
  DEFAULT_RETIREMENT_ACCOUNT_TYPES,
  IMPORT_COL_TYPES,
  EMAIL_DEFAULT_OPTIONS,
  ADDRESS_DEFAULT_OPTIONS,
  PHONE_DEFAULT_OPTIONS,
  CURRENCY_NUM_FMT,
  TRUST_REVOCABILITY,
  DEFAULT_TRUST_TYPE,
  DEFAULT_TYPE_OF_TRUST_INTEREST,
  ASSET_TYPES
} from './Constants'
import currencyData from 'world-currencies'
import MICS from './CommonMICs'
import { countries } from 'country-data'
import moment from 'moment'
import * as utf8 from 'utf8'
import * as quotedPrintable from 'quoted-printable'
import { CRYPTO_CURRENCIES, CRYPTO_EXCHANGES } from './CommonCryptos'
import logo from '../assets/logo.png'

const VCF_TYPE_MAP = {
  email: {
    home: 'Personal',
    work: 'Work'
  },
  adr: {
    home: 'Home',
    work: 'Work'
  },
  tel: {
    cell: 'Mobile',
    home: 'Home',
    work: 'Work'
  }
}

const FILTERABLE_VCF_TYPES = ['internet', 'voice', 'pref']

const currenciesCount = Object.keys(currencyData).length
const exchangesCount = MICS.length
const countriesCount = countries.all.filter(c => c.status === 'assigned').length

const promptTitle = (title, required) => {
  return required ? `${title}. Mandatory field.` : title
}

export const addValidation = (column, wsName) => {
  const { type, key, isCustomList, required } = column
  switch (type) {
    case IMPORT_COL_TYPES.CURRENCY:
      return {
        type: 'decimal',
        operator: 'greaterThan',
        allowBlank: !required,
        showInputMessage: true,
        showErrorMessage: true,
        formulae: [0],
        promptTitle: promptTitle('Decimal greater than 0', required),
        prompt: 'Enter a decimal greater than 0',
        errorStyle: 'error',
        errorTitle: 'Decimal greater than 0',
        error: 'The value must be a decimal greater than 0'
      }
    case IMPORT_COL_TYPES.PERCENTAGE:
      return {
        type: 'decimal',
        operator: 'between',
        allowBlank: !required,
        showInputMessage: true,
        showErrorMessage: true,
        formulae: [0, 100],
        promptTitle: promptTitle('Decimal between 0 & 100', required),
        prompt: 'Enter a decimal between 0 & 100',
        errorStyle: 'error',
        errorTitle: 'Decimal between 0 & 100',
        error: 'The value must be a decimal between 0 & 100'
      }
    case IMPORT_COL_TYPES.NUMBER:
      return {
        type: 'whole',
        operator: 'greaterThan',
        allowBlank: !required,
        showInputMessage: true,
        showErrorMessage: true,
        formulae: [0],
        promptTitle: promptTitle('Number greater than 0', required),
        prompt: 'Enter a whole number greater than 0',
        errorStyle: 'error',
        errorTitle: 'Number greater than 0',
        error: 'The value must be a whole number greater than 0'
      }
    case IMPORT_COL_TYPES.DAY_IN_MONTH:
      return {
        type: 'whole',
        operator: 'between',
        allowBlank: !required,
        showInputMessage: true,
        showErrorMessage: true,
        formulae: [0, 31],
        promptTitle: promptTitle('Number between 0 & 31', required),
        prompt: 'Enter a whole number between 0 and 31',
        errorStyle: 'error',
        errorTitle: 'Number between 0 & 31',
        error: 'The value must be a whole number between 0 and 31'
      }
    case IMPORT_COL_TYPES.LIST:
      return {
        type: 'list',
        allowBlank: !required,
        showInputMessage: true,
        showErrorMessage: !isCustomList,
        formulae: getListFormulae(key, wsName),
        promptTitle: promptTitle('Dropdown', required),
        prompt: isCustomList
          ? 'Select a value from the dropdown list or enter a new one'
          : 'Select a value from the dropdown list',
        errorStyle: 'error',
        errorTitle: 'Invalid value',
        error: 'The value must be one of the options in the dropdown list'
      }
    case IMPORT_COL_TYPES.DATE:
      return {
        type: 'date',
        operator: 'greaterThan',
        formulae: [new Date(1900, 0, 1)],
        showInputMessage: true,
        showErrorMessage: true,
        allowBlank: !required,
        promptTitle: promptTitle('Date', required),
        prompt: 'Enter a date',
        errorStyle: 'error',
        errorTitle: 'Date',
        error: 'The value must be a date'
      }
    case IMPORT_COL_TYPES.COMMA_SEPARATED:
      return {
        type: 'textLength',
        operator: 'greaterThan',
        formulae: [0],
        allowBlank: !required,
        showInputMessage: true,
        showErrorMessage: !!required,
        promptTitle: promptTitle('Comma-separated values'),
        prompt: 'Enter comma-separated values',
        errorStyle: 'error',
        errorTitle: 'Mandatory field',
        error: `Mandatory field can't be blank`
      }
    default:
      return {
        type: 'textLength',
        operator: 'greaterThan',
        formulae: [0],
        allowBlank: !required,
        showInputMessage: !!required,
        showErrorMessage: !!required,
        promptTitle: 'Mandatory field',
        prompt: 'Enter a value for this mandatory field',
        errorStyle: 'error',
        errorTitle: 'Mandatory field',
        error: `Mandatory field can't be blank`
      }
  }
}

const arrayToFormulae = arr => {
  return [`"${arr.join(',')}"`]
}

const getListFormulae = (key, wsName) => {
  switch (key) {
    // assets / liabilites fields
    case 'currency':
      return wsName === ASSET_TYPES.CRYPTO
        ? arrayToFormulae(CRYPTO_CURRENCIES.map(c => c.code))
        : [`=Currencies!$A$2:$A$${currenciesCount + 1}`]
    case 'exchange':
      return [`=Exchanges!$A$2:$A$${exchangesCount + 1}`]
    case 'typeOfPolicy':
      return arrayToFormulae(TYPE_OF_POLICY_DEFAULT_OPTIONS)
    case 'classOfShares':
      return arrayToFormulae(DEFAULT_CLASS_OF_SHARES)
    case 'otherAssetType':
      return arrayToFormulae(DEFAULT_OTHER_ASSET_TYPES)
    case 'retirementAccountType':
      return arrayToFormulae(DEFAULT_RETIREMENT_ACCOUNT_TYPES)
    case 'typeOfInterestRate':
      return arrayToFormulae(DEFAULT_INTEREST_RATE_TYPES)
    case 'trustRevocability':
      return arrayToFormulae(TRUST_REVOCABILITY)
    case 'trustType':
      return arrayToFormulae(DEFAULT_TRUST_TYPE)
    case 'typeOfTrustInterestKey':
      return arrayToFormulae(DEFAULT_TYPE_OF_TRUST_INTEREST)
    case 'interestRateUnit':
      return arrayToFormulae(Object.values(INTEREST_RATE_UNITS))
    case 'accountType':
      return arrayToFormulae(Object.values(ACCOUNT_TYPES))
    case 'buildingType':
      return arrayToFormulae(Object.values(BUILDING_TYPES))
    case 'floorSizeUnit':
      return arrayToFormulae(Object.values(SQUARE_UNITS))
    case 'holdingType':
      return arrayToFormulae(Object.values(HOLDING_TYPES))
    case 'rentAmountUnit':
      return arrayToFormulae(Object.values(RENT_AMOUNT_UNITS))
    case 'premiumUnit':
      return arrayToFormulae(Object.values(PREMIUM_UNITS))
    case 'loanPeriodUnit':
      return arrayToFormulae(Object.values(LOAN_PERIOD_UNITS))
    // case 'loanPeriodUnit':
    //   return arrayToFormulae(Object.values(LOAN_PERIOD_UNITS))
    case 'cryptoExchange':
      return arrayToFormulae(CRYPTO_EXCHANGES)
    // contacts fields
    case 'email1Name':
    case 'email2Name':
    case 'email3Name':
      return arrayToFormulae(EMAIL_DEFAULT_OPTIONS)
    case 'address1Name':
    case 'address2Name':
    case 'address3Name':
      return arrayToFormulae(ADDRESS_DEFAULT_OPTIONS)
    case 'phoneNumber1Name':
    case 'phoneNumber2Name':
    case 'phoneNumber3Name':
      return arrayToFormulae(PHONE_DEFAULT_OPTIONS)
    case 'countryTerritoryOfIncorporation':
      return [`=Countries!$A$2:$A$${countriesCount + 1}`]
    default:
      return []
  }
}

export const renderValue = (type, rawValue) => {
  switch (type) {
    case IMPORT_COL_TYPES.DATE:
      return rawValue ? new Date(rawValue) : ''
    case IMPORT_COL_TYPES.BOOLEAN:
      return rawValue === true ? 'Yes' : 'No'
    default:
      return rawValue
  }
}

export const parseValue = (type, rawValue) => {
  switch (type) {
    case IMPORT_COL_TYPES.DATE:
      return rawValue ? moment(rawValue) : undefined
    case IMPORT_COL_TYPES.BOOLEAN:
      return rawValue && rawValue.toUpperCase() === 'YES'
    case IMPORT_COL_TYPES.COMMA_SEPARATED:
      return rawValue && rawValue.split(',').map(item => item?.trim())
    default:
      // when the value is an email, it's converted to object so need to get value differently
      return typeof rawValue === 'object' && 'text' in rawValue
        ? rawValue.text
        : rawValue || undefined
  }
}

export const addWorksheetContent = (
  base64Banner,
  workbook,
  data,
  wsName,
  columns
) => {
  workbook.addWorksheet(wsName)
  const ws = workbook.getWorksheet(wsName)

  ws.getRow(1).height = 60

  const vaultboxLogo = workbook.addImage({
    base64: logo,
    extension: 'png'
  })
  ws.addImage(vaultboxLogo, {
    tl: { col: 0.35, row: 0.9 },
    ext: { width: 165, height: 45 }
  })

  const vaultboxBanner = workbook.addImage({
    base64: base64Banner,
    extension: 'png'
  })

  ws.addImage(vaultboxBanner, {
    tl: { col: 1.0, row: 0.9 },
    ext: { width: 210, height: 50 },
    hyperlinks: {
      hyperlink: 'https://app.vaultbox.tech/signup',
      tooltip: 'https://app.vaultbox.tech/signup'
    }
  })

  ws.getCell('C1').value = {
    text: 'www.vaultbox.tech',
    hyperlink: 'http://www.vaultbox.tech'
  }
  ws.getCell('C1').alignment = {
    vertical: 'middle',
    horizontal: 'center'
  }
  ws.getCell('C1').font = {
    name: 'Overpass',
    underline: true,
    size: 11,
    color: { argb: 'FFFFFF' }
  }

  ws.getCell('D1').value =
    "Sign up with vaultbox, you'll be able to transform these spreadsheet into time graphs and pie charts"
  ws.getCell('D1').alignment = {
    vertical: 'middle',
    wrapText: true,
    horizontal: 'center'
  }
  ws.getCell('D1').font = {
    name: 'Overpass',
    size: 9,
    color: { argb: 'FFFFFF' }
  }

  ws.getCell('E1').value = 'Finally. Organised.'
  ws.getCell('E1').alignment = {
    vertical: 'middle',
    horizontal: 'center'
  }
  ws.getCell('E1').font = {
    name: 'Overpass',
    size: 14,
    bold: true,
    color: { argb: '02884E' }
  }

  const alphabet = 'ABCDEFGHIJKLMNOPQRS'.split('')
  alphabet.forEach(item => {
    ws.getCell(item.concat('1')).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '22253F' }
    }
  })

  const headers = []
  columns.filter(col => !col.ignore).forEach(col => headers.push(col.title))
  ws.getRow(2).values = headers

  ws.columns = columns
    .filter(col => !col.ignore)
    .map((col, colNumber) => {
      return {
        key: col.key,
        width: 30
      }
    })

  for (let i = 3; i <= 100; i++) {
    const row = ws.getRow(i)

    columns
      .filter(col => !col.ignore)
      .forEach((col, index) => {
        const cell = row.getCell(index + 1)

        if (i === 3) {
          if (col.key in data) {
            cell.value = renderValue(col.type, data[col.key])
          } else if (col.dataIndex in data) {
            if (
              col.arrayIndex !== undefined &&
              Array.isArray(data[col.dataIndex])
            ) {
              cell.value = renderValue(
                col.type,
                data[col.dataIndex][col.arrayIndex][col.objectKey]
              )
            } else {
              cell.value = renderValue(
                col.type,
                data[col.dataIndex][col.objectKey]
              )
            }
          }
        }

        cell.dataValidation = addValidation(col, wsName)

        if (col.type === IMPORT_COL_TYPES.CURRENCY) {
          cell.numFmt = CURRENCY_NUM_FMT
        }
      })
  }
}

const tryJSONParse = vcfField => {
  if (!vcfField?._data) return null

  try {
    return JSON.parse(`"${vcfField._data}"`)
  } catch (err) {
    return vcfField._data
  }
}

const getFieldData = vcfField => {
  if (!vcfField) return null

  return vcfField.charset === 'UTF-8' &&
    vcfField.encoding === 'QUOTED-PRINTABLE'
    ? utf8.decode(quotedPrintable.decode(vcfField._data)) // Decode if needed
    : tryJSONParse(vcfField)
}

const getNameValueFromVcfType = (key, vcfField) => {
  let name,
    isPreferred = false

  // mapping the vcf types to matching vaultbox types
  // default to "Other" if can't find the matching one
  if (!vcfField?.type) {
    name = 'Other'
  } else {
    const types = Array.isArray(vcfField.type) ? vcfField.type : [vcfField.type]
    const filteredTypes = types.filter(
      type => !FILTERABLE_VCF_TYPES.includes(type)
    )
    name = filteredTypes.length
      ? filteredTypes
          .map(type => VCF_TYPE_MAP[key][type] || type || 'Other')
          .join(', ')
      : 'Other'

    isPreferred = types.includes('pref')
  }

  const data = getFieldData(vcfField)

  // address format: postOfficeBox;number;street;city;region;postalCode;country
  // just filter the empty values & re-join
  const value =
    key === 'adr'
      ? data
          .split(';')
          // ADR in vcf spec has 7 components, but somehow the vcard exported from gmail has 8th component (sum of the first 7)
          // so need to remove it here
          .slice(0, 7)
          .filter(name => !!name)
          .join('\r\n')
      : data

  return { name, value, isPreferred }
}

const addressLine = vcfField => {
  if (!vcfField?._data) return null

  return getNameValueFromVcfType('adr', vcfField)
}

const nameValueLine = (key, vcfField) => {
  if (!vcfField?._data) return null

  return getNameValueFromVcfType(key, vcfField)
}

const dateLine = vcfField => {
  if (!vcfField?._data) return null

  const data = getFieldData(vcfField)
  // if value is in "19531015T231000Z" format strip time field and use date value.
  const value = data.length === 16 ? data.substr(0, 8) : data

  let dateValue

  if (value.length === 8) {
    // "19960415" format ?
    dateValue = new Date(
      value.substr(0, 4),
      value.substr(4, 2) - 1, // minus 1 because Date's month count from 0
      value.substr(6, 2)
    )
  } else {
    // last chance to try as date.
    dateValue = new Date(value)
  }

  if (!dateValue || isNaN(dateValue.getDate())) {
    dateValue = null
    throw Error('Invalid date format ' + value)
  }

  return dateValue && dateValue.toJSON() // always return the ISO date format
}

const convert = (data, convertFn) => {
  if (Array.isArray(data)) {
    return data.map(convertFn).filter(d => !!d)
  }

  return convertFn(data) ? [convertFn(data)] : []
}

export const vcfToJsonMapper = vcf => {
  const { adr, bday, email, tel, note, fn, n, url } = vcf.data

  const names = getFieldData(n)?.split(';')

  return {
    addresses: convert(adr, addressLine),
    dateOfBirth: dateLine(bday),
    emails: convert(email, d => nameValueLine('email', d)),
    phoneNumbers: convert(tel, d => nameValueLine('tel', d)),
    description: tryJSONParse(note),
    fullName: getFieldData(fn),
    names:
      names && Array.isArray(names)
        ? {
            surName: names[0] || '',
            givenName: names[1] || '',
            additionalName: names[2] || '',
            prefix: names[3] || '',
            suffix: names[4] || ''
          }
        : {},
    url: url && convert(url, data => tryJSONParse(data)).join(', ')
  }
}
