import React, { useState, useEffect, useContext } from 'react'
import AuthContext from '../../contexts/AuthContext'
import api from '../../lib/api'
import {
  Empty,
  Spin,
  Dropdown,
  Menu,
  Table,
  Tooltip,
  Icon,
  message,
  Popconfirm,
  Divider
} from 'antd'
import CustomIcon from '../override/Icon'
import { ThemeContext } from 'styled-components'
import Button from '../override/Button'
import { getUserAttributeValue, getUserData } from '../../lib/cognito'
import TableHeader from '../common/TableHeader'
import PrimaryUserDetailsModal from './PrimaryUserDetailsModal'
import { onError } from '../../lib/sentry'
import {
  addPDNameColumn,
  getExternalUserAttributes
} from './../../share/helpers'
import { useSelector, useDispatch } from 'react-redux'
import { fetchUser } from '../../features/user/userSlice'
import { useTranslation } from 'react-i18next'

export default function PrimaryUsers({
  fetchVaults,
  professionalDeputies,
  isLoading,
  setIsLoading,
  fetchData,
  primaryUsers,
  pendingRequests,
  unlockRequests,
  updateRequests,
  resetRequests
}) {
  const { user } = useContext(AuthContext)
  const theme = useContext(ThemeContext)
  const externalUser = localStorage.getItem('External_User')
  const [userInfo, setUserInfo] = useState({ email: '', discountCode: '' })
  const [acceptedDetailsVisible, setAcceptedDetailsVisible] = useState(false)
  const [pendingDetailsVisible, setPendingDetailsVisible] = useState(false)
  const [pendingUpdateDetailsVisible, setPendingUpdateDetailsVisible] =
    useState(false)
  const [selectedPrimaryUser, setSelectedPrimaryUser] = useState({})
  const [delegateAccessRequestsLoading, setDelegateAccessRequestsLoading] =
    useState(false)

  const dispatch = useDispatch()
  const { professionalDeputyId, delegatedByProfessionalDeputies } = useSelector(
    state => state.user
  ).user
  const { t } = useTranslation()

  const isProfessionalDeputyId = (primaryUserId, isPending = false) =>
    !!professionalDeputyId ||
    (!!delegatedByProfessionalDeputies?.length &&
      delegatedByProfessionalDeputies.some(d =>
        (isPending ? d.pendingPrimaryUsers : d.primaryUsers)?.includes(
          primaryUserId
        )
      ))

  const pendingRequestsColumns = [
    {
      key: 'email',
      dataIndex: 'email',
      title: t('EMAIL')
    },
    {
      key: 'action',
      align: 'right',
      render: (text, record) => (
        <>
          {!isProfessionalDeputyId(record.key, true) ? (
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item>
                    <Button
                      type="link"
                      icon="check-circle"
                      onClick={() => handleDeputyRequest(true, record.key)}
                    >
                      {t('ACCEPT')}
                    </Button>
                  </Menu.Item>
                  <Menu.Item>
                    <Button
                      type="link"
                      icon="minus-circle"
                      style={{ color: theme.red }}
                      onClick={() => handleDeputyRequest(false, record.key)}
                    >
                      {t('DECLINE')}
                    </Button>
                  </Menu.Item>
                </Menu>
              }
              placement="bottomRight"
              trigger={['click']}
            >
              <CustomIcon type="more" />
            </Dropdown>
          ) : (
            <Tooltip title={t('VIEW_DETAILS')}>
              <Icon
                type="unordered-list"
                style={{ marginLeft: '1em' }}
                onClick={() => {
                  setSelectedPrimaryUser(record)
                  setPendingDetailsVisible(true)
                }}
              />
            </Tooltip>
          )}
        </>
      )
    }
  ]

  const pendingUpdateUserDetailsColumns = [
    {
      key: 'email',
      dataIndex: 'email',
      title: t('EMAIL')
    },
    {
      key: 'action',
      align: 'right',
      render: (text, record) => (
        <Tooltip title={t('VIEW_DETAILS')}>
          <Icon
            type="unordered-list"
            style={{ marginLeft: '1em' }}
            onClick={() => {
              setSelectedPrimaryUser(record)
              setPendingUpdateDetailsVisible(true)
            }}
          />
        </Tooltip>
      )
    }
  ]

  let primaryUsersColumns = [
    {
      key: 'email',
      dataIndex: 'email',
      title: t('EMAIL')
    },
    {
      key: 'status',
      title: t('STATUS'),
      render: (text, record) =>
        record.allPublicKeys?.some(
          d =>
            (isProfessionalDeputyId(record.key) ||
              d.deputyId === user.username) &&
            d.pendingDisconect
        )
          ? t('WAITING_FOR_DISCONNECTION')
          : record.shareKey
          ? t('RECEIVED_BACKUP_KEY')
          : t('NOT_YET_RECEIVED_BACKUP_KEY')
    }
  ]

  if (!!professionalDeputyId || delegatedByProfessionalDeputies?.length) {
    primaryUsersColumns.push(
      {
        title: t('LEGACY_MANAGEMENT_ENABLED'),
        key: 'legacyManagementEnabled',
        render: (text, record) =>
          isProfessionalDeputyId(record.key)
            ? record.legacyManagementEnabled
              ? t('YES')
              : t('NO')
            : ''
      },
      {
        key: 'action',
        align: 'right',
        render: (text, record) =>
          isProfessionalDeputyId(record.key) && (
            <>
              <Tooltip title={t('VIEW_DETAILS')}>
                <Icon
                  type="unordered-list"
                  onClick={() => {
                    setSelectedPrimaryUser(record)
                    setAcceptedDetailsVisible(true)
                  }}
                />
              </Tooltip>
              {!record.allPublicKeys?.some(
                d =>
                  (isProfessionalDeputyId(record.key) ||
                    d.deputyId === user.username) &&
                  d.pendingDisconect
              ) &&
                !resetRequests?.map(rr => rr.email).includes(record.email) &&
                !updateRequests?.map(rr => rr.email).includes(record.email) &&
                !unlockRequests?.map(rr => rr.email).includes(record.email) && (
                  <>
                    <Divider type="vertical" />
                    <Popconfirm
                      title={t('ARE_YOU_SURE_TO_DISCONNECT_THIS_USER')}
                      onConfirm={() => handleDisconectUser(record.key)}
                      okText={t('YES')}
                      cancelText={t('NO')}
                      arrowPointAtCenter
                      placement="bottomRight"
                    >
                      <Tooltip title={t('DISCONNECT')}>
                        <Icon type="delete" />
                      </Tooltip>
                    </Popconfirm>
                  </>
                )}
            </>
          )
      }
    )
  } else {
    primaryUsersColumns.push({
      key: 'action',
      align: 'right',
      render: (text, record) =>
        !record.allPublicKeys?.some(
          d => d.deputyId === user.username && d.pendingDisconect
        ) &&
        !resetRequests?.map(rr => rr.email).includes(record.email) &&
        !updateRequests?.map(rr => rr.email).includes(record.email) &&
        !unlockRequests?.map(rr => rr.email).includes(record.email) && (
          <Popconfirm
            title={t('ARE_YOU_SURE_TO_DISCONNECT_THIS_USER')}
            onConfirm={() => handleDisconectUser(record.key)}
            okText={t('YES')}
            cancelText={t('NO')}
            arrowPointAtCenter
            placement="bottomRight"
          >
            <Tooltip title={t('DISCONNECT')}>
              <Icon type="delete" />
            </Tooltip>
          </Popconfirm>
        )
    })
  }

  const delegatedByPdsColumns = [
    {
      title: t('FROM'),
      dataIndex: 'professionalDeputyId',
      key: 'professionalDeputyId',
      render: pdId => {
        return professionalDeputies.find(pd => pd.id === pdId)
          ?.professionalDeputyName
      }
    },
    {
      title: t('STATUS'),
      dataIndex: 'isAccepted',
      key: 'isAccepted',
      render: text => (text ? t('ACCEPTED') : t('PENDING'))
    },
    {
      key: 'action',
      render: (text, record) =>
        !record.isAccepted ? (
          <div style={{ textAlign: 'right' }}>
            <Button onClick={() => handlePdDelegation(record, true)}>
              {t('ACCEPT')}
            </Button>
            <Button
              onClick={() => handlePdDelegation(record)}
              style={{ color: theme.red, marginLeft: 8 }}
            >
              {t('REJECT')}
            </Button>
          </div>
        ) : (
          ''
        )
    }
  ]

  const handleDisconectUser = async primaryUserId => {
    setIsLoading(true)
    try {
      await api.disconectUser(
        user.username,
        JSON.stringify({
          primaryUserId,
          professionalDeputyId
        })
      )
      fetchData()
      setIsLoading(false)
    } catch (error) {
      message.error(t('FAILED_TO_DISCONNECT_USER'))
    }
  }

  const handlePdDelegation = async (record, isAccepted) => {
    setDelegateAccessRequestsLoading(true)
    try {
      const data = JSON.stringify({
        userId: user.username,
        isAccepted,
        professionalDeputyId: record.professionalDeputyId
      })

      await api.handlePdDelegation(data)
      dispatch(fetchUser(user.username))
      message.success(
        isAccepted ? t('REQUEST_ACCEPTED') : t('REQUEST_REJECTED')
      )
      setDelegateAccessRequestsLoading(false)
    } catch (error) {
      message.error(
        isAccepted
          ? t('FAILED_TO_ACCEPT_REQUEST')
          : t('FAILED_TO_REJECT_REQUEST')
      )
      onError(error)
      setDelegateAccessRequestsLoading(false)
    }
  }

  useEffect(() => {
    if (externalUser) {
      const userAttributes = getExternalUserAttributes()
      const email = userAttributes.email
      const discountCode = userAttributes.discount_code
      const fullname = userAttributes.full_name
      setUserInfo({ email, discountCode, fullname })
    } else {
      getUserData(
        user,
        (err, data) => {
          if (err) {
            onError(err)
          }

          const email = getUserAttributeValue(data.UserAttributes, 'email')
          const discountCode = getUserAttributeValue(
            data.UserAttributes,
            'custom:discount_code'
          )
          const fullname = getUserAttributeValue(
            data.UserAttributes,
            'custom:full_name'
          )
          setUserInfo({ email, discountCode, fullname })
        },
        { bypassCache: true }
      )
    }
  }, [user, externalUser])

  const handleDeputyRequest = (isAccepted, primaryUserId) => {
    const requestedProfessionalDeputyId =
      professionalDeputyId ||
      delegatedByProfessionalDeputies?.find(dpd =>
        dpd.pendingPrimaryUsers?.includes(primaryUserId)
      )?.professionalDeputyId

    const handleData = {
      isAccepted,
      primaryUserId,
      professionalDeputyId: requestedProfessionalDeputyId,
      professionalDeputyName:
        professionalDeputies.find(pd => pd.id === requestedProfessionalDeputyId)
          ?.professionalDeputyName || '',
      ...userInfo
    }

    setIsLoading(true)

    api
      .handleDeputyRequest(user.username, JSON.stringify(handleData))
      .then(response => {
        if (response.data?.success) {
          fetchData()
          fetchVaults()
          dispatch(fetchUser(user.username))
        }
      })
      .catch(err => {
        setIsLoading(false)
        message.error(t('FAILED_TO_HANDLE_DEPUTY_REQUEST'))
        onError(err)
      })
  }

  const handleUpdateUserDetailsRequest = async (isAccepted, pendingDetails) => {
    try {
      setIsLoading(true)
      const primaryUserId = selectedPrimaryUser.key
      const allProfessionalDeputies = selectedPrimaryUser.allPublicKeys.filter(
        d => d.professionalDeputyId
      )

      const primaryUserDataToUpdate = {
        sourceFileId: `identification/pendingDetails`,
        newFileId: `identification/details`
      }

      if (isAccepted) {
        await api.copyFile(
          primaryUserId,
          JSON.stringify(primaryUserDataToUpdate)
        )
      }

      await api.bulkDeleteFiles(
        primaryUserId,
        JSON.stringify({
          keys: [primaryUserDataToUpdate.sourceFileId]
        })
      )

      let deputyDataToUpdate = [
        {
          sourceFileId: `identification/${primaryUserId}/pendingDetails`,
          newFileId: `identification/${primaryUserId}/details`
        },
        {
          sourceFileId: `identification/${primaryUserId}/pendingIdPassport`,
          newFileId: `identification/${primaryUserId}/idPassport`
        }
      ]
      if (pendingDetails.addressProof) {
        deputyDataToUpdate.push({
          sourceFileId: `identification/${primaryUserId}/pendingAddressProof`,
          newFileId: `identification/${primaryUserId}/addressProof`
        })
      }

      await Promise.all(
        allProfessionalDeputies.map(async deputy => {
          if (isAccepted) {
            await api.bulkCopyFiles(
              deputy.deputyId,
              JSON.stringify({
                normalFileData: deputyDataToUpdate
              })
            )
          }

          await api.bulkDeleteFiles(
            deputy.deputyId,
            JSON.stringify({
              keys: deputyDataToUpdate.map(dtu => dtu.sourceFileId)
            })
          )
        })
      )

      await api.handleUpdateUserDetailsRequest(primaryUserId)
      fetchData()
      setIsLoading(false)
    } catch (error) {
      onError(error)
      setIsLoading(false)
      message.error(t('FAILED_TO_HANDLE_UPDATE_USER_DETAILS_REQUEST'))
    }
  }

  if (delegatedByProfessionalDeputies?.length) {
    addPDNameColumn(
      pendingRequestsColumns,
      professionalDeputies,
      delegatedByProfessionalDeputies,
      true
    )
    addPDNameColumn(
      primaryUsersColumns,
      professionalDeputies,
      delegatedByProfessionalDeputies
    )
    addPDNameColumn(
      pendingUpdateUserDetailsColumns,
      professionalDeputies,
      delegatedByProfessionalDeputies
    )
  }

  return (
    <Spin spinning={isLoading}>
      {!!delegatedByProfessionalDeputies?.length && (
        <>
          <TableHeader
            title={t('PROFESSIONAL_DEPUTY_ACCESS_DELEGATIONS')}
            count={delegatedByProfessionalDeputies.length}
          />
          <Table
            columns={delegatedByPdsColumns}
            dataSource={delegatedByProfessionalDeputies}
            rowKey="professionalDeputyId"
            loading={delegateAccessRequestsLoading}
            pagination={false}
          />
        </>
      )}

      <TableHeader
        title={t('PENDING_MY_ACCEPTANCE_TO_BE_DEPUTY')}
        count={pendingRequests.length}
      />
      <Table
        rowKey="key"
        dataSource={pendingRequests}
        scroll={{ x: true }}
        columns={pendingRequestsColumns}
        pagination={false}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('NO_PENDING_REQUESTS')}
            />
          )
        }}
        showHeader={!!pendingRequests.length}
      />
      <TableHeader
        title={t('I_AM_CURRENTLY_A_DEPUTY_FOR_THESE_PRIMARY_USERS')}
        count={primaryUsers.length}
      />
      <Table
        rowKey="key"
        dataSource={primaryUsers}
        scroll={{ x: true }}
        columns={primaryUsersColumns}
        pagination={false}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('NO_PRIMARY_USERS')}
            />
          )
        }}
        showHeader={!!primaryUsers.length}
      />
      {(!!professionalDeputyId ||
        !!delegatedByProfessionalDeputies?.length) && (
        <>
          <TableHeader
            title={t('REQUEST_TO_UPDATE_THEIR_DETAILS')}
            count={
              primaryUsers.filter(pu => pu.isPendingUpdateUserDetails).length
            }
          />
          <Table
            rowKey="key"
            dataSource={primaryUsers.filter(
              pu => pu.isPendingUpdateUserDetails
            )}
            scroll={{ x: true }}
            columns={pendingUpdateUserDetailsColumns}
            pagination={false}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={t('NO_REQUESTS')}
                />
              )
            }}
            showHeader={
              !!primaryUsers.filter(pu => pu.isPendingUpdateUserDetails).length
            }
          />
        </>
      )}

      <PrimaryUserDetailsModal
        visible={acceptedDetailsVisible}
        setVisible={setAcceptedDetailsVisible}
        primaryUserId={selectedPrimaryUser.key}
        selectedPrimaryUser={selectedPrimaryUser}
        showLegacyManagement
        legacyManagementEnabled={selectedPrimaryUser.legacyManagementEnabled}
      />
      <PrimaryUserDetailsModal
        visible={pendingDetailsVisible}
        setVisible={setPendingDetailsVisible}
        primaryUserId={selectedPrimaryUser.key}
        selectedPrimaryUser={selectedPrimaryUser}
        isPendingDetails
        handleDeputyRequest={handleDeputyRequest}
      />
      <PrimaryUserDetailsModal
        visible={pendingUpdateDetailsVisible}
        setVisible={setPendingUpdateDetailsVisible}
        selectedPrimaryUser={selectedPrimaryUser}
        primaryUserId={selectedPrimaryUser.key}
        isPendingUpdateDetails
        handleUpdateUserDetailsRequest={handleUpdateUserDetailsRequest}
      />
    </Spin>
  )
}
