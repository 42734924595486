import React, { useContext, useEffect } from 'react'
import { Result, Icon } from 'antd'
import { Link, useLocation } from 'react-router-dom'
import Button from '../override/Button'
import { ThemeContext } from 'styled-components'
import { useTranslation, Trans } from 'react-i18next'

const Goodbye = () => {
  const query = new URLSearchParams(useLocation().search)
  const lang = query.get('lang')
  const theme = useContext(ThemeContext)
  const { t, i18n } = useTranslation()

  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang)
    }
  }, [lang, i18n])

  return (
    <div className="submitted-result">
      <Result
        icon={
          <Icon
            type="check-circle"
            theme="filled"
            style={{ color: theme.primary }}
          />
        }
        title={t('THANKS_AND_GOODBYE')}
        subTitle={
          <span>
            <Trans i18nKey="DELETED_ACCOUNT_SUCCESSFULLY_MSG"></Trans>
          </span>
        }
        extra={
          <Button size="large" type="primary">
            <Link to="/login">{t('RETURN_TO_HOMEPAGE')}</Link>
          </Button>
        }
      />
    </div>
  )
}

export default Goodbye
