import React, { useState } from 'react'
import styled from 'styled-components'
import { Modal } from 'antd'
import SubscriptionModal from '../payment/SubscriptionModal'
import { Trans } from 'react-i18next'

var modal
const Overlay = ({ className }) => {
  const [subscriptionModalVisible, setSubscriptionModalVisible] = useState()

  const handleClick = () => {
    if (!modal && !subscriptionModalVisible) {
      modal = Modal.confirm({
        content: (
          <span>
            <Trans i18nKey="FREE_ACCOUNT_SUMMARY"></Trans>
          </span>
        ),
        okText: <Trans i18nKey="UPGRADE"></Trans>,
        cancelText: <Trans i18nKey="NOT_NOW"></Trans>,
        onOk: () => {
          setSubscriptionModalVisible(true)
          modal = null
        },
        onCancel: () => {
          modal = null
        },
        icon: null
      })
    }
  }

  return (
    <div className={className} onClick={handleClick}>
      <SubscriptionModal
        visible={subscriptionModalVisible}
        setVisible={setSubscriptionModalVisible}
      />
    </div>
  )
}

const StyledOverlay = styled(Overlay)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0.6;
`
export default StyledOverlay
