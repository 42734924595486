import React from 'react'
import { Form, Modal, Input } from 'antd'
import FormItem from '../override/FormItem'
import { withTranslation } from 'react-i18next'

class RejectModal extends React.Component {
  render() {
    const { form, visible, handleOk, handleCancel, rejecting, t } = this.props
    const { getFieldDecorator } = form

    return (
      <Modal
        visible={visible}
        title={t('REJECTION_REASON')}
        onCancel={() => {
          form.resetFields()
          handleCancel()
        }}
        onOk={handleOk}
        okText={t('REJECT')}
        cancelText={t('CANCEL')}
        maskClosable={false}
        okButtonProps={{ loading: rejecting }}
      >
        <Form layout="vertical" hideRequiredMark>
          <FormItem>
            {getFieldDecorator('reasonReject', {
              rules: [
                {
                  required: true,
                  message: t('REJECTED_REASON_INPUT_MSG')
                }
              ]
            })(<Input.TextArea maxLength={2000} rows={4} />)}
          </FormItem>
        </Form>
      </Modal>
    )
  }
}

const WrappedRejectModal = Form.create({ name: 'rejectModal' })(RejectModal)
export default withTranslation()(WrappedRejectModal)
