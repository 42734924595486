import Asset, { assetColumns } from './AssetModel'
import { referenceColumns } from './AssetLiabilityModel'
import { ASSET_TYPES, IMPORT_COL_TYPES } from '../share/Constants'
import i18next from 'i18next'

class Crypto extends Asset {
  /**
   * Construct a new Crypto instance
   * @param {object} params - Crypto creation options:
   * @param {string} [params.cryptoExchange]
   * @param {string} [params.walletAddress]
   */
  constructor({
    _id,
    _rev,
    _deleted,
    title,
    description,
    descriptionWithMarkup,
    percentageOwnership,
    purchaseDate,
    disposalDate,
    currency,
    valuationDate,
    valuationDateInBaseCurrency,
    valuationInAssetCurrency,
    valuationInBaseCurrency,
    contacts,
    references,
    links,
    documents,
    includeValueInNetWorth,
    cryptoExchange,
    walletAddress,
    password,
    status,
    events,
    passwords,
    tags
  }) {
    super({
      _id,
      _rev,
      _deleted,
      title,
      description,
      descriptionWithMarkup,
      percentageOwnership,
      purchaseDate,
      disposalDate,
      currency,
      valuationDate,
      valuationDateInBaseCurrency,
      valuationInAssetCurrency,
      valuationInBaseCurrency,
      contacts,
      references,
      links,
      documents,
      includeValueInNetWorth,
      status,
      events,
      passwords,
      tags
    })

    this.subType = ASSET_TYPES.CRYPTO
    this.cryptoExchange = cryptoExchange
    this.walletAddress = walletAddress
    this.percentageOwnership = 100
    this.password = password
  }
}

export default Crypto

export const cryptoColumns = () => [
  ...assetColumns(),
  {
    key: 'cryptoExchange',
    title: i18next.t('WALLET_PROVIDER'),
    type: IMPORT_COL_TYPES.LIST,
    isCustomList: true
  },
  {
    key: 'walletAddress',
    title: i18next.t('WALLET_ADDRESS')
  },
  ...referenceColumns()
]
