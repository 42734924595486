import React from 'react'
import { Modal, Form } from 'antd'
//import { StringResources } from '../../share/StringResources'
import CurrencySelect from '../common/CurrencySelect'
import FormItem from '../override/FormItem'
import { withTranslation } from 'react-i18next'

class BaseCurrencyModal extends React.Component {
  render() {
    const {
      visible,
      baseCurrency,
      handleSave,
      handleCancel,
      isSaving,
      form,
      t
    } = this.props
    const { getFieldDecorator, getFieldValue } = form

    return (
      <Modal
        visible={visible}
        title={t('SET_BASE_CURRENCY')}
        okText={t('SAVE')}
        cancelText={t('CANCEL')}
        onOk={handleSave}
        onCancel={handleCancel}
        okButtonProps={{
          disabled: !getFieldValue('currency'),
          loading: isSaving
        }}
        maskClosable={false}
      >
        <Form>
          <FormItem>
            {getFieldDecorator('currency', {
              initialValue: baseCurrency,
              rules: [
                { required: true, message: t('PLEASE_SELECT_A_CURRENCY') }
              ]
            })(<CurrencySelect />)}
          </FormItem>
        </Form>
      </Modal>
    )
  }
}

const WrappedBaseCurrencyForm = Form.create({ name: 'baseCurrency' })(
  BaseCurrencyModal
)
export default withTranslation()(WrappedBaseCurrencyForm)
