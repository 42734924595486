import React, { useEffect, useContext, useState, useRef } from 'react'
import {
  Tabs,
  Layout,
  Spin,
  Breadcrumb,
  PageHeader,
  Input,
  Button,
  Icon,
  message,
  Divider,
  Dropdown,
  Menu,
  Alert,
  Radio,
  Row,
  Col,
  Select,
  Modal,
  Tooltip as AntToolTip
} from 'antd'
import { withRouter, useParams, useLocation } from 'react-router-dom'
import VaultContext from '../../contexts/VaultContext'
import { H4, H5, Span1 } from '../override/Typography'
import { ThemeContext } from 'styled-components'
import DocumentsLinksModal from './DocumentsLinksModal'
import AssetsLiabilitiesLinksModal from './AssetsLiabilitiesLinksModal'
import AssetLiabilityInformation from './AssetLiabilityInformation'
import FileDetails from '../file/FileDetails'
import { DATE_FORMAT } from '../../share/Constants'
import EventDetails from '../calendar/EventDetails'
import CustomTable from '../override/CustomTable'
import {
  getRecord,
  getRecords,
  linkDocumentToAssetLiability,
  linkAssetsLiabilities,
  unlinkAssetLiability,
  subscribeToDBChanges,
  linkContacts,
  unlinkContact,
  unlinkPassword,
  unlinkEventFromAL
} from '../../lib/pouchDb'
import {
  formatBytes,
  renderDate,
  search,
  currencyFormat,
  exportToPng,
  currencyChartFormat,
  getTicks
} from '../../share/helpers'
import { CORE_TYPES } from '../../share/Constants'
import { StringResources } from '../../share/StringResources'
import { useSelector, useDispatch } from 'react-redux'
import ContactLinks from './ContactLinks'
import { AssetLiabilityTitleWithIcon } from './AssetLiabilitySubtypes'
import {
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
  Dot
} from 'recharts'
import moment from 'moment'
import FormItem from '../override/FormItem'
import TourContext, { TOUR_STEP_INDEXES } from '../../contexts/TourContext'
import { onError } from '../../lib/sentry'
import { useMediaQuery } from '@material-ui/core'
import { useTranslation, Trans } from 'react-i18next'
import AuthContext from '../../contexts/AuthContext'
import { fetchPendingAssetsLiabilities } from '../../features/assets-liabilities/assetsLiabilitiesSlice'
import PasswordFolderModal from '../file/PasswordFolderModal'
import PasswordDetails from '../passwords/PasswordDetails'
import { useMutation } from 'react-apollo-hooks'
import { createS3Change } from '../../graphql/mutations'
import { AES, enc } from 'crypto-js'

const { TabPane } = Tabs
const { Content, Sider } = Layout

const chartKeyTitleMap = {
  quantity: StringResources.QUANTITY,
  valuationInAssetCurrency: StringResources.VALUATION_ASSET_CURRENCY,
  valuationInBaseCurrency: StringResources.VALUATION_BASE_CURRENCY,
  outstandingValueInLiabilityCurrency:
    StringResources.OUTSTANDING_VALUE_LIABILITY_CURRENCY,
  outstandingValueInBaseCurrency:
    StringResources.OUTSTANDING_VALUE_BASE_CURRENCY
}

const CustomizedShape = props => {
  const { cx, cy, fill, updatedByRate, yAxis } = props
  return (
    <g>
      <Dot
        cx={cx}
        cy={cy}
        r={
          (yAxis.name === StringResources.VALUATION_BASE_CURRENCY ||
            yAxis.name === StringResources.OUTSTANDING_VALUE_BASE_CURRENCY) &&
          updatedByRate
            ? 3
            : 5
        }
        fill={fill}
      />
    </g>
  )
}

const CustomTooltip = ({ active, payload, label }) => {
  if (active) {
    return (
      <div className="custom-tooltip">
        <p>{`${payload[0].name} : ${moment(payload[0].value).format(
          'DD/MM/YYYY'
        )}`}</p>
        <p>{`${payload[1].name} : ${
          payload[1].name !== 'quantity'
            ? currencyFormat(payload[1].value)
            : payload[1].value
        }`}</p>
      </div>
    )
  }

  return null
}

function AssetLiabilityDetails({ history }) {
  const {
    userId,
    isReadonly,
    masterKey,
    privateFolderKey,
    isLockPrivateFolder
  } = useContext(VaultContext)
  const { nextTourStep, tourStepIndex, prevTourStep, tourRun } =
    useContext(TourContext)
  const { isProfessionalDeputy } = useContext(AuthContext)

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const query = new URLSearchParams(useLocation().search)
  const isActived = query.get('isActived')

  const timeRanges = [
    {
      key: 'allTime',
      title: t('ALL_TIME')
    },
    {
      key: '1W',
      title: 1 + t('W_AS_WEEK'),
      subtract: { weeks: 1 }
    },
    {
      key: '1M',
      title: 1 + t('M_AS_MONTH'),
      subtract: { months: 1 }
    },
    {
      key: '3M',
      title: 3 + t('M_AS_MONTH'),
      subtract: { months: 3 }
    },
    {
      key: '6M',
      title: 6 + t('M_AS_MONTH'),
      subtract: { months: 6 }
    },
    {
      key: '1Y',
      title: 1 + t('Y_AS_YEAR'),
      subtract: { years: 1 }
    },
    {
      key: '3Y',
      title: 3 + t('Y_AS_YEAR'),
      subtract: { years: 3 }
    },
    {
      key: '5Y',
      title: 5 + t('Y_AS_YEAR'),
      subtract: { years: 5 }
    }
  ]

  const { assetLiabilityId } = useParams()
  const [record, setRecord] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [linkedAssets, setLinkedAssets] = useState([])
  const [linkedLiabilities, setLinkedLiabilities] = useState([])
  const [linkedDocuments, setLinkedDocuments] = useState([])
  const [linkedEvents, setLinkedEvents] = useState([])
  const [linkedPasswords, setLinkedPasswords] = useState([])
  const [passwordDetails, setPasswordDetails] = useState('')
  const [passwordDetailsVisible, setPasswordDetailsVisible] = useState(false)
  const [filteredLinkedAssets, setFilteredLinkedAssets] = useState([])
  const [filteredLinkedLiabilities, setFilteredLinkedLiabilities] = useState([])
  const [filteredLinkedDocuments, setFilteredLinkedDocuments] = useState([])
  const [linkedContacts, setLinkedContacts] = useState([])
  const [documentsLinksVisible, setDocumentsLinksVisible] = useState(false)
  const [assetLiabilityLinksVisible, setAssetLiabilityLinksVisible] =
    useState(false)
  const [isDetailEventModal, setIsDetailEventModal] = useState(false)
  const [eventDetails, setEventDetails] = useState('')
  const [fileDetailsVisible, setFileDetailsVisible] = useState(false)
  const [selectedDoc, setSelectedDoc] = useState({})
  const [linkType, setLinkType] = useState('')
  const [error, setError] = useState('')
  const [chartData, setChartData] = useState([])
  const [ticks, setTicks] = useState([])
  const [filteredChartData, setFilteredChartData] = useState([])
  const [chartKey, setChartKey] = useState('')
  const [chartFrom, setChartFrom] = useState('')
  const [docItem, setDocItem] = useState('')
  const [isShowLinkedDocument, setIsShowLinkedDocument] = useState(false)

  const theme = useContext(ThemeContext)
  const { activeAssetsLiabilities } = useSelector(state =>
    isReadonly ? state.otherAssetsLiabilities : state.assetsLiabilities
  )
  const { pendingContacts } = useSelector(state => state.contacts)
  const { pendingDocuments, activeDocuments } = useSelector(state =>
    isReadonly ? state.otherDocuments : state.documents
  )
  const [passwordFolderModalVisible, setPasswordFolderModalVisible] =
    useState(false)
  const { baseCurrency } = useSelector(state => state.settings)
  const privateFolder = activeDocuments.find(folder => folder.isPrivate)
  const privateFolderPassword = !!privateFolder?.password
    ? AES.decrypt(privateFolder.password, privateFolderKey).toString(enc.Latin1)
    : undefined
  const chartKeyUnitMap = {
    valuationInAssetCurrency: record.currency,
    valuationInBaseCurrency: baseCurrency,
    outstandingValueInLiabilityCurrency: record.currency,
    outstandingValueInBaseCurrency: baseCurrency
  }

  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'))
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'))

  const chartRef = useRef()

  const [addS3Change] = useMutation(createS3Change)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true)
        const record = await getRecord(
          userId,
          isActived === 'true'
            ? 'assetsLiabilities'
            : 'pendingAssetsLiabilities',
          assetLiabilityId,
          masterKey
        )
        const linkedAssetsLiabilities =
          record.links && record.links.length
            ? await getRecords(userId, 'assetsLiabilities', masterKey, {
                keys: record.links
              })
            : []
        const linkedDocuments =
          record.documents && record.documents.length
            ? [
                ...(await getRecords(userId, 'documents', masterKey)).filter(
                  d => record.documents?.includes(d._id)
                ),
                ...(
                  await getRecords(userId, 'pendingDocuments', masterKey)
                ).filter(d => record.documents?.includes(d._id))
              ]
            : []
        const linkedEvents = record.events?.length
          ? [
              ...(await getRecords(userId, 'events', masterKey)).filter(c =>
                record.events?.includes(c._id)
              ),
              ...(
                await getRecords(userId, 'pendingEvents', masterKey)
              ).filter(c => record.events?.includes(c._id))
            ]
          : []
        const linkedPasswords = record.passwords?.length
          ? [
              ...(await getRecords(userId, 'passwords', masterKey)).filter(c =>
                record.passwords?.includes(c._id)
              ),
              ...(
                await getRecords(userId, 'pendingPasswords', masterKey)
              ).filter(c => record.passwords?.includes(c._id))
            ]
          : []
        const linkedContacts =
          record.contacts && record.contacts.length
            ? [
                ...(await getRecords(userId, 'contacts', masterKey)).filter(c =>
                  record.contacts?.includes(c._id)
                ),
                ...(
                  await getRecords(userId, 'pendingContacts', masterKey)
                ).filter(c => record.contacts?.includes(c._id))
              ]
            : []
        const linkedAssets = linkedAssetsLiabilities.filter(
          al => al.type === CORE_TYPES.ASSET
        )
        const linkedLiabilities = linkedAssetsLiabilities.filter(
          al => al.type === CORE_TYPES.LIABILITY
        )

        setRecord(record)
        setChartKey(
          record.type === CORE_TYPES.ASSET
            ? 'valuationInAssetCurrency'
            : 'outstandingValueInLiabilityCurrency'
        )

        setLinkedAssets(linkedAssets)
        setLinkedLiabilities(linkedLiabilities)
        setLinkedDocuments(linkedDocuments)
        setLinkedEvents(linkedEvents)
        setLinkedPasswords(linkedPasswords)
        setFilteredLinkedAssets(linkedAssets)
        setFilteredLinkedLiabilities(linkedLiabilities)
        setFilteredLinkedDocuments(linkedDocuments)

        setLinkedContacts(linkedContacts)
        setIsLoading(false)
      } catch (err) {
        onError(err)
        setIsLoading(false)
        if (err.status === 404)
          setError(<Trans i18nKey="ASSET_LIABILITY_RECORD_NOT_FOUND"></Trans>)
      }
    }

    if (masterKey) {
      fetchData()
    }

    // TODO: optimize so that only the part which has been changed would be fetched
    const assetsLiabilitiesChanges = subscribeToDBChanges(
      isActived === 'true' ? 'assetsLiabilities' : 'pendingAssetsLiabilities',
      userId,
      fetchData
    )

    // const documentsChanges = subscribeToDBChanges(
    //   'documents',
    //   userId,
    //   fetchData
    // )

    return () => {
      assetsLiabilitiesChanges.cancel()
      // documentsChanges.cancel()
    }
  }, [userId, assetLiabilityId, masterKey, isActived, isProfessionalDeputy])

  const allLinkedContactIds = [
    ...(record.tenant || []),
    ...(record.contacts || []),
    ...(record.insuranceAdvisor || []),
    ...(record.beneficiaries || []),
    ...(record.borrower ? [record.borrower] : []),
    ...(record.nameAssured ? [record.nameAssured] : []),
    ...(record.company ? [record.company] : []),
    ...(record.lender ? [record.lender] : []),
    ...(record.typeOfTrustInterest?.values || [])
  ]

  const lstPendingContacts = pendingContacts.filter(pc =>
    allLinkedContactIds.includes(pc._id)
  )

  const lstPendingDocuments = pendingDocuments.filter(pd =>
    record.documents?.includes(pd._id)
  )

  useEffect(() => {
    const fetchChartData = async () => {
      try {
        const valuationRecords = await getRecords(
          userId,
          'assetsLiabilitiesValuations',
          masterKey,
          { startkey: assetLiabilityId, endkey: `${assetLiabilityId}\ufff0` }
        )
        const chartData = valuationRecords
          .sort(
            (a, b) => moment(b.validFrom).unix() - moment(a.validFrom).unix()
          )
          .map(record => ({
            ...record,
            validFromUnix: moment(record.validFrom).valueOf()
          }))
        const ticks = getTicks('dataMin', chartData, 'allTime', true)
        setTicks(ticks)
        setChartData(chartData)
        setFilteredChartData(
          chartData.concat(
            ticks.map(tick => {
              return { validFromUnix: tick }
            })
          )
        )
      } catch (err) {
        onError(err)
      }
    }
    if (masterKey) {
      fetchChartData()
    }
  }, [userId, assetLiabilityId, masterKey])

  useEffect(() => {
    if (tourRun) {
      if (tourStepIndex === TOUR_STEP_INDEXES.LINK_EXISTED_DOCS) {
        prevTourStep()
      } else if (tourStepIndex === TOUR_STEP_INDEXES.CREATED_ASSET) {
        nextTourStep()
      }
    }
  }, [tourStepIndex, nextTourStep, prevTourStep, tourRun])

  const maxValueYAxis = Math.max(...chartData.map(cd => cd[chartKey]))

  const linkDocument = async (documentId, isRemoved = false) => {
    try {
      await linkDocumentToAssetLiability(
        userId,
        record._id,
        documentId,
        masterKey,
        isRemoved,
        record.status
      )
      localStorage.setItem('NotReload', true)
      addS3Change({
        variables: {
          message: 'assetsLiabilities, pendingAssetsLiabilities, documents',
          userId: userId
        }
      })
      message.success(
        <Trans
          i18nKey="SUCCESSFULLY_LINKED_OR_UNLINKED_DOCUMENT"
          values={{ linkedOrUnlinked: isRemoved ? t('UNLINKED') : t('LINKED') }}
        ></Trans>
      )
    } catch (e) {
      onError(e)
    }
  }

  const handleLinkAssetsLiabilities = async assetsLiabilitiesIds => {
    try {
      await linkAssetsLiabilities(
        userId,
        record._id,
        assetsLiabilitiesIds,
        masterKey
      )
      localStorage.setItem('NotReload', true)
      addS3Change({
        variables: {
          message: 'assetsLiabilities',
          userId: userId
        }
      })
      message.success(t('SUCCESSFULLY_LINKED_ASSET_LIABILITY'))
    } catch (e) {
      onError(e)
    }
  }

  const handleUnlinkAssetLiability = async linkedAssetLiabilityId => {
    try {
      await unlinkAssetLiability(
        userId,
        record._id,
        linkedAssetLiabilityId,
        masterKey
      )
      localStorage.setItem('NotReload', true)
      addS3Change({
        variables: {
          message: 'assetsLiabilities',
          userId: userId
        }
      })
      message.success(t('SUCCESSFULLY_UNLINKED_ASSET_LIABILITY'))
    } catch (e) {
      onError(e)
    }
  }

  const handleUnlinkContact = async contactId => {
    try {
      await unlinkContact(
        userId,
        record._id,
        contactId,
        masterKey,
        record.status
      )

      setLinkedContacts(linkedContacts.filter(doc => doc._id !== contactId))

      dispatch(fetchPendingAssetsLiabilities(userId, masterKey))
      localStorage.setItem('NotReload', true)
      addS3Change({
        variables: {
          message: 'assetsLiabilities, contacts',
          userId: userId
        }
      })
      message.success(t('SUCCESSFULLY_UNLINKED_CONTACT'))
    } catch (e) {
      message.error(t('FAILED_TO_UNLINK_CONTACT'))
      onError(e)
    }
  }

  const handleLinkContacts = async contactIds => {
    try {
      await linkContacts(userId, assetLiabilityId, contactIds, masterKey)
      localStorage.setItem('NotReload', true)
      addS3Change({
        variables: {
          message: 'assetsLiabilities, contacts',
          userId: userId
        }
      })
      message.success(t('SUCCESSFULLY_LINKED_CONTACT'))
    } catch (e) {
      message.error(t('FAILED_TO_LINK_CONTACT'))
      onError(e)
    }
  }

  const documentColumns = [
    {
      key: 'name',
      width: 250,
      title: <span className="dragHandler">{t('NAME')}</span>,
      render: (text, record) => (
        <span className="icon-name">
          <Icon type="file-text" style={{ fontSize: '20px' }} />
          <span className="name">{record.fileName}</span>
        </span>
      ),
      sorter: (a, b) => a.fileName.localeCompare(b.fileName)
    },
    {
      key: 'detail',
      title: <span className="dragHandler">{t('DETAIL')}</span>,
      width: 250,
      render: (text, record) =>
        record.type === 'folder' ? (
          <span>
            <Trans
              i18nKey="FOLDERS_COUNT_AND_FILES_COUNT"
              values={{
                foldersCount: record.foldersCount,
                filesCount: record.filesCount
              }}
            ></Trans>
          </span>
        ) : (
          <span>{formatBytes(record.file[0].size)}</span>
        )
    },
    {
      key: 'actions',
      width: 250,
      render: (text, record) =>
        !record.status && (
          <div
            style={{ textAlign: 'right' }}
            onClick={e => e.stopPropagation()}
          >
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item>
                    <Button
                      type="link"
                      icon="delete"
                      onClick={() => linkDocument(record._id, true)}
                    >
                      {t('UNLINK')}
                    </Button>
                  </Menu.Item>
                </Menu>
              }
              placement="bottomRight"
              trigger={['click']}
            >
              <Icon type="ellipsis" />
            </Dropdown>
          </div>
        )
    }
  ]

  const assetsColumns = [
    {
      key: 'title',
      width: 200,
      title: <span className="dragHandler">{t('ASSET_NAME')}</span>,
      dataIndex: 'title',
      sorter: (a, b) => a.title.localeCompare(b.title),
      render: (text, record) => <AssetLiabilityTitleWithIcon record={record} />
    },
    {
      key: 'percentageOwnership',
      width: 200,
      dataIndex: 'percentageOwnership',
      title: <span className="dragHandler">{t('PERCENTAGE_OWNERSHIP')}</span>
    },
    {
      key: 'purchaseDate',
      width: 200,
      dataIndex: 'purchaseDate',
      title: <span className="dragHandler">{t('ASSET_PURCHASE_DATE')}</span>,
      render: renderDate
    },
    {
      key: 'valuationDate',
      dataIndex: 'valuationDate',
      width: 200,
      title: <span className="dragHandler">{t('VALUATION_DATE')}</span>,
      render: renderDate
    },
    {
      key: 'actions',
      width: 200,
      render: (text, record) => (
        <div style={{ textAlign: 'right' }} onClick={e => e.stopPropagation()}>
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item>
                  <Button
                    type="link"
                    icon="delete"
                    onClick={() => handleUnlinkAssetLiability(record._id)}
                  >
                    {t('UNLINK')}
                  </Button>
                </Menu.Item>
              </Menu>
            }
            placement="bottomRight"
            trigger={['click']}
          >
            <Icon type="ellipsis" />
          </Dropdown>
        </div>
      )
    }
  ]

  const liabilitiesColumns = [
    {
      key: 'title',
      width: 200,
      title: <span className="dragHandler">{t('LIABILITY_NAME')}</span>,
      dataIndex: 'title',
      sorter: (a, b) => a.title.localeCompare(b.title),
      render: (text, record) => <AssetLiabilityTitleWithIcon record={record} />
    },
    {
      key: 'percentageOwnership',
      width: 150,
      dataIndex: 'percentageOwnership',
      title: <span className="dragHandler">{t('PERCENTAGE_OWNERSHIP')}</span>
    },
    {
      key: 'startDate',
      width: 150,
      dataIndex: 'startDate',
      title: <span className="dragHandler">{t('LIABILITY_START_DATE')}</span>,
      render: renderDate
    },
    {
      key: 'maturityDate',
      width: 150,
      dataIndex: 'maturityDate',
      title: (
        <span className="dragHandler">{t('LIABILITY_MATURITY_DATE')}</span>
      ),
      render: renderDate
    },
    {
      key: 'valuationDate',
      width: 150,
      dataIndex: 'valuationDate',
      title: <span className="dragHandler">{t('VALUATION_DATE')}</span>,
      render: renderDate
    },
    {
      key: 'actions',
      width: 150,
      render: (text, record) => (
        <div style={{ textAlign: 'right' }} onClick={e => e.stopPropagation()}>
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item>
                  <Button
                    type="link"
                    icon="delete"
                    onClick={() => handleUnlinkAssetLiability(record._id)}
                  >
                    {t('UNLINK')}
                  </Button>
                </Menu.Item>
              </Menu>
            }
            placement="bottomRight"
            trigger={['click']}
          >
            <Icon type="ellipsis" />
          </Dropdown>
        </div>
      )
    }
  ]

  const showDetailEventModal = record => {
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
    }, 1500)
    setEventDetails(record)
    setIsDetailEventModal(true)
  }

  const showPasswordDetails = record => {
    setPasswordDetails(record)
    setPasswordDetailsVisible(true)
  }

  const handleUnlinkEvent = async event => {
    Modal.confirm({
      title: t('UNLINK_CONFIRM'),
      content: <>{t('UNLINK_EVENT_CONFIRM')}</>,
      async onOk() {
        try {
          await unlinkEventFromAL(
            userId,
            record._id,
            event,
            masterKey,
            record.status
          )

          setLinkedEvents(linkedEvents.filter(doc => doc._id !== event._id))
          localStorage.setItem('NotReload', true)
          addS3Change({
            variables: {
              message: 'assetsLiabilities, events',
              userId: userId
            }
          })
          message.success(t('SUCCESSFULLY_UNLINKED_EVENT'))
        } catch (e) {
          message.error(t('FAILED_TO_UNLINK_EVENT'))
          onError(e)
        }
      },
      onCancel() {}
    })
  }

  const eventsColumns = [
    {
      key: 'description',
      width: 250,
      dataIndex: 'description',
      title: <span className="dragHandler"> {t('DESCRIPTION')}</span>,
      render: (text, record) => (
        <span
          className="item-name"
          onClick={() => showDetailEventModal(record)}
        >
          {record.description}
        </span>
      )
    },
    {
      key: 'date',
      dataIndex: 'date',
      width: 250,
      title: <span className="dragHandler"> {t('DATE')} </span>,
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
      defaultSortOrder: 'descend',
      render: (text, record) => (
        <span>{moment(record.date).format(DATE_FORMAT)}</span>
      )
    },
    {
      key: 'actions',
      width: 250,
      render: (text, record) => (
        <div style={{ textAlign: 'right' }} onClick={e => e.stopPropagation()}>
          <AntToolTip
            title={t('UNLINK_EVENT')}
            arrowPointAtCenter
            placement="topRight"
          >
            <Icon
              onClick={() => handleUnlinkEvent(record)}
              theme="twoTone"
              twoToneColor="#eb4444"
              type="delete"
            />
          </AntToolTip>
        </div>
      )
    }
  ]

  const passwordsColumns = [
    {
      key: 'title',
      width: 250,
      dataIndex: 'title',
      title: <span className="dragHandler"> {t('TITLE')}</span>,
      render: (text, record) => (
        <span
          className="item-name"
          onClick={() => showPasswordDetails(record)} //show detail password modal
        >
          {record.title}
        </span>
      )
    },
    {
      key: 'username',
      width: 250,
      dataIndex: 'username',
      title: <span className="dragHandler"> {t('USERNAME')}</span>,
      render: (text, record) => <span>{record.username}</span>
    },
    {
      key: 'actions',
      width: 250,
      render: (text, record) => (
        <div style={{ textAlign: 'right' }} onClick={e => e.stopPropagation()}>
          <AntToolTip
            title={t('UNLINK_PASSWORD')}
            arrowPointAtCenter
            placement="topRight"
          >
            <Icon
              onClick={() => handleUnlinkPassword(record)}
              theme="twoTone"
              twoToneColor="#eb4444"
              type="delete"
            />
          </AntToolTip>
        </div>
      )
    }
  ]

  const handleUnlinkPassword = async password => {
    Modal.confirm({
      title: t('UNLINK_CONFIRM'),
      content: <>{t('UNLINK_PASSWORD_CONFIRM')}</>,
      async onOk() {
        try {
          await unlinkPassword(
            userId,
            record._id,
            password,
            masterKey,
            record.status
          )

          setLinkedPasswords(
            linkedPasswords.filter(doc => doc._id !== password._id)
          )

          message.success(t('SUCCESSFULLY_UNLINKED_PASSWORD'))
        } catch (e) {
          message.error(t('FAILED_TO_UNLINK_PASSWORD'))
          onError(e)
        }
      },
      onCancel() {}
    })
  }

  const handleTimeRangeChange = value => {
    const selectedRange = timeRanges.find(range => range.key === value)
    const chartFrom = selectedRange.subtract
      ? moment().startOf('day').subtract(selectedRange.subtract).valueOf()
      : 'dataMin'
    setChartFrom(chartFrom)

    const ticks = getTicks(chartFrom, chartData, value, true)
    setTicks(ticks)

    setFilteredChartData(
      chartData
        .filter(
          record => chartFrom === 'dataMin' || record.validFromUnix >= chartFrom
        )
        .concat(
          ticks.map(tick => {
            return { validFromUnix: tick }
          })
        )
    )
  }

  const DetailsComponent = () => (
    <>
      <AssetLiabilityInformation
        record={record}
        extra={
          !isReadonly &&
          !record.status &&
          isLgUp && (
            <Button
              className="tour-edit-asset-button"
              type="link"
              icon="edit"
              onClick={() =>
                history.push(`/assets-liabilities/${assetLiabilityId}/edit`)
              }
            >
              {t('EDIT')}
            </Button>
          )
        }
      />
      <Divider />
      <ContactLinks
        linkedContacts={linkedContacts}
        handleUnlinkContact={handleUnlinkContact}
        handleLinkContacts={handleLinkContacts}
        isReadonly={isReadonly}
      />
    </>
  )

  const getChartLeftPadding = chartKey => {
    const charactersCount =
      chartKey !== 'quantity'
        ? currencyChartFormat(maxValueYAxis).length
        : maxValueYAxis.toString().length
    const multiplier =
      charactersCount < 7
        ? 2
        : charactersCount < 11
        ? 3.3
        : charactersCount < 15
        ? 4.5
        : charactersCount < 19
        ? 5.5
        : 6
    return charactersCount * multiplier
  }

  return (
    <>
      {!!lstPendingContacts?.length && (
        <Alert
          type="warning"
          style={{ margin: 20 }}
          showIcon
          message={
            <>
              <Span1>
                {t('PENDING_CONTACT_WARNING_COUNT', {
                  type: CORE_TYPES.ASSET ? t('ASSET') : t('LIABILITY'),
                  contactCount: lstPendingContacts.length
                })}
                :{' '}
                <b>
                  {lstPendingContacts
                    .map(
                      lc =>
                        `${lc.name}${
                          lc.emails?.length ? ` (${lc.emails[0].value})` : ''
                        }`
                    )
                    .join(', ')}
                </b>
              </Span1>

              <Button
                type="link"
                onClick={() => history.push('/contacts?activeKey=Pending')}
              >
                {' '}
                {t('VIEW_DETAILS')}
              </Button>
            </>
          }
        />
      )}
      {!!lstPendingDocuments?.length && (
        <Alert
          type="warning"
          style={{ margin: 20 }}
          showIcon
          message={
            <>
              <Span1>
                {t('PENDING_DOCUMENT_WARNING_COUNT', {
                  type: CORE_TYPES.ASSET ? t('ASSET') : t('LIABILITY'),
                  documentCount: lstPendingDocuments.length
                })}
                :{' '}
                <b>{lstPendingDocuments.map(lpd => lpd.fileName).join(', ')}</b>
              </Span1>

              <Button
                type="link"
                onClick={() => history.push('/files/pending')}
              >
                {' '}
                {t('VIEW_DETAILS')}
              </Button>
            </>
          }
        />
      )}
      <Layout style={{ height: '100%' }}>
        <Content
          style={{
            backgroundColor: '#fff',
            padding: '0 20px 20px',
            overflow: `${!tourRun && 'hidden'}`
          }}
        >
          <Spin spinning={isLoading}>
            {error ? (
              <Alert type="error" message={error} showIcon />
            ) : (
              <>
                <Breadcrumb
                  separator=">"
                  style={{
                    paddingTop: 20,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  <Breadcrumb.Item
                    href=""
                    onClick={e => {
                      e.preventDefault()
                      history.push('/')
                    }}
                  >
                    <H4 display="inline" style={{ color: '#9AA0B3' }}>
                      {t('REGISTRY')}
                    </H4>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <H4 display="inline">{record.title}</H4>
                  </Breadcrumb.Item>
                </Breadcrumb>
                <Tabs onChange={setLinkType} className="custom-tabs">
                  {/* {!isReadonly && ( */}
                  <TabPane
                    tab={t('OVERVIEW')}
                    key="Overview"
                    className="tour-asset-overview"
                  >
                    <PageHeader
                      title={<H4 display="inline-block">{t('OVERVIEW')}</H4>}
                      extra={
                        <>
                          <Button
                            onClick={() => {
                              try {
                                exportToPng(
                                  chartRef.current,
                                  `${record.title} - ${chartKeyTitleMap[chartKey]}`
                                )
                              } catch (err) {
                                message.error(t('FAILED_TO_EXPORT_TO_PNG'))
                              }
                            }}
                          >
                            {t('EXPORT_TO_PNG')}
                          </Button>
                          {!isLgUp && !isReadonly && (
                            <Button
                              className="tour-edit-asset-button"
                              type="link"
                              icon="edit"
                              onClick={() =>
                                history.push(
                                  `/assets-liabilities/${assetLiabilityId}/edit`
                                )
                              }
                            >
                              {t('EDIT')}
                            </Button>
                          )}
                        </>
                      }
                      style={{ padding: '0 0 20px' }}
                    />
                    <div style={{ paddingBottom: 30 }}>
                      <Row gutter={20}>
                        <Col xxl={12}>
                          <FormItem label={t('DATA')}>
                            <Radio.Group
                              onChange={e => setChartKey(e.target.value)}
                              value={chartKey}
                            >
                              {record.type === CORE_TYPES.ASSET && (
                                <>
                                  <Radio value="valuationInAssetCurrency">
                                    {t('VALUATION_ASSET_CURRENCY')}
                                  </Radio>
                                  <Radio value="valuationInBaseCurrency">
                                    {t('VALUATION_BASE_CURRENCY')}
                                  </Radio>
                                </>
                              )}
                              {record.type === CORE_TYPES.LIABILITY && (
                                <>
                                  <Radio value="outstandingValueInLiabilityCurrency">
                                    {t('OUTSTANDING_VALUE_LIABILITY_CURRENCY')}
                                  </Radio>
                                  <Radio value="outstandingValueInBaseCurrency">
                                    {t('OUTSTANDING_VALUE_BASE_CURRENCY')}
                                  </Radio>
                                </>
                              )}
                              <Radio value="quantity">{t('QUANTITY')}</Radio>
                            </Radio.Group>
                          </FormItem>
                        </Col>
                        <Col xxl={12}>
                          <FormItem label={t('TIME_RANGE')}>
                            {isSmUp ? (
                              <Radio.Group
                                onChange={e =>
                                  handleTimeRangeChange(e.target.value)
                                }
                                defaultValue="allTime"
                              >
                                {timeRanges.map(option => (
                                  <Radio key={option.key} value={option.key}>
                                    {option.title}
                                  </Radio>
                                ))}
                              </Radio.Group>
                            ) : (
                              <Select
                                onChange={handleTimeRangeChange}
                                defaultValue="allTime"
                                style={{ width: 90 }}
                              >
                                {timeRanges.map(option => (
                                  <Select.Option
                                    key={option.key}
                                    value={option.key}
                                  >
                                    {option.title}
                                  </Select.Option>
                                ))}
                              </Select>
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                    </div>

                    <div>
                      <ResponsiveContainer height={isSmUp ? 420 : 210}>
                        <ScatterChart
                          ref={chartRef}
                          margin={{
                            top: 20,
                            right: 5,
                            bottom: 5,
                            left: getChartLeftPadding(chartKey)
                          }}
                        >
                          <XAxis
                            dataKey="validFromUnix"
                            domain={[chartFrom, 'dataMax']}
                            name="Valid from"
                            ticks={ticks}
                            tickCount={ticks?.length}
                            tickFormatter={unixTime => {
                              return moment(unixTime).format('DD/MM/YYYY')
                            }}
                            type="number"
                            padding={{ right: 20 }}
                            label={{
                              value: 'Time',
                              position: 'insideBottomRight',
                              offset: -5
                            }}
                          />
                          <YAxis
                            dataKey={chartKey}
                            tickFormatter={
                              chartKey !== 'quantity'
                                ? currencyChartFormat
                                : undefined
                            }
                            domain={['dataMin', 'dataMax']}
                            allowDecimals={false}
                            name={chartKeyTitleMap[chartKey]}
                            label={{
                              value: chartKeyUnitMap[chartKey],
                              position: 'top'
                            }}
                            padding={{ top: 20, bottom: 20 }}
                          />
                          <Tooltip
                            content={<CustomTooltip />}
                            cursor={{ strokeDasharray: '5 5' }}
                          />
                          <Scatter
                            data={filteredChartData}
                            fill={theme.primary}
                            line={{ stroke: theme.dark2 }}
                            lineType="joint"
                            name={chartKeyTitleMap[chartKey]}
                            shape={<CustomizedShape />}
                          />
                        </ScatterChart>
                      </ResponsiveContainer>
                    </div>
                  </TabPane>
                  {/* )} */}
                  {!isLgUp && (
                    <TabPane tab={t('DETAILS')} key={StringResources.DETAILS}>
                      <div style={{ paddingBottom: 80 }}>
                        <DetailsComponent />
                      </div>
                    </TabPane>
                  )}
                  <TabPane tab={t('DOCUMENTS')} key={StringResources.DOCUMENTS}>
                    <PageHeader
                      title={
                        <H4 display="inline-block">{t('FILES_AND_FOLDERS')}</H4>
                      }
                      subTitle={
                        <H5 display="inline-block" color={theme.dark2}>
                          {filteredLinkedDocuments.length}
                        </H5>
                      }
                      style={{ padding: '0 0 20px' }}
                      extra={
                        <div className="flex-container-gap">
                          <Input.Search
                            placeholder={t('SEARCH_FILE_OR_FOLDER_NAME')}
                            allowClear
                            onSearch={value => {
                              const filteredDocuments = search(
                                linkedDocuments,
                                ['fileName'],
                                value
                              )
                              setFilteredLinkedDocuments(filteredDocuments)
                            }}
                            style={{ width: 320 }}
                          />
                          {!isReadonly && (
                            <Button
                              type="primary"
                              onClick={() => setDocumentsLinksVisible(true)}
                            >
                              {t('LINK_NEW_FILE')}
                            </Button>
                          )}
                        </div>
                      }
                    />
                    <CustomTable
                      dataSource={filteredLinkedDocuments}
                      scroll={{ x: true }}
                      columns={
                        isReadonly
                          ? documentColumns.filter(col => col.key !== 'actions')
                          : documentColumns
                      }
                      rowKey="_id"
                      onRow={record => ({
                        onClick: () => {
                          if (
                            !!privateFolder?.password &&
                            record.path.slice(0, privateFolder?.path.length) ===
                              privateFolder?.path
                          ) {
                            if (isLockPrivateFolder) {
                              Modal.warning({
                                title: t('WARNING_MSG'),
                                content: t('LOCK_PRIVATE_FOLDER_MSG')
                              })
                            } else if (
                              !!privateFolderPassword &&
                              localStorage.getItem('privateFolderPassword') ===
                                privateFolderPassword
                            ) {
                              setSelectedDoc({
                                id: record._id,
                                name: record.fileName,
                                fileId: record.fileId,
                                sub: record.sub,
                                status: record.status
                              })
                              setFileDetailsVisible(true)
                            } else {
                              setIsShowLinkedDocument(true)
                              setDocItem(record)
                              setPasswordFolderModalVisible(true)
                            }
                          } else {
                            setSelectedDoc({
                              id: record._id,
                              name: record.fileName,
                              fileId: record.fileId,
                              sub: record.sub,
                              status: record.status
                            })
                            setFileDetailsVisible(true)
                          }
                        }
                      })}
                    />
                  </TabPane>
                  <TabPane tab={t('EVENTS')} key={StringResources.EVENTS}>
                    <PageHeader
                      title={<H4 display="inline-block">{t('EVENTS')}</H4>}
                      style={{ padding: '0 0 20px' }}
                    />
                    <CustomTable
                      dataSource={linkedEvents}
                      scroll={{ x: true }}
                      columns={eventsColumns}
                      rowKey="_id"
                    />
                  </TabPane>
                  <TabPane tab={t('PASSWORDS')} key={StringResources.PASSWORDS}>
                    <PageHeader
                      title={<H4 display="inline-block">{t('PASSWORDS')}</H4>}
                      style={{ padding: '0 0 20px' }}
                    />
                    <CustomTable
                      dataSource={linkedPasswords}
                      scroll={{ x: true }}
                      columns={passwordsColumns}
                      rowKey="_id"
                    />
                  </TabPane>
                  <TabPane tab={t('ASSET_LINKS')} key={CORE_TYPES.ASSET}>
                    <PageHeader
                      title={<H4 display="inline-block">{t('ASSET_LINKS')}</H4>}
                      subTitle={
                        <H5 display="inline-block" color={theme.dark2}>
                          {filteredLinkedAssets.length}
                        </H5>
                      }
                      style={{ padding: '0 0 20px' }}
                      extra={
                        <div className="flex-container-gap">
                          <Input.Search
                            placeholder={t('SEARCH_ASSETS')}
                            allowClear
                            onSearch={value => {
                              const filteredAssets = search(
                                linkedAssets,
                                ['title'],
                                value
                              )
                              setFilteredLinkedAssets(filteredAssets)
                            }}
                            style={{ width: 320 }}
                          />
                          {!isReadonly && (
                            <Button
                              type="primary"
                              onClick={() =>
                                setAssetLiabilityLinksVisible(true)
                              }
                            >
                              {t('LINK_ASSETS')}
                            </Button>
                          )}
                        </div>
                      }
                    />
                    <CustomTable
                      dataSource={filteredLinkedAssets}
                      scroll={{ x: true }}
                      columns={
                        isReadonly
                          ? assetsColumns.filter(col => col.key !== 'actions')
                          : assetsColumns
                      }
                      rowKey="_id"
                      onRow={record => ({
                        onClick: () => {
                          const userIdParam = isReadonly ? `/${userId}` : ''
                          history.push(
                            `/assets-liabilities/${
                              record._id
                            }${userIdParam}?isActived=${
                              record.status ? 'false' : 'true'
                            }`
                          )
                        }
                      })}
                    />
                  </TabPane>
                  <TabPane
                    tab={t('LIABILITY_LINKS')}
                    key={CORE_TYPES.LIABILITY}
                  >
                    <PageHeader
                      title={
                        <H4 display="inline-block">{t('LIABILITY_LINKS')}</H4>
                      }
                      subTitle={
                        <H5 display="inline-block" color={theme.dark2}>
                          {filteredLinkedLiabilities.length}
                        </H5>
                      }
                      style={{ padding: '0 0 20px' }}
                      extra={
                        <div className="flex-container-gap">
                          <Input.Search
                            placeholder={t('SEARCH_LIABILITIES')}
                            allowClear
                            onSearch={value => {
                              const filteredLiabilities = search(
                                linkedLiabilities,
                                ['title'],
                                value
                              )
                              setFilteredLinkedLiabilities(filteredLiabilities)
                            }}
                            style={{ width: 320 }}
                          />
                          {!isReadonly && (
                            <Button
                              type="primary"
                              onClick={() =>
                                setAssetLiabilityLinksVisible(true)
                              }
                            >
                              {t('LINK_LIABILITIES')}
                            </Button>
                          )}
                        </div>
                      }
                    />
                    <CustomTable
                      dataSource={filteredLinkedLiabilities}
                      scroll={{ x: true }}
                      columns={
                        isReadonly
                          ? liabilitiesColumns.filter(
                              col => col.key !== 'actions'
                            )
                          : liabilitiesColumns
                      }
                      rowKey="_id"
                      onRow={record => ({
                        onClick: () => {
                          const userIdParam = isReadonly ? `/${userId}` : ''
                          history.push(
                            `/assets-liabilities/${
                              record._id
                            }${userIdParam}?isActived=${
                              record.status ? 'false' : 'true'
                            }`
                          )
                        }
                      })}
                    />
                  </TabPane>
                </Tabs>
              </>
            )}
          </Spin>
        </Content>
        {!error && isLgUp && (
          <Sider
            width={350}
            theme="light"
            style={{
              background: 'rgba(242, 243, 247, 0.5)',
              padding: '20px 20px 80px'
            }}
          >
            <DetailsComponent />
          </Sider>
        )}
        <DocumentsLinksModal
          visible={documentsLinksVisible}
          setVisible={setDocumentsLinksVisible}
          record={record}
          linkDocument={linkDocument}
          filteredDocIds={record.documents || []}
        />
        <AssetsLiabilitiesLinksModal
          visible={assetLiabilityLinksVisible}
          setVisible={setAssetLiabilityLinksVisible}
          assetsLiabilities={
            linkType
              ? activeAssetsLiabilities.filter(al => al.type === linkType)
              : activeAssetsLiabilities
          }
          record={record}
          handleLinkAssetsLiabilities={handleLinkAssetsLiabilities}
        />
        <FileDetails
          visible={fileDetailsVisible}
          setVisible={setFileDetailsVisible}
          docItem={selectedDoc}
        />
        <EventDetails
          isLoading={isLoading}
          visible={isDetailEventModal}
          setVisible={setIsDetailEventModal}
          record={eventDetails}
          isViewMode={true}
        />
        <PasswordFolderModal
          visible={passwordFolderModalVisible}
          setVisible={setPasswordFolderModalVisible}
          isShowLinkedDocument={isShowLinkedDocument}
          isDeletePrivateFolder={false}
          docItem={docItem}
        />
        <PasswordDetails
          record={passwordDetails}
          visible={passwordDetailsVisible}
          setVisible={setPasswordDetailsVisible}
        />
      </Layout>
    </>
  )
}

export default withRouter(AssetLiabilityDetails)
