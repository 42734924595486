import React, { useState, useContext, useEffect, useRef } from 'react'
import {
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
  Dot
} from 'recharts'
import moment from 'moment'
import {
  currencyFormat,
  currencyChartFormat,
  getTicks
} from '../../share/helpers'
import { ThemeContext } from 'styled-components'
import { Radio, PageHeader, Select } from 'antd'
import { useSelector } from 'react-redux'
import { H4 } from '../override/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTranslation } from 'react-i18next'

const CustomTooltip = ({ active, payload, label }) => {
  if (active) {
    return (
      <div className="custom-tooltip">
        <p>{`${payload[0].name} : ${moment(payload[0].value).format(
          'DD/MM/YYYY'
        )}`}</p>
        <p>{`${payload[1].name} : ${currencyFormat(payload[1].value)}`}</p>
      </div>
    )
  }

  return null
}

const CustomizedShape = props => {
  const { cx, cy, fill, updatedByRate } = props

  return (
    <g>
      <Dot cx={cx} cy={cy} r={updatedByRate ? 3 : 5} fill={fill} />
    </g>
  )
}

// TODO: refactor the duplicate code
export default function NetworthChart({ chartData }) {
  const [chartFrom, setChartFrom] = useState('')
  const [filteredChartData, setFilteredChartData] = useState([])
  const [ticks, setTicks] = useState([])
  const theme = useContext(ThemeContext)
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'))
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'))
  const { baseCurrency } = useSelector(state => state.settings)
  const { t } = useTranslation()

  useEffect(() => {
    const ticks = getTicks('dataMin', chartData, 'allTime')

    setFilteredChartData(
      chartData.concat(
        ticks.map(tick => {
          return { time: tick }
        })
      )
    )
  }, [chartData])

  const chartRef = useRef()

  const timeRanges = [
    {
      key: 'allTime',
      title: t('ALL_TIME')
    },
    {
      key: '1W',
      title: 1 + t('W_AS_WEEK'),
      subtract: { weeks: 1 }
    },
    {
      key: '1M',
      title: 1 + t('M_AS_MONTH'),
      subtract: { months: 1 }
    },
    {
      key: '3M',
      title: 3 + t('M_AS_MONTH'),
      subtract: { months: 3 }
    },
    {
      key: '6M',
      title: 6 + t('M_AS_MONTH'),
      subtract: { months: 6 }
    },
    {
      key: '1Y',
      title: 1 + t('Y_AS_YEAR'),
      subtract: { years: 1 }
    },
    {
      key: '3Y',
      title: 3 + t('Y_AS_YEAR'),
      subtract: { years: 3 }
    },
    {
      key: '5Y',
      title: 5 + t('Y_AS_YEAR'),
      subtract: { years: 5 }
    }
  ]

  const handleTimeRangeChange = value => {
    const selectedRange = timeRanges.find(range => range.key === value)
    const chartFrom = selectedRange.subtract
      ? moment().startOf('day').subtract(selectedRange.subtract).valueOf()
      : 'dataMin'

    const ticks = getTicks(chartFrom, chartData, value)
    setTicks(ticks)

    setChartFrom(chartFrom)
    setFilteredChartData(
      chartData
        .filter(record => chartFrom === 'dataMin' || record.time >= chartFrom)
        .concat(
          ticks.map(tick => {
            return { time: tick }
          })
        )
    )
  }

  const maxNetWorthValue = Math.max(...chartData.map(cd => cd.netWorth))

  const getChartLeftPadding = () => {
    const charactersCount = currencyChartFormat(maxNetWorthValue).length
    const multiplier =
      charactersCount < 7
        ? 2
        : charactersCount < 11
        ? 3.3
        : charactersCount < 15
        ? 4.5
        : charactersCount < 19
        ? 5.5
        : 6
    return charactersCount * multiplier
  }

  return (
    <>
      <PageHeader
        title={
          <>
            <H4 style={{ minWidth: 95 }} display="inline-block">
              {t('NET_WORTH')}:
            </H4>
            {isLgUp ? (
              <Radio.Group
                onChange={e => handleTimeRangeChange(e.target.value)}
                defaultValue="allTime"
              >
                {timeRanges.map(option => (
                  <Radio key={option.key} value={option.key}>
                    {option.title}
                  </Radio>
                ))}
              </Radio.Group>
            ) : (
              <Select
                onChange={handleTimeRangeChange}
                defaultValue="allTime"
                style={{ width: 90 }}
              >
                {timeRanges.map(option => (
                  <Select.Option key={option.key} value={option.key}>
                    {option.title}
                  </Select.Option>
                ))}
              </Select>
            )}
          </>
        }
        style={{ padding: 20 }}
      />

      <div>
        <ResponsiveContainer height={isSmUp ? 420 : 210}>
          <ScatterChart
            ref={chartRef}
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: getChartLeftPadding()
            }}
          >
            <XAxis
              dataKey="time"
              domain={[chartFrom, 'dataMax']}
              name="Time"
              ticks={ticks}
              tickCount={ticks?.length}
              tickFormatter={unixTime => {
                return moment(unixTime).format('DD/MM/YYYY')
              }}
              type="number"
              padding={{ right: 20 }}
              label={{
                value: 'Time',
                position: 'insideBottomRight',
                offset: -5
              }}
            />
            <YAxis
              dataKey="netWorth"
              name={t('NET_WORTH')}
              domain={['dataMin', 'auto']}
              allowDecimals={false}
              tickFormatter={currencyChartFormat}
              label={{
                value: baseCurrency,
                position: 'top'
              }}
              padding={{ top: 20, bottom: 20 }}
            />
            <Tooltip
              content={<CustomTooltip />}
              cursor={{ strokeDasharray: '5 5' }}
            />
            <Scatter
              data={filteredChartData}
              fill={theme.primary}
              line={{ stroke: theme.dark2 }}
              lineType="joint"
              name={t('NET_WORTH')}
              shape={<CustomizedShape />}
            />
          </ScatterChart>
        </ResponsiveContainer>
      </div>
    </>
  )
}
