import { createSlice } from '@reduxjs/toolkit'
import { getRecords } from '../../lib/pouchDb'
import { onError } from '../../lib/sentry'

const initialState = {
  linkedEvents: [],
  deletedLinkedEvents: [],
  activeLinkedEvents: [],
  isLoading: false,
  error: null
}

const linkedEvents = createSlice({
  name: 'linkedEvents',
  initialState,
  reducers: {
    getLinkedEventsStart(state) {
      state.isLoading = true
      state.error = null
    },
    getLinkedEventsSuccess(state, action) {
      const { linkedEvents } = action.payload
      state.linkedEvents = linkedEvents
      state.deletedLinkedEvents = linkedEvents.filter(item => item.deleted)
      state.activeLinkedEvents = linkedEvents.filter(item => !item.deleted)
      state.isLoading = false
      state.error = null
    },
    getLinkedEventsFailure(state, action) {
      state.isLoading = false
      state.error = action.payload
    }
  }
})

export const {
  getLinkedEventsStart,
  getLinkedEventsSuccess,
  getLinkedEventsFailure
} = linkedEvents.actions

export default linkedEvents.reducer

export const fetchLinkedEvents = (userId, masterKey) => async dispatch => {
  try {
    dispatch(getLinkedEventsStart())
    const linkedEvents = await getRecords(userId, 'events', masterKey)
    dispatch(getLinkedEventsSuccess({ linkedEvents }))
  } catch (err) {
    onError(err)
    dispatch(getLinkedEventsFailure(err.toString()))
  }
}
