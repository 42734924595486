import React, { useState, useRef, useContext, useEffect } from 'react'
import { Modal, Alert, Spin, message } from 'antd'
import UserDetailForm from '../settings/UserDetailForm'
import { useSelector } from 'react-redux'
import NodeRSA from 'node-rsa'
import { s3Put, s3Get } from '../../lib/awsSDK'
import { randomBytes } from 'crypto'
import { encryptFilePromise, decryptFilePromise } from '../../lib/crypto'
import { AES } from 'crypto-js'
import AuthContext from '../../contexts/AuthContext'
import VaultContext from '../../contexts/VaultContext'
import { getExternalUserAttributes, getFileContent } from './../../share/helpers'
import api from '../../lib/api'
import { getUserData, getUserAttributeValue } from '../../lib/cognito'
import { onError } from '../../lib/sentry'
import { useDispatch } from 'react-redux'
import { fetchUser } from '../../features/user/userSlice'
import { useTranslation } from 'react-i18next'
import { removeHtmlTags } from '../../share/helpers'
const utf8 = require('utf8')

const UpdateUserDetailsModal = props => {
  const { visible, setVisible, isPendingUpdateUserDetails } = props
  const { deputies } = useSelector(state => state.deputies)
  const { user } = useContext(AuthContext)
  const { masterKey } = useContext(VaultContext)
  const [addressProofData, setAddressProofData] = useState({})
  const [idPassportData, setIdPassportData] = useState({})
  const [userDetails, setUserDetails] = useState({})
  const [isSaving, setIsSaving] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [errMsg, setErrMsg] = useState('')

  const userDetailFormRef = useRef()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const externalUser = localStorage.getItem('External_User')
  const allProfessionalDeputies = deputies.filter(d => d.professionalDeputyId)

  useEffect(() => {
    const fetchUserDetails = async () => {
      setIsLoading(true)
      try {
        const userId = user.username

        const detailsRes = await s3Get(
          userId,
          `identification/${
            isPendingUpdateUserDetails ? 'pendingDetails' : 'details'
          }`,
          {},
          { responseType: 'blob' }
        )

        const data = await decryptFilePromise(detailsRes, masterKey)
        const userDetails = JSON.parse(
          utf8.decode(String.fromCharCode(...data))
        )

        if (userDetails.addressProof && userDetails.addressProofFileId) {
          const addressProofContent = await getFileContent(
            userId,
            userDetails.addressProofFileId,
            masterKey
          )

          if (addressProofContent) {
            setAddressProofData({
              id: userDetails.addressProofFileId,
              name: userDetails.addressProof,
              content: addressProofContent
            })
          }
        }

        if (userDetails.idPassport && userDetails.idPassportFileId) {
          const idPassportContent = await getFileContent(
            userId,
            userDetails.idPassportFileId,
            masterKey
          )

          if (idPassportContent) {
            setIdPassportData({
              id: userDetails.idPassportFileId,
              name: userDetails.idPassport,
              content: idPassportContent
            })
          }
        }

        setUserDetails(userDetails)
      } catch (error) {
        setErrMsg(t('FAILDED_TO_LOAD_USER_DETAILS'))
        onError(error)
      } finally {
        setIsLoading(false)
      }
    }

    if (visible && masterKey) {
      fetchUserDetails()
    }
  }, [user, visible, isPendingUpdateUserDetails, masterKey, t])

  const handleSave = () => {
    userDetailFormRef.current.validateFields(async (err, values) => {
      if (err) return

      removeHtmlTags(values)

      const keys = Object.keys(values)
      if (keys.every(key => values[key] === userDetails[key])) {
        setVisible(false)
        return
      }

      setIsSaving(true)

      const onUpdateUserDetals = async fullname => {
        const pendingProfessionalDeputies = allProfessionalDeputies.filter(
          pd => !pd.publicKey
        )

        let professionalDeputies,
          detailsFileName,
          idPassportFileName,
          addressProofFileName

        if (!pendingProfessionalDeputies.length) {
          professionalDeputies = [...allProfessionalDeputies]
          detailsFileName = 'pendingDetails'
          idPassportFileName = 'pendingIdPassport'
          addressProofFileName = 'pendingAddressProof'
        } else {
          const usersRes = await api.getUsersByEmails(
            pendingProfessionalDeputies.map(a => a.email).join(',')
          )
          professionalDeputies = [...usersRes.data]
          detailsFileName = 'details'
          idPassportFileName = 'idPassport'
          addressProofFileName = 'addressProof'
        }

        // TODO: duplicate with the code in SpecifyDeputy.js, should refactor
        let idPassportFileKeys = []
        let addressProofFileKeys = []

        await Promise.all(
          professionalDeputies.map(async deputy => {
            const { id, publicKey } = deputy
            const key = new NodeRSA()

            key.importKey(publicKey, 'public')

            let putPromises = []
            const encryptedUserDetails = key.encrypt(
              JSON.stringify(values),
              'base64'
            )

            putPromises.push(
              s3Put(
                id,
                `identification/${user.username}/${detailsFileName}`,
                encryptedUserDetails.toString()
              )
            )

            const idPassportFileKey = randomBytes(20).toString('hex')
            idPassportFileKeys.push({
              deputyId: id,
              key: key.encrypt(idPassportFileKey, 'base64')
            })

            const encryptedIdPassport = await encryptFilePromise(
              idPassportData.content,
              idPassportFileKey
            )

            putPromises.push(
              s3Put(
                id,
                `identification/${user.username}/${idPassportFileName}`,
                encryptedIdPassport
              )
            )

            if (addressProofData.content) {
              const addressProofFileKey = randomBytes(20).toString('hex')
              addressProofFileKeys.push({
                deputyId: id,
                key: key.encrypt(addressProofFileKey, 'base64')
              })

              const encryptedAddressProof = await encryptFilePromise(
                addressProofData.content,
                addressProofFileKey
              )

              putPromises.push(
                s3Put(
                  id,
                  `identification/${user.username}/${addressProofFileName}`,
                  encryptedAddressProof
                )
              )
            }

            await Promise.all(putPromises)
          })
        )
        const fileKeys = [
          {
            fileId: `identification/${user.username}/${idPassportFileName}`,
            keys: idPassportFileKeys
          }
        ]
        if (addressProofFileKeys.length) {
          fileKeys.push({
            fileId: `identification/${user.username}/${addressProofFileName}`,
            keys: addressProofFileKeys
          })
        }

        await api.saveFileKeys(user.username, JSON.stringify({ fileKeys }))

        const encryptedUserDetails = AES.encrypt(
          JSON.stringify({
            ...values,
            idPassportFileId: idPassportData.id,
            addressProofFileId: addressProofData.id
          }),
          masterKey
        ).toString()

        await s3Put(
          user.username,
          `identification/${detailsFileName}`,
          encryptedUserDetails
        )

        if (!pendingProfessionalDeputies.length) {
          await api.requestToUpdateUserDetails(
            user.username,
            JSON.stringify({ fullname })
          )
          dispatch(fetchUser(user.username))
        }

        message.success(t('SUCCESSFULLY_UPDATE_DETAILS'))
        setIsSaving(false)
        resetModal()
      }

      try {
        if (externalUser) {
          const userAttributes = getExternalUserAttributes()
          await onUpdateUserDetals(userAttributes.full_name)
        } else {
          getUserData(user, async (err, userData) => {
            if (err) {
              onError(err)
              setIsSaving(false)
              return
            }
            const fullname = getUserAttributeValue(
              userData.UserAttributes,
              'custom:full_name'
            )
            await onUpdateUserDetals(fullname)
          })
        }
      } catch (error) {
        setIsSaving(false)
        onError(err)
        message.error(t('FAILED_TO_UPDATE_DETAILS'))
      }
    })
  }

  const resetModal = () => {
    userDetailFormRef.current.resetFields()
    setAddressProofData({})
    setIdPassportData({})
    setVisible(false)
  }

  return (
    <Modal
      title={t('USER_DETAILS')}
      visible={visible}
      onCancel={resetModal}
      onOk={handleSave}
      okText={t('SAVE')}
      cancelText={t('CANCEL')}
      maskClosable={false}
      width={750}
      okButtonProps={{ loading: isSaving }}
    >
      <Spin spinning={isLoading}>
        {errMsg && (
          <Alert
            message={errMsg}
            type="error"
            closable
            afterClose={() => setErrMsg('')}
            style={{ marginBottom: 16 }}
          />
        )}
        <UserDetailForm
          ref={userDetailFormRef}
          userDetails={userDetails}
          addressProofData={addressProofData}
          setAddressProofData={setAddressProofData}
          idPassportData={idPassportData}
          setIdPassportData={setIdPassportData}
        />
      </Spin>
    </Modal>
  )
}

export default UpdateUserDetailsModal
