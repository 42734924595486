import { AES, enc, lib } from 'crypto-js'

export const encryptFile = async (
  file,
  key,
  callbackFunc,
  callbackProgress = (percent) => {}
) => {
  let encryptedChunks = []
  let count = 0
  let chunkSize
  await loadingFile(
    file,
    arrayBuffer => {
      const wordArray = lib.WordArray.create(arrayBuffer)
      const encryptedWordArray = AES.encrypt(wordArray, key)
      encryptedChunks = [...encryptedChunks, encryptedWordArray.toString()]

      chunkSize = ++count * 1024 * 1024
      let percent
      if (chunkSize < file.size) {
        percent = (chunkSize * 100) / file.size
      } else {
        percent = 100
      }
      callbackProgress(percent)
    },
    () => {
      const blob = new Blob([...encryptedChunks])
      callbackFunc(blob)
    },
    true
  )
}

export const encryptFilePromise = (file, key, callbackProgress) => {
  return new Promise((resolve, reject) => {
    encryptFile(
      file,
      key,
      encryptedFile => {
        resolve(encryptedFile)
      },
      callbackProgress
    )
  })
}

export const decryptFile = async (file, key, callbackFunc) => {
  let array = []

  await loadingFile(
    file,
    text => {
      const decryptedChunk = AES.decrypt(text, key)
      const base64 = decryptedChunk.toString(enc.Base64)
      const arrayBuffer = base64ToArrayBuffer(base64)
      array = [...array, arrayBuffer]
    },
    () => {
      const arrayBuffer = arrayBufferConcat(...array)
      callbackFunc(new Uint8Array(arrayBuffer))
    },
    false
  )
}

export const decryptFilePromise = (file, key) => {
  return new Promise((resolve, reject) => {
    decryptFile(file, key, uint8Array => {
      resolve(uint8Array)
    })
  })
}

function arrayBufferConcat(/* arraybuffers */) {
  const arrays = [].slice.call(arguments)

  if (arrays.length <= 0) {
    return new Uint8Array(0).buffer
  }

  const arrayBuffer = arrays.reduce(function (cbuf, buf, i) {
    if (i === 0) return cbuf

    const tmp = new Uint8Array(cbuf.byteLength + buf.byteLength)
    tmp.set(new Uint8Array(cbuf), 0)
    tmp.set(new Uint8Array(buf), cbuf.byteLength)

    return tmp.buffer
  }, arrays[0])

  return arrayBuffer
}

const base64ToArrayBuffer = base64 => {
  const binaryString = window.atob(base64)
  const length = binaryString.length
  let bytes = new Uint8Array(length)
  for (let i = 0; i < length; i++) {
    bytes[i] = binaryString.charCodeAt(i)
  }
  return bytes.buffer
}

const loadingFile = async (
  file,
  callbackProgress,
  callbackFinal,
  isEncrypting
) => {
  let chunkSize = isEncrypting ? 1024 * 1024 : 1398144 // size after encrypted by AES-256 of a 1MB chunk
  let offset = 0
  let size = chunkSize
  let partial
  let index = 0

  if (file.size === 0) {
    callbackFinal()
  }

  while (offset < file.size) {
    partial = file.slice(offset, offset + size)
    let reader = new FileReader()
    reader.size = chunkSize
    reader.offset = offset
    reader.index = index
    reader.onload = function (evt) {
      callbackRead_waiting(this, file, evt, callbackProgress, callbackFinal)
    }

    if (isEncrypting) {
      reader.readAsArrayBuffer(partial)
    } else {
      reader.readAsText(partial)
    }

    offset += chunkSize
    index += 1
  }
}

let lastOffset = 0
const callbackRead_waiting = (
  reader,
  file,
  evt,
  callbackProgress,
  callbackFinal
) => {
  if (lastOffset === reader.offset) {
    lastOffset = reader.offset + reader.size
    callbackProgress(evt.target.result)
    if (reader.offset + reader.size >= file.size) {
      lastOffset = 0
      callbackFinal()
    }
  } else {
    setTimeout(function () {
      callbackRead_waiting(reader, file, evt, callbackProgress, callbackFinal)
    }, 10)
  }
}
