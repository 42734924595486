export const StringResources = {
  INPUT_TOTP_CODE: 'Enter the authentication code from your Authenticator app.',
  INPUT_SMS_CODE:
    'Enter the verification code in the SMS sent to your registered phone number.',
  SETUP_MFA: 'Multi-factor authentication setup',
  SAME_PASSWORD_MSG: 'New password must be different from old password!',
  PASSWORDS_DO_NOT_MATCH_MSG: 'The passwords you entered do not match.',
  INPUT_CURRENT_PASSWORD_MSG: 'Enter your current password!',
  INPUT_NEW_PASSWORD_MSG: 'Enter your new password!',
  CONFIRM_NEW_PASSWORD_MSG: 'Please confirm your new password!',
  CHANGE_PASSWORD: 'Change password',
  CURRENT_PASSWORD: 'Current password',
  NEW_PASSWORD: 'New password',
  CONFIRM_NEW_PASSWORD: 'Confirm new password',
  CLOSE_ACCOUNT: 'Delete account',
  CONFIRM_CLOSE_ACCOUNT_MSG:
    'Are you sure you want to delete your account? All your data will be deleted and cannot be retrieved in the future.',
  SETUP_MFA_INSTRUCTION:
    'You need to set up multi-factor authentication to protect your vaultbox.',
  INPUT_PASSWORD_OR_EMAIL_MSG: 'Enter your username or email!',
  INPUT_PASSWORD_MSG: 'Enter your password!',
  INPUT_EXISTING_DEPUTY_EMAIL_MSG:
    'This email already existed in the list of Deputies!',
  INPUT_CURRENT_USER_EMAIL_MSG: 'Enter another email, not your own one!',
  FORGOT_PASSWORD: 'Forgot password',
  LOGIN: 'Login',
  REMEMBER_ME: 'Remember me',
  SECRET_CODE: 'Secret code',
  FRIENDLY_DEVICE_NAME: 'My TOTP device',
  USERNAME_OR_EMAIL: 'Username/Email',
  SETTINGS: 'Settings',
  FILES: 'Files',
  SIGN_OUT: 'Sign out',
  INVALID_CODE_TYPE: 'Code must be 6-digit number!',
  PASSWORD_RULES:
    'Password must have minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character.',
  ACTION: 'Action',
  PENDING_ACCEPTANCE_OF_DEPUTY: 'Pending acceptance',
  DEPUTY_MANAGAMENT: 'Deputy Management',
  PROFILE_AND_SETTINGS: 'Profile & Settings',
  REQUESTS: 'Requests',
  PRIMARY_USERS: 'Primary users',
  RESEND: 'Resend',
  REVOKE: 'Revoke',
  REACTIVATE: 'Reactivate',
  PAYMENT: 'Payment',
  SIGN_IN: 'Sign in',
  INPUT_FULLNAME_MSG: 'Enter your full name!',
  USE_SAME_NAME_AS_PASSPORT_TOOLTIP: 'Use the full name as per ID / passport',
  INPUT_PHONE_NUMBER_MSG: 'Enter your mobile number!',
  INVALID_EMAIL_MSG: 'Enter a valid email address!',
  INPUT_EMAIL_MSG: 'Enter your email!',
  CONFIRM_PASSWORD_MSG: 'Please confirm your password!',
  FULLNAME: 'Full name',
  PREFERRED_NAME: 'Preferred name',
  PHONE_NUMBER: 'Mobile number',
  EMAIL: 'Email address',
  SHARE_KEY_STATUS: 'Share key status',
  UNLOCK_STATUS: 'Unlock status',
  PASSWORD: 'Password',
  CONFIRM_PASSWORD: 'Confirm password',
  VERIFICATION_CODE: 'Verification code',
  CONTACT_NUMBER: 'Contact number',
  ID_PASSPORT_NUMBER: 'ID / Passport number',
  NATIONALITY: 'Nationality',
  RESIDENTIAL_ADDRESS: 'Residential address',
  COUNTRY_TERRITORY: 'Country / Territory',
  RESET_PASSWORD: 'Reset password',
  RESET_PASSWORD_INSTRUCTION:
    'To reset your password, please enter the verification code which was sent to your email, then enter your new password.',
  INPUT_VERIFICATION_CODE_MSG: 'Enter verification code',
  FILE_NAME: 'File name',
  DESCRIPTION: 'Description',
  TAGS: 'Tags',
  DOWNLOAD: 'Download',
  VIEW: 'View',
  EDIT: 'Edit',
  SAVE: 'Save',
  CANCEL: 'Cancel',
  UPLOAD: 'Upload',
  OR: 'or',
  INPUT_FILE_NAME_MSG: 'Enter file name',
  SAVE_AS_TYPE: 'Save as type',
  INPUT_SHORT_DESCRIPTION_MSG: 'Enter a short description of the file',
  FILE_DETAILS: 'File details',
  PATH: 'Path',
  RENAME: 'Rename',
  SEARCH: 'Search',
  RESET: 'Reset',
  START_TIME: 'Start time',
  END_TIME: 'End time',
  SELECT_START_DATE: 'Select start date',
  SELECT_END_DATE: 'Select end date',
  FILL_IN_SEARCH_FIELD_MSG: 'Please fill in at least one search field',
  SEARCH_RESULTS: 'Search results',
  UPLOAD_FILE: 'Upload file',
  FILE: 'File',
  SELECT_FILE_TO_UPLOAD_MSG: 'Select a file to upload',
  UPLOAD_FILE_INSTRUCTION: 'Click or drag file to this area to upload',
  MOVE: 'Move',
  MOVE_FILE: 'Move file',
  BROWSE: 'Browse',
  DESTINATION: 'Destination',
  LINK: 'Link',
  UNLINK: 'Unlink',
  FAVOURITES: 'Favourites',
  TITLE: 'Title',
  TYPE: 'Type',
  ASSET: 'Asset',
  LIABILITY: 'Liability',
  DESCRIPTION_COL: 'Description',
  PERCENTAGE_OWNERSHIP: 'Percentage ownership',
  PURCHASE_DATE: 'Purchase date',
  OK: 'Ok',
  ACTIONS: 'Actions',
  EDIT_ASSET_LIABILITY: 'Edit asset/liability',
  REMOVE_ASSET_LIABILITY: 'Delete asset/liability',
  LINK_TO_ASSETS_LIABILITIES_TOOLTIP:
    'Link to one or more other assets or liabilities',
  LINK_TO_DOCUMENTS_TOOLTIP: 'Link to one or more documents',
  VIEW_PREVIOUS_VERSIONS_ASSET_LIABILITY:
    'View previous versions of asset/liability',
  ADD_ASSET_LIABILITY_SUCCESS: 'Successfully added asset/liability',
  UPDATE_ASSET_LIABILITY_SUCCESS: 'Successfully updated asset/liability',
  REACTIVATE_SUBSCRIPTION_SUCCESS: 'Successfully reactivated your subscription',
  REACTIVATE_SUBSCRIPTION_FAIL: 'Failed to reactivate subscription',
  ADD_ASSET_LIABILITY: 'Add asset / liability',
  INPUT_TITLE_MSG: 'Enter title!',
  INPUT_DESCRIPTION_MSG: 'Enter description!',
  INPUT_PURCHASE_DATE_MSG: 'Enter purchase date!',
  CURRENCY: 'Currency',
  CCY: 'Ccy',
  VALUATION_ASSET_LIABILITY_CURRENCY: 'Valuation in asset / liability currency',
  ASSETS_CURRENCY: 'Asset currency',
  VALUATION_ASSET_CURRENCY: 'Valuation in asset currency',
  VALUATION_BASE_CURRENCY: 'Valuation in base currency',
  START_DATE: 'Start date',
  INPUT_START_DATE: 'Enter start date!',
  MATURITY_DATE: 'Maturity date',
  LIABILITIES_CURRENCY: 'Liability currency',
  SUCCESSFULLY_SAVED_COLUMNS: 'Successfully saved columns',
  SHOW_HIDE_COLUMNS: 'Show / hide columns',
  FAILED_TO_SAVE_COLUMNS: 'Failed to save columns',
  SAVE_COLUMNS_TOOLTIP:
    'Save the currently selected columns as your default view',
  SAVE_COLUMNS: 'Save columns',
  RESET_TOOLTIP: 'Reset to your default columns',
  OUTSTANDING_VALUE_LIABILITY_CURRENCY:
    'Outstanding amount in liability currency',
  OUTSTANDING_VALUE_BASE_CURRENCY: 'Outstanding amount in base currency',
  LINK_ASSETS_LIABILITIES: 'Link assets/liabilities',
  LINK_DOCUMENTS: 'Link documents',
  SELECT_ASSETS_LIABILITIES_TO_LINK: 'Select assets/liabilities to link',
  ASSETS_LIABILITIES: 'Assets/Liabilities',
  SELECT_ASSETS_LIABILITIES: 'Select assets/liabilities',
  LINK_ASSETS_LIABILITIES_SUCCESS:
    'Successfully updated assets/liabilities links',
  VIEW_LINKED_RECORDS: 'View linked records',
  SET_BASE_CURRENCY: 'Set base currency',
  SELECT_CURRENCY: 'Select currency',
  BASE_CURRENCY: 'Base currency',
  GROSS_ASSET_VALUE: 'Gross asset value',
  GROSS_OUTSTANDING_VALUE: 'Gross outstanding value',
  NET_VALUE: 'Net value',
  GROUP_BY_CURRENCY: 'Group by currency',
  TIME: 'Time',
  ASSET_PURCHASE_DATE: 'Asset purchase date',
  LIABILITY_START_DATE: 'Liability start date',
  LIABILITY_MATURITY_DATE: 'Liability maturity date',
  VALUATION_IN_ASSET_LIABILITY_CURRENCY:
    'Valuation in asset/liability currency',
  OTHER_ASSET_TYPE: 'Other asset type',
  VALUATION_DATE: 'Valuation date',
  TYPE_OF_INTEREST_RATE: 'Type of interest rate',
  SECURITY_COLLATERAL: 'Security / collateral provided to lender',
  RECURRING_PAYMENT_DUE_DAY: 'Recurring payment due day',
  ISSUING_BANK: 'Issuing bank',
  CREDIT_LIMIT: 'Credit limit',
  STATEMENT_DATE: 'Statement date',
  CLASS_OF_SHARES: 'Class of shares',
  SHARE_CERTIFICATE_NUMBER: 'Share certificate number',
  PERCENTAGE_OF_SHARES: 'Percentage of shares within this class',
  DISPOSAL_DATE: 'Disposal date',
  ACCOUNT_TYPE: 'Account type',
  BALANCE_AMOUNT: 'Balance amount',
  BUILDING_TYPE: 'Building type',
  BUILD_DATE: 'Build date',
  FLOOR_SIZE: 'Floor size',
  FLOOR_SIZE_VALUE: 'Floor size value',
  FLOOR_SIZE_UNIT: 'Floor size unit',
  PROPERTY_ADDRESS: 'Property address',
  PROPERTY_ADDRESS_MSG: 'Enter property address',
  HOLDING_TYPE: 'Holding type',
  LEASEHOLD_DURATION: 'Leasehold duration',
  TENANT: 'Tenant',
  RENT_AMOUNT: 'Rent amount',
  RENT_AMOUNT_VALUE: 'Rent amount value',
  RENT_AMOUNT_UNIT: 'Rent amount unit',
  RENTAL_START_DATE: 'Rental start date',
  RENTAL_END_DATE: 'Rental end date',
  EXCHANGE: 'Exchange',
  NUMBER_OF_SHARES: 'Number of shares',
  EXPIRY_DATE: 'Expiry date',
  PAYMENT_DUE_DATE: 'Payment due date',
  LENDER: 'Lender',
  BORROWER: 'Borrower',
  LOAN_PERIOD: 'Loan period',
  LOAN_PERIOD_VALUE: 'Loan period value',
  LOAN_PERIOD_UNIT: 'Loan period unit',
  LENGTH_OF_LOAN_VALUE: 'Length of loan value',
  LENGTH_OF_LOAN_UNIT: 'Length of loan unit',
  NUMBER_OF_REPAYMENTS: 'Number of repayments',
  MONTHLY_PAYMENT: 'Monthly payment',
  ACCOUNT_NUMBER: 'Account number',
  LENGTH_OF_LOAN: 'Length of loan',
  YEAR_OF_LOAN: 'Year of loan',
  OTHER_NOTES: 'Other notes',
  COMPANY: 'Company',
  BENEFICIARIES: 'Beneficiaries',
  INSURANCE_ADVISOR: 'Insurance advisor',
  ENTITLEMENT: '% Entitlement',
  POLICY_NUMBER: 'Policy number',
  INSURER: 'Insurer',
  PREMIUM: 'Premium',
  PREMIUM_VALUE: 'Premium value',
  PREMIUM_UNIT: 'Premium unit',
  SUM_ASSURED_IN_BASE_CURRENCY: 'Sum assured in base currency',
  SUM_ASSURED_IN_ASSET_CURRENCY: 'Sum assured in asset currency',
  TYPE_OF_POLICY: 'Type of policy',
  LIFE_ASSURED: 'Life assured',
  LIFE_ASSURED_TOOLTIP: 'The person who gets coverage in the policy contract',
  POLICY_OWNER: 'Policy owner',
  POLICY_OWNER_TOOLTIP:
    'The person who pays for the policy premium and has the ownership over the policy contract',
  BANK: 'Bank',
  INTEREST_RATE: 'Interest rate',
  INTEREST_RATE_VALUE: 'Interest rate value',
  INTEREST_RATE_UNIT: 'Interest rate unit',
  SUBTYPE: 'Subtype',
  AMOUNT_BORROWED: 'Amount borrowed',
  ASSET_LINKS: 'Asset Links',
  SEARCH_ASSETS: 'Search assets',
  LIABILITY_LINKS: 'Liability Links',
  SEARCH_LIABILITIES: 'Search liabilities',
  POSITION_DATE: 'Position date',
  BALANCE_DATE: 'Balance date',
  ASSETS: 'Assets',
  LIABILITIES: 'Liabilities',
  INPUT_BALANCE_DATE_MSG: 'Enter balance date',
  INPUT_POSITION_DATE_MSG: 'Enter position date',
  QUANTITY: 'Quantity',
  BROKERAGE_NAME: 'Brokerage name',
  JOINT_ACCOUNT: 'Joint account',
  VEHICLE_REGISTRATION_NUMBER: 'Vehicle registration number',
  RETIREMENT_ACCOUNT_TYPE: 'Retirement account type',
  EXPORT_TO_PDF: 'Export to PDF',
  EXPORT_TO_PDF_WARNING:
    "You have selected more than 10 columns. To have the data presented nicely in the exported PDF file, it's recommended to select less than 10 columns.",
  INCLUDE_IN_NETWORTH: 'Include value in overall Net worth',
  TRUST_TYPE: 'Trust type',
  TYPE_OF_TRUST_INTEREST: 'Type of trust interest',
  TRUST_REVOCABILITY: 'Trust revocability',
  CONTACTS: 'Contacts',
  ADD_CONTACT: 'Add contact',
  NAME: 'Name',
  ADD_CONTACT_SUCCESS: 'Successfully added contact',
  INPUT_NAME_MSG: 'Enter name!',
  INPUT_GIVEN_NAME_MSG: 'Enter given name!',
  INPUT_DISPLAY_NAME_MSG: 'Enter display name!',
  INPUT_LEGAL_NAME_MSG: 'Enter legal name!',
  ORGANISATION: 'Organisation',
  INDIVIDUAL: 'Individual',
  GIVEN_NAME: 'Given name',
  SURNAME: 'Surname',
  DATE_OF_BIRTH: 'Date of birth',
  DISPLAY_NAME: 'Display name',
  LEGAL_NAME: 'Legal name',
  EMAILS: 'Emails',
  CONTACT_INFO: 'Contact info',
  ADDRESSES: 'Addresses',
  REFERENCES: 'References',
  URL: 'Url',
  EDIT_CONTACT: 'Edit contact',
  REMOVE_CONTACT: 'Delete contact',
  EDIT_CONTACT_SUCCESS: 'Successfully edited contact',
  REMOVE_CONTACT_SUCCESS: 'Successfully deleted contact',
  REMOVE_CONTACT_CONFIRM: 'Are you sure to delete this contact?',
  VIEW_PREVIOUS_VERSIONS_CONTACT: 'View previous versions of contact',
  CREATE_NEW_FOLDER: 'Create new folder',
  RENAME_FOLDER: 'Rename folder',
  INPUT_FOLDER_NAME_MSG: 'Input folder name',
  FOLDER_NAME: 'Folder name',
  NEW_FOLDER: 'New folder',
  DELETE: 'Delete',
  CONFIRM_DELETE: 'Confirm delete',
  CONFIRM_DELETE_ITEM_MSG: 'Are you sure to delete this item?',
  CONFIRM_DELETE_ITEMS_MSG: 'Are you sure to delete these items?',
  PERCENTAGE_OWNERSHIP_TOOLTIP: 'Enter the overall percentage ownership',
  VALUATION_IN_BASE_CURRENCY_TOOLTIP:
    'If you leave this blank, it will be automatically converted from the asset currency to your base currency according to the exchange rate you have set in your settings',
  OUTSTANDING_AMOUNT_IN_BASE_CURRENCY_TOOLTIP:
    'If you leave this blank, it will be automatically converted from the liability currency to your base currency according to the exchange rate you have set in your settings',
  LIABILITY_VALUATION_DATE_TOOLTIP:
    'This is the specific date of the outstanding amount below',
  LOW_STORAGE_SPACE: 'Low storage space',
  LOW_STORAGE_SPACE_CONTENT:
    'There is not enough free space. Please clear some space by deleting files that you no longer need or upgrade your subscription plan for additional storage.',
  COUNTRY_TERRITORY_OF_INCORPORATION: 'Country / territory of incorporation',
  COMPANY_REGISTRATION_NUMBER: 'Company registration number',
  PHONE: 'Phone',
  REGISTRY: 'Registry',
  DEPUTY: 'Deputy',
  TRASH: 'Trash',
  DOCUMENTS: 'Documents',
  DETAILS: 'Details',
  EXPORT_TO_EXCEL: 'Export to Excel',
  PASSPORT_NUMBER: 'Passport number',
  PROFILE_INFORMATION: 'Profile information',
  EXCHANGE_RATES: 'Exchange rates',
  MY_DEPUTIES: 'My Deputies',
  PHONE_NUMBERS: 'Phone numbers',
  ALL_FILES: 'All Files',
  CRYPTO_CURRENCY: 'Cryptocurrency',
  WALLET_ADDRESS: 'Wallet address',
  PD_NAME: 'Delegated by Professional deputy',
  PRICE: 'Price',
  WALLET_PROVIDER: 'Wallet provider',
  PENDING_RECOVERY: 'Pending recovery',
  RESET_ACCOUNT_NOW: 'Reset account now',
  WILL_WAIT: 'Ok, I will wait',
  RECOVER_DATA_FIRST_CONTENT:
    'You need to wait for approval from your Deputies to recover your data.',
  RECOVER_DATA_SECOND_CONTENT:
    "If you didn't set a Deputy, or if your Deputies are no longer available, your existing data cannot be recovered and you wil need to reset your account to start again.",
  RESET_ACCOUNT_CONFIRMATION: 'Reset account confirmation',
  RESET_ACCOUNT_MODAL_CONTENT: 'Are you sure you want to reset your account?',
  SUCCESSFULLY_RESET_ACCOUNT: 'Successfully reset account',
  FAILED_TO_RESET_ACCOUNT: 'Failed to reset account',
  WELL_COME_TO_VAULTBOX: 'Welcome back to',
  BY_CONTINUING: 'By continuing, you agree to the',
  TERMS_OF_SERVICE: 'Terms of Service',
  RESET_MFA: 'Reset MFA',
  RESET_MFA_FIRST_CONTENT:
    "If you have lost or reset your phone and can't no longer get the authenticade code from the Authenticator app, you can reset the MFA to do the setup again.",
  RESET_MFA_SECOND_CONTENT:
    'When you reset the MFA, a SMS OTP will be sent to your registered phone number. Submit that code to complete the reset.',
  RESET_MFA_WITH_PHONE_NUMBER: 'Reset MFA with new phone number',
  RESET_MFA_WITH_PHONE_NUMBER_CONTENT:
    'If you have lost your phone and phone number, you can reset the MFA with new phone number to do the setup again. This action need approvals from your Deputies',
  FAIL_TO_GET_COUNTRY_CODE: 'Fail to get country code',
  GIFT_INFO_NOT_FOUND: 'Gift info not found',
  GIFT_RECEIVER_EMAIL_MISSMATCH:
    "Gift receiver's email address mismatch. Please register with the email address which received the gift.",
  GIFT_PLAN_MISSMATCH: 'Gift plan mismatch',
  SUBSCRIPTION_PLAN_EMPTY_MSG: 'Please select a subscription plan',
  INVALID_PROMOTION_CODE: 'Invalid promotion code',
  PROMOTION_CODE_MSG:
    "This promotion code can't be applied to the selected plan.",
  FAILED_TO_CHECK_CODE_VALIDATE: 'Failed to check code validity.',
  PERSONAL_INFO_DESCRIPTION:
    'Please memorise this password as nobody other than you will be able to access your <strong>vaultbox</strong> until you sign in and appoint your Deputies.',
  SUBSCRIPTION_PLANS: 'Subscription plans',
  REVIEW: 'Review',
  PERSONAL_INFORMATION: 'Personal Information',
  SUBSCRIPTION: 'Subscription',
  PLAN: 'Plan',
  FREE: 'Free',
  APPLIED_REFERRAL_CODE: 'Applied referral code',
  APPLIED_PROMOTION: 'Applied promotion',
  PROMOTION_NAME: 'Promotion name',
  PERCENTAGE_DISCOUNT: 'Percentage discount',
  FIXED_AMOUNT_DISCOUNT: 'Fixed amount discount',
  DURATION: 'Duration',
  ONCE: 'Once',
  FOREVER: 'Forever',
  READ_AND_AGREE_TERM_OF_SERVICE: 'I have read and agree to the',
  MARKETING_CONSENT_FROM_VAULTBOX:
    'I consent to receiving marketing materials regarding <strong>vaultbox</strong> from Sircured, its affiliates and partners.',
  MARKETING_CONSENT_FROM_THIRT_PARTY:
    'I consent to receiving marketing materials regarding matters which may interest me from Sircured, its affiliates, partners and third parties.',
  NOT_AGREE_TERMS_OF_SERVICE_MSG: 'Please agree to the Terms of Service',
  PREVIOUS: 'Previous',
  SUBMIT: 'Submit',
  NEXT: 'Next',
  REGISTER: 'Register',
  REGISTER_DESCRIPTION: 'Managing and protecting everything that matters',
  CREATE_A_NEW_ACCOUNT: 'Create a new account',
  ACCOUNT_CREATED: 'Account Created',
  ACCOUNT_CREATED_SUCCESSFULLY_MSG:
    'An email has been sent to <strong>{{email}} </strong> to verify your email address. Please follow the instructions in the email to activate your account.',
  RETURN_TO_HOMEPAGE: 'Return to home page',
  INPUT_PASSPORT_NUMBER_MSG: 'Please enter your passport number!',
  MOBLIE_NUMBER_WITH_CODE: 'Mobile number (select country code)',
  INPUT_BASE_CURRENCY_MSG: 'Select your base currency!',
  BASE_CURRENCY_TOOLTIP:
    'This is the default currency for your assets and liabilities to be recorded in the Registry. You can also change this later in the Settings.',
  MONTHS: 'months',
  SELECT_COUNTRY_CODE: 'Select country code',
  INVALID_MOBLIE_NUMBER: 'Invalid mobile number',
  iS_LONG_ENOUGHT: 'At least 8 characters',
  HAS_NUMBER: 'At least 1 number',
  HAS_SPECIAL_CHAR: 'At least 1 special character',
  HAS_UPPERCASE: 'At least 1 uppercase letter',
  HAS_LOWERCASE: 'At least 1 lowercase letter',
  INVALID_PASSWORD: 'Invalid password',
  THANKS_AND_GOODBYE: 'Thanks and goodbye',
  DELETED_ACCOUNT_SUCCESSFULLY_MSG:
    'Your <strong>vaultbox</strong> account is now deleted.',
  RESET_PASSWORD_BY_EAMIL: 'Reset password by email',
  COMPLETE: 'Complete',
  ENTER_YOUR_EMAIL_ADDRESS: 'Enter your email address',
  RESET_PASSWORD_WITH_DEPUTIES_DESCRIPTION:
    'Your account recovery request has been sent to your Deputies. You will be able to sign in with your new password after both of them have unlocked your account.',
  RESET_PASSWORD_DESCRIPTION:
    "Your account has been reset since you don't have enough Deputies to help with recovering your data.",
  CLICK_TO_GO_TO_HOMEPAGE: 'Click <i>Finish</i> to go to Homepage.',
  ACCOUNT_RECOVERY: 'Account recovery',
  RECOVER_ACCESS_TO_YOUR_ACCOUNT: 'Recover access to your account',
  FINISH: 'Finish',
  ABOUT_US: 'About us',
  HELP: 'Help',
  PRIVACY: 'Privacy',
  ALREADY_HAVE_AN_ACCOUNT: 'Already have an account?',
  NEW_TO_VAULTBOX: 'New to <strong>vaultbox</strong>?',
  RESET_PASSWORD_TITLE:
    'Enter the verification sent to your email, and enter your new password.',
  ACCOUNT_ACTIVATION_FAILED: 'Account activation failed',
  ACCOUNT_ACTIVATION_SUCCESSED:
    'Your <strong>vaultbox</strong> account has been activated.',
  ACTIVATING: 'Activating...',
  DATA_RECOVERY: 'Data recovery',
  DATA_RECOVERY_SUMMARY: 'Enter your password to update data encryption',
  SUCCESSFULLY_RECOVERED_DATA: 'Successfully recovered data',
  FAILED_TO_RECOVER_DATA: 'Failed to recover data',
  UPDATE_PHONE_NUMBER_REQUEST_SUCCESSFULLY:
    'Your request has been sent to your Deputies. Please wait for approval.',
  YOU_DO_NOT_HAVE_ANY_DEPUTIES: 'You do not have any Deputies.',
  RESET_PHONE_NUMBER: 'Reset phone number',
  RESET_PHONE_NUMBER_SUMMARY:
    'Change your phone number with the help from your Deputies',
  NEW_PHONE_NUMBER: 'New mobile number (select country code)',
  SUCCESSFULLY_RESTORED_ITEMS: 'Successfully restored item(s)',
  FAILED_TO_RESTORE_ITEMS: 'Failed to restore item(s)',
  PERMANENTLY_DELETE: 'Permanently delete',
  PERMANENTLY_DELETE_ITEM_CONFIRM:
    'Are you sure you want to permanently delete this item?',
  PERMANENTLY_DELETE_ITEMS_CONFIRM:
    'Are you sure you want to permanently delete these items?',
  SUCCESSFULLY_DELETED_ITEM: 'Successfully deleted item',
  SUCCESSFULLY_DELETED_ITEMS: 'Successfully deleted {{count}} items',
  FAILED_TO_DELETE_ITEM: 'Failed to delete item',
  FAILED_TO_DELETE_ITEMS: 'Failed to delete items',
  PERMANENTLY_DELETE_ALL: 'Permanently delete all',
  PERMANENTLY_DELETE_ALL_CONFIRM:
    'Are you sure you want to permanently delete all items?',
  SUCCESSFULLY_DELETED_ALL_ITEMS: 'Successfully deleted all items',
  RESTORE_ALL: 'Restore all',
  RESTORE_ALL_CONFIRM: 'Are you sure you want to restore all items?',
  DELETE_ASSETS_AND_LIABILITIES: 'Deleted Assets & Liabilities',
  DELETED_CONTACTS: 'Deleted Contacts',
  DELETED_FILE_AND_FOLDERS: 'Deleted Files & Folders',
  SELECTED_RECORDS: 'Selected records',
  BULK_DELETE: 'Bulk delete',
  SEARCH_FIL_OR_FOLDER: 'Search file or folder',
  SEARCH_CONTACT: 'Search contact',
  SEARCH_ASSET_OR_LIABILITY: 'Search asset or liability',
  TOUR_INTRO:
    "<strong>vaultbox</strong> - your digital safety deposit box. Itemise your assets and liabilities, keep track of your net worth, upload key documents and more. Let's get started on managing and protecting everything that matters to you.",
  GENERAL_MENU:
    "You can easily add assets and liabilities in <strong>Registry</strong>, upload documents to <strong>Files</strong>, add your friends, service providers and family members' details in <strong>Contacts</strong>, manage who you would\n  deputise to access your <strong>vaultbox</strong> upon death or during incapacitation in <strong>Deputies</strong> and use <strong>Legacy Management</strong> to plan your legacy.",
  ASSET_MENU:
    'Navigate to <strong>Registry</strong> to consolidate, view and manage all your assets and liabilities.',
  CREATE_NEW_ASSET_BUTTON:
    'Click "Create new" to add a new asset or liability. Alternatively, you can click the green + button on the bottom right to do so.',
  CREATE_ASSET_SELECT_TYPE:
    'Select the applicable asset or liability classes to populate your <strong>Registry</strong>.',
  CREATE_ASSET_FORM:
    'Sample information has been pre-filled in the relevant fields to guide you along. Try entering your own information and click "Next" once you are done.',
  SAVE_ASSET_BUTTON:
    'Once you are ready to save your asset to <strong>Registry</strong>, click "Save". If you would like to edit your details, click "Back".',
  SAVE_ASSET_SUCCESS: 'You have successfully added a new asset.',
  FILE_MENU:
    ' Navigate to <strong>Files</strong> to store and arrange your files securely and systematically.',
  CREATE_FILE_BUTTON: 'Click "Upload" to start uploading a new file.',
  ADD_FILE_UPLOAD:
    ' Click or drag your file to this area to upload onto <strong>Files</strong> library.',
  ADD_FILE_INFO:
    'Label, categorise and tag your files or create new folders easily. The default folders in <strong>Files</strong> help you to organise quickly.',
  SAVE_FILE_BUTTON:
    'Once you are ready to save your file, click "Save". If you wish to change the details, click "Back".',
  UPLOAD_FILE_SUCCESS: 'You have successfully uploaded a new file.',
  CONTACT_MENU:
    'Navigate to <strong>Contacts</strong> to add the contact details of your family members, business associates or service providers.',
  CREATE_CONTACT_BUTTON: 'Click "Create new" to create a new contact.',
  CREATE_CONTACT_FORM:
    ' Fill in all the vital information for your contacts for individuals or organisations here. You can tag a contact to particular asset or liability in <strong>Registry</strong>.',
  SAVE_CONTACT_BUTTON:
    'Once you are ready to save your contact, click "Save". If you need to change the details, click "Back".',
  SAVE_CONTACT_SUCCESS: 'You have successfully added a new contact.',
  REVIEW_REGISTRY:
    'Navigate to <strong>Registry</strong> to review your assets and update any details.',
  CREATED_ASSET: 'Select any of your assets to review and update any details.',
  ASSET_OVERVIEW:
    'See clearly how your assets perform over time in a snapshot.',
  EDIT_ASSET_BUTTON: 'Click "Edit" to amend any information.',
  LINK_EXISTED_DOCS:
    'Click "+" button to link any files related to this asset.',
  SELECT_DOCS_TO_LINK:
    'From the <strong>Files</strong> library, select any files to link them. Click "Next" to link them.',
  SAVE_SELECTED_DOCS:
    'When ready, click "Select". To select another file, click "Back".',
  NEW_DOCS_LINK: 'Linking of files to assets is now completed.',
  LINK_EXISTED_CONTACTS:
    'To link a contact to a particular asset, click "+" to link any contacts related to this asset.',
  CONTACTS_LINK_MODAL:
    'Select a contact from <strong>Contacts</strong>, then click "Save".',
  NEW_CONTACTS_LINK: 'Linking of contacts to this asset is now completed.',
  SAVE_LINKED_ASSET: 'Once ready, click "Save".',
  SAVE_LINKED_ASSET_SUCCESS: 'Changes saved.',
  DEPUTIES_MENU:
    'You have an option to appoint Deputies to access your <strong>vaultbox</strong>. Having Deputies help you with legacy management.',
  CURRENT_DEPUTIES: 'View, revoke or update your assigned Deputies here.',
  PENDING_DEPUTIES:
    'Check if all your chosen Deputies have accepted your request to be your Deputies here.',
  PENDING_FILE_UNLOCK:
    'You can mark all your files secret in <strong>Files</strong>. They can only opened once Deputies have unlocked them. View the latest file unlocking status here.',
  COMPLETE_TOUR_MODAL:
    'You have come to the end of the <strong>vaultbox</strong> tour. You can repeat this tour by going to the "Quick access" menu, then start the <strong>vaultbox</strong> tour again.',
  VAULTBOX_TOUR_STOPPED: 'Vaultbox tour stopped',
  VAULTBOX_TOUR_STOPPED_SUMMARY:
    "You have stopped the vaultbox tour. You can take the tour again anytime at 'Quick access menu > Vaultbox tour'.",
  COMPLETE_TOUR: 'Complete tour',
  START_TOUR: 'Start tour',
  STOP_TOUR: 'Stop tour',
  SKIP: 'Skip',
  VAULTBOX_TOUR: 'Vaultbox tour',
  CONTINUE_WHERE_YOU_LEFT_OFF: 'Continue where you left off',
  START_FROM_BEGINNING: 'Start from beginning',
  FAILED_TO_LOAD_YOUR_VAULTBOX_DATA: 'Failed to load your vaultbox data',
  VIEW_DETAILS: 'View details',
  PLEASE_LOG_IN_AGAIN_TO_RETRY: 'Please log in again to retry.',
  LOG_OUT: 'Log out',
  SHOWED_MOBILE_PROMPT:
    'Thanks for opening your vaultbox on mobile. For an optimal experience and more detailed information, please log on via your desktop browser',
  BACK_DATED_AMEND_CONFIRMATION: 'Back-dated amend confirmation',
  BACK_DATED_AMEND_SUMMARY:
    'The position and valuation changes will be recorded as of the selected',
  ARE_YOU_SURE_YOU_WANT_TO_CONTINUTE: 'Are you sure you want to continue?',
  UPLOAD_DOCUMENT: 'Upload document',
  INFORMATION: 'Information',
  CREATE_NEW: 'Create new',
  ASSET_LIABILITY_RECORD_NOT_FOUND: 'Asset / liability record not found.',
  SUCCESSFULLY_LINKED_OR_UNLINKED_DOCUMENT:
    'Successfully {{linkedOrUnlinked}} document',
  LINKED: 'linked',
  UNLINKED: 'unlinked',
  SUCCESSFULLY_LINKED_ASSET_LIABILITY: 'Successfully linked asset/liability',
  SUCCESSFULLY_UNLINKED_ASSET_LIABILITY:
    'Successfully unlinked asset/liability',
  SUCCESSFULLY_UNLINKED_CONTACT: 'Successfully unlinked contact',
  FAILED_TO_UNLINK_CONTACT: 'Failed to unlink contact',
  SUCCESSFULLY_LINKED_CONTACT: 'Successfully linked contacts',
  FAILED_TO_LINK_CONTACT: 'Failed to link contact',
  DETAIL: 'Detail',
  ASSET_NAME: 'Asset name',
  LIABILITY_NAME: 'Liability name',
  OVERVIEW: 'Overview',
  FAILED_TO_EXPORT_TO_PNG: 'Failed to export to PNG',
  EXPORT_TO_PNG: 'Export to PNG',
  TIME_RANGE: 'Time range',
  DATA: 'Data',
  FILES_AND_FOLDERS: 'Files & Folders',
  FOLDERS_COUNT_AND_FILES_COUNT:
    '{{foldersCount}} folders & {{filesCount}} files',
  SEARCH_FILE_OR_FOLDER_NAME: 'Search file or folder name',
  LINK_NEW_FILE: 'Link new file',
  LINK_ASSETS: 'Link assets',
  LINK_LIABILITIES: 'Link liabilities',
  CUSTOM: 'Custom',
  SELECT_EXCHANGE: 'Select exchange',
  EXCHANGE_NAME: 'Exchange name',
  EXCHANGE_CODE: 'Exchange code',
  CURRENCY_NAME: 'Currency name',
  CURRENCY_CODE: 'Currency code',
  ASSET_LIABILITY_CURRENCY: 'Asset / liability currency',
  TYPE_CURRENCY: '{{type}} currency',
  LINK_TO_DOCUMENTS: 'Link to documents',
  LINK_TO_ASSETS_LIABILITIES: 'Link to assets / liabilities',
  INCLUDE_VAULE_IN_NETWORTH: 'Include value in Net worth',
  BACK_DATED_AMEND: 'Back-dated amend',
  FAILED_TO_GET_REGISTRY_COLUMNS: 'Failed to get Registry columns',
  DELETE_VALUATION_CHANGE: 'Delete valuation change',
  DELETE_VALUATION_CHANGE_CONFIRM:
    'Are you sure to delete the valuation change of this item on',
  DELETE_VALUATION_BEFORE_THIS_DATE:
    ' Also delete valuations <strong>before</strong> this date',
  DELETE_VALUATION_AFTER_THIS_DATE:
    'Also delete valuations <strong>after</strong> this date',
  SUCCESSFULLY_DELETED_ASSET_LIABILITY: 'Successfully deleted asset/liability',
  FAILED_TO_DELETE_ASSET_LIABILITY: 'Failed to delete asset/liability',
  VALUATION_IN_BASE_CURRENCY_WITH_PERCENTAGE_OWNERSHIP_TOOLTIP:
    'The sum of valuations in your base currency with percentage ownership taken into account.',
  TOTAL: 'Total',
  NO_ASSETS: 'No assets',
  ENTER_THE_SEARCH_KEYWORD: 'Enter the search keyword',
  CREATE: 'Create',
  NO_LIABILITIES: 'No liabilities',
  BALANCE_SHEET: 'Balance sheet',
  GROUP: 'Group',
  GROUP_BY: 'Group by',
  NET_WORTH: 'Net Worth',
  NO_ASSETS_LIABILITIES: 'No assets / liabilities',
  REPORTS: 'Reports',
  NO_DOCUMENTS: 'No documents',
  ENTER_LABEL: 'Enter {{label}}',
  SELECT_LABEL: 'Select {{label}}',
  ADD_NEW: 'Add new',
  TO_EXCEL: 'To Excel',
  TO_PDF: 'To PDF',
  EXPORT: 'Export',
  TOTAL_LIABILITIES: 'Total Liabilities',
  EXCLUDED_FROM_THE_TOTAL_NET_WORTH: 'Excluded from the total Net Worth',
  CONTACT_LINKS: 'Contact links',
  UNLINK_CONTACT_CONFIRM: 'Are you sure to unlink this contact?',
  YES: 'Yes',
  NO: 'No',
  LINK_CONTACTS: 'Link contacts',
  SELECT_CONTACT: 'Select contact',
  ADD_NEW_CONTACT: 'Add new contact',
  DOCUMENT_LINKS: 'Document links',
  UNLINK_DOCUMENT_CONFIRM: 'Are you sure to unlink this document?',
  Select: 'Select',
  MORE_THAN_TEN_COLUMNS_SELECTED: 'More than 10 columns selected',
  NO_DATA: 'No data',
  INVALID_FILE_MISSING_THE_HEADER_ROW: 'Invalid file: missing the header row',
  INVALID_FILE_MISSING_THE_STATEMENT_HEADER_ROW:
    'Invalid file: missing the statement header row',
  INVALID_FILE_MISSING_COLUMN: 'Invalid file: missing column {{name}}',
  INVALID_FILE_MISSING_VALUATION_DATE: 'Invalid file: missing valuation date',
  UPDATING_ASSETS_LIABILITIES: 'Updating assets / liabilities?',
  UPDATING_ASSETS_LIABILITIES_SUMMARY:
    '{{updatedRecordsCount}} item(s) in your registry will be updated',
  SUCCESSFULLY_IMPORTED_DATA: 'Successfully imported data',
  FAILED_TO_IMPORT_DATA: 'Failed to import data',
  IMPORT_FROM_CSV: 'Import from CSV',
  TEMPLATE: 'Template',
  DOWNLOAD_SAMPLE_TEMPLATE: 'Download sample template',
  SELECT_A_TEMPLATE: 'Select a template',
  FROM_CSV: 'From CSV',
  FROM_EXCEL: 'From Excel',
  IMPORT: 'Import',
  IMPORT_FROM_EXCEL: 'Import from Excel',
  INVALID_FILE_TYPE: 'Invalid file type',
  ASSET_LIABILITY_RECORDS: 'asset / liability record(s)',
  CONTACT_RECORDS: 'contact(s)',
  EVENT_RECORDS: 'event(s)',
  DOCUMENT_RECORDS: 'document(s)',
  FILE_RECORDS: 'File(s)',
  WILL_BE_UNLINKED_FROM_THIS_ITEM: 'will be unlinked from this item',
  DELETE_ALL_HISTORICAL_VALUATIONS_DATA:
    'Delete all historical valuations data',
  ENTER: 'Enter',
  SELECT: 'Select',
  SELECTED: 'Selected',
  ADD: 'Add',
  AUTHENTICATOR_APP: 'Authenticator app',
  BRIEF_STEPS: 'Brief steps',
  GOOGLE_AUTHENTICATOR: 'Google Authenticator',
  OPEN_THE_APP: 'Open the app.',
  TAP_BEGIN_SETUP: 'Tap "Begin setup."',
  TAP_SCAN_BARCODE: 'Tap "Scan barcode".',
  SCAN_THE_QR_CODE: 'Scan the QR code',
  TAP_PLUS_BUTTON: 'Tap the "+" button',
  TAP_OTHER: 'Tap "Other (Google, Facebook, etc.)"',
  ENTER_THE_AUTHENTICATION_CODE:
    'Enter the authentication code into the box below on this screen.',
  LASTPASS_AUTHENTICATOR: 'LastPass Authenticator',
  MICROSOFT_AUTHENTICATOR: 'Microsoft Authenticator',
  MFA_FIRST_CONTENT:
    'These are the brief steps to scan the QR code using some of the commonly-used authenticator apps.',
  MFA_SECOND_CONTENT:
    'The information provided above are for convenience only and may differ from actual use.',
  IPHONE: 'iPhone',
  ANDROID: 'Android',
  APP_STORE: 'App store',
  PLAY_STORE: 'Play store',
  MFA_TABLE_SUMMARY:
    'These apps and links are provided for convenience only and are not an endorsement or warranty of their suitability.',
  CODE: 'Code',
  CODE_IS_REQUIRED: 'Code is required!',
  FAILED_TO_ADD_OTHER_CRYPTO: 'Failed to add other crypto',
  SELECT_CRYPTOCURRENCY: 'Select cryptocurrency',
  OTHER: 'Other',
  AN_ERROR_HAS_OCCURRED: 'An error has occurred',
  MULTI_FACTOR_AUTHENTICATION: 'Multi-factor authentication',
  DID_NOT_RECEIVE_THE_SMS: "Didn't receive the SMS?",
  RESEND_SMS: 'Resend SMS',
  SETUP_MFA_AUTHENTICATION_APP_FISRT_CONTENT:
    'To set up multi-factor authentication on vaultbox, you need to have a compatible authenticator app on your phone.',
  SETUP_MFA_AUTHENTICATION_APP_SECOND_CONTENT:
    "If you don't have one, you may consider installing one from the app store specific to your phone type.",
  SETUP_MFA_AUTHENTICATION_APP_THIRD_CONTENT:
    'When you are ready with an authenticator app installed, click Next.',
  SETUP_MFA_AUTHENTICATION_APP_HEADER:
    'See the list of commonly-used authenticator apps',
  ADD_VAULTBOX_TO_YOUR_AUTHENTICATOR_APP:
    'Add vaultbox to your authenticator app',
  USE_YOUR_AUTHENTICATOR_APP_TO_SCAN_THIS_QR_CODE:
    'Use your authenticator app to scan this QR code.',
  NEED_AN_AUTHENTICATOR_APP: 'Need an authenticator app?',
  HOW_DO_I_SCAN_THE_QR_CODE_FROM_MY_AUTHENTICATOR_APP:
    'How do I scan the QR code from my authenticator app?',
  SHOW_CODE_FOR_MANUAL_CONFIGURATION: 'Show code for manual configuration',
  ADD_VAULTBOX_TO_YOUR_AUTHENTICATOR_APP_FIRST_CONTENT:
    'Ensure that you have only one vaultbox profile in your authenticator app. If you have more, we suggest you delete them and start again.',
  ADD_VAULTBOX_TO_YOUR_AUTHENTICATOR_APP_SECOND_CONTENT:
    'To proceed, enter the authentication code from your authenticator app. This code will refresh automatically, so enter it and then click Next so that we can verify it.',
  BACK: 'Back',
  SETUP_MFA_REVIEW_SUMMARY:
    'You have now set up your authenticator app for vaultbox. The next time you sign in, you will be asked to enter the authentication code from your authenticator app, in addition to your email address and password.',
  SETUP_MFA_SUMMARY:
    'To protect your vaultbox, you need to set up multi-factor authentication before proceeding.',
  LEARN_MORE_ABOUT_SETTING_UP_MULTI_FACTOR_AUTHENTICATION:
    'Learn more about setting up multi-factor authentication.',
  MINOR_EDIT: 'Minor edit',
  MINOR_EDIT_TOOLTIP:
    "If an edit is minor then it doesn't show up in the versions list",
  GENERAL_INFO: 'General info',
  ADDRESS: 'Address',
  LABEL: 'Label',
  DOCUMENTS_DETAIL: '{{foldersCount}} folders & {{filesCount}} files',
  CONTACT_USED_IN_LEGACY:
    'This contact cannot be deleted because it is used in legacy management.',
  FAILED_TO_DELETE_CONTACT: 'Failed to delete contact',
  WILL_BE_UNLINKED_FROM_THIS_CONTACT: 'will be unlinked from this contact.',
  GROUP_INFORMATION: 'Group information',
  PEOPLE: 'people',
  CONTACT_INFORMATION: 'Contact information',
  NOTE: 'Note',
  VERSION: 'Version',
  SELECT_CONTACTS_TO_IMPORT: 'Select contacts to import',
  FAILED_TO_IMPORT_CONTACTS: 'Failed to import contacts',
  INVALID_RECORDS_CANT_BE_IMPORTED:
    "invalid record(s) (missing given name) can't be imported.",
  ALL: 'All',
  NO_CONTACTS_TO_IMPORT: 'No contacts to import',
  REFERENCE_LABEL: 'Reference label',
  REFERENCE_INFO: 'Reference info',
  FROM_VCARD: 'From vCard',
  FAILED_TO_EXPORT_TO_EXCEL: 'Failed to export to Excel',
  FAILED_TO_EXPORT_TO_PDF: 'Failed to export to PDF',
  TAG: 'Tag',
  NO_CONTACTS_RESULTS: 'No contacts results',
  SEARCH_CONTACT_FROM_LEFT_SIDEBAR:
    'Please search contact name or select the contact from left sidebar to get information',
  SEARCH_CONTACT_FROM_CONTACTS_LIST:
    'Please search contact name or select the contact from Contacts list to get information',
  NO_CONTACTS: 'No contacts',
  INVALID_TEMPLATE: 'Invalid template',
  SHEET: 'Sheet',
  ROW: 'Row',
  MISSING_COLUMN: 'Missing column',
  IS_MANDATORY: 'is mandatory',
  SUCCESSFULLY_IMPORT_CONTACTS:
    'Successfully imported {{selectedContacts}} contact(s)',
  IMPORT_FROM_VCARD: 'Import from vCard',
  UPDATE_PENDING_ACCEPTANCE_FROM_DEPUTY:
    'Update pending acceptance from Deputy',
  UPDATE_MY_INFO: 'Update my info',
  STATUS: 'Status',
  BACKUP_KEY_DISTRIBUTED: 'Backup key distributed',
  ACCEPTED: 'Accepted',
  CANNOT_REVOKE_DEPUTY: 'Cannot revoke deputy',
  ARE_YOU_SURE_TO_REVOKE_THIS_DEPUTY: 'Are you sure to revoke this Deputy?',
  CANNOT_REVOKE_DEPUTY_SUMMARY:
    'The number of deputies that need to come together to unlock your vaultbox is <strong>{sharesThreshold}</strong>. To be able to revoke a deputy, you need to reduce this number.',
  WHY_DO_I_NEED_TO_APPOINT_DEPUTIES: 'Why do I need to appoint Deputies?',
  DEPUTY_SUMMARY:
    'Deputies are optional. But if you lose your password or access to your <strong>vaultbox</strong>, you need to have Deputies to reset your password or recover access to your <strong>vaultbox</strong>. We do this by generating a recovery key to be securely kept by your Deputies.',
  ADD_A_DEPUTY: 'Add a Deputy',
  SHARES_THRESHOLD_SUMMARY:
    'The number of deputies that need to come together to unlock your vaultbox',
  CLICK_HERE_TO_RETRY: 'Click here to retry',
  MY_CURRENT_DEPUTIES: 'My current Deputies',
  NO_DEPUTIES: 'No Deputies',
  MY_PENDING_DEPUTIES: 'My pending Deputies',
  NO_PENDING_DEPUTIES: 'No pending Deputies',
  MY_PENDING_FILE_UNLOCKS: 'My pending file unlocks',
  NO_PENDING_FILE_UNLOCKS: 'No pending file unlocks',
  DEPUTIES: 'Deputies',
  PRIMARY_USERS_WHO_I_AM_A_DEPUTY_FOR: ' Primary Users who I am a Deputy for',
  YOU_HAVE_BEEN_ENTRUSTED_AS_DEPUTY:
    'You have been entrusted as Deputy for the following Primary Users.',
  LEARN_MORE_ABOUT_BEING_A_DEPUTY: 'Learn more about being a Deputy.',
  ID_CONTAINS_RESIDENTIAL_ADDRESS: 'ID contains residential address.',
  ADDRESS_PROOF: 'Address proof',
  ID_PASSPORT: 'ID / Passport',
  PRIMARY_USER_DETAILS: 'Primary user details',
  DECLINE: 'Decline',
  ACCEPT: 'Accept',
  REJECT: 'Reject',
  APPROVE: 'Approve',
  CURRENT_DETAILS: 'Current details',
  PENDING_UPDATE_DETAILS: 'Pending update details',
  LEGACY_MANAGEMENT: 'Legacy management',
  INSTRUCTION: 'Instruction',
  RECEIVED_BACKUP_KEY: 'Received backup key',
  NOT_YET_RECEIVED_BACKUP_KEY: 'Not yet received backup key',
  LEGACY_MANAGEMENT_ENABLED: 'Legacy management enabled',
  FROM: 'From',
  PENDING: 'Pending',
  REQUEST_ACCEPTED: 'Request accepted',
  REQUEST_REJECTED: 'Request rejected',
  FAILED_TO_ACCEPT_REQUEST: 'Failed to accept request',
  FAILED_TO_APPROVE_REQUEST: 'Failed to approve request',
  FAILED_TO_REJECT_REQUEST: 'Failed to reject request',
  FAILED_TO_HANDLE_DEPUTY_REQUEST: 'Failed to handle Deputy request',
  FAILED_TO_HANDLE_UPDATE_USER_DETAILS_REQUEST:
    'Failed to handle update user details request',
  PROFESSIONAL_DEPUTY_ACCESS_DELEGATIONS:
    'Professional deputy access delegations',
  PENDING_MY_ACCEPTANCE_TO_BE_DEPUTY: 'Pending my acceptance to be Deputy',
  NO_PENDING_REQUESTS: 'No pending requests',
  I_AM_CURRENTLY_A_DEPUTY_FOR_THESE_PRIMARY_USERS:
    'I am currently a Deputy for these Primary Users',
  NO_PRIMARY_USERS: 'No primary users',
  REQUEST_TO_UPDATE_THEIR_DETAILS: 'Requests to update their details',
  NO_REQUESTS: 'No requests',
  APPROVAL_SATUS: 'Approval status',
  NEW_PHONE_NUMBER_DEPUTY: 'New phone number',
  REQUEST_APPROVED: 'Request approved',
  REQUESTS_TO_RESET_THEIR_PASSWORD: 'Requests to reset their password',
  REQUEST_TO_UNLOCK_THEIR_FILES: 'Requests to unlock their files',
  REQUEST_TO_CHANGE_THEIR_PHONE_NUMBER: 'Requests to change their phone number',
  SUCCESSFULLY_UPDATED_NUMBER_OF_DEPUTIES_REQUIRED:
    'Successfully updated number of deputies required',
  FAILED_TO_UPDATE_NUMBER_OF_DEPUTIES_REQUIRED:
    'Failed to update number of deputies required',
  NUMBER_OF_DEPUTIES_REQUIRED_TO_UNLOCK_VAULTBOX:
    'Number of deputies required to unlock vaultbox',
  ENTER_NUMBER_OF_DEPUTIES: 'Enter number of deputies',
  FAILDED_TO_LOAD_USER_DETAILS: 'Failed to load user details',
  SUCCESSFULLY_UPDATE_DETAILS: 'Successfully update details',
  FAILED_TO_UPDATE_DETAILS: 'Failed to update details',
  USER_DETAILS: 'User details',
  OWNER: 'Owner',
  UNLOCK: 'Unlock',
  LOCK: 'Lock',
  SUCCESSFULLY_UNLOCKED_VAULT: 'Successfully unlocked vault',
  FAILED_TO_UNLOCK_VAULT: 'Failed to unlock vault',
  SUCCESSFULLY_LOCKED_VAULT: 'Successfully locked vault',
  FAILED_TO_LOCK_VAULT: 'Failed to lock vault',
  VAULTBOXES_OF_PRIMARY_USERS: 'vaultboxes of Primary Users',
  NO_VAULTBOXES: 'No vaultboxes',
  FOLDER_NAME_EXISTS: 'Folder name exists',
  FAILED_TO_GET_FILE: 'Failed to get file.',
  MARKING_FILE_AS_SECRET: 'Marking file as secret?',
  MARKING_FILE_AS_SECRET_SUMMARY:
    'Once marked as secret, the file can only be viewed or downloaded once a Deputy have unlocked it.',
  SUCCESSFULLY_MARKED_DOCUMENT_AS_SECRET:
    'Successfully marked document as secret',
  FAILED_TO_MARK_DOCUMENT_AS_SECRET: 'Failed to mark document as secret.',
  REQUEST_TO_UNLOCK_HAS_BEEN_SENT: 'Request to unlock has been sent',
  RESEND_UNLOCK_REQUEST: 'Resend unlock request',
  REQUEST_TO_UNLOCK: 'Request to unlock',
  MARK_SECRET_FILE_FIRST_TOOLTIP:
    'If a file is marked as secret, it can only be viewed or downloaded once a Deputy have unlocked it.',
  MARK_SECRET_FILE_SECOND_TOOLTIP:
    "To be able to mark a file as secret, it's required to have at least one Deputy.",
  MARK_SECRET: 'Mark secret',
  SECRET_FILE: 'Secret File',
  SECRET_FILE_SUMMARY:
    "This file has been marked as secret and can't be viewed or downloaded. To regain file access, please request the Deputies to help to unlock.",
  PENDING_UNLOCK: 'Pending unlock',
  PENDING_UNLOCK_SUMMARY: 'File unlock request has been sent to Deputies.',
  FILE_INFORMATION: 'File information',
  ORIGINAL_PATH: 'Original path',
  CREATED: 'Created',
  SIZE: 'Size',
  SUCCESSFULLY_UPLOADED_FILE: 'Successfully uploaded file',
  FAILED_TO_UPLOAD_FILE: 'Failed to upload file',
  REMOVE_FROM_FAVOURITES: 'Remove from Favourites',
  ADD_TO_FAVOURITES: 'Add to Favourites',
  FILE_USED_IN_LEGACY_MANAGEMENT:
    'This file cannot be deleted because it is used in legacy management.',
  SUCCESSFULLY_DELETED: 'Successfully deleted',
  FAILED_TO_DELETE: 'Failed to delete',
  FOLDER: 'Folder',
  CANNOT_DELETE_FOLDERS_WITH_FILES_INSIDE:
    'Cannot delete folders with files inside',
  CANNOT_BE_DELETED_BECAUSE_IT_USED_IN_LEGACY_MANAGEMENT:
    'File "{{name}}" cannot be deleted because it is used in legacy management.',
  SUCCESSFULLY_RENAMED_FOLDER: 'Successfully renamed folder',
  FAILED_TO_RENAME_FOLDER: 'Failed to rename folder',
  FAILED_TO: 'Failed to',
  THE_DESTINATION_IS_A_SUBFOLDER:
    'The destination folder is a subfolder of the source folder.',
  SUCCESSFULLY_COPIED: 'Successfully copied',
  ITEM: 'Item',
  ITEMS: 'Items',
  FAILED_TO_COPY: 'Failed to copy',
  SUCCESSFULLY_MOVED_ITEM: 'Successfully moved item',
  FAILED_TO_MOVE_ITEM: 'Failed to move item',
  SUCCESSFULLY_MOVED_ITEMS: 'Successfully moved items',
  FAILED_TO_MOVE_ITEMS: 'Failed to move items',
  COPY: 'Copy',
  EDIT_CONTENT: 'Edit content',
  CANT_DELETE_FOLDER_WITH_FILES_INSIDE:
    "Can't delete folder with file(s) inside",
  FOLDERS: 'Folders',
  SUCCESSFULLY_CREATED_FOLDER: 'Successfully created folder',
  FAILED_TO_CREATE_FOLDER: 'Failed to create folder',
  TEXT_FILE: 'Text File',
  HOME: 'Home',
  SELECT_FOLDER: 'Select folder',
  SHOW_FOLDER_TREE: 'Show Folder Tree',
  STORAGE: 'Storage',
  YOUR_STORAGE_INCLUDES_IN_TRASH:
    'Your storage includes {{deletedStorage}} in Trash. Empty your trash to free up more storage.',
  OF: 'of',
  USED: 'Used',
  SELECT_FILE_OR_FOLDER_TO_SEE_DETAILS:
    'Select a file or a folder to see details',
  NEW_TAG: 'New Tag',
  CANT_BE_ENCRYPTED_AND_UPLOADED: "can't be encrypted and uploaded",
  FILE_SIZE_LIMIT_IS: 'File size limit is',
  SAVE_TO_VAULTBOX_FOLDER: 'Save to <strong>vaultbox</strong> folder',
  SAVE_TEXT_FILE: 'Save text file',
  FILE_NAME_IS_REQUIRED: 'File name is required!',
  SUCCESSFULLY_CREATED_FILE: 'Successfully created file',
  FAILED_TO_CREATE_FILE: 'Failed to create file',
  THE_SELECTED_FILE_IS_LOCK: 'The selected file is locked and cannot be used',
  REMOVE_FILE: 'Remove file',
  SELECT_FROM_UPLOADED_FILES: 'Select from uploaded Files',
  UPLOAD_OR_SELECT_FROM_UPLOADED_FILES:
    'Upload {{labelForErrorMsg}} or select from uploaded Files',
  QUICK_ACCESS: 'Quick access',
  DOWNLOAD_VAULTBOX: 'Download vaultbox',
  IMPORT_TEMPLATE: 'Import template',
  FAILED_TO_DOWNLOAD_VAULTBOX: 'Failed to download vaultbox',
  SESSION_TIMEOUT: 'Session timeout',
  SESSION_TIMEOUT_SUMMARY: 'Please login again to continue',
  FAILE_TO_GET_USER_DATA: 'Failed to get user data',
  FAILE_TO_GET_PRIMARY_USERS: 'Failed to get primary users',
  ARE_YOU_SURE_YOU_WANT_TO_SIGN_OUT: 'Are you sure you want to sign out?',
  BACK_TO_MY_VAULTBOX: 'Back to my vaultbox',
  VIEWING_VAULTBOX_OF: 'Viewing vaultbox of',
  PLEASE_APPOINT_YOUR_DEPUTIES:
    'Please appoint your Deputies, who can help to unlock your vaultbox if you forget your password. Otherwise, all your vaultbox contents will be deleted when you reset your password.',
  APPOINT_DEPUTIES_NOW: 'Appoint Deputies now',
  REMIND_ME_LATER: 'Remind me later',
  DO_NOT_REMIND_ME_AGAIN: 'Do not remind me again',
  YOU_HAVENT_SET_BASE_CURRENCY:
    "You haven't set your base currency. Please set it",
  HERE: 'here',
  SUBSCRIPTION_HAS_BEEN_CANCEL_AT:
    'Your <strong>vaultbox</strong> subscription has been cancelled. Your {{nickname}} subscription will end on <strong>{{cancel_at}}</strong>',
  YOU_STILL_CAN_REACTIVATE_SUBSCRIPTION:
    'You still can <2> reactivate </2> your subscription before it ends.',
  REACTIVATE_SUBSCRIPTION: 'Reactivate subscription',
  REACTIVATE_SUBSCRIPTION_CONFIRM:
    'Are you sure you want to reactivate your {{nickname}} subscription?',
  THE_PAYMENT_BECAME_PAST_DUE:
    'The payment for your subscription failed and became past due on {{billing_cycle_anchor}}.',
  UPDATE_PAYMENT_INFORMATION:
    'Please update your <2> payment information </2> within one week, otherwise your current subscription will be canceled and downgraded to a vaultbox Essential.',
  YOU_HAVENT_ADDED_ANY_PAYMENT_METHOD:
    "You haven't added any <2> payment method </2>. Please add at least one <5> payment method </5> ",
  YOU_NEED_TO_ADD_MORE_DEPUTIES:
    'The number of deputies required to unlock your vaultbox is <strong>{{sharesThreshold}}</strong> but you currently have only <strong>{{acceptedDeputies}}</strong> deputies. You need to add more deputies or reduce the number of deputies required.',
  UPDATE: 'Update',
  WHO_WILL_RECEIVE_THE_ACCESS_TO_YOUR_VAULTBOX:
    'Who will receive the access to your vaultbox?',
  LEGACY_ACCESSED_BY_PROFESSIONAL_DEPUTY:
    'Your Professional Deputy will unlock your vaultbox and have access to the full content. They will decide what to do with the content based on your instructions (uploaded in the "Content permission" step)',
  LEGACY_ACCESSED_BY_OTHER_SPECIFIC_PERSON:
    'Your Professional Deputy will unlock your vaultbox and release to that person the content specified in “Content permission” step. Such person will need to prove their identity and connection with you.',
  OTHER_PERSON: 'Other person',
  THE_SELECTED_FILE_IS_LOCK_AND_CANNOT_BE_USED:
    'The selected file is locked and cannot be used',
  SUCCESSFULLY_SAVED_LEGACY_MANAGEMENT_CONFIGURATION:
    'Successfully saved Legacy management configuration',
  SUMMARY: 'Summary',
  HERE_IS_A_SUMMARY_OF_YOUR_CONFIGURATION:
    "Here's a summary of your configuration",
  LEGACY_MANAGEMENT_CONFIGURATION: 'Legacy management configuration',
  REQUEST_BY_USER: 'Request by user',
  REQUEST_BY_YOU: 'Request by you',
  USER_DEATH_OR_INCAPACITATED_STATE: "User's death or incapacitated state",
  YOUR_DEATH_OR_INCAPACITATED_STATE: 'Your death or incapacitated state',
  WE_WILL_SHARE_USER: "We will share user's:",
  WE_WILL_SHARE_YOUR: 'We will share your:',
  FULL_VAULTBOX: 'Full vaultbox',
  FINAL_REGISTRY_SNAPSHOT_AND_SELECTED_FILES_FOLDERS:
    'Final Registry snapshot and Selected files/folders',
  FINAL_REGISTRY_SNAPSHOT: 'Final Registry snapshot',
  FINAL_SNAPSHOT_OF_YOUR_REGISTRY: 'Final snapshot of your Registry',
  SELECTED_FILES_FOLDERS: 'Selected files/folders',
  WITH: 'With',
  UPON: 'Upon',
  USER_PROFESSIONAL_DEPUTY: "user's Professional Deputy",
  YOUR_PROFESSIONAL_DEPUTY: 'your Professional Deputy',
  FAILED_TO_GET_LEGACY_MANAGEMENT_INFO: 'Failed to get legacy management info',
  FAILED_TO_GET_LEGACY_MANAGEMENT_STATUS:
    'Failed to get legacy management status',
  FAILED_TO_CHANGE_LEGACY_MANAGEMENT: 'Failed to change legacy management',
  DISABLE_THE_LEGACY_MANAGEMENT: 'Disable the legacy management?',
  ENABLE_THE_LEGACY_MANAGEMENT: 'Enable the legacy management',
  SUCCESSFULLY_SENT_REQUEST_TO_RELEASE_YOUR_VAULTBOX:
    'Successfully sent request to release your vaultbox',
  FAILED_TO_SENT_REQUEST_TO_RELEASE_YOUR_VAULTBOX:
    'Failed to send request to release your vaultbox',
  REQUEST_TO_RELEASE_YOUR_VAULTBOX: 'Request to release your vaultbox?',
  REQUEST_TO_RELEASE_YOUR_VAULTBOX_SUMNARY:
    'Your Professional Deputy will be requested to unlock your vaultbox and release its content follow your current legacy management configuration. Are you sure you want to continue?',
  LEGACY_MANAGEMENT_SUMMARY:
    'As a <strong>vaultbox</strong> customer, you can have a set of executing instructions upon your death or incapacitated state so that the Professional Deputy that you have assigned would know how to proceed with your <strong>vaultbox</strong> content.',
  START: 'Start',
  TOGGLE_THE_LEGACY_MANAGEMENT: 'Toggle the legacy management',
  REQUEST_TO_RELEASE_NOW: 'Request to release now',
  UPDATE_CONFIGURATION: 'Update configuration',
  YOU_ARE_PENDING_ACCEPTANCE_FROM_YOUR_DEPUITES:
    'You are pending acceptance from your Deputies.',
  YOU_NEED_TO_HAVE_A_PROFESSIONAL_DEPUTY_TO_USE_THIS_FEATURE:
    'You need to have a Professional Deputy to use this feature.',
  APPOINT_PROFESSIONAL_DEPUTY_NOW: 'Appoint Professional Deputy now',
  PLEASE_SELECT_AT_LEAST_ONE_OPTION: 'Please select at least one option',
  PLEASE_SELECT_AT_LEAST_ONE_FILE_FOLDER:
    'Please select at least one file/folder',
  CONTENT_PERMISSION: 'Content permission',
  ACCESSED_BY_OTHER_SPECIFIC_PERSON_FIRST_SUMMARY:
    'You have selected to provide access for <strong>Other specific person</strong>',
  ACCESSED_BY_OTHER_SPECIFIC_PERSON_SECOND_SUMMARY:
    'You can either provide full access to your <strong>vaultbox</strong>, or just your Registry content and/or selected files and folders.',
  ACCESSED_BY_PROFESSIONAL_DEPUTY_FIRST_SUMMARY:
    ' You have selected to provide access for <strong>Professional deputy</strong>.',
  ACCESSED_BY_PROFESSIONAL_DEPUTY_SECOND_SUMMARY:
    ' Your Professional deputy will have full access to your <strong>vaultbox</strong>.',
  CHOOSE_FILES_OR_FOLDERS_TO_SHARE: 'Choose files or folders to share',
  ADD_FILE_FOLDERS: 'Add files/folders',
  INSTRUCTIONS: 'Instructions',
  INSTRUCTIONS_SUMMARY:
    'Upload a written document on any other instructions for your Professional Deputy. For example a README.TXT on where are specific files located at, to whom should the Professional Deputy pass those files to.',
  INSTRUCTION_FILE: 'Instruction file',
  WHEN_DO_YOU_WANT_TO_RELEASE_YOUR_VAULTBOX_CONTENT:
    'When do you want to release your vaultbox content?',
  UPON_REQUEST_BY_ME: 'Upon request by me',
  UPON_MY_DEATH_OR_INCAPACITATED_SATE: 'Upon my death or incapacitated state',
  INACTIVITY_STATE_CONFIGURATION:
    'To select this option, you need to complete the inactivity state configuration.',
  CONFIGURE: 'Configure',
  CHOSSE_FILES_FOLDERS: 'Choose files / folders',
  NEW_CRYPTOCURRENCY: 'New cryptocurrency',
  THE_SAME_NAME_ALREADY_EXISTS: 'The same name already exists!',
  SYMBOL: 'Symbol',
  ENTER_SYMBOL: 'Enter symbol',
  THE_SAME_SYMBOL_ALREADY_EXISTS: 'The same symbol already exists!',
  NEW_ITEM: 'New {{item}}',
  ENTER_NEW_ITEM: 'Enter new {{item}}!',
  THE_SAME_ITEM_ALREADY_EXISTS: 'The same {{item}} already exists!',
  SUCCESSFULLY_ADDED_CARD: 'Successfully added card',
  FAILED_TO_ADD_CARD: 'Failed to add card',
  ADD_A_CARD: 'Add a card',
  SUPPORTED_CARD_TYPES: 'Supported card types:',
  TESTING_NOTE:
    'Testing note: You can enter the following card number for testing',
  SUCCESSFULLY_DELETED_CARD: 'Successfully deleted card',
  FAILED_TO_DELETED_CARD: 'Failed to deleted card',
  ARE_YOU_SURE_TO_DELETE_THIS_CARD: 'Are you sure to delete this card?',
  DELETE_CARD: 'Delete card',
  FAILED_TO_GET_INVOICES: 'Failed to get invoices',
  AMOUNT: 'Amount',
  VIEW_INVOICE: 'View invoice',
  VIEW_RECEIPT: 'View receipt',
  CARD_NO: 'Card No.',
  EXPIRES_ON: 'Expires on',
  CURRENT_SUBSCRIPTION: 'Current subscription',
  CURRENT_SUBSCRIPTION_APPLIED_PROMOTION_CODE:
    "Your current subscription already applied a promotion code and can't apply another.",
  PROMOTION_CODE: 'Promotion code',
  CHANGE_SUBSCRIPTION: 'Change subscription',
  APPLIED_DATE: 'Applied date',
  PAYMENT_METHOD: 'Payment method',
  NO_CARDS: 'No Cards',
  PROMOTION_CODE_CANT_APPLIED:
    "This promotion code can't be applied to the current plan.",
  SUCCESSFULLY_APPLIED_PROMOTION_CODE: 'Successfully applied promotion code',
  FAILED_TO_APPLY_PROMOTION_CODE: 'Failed to apply promotion code',
  PROMOTION_CODE_IS_REQUIRED: 'Promotion code is required',
  ENTER_PROMOTION_CODE: 'Enter promotion code',
  SUCCESSFULLY_SET_DEFAULT_CARD: 'Successfully set default card',
  FAIL_TO_SET_DEFAULT_CARD: 'Failed to set default card',
  ARE_YOU_SURE_TO_SET_THIS_CARD_AS_DEFAULT:
    'Are you sure to set this card as default?',
  SET_DEFAULT_CARD: 'Set default card',
  INVALID_SUBSCRIPTION: 'Invalid subscription',
  INVALID_SUBSCRIPTION_SUMMARY:
    'Your used storage space exceeded the allowed storage of this plan. Please clear some space by deleting files that you no longer need.',
  INVALID_REFERRAL_CODE: 'Invalid referral code',
  CODE_USED:
    'You have used this code before. Please try another one, or leave it empty to proceed.',
  TRY_ANOTHER_ONE: 'Please try another one, or leave it empty to proceed.',
  FAILED_TO_SUBSCRIBE: 'Failed to subscribe',
  FAILED_TO_GET_PROMOTION_CODE_INFO: 'Failed to get promotion code info',
  CHANGE_SUBSCRIPTION_PLAN: 'Change subscription plan',
  CHANGE_SUBSCRIPTION_PLAN_FIRST_SUMMARY:
    'You shall continue the current plan until {{current_period_end }} and will switch to the new plan upon renewal.',
  CHANGE_SUBSCRIPTION_PLAN_SECOND_SUMMARY:
    'Your active promotion code <strong>{{removedPromoCode}}</strong> can only be applied to <strong>{{nickname}}</strong> plan. If you change your subscription plan, the promotional discount will be removed.',
  FAILED_TO_GET_DEPUTIES_LIST_TO_CHECK_PROFESSTIONAL_DEPUTY_PLAN:
    'Failed to get Deputies list to check Professional Deputy plan. Please retry.',
  FAILED_TO_SAVE_SUBSCRIPTION: 'Failed to save subscription',
  CANCEL_SUBSCRIPTION: 'Cancel subscription?',
  CANCEL_SUBSCRIPTION_FIRST_SUMMARY:
    'This action will downgrade your account to vaultbox Essential at the end of your current subscription period.',
  CANCEL_SUBSCRIPTION_SECOND_SUMMARY:
    'After your subscription ends, you are only able to keep up to 20 items within 2GB storage. <2><strong>Remember to back up your data first.</strong></2>',
  YOU_CAN_STILL_USE_YOUR_ACCOUNT_AS_DEPUTY:
    'You can still use your account to be a Deputy for other <strong>vaultbox</strong> users.',
  ARE_YOU_SURE_YOU_WISH_TO_CANCEL_SUBSCRIPTION:
    'Are you sure you wish to cancel your subscription?',
  KEEP_SUBSCRIPTION: 'Keep subscription',
  SUBSCRIPTION_CANCELLED: 'Subscription cancelled',
  SUBSCRIPTION_CANCELLED_SUMMARY:
    'Your {{nickname}} subscription will end on {{current_period_end}}',
  FAILED_TO_CANCEL_SUBSCRIPTION: 'Failed to cancel subscription',
  SUCCESSFULLY_CANCELLED_SUBSCRIPTION_CHANGE:
    'Successfully cancelled subscription change',
  FAILED_TO_CANCEL_SUBSCRIPTION_CHANGE: 'Failed to cancel subscription change',
  CANCEL_CHANGE: 'Cancel change',
  SUBSCRIPTION_TESTING_NOTE:
    "Testing note: The plans below are just mocking data. You can pick any of them to proceed and won't need to pay.",
  SUBSCRIBE: 'Subscribe',
  CHANGE_SUBSCRIPTION_PLAN_ON:
    'Your subscription will change to this plan on {{changeDate}}. Cancel this change if you want to keep the current subscription plan.',
  REFERRAL_CODE: 'Referral code',
  REFERRAL_CODE_TOOLTIP_FIRST_SUMMARY:
    'If you are referred by another vaultbox user, enter their referral code here.',
  REFERRAL_CODE_TOOLTIP_SECOND_SUMMARY:
    'For a successful registration, you will get a US$4.99 discount and your referrer will get US$4.99 credit to their account that can be applied in their next renewal.',
  THIS_IS_NOT_APPLIED_TO_THE_FREE_PLAN:
    'This is not applicable to vaultbox Essential plan.',
  ENTER_REFERRAL_CODE: 'Enter referral code (optional)',
  PLEASE_SELECT_A_CURRENCY: 'Please select a currency!',
  SUCCESSFULLY_CHANGED_PASSWORD: 'Successfully changed password',
  SUCCESSFULLY_SAVED_INACTIVIY_STATE:
    'Successfully saved inactivity state configuration',
  FAILED_TO_SAVE_INACTIVIY_STATE:
    'Failed to save inactivity state configuration',
  INACTIVITY_STATE_CONFIG: 'Inactivity State Config',
  WHEN_YOU_ARE_INACTIVE_FOR: 'When you are inactive for',
  WE_WILL_SEND_YOU_REMINDER: 'we will send you reminder',
  BUT_IF_YOU_DONOT_RESPOND_WITHIN: ' but if you do not respond within',
  OF_THE_REMINDER: 'of the reminder,',
  WE_WILL_INFORM_YOUR_DEPUTIES_ABOUT_YOUR_INACTIVITY:
    'we will inform your Deputies about your inactivity.',
  INACTIVITY_STATE_CONFIGURATION_TITLE: 'Inactivity State Configuration',
  INACTIVITY_STATE_CONFIGURATION_FIRST_SUMMARY:
    'Configuration to inform your Deputies when you are inactive for a long time.',
  INACTIVITY_STATE_CONFIGURATION_SECOND_SUMMARY:
    "We recommend that you occasionally review your configuration whenever there's a major change in your life events, or at least once a year.",
  YOU_NEED_TO_HAVE_DEPUTIES_TO_ABLE_TO_USE_THIS_FEATURE:
    'You need to have Deputies to be able to use this feature.',
  EMAIL_ADDRESS_UPDATED_AND_VERIFIED:
    'Your email address has been updated and verified. Please setup multi-factor authentication again to have the email address shown in your authenticator app updated as well.',
  EMAIL_ADDRESS_VERIFICATION: 'Email address verification',
  ENTER_THE_VERIFICATION_CODE:
    'Enter the verification code which was sent to your new email address.',
  RECEIVER: 'Receiver',
  SUBSCRIPTION_PLAN: 'Subscription plan',
  SENT_DATE: 'Sent date',
  RECEIVED: 'Received',
  GIFTS_I_HAVE_SENT: 'Gifts I have sent',
  YOU_NEED_TO_ADD_A_PAYMENT_METHOD_TO_SENT_GIFT:
    'You need to add a payment method to be able to send gift',
  SEND_GIFT: 'Send gift',
  NO_GIFTS: 'No gifts',
  SETUP_AGAIN: 'Setup again',
  MULTI_FACTOR_AUTHENTICATION_FIRST_SUMMARY:
    'You have set up vaultbox multi-factor authentication using your authenticator app on <strong>{{setupMfaDate}}</strong>.',
  MULTI_FACTOR_AUTHENTICATION_SECOND_SUMMARY:
    'If you are changing phones, authenticator app or MFA type, you need to set it up again. When you do that, the old authentication codes will no longer be usable.',
  RESEND_CODE: 'Resend code',
  ENTER_YOUR_PASSWORD_TO_PROCEED: 'Enter your password to proceed',
  FAILED_TO_EDIT_PROFILE: 'Failed to edit profile',
  SUCCESSFULLY_EDITED_PROFILE: 'Successfully edited profile',
  VERIFY_EMAIL_ADDRESS: 'Verify email address',
  SET_A_STRONG_PASSWORD_TO_PROTECT_YOUR_ACCOUNT:
    ' Set a strong password to protect your account',
  QUOTE_CURRENCY: 'Quote Currency',
  RATE: 'Rate',
  LAST_UPDATE: 'Last Updated',
  FAILE_TO_SET_CURRENCY: 'Failed to set currency',
  FAILED_TO_UPDATE_BASE_CURRENCY: 'Failed to update base currency',
  BASE_CURRENCY_MEANS_YOUR_REPORTING_CURRENCY_OR_FUNCTIONAL_CURRENCY:
    'Base currency means your reporting currency or functional currency',
  SELECT_QUOTE_CURRENCY: 'Select quote currency',
  PLEASE_ENTER_AT_LEAST_ONE_EMAIL_ADDRESS:
    'Please enter at least 1 email address.',
  SUCCESSFULLY_SENT_INVITATION: 'Successfully sent invitation',
  THIS_EMAIL_HAS_APPLIED_YOUR_REFERRAL_CODE:
    'The vaultbox account with this email address has already applied your referral code.',
  REFER: 'Refer',
  REFERRAL_CODE_SUMMARY:
    'The referral code is unique to you and has been assigned to your account. You can refer your business associates, family and friends to vaultbox and get rewarded. For a successful registration, they will get\n    a US$5.00 discount and you will get US$5.00 credit to your account that can be applied in your next renewal.',
  SEND_REFERRAL_LINK: 'Send referral link',
  SEND: 'Send',
  EMAIL_ADDRESSES: 'Email addresses',
  YOU_HAVE_SENT_A_GIFT_TO_THIS_EMAIL:
    "You've sent a gift to this email address before and it's still pending acceptance.",
  EXISTING_VAULTBOX_USER_CAN_NOT_RECEIVE_GIFT:
    "A vaultbox user with this email address already exists. Existing vaultbox users can't receive a subscription gift.",
  SUCCESS_PURCHASED: 'Successfully purchased! The gift is on the way!',
  FAILED_TO_PURCHASE_GIFT: 'Failed to purchase gift',
  PURCHASE: 'Purchase',
  ENTER_EMAIL_ADDRESS: 'Enter email address',
  SELECT_A_PLAN: 'Select a plan',
  ENTER_QUANTITY: 'Enter quantity',
  GIFT_MESSAGE: 'Gift message',
  GIFT_MESSAGE_SUMMARY:
    'I’m using vaultbox and I think it’s very useful for you too! Here’s a vaultbox subscription from me to help you get started.',
  INPUT_GIFT_MESSAGE: 'Input gift message',
  GIFTS: 'Gifts',
  RESET_REGISTRY: 'Reset registry',
  SUCCESSFULLY_RESET_REGISTRY: 'Successfully reset registry',
  FAILED_TO_DELETE_USER: 'Failed to delete user',
  FAILED_TO_RESET_REGISTRY: 'Failed to reset registry',
  INVALID_FORM: 'Invalid form',
  ACTIVITIES: 'Activities',
  DEPUTY_ACCESSES_ONLY: ' Deputy accesses only',
  ACCESSED_YOUR_VAULTBOX: '{{name}} ({{email}}) accessed your vaultbox',
  DELETE_ACCOUNT: 'Delete account',
  DOWNLOAD_DATA_AND_DELETE_ACCOUNT: 'Download data & Delete account',
  ARE_YOU_SURE_YOU_WANT_TO_RESET_YOUR_REGISTRY:
    'Are you sure you want to reset your registry? All your registry data will be deleted and cannot be retrieved in the future.',
  ENTER_PASSWORD: 'Enter password',
  ENTER_A_NEW_EMAIL_WHICH_WAS_NOT_SPECIFIED_BEFORE:
    "Enter a new email which wasn't specified before.",
  SUCCESSFULLY_SPECIFIED_DEPUTIES: 'Successfully specified Deputies',
  FAILED_TO_SPECIFY_DEPUTY: 'Failed to specify Deputy',
  PLEASE_ADD_AT_LEAST_ONE_DEPUTY: 'Please add at least 1 Deputy',
  SELECT_A_DEPUTY_TYPE: 'Select a Deputy type',
  APPOINT_SOMEBODY_ELSE: 'Appoint somebody else',
  WATIGA_TRUST: 'Watiga Trust',
  WATIGA_TRUST_FIRST_SUMMARY:
    'We have partnered with Watiga Trust Ltd (“Watiga Trust”), a licensed trust company in Singapore, to provide private and professional Deputy services to you.',
  WATIGA_TRUST_SECOND_SUMMARY:
    'Watiga Trust will safekeep the recovery key and they can recover access to your <strong>vaultbox</strong> for you or legal representative after you pass away.',
  WATIGA_TRUST_TERMS_OF_SERVICE:
    'Watiga Trust Professional Deputy Terms of Service',
  OTHER_PD_TERMS_OF_SERVICE: 'Professional Deputy Terms of Service',
  PROFESSIONAL_DEPUTY_SETUP_IS_NOT_YET_COMPLETED:
    'Professional Deputy setup is not yet completed.',
  YOU_CANT_HAVE_BOTH_NOMARL_AND_PD_DEPUTY:
    "You can't have both a normal Deputy and a Professional Deputy. To be able to add a Professional Deputy, please revoke all your normal Deputies.",
  YOU_HAVE_ADDED_PD:
    'You have already added a Professional Deputy before and cannot add another.',
  PD_ACCOUNTS_ARE_NOT_YET_AVAILABLE:
    'Professional Deputies accounts are not yet available.',
  YOU_CANT_HAVE_BOTH_PD_AND_NOMARL_DEPUTY:
    "You can't have both a normal Deputy and a Professional Deputy. To be able to add a normal Deputy, please revoke your Professional Deputy",
  WATIGA_TRUST_DETAIL_FIRST_SUMMARY:
    'Watiga Trust is a licensed trust company regulated by the Monetary Authority of Singapore. As a licensed financial institution in Singapore, Watiga Trust is subject to strict\n    compliance requirements, including with respect to anti-money laundering and countering the financing of terrorism.',
  WATIGA_TRUST_DETAIL_SECOND_SUMMARY:
    'For Watiga Trust to act as your Deputy, please provide the following',
  OTHER_PD_SUMMARY:
    'For <strong>{{professionalDeputyName}}</strong> to act as your Deputy, please provide the following:',
  WATIGA_TRUST_DETAIL_THIRD_SUMMARY:
    'We will store this information and files in your <strong>vaultbox</strong> and transmit to Watiga Trust securely. Watiga Trust will keep your information confidential and use them for due diligence\n    verification purposes in accordance with <5> Watiga Trust Professional Deputy Terms of Service </5>. If the documents or information are inaccurate or incomplete, Watiga Trust may contact you to verify that.',
  NORMAL_DEPUTY_SUMMARY:
    'To appoint somebody else as your Deputy, enter their email address below. They will be sent a link to set up the Deputy appointment. If they already have a <strong>vaultbox</strong> account, this appointment will be saved in their <strong>vaultbox</strong>. Otherwise, they will be able to create a free vaultbox Essential account to be your Deputy. You need at least 2 Deputies.',
  EMAIL_ADDRESS_OF_DEPUTY: 'Email address of Deputy',
  DOCUMENTS_UPLOADED: 'Documents uploaded',
  MY_ID_CONTAINS_MY_RESIDENTIAL_ADDRESS:
    'My ID contains my residential address.',
  WATIGA_TRUST_REVIEW_SUMMARY:
    'I agree to appoint Watiga Trust as my Deputy in accordance with the <2> Watiga Trust Professional Deputy Terms of Service </2>',
  OTHER_PD_REVIEW_SUMMARY:
    'I agree to appoint {{professionalDeputyName}} as my Deputy in accordance with the <5>Professional Deputy Terms of Service</5>',
  WE_WILL_EMAIL_YOUR_DEPUTY_FOR_THEIR_ACCEPTANCE:
    'We will email your Deputy for their acceptance.',
  ENTER_FULL_NAME: 'Enter full name',
  ENTER_CONTACT_NUMBER: 'Enter contact number',
  ENTER_ID_PASSPORT_NUMBER: 'Enter ID / passport number',
  SELECT_NATIONALITY: 'Select nationality',
  ENTER_RESIDENTIAL_ADDRESS: 'Enter residential address',
  YOUR_ID_PASSPORT_FILENAME_IS: 'Your ID / Passport filename is',
  YOUR_PROOF_OF_ADDRESS_FILENAME_IS: 'Your Proof of Address filename is',
  UPLOAD_ADDRESS_PROOF_DATED_WITHIN_THE_LAST_SIX_MONTHS:
    'Upload address proof dated within the last six (6) months:',
  ID_CARD: 'ID card',
  UTILITY_BILL: 'Utility bill',
  CORRESPONDENCE_FROM_TAX_OFFICE_OR_OTHER_GOVERMENT_AUTHORITY:
    'Correspondence from tax office or other government authority',
  USERNAME_IS_NULL: 'Username is null. Cannot retrieve a new session',
  INVALID_SESSION: 'Invalid session. Please authenticate.',
  LOCAL_STORAGE_IS_MISSING_AN_ID_TOKEN:
    'Local storage is missing an ID Token. Please authenticate',
  ENTER_ANOTHER_EAMIL_NOT_YOUR_OWN_ONE:
    'Enter another email, not your own one.',
  ENTER_AN_EMAIL_WHICH_IS_DEFFETENT_FROM_PD_EMAIL:
    'Enter an email which is different from the Professional deputy emails',
  FILE_NAME_ALREADY_EXISTS:
    'File name already exists in the current folder, please rename',
  VALUE_MUST_BE_GREATER_THAN: 'Value must be greater than {{min}}',
  NRIC_IS_INVALID: 'NRIC is invalid',
  FIN_IS_INVALID: 'FIN is invalid',
  REFERENCES_TOOLTIP_SUMMARY:
    'References can be used for multiple purposes. Each reference has a label and its matching information.',
  ASSET_LIABILITY_USE_CASE_EXAMPLES: 'Asset/liability use case examples',
  EQITY_LISTED_INFORMATION_OF_TICKER_ISIN:
    'Equity listed: information of Ticker or ISIN',
  INSURANCE_SURRENDER_VALUE: 'Insurance: surrender value',
  CONTACT_USE_CASE_EXAMPLES: 'Contacts use case examples',
  INDIVIDUAL_CONTACT_INFORMATION:
    'Individual contact: information of family members or colleagues which can be used for emergency contact (e.g. Label: Wife, Info: +6512345678)',
  ORGANISATION_CONTACT_INFORMATION:
    'Organization contact: information of Person in charge (e.g. Label: John(PIC), Info: +6512345678 - john@example.com)',
  ENTER_ALPHANUMERIC_CHARACTERS_AND_DASH_ONLY:
    'Enter alphanumeric characters and dash only',
  INVALID_CODE_PLEASE_TRY_AGAIN: 'Invalid code. Please try again.',
  INVALID_CODE_FAILED_TO_SETUP_MFA:
    'Invalid code. Failed to setup multi-factor authentication. Please try again.',
  USER_IS_NOT_CONFIRMED:
    'User is not confirmed - please check for your activation email.',
  UNKNOWN_ERROR_DETECTED: 'Unknown error detected',
  DOWNLOADING_VAULTBOX: 'Downloading vaultbox',
  DOWNLOAD_STEP: 'Downloaded {{index}} of {{size}} files',
  SAVING_ZIP_PART: 'Saving zip part',
  SAVING_ZIP: 'Saving zip',
  FAILED_TO_DOWNLOAD_THE_FOLLOWING_FILES:
    'Failed to download the following files',
  PLEASE_DOWNLOAD_THEM_MANUALLY: 'Please download them manually.',
  DOWNLOADED_VAULTBOX: 'Downloaded vaultbox',
  FAILED_TO_DOWNLOAD_ALL_VAULTBOX: 'Failed to download all vaultbox',
  FILE_NAMES: 'File name(s)',
  ALREADY_EXISTS_IN_THE_CURRENT_FOLDER:
    'already exists in the current folder, please rename',
  ASSETS_AND_LIABILITY_REGISTRY: 'Assets and liabilities registry',
  TRACKING_NETWORTH: 'Tracking net worth',
  QUANTITY_OF_SECURE_FILE_STORAGE: '50 GB of secure file storage',
  SAVE_YOUR_IMPORTANT_CONTACT: 'Save your important contacts',
  APPOINT_DEPUTIES: 'Appoint Deputies',
  BE_APPOINTED_AS_A_DEPUTY: 'Be appointed as a Deputy',
  ABILITY_TO_APPOINT_PD_AS_ADDON_AT:
    'Ability to appoint Professional Deputy as an add-on at ',
  INCLUDE_PROPERTY_LOAN: 'Include property loan',
  PROPERTY_LOAN_INFORMATION: 'Property loan information',
  LINKED_DOCUMENTS: 'Linked documents',
  SECRET_FILE_ALERT_CONTENT:
    "This file has been marked as secret and can't be viewed or downloaded. To regain file access, please request the Deputies to help to unlock.",
  LINKS: 'Links',
  TOTAL_ASSETS: 'Total Assets',
  TODAY: 'Today',
  ALL_TIME: 'All time',
  W_AS_WEEK: 'W',
  M_AS_MONTH: 'M',
  Y_AS_YEAR: 'Y',
  SECURITY_CODE: 'Security Code',
  MARKET_VALUE: 'Market Value',
  LAST_PRICE_DATE: 'Last Price Date',
  REFERENCE_NAME_WITH_INDEX: 'Reference {{index}} name',
  REFERENCE_LABEL_WITH_INDEX: 'Reference {{index}} label',
  REFERENCE_VALUE_WITH_INDEX: 'Reference {{index}} value',
  EMAIL_LABEL_WITH_INDEX: 'Email {{index}} label',
  EMAIL_VALUE_WITH_INDEX: 'Email {{index}} value',
  ADDRESS_LABEL_WITH_INDEX: 'Address {{index}} label',
  ADDRESS_VALUE_WITH_INDEX: 'Address {{index}} value',
  PHONE_NUMBER_LABEL_WITH_INDEX: 'Phone number {{index}} label',
  PHONE_NUMBER_VALUE_WITH_INDEX: 'Phone number {{index}} value',
  FIELD_NAME: 'Field Name',
  FIELD_VALUE: 'Field Value',
  OTHER_PROFESSIONAL_DEPUTY: 'Other Professional Deputy',
  ENTER_PROFESSIONAL_DEPUTY_REFERRAL_CODE:
    'Enter Professional Deputy referral code',
  PROFESSIONAL_DEPUTY_NOT_FOUND: 'Professional Deputy not found',
  THE_PROFESSIONAL_DEPUTY_IS_DEFAULT:
    'The Professional Deputy you are looking for is already your default.',
  FAILED_TO_LOAD_DEPUTIES: 'Failed to load Deputies',
  FAILED_TO_LOAD_DISTRIBUTE_KEYS: 'Failed to distribute keys to your Deputies.',
  SUCCESSFULLY_REVOKED_DEPUTY: 'Successfully revoked Deputy',
  FAILED_TO_REVOKE_DEPUTY: 'Failed to revoke Deputy',
  SUCCESSFULLY_RESENT_DEPUTY_REQUEST: 'Successfully resent Deputy request',
  FAILED_TO_RESENT_DEPUTY_REQUEST: 'Failed to resend Deputy request',
  SUCCESSFULLY_RESENT_UNLOCK_REQUEST: 'Successfully resent unlock request',
  FAILED_TO_RESENT_UNLOCK_REQUEST: 'Failed to resend unlock request',
  FETCHING_LASTEST_EXCHANGE_RATES: 'Fetching latest exchange rates...',
  FAILED_TO_FETCH_LASTEST_EXCHANGE_RATES:
    'Failed to fetch latest exchange rates.',
  RETRY: 'Retry',
  SUCCESSFULLY_FETCHED_LASTEST_EXCHANGE_RATES:
    'Successfully fetched latest exchange rates',
  FAILED_TO_FETCH_SAVE_EXCHANGE_RATES: 'Failed to save latest exchange rates.',
  UPDATING_VALUATION_IN_BASE_CURRENCY:
    'Updating assets / liabilities valuation in base currency...',
  SUCCESS_UPDATED_VALUATION_IN_BASE_CURRENCY:
    'Successfully updated assets / liabilities valuation in base currency.',
  FAILED_TO_UPDATE_VALUATION_IN_BASE_CURRENCY:
    'Failed to update assets / liabilities valuation in base currency.',
  FAILED_TO_GET_INSTRUCTION_FILE: 'Failed to get instruction file.',
  FAILED_TO_SAVE_LEGACY_MANAGEMENT_CONFIGURATION:
    'Failed to save legacy management configuration.',
  FAILED_TO_SET_USER_MFA_PREFERENCE: 'Failed to set user MFA preference.',
  FAILED_TO_RESET_PASSWORD: 'Failed to reset password.',
  FAILED_TO_REGISTER_USER: 'Failed to register user.',
  FAILED_TO_VERIFY_EMAIL_ADDRESS: 'Failed to verify email address.',
  FAILED_TO_AUTHENTICATE_USER: 'Failed to authenticate user.',
  FAILED_TO_GET_PLAN: 'Failed to get plan',
  FAILED_TO_SENT_INVITATION: 'Failed to send invitation',
  FAILED_TO_UPDATE_USER_ATTRIBUTES: 'Failed to update user attributes',
  FAILED_TO_CHANGE_PASSWORD: 'Failed to change password',
  INVALID_PARAMETER_EXCEPTION: 'Invalid parameter. Please try again',
  DID_NOT_RECEIVE_THE_EMAIL: "Didn't receive the email?",
  RESEND_EMAIL: 'Resend email',
  INPUT_EMAIL_CODE: 'Enter the verification code sent to your email address',
  FAILED_TO_UPDATE_AUTH_FLOW: 'Failed to update MFA type.',
  AUTHENTiCATION_FLOW_TYPE: 'MFA Type',
  SELECT_A_TYPE_TO_SETUP_FLOW: 'Select a Multi factor authentication type',
  CHANGE_DEFAULT_LANGUAGE: 'Change default language',
  CHANGE: 'Change',
  FAIILED_TO_CHANGE_DEFAULT_LANGUAGE: 'Failed to change default language',
  YOUR_DEFAULT_LANGUAGE_IS: 'Your default language is',
  CHANGE_DEFAULT_LANGUAGE_SUMMARY:
    'Your default language is {{defaultLanguage}}. But the currently selected language is {{selectedLanguage}}. Which language do you want to use?',
  ENGLISH: 'English',
  VIETNAMESE: 'Vietnamese',
  SELECTED_LANGUAGE: 'Selected language',
  DEFAULT_LANGUAGE: 'Default language',
  MFA_CODE: 'Digital OTP',
  EMAIL_CODE: 'Email OTP',
  FAILED_TO_GET_AUTHENTICATION_FLOW_TYPE: 'Failed to get MFA type',
  INCORRECT_USERNAME_OR_PASSWORD: 'Incorrect username or password.',
  EMAIL_OTP_SUMMARY:
    'You have set up vaultbox multi-factor authentication using your email address on <strong>{{setupMfaDate}}</strong>.',
  CHANGE_AUTH_FLOW_TYPE: 'Change MFA type',
  PENDING_ASSETS: 'Pending assets',
  PENDING_LIABILITIES: 'Pending Liabilities',
  ACTIVE: 'Active',
  REJECTED_LIABILITIES: 'Rejected Liabilities',
  REJECTED_ASSETS: 'Rejected Assets',
  REJECTED: 'Rejected',
  REJECTED_REASON: 'Rejected reason',
  REJECTED_REASON_INPUT_MSG: 'Please input reason for reject',
  BAHASA_INDONESIA: 'Bahasa Indonesia',
  RESTORE: 'Restore',
  PENDING_CONTACT_WARNING_COUNT:
    'This {{type}} has {{contactCount}} linked pending contacts',
  DELETE_ASSET_LIABILITY: 'Delete asset / liability',
  ARE_YOU_SURE_TO_DELETE_THIS_RECORD: 'Are you sure to delete this record?',
  FAILED_TO_UPDATE_ACCESS_LEVEL: 'Failed to update access level',
  PROFESSIONAL_DEPUTY_ACCESS_LEVEL: 'Professional Deputy access level',
  CHANGE_ACCESS_LEVEL: 'Change access level',
  REJECTION_REASON: 'Rejection reason',
  ALLOW: 'Preapproved to make all changes to my data',
  NEED_APPROVAL: 'Approval needed',
  DENY: 'Not allowed to make any changes',
  DEPUTY_ACCESS: 'Deputy access',
  CALENDAR: 'Calendar',
  FAILED_TO_SAVE_THE_EVENT: 'Failed to save the event',
  EVENTS: 'Events',
  ADD_EVENT: 'Add event',
  EVENT_DETAILS: 'Events details',
  EDIT_EVENT: 'Edit event',
  SUCCESSFULLY_SAVE_THE_EVENT: 'Successfully save the event',
  EVENT_LINKS: 'Events links',
  EVENT: 'Events',
  UNLINK_EVENT_CONFIRM: 'Are you sure to unlink this event?',
  SELECT_DOCUMENTS: 'Select documents',
  PENDING_FOLDES: 'pending folders',
  PENDING_FILES: 'pending files',
  SUCCESSFULLY_DELETED_DOCUMENTS: 'Successfully deleted document(s)',
  FAILED_TO_DELETE_DOCUMENTS: 'Failed to delete document(s)',
  FAILED_TO_UNLINK_FROM_FILE: 'Failed to unlink from file',
  SUCCESSFULLY_UPDATED_PENDING_DOCUMENTS:
    'Successfully updated pending document(s)',
  FAILED_TO_UPDATE_PENDING_DOCUMENTS: 'Failed to update pending document(s)',
  SUCCESSFULLY_REJECTED_DOCUMENTS: 'Successfully rejected document(s)',
  FAILED_TO_REJECT_DOCUMENTS: 'Failed to reject document(s)',
  SUCCESSFULLY_APPROVED_DOCUMENTS: 'Successfully approved document(s)',
  FAILED_TO_APPROVE_DOCUMENTS: 'Failed to approve document(s)',
  FREE_ACCOUNT_SUMMARY:
    'vaultbox Essential account can only act as Deputy for other <strong>vaultbox</strong> users. Upgrade your subscription plan to enjoy <strong>vaultbox</strong> full features.',
  NOT_NOW: 'Not now',
  UPGRADE: 'Upgrade',
  PENDING_DOCUMENT_WARNING_COUNT:
    'This {{type}} has {{documentCount}} linked pending documents',
  PENDING_ASSET_LIABILITY_WARNING_COUNT:
    'This {{type}} has {{assetLiabilityCount}} linked pending assets / liabilities',
  CONTACT: 'Contact',
  UNLINK_CONFIRM: 'Unlink confirm',
  DATE: 'Date',
  ASSET_LIABILITY: 'Asset/Liability',
  REMINDER_DAYS: 'Reminder days',
  SUCCESSFULLY_UNLINKED_EVENT: 'Successfully unlinked event',
  RECURRING_EVENT: 'Recurring event',
  END_DATE: 'End date',
  ACCESS_LEVEL: 'Access level',
  UPCOMING_EVENTS: 'Upcoming events',
  DONT_SHOW_AGAIN: "Don't show again",
  RECURRING_TIMES: 'Recurring Times',
  END_DATE_OF_RECURRING: 'End date of recurring',
  SELECT_DATE: 'Please select a date',
  SELECT_RECURRING_TIMES: 'Please select recurring times',
  THIS_EVENT: 'This event',
  THIS_AND_FOLLOWING_EVENTS: 'This and following events',
  ALL_EVENTS: 'All events',
  EDIT_RECURRING_EVENT: 'Edit recurring event',
  DELETE_RECURRING_EVENT: 'Delete recurring event',
  CONFIRM_DOWNLOAD_VAULTBOX: 'Confirm download vaultbox',
  CONFIRM_DOWNLOAD_VAULTBOX_MSG: 'Are you sure to download vaultbox?',
  WARNING_DOWNLOAD_VAULTBOX_MSG:
    'The download process might take several minutes',
  TOTAL_SIZE: 'Total size',
  YOUR_PASSWORD_HAS_BEEN_RESET: 'Your password has been reset.',
  PASSWORDS: 'Passwords',
  USERNAME: 'Username',
  INPUT_USERNAME_MSG: 'Enter your username!',
  INPUT_URL_MSG: 'Please enter a valid website URL (e.g. http://example.com)',
  EDIT_PASSWORD: 'Edit password',
  REMOVE_PASSWORD: 'Remove password',
  SUCCESSFULLY_SAVED_THE_PASSWORD: 'Successfully saved the password',
  FAILED_TO_SAVE_THE_PASSWORD: 'Failed to save the password',
  PASSWORD_LINKS: 'Password links',
  SELECT_PASSWORD: 'Select password',
  ADD_NEW_PASSWORD: 'Add new password',
  LINK_PASSWORD: 'Link password',
  SUCCESSFULLY_DELETED_THE_PASSWORD: 'Successfully deleted the password',
  FAILED_TO_DELETE_THE_PASSWORD: 'Failed to delete the password',
  UNLINK_PASSWORD: 'Unlink password',
  SUCCESSFULLY_UNLINKED_PASSWORD: 'Successfully unlinked the password',
  FAILED_TO_UNLINK_PASSWORD: 'Failed to unlink password',
  PASSWORD_DETAILS: 'Password details',
  UNLINK_PASSWORD_CONFIRM: 'Are you sure to unlink this password?',
  PASSWORD_RECORDS: 'password(s)',
  SELECT_PASSWORDS: 'Select passwords',
  TOO_SHORT: 'Too short',
  TOO_WEAK: 'Too weak',
  WEAK: 'Weak',
  FAIR: 'Fair',
  GOOD: 'Good',
  STRONG: 'Strong',
  WAITING_FOR_DISCONNECTION: 'Waiting for disconnection',
  FAILED_TO_DISCONNECT_USER: 'Failed to disconnect user',
  ARE_YOU_SURE_TO_DISCONNECT_THIS_USER: 'Are you sure to disconnect this user?',
  DISCONNECT: 'Disconnect',
  DISCONNECT_USER_REQUESTS: 'Disconnect user requests',
  REQUEST_TO_DISCONNECT_ACCOUNT:
    'Your deputies have sent a request to disconnect your account',
  APPROVE_TO_DISCONNECT: 'Approve to disconnect',
  TRANSFER_TO_AN_OTHER_DEPUTY: 'Transfer to another deputy',
  THE_PD_IS_WAITING_FOR_DISCONNECTION:
    'The Professional Deputy is waiting for disconnection',
  NO_OF_YEARS_REPAYMENT_COMPLETED: 'No. of years / repayments completed',
  USE_EMAIL_OTP_CODE: 'Use email OTP code',
  COLOR: 'Color',
  INCORRECT_PASSWORD: 'Incorrect password!',
  RELEASE_PASSWORD: 'Release password',
  SUCCESSFULLY_RELEASE_PASSWORD: 'Successfully release password',
  FAILED_TO_RELEASE_PASSWORD: 'Failed to release password',
  PRIVATE_FOLDER: 'Private Folder',
  CREATE_PRIVATE_FOLDER: 'Create private folder',
  WARNING_MSG: 'Warning message',
  PERMANENTLY_DELETE_PRIVATE_FOLDER_WARNING:
    'You have to delete all private files before deleting the private folder',
  PASSWORD_OF_THE_PRIVATE_FOLDER: 'Password of the private folder',
  DOWNLOAD_PRIVATE_FOLDER_MSG:
    'Please enter <strong>the password of the Private Folder</strong> to download Vaultbox include the Private Folder',
  SET_PASSWORD: 'Set password',
  SUCCESSFULLY_SET_PASSWORD: 'Successfully set password',
  FAILED_TO_SET_PASSWORD: 'Failed to set password',
  OTHER_PERSON_WARNING_MSG:
    'You can not choose the Other Person who does not have an email and phone number',
  RELEASE_PASSWORD_NOTES:
    'If user has the Private Folder, Professional Deputy must unlock user for releasing the password',
  YOU_CANNOT_NAME_THE_FOLDER_PRIVATE:
    'You cannot name the folder "Private Folder"',
  SUN: 'Sunday',
  MON: 'Monday',
  TUE: 'Tuesday',
  WED: 'Wednesday',
  THU: 'Thursday',
  FRI: 'Friday',
  SAT: 'Saturday',
  SUN_MD: 'Sun',
  MON_MD: 'Mon',
  TUE_MD: 'Tue',
  WED_MD: 'Wed',
  THU_MD: 'Thu',
  FRI_MD: 'Fri',
  SAT_MD: 'Sat',
  JAN: 'January',
  FEB: 'February',
  MAR: 'March',
  APR: 'April',
  MAY: 'May',
  JUN: 'June',
  JUL: 'July',
  AUG: 'August',
  SEP: 'September',
  OCT: 'October',
  NOV: 'November',
  DEC: 'December',
  JAN_MD: 'Jan',
  FEB_MD: 'Feb',
  MAR_MD: 'Mar',
  APR_MD: 'Apr',
  MAY_MD: 'May',
  JUN_MD: 'Jun',
  JUL_MD: 'Jul',
  AUG_MD: 'Aug',
  SEP_MD: 'Sep',
  OCT_MD: 'Oct',
  NOV_MD: 'Nov',
  DEC_MD: 'Dec',
  MONTH: 'Month',
  YEAR: 'Year',
  SELECT_EVENT: 'Select event',
  ADD_NEW_EVENT: 'Add new event',
  TO: 'To',
  LOGIN_WITH_GOOGLE: 'Login with Google',
  LOGIN_WITH_FACEBOOK: 'Login with Facebook',
  VALUATION_DATE_IN_BASE_CURRENCY: 'Valuation date in base currency',
  CREATE_TEXT_FILE: 'Create new text file',
  EDIT_TEXT_FILE: 'Edit text file',
  PASSWORD_COPIED: 'Password copied!',
  COPY_PASSWORD: 'Copy password',
  WEBSITE: 'Website',
  UPGRAGE_PLAN_SUMMARY:
    'vaultbox Essential Plan limit of 20 items have been reached. Please upgrade your subscription plan to save more records.',
  LIMITED_STORAGE_VAULTBOX_LITE: '2 GB of secure file storage',
  LIMITED_RECORD_VAULTBOX_LITE:
    '20 records is the limit for the total of: asset/liability, files, contacts, passwords and events',
  MARKETING_MATERIALS: 'Marketing materials',
  MARKETING_MATERIALS_STATUS_TEXT:
    'You can choose to receive or not to receive marketing materials',
  INPUT_COUNTRY_MSG: 'Select your country!',
  APPLY: 'Apply',
  WAITING_FOR_REDIRECT: 'Waiting for redirect',
  ACCESS_DENIED: 'Access denied',
  ARE_YOU_SURE_YOU_WANT_TO_RESET_PASSWORD_OF_PRIVATE_FOLDER:
    'Are you sure you want to reset password of Private Folder?',
  RESET_PASSWORD_OF_PRIVATE_FOLDER: 'Reset password of Private Folder',
  SUCCESSFULLY_RESET_PASSWORD_OF_PRIVATE_FOLDER:
    'Successfully reset password of Private Folder',
  FAILED_TO_RESET_PASSWORD_OF_PRIVATE_FOLDER:
    'Failed to reset password of Private Folder',
  SKIP_PRIVATE_FOLDER: 'Skip Private Folder',
  CANNOT_MOVE_LOCK_FILE_TO_PRIVATE_FOLDER:
    'Cannot move lock file(s) to Private Folder',
  CANNOT_COPY_LOCK_FILE_TO_PRIVATE_FOLDER:
    'Cannot copy lock file(s) to Private Folder',
  CANNOT_CHOOSE_FILE_FROM_PRIVATE_FOLDER:
    'Cannot choose file from Private Folder',
  RESET_PASSWORD_MSG: 'Enter the password of your vaultbox account to proceed',
  LOCK_PRIVATE_FOLDER_MSG:
    'Your access to the Private Folder will be denied for 24 hours, please try again later.',
  RECORD: 'Record',
  YOU_NEED_TO_UPGRADE_YOUR_PLAN:
    'You are about to run out of records/storage limit of <strong>vaultbox Essential</strong>, please upgrade to have a better experience',
  DIGITAL_VAULT: 'Digital vault',
  DIGITAL_VAULT_CONTENT:
    'Store your will, insurance policies, and other key documents with bank quality encryption. 2GB of complimentary storage covers your basic needs of most users.',
  ASSETS_AND_LIABILITY_REGISTRY_CONTENT:
    'Track your net worth & generate financial reports',
  PASSWORD_VAULT: 'Password vault',
  PASSWORD_VAULT_CONTENT:
    'Secure all your passwords in one centralised location',
  CONTACT_MANAGEMENT: 'Contact management',
  CONTACT_MANAGEMENT_CONTENT:
    'Store all your vital contacts (e.g. Lawyer, insurance agent, bank officer)',
  CALENDAR_FEATURE: 'Calendar feature',
  CALENDAR_FEATURE_CONTENT: 'Keep track of your payments and subscriptions',
  ESSENTIAL_VAULT_STORAGE: '2GB storage (up to 20 items)',
  INCLUDE_EVERYTHING_IN_VAULTBOX_ESSENTIAL:
    'Include everything in vaultbox Essential',
  DEPUTIES_CONTENT:
    'Appoint family members and trusted individuals as part of the  "smart legacy management"',
  PROFESSIONAL_DEPUTY: 'Professional Deputy',
  PRO_STORAGE: '50GB Storage',
  ADD_ON_THIS_SERVICE_AT: 'Add on this service at',
  FREE_TO_USE_GET_STARTED_NOW_UPGRADE_ANYTIME:
    'Free-To-Use. Get started now. Upgrade anytime.',
  ULTIMATE_FINANCIAL_AND_LEGACY_PLAN: 'Ultimate financial and legacy plan.',
  IDEAL_FOR_SMALL_ENTERPRISES_AND_FAMILY:
    'Ideal for small enterprises and family.',
  RECORDS: 'Records',
  AVATAR: 'Avatar',
  SUCCESSFULLY_UPLOADED_AVATAR: 'Successfully uploaded avatar',
  EDIT_AVATAR: 'Edit avatar',
  UPLOAD_AVATAR: 'Upload avatar',
  FAILED_UPLOAD_AVATAR_MSG:
    'Image failed to upload. Only supports PNG, JPEG at a maximum size of 2MB. Please try again.',
  DRAG_AND_DROP_IMAGE_HERE_OR: 'Drag & drop image here or',
  BROWSE_FROM_YOUR_COMPUTER: 'Browse from your computer',
  ZOOM: 'Zoom',
  ADD_MEMBERS: 'Add members',
  SUCCESSFULLY_ADDED_MEMBERS: 'Successfully added members',
  SUCCESSFULLY_LEFT_GROUP: 'Successfully left group',
  SUCCESSFULLY_REMOVED_MEMBERS: 'Successfully removed member',
  FAILED_TO_ADD_MEMBERS: 'Failed to add members',
  FAILED_TO_LEAVE_GROUP: 'Failed to leave group',
  FAILED_TO_REMOVE_MEMBER: 'Failed to remove member',
  PLEASE_ADD_AT_LEAST_ONE_EMAIL: 'Please add at least one email!',
  LIMIT_MEMBER_MSG:
    'Up to 5 users (including main user) can be added. Please check and try again.',
  ONE_OR_MORE_EMAIL_ALREADY_EXIST:
    'One or more emails already exist in the group. Please choose other emails',
  ARE_YOU_SURE_TO_REMOVE_THIS_MEMBER: 'Are you sure to remove this member?',
  ARE_YOU_SURE_TO_LEFT_GROUP: 'Are you sure to left group?',
  LEAVE_GROUP: 'Leave group',
  MEMBERS: 'Members',
  NOT_BE_INVITED_TO_GROUP_MSG:
    'You need to be invited to join this vaultbox Group Plan',
  //CONSTANT
  REQUESTED_TO_RELEASE: 'Requested to release',
  COPY_USERNAME: 'Copy username',
  USERNAME_COPIED: 'Username copied!',
  ADD_PASSWORD: 'Add password',
  MAIN_USER_CHANGE_SUBSCRIPTION_MSG:
    'You cannot change your subscription when there are still member(s) in the group',
  LOADING: 'Loading',
  SUCCESSFULLY_ACCESSED_YOUR_PRIVATE_FOLDER: '{{name}} ({{email}}) Successfully accessed your Private Folder',
  ATTEMPT_TO_OPEN_THE_LOCKED_PRIVATE_FOLDER: '{{name}} ({{email}}) Attempt to open the locked Private Folder',
  PLEASE_MAKE_SURE_THAT_YOUR_FACEBOOK_ACCOUNT_HAS_AN_EMAIL: 'Please make sure that your Facebook account has an email',
  SUCCESSFULLY_SET_COLOR: 'Successfully to set color',
  FAILED_TO_SET_COLOR: 'Failed to set color',
  COLORS_CUSTOMIZATION: 'Colors customization',
  RESET_TO_DEFAULT: 'Reset to default',
  FAILED_TO_RESET_COLOR: 'Failed to reset color',
  PENDING_PASSWORDS: 'Pending passwords',
  REJECTED_PASSWORDS: 'Rejected passwords',
  PENDING_EVENTS: 'Pending events',
  REJECTED_EVENTS: 'Rejected events',
  UNLINK_EVENT: 'Unlink event',
  CHANGE_VAULTBOX_PRO_TO_VAULTBOX_GROUP_MSG:
    'You will immediately switch to vaultbox Group. Are you sure to change subscription?',
  INVITATIONS: 'Invitations',
  SUCCESSFULLY_ACCEPTED_INVITATION: 'Successfully accepted invitation',
  FAILED_TO_ACCEPT_INVITATION: 'Failed to accept invitation!',
  SUCCESSFULLY_DECLINED_INVITATION: 'Successfully declined invitation',
  FAILED_TO_DECLINE_INVITATION: 'Failed to decline invitation!',
  GROUP_OWNER: 'Group owner',
  LOGOUT_AND_ADD_CARD_LATER: 'Logout and add card later',
  INCLUDE_EVERYTHING_IN_VAULTBOX_PRO: 'Include everything in vaultbox Pro',
  DEPUTIES_FOR_ALL_MEMBERS: 'Deputies for all members',
  FOR_UP_TO_MEMBERS: 'For up to 5 members',
  None: 'None',
  Weekly: 'Weekly',
  Monthly: 'Monthly',
  Quarterly: 'Quarterly',
  Yearly: 'Yearly',
  Annually: 'Annually',
  day: 'day',
  month: 'month',
  year: 'year',
  days: 'days',
  Months: 'Months',
  months: 'months',
  Years: 'Years',
  years: 'years',
  Cash: 'Cash',
  Property: 'Property',
  Vehicle: 'Vehicle',
  Insurance: 'Insurance',
  Crypto: 'Crypto',
  Trust: 'Trust',
  NRIC: 'NRIC',
  FIN: 'FIN',
  Passport: 'Passport',
  Personal: 'Personal',
  Other: 'Other',
  Mobile: 'Mobile',
  Work: 'Work',
  Home: 'Home',
  Hospitalisation: 'Hospitalisation',
  Travel: 'Travel',
  House: 'House',
  Apartment: 'Apartment',
  Commercial: 'Commercial',
  Freehold: 'Freehold',
  Leasehold: 'Leasehold',
  name: 'name',
  value: 'value',
  Currency: 'Currency',
  Items: 'Items',
  Valuation: 'Valuation',
  Ticker: 'Ticker',
  ISIN: 'ISIN',
  Individual: 'Individual',
  Organisation: 'Organisation',
  Savings: 'Savings',
  Current: 'Current',
  Jewellery: 'Jewellery',
  Furnishing: 'Furnishing',
  Antiques: 'Antiques',
  Art: 'Art',
  Collectible: 'Collectible',
  Settlor: 'Settlor',
  Trustee: 'Trustee',
  Beneficiary: 'Beneficiary',
  Protector: 'Protector',
  Settlors: 'Settlors',
  Trustees: 'Trustees',
  Beneficiaries: 'Beneficiaries',
  Protectors: 'Protectors',
  Irrevocable: 'Irrevocable',
  Revocable: 'Revocable',
  Private: 'Private',
  Bare: 'Bare',
  Charitable: 'Charitable',
  Investment: 'Investment',
  Fixed: 'Fixed',
  Variable: 'Variable',
  Ordinary: 'Ordinary',
  Preference: 'Preference',
  Convertible: 'Convertible',
  CPF: 'CPF',
  SRS: 'SRS',
  Superannuation: 'Superannuation',
  Pension: 'Pension',
  Education: 'Education',
  'Total and Permanent Disability': 'Total and Permanent Disability',
  'Investment-Linked Policy': 'Investment-Linked Policy',
  'Income/Endowment/Savings': 'Income/Endowment/Savings',
  'Annuity/Retirement': 'Annuity/Retirement',
  'Life – Whole': 'Life – Whole',
  'Personal Accident': 'Personal Accident',
  'Precious stone': 'Precious stone',
  'Structured deposit': 'Structured deposit',
  'Fixed deposit': 'Fixed deposit',
  'Plain Text': 'Plain Text',
  'Word Document': 'Word Document',
  'Title with icon': 'Title with icon',
  'Other medical': 'Other medical',
  'Critical Illness': 'Critical Illness',
  'Life - Universal': 'Life - Universal',
  'Life - Term': 'Life - Term',
  'Other assets': 'Other assets',
  'Property loan': 'Property loan',
  'Vehicle loan': 'Vehicle loan',
  'Credit card': 'Credit card',
  'Loan from others': 'Loan from others',
  'Loan to others': 'Loan to others',
  'Other liability': 'Other liability',
  'Retirement account': 'Retirement account',
  'Brokerage account': 'Brokerage account',
  'Equity - listed': 'Equity - listed',
  'Equity - unlisted': 'Equity - unlisted',
  'per month': 'per month',
  'per year': 'per year',
  'Free Sign Up': 'Free Sign Up',
  'No Credit Card Required': 'No Credit Card Required'
}
