import React, { useState, useContext } from 'react'
import { Form, Modal, message, Select } from 'antd'
import { Span } from '../override/Typography'
import api from '../../lib/api'
import FormItem from '../override/FormItem'
import AuthContext from '../../contexts/AuthContext'
import {
  INACTIVITY_DURATIONS,
  INACTIVITY_REMINDER_DURATIONS
} from '../../share/Constants'
import moment from 'moment'
import config from '../../config'
import { onError } from '../../lib/sentry'
import { useDispatch } from 'react-redux'
import { fetchUser } from '../../features/user/userSlice'
import { useTranslation } from 'react-i18next'

const inactivityDurations =
  config.env === 'prod'
    ? INACTIVITY_DURATIONS.filter(d => d !== '5 minutes')
    : INACTIVITY_DURATIONS

const inactivityReminderDurations =
  config.env === 'prod'
    ? INACTIVITY_REMINDER_DURATIONS.filter(d => d !== '5 minutes')
    : INACTIVITY_REMINDER_DURATIONS

function ConfigInactivityModal({
  form,
  configModalVisible,
  setConfigModalVisible,
  inactivityStateConfig
}) {
  const { user } = useContext(AuthContext)
  const { getFieldDecorator } = form
  const [isSaving, setIsSaving] = useState(false)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const handleSave = e => {
    form.validateFields(async (err, values) => {
      if (err) return
      try {
        setIsSaving(true)
        const inactivityDuration = values?.inactivityDuration.split(' ')
        const inactivityReminderResponseDuration = values?.inactivityReminderResponseDuration.split(
          ' '
        )
        const inactivityMilliseconds = moment
          .duration(+inactivityDuration[0], inactivityDuration[1])
          .asMilliseconds()
        const inactivityReminderResponseMilliseconds = moment
          .duration(
            +inactivityReminderResponseDuration[0],
            inactivityReminderResponseDuration[1]
          )
          .asMilliseconds()

        await api.saveInactivityStateConfig(user.username, {
          inactivityDuration: {
            value: inactivityDuration[0],
            unit: inactivityDuration[1],
            milliseconds: inactivityMilliseconds
          },
          inactivityReminderResponseDuration: {
            value: inactivityReminderResponseDuration[0],
            unit: inactivityReminderResponseDuration[1],
            milliseconds: inactivityReminderResponseMilliseconds
          },
          inactivityCheckEnabled: true
        })

        dispatch(fetchUser(user.username))

        setConfigModalVisible(false)
        setIsSaving(false)
        message.success(t('SUCCESSFULLY_SAVED_INACTIVIY_STATE'))
      } catch (e) {
        setIsSaving(false)
        message.error(t('FAILED_TO_SAVE_INACTIVIY_STATE'))
        onError(e)
      }
    })
  }

  return (
    <>
      <Modal
        title={t('INACTIVITY_STATE_CONFIG')}
        visible={configModalVisible}
        okText={t('SAVE')}
        cancelText={t('CANCEL')}
        okButtonProps={{
          loading: isSaving
        }}
        onOk={handleSave}
        onCancel={() => {
          setConfigModalVisible(false)
          form.resetFields()
        }}
      >
        <>
          <Form>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                textAlign: 'justify'
              }}
            >
              <Span style={{ lineHeight: '60px' }}>
                {t('WHEN_YOU_ARE_INACTIVE_FOR')}{' '}
              </Span>
              <FormItem>
                {getFieldDecorator('inactivityDuration', {
                  initialValue: inactivityStateConfig?.inactivityDuration
                    ? (inactivityStateConfig.inactivityDuration?.value || '') +
                      ' ' +
                      (inactivityStateConfig.inactivityDuration?.unit || '')
                    : ''
                })(
                  <Select style={{ width: 120, margin: 5 }}>
                    {inactivityDurations.map(value => (
                      <Select.Option key={value} value={value}>
                        {value}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </FormItem>
              <Span style={{ lineHeight: '60px' }}>
                {t('WE_WILL_SEND_YOU_REMINDER')},
              </Span>
              <Span style={{ lineHeight: '60px' }}>
                {t('BUT_IF_YOU_DONOT_RESPOND_WITHIN')}
              </Span>
              <FormItem>
                {getFieldDecorator('inactivityReminderResponseDuration', {
                  initialValue: inactivityStateConfig?.inactivityReminderResponseDuration
                    ? (inactivityStateConfig.inactivityReminderResponseDuration
                        ?.value || '') +
                      ' ' +
                      (inactivityStateConfig.inactivityReminderResponseDuration
                        ?.unit || '')
                    : ''
                })(
                  <Select style={{ width: 100, margin: 5 }}>
                    {inactivityReminderDurations.map(value => (
                      <Select.Option key={value} value={value}>
                        {value}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </FormItem>
              <Span style={{ lineHeight: '60px' }}>
                {' '}
                {t('OF_THE_REMINDER')}
              </Span>
            </div>
            <Span>
              {t('WE_WILL_INFORM_YOUR_DEPUTIES_ABOUT_YOUR_INACTIVITY')}
            </Span>
          </Form>
        </>
      </Modal>
    </>
  )
}

const WrappedConfigInactivityForm = Form.create({
  name: 'ConfigInactivityModal'
})(ConfigInactivityModal)

export default WrappedConfigInactivityForm
