import { createSlice } from '@reduxjs/toolkit'
import { getRecords } from '../../lib/pouchDb'
import { onError } from '../../lib/sentry'

const initialState = {
  contacts: [],
  deletedContacts: [],
  activeContacts: [],
  pendingContacts: [],
  contactTags: [],
  pendingContactTags: [],
  pendingLoading: false,
  pendingError: null,
  isLoading: false,
  error: null
}

const otherContacts = createSlice({
  name: 'otherContacts',
  initialState,
  reducers: {
    getOtherContactsStart(state) {
      state.isLoading = true
      state.error = null
    },
    getOtherContactsSuccess(state, action) {
      const { contacts } = action.payload
      state.contacts = contacts
      state.deletedContacts = contacts.filter(contact => contact.deleted)
      state.activeContacts = contacts.filter(contact => !contact.deleted)
      state.contactTags = contacts
        .filter(contact => !contact.deleted &&!!contact.tags?.length)
        .map(contact => contact.tags)
      state.isLoading = false
      state.error = null
    },
    getOtherContactsFailure(state, action) {
      state.isLoading = false
      state.error = action.payload
    },
    getOtherPendingContactsStart(state) {
      state.pendingLoading = true
      state.pendingError = null
    },
    getOtherPendingContactsSuccess(state, action) {
      const { contacts } = action.payload
      state.pendingContacts = contacts.filter(c => c.status === 'Draft')
      state.rejectedContacts = contacts.filter(c => c.status === 'Rejected')
      state.pendingContactTags = contacts
        .filter(contact => !!contact.tags?.length)
        .map(contact => contact.tags)
      state.pendingLoading = false
      state.pendingError = null
    },
    getOtherPendingContactsFailure(state, action) {
      state.pendingLoading = false
      state.pendingError = action.payload
    }
  }
})

export const {
  getOtherContactsStart,
  getOtherContactsSuccess,
  getOtherContactsFailure,
  getOtherPendingContactsStart,
  getOtherPendingContactsSuccess,
  getOtherPendingContactsFailure
} = otherContacts.actions

export default otherContacts.reducer

export const fetchOtherContacts = (userId, masterKey) => async dispatch => {
  try {
    dispatch(getOtherContactsStart())
    const contacts = await getRecords(userId, 'contacts', masterKey)
    dispatch(getOtherContactsSuccess({ contacts }))
  } catch (err) {
    onError(err)
    dispatch(getOtherContactsFailure(err.toString()))
  }
}

export const fetchOtherPendingContacts =
  (userId, masterKey) => async dispatch => {
    try {
      dispatch(getOtherPendingContactsStart())
      const contacts = await getRecords(userId, 'pendingContacts', masterKey)
      dispatch(getOtherPendingContactsSuccess({ contacts }))
    } catch (err) {
      onError(err)
      dispatch(getOtherPendingContactsFailure(err.toString()))
    }
  }
