import React, { useState, useContext, useEffect } from 'react'
import {
  List,
  Icon,
  Button,
  Menu,
  Modal,
  Dropdown,
  Tooltip,
  Table,
  message,
  Divider,
  Input,
  Alert
} from 'antd'
import FileDetails from './FileDetails'
import {
  toggleStar,
  unlinkDocument,
  deleteDocument,
  deleteFolder,
  renameFolder,
  moveItem,
  bulkDeleteDocuments,
  moveItems,
  copyItems,
  unlinkDocuments,
  newDocumentsToCopy,
  rejectDocument,
  approveDocument,
  permanentlyDeleteDocuments,
  uploadEncryptedData
} from '../../lib/pouchDb'
import WrappedForm from '../common/WrappedForm'
import FormItem from '../override/FormItem'
import { P } from '../override/Typography'
import { filesGridLayout, filesGridLayoutInModal } from '../../share/Layout'
import CreateFolderModal from '../file/CreateFolderModal'
import CreatePrivateFolderModal from './CreatePrivateFolderModal'
import PasswordFolderModal from './PasswordFolderModal'
import {
  queryLinkedRecords,
  showUpgradeSubscriptionPlanConfirm
} from '../../share/helpers'
import { useSelector, useDispatch } from 'react-redux'
import FileDirectory from '../file/FileDirectory'
import { ThemeContext } from 'styled-components'
import { s3Get } from '../../lib/awsSDK'
import { decryptFile } from '../../lib/crypto'
import { Span } from '../override/Typography'
import { uniqBy, uniq, sum } from 'lodash'
import { getLegacyInfo } from '../../features/settings/settingsSlice'
import VaultContext from '../../contexts/VaultContext'
import { onError } from '../../lib/sentry'
import { useMediaQuery } from '@material-ui/core'
import { useTranslation, Trans } from 'react-i18next'
import { removeHtmlTags, errorMessage } from './../../share/helpers'
import { ACCESS_LEVEL, AUTH_FLOW, MFA_TYPES } from './../../share/Constants'
import AuthContext from '../../contexts/AuthContext'
import { fetchPendingDocuments } from './../../features/documents/documentsSlice'
import RejectModal from '../modals/RejectModal'
import '../common/Common.scss'
import { useMutation } from 'react-apollo-hooks'
import { createS3Change } from '../../graphql/mutations'
import api from '../../lib/api'
import SubscriptionModal from '../payment/SubscriptionModal'
import { getAuthenticationDetails } from '../../lib/cognito'
import MfaForm from '../common/MfaVerificationForm'
import PouchDB from 'pouchdb'
import { AES, enc } from 'crypto-js'

function StarIcon({ item, handleStar, isReadonly, t }) {
  const theme = useContext(ThemeContext)

  return (
    <Tooltip
      title={
        isReadonly
          ? null
          : item.starred
          ? t('REMOVE_FROM_FAVOURITES')
          : t('ADD_TO_FAVOURITES')
      }
    >
      <Icon
        type="star"
        theme={item.starred ? 'filled' : 'outlined'}
        style={{ color: theme.secondaryOrange }}
        onClick={e => {
          e.stopPropagation()
          handleStar(item)
        }}
      />
    </Tooltip>
  )
}

let passwordFormRef, callbacks, formRef, cognitoUser, authDetails

// TODO: Crop file name when it's too long
export default function FileList(props) {
  const {
    content,
    isReadonly,
    linkedAssetLiabilityId,
    filteredDocIds,
    setDocToLink,
    userId,
    breadcrumb,
    setBreadcrumb,
    viewMode,
    showStarredDocsOnly,
    setShowStarredDocsOnly,
    setEnableCreateFile,
    setSelectedTextFile,
    isModal,
    isSelectedMutilpleFiles,
    setSelectedFilesFolders,
    selectedFilesFolders
  } = props
  const theme = useContext(ThemeContext)
  const { isProfessionalDeputy, isDelegateByPD, user } = useContext(AuthContext)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const {
    activeDocuments,
    activeFolders,
    activeFiles,
    pendingFiles,
    usedStorage,
    pendingDocuments
  } = useSelector(state =>
    isReadonly ? state.otherDocuments : state.documents
  )

  const { allowedStorage } = useSelector(state => state.customer)
  const { limit } = useSelector(state => state.customer)
  const { accessLevel } = useSelector(state => state.settings)
  const { currentFlow } = useSelector(state => state.user).user

  const { activePasswords, pendingPasswords } = useSelector(state =>
    isReadonly ? state.otherPasswords : state.passwords
  )

  const { activeContacts, pendingContacts } = useSelector(state =>
    isReadonly ? state.otherContacts : state.contacts
  )
  const { activeAssetsLiabilities, pendingAssetsLiabilities } = useSelector(
    state =>
      isReadonly ? state.otherAssetsLiabilities : state.assetsLiabilities
  )
  const { eventsFromPouchDB: events, pendingEventsFromPouchDB: pendingEvents } =
    useSelector(state => (isReadonly ? state.otherEvents : state.events))

  const {
    masterKey,
    fullName,
    privateFolderKey,
    limitedRecord,
    isLockPrivateFolder
  } = useContext(VaultContext)

  const [visible, setVisible] = useState(false)
  const [editFormVisible, setEditFormVisible] = useState(false)
  const [isChangePassword, setIsChangePassword] = useState(false)
  const [createPrivateFolderVisible, setCreatePrivateFolderVisible] =
    useState(false)
  const [passwordFolderModalVisible, setPasswordFolderModalVisible] =
    useState(false)
  const [isShowLinkedDocument, setIsShowLinkedDocument] = useState(false)
  const [docItem, setDocItem] = useState({})
  const [selectedDoc, setSelectedDoc] = useState()
  const [directoryModalVisible, setDirectoryModalVisible] = useState(false)
  const [destinationFolder, setDestinationFolder] = useState('')
  const [action, setAction] = useState('')
  const [isMovingOrCopying, setIsMovingOrCopying] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
  const [rejectModalVisible, setRejectModalVisible] = useState(false)
  const [rejectRecord, setRejectRecord] = useState({})
  const [rejecting, setRejecting] = useState(false)
  const [isMoveOrCopy, setIsMoveOrCopy] = useState(false)
  const [isDeletePrivateFolder, setIsDeletePrivateFolder] = useState(false)
  const [subscriptionModalVisible, setSubscriptionModalVisible] = useState()
  const [passwordModalVisible, setPasswordModalVisible] = useState(false)
  const [errMsg, setErrMsg] = useState('')
  const [mfaModalVisible, setMfaModalVisible] = useState(false)
  const [isResendEmailCode, setIsResendEmailCode] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [mfaType, setMfaType] = useState()
  let rejectFormRef

  const [addS3Change] = useMutation(createS3Change)


  useEffect(() => {
    setSelectedRows([])
  }, [breadcrumb])

  const privateFolder = activeFolders.find(folder => folder.isPrivate)

  const privateFolderPassword = !!privateFolder?.password
    ? AES.decrypt(privateFolder.password, privateFolderKey).toString(enc.Latin1)
    : undefined

  const handleDoubleClick = item => {
    if (item.type === 'folder') {
      setDocItem(item)
      if (
        item.path.slice(0, privateFolder?.path.length) ===
          privateFolder?.path &&
        !privateFolder?.password
      ) {
        setCreatePrivateFolderVisible(true)
      } else if (
        item.path.slice(0, privateFolder?.path.length) ===
          privateFolder?.path &&
        privateFolder?.password
      ) {
        if (isLockPrivateFolder) {
          Modal.warning({
            title: t('WARNING_MSG'),
            content: t('LOCK_PRIVATE_FOLDER_MSG')
          })
        } else if (
          !!privateFolderPassword &&
          localStorage.getItem('privateFolderPassword') ===
            privateFolderPassword
        ) {
          setBreadcrumb(item.path)
          showStarredDocsOnly && setShowStarredDocsOnly(false)
        } else {
          setPasswordFolderModalVisible(true)
        }
      } else {
        setBreadcrumb(item.path)
        showStarredDocsOnly && setShowStarredDocsOnly(false)
      }
    } else if (item.type === 'file-text') {
      if (
        item.path.slice(0, privateFolder?.path.length) ===
          privateFolder?.path &&
        !privateFolder?.password
      ) {
        setCreatePrivateFolderVisible(true)
      } else if (
        item.path.slice(0, privateFolder?.path.length) ===
          privateFolder?.path &&
        !!privateFolder?.password
      ) {
        if (isLockPrivateFolder) {
          Modal.warning({
            title: t('WARNING_MSG'),
            content: t('LOCK_PRIVATE_FOLDER_MSG')
          })
        } else if (
          (!!privateFolderPassword &&
            localStorage.getItem('privateFolderPassword') ===
              privateFolderPassword) ||
          breadcrumb === privateFolder?.path
        ) {
          setDocItem(item)
          setVisible(true)
        } else {
          setIsShowLinkedDocument(true)
          setDocItem(item)
          setPasswordFolderModalVisible(true)
        }
      } else {
        setDocItem(item)
        setVisible(true)
      }
    }
  }

  const handleClick = item => {
    if (isModal && !isSelectedMutilpleFiles) {
      setSelectedDoc(item.id)
      if (!setDocToLink) return
      if (item.type === 'file-text' && !isLinked(item.id)) {
        setDocToLink(item.id)
      } else {
        setDocToLink(null)
      }
    } else {
      const selectedItems = isSelectedMutilpleFiles
        ? selectedFilesFolders
        : selectedRows

      const selected = selectedItems.find(sr => sr.id === item.id)
        ? selectedItems.filter(sr => sr.id !== item.id)
        : [...selectedItems, item]

      setSelectedRows(
        selected.filter(sr =>
          sr.type === 'folder'
            ? !sr.status &&
              sr.pendingFilesCount === 0 &&
              sr.pendingFoldersCount === 0
            : !sr.status
        )
      )
      if (isSelectedMutilpleFiles) setSelectedFilesFolders(selected)
    }
  }

  const handleStar = async item => {
    if (isReadonly) return

    try {
      await toggleStar(userId, item.id, masterKey)
      localStorage.setItem('NotReload', true)
      addS3Change({
        variables: {
          message: 'documents, pendingDocuments',
          userId: userId
        }
      })
    } catch (e) {
      onError(e)
    }
  }

  const isLinked = itemId => {
    return filteredDocIds && filteredDocIds.includes(itemId)
  }

  const resetFields = () => {
    setErrMsg('')
    formRef && formRef.props.form.resetFields()
    passwordFormRef && passwordFormRef.props.form.resetFields()
    passwordModalVisible && setPasswordModalVisible(false)
    mfaModalVisible && setMfaModalVisible(false)
  }

  const handleConfirmPassword = () => {
    setErrMsg('')

    return new Promise((resolve, reject) => {
      passwordFormRef.props.form.validateFields((err, values) => {
        if (err) {
          // TODO: this will close the modal immediately and can't see the validation error
          resolve(t('INVALID_FORM'))
          return
        }

        cognitoUser = user
        authDetails = { username: user.username, password: values.password }

        authenticateUser(resolve, reject)
      })
    })
  }

  const authenticateUser = (resolve, reject) => {
    const authenticationDetails = getAuthenticationDetails(
      authDetails.username,
      authDetails.password
    )

    if (currentFlow === AUTH_FLOW.CUSTOM_FLOW) {
      setIsResendEmailCode(true)
      user.setAuthenticationFlowType('CUSTOM_AUTH')
    }

    user.authenticateUser(authenticationDetails, {
      onSuccess: async function (result) {
        try {
          const db = new PouchDB(`${userId}_documents`)
          db.crypto(masterKey)
          const record = {
            ...privateFolder,
            password: ''
          }
          await db.put(record)
          await uploadEncryptedData(db, userId, 'documents')
          addS3Change({
            variables: {
              message: 'documents',
              userId: user.username
            }
          })
          resolve(
            message.success(t('SUCCESSFULLY_RESET_PASSWORD_OF_PRIVATE_FOLDER'))
          )
          setMfaModalVisible(false)
          resetFields()
        } catch (err) {
          reject(message.error(t('FAILED_TO_RESET_PASSWORD_OF_PRIVATE_FOLDER')))
          onError(err)
        } finally {
          setIsSubmitting(false)
        }
      },

      onFailure: function (err) {
        setErrMsg(t('FAILED_TO_AUTHENTICATE_USER'))
        setIsSubmitting(false)
        reject(err)
      },
      customChallenge: function () {
        callbacks = this
        setMfaType(MFA_TYPES.EMAIL)
        setIsSubmitting(false)
        setMfaModalVisible(true)
        setIsResendEmailCode(false)
      },
      selectMFAType: function () {
        user.sendMFASelectionAnswer(MFA_TYPES.TOTP, this)
      },

      mfaRequired: function () {
        callbacks = this
        setMfaType(MFA_TYPES.SMS)
        setMfaModalVisible(true)
      },

      // Require if user have setup MFA successfully
      totpRequired: function () {
        callbacks = this
        setMfaType(MFA_TYPES.TOTP)
        setMfaModalVisible(true)
      }
    })
  }

  // Move to Trash
  const handleDeleteItem = item => {
    let linkedContacts = [],
      linkedPendingContacts = [],
      linkedEvents = [],
      linkedAssetsLiabilities = [],
      linkedPendingAssetsLiabilities = [],
      linkedItemsMessages = [],
      linkedPasswords = [],
      linkedPendingEvents = [],
      linkedPendingPasswords = []

    if (item.type !== 'folder') {
      linkedAssetsLiabilities = queryLinkedRecords(
        activeAssetsLiabilities,
        'documents',
        [item.id],
        'every'
      )

      linkedPendingAssetsLiabilities = queryLinkedRecords(
        pendingAssetsLiabilities,
        'documents',
        [item.id],
        'every'
      )

      linkedContacts = queryLinkedRecords(
        activeContacts,
        'documents',
        [item.id],
        'every'
      )

      linkedPendingContacts = queryLinkedRecords(
        pendingContacts,
        'documents',
        [item.id],
        'every'
      )

      linkedPasswords = queryLinkedRecords(
        activePasswords,
        'documents',
        [item.id],
        'every'
      )

      linkedPendingPasswords = queryLinkedRecords(
        pendingPasswords,
        'documents',
        [item.id],
        'every'
      )

      linkedEvents = queryLinkedRecords(events, 'documents', [item.id], 'every')

      linkedPendingEvents = queryLinkedRecords(
        pendingEvents,
        'documents',
        [item.id],
        'every'
      )

      const totalLinkedALs =
        linkedAssetsLiabilities.length + linkedPendingAssetsLiabilities.length

      if (
        linkedAssetsLiabilities?.length ||
        linkedPendingAssetsLiabilities?.length
      ) {
        linkedItemsMessages.push(
          `${totalLinkedALs} ${t('ASSET_LIABILITY_RECORDS')}`
        )
      }

      const totalLinkedContacts =
        linkedContacts.length + linkedPendingContacts.length

      if (linkedContacts?.length || linkedPendingContacts?.length) {
        linkedItemsMessages.push(
          `${totalLinkedContacts} ${t('CONTACT_RECORDS')}`
        )
      }

      linkedPasswords?.length &&
        linkedItemsMessages.push(
          `${linkedPasswords.length} ${t('PASSWORD_RECORDS')}`
        )

      linkedEvents?.length &&
        linkedItemsMessages.push(`${linkedEvents.length} ${t('EVENT_RECORDS')}`)
    }

    Modal.confirm({
      title: t('CONFIRM_DELETE'),
      content: (
        <>
          {!!linkedItemsMessages.length && (
            <p>
              {`${linkedItemsMessages.join(' & ')} ${t(
                'WILL_BE_UNLINKED_FROM_THIS_ITEM'
              )}`}
            </p>
          )}
          <div>{t('CONFIRM_DELETE_ITEM_MSG')}</div>
        </>
      ),
      async onOk() {
        try {
          if (item.type === 'folder') {
            if (item.isPrivate && item.password) {
              if (isLockPrivateFolder) {
                Modal.warning({
                  title: t('WARNING_MSG'),
                  content: t('LOCK_PRIVATE_FOLDER_MSG')
                })
              } else {
                setDocItem(item)
                setIsDeletePrivateFolder(true)
                setPasswordFolderModalVisible(true)
              }
            } else {
              await deleteFolder(activeDocuments, item, userId, masterKey)
            }
          } else {
            const legacyInfo = await getLegacyInfo(userId, masterKey)

            if (legacyInfo?.instructionFileId === item.fileId) {
              message.error(t('FILE_USED_IN_LEGACY_MANAGEMENT'))
              return
            }

            await deleteDocument(userId, item.id, masterKey)
            linkedContacts?.length &&
              (await unlinkDocument(
                userId,
                item.id,
                'contacts',
                linkedContacts,
                masterKey
              ))

            linkedPendingContacts?.length &&
              (await unlinkDocument(
                userId,
                item.id,
                'pendingContacts',
                linkedPendingContacts,
                masterKey
              ))

            linkedEvents?.length &&
              (await unlinkDocument(
                userId,
                item.id,
                'events',
                linkedEvents,
                masterKey
              ))

            linkedPendingEvents?.length &&
              (await unlinkDocument(
                userId,
                item.id,
                'pendingEvents',
                linkedPendingEvents,
                masterKey
              ))

            linkedPasswords?.length &&
              (await unlinkDocument(
                userId,
                item.id,
                'passwords',
                linkedPasswords,
                masterKey
              ))

            linkedPendingPasswords?.length &&
              (await unlinkDocument(
                userId,
                item.id,
                'pendingPasswords',
                linkedPendingPasswords,
                masterKey
              ))

            linkedAssetsLiabilities?.length &&
              (await unlinkDocument(
                userId,
                item.id,
                'assetsLiabilities',
                linkedAssetsLiabilities,
                masterKey
              ))

            linkedPendingAssetsLiabilities?.length &&
              (await unlinkDocument(
                userId,
                item.id,
                'pendingAssetsLiabilities',
                linkedPendingAssetsLiabilities,
                masterKey
              ))
          }

          localStorage.setItem('NotReload', true)
          addS3Change({
            variables: {
              message:
                'assetsLiabilities, pendingAssetsLiabilities, contacts, pendingContacts, documents, pendingDocuments, events, pendingEvents',
              userId: userId
            }
          })

          !item.isPrivate &&
            message.success(
              `${t('SUCCESSFULLY_DELETED')} ${
                item.type === 'folder'
                  ? t('FOLDER').toLowerCase()
                  : t('FILE').toLowerCase()
              }`
            )
        } catch (err) {
          message.error(
            `${t('FAILED_TO_DELETE')} ${
              item.type === 'folder'
                ? t('FOLDER').toLowerCase()
                : t('FILE').toLowerCase()
            }`
          )
          onError(err)
        }
      },
      onCancel() {}
    })
  }

  // delete multiple records
  const handleDeleteItems = async () => {
    let allLinkedContacts = [],
      allLinkedPendingContacts = [],
      allLinkedAssetsLiabilities = [],
      allLinkedPendingAssetsLiabilities = [],
      allLinkedEvents = [],
      allLinkedPasswords = [],
      linkedItemsMessages = [],
      allLinkedPendingEvents = [],
      allLinkedPendingPasswords = []

    if (selectedRows.find(sr => sr.type === 'folder' && sr.filesCount > 0)) {
      message.error(
        `${t('FAILED_TO_DELETE')}: ${t(
          'CANNOT_DELETE_FOLDERS_WITH_FILES_INSIDE'
        )}`
      )
      return
    }

    const fileItems = selectedRows.filter(sr => sr.type === 'file-text')

    const legacyInfo = await getLegacyInfo(userId, masterKey)

    if (legacyInfo) {
      const instructionFile = fileItems.find(
        item => item.fileId === legacyInfo.instructionFileId
      )
      if (instructionFile) {
        message.error(
          <Trans
            i18nKey="CANNOT_BE_DELETED_BECAUSE_IT_USED_IN_LEGACY_MANAGEMENT"
            values={{ name: instructionFile.name }}
          ></Trans>
        )
        return
      }
    }

    fileItems.forEach(item => {
      const linkedAssetsLiabilities = queryLinkedRecords(
        activeAssetsLiabilities,
        'documents',
        [item.id],
        'every'
      )

      const linkedPendingAssetsLiabilities = queryLinkedRecords(
        pendingAssetsLiabilities,
        'documents',
        [item.id],
        'every'
      )

      const linkedContacts = queryLinkedRecords(
        activeContacts,
        'documents',
        [item.id],
        'every'
      )

      const linkedPendingContacts = queryLinkedRecords(
        pendingContacts,
        'documents',
        [item.id],
        'every'
      )

      const linkedPasswords = queryLinkedRecords(
        activePasswords,
        'documents',
        [item.id],
        'every'
      )

      const linkedPendingPasswords = queryLinkedRecords(
        pendingPasswords,
        'documents',
        [item.id],
        'every'
      )

      const linkedEvents = queryLinkedRecords(
        events,
        'documents',
        [item.id],
        'every'
      )

      const linkedPendingEvents = queryLinkedRecords(
        pendingEvents,
        'documents',
        [item.id],
        'every'
      )

      linkedAssetsLiabilities?.length &&
        allLinkedAssetsLiabilities.push(linkedAssetsLiabilities)
      linkedPendingAssetsLiabilities?.length &&
        allLinkedPendingAssetsLiabilities.push(linkedPendingAssetsLiabilities)
      linkedContacts?.length && allLinkedContacts.push(linkedContacts)
      linkedPendingContacts?.length &&
        allLinkedPendingContacts.push(linkedPendingContacts)
      linkedEvents?.length && allLinkedEvents.push(linkedEvents)
      linkedPasswords?.length && allLinkedPasswords.push(linkedPasswords)
      linkedPendingEvents?.length &&
        allLinkedPendingEvents.push(linkedPendingEvents)
      linkedPendingPasswords?.length &&
        allLinkedPendingPasswords.push(linkedPendingPasswords)
    })

    const linkedAssetsLiabilitiesArr = uniqBy(
      allLinkedAssetsLiabilities?.flat(),
      ab => ab._id
    )
    const linkedPendingAssetsLiabilitiesArr = uniqBy(
      allLinkedPendingAssetsLiabilities?.flat(),
      ab => ab._id
    )
    const linkedContactsArr = uniqBy(allLinkedContacts?.flat(), c => c._id)

    const linkedPendingContactsArr = uniqBy(
      allLinkedPendingContacts?.flat(),
      c => c._id
    )

    const linkedEventsArr = uniqBy(allLinkedEvents?.flat(), e => e._id)
    const linkedPendingEventsArr = uniqBy(allLinkedEvents?.flat(), e => e._id)

    const linkedPasswordsArr = uniqBy(
      allLinkedPendingEvents?.flat(),
      e => e._id
    )
    const linkedPendingPasswordsArr = uniqBy(
      allLinkedPendingPasswords?.flat(),
      e => e._id
    )

    const totalLinkedALs =
      linkedAssetsLiabilitiesArr.length +
      linkedPendingAssetsLiabilitiesArr.length

    if (
      linkedAssetsLiabilitiesArr?.length ||
      linkedPendingAssetsLiabilitiesArr?.length
    ) {
      linkedItemsMessages.push(
        `${totalLinkedALs} ${t('ASSET_LIABILITY_RECORDS')}`
      )
    }

    const totalLinkedContacts =
      linkedContactsArr.length + linkedPendingContactsArr.length

    if (linkedContactsArr?.length || linkedPendingContactsArr?.length) {
      linkedItemsMessages.push(`${totalLinkedContacts} ${t('CONTACT_RECORDS')}`)
    }

    if (linkedEventsArr?.length || linkedPendingEventsArr?.length) {
      linkedItemsMessages.push(
        `${linkedEventsArr.length + linkedPendingEventsArr.length} ${t(
          'EVENT_RECORDS'
        )}`
      )
    }

    if (linkedPasswordsArr?.length || linkedPendingPasswordsArr?.length) {
      linkedItemsMessages.push(
        `${linkedPasswordsArr.length + linkedPendingPasswordsArr} ${t(
          'PASSWORD_RECORDS'
        )}`
      )
    }

    Modal.confirm({
      title: t('CONFIRM_DELETE'),
      content: (
        <>
          {!!linkedItemsMessages.length && (
            <p>
              {`${linkedItemsMessages.join(' & ')} ${t(
                'WILL_BE_UNLINKED_FROM_THIS_ITEM'
              )}`}
            </p>
          )}
          <div>{t('CONFIRM_DELETE_ITEMS_MSG')}</div>
        </>
      ),
      async onOk() {
        try {
          await bulkDeleteDocuments(
            activeFolders,
            activeFiles,
            selectedRows,
            userId,
            masterKey
          )

          linkedAssetsLiabilitiesArr?.length &&
            (await unlinkDocuments(
              userId,
              fileItems.map(sr => sr.id),
              'assetsLiabilities',
              linkedAssetsLiabilitiesArr,
              masterKey
            ))

          linkedPendingAssetsLiabilitiesArr?.length &&
            (await unlinkDocuments(
              userId,
              fileItems.map(sr => sr.id),
              'pendingAssetsLiabilities',
              linkedPendingAssetsLiabilitiesArr,
              masterKey
            ))

          linkedContactsArr?.length &&
            (await unlinkDocuments(
              userId,
              fileItems.map(sr => sr.id),
              'contacts',
              linkedContactsArr,
              masterKey
            ))

          linkedPendingContactsArr?.length &&
            (await unlinkDocuments(
              userId,
              fileItems.map(sr => sr.id),
              'pendingContacts',
              linkedPendingContactsArr,
              masterKey
            ))

          linkedEventsArr?.length &&
            (await unlinkDocuments(
              userId,
              fileItems.map(sr => sr.id),
              'events',
              linkedEventsArr,
              masterKey
            ))

          linkedPendingEventsArr?.length &&
            (await unlinkDocuments(
              userId,
              fileItems.map(sr => sr.id),
              'pendingEvents',
              linkedPendingEventsArr,
              masterKey
            ))

          linkedPasswordsArr?.length &&
            (await unlinkDocuments(
              userId,
              fileItems.map(sr => sr.id),
              'passwords',
              linkedPasswordsArr,
              masterKey
            ))

          linkedPendingPasswordsArr?.length &&
            (await unlinkDocuments(
              userId,
              fileItems.map(sr => sr.id),
              'pendingPasswords',
              linkedPendingPasswordsArr,
              masterKey
            ))

          setSelectedRows([])
          localStorage.setItem('NotReload', true)
          addS3Change({
            variables: {
              message:
                'assetsLiabilities, pendingAssetsLiabilities, contacts, pendingContacts, documents, pendingDocuments, events, pendingEvents, passwords, pendingPasswords',
              userId: userId
            }
          })
          message.success(
            <Trans
              i18nKey="SUCCESSFULLY_DELETED_ITEMS"
              values={{ count: selectedRows.length }}
            ></Trans>
          )
        } catch (err) {
          message.error(t('FAILED_TO_DELETE_ITEMS'))
          onError(err)
        }
      },
      onCancel() {}
    })
  }

  let editFolderForm
  const editFolderFormRef = fr => {
    editFolderForm = fr
  }

  const handleRenameFolder = item => {
    editFolderForm.props.form.validateFields(async (err, values) => {
      if (err) return
      removeHtmlTags(values)
      if (values.folderName.trim() === item.name) {
        setEditFormVisible(false)
        return
      }

      try {
        await renameFolder(
          activeDocuments,
          item,
          userId,
          values.folderName,
          masterKey
        )
        message.success(t('SUCCESSFULLY_RENAMED_FOLDER'))
        localStorage.setItem('NotReload', true)
        addS3Change({
          variables: {
            message: 'documents',
            userId: userId
          }
        })
        setEditFormVisible(false)
      } catch (err) {
        message.error(t('FAILED_TO_RENAME_FOLDER'))
        onError(err)
      }
    })
  }

  const handleRejectDocument = () => {
    setRejecting(true)
    rejectFormRef.props.form.validateFields(async (err, values) => {
      if (err) return

      const linkedContacts = queryLinkedRecords(
        activeContacts,
        'documents',
        [rejectRecord.id],
        'every'
      )

      const linkedPendingContacts = queryLinkedRecords(
        pendingContacts,
        'documents',
        [rejectRecord.id],
        'every'
      )

      const linkedAssetsLiabilities = queryLinkedRecords(
        activeAssetsLiabilities,
        'documents',
        [rejectRecord.id],
        'every'
      )

      const linkedPendingAssetsLiabilities = queryLinkedRecords(
        pendingAssetsLiabilities,
        'documents',
        [rejectRecord.id],
        'every'
      )

      const linkedEvents = queryLinkedRecords(
        events,
        'documents',
        [rejectRecord.id],
        'every'
      )

      const linkedPendingEvents = queryLinkedRecords(
        pendingEvents,
        'documents',
        [rejectRecord.id],
        'every'
      )

      const linkedPasswords = queryLinkedRecords(
        activePasswords,
        'documents',
        [rejectRecord.id],
        'every'
      )

      const linkedPendingPasswords = queryLinkedRecords(
        pendingPasswords,
        'documents',
        [rejectRecord.id],
        'every'
      )

      try {
        await rejectDocument(
          pendingDocuments,
          rejectRecord,
          userId,
          masterKey,
          values.reasonReject
        )

        linkedContacts?.length &&
          (await unlinkDocument(
            userId,
            rejectRecord.id,
            'contacts',
            linkedContacts,
            masterKey
          ))

        linkedPendingContacts?.length &&
          (await unlinkDocument(
            userId,
            rejectRecord.id,
            'pendingContacts',
            linkedPendingContacts,
            masterKey
          ))

        linkedAssetsLiabilities?.length &&
          (await unlinkDocument(
            userId,
            rejectRecord.id,
            'assetsLiabilities',
            linkedAssetsLiabilities,
            masterKey
          ))

        linkedPendingAssetsLiabilities?.length &&
          (await unlinkDocument(
            userId,
            rejectRecord.id,
            'pendingAssetsLiabilities',
            linkedPendingAssetsLiabilities,
            masterKey
          ))

        linkedEvents?.length &&
          (await unlinkDocument(
            userId,
            rejectRecord.id,
            'events',
            linkedEvents,
            masterKey
          ))

        linkedPendingEvents?.length &&
          (await unlinkDocument(
            userId,
            rejectRecord.id,
            'pendingEvents',
            linkedPendingEvents,
            masterKey
          ))

        linkedPasswords?.length &&
          (await unlinkDocument(
            userId,
            rejectRecord.id,
            'passwords',
            linkedPasswords,
            masterKey
          ))

        linkedPendingPasswords?.length &&
          (await unlinkDocument(
            userId,
            rejectRecord.id,
            'pendingPasswords',
            linkedPendingPasswords,
            masterKey
          ))

        setRejectRecord({})
        setRejecting(false)
        setRejectModalVisible(false)
        localStorage.setItem('NotReload', true)
        addS3Change({
          variables: {
            message:
              'assetsLiabilities, pendingAssetsLiabilities, contacts, pendingContacts, documents, pendingDocuments, events, pendingEvents, passwords, pendingPasswords',
            userId: userId
          }
        })
        dispatch(fetchPendingDocuments(userId, masterKey))
        await api.handleAddRecordRequest(
          JSON.stringify({
            isApproved: false,
            primaryUserId: userId,
            fullname: fullName,
            recordType: 'document'
          })
        )
        message.success(t('SUCCESSFULLY_REJECTED_DOCUMENTS'))
      } catch (error) {
        setRejecting(false)
        setRejectRecord({})
        message.error(t('FAILED_TO_REJECT_DOCUMENTS'))
      }
    })
  }

  const checkPrivateDestination = () => {
    if (
      !!privateFolder?.password &&
      destinationFolder.slice(0, privateFolder?.path.length) ===
        privateFolder?.path
    ) {
      if (isLockPrivateFolder) {
        Modal.warning({
          title: t('WARNING_MSG'),
          content: t('LOCK_PRIVATE_FOLDER_MSG')
        })
      } else if (
        !!privateFolderPassword &&
        localStorage.getItem('privateFolderPassword') !== privateFolderPassword
      ) {
        setIsMoveOrCopy(true)
        setDirectoryModalVisible(false)
        setPasswordFolderModalVisible(true)
      } else {
        handleMoveOrCopy()
      }
    } else {
      handleMoveOrCopy()
    }
  }

  const handleMoveOrCopy = async () => {
    if (
      docItem.type === 'folder' &&
      destinationFolder.indexOf(docItem.path) === 0
    ) {
      message.error(
        `${t('FAILED_TO')} ${action}: ${t('THE_DESTINATION_IS_A_SUBFOLDER')}`
      )
      return
    }

    // validate when the destination is subfolder of a selected folder
    if (selectedRows.length) {
      const invalidChoice = selectedRows.find(
        s => s.type === 'folder' && destinationFolder.indexOf(s.path) === 0
      )

      if (invalidChoice) {
        message.error(
          `${t('THE_DESTINATION_IS_A_SUBFOLDER')} <${invalidChoice.name}>.`
        )
        return
      }
    }

    setIsMovingOrCopying(true)

    switch (action) {
      case 'Copy':
        await handleCopy()
        break
      case 'Move':
        await handleMove()
        break
      case 'Bulk move':
        await handleMoveItems()
        break
      case 'Bulk copy':
        await handleCopy(true)
        break
      default:
        break
    }
  }

  const handleCopy = async (isBulkCopy = false) => {
    const items = isBulkCopy ? selectedRows : [docItem]

    let lockFiles = []

    await Promise.all(
      items.map(async item => {
        const statusRes = await api.getFileStatus(userId, item.fileId)
        if (statusRes.data && statusRes.data.isLocked) {
          lockFiles.push(item)
        }
      })
    )

    if (
      destinationFolder.slice(0, privateFolder?.path.length) ===
        privateFolder?.path &&
      lockFiles.length
    ) {
      Modal.warning({
        title: t('WARNING_MSG'),
        content: t('CANNOT_COPY_LOCK_FILE_TO_PRIVATE_FOLDER')
      })
      setDirectoryModalVisible(false)
      isBulkCopy && setSelectedRows([])
      return
    }

    const newDocsToCopy = newDocumentsToCopy(
      items,
      activeFolders,
      activeFiles,
      destinationFolder
    )

    const newFiles = newDocsToCopy.newFiles || []
    const newFolders = newDocsToCopy.newFolders || []

    if (
      newFiles?.length &&
      sum([...newFiles.map(n => n?.file[0]?.size), usedStorage]) >
        allowedStorage
    ) {
      Modal.warn({
        title: t('LOW_STORAGE_SPACE'),
        content: t('LOW_STORAGE_SPACE_CONTENT')
      })
      setIsMovingOrCopying(false)
      return
    }

    if (limitedRecord + newFiles.length > limit) {
      setIsMovingOrCopying(false)
      showUpgradeSubscriptionPlanConfirm(setSubscriptionModalVisible)
      return
    }

    try {
      await copyItems(userId, newFiles, newFolders, masterKey)

      message.success(
        `${t('SUCCESSFULLY_COPIED')} ${
          isBulkCopy ? t('ITEMS').toLowerCase() : t('ITEM').toLowerCase()
        }`
      )
      setIsMovingOrCopying(false)
      setDirectoryModalVisible(false)
      isBulkCopy && setSelectedRows([])
      localStorage.setItem('NotReload', true)
      addS3Change({
        variables: {
          message: 'documents',
          userId: userId
        }
      })
    } catch (err) {
      message.error(
        `${t('FAILED_TO_COPY')} ${
          isBulkCopy ? t('ITEMS').toLowerCase() : t('ITEM').toLowerCase()
        }`
      )
      setIsMovingOrCopying(false)
      onError(err)
    }
  }

  const handleMove = async () => {
    // moving a file to the its current folder, so don't need to do anything
    if (docItem.type !== 'folder' && destinationFolder === docItem.path) {
      setIsMovingOrCopying(false)
      setDirectoryModalVisible(false)
      return
    }

    const statusRes = await api.getFileStatus(userId, docItem.fileId)
    if (
      statusRes.data &&
      statusRes.data.isLocked &&
      destinationFolder.slice(0, privateFolder?.path.length) ===
        privateFolder?.path
    ) {
      Modal.warning({
        title: t('WARNING_MSG'),
        content: t('CANNOT_MOVE_LOCK_FILE_TO_PRIVATE_FOLDER')
      })
      setIsMovingOrCopying(false)
      setDirectoryModalVisible(false)
      return
    }

    try {
      await moveItem(
        userId,
        docItem,
        activeDocuments,
        activeFolders,
        activeFiles,
        destinationFolder,
        masterKey
      )

      message.success(t('SUCCESSFULLY_MOVED_ITEM'))
      setIsMovingOrCopying(false)
      setDirectoryModalVisible(false)
    } catch (err) {
      message.error(t('FAILED_TO_MOVE_ITEM'))
      setIsMovingOrCopying(false)
      onError(err)
    }
  }

  const handleMoveItems = async () => {
    try {
      let lockFiles = []

      await Promise.all(
        selectedRows.map(async sr => {
          const statusRes = await api.getFileStatus(userId, sr.fileId)
          if (statusRes.data && statusRes.data.isLocked) {
            lockFiles.push(sr)
          }
        })
      )

      if (
        destinationFolder.slice(0, privateFolder?.path.length) ===
          privateFolder?.path &&
        lockFiles.length
      ) {
        Modal.warning({
          title: t('WARNING_MSG'),
          content: t('CANNOT_MOVE_LOCK_FILE_TO_PRIVATE_FOLDER')
        })
        setIsMovingOrCopying(false)
        setDirectoryModalVisible(false)
        setSelectedRows([])
        return
      }

      await moveItems(
        userId,
        selectedRows,
        activeDocuments,
        activeFolders,
        activeFiles,
        destinationFolder,
        masterKey
      )

      message.success(t('SUCCESSFULLY_MOVED_ITEMS'))
      setIsMovingOrCopying(false)
      setDirectoryModalVisible(false)
      setSelectedRows([])
      localStorage.setItem('NotReload', true)
      addS3Change({
        variables: {
          message: 'documents',
          userId: userId
        }
      })
    } catch (err) {
      message.error(t('FAILED_TO_MOVE_ITEMS'))
      setIsMovingOrCopying(false)
      onError(err)
    }
  }

  const handleApprove = async item => {
    try {
      const subItems = pendingDocuments.filter(
        d =>
          d.path.indexOf(item.path) === 0 &&
          (item.fileId ? item.name === d.fileName : true)
      )

      const pendingFilesSize = subItems
        .filter(item => item.fileId)
        .reduce((sum, item) => {
          return sum + item?.file[0].size
        }, 0)

      if (!allowedStorage || usedStorage + pendingFilesSize > allowedStorage) {
        Modal.warn({
          title: t('LOW_STORAGE_SPACE'),
          content: t('LOW_STORAGE_SPACE_CONTENT')
        })
        return
      }

      await approveDocument(pendingDocuments, item, userId, masterKey)

      //Delete pending records
      const parentItems = pendingDocuments.filter(
        d => !d.fileName && item.path.indexOf(d.path) === 0
      )

      const deletedItems = uniq([...parentItems, ...subItems])

      await permanentlyDeleteDocuments(
        userId,
        pendingDocuments,
        deletedItems,
        masterKey,
        'pendingDocuments'
      )
      localStorage.setItem('NotReload', true)
      addS3Change({
        variables: {
          message: 'documents, pendingDocuments',
          userId: userId
        }
      })
      await api.handleAddRecordRequest(
        JSON.stringify({
          isApproved: true,
          primaryUserId: userId,
          fullname: fullName,
          recordType: 'document'
        })
      )
      dispatch(fetchPendingDocuments(userId, masterKey))
      message.success(t('SUCCESSFULLY_APPROVED_DOCUMENTS'))
    } catch (error) {
      message.error(t('FAILED_TO_APPROVE_DOCUMENTS'))
    }
  }

  const handleEditContent = async item => {
    const resBody = await s3Get(
      userId,
      item.fileId,
      { sub: item.sub },
      { responseType: 'blob' }
    )
    decryptFile(resBody, masterKey, uint8Array => {
      const fileContent = String.fromCharCode(...uint8Array)

      setSelectedTextFile({
        id: item.id,
        fileId: item.fileId,
        sub: item.sub,
        fileName: item.name.substr(0, item.name.lastIndexOf('.')),
        fileExtension: item.name.substr(item.name.lastIndexOf('.')),
        fileContent
      })

      setEnableCreateFile(true)
    })
  }

  const actionsDropdown = item => (
    <Dropdown
      overlay={
        !(isProfessionalDeputy || (isDelegateByPD && isReadonly)) &&
        accessLevel === ACCESS_LEVEL.NEED_APPROVAL &&
        item.status ? (
          <Menu>
            <Menu.Item style={{ padding: '5px 0' }}>
              <Button type="link" onClick={() => handleApprove(item)}>
                {t('APPROVE')}
              </Button>
            </Menu.Item>
            <Menu.Item style={{ padding: '5px 0' }}>
              <Button
                type="link"
                onClick={() => {
                  rejectFormRef.props.form.resetFields()
                  setRejectModalVisible(true)
                  setRejectRecord(item)
                }}
              >
                {t('REJECT')}
              </Button>
            </Menu.Item>
          </Menu>
        ) : (
          <Menu>
            {item.type === 'folder' && item.isPrivate && (
              <Menu.Item style={{ padding: '5px 0' }}>
                <Button
                  type="link"
                  onClick={() => {
                    item.password
                      ? setIsChangePassword(true)
                      : setIsChangePassword(false)
                    setCreatePrivateFolderVisible(true)
                    setDocItem(item)
                  }}
                >
                  {item.password ? t('CHANGE_PASSWORD') : t('SET_PASSWORD')}
                </Button>
              </Menu.Item>
            )}
            {item.type === 'folder' && item.isPrivate && item.password && (
              <Menu.Item style={{ padding: '5px 0' }}>
                <Button
                  type="link"
                  onClick={() => setPasswordModalVisible(true)}
                >
                  {t('RESET_PASSWORD')}
                </Button>
              </Menu.Item>
            )}
            {item.type === 'folder' && (
              <Menu.Item style={{ padding: '5px 0' }}>
                <Button
                  type="link"
                  disabled={
                    hasPending(item) ||
                    item.isPrivate ||
                    (item.path.slice(0, privateFolder?.path.length) ===
                      privateFolder?.path &&
                      !!privateFolderPassword &&
                      localStorage.getItem('privateFolderPassword') !==
                        privateFolderPassword)
                  }
                  onClick={() => {
                    setEditFormVisible(true)
                    setDocItem(item)
                  }}
                >
                  {t('RENAME')}
                </Button>
              </Menu.Item>
            )}
            <Menu.Item style={{ padding: '5px 0' }}>
              <Button
                type="link"
                disabled={
                  hasPending(item) ||
                  item.isPrivate ||
                  (item.path.slice(0, privateFolder?.path.length) ===
                    privateFolder?.path &&
                    !!privateFolderPassword &&
                    localStorage.getItem('privateFolderPassword') !==
                      privateFolderPassword)
                }
                onClick={e => {
                  setAction('Copy')
                  setDirectoryModalVisible(true)
                  setDocItem(item)
                }}
              >
                {t('COPY')}
              </Button>
            </Menu.Item>
            <Menu.Item style={{ padding: '5px 0' }}>
              <Button
                type="link"
                disabled={
                  hasPending(item) ||
                  item.isPrivate ||
                  (item.path.slice(0, privateFolder?.path.length) ===
                    privateFolder?.path &&
                    !!privateFolderPassword &&
                    localStorage.getItem('privateFolderPassword') !==
                      privateFolderPassword)
                }
                onClick={e => {
                  setAction('Move')
                  setDirectoryModalVisible(true)
                  setDocItem(item)
                }}
              >
                {t('MOVE')}
              </Button>
            </Menu.Item>
            {item.isContentEditable && (
              <Menu.Item style={{ padding: '5px 0' }}>
                <Button
                  type="link"
                  disabled={hasPending(item)}
                  onClick={() => handleEditContent(item)}
                >
                  {t('EDIT_CONTENT')}
                </Button>
              </Menu.Item>
            )}
            <Menu.Item
              style={{ padding: '5px 0' }}
              onClick={() => handleDeleteItem(item)}
            >
              <Button type="link" disabled={deleteDisabled(item)}>
                {t('DELETE')}
                {deleteDisabled(item) && (
                  <Tooltip
                    title={t('CANT_DELETE_FOLDER_WITH_FILES_INSIDE')}
                    arrowPointAtCenter
                    placement="right"
                  >
                    {' '}
                    <Icon type="question-circle" />
                  </Tooltip>
                )}
              </Button>
            </Menu.Item>
          </Menu>
        )
      }
      placement="bottomRight"
      trigger={['click']}
    >
      <Icon type="ellipsis" />
    </Dropdown>
  )

  const deleteDisabled = item =>
    item.type === 'folder' &&
    (accessLevel === ACCESS_LEVEL.NEED_APPROVAL
      ? [...activeFiles, ...pendingFiles]
      : activeFiles
    ).find(file => file?.path.indexOf(item.path) === 0)

  const hasPending = item =>
    item.type === 'folder' &&
    (item.pendingFilesCount || item.pendingFoldersCount)

  const getFileExtension = filename => {
    return filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2)
  }

  const columns = [
    {
      key: 'name',
      title: t('NAME'),
      render: (text, record) => (
        <span
          className="icon-name"
          onClick={e => {
            e.stopPropagation()
            handleDoubleClick(record)
          }}
        >
          {record.type === 'folder' ? (
            <Icon
              type={record.type}
              style={{ fontSize: '20px', color: theme.primary }}
              theme={'filled'}
            />
          ) : (
            <Icon
              type={record.type}
              style={{ fontSize: '20px' }}
              theme={isLinked(record.id) ? 'twoTone' : ''}
            />
          )}
          <span className="name" style={{ whiteSpace: 'nowrap' }}>
            {(!isModal ||
              isProfessionalDeputy ||
              (isDelegateByPD && isReadonly)) &&
            accessLevel === ACCESS_LEVEL.NEED_APPROVAL &&
            record.status ? (
              <i>{record.name}</i>
            ) : (
              record.name
            )}
          </span>
        </span>
      ),
      sorter: (a, b) =>
        a.type === b.type
          ? getFileExtension(a.name) === getFileExtension(b.name)
            ? a.name === b.name
              ? ''
              : a.name.localeCompare(b.name)
            : getFileExtension(a.name).localeCompare(getFileExtension(b.name))
          : b.type.localeCompare(a.type),
      defaultSortOrder: 'ascend'
    },
    {
      key: 'starred',
      render: (text, record) => (
        <StarIcon
          item={record}
          handleStar={handleStar}
          isReadonly={isReadonly}
          t={t}
        />
      )
    },
    {
      key: 'description',
      render: (text, record) =>
        record.type === 'folder' && (
          <>
            {accessLevel === ACCESS_LEVEL.NEED_APPROVAL && record.status ? (
              <span>
                <i>
                  {record.pendingFoldersCount} {t('PENDING_FOLDES')}
                  {' & '} {record.pendingFilesCount} {t('PENDING_FILES')}
                </i>
              </span>
            ) : (
              <>
                <span>
                  {record.foldersCount} {t('FOLDERS').toLowerCase()} &{' '}
                  {record.filesCount} {t('FILES').toLowerCase()}
                </span>
                <br />
                {(!isModal ||
                  isProfessionalDeputy ||
                  (isDelegateByPD && isReadonly)) &&
                  (!!record.pendingFoldersCount ||
                    !!record.pendingFilesCount) && (
                    <span>
                      <i>
                        {record.pendingFoldersCount} {t('PENDING_FOLDES')}
                        {' & '} {record.pendingFilesCount} {t('PENDING_FILES')}
                      </i>
                    </span>
                  )}
              </>
            )}
          </>
        )
    },
    {
      key: 'status',
      render: (text, record) =>
        (!isModal || isProfessionalDeputy || (isDelegateByPD && isReadonly)) &&
        accessLevel === ACCESS_LEVEL.NEED_APPROVAL &&
        (record.status ? <i>{t('PENDING')}</i> : '')
    },
    {
      key: 'size',
      render: (text, record) => {
        return (
          <span>
            {record.type === 'folder' ? record.folderSize : record.size}
          </span>
        )
      }
    },
    {
      key: 'private',
      render: (text, record) => {
        return (
          <span>
            {record.path.slice(0, privateFolder?.path.length) ===
            privateFolder?.path ? (
              privateFolder?.password ? (
                <Icon type="lock" style={{ fontSize: 20 }} />
              ) : (
                <Icon type="unlock" style={{ fontSize: 20 }} />
              )
            ) : null}
          </span>
        )
      }
    },
    {
      key: 'actions',
      render: (text, record) => !isReadonly && actionsDropdown(record),
      width: 46,
      align: 'right'
    }
  ]

  const selectedItemStyle = item => {
    const selectedItems =
      isModal && !isSelectedMutilpleFiles
        ? [{ id: selectedDoc }]
        : isSelectedMutilpleFiles
        ? selectedFilesFolders
        : selectedRows

    return `list-item ${
      selectedItems.find(sr => sr.id === item.id) ? 'selected-item' : ''
    }`
  }

  const bulkActionsMenu = (
    <Menu>
      <Menu.Item style={{ padding: '5px 0' }}>
        <Button
          type="link"
          key="1"
          onClick={() => {
            setAction('Bulk move')
            setDirectoryModalVisible(true)
          }}
        >
          {t('MOVE')}
        </Button>
      </Menu.Item>
      <Menu.Item style={{ padding: '5px 0' }}>
        <Button type="link" key="2" onClick={() => handleDeleteItems()}>
          {t('DELETE')}
        </Button>
      </Menu.Item>
      <Menu.Item style={{ padding: '5px 0' }}>
        <Button
          type="link"
          key="3"
          onClick={() => {
            setAction('Bulk copy')
            setDirectoryModalVisible(true)
          }}
        >
          {t('COPY')}
        </Button>
      </Menu.Item>
    </Menu>
  )

  return (
    <div className="file-list">
      {isModal && isSelectedMutilpleFiles
        ? selectedFilesFolders.length > 1 && (
            <div style={{ marginBottom: 10 }}>
              <Span>{`${t('SELECTED_RECORDS')}: ${
                selectedFilesFolders.length
              }`}</Span>
              <Divider type="verticle" />
            </div>
          )
        : !isReadonly &&
          selectedRows.length > 1 && (
            <div style={{ marginBottom: 10 }}>
              <Span>{`${t('SELECTED_RECORDS')}: ${selectedRows.length}`}</Span>
              <Divider type="verticle" />
              <Dropdown overlay={bulkActionsMenu}>
                <Button type="primary">
                  {t('ACTIONS')} <Icon type="down" />
                </Button>
              </Dropdown>
            </div>
          )}

      {viewMode === 'grid' && (
        <List
          grid={
            linkedAssetLiabilityId ? filesGridLayoutInModal : filesGridLayout
          }
          dataSource={content}
          renderItem={item => {
            return (
              <List.Item>
                <div
                  className={selectedItemStyle(item)}
                  onClick={() => {
                    !item.isPrivate && handleClick(item)
                  }}
                  onDoubleClick={() => handleDoubleClick(item)}
                >
                  <div className="icon-actions">
                    <Icon
                      type={item.type}
                      style={{
                        fontSize: '40px',
                        color:
                          item.type === 'folder' ? theme.primary : theme.dark1
                      }}
                      theme={
                        item.type === 'folder'
                          ? 'filled'
                          : isLinked(item.id)
                          ? 'twoTone'
                          : ''
                      }
                    />
                    {item.path.slice(0, privateFolder?.path.length) ===
                    privateFolder?.path ? (
                      privateFolder?.password ? (
                        <Icon type="lock" style={{ fontSize: 20 }} />
                      ) : (
                        <Icon type="unlock" style={{ fontSize: 20 }} />
                      )
                    ) : null}
                    <div>
                      <StarIcon
                        item={item}
                        handleStar={handleStar}
                        isReadonly={isReadonly}
                        t={t}
                      />
                      {!isReadonly && (
                        <span style={{ marginLeft: 10 }}>
                          {actionsDropdown(item)}
                        </span>
                      )}
                    </div>
                  </div>
                  <div
                    className="item-name"
                    onClick={() => handleDoubleClick(item)}
                  >
                    {item.status ? (
                      <i>
                        {item.name} ({t('PENDING')})
                      </i>
                    ) : (
                      item.name
                    )}
                  </div>
                  <div className="sub-title">
                    {item.type === 'folder' ? (
                      <>
                        {accessLevel === ACCESS_LEVEL.NEED_APPROVAL &&
                        item.status ? (
                          <span>
                            <i>
                              {item.pendingFoldersCount} {t('PENDING_FOLDES')}
                              {' & '} {item.pendingFilesCount}{' '}
                              {t('PENDING_FILES')}
                            </i>
                          </span>
                        ) : (
                          <>
                            <span>
                              {item.foldersCount} {t('FOLDERS').toLowerCase()}
                              {' &'}
                              {item.filesCount} {t('FILES').toLowerCase()}
                            </span>
                            <br />
                            {(!isModal ||
                              isProfessionalDeputy ||
                              (isDelegateByPD && isReadonly)) &&
                              (!!item.pendingFoldersCount ||
                                !!item.pendingFilesCount) && (
                                <span>
                                  <i>
                                    {item.pendingFoldersCount}{' '}
                                    {t('PENDING_FOLDES')}
                                    {' & '} {item.pendingFilesCount}{' '}
                                    {t('PENDING_FILES')}
                                  </i>
                                </span>
                              )}
                          </>
                        )}
                        <br />
                        <span>{item.folderSize}</span>
                      </>
                    ) : (
                      `${item.size}`
                    )}
                  </div>
                </div>
              </List.Item>
            )
          }}
        />
      )}
      {viewMode === 'list' && (
        <Table
          className="tour-select-docs-to-link"
          scroll={{ x: true }}
          rowSelection={{
            selectedRowKeys: selectedRows
          }}
          dataSource={content}
          columns={columns}
          rowKey="id"
          onRow={record => ({
            onDoubleClick: () => {
              handleDoubleClick(record)
            },
            onClick: () => {
              !record.isPrivate && handleClick(record)
            }
          })}
          rowClassName={record => selectedItemStyle(record)}
          // loading={isLoading}
          size={isMdUp ? '' : 'middle'}
        />
      )}

      {/* Password modal */}
      <Modal
        visible={passwordModalVisible}
        title={t('RESET_PASSWORD_OF_PRIVATE_FOLDER')}
        onOk={async e => {
          try {
            await handleConfirmPassword()
          } catch (e) {
            onError(e)
            setErrMsg(errorMessage(e))
          }
        }}
        onCancel={resetFields}
      >
        <WrappedForm wrappedComponentRef={fr => (passwordFormRef = fr)}>
          {getFieldDecorator => (
            <>
              <P style={{ marginBottom: '1em' }}>
                {t('ARE_YOU_SURE_YOU_WANT_TO_RESET_PASSWORD_OF_PRIVATE_FOLDER')}
              </P>
              <P>{t('RESET_PASSWORD_MSG')}: </P>
              <FormItem>
                {getFieldDecorator('password', {
                  rules: [
                    {
                      required: true,
                      message: 'Password is required!'
                    }
                  ]
                })(<Input.Password placeholder={t('ENTER_PASSWORD')} />)}
              </FormItem>
            </>
          )}
        </WrappedForm>
        {errMsg && (
          <Alert
            message={errMsg}
            type="error"
            closable
            style={{ marginBottom: 16 }}
          />
        )}
      </Modal>

      {/* mfa authentication modal */}
      <Modal
        visible={mfaModalVisible}
        footer={null}
        closable
        onCancel={resetFields}
      >
        <MfaForm
          wrappedComponentRef={ref => (formRef = ref)}
          cognitoUser={cognitoUser}
          mfaType={mfaType}
          callbacks={callbacks}
          errMsg={errMsg}
          setErrMsg={setErrMsg}
          isSubmitting={isSubmitting}
          setIsSubmitting={setIsSubmitting}
          handleResend={authenticateUser}
          isResending={isResendEmailCode}
        />
      </Modal>

      <FileDetails
        visible={visible}
        setVisible={setVisible}
        docItem={docItem}
      />

      <CreateFolderModal
        wrappedComponentRef={editFolderFormRef}
        visible={editFormVisible}
        isEditFolder={editFormVisible}
        handleSaveFolder={() => {
          handleRenameFolder(docItem)
          editFolderForm.props.form.resetFields()
        }}
        handleCancel={() => {
          setEditFormVisible(false)
          editFolderForm.props.form.resetFields()
        }}
        folderName={docItem.name}
        folders={activeFolders}
        breadcrumb={breadcrumb}
      />
      <Modal
        visible={directoryModalVisible}
        title={`${action} to`}
        onCancel={() => setDirectoryModalVisible(false)}
        onOk={checkPrivateDestination}
        okButtonProps={{
          disabled: !destinationFolder,
          loading: isMovingOrCopying
        }}
      >
        <FileDirectory onFolderSelect={setDestinationFolder} />
      </Modal>
      <RejectModal
        wrappedComponentRef={fr => (rejectFormRef = fr)}
        visible={rejectModalVisible}
        handleOk={handleRejectDocument}
        handleCancel={() => setRejectModalVisible(false)}
        rejecting={rejecting}
      />
      <PasswordFolderModal
        visible={passwordFolderModalVisible}
        setVisible={setPasswordFolderModalVisible}
        showStarredDocsOnly={showStarredDocsOnly}
        setShowStarredDocsOnly={setShowStarredDocsOnly}
        setBreadcrumb={setBreadcrumb}
        isDeletePrivateFolder={isDeletePrivateFolder}
        isShowLinkedDocument={isShowLinkedDocument}
        docItem={docItem}
        handleMoveOrCopy={handleMoveOrCopy}
        isMoveOrCopy={isMoveOrCopy}
        isMovingOrCopying={isMovingOrCopying}
      />
      <CreatePrivateFolderModal
        isChangePassword={isChangePassword}
        setIsChangePassword={setIsChangePassword}
        visible={createPrivateFolderVisible}
        setVisible={setCreatePrivateFolderVisible}
        docItem={docItem}
      />
      <SubscriptionModal
        visible={subscriptionModalVisible}
        setVisible={setSubscriptionModalVisible}
      />
    </div>
  )
}
