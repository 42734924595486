import React, { useState } from 'react'
import { Input, Form, Alert, Select } from 'antd'
//import { StringResources } from '../../share/StringResources'
import { P1, H3 } from '../override/Typography'
import FormItem from '../override/FormItem'
import Button from '../override/Button'
import { withRouter } from 'react-router-dom'
import { countries } from 'country-data'
import { NUMBER_ONLY_REGEX } from '../../share/Constants'
import api from '../../lib/api'
import { onError } from '../../lib/sentry'
import { useTranslation } from 'react-i18next'

function ResetPhoneNumber(props) {
  const { email } = props
  const { getFieldDecorator, getFieldValue } = props.form
  const [errMsg, setErrMsg] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [phoneNumberDirty, setPhoneNumberDirty] = useState(false)
  const [successOutput, setSuccessOutput] = useState('')
  const { t } = useTranslation()

  const countryCallingCodes = countries.all.reduce((allCodes, country) => {
    return [...allCodes, ...country.countryCallingCodes]
  }, [])

  const { Option } = Select

  const uniqueCountryCallingCodes = [
    ...new Set(countryCallingCodes)
  ].sort((a, b) => a.localeCompare(b))

  const handlePhoneNumberBlur = e => {
    const { value } = e.target
    setPhoneNumberDirty(phoneNumberDirty || !!value)
  }

  const validatePhoneNumber = (rule, value, callback) => {
    if (value) {
      const prefix = getFieldValue('prefix')
      if (!prefix) {
        callback(t('SELECT_COUNTRY_CODE'))
      } else if (!value.match(NUMBER_ONLY_REGEX)) {
        callback(t('INVALID_MOBLIE_NUMBER'))
      }
    }

    callback()
  }

  const validateToPhoneNumber = (rule, value, callback) => {
    if (value && phoneNumberDirty) {
      props.form.validateFields(['phone'], { force: true })
    }
    callback()
  }

  const handleSubmit = e => {
    setErrMsg('')
    e.preventDefault()
    props.form.validateFields((err, values) => {
      if (err) {
        return err.message
      }

      setIsLoading(true)
      const updateData = { ...values, email: email }

      api
        .sendUpdatePhoneRequest(JSON.stringify(updateData))
        .then(res => {
          if (res.data?.success) {
            setSuccessOutput(t('UPDATE_PHONE_NUMBER_REQUEST_SUCCESSFULLY'))
          } else if (res.data?.error) {
            setErrMsg(t('YOU_DO_NOT_HAVE_ANY_DEPUTIES'))
          } else {
            console.log(res.data)
          }
        })
        .catch(err => onError(err))
        .finally(act => setIsLoading(false))
    })
  }

  const prefixSelector = getFieldDecorator('prefix', {
    rules: [
      {
        validator: validateToPhoneNumber
      }
    ]
  })(
    <Select
      style={{ width: 85 }}
      showSearch
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.props.children
          .toString()
          .toLowerCase()
          .includes(input.trim().toLowerCase())
      }
    >
      {uniqueCountryCallingCodes.map(code => (
        <Option key={code} value={code.replace(/ /g, '')}>
          {code}
        </Option>
      ))}
    </Select>
  )

  return successOutput ? (
    <div className="login-form">
      <div className="form-header">
        <H3>{t('RESET_PHONE_NUMBER')}</H3>
        <P1>{t('RESET_PHONE_NUMBER_SUMMARY')}</P1>
      </div>
      <Alert type="info" message={successOutput} style={{ marginBottom: 16 }} />
    </div>
  ) : (
    <Form
      onSubmit={handleSubmit}
      className="login-form"
      layout="vertical"
      hideRequiredMark={true}
    >
      <div className="form-header">
        <H3>{t('RESET_PHONE_NUMBER')}</H3>
        <P1>{t('RESET_PHONE_NUMBER_SUMMARY')}</P1>
      </div>
      {errMsg && (
        <Alert
          message={errMsg}
          type="error"
          closable
          style={{ marginBottom: 16 }}
        />
      )}

      <FormItem label={t('NEW_PHONE_NUMBER')}>
        {getFieldDecorator('phone', {
          rules: [
            {
              required: true,
              message: t('INPUT_PHONE_NUMBER_MSG')
            },
            {
              validator: validatePhoneNumber
            }
          ]
        })(
          <Input
            addonBefore={prefixSelector}
            style={{ width: '100%' }}
            onBlur={handlePhoneNumberBlur}
          />
        )}
      </FormItem>
      <FormItem>
        <Button
          type="primary"
          size="large"
          htmlType="submit"
          loading={isLoading}
          block
        >
          {t('SUBMIT')}
        </Button>
      </FormItem>
    </Form>
  )
}

const WrappedResetPhoneNumberForm = Form.create({ name: 'resetPhoneNumber' })(
  ResetPhoneNumber
)
export default withRouter(WrappedResetPhoneNumberForm)
