import React from 'react'
import { Popconfirm, Icon, Tooltip, message } from 'antd'
import api from '../../lib/api'
import { onError } from '../../lib/sentry'
import { useTranslation } from 'react-i18next'

export default function SetDefaultCard(props) {
  const { customerId, cardId, fetchCustomer } = props
  const { t } = useTranslation()
  const confirm = async () => {
    try {
      const updateData = JSON.stringify({ default_source: cardId })
      const res = await api.updateCustomer(customerId, updateData)
      const { data } = res
      if (data.message) {
        throw new Error(data.message)
      }

      fetchCustomer()
      message.success(t('SUCCESSFULLY_SET_DEFAULT_CARD'))
    } catch (err) {
      message.error(err.message || t('FAIL_TO_SET_DEFAULT_CARD'))
      onError(err)
    }
  }

  return (
    <Popconfirm
      title={t('ARE_YOU_SURE_TO_SET_THIS_CARD_AS_DEFAULT')}
      onConfirm={confirm}
      okText={t('YES')}
      cancelText={t('NO')}
      arrowPointAtCenter
      placement="bottomRight"
    >
      <Tooltip
        title={t('SET_DEFAULT_CARD')}
        arrowPointAtCenter
        placement="topRight"
      >
        <Icon type="check" />
      </Tooltip>
    </Popconfirm>
  )
}
