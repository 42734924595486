import React, { useContext, useEffect, useState, useCallback } from 'react'
import { Tabs, Badge } from 'antd'
import { P, H4 } from '../override/Typography'
import Deputies from './Deputies'
import PrimaryUsers from './PrimaryUsers'
import Requests from './Requests'
import Vaults from './Vaults'
import AuthContext from '../../contexts/AuthContext'
import { StringResources } from '../../share/StringResources'
import TourContext from '../../contexts/TourContext'
import { onError } from '../../lib/sentry'
import api from '../../lib/api'
import { ThemeContext } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const { TabPane } = Tabs

export default function Deputy() {
  const { isProfessionalDeputy, user } = useContext(AuthContext)
  const { tourRun } = useContext(TourContext)
  const theme = useContext(ThemeContext)
  const { t } = useTranslation()

  const [activeKey, setActiveKey] = useState('')
  const [vaults, setVaults] = useState([])
  const [isVaultsLoading, setIsVaultsLoading] = useState(false)
  const [professionalDeputies, setProfessionalDeputies] = useState([])

  const [primaryUsers, setPrimaryUsers] = useState([])
  const [pendingRequests, setPendingRequests] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const [resetRequests, setResetRequests] = useState([])
  const [resetRequestsLoading, setResetRequestsLoading] = useState(false)
  const [unlockRequests, setUnlockRequests] = useState([])
  const [unlockRequestsLoading, setUnlockRequestLoading] = useState(false)
  const [updateRequests, setUpdateRequests] = useState([])
  const [updateRequestsLoading, setUpdateRequestsLoading] = useState(false)

  const { isDeputyOnly } = useSelector(state => state.user).user

  const fetchVaults = useCallback(async () => {
    try {
      setIsVaultsLoading(true)
      const response = await api.getPrimaryUsers(user.username)
      if (response.data?.length) {
        setVaults(response.data.filter(record => record.shareKey))
      }
    } catch (err) {
      onError(err)
    } finally {
      setIsVaultsLoading(false)
    }
  }, [user])

  useEffect(() => {
    fetchVaults()
  }, [fetchVaults])

  useEffect(() => {
    api
      .getProfessionalDeputies()
      .then(res => {
        if (res.data?.length) setProfessionalDeputies(res.data)
      })
      .catch(err => onError(err))
  }, [])

  useEffect(() => {
    const activeKey = isDeputyOnly
      ? tourRun
        ? StringResources.MY_DEPUTIES
        : StringResources.PRIMARY_USERS
      : StringResources.MY_DEPUTIES

    setActiveKey(activeKey)
  }, [isDeputyOnly, tourRun])

  const fetchResetRequests = useCallback(async () => {
    setResetRequestsLoading(true)
    try {
      const res = await api.getResetRequests(user.username)
      setResetRequests(res.data)
    } catch (err) {
      console.log('Failed to get reset requests: ', err)
      onError(err)
    }
    setResetRequestsLoading(false)
  }, [user])

  const fetchUnlockRequests = useCallback(async () => {
    setUnlockRequestLoading(true)
    try {
      const res = await api.getUnlockRequests(user.username)
      setUnlockRequests(res.data)
    } catch (err) {
      console.log('Failed to get unlock requests: ', err)
      onError(err)
    }
    setUnlockRequestLoading(false)
  }, [user])

  const fetchUpdateRequests = useCallback(async () => {
    setUpdateRequestsLoading(true)
    try {
      const res = await api.getUpdateRequests(user.username)
      setUpdateRequests(res.data)
    } catch (err) {
      console.log('Failed to get update requests: ', err)
      onError(err)
    }
    setUpdateRequestsLoading(false)
  }, [user])

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true)
      const response = await Promise.all([
        api.getPrimaryUsers(user.username),
        api.getDeputyRequests(user.username)
      ])
      if (response[0].data) {
        setPrimaryUsers(response[0].data)
      }
      if (response[1].data) {
        setPendingRequests(response[1].data)
      }
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
      onError(err)
    }
  }, [user])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  useEffect(() => {
    fetchResetRequests()
    fetchUnlockRequests()
    fetchUpdateRequests()
  }, [fetchResetRequests, fetchUnlockRequests, fetchUpdateRequests])

  return (
    <div className="main-wrapper">
      <H4 style={{ paddingTop: 20 }}>{t('DEPUTIES')}</H4>
      <Tabs
        className="custom-tabs"
        activeKey={activeKey}
        onChange={key => {
          setActiveKey(key)
        }}
      >
        {!isProfessionalDeputy && (
          <TabPane tab={t('MY_DEPUTIES')} key={StringResources.MY_DEPUTIES}>
            <Deputies disabled={isDeputyOnly && !tourRun} />
          </TabPane>
        )}
        <TabPane
          tab={
            <>
              <span style={{ paddingRight: 8 }}>
                {t('PRIMARY_USERS_WHO_I_AM_A_DEPUTY_FOR')}
              </span>
              <Badge
                count={
                  resetRequests.length +
                  unlockRequests.length +
                  updateRequests.length +
                  pendingRequests.length
                }
                style={{ backgroundColor: theme.primary }}
              />
            </>
          }
          key={StringResources.PRIMARY_USERS}
        >
          <P style={{ marginTop: 10 }}>
            {t('YOU_HAVE_BEEN_ENTRUSTED_AS_DEPUTY')}{' '}
            <a
              href="https://support.vaultbox.tech/support/solutions/articles/51000024153-being-a-deputy-overview"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('LEARN_MORE_ABOUT_BEING_A_DEPUTY')}
            </a>
          </P>
          <PrimaryUsers
            fetchVaults={fetchVaults}
            professionalDeputies={professionalDeputies}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            primaryUsers={primaryUsers}
            pendingRequests={pendingRequests}
            unlockRequests={unlockRequests}
            updateRequests={updateRequests}
            resetRequests={resetRequests}
            fetchData={fetchData}
          />
          <Requests
            professionalDeputies={professionalDeputies}
            resetRequests={resetRequests}
            resetRequestsLoading={resetRequestsLoading}
            setResetRequestsLoading={setResetRequestsLoading}
            unlockRequests={unlockRequests}
            unlockRequestsLoading={unlockRequestsLoading}
            setUnlockRequestLoading={setUnlockRequestLoading}
            updateRequests={updateRequests}
            updateRequestsLoading={updateRequestsLoading}
            fetchUpdateRequests={fetchUpdateRequests}
            fetchResetRequests={fetchResetRequests}
            fetchUnlockRequests={fetchUnlockRequests}
          />
          <Vaults
            professionalDeputies={professionalDeputies}
            vaults={vaults}
            fetchData={fetchData}
            fetchVaults={fetchVaults}
            isLoading={isVaultsLoading}
          />
        </TabPane>
      </Tabs>
    </div>
  )
}
