import React, { useContext, Fragment, useState } from 'react'
import DeleteCard from './DeleteCard'
import SetDefaultCard from './SetDefaultCard'
import { Table, Empty } from 'antd'
import AuthContext from '../../contexts/AuthContext'
import Button from '../override/Button'
import SimpleHeader from '../override/SimpleHeader'
import { H4 } from '../override/Typography'
import { ThemeContext } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { fetchCustomer } from '../../features/payment/customerSlice'
import { useMediaQuery } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Elements, StripeProvider } from 'react-stripe-elements'
import config from '../../config'
import CardModal from './CardModal'

export default function PaymentMethod() {
  const { user } = useContext(AuthContext)
  const theme = useContext(ThemeContext)

  const [cardModalVisible, setCardModalVisible] = useState(false)

  const { customer } = useSelector(state => state.customer)

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))

  const cardColumns = [
    {
      key: 'last4',
      dataIndex: 'last4',
      title: t('CARD_NO'),
      render: text => `...${text}`
    },
    {
      key: 'expiredOn',
      title: t('EXPIRES_ON'),
      render: (text, record) => `${record.exp_month}/${record.exp_year}`
    },
    {
      key: 'actions',
      title: t('ACTIONS'),
      render: (text, record) =>
        record.id === customer.default_source ? (
          <span>Current default</span>
        ) : (
          <div className="asset-liability-actions">
            <SetDefaultCard
              customerId={customer.id}
              cardId={record.id}
              fetchCustomer={() => dispatch(fetchCustomer(user.username))}
            />
            <DeleteCard
              customerId={customer.id}
              cardId={record.id}
              fetchCustomer={() => dispatch(fetchCustomer(user.username))}
            />
          </div>
        )
    }
  ]

  return (
    <Fragment>
      <SimpleHeader
        title={<H4>{t('PAYMENT_METHOD')}</H4>}
        extra={
          <Button onClick={() => setCardModalVisible(true)}>
            {t('ADD_A_CARD')}
          </Button>
        }
        size="small"
      />
      <Table
        dataSource={customer.sources?.data || []}
        columns={cardColumns}
        rowKey="id"
        pagination={false}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('NO_CARDS')}
            />
          )
        }}
        size={isMdUp ? '' : 'middle'}
      />
      
      <StripeProvider apiKey={config.stripe.PUBLISHABLE_KEY}>
        <Elements>
          <CardModal
            visible={cardModalVisible}
            setVisible={setCardModalVisible}
            customerId={customer.id}
            handleOkComplete={() => dispatch(fetchCustomer(user.username))}
          />
        </Elements>
      </StripeProvider>
    </Fragment>
  )
}
