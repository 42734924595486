import React from 'react'
import { Form, Alert } from 'antd'
import CodeInput from '../common/CodeInput'
import Button from '../override/Button'
import { H3, P1 } from '../override/Typography'
//import { StringResources } from '../../share/StringResources'
import { MFA_TYPES } from '../../share/Constants'
import { Trans } from 'react-i18next'

import api from '../../lib/api'
import { onError } from '../../lib/sentry'

class MfaVerificationForm extends React.Component {
  render() {
    const {
      form,
      cognitoUser,
      mfaType,
      callbacks,
      className,
      errMsg,
      setErrMsg,
      isSubmitting,
      setIsSubmitting,
      extra,
      style,
      handleResend,
      isResending,
      isReset,
      emailOTPLoading,
      setEmailOTPLoading
    } = this.props

    const { getFieldDecorator } = form

    const descriptionByType = type => {
      switch (type) {
        case MFA_TYPES.TOTP:
          return <Trans i18nKey="INPUT_TOTP_CODE"></Trans>
        case MFA_TYPES.SMS:
          return <Trans i18nKey="INPUT_SMS_CODE"></Trans>
        case MFA_TYPES.EMAIL:
          return <Trans i18nKey="INPUT_EMAIL_CODE"></Trans>
        default:
          return ''
      }
    }

    const handleResendEmailOTPCode = () => {
      return new Promise((resolve, reject) => {
        handleResend(resolve, reject)
      })
    }

    const handleCodeSubmit = e => {
      e.preventDefault()
      form.validateFields((err, values) => {
        if (err) return

        setIsSubmitting(true)
        setErrMsg('')
        mfaType === MFA_TYPES.EMAIL
          ? cognitoUser.sendCustomChallengeAnswer(values.code, callbacks)
          : cognitoUser.sendMFACode(values.code, callbacks, mfaType)
      })
    }

    const handleChangeMFAType = async () => {
      setEmailOTPLoading(true)
      try {
        await api.updateMFASettings(
          JSON.stringify({
            userId: cognitoUser.username
          })
        )

        cognitoUser.setAuthenticationFlowType('CUSTOM_AUTH')
        handleResend()
        setEmailOTPLoading(false)
      } catch (e) {
        setErrMsg(<Trans i18nKey="FAILED_TO_UPDATE_AUTH_FLOW"></Trans>)
        setIsSubmitting(false)
        setEmailOTPLoading(false)
        onError(e)
      }
    }

    return (
      <Form style={style} className={className} onSubmit={handleCodeSubmit}>
        <div className="form-header">
          <H3>
            <Trans i18nKey="MULTI_FACTOR_AUTHENTICATION"></Trans>
          </H3>
          <P1>{descriptionByType(mfaType)}</P1>
        </div>
        {errMsg && (
          <Alert
            message={errMsg}
            type="error"
            closable
            afterClose={() => setErrMsg('')}
            style={{ marginBottom: 16 }}
          />
        )}
        <CodeInput
          getFieldDecorator={getFieldDecorator}
          style={{
            marginRight: 10,
            width: 'calc(100% - 60px)'
          }}
          extra={
            <>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                icon="right"
                style={{ fontSize: 14 }}
                loading={isSubmitting}
              />
              {isReset &&
                (mfaType === MFA_TYPES.TOTP || mfaType === MFA_TYPES.SMS) && (
                  <Button
                    type="link"
                    style={{ padding: '0 0.5em 0 0' }}
                    onClick={handleChangeMFAType}
                    loading={emailOTPLoading}
                  >
                    <Trans i18nKey="USE_EMAIL_OTP_CODE"></Trans>
                  </Button>
                )}
            </>
          }
        />
        {mfaType === MFA_TYPES.TOTP ? (
          extra
        ) : (
          <>
            <span>
              <Trans
                i18nKey={
                  mfaType === MFA_TYPES.EMAIL
                    ? 'DID_NOT_RECEIVE_THE_EMAIL'
                    : 'DID_NOT_RECEIVE_THE_SMS'
                }
              ></Trans>
            </span>
            <Button
              type="link"
              style={{ padding: '0 0 0 0.5em' }}
              onClick={
                mfaType === MFA_TYPES.EMAIL
                  ? handleResendEmailOTPCode
                  : handleResend
              }
              loading={isResending}
            >
              <Trans
                i18nKey={
                  mfaType === MFA_TYPES.EMAIL ? 'RESEND_EMAIL' : 'RESEND_SMS'
                }
              ></Trans>
            </Button>
          </>
        )}
      </Form>
    )
  }
}

const MfaForm = Form.create({ name: 'mfaForm' })(MfaVerificationForm)
export default MfaForm
