import React, { useState, useEffect, useContext } from 'react'
import { withRouter } from 'react-router-dom'
import {
  PageHeader,
  Layout,
  Table,
  Input,
  Divider,
  Icon,
  Menu,
  message,
  Dropdown,
  Button,
  Modal
} from 'antd'
import { ThemeContext } from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { H4, Span } from '../override/Typography'
import AssetLiabilityInformation from '../assets-liabilities/AssetLiabilityInformation'
import ContactInformation from '../contacts/ContactInformation'
import FileInformation from '../file/FileInformation'
import { search, showUpgradeSubscriptionPlanConfirm } from '../../share/helpers'
import VaultContext from '../../contexts/VaultContext'
import {
  permanentlyDeleteItems,
  restoreItems,
  permanentlyDeleteDocument,
  //restoreDocument,
  restoreValuation,
  permanentlyDeleteDocuments,
  restoreDocuments,
  deletedRecordsHistory
} from '../../lib/pouchDb'
import { ENTITY_TYPES } from '../../share/Constants'
//import { StringResources } from './../../share/StringResources'
import { fetchPendingUnlockFiles } from '../../features/documents/documentsSlice'
import { onError } from '../../lib/sentry'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Flex from '../override/Flex'
import { useTranslation, Trans } from 'react-i18next'
import i18next from 'i18next'
import { useMutation } from 'react-apollo-hooks'
import { createS3Change } from '../../graphql/mutations'
import SubscriptionModal from '../payment/SubscriptionModal'

const { Content, Sider } = Layout

const Trash = () => {
  const { userId, isReadonly, masterKey, limitedRecord } =
    useContext(VaultContext)
  const theme = useContext(ThemeContext)
  const { t } = useTranslation()

  const { deletedAssetsLiabilities } = useSelector(state =>
    isReadonly ? state.otherAssetsLiabilities : state.assetsLiabilities
  )
  const { deletedContacts } = useSelector(state =>
    isReadonly ? state.otherContacts : state.contacts
  )

  const { deletedDocuments, activeFolders, documents } = useSelector(state =>
    isReadonly ? state.otherDocuments : state.documents
  )

  const { limit } = useSelector(state => state.customer)

  const dispatch = useDispatch()
  const [selectedRecord, setSelectedRecord] = useState()
  const [selectedRecordType, setSelectedRecordType] = useState('')
  const [sourceAssetsLiabilities, setSourceAssetsLiabilities] = useState([])
  const [sourceContacts, setSourceContacts] = useState([])
  const [sourceDocuments, setSourceDocuments] = useState([])
  const [filteredDocuments, setFilteredDocuments] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [subscriptionModalVisible, setSubscriptionModalVisible] = useState()
  const isUpMD = useMediaQuery(theme.breakpoints.up('md'))
  const deletedPrivateFolder = deletedDocuments.find(d => d.isPrivate)
  const privateFolder = activeFolders.find(d => d.isPrivate)
  const privateDocuments = []
  deletedDocuments.forEach(d => {
    if (
      d.fileId &&
      d.path.slice(0, deletedPrivateFolder?.path.length) ===
        deletedPrivateFolder?.path
    ) {
      privateDocuments.push(d)
    }
  })

  const [addS3Change] = useMutation(createS3Change)

  useEffect(() => {
    setSourceAssetsLiabilities([...deletedAssetsLiabilities])
  }, [deletedAssetsLiabilities])

  useEffect(() => {
    setSourceContacts([...deletedContacts])
  }, [deletedContacts])

  useEffect(() => {
    const sourceDocuments = deletedDocuments
      .filter(df => !df.deletedFolderId)
      .map(doc => {
        if (doc.fileName) {
          return {
            _id: doc._id,
            name: doc.fileName,
            description: doc.description,
            tags: doc.tags,
            type: 'file-text',
            size: doc.file[0].size,
            path: doc.path,
            fileId: doc.fileId,
            uploadTime: doc.uploadTime
          }
        } else {
          const folders = doc.path.split('/').slice(0, -1)
          const subItems = deletedDocuments.filter(
            df => df.deletedFolderId === doc._id
          )
          const filesCount = subItems.filter(df => df.fileName).length
          const foldersCount = subItems.filter(df => !df.fileName).length

          return {
            name: folders[folders.length - 1],
            type: 'folder',
            path: doc.path,
            _id: doc._id,
            description: `${foldersCount} ${i18next.t(
              'FOLDERS'
            )} & ${filesCount} ${i18next.t('FILES')}`
          }
        }
      })

    setSourceDocuments(sourceDocuments)
    setFilteredDocuments(sourceDocuments)
  }, [deletedDocuments])

  const assetsLiabilitiesColumns = [
    {
      title: t('TITLE'),
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: t('DESCRIPTION'),
      dataIndex: 'description',
      key: 'description'
    },
    {
      key: 'actions',
      dataIndex: 'actions',
      align: 'right',
      render: (text, record) => actionsDropdown('assetsLiabilities', record)
    }
  ]

  const contactsColumns = [
    {
      title: t('NAME'),
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: t('DESCRIPTION'),
      dataIndex: 'description',
      key: 'description'
    },
    {
      key: 'actions',
      dataIndex: 'actions',
      align: 'right',
      render: (text, record) => actionsDropdown('contacts', record)
    }
  ]

  const documentsColumns = [
    {
      title: t('NAME'),
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: t('DETAILS'),
      dataIndex: 'description',
      key: 'description'
    },
    {
      dataIndex: 'isPrivate',
      key: 'isPrivate',
      render: (text, record) => (
        <span>
          {record.path.slice(0, deletedPrivateFolder?.path.length) ===
            deletedPrivateFolder?.path ||
          record.path.slice(0, privateFolder?.path.length) ===
            privateFolder?.path ? (
            <Icon type="lock" />
          ) : null}
        </span>
      )
    },
    {
      key: 'actions',
      dataIndex: 'actions',
      align: 'right',
      render: (text, record) =>
        actionsDropdown('documents', record, deletedDocuments, activeFolders)
    }
  ]

  const handleRestore = async (e, dbName, records = []) => {
    const length = records.filter(r => r?.type !== 'folder')?.length
    if (limitedRecord + length > limit) {
      showUpgradeSubscriptionPlanConfirm(setSubscriptionModalVisible)
      return
    }
    try {
      await (dbName === 'documents'
        ? restoreDocuments(
            userId,
            deletedDocuments,
            activeFolders,
            documents.filter(d => !d.fileName) || [],
            records,
            masterKey
          )
        : restoreItems(
            dbName,
            userId,
            !!records.length
              ? records
              : dbName === 'assetsLiabilities'
              ? deletedAssetsLiabilities
              : deletedContacts,
            masterKey
          ))

      if (dbName === 'assetsLiabilities') {
        await restoreValuation(
          userId,
          !!records.length ? records : deletedAssetsLiabilities,
          masterKey
        )
      }

      setSelectedRecord()
      setSelectedRecordType('')
      localStorage.setItem('NotReload', true)
      addS3Change({
        variables: {
          message: dbName,
          userId: userId
        }
      })
      message.success(t('SUCCESSFULLY_RESTORED_ITEMS'))
    } catch (err) {
      message.error(t('FAILED_TO_RESTORE_ITEMS'))
      onError(err)
    }
  }

  const handlePermanentlyDelete = (e, dbName, record) => {
    if (dbName === 'documents') {
      if (
        record.type === 'folder' &&
        !!deletedPrivateFolder &&
        !privateFolder &&
        record.path === deletedPrivateFolder.path &&
        privateDocuments.length
      ) {
        Modal.warning({
          title: t('WARNING_MSG'),
          content: t('PERMANENTLY_DELETE_PRIVATE_FOLDER_WARNING')
        })
      } else {
        Modal.confirm({
          title: t('PERMANENTLY_DELETE'),
          content: t('PERMANENTLY_DELETE_ITEM_CONFIRM'),
          async onOk() {
            try {
              await permanentlyDeleteDocument(
                userId,
                record,
                deletedDocuments,
                masterKey
              )
              localStorage.setItem('NotReload', true)
              addS3Change({
                variables: {
                  message: 'documents',
                  userId: userId
                }
              })
              dispatch(fetchPendingUnlockFiles(userId))
              setSelectedRecord()
              setSelectedRecordType('')
              message.success(t('SUCCESSFULLY_DELETED_ITEM'))
            } catch (err) {
              message.error(t('FAILED_TO_DELETE_ITEM'))
              onError(err)
            }
          }
        })
      }
    } else {
      Modal.confirm({
        title: t('PERMANENTLY_DELETE'),
        content: t('PERMANENTLY_DELETE_ITEM_CONFIRM'),
        async onOk() {
          try {
            await deletedRecordsHistory([record], userId, masterKey, dbName)
            await permanentlyDeleteItems(dbName, userId, [record], masterKey)
            localStorage.setItem('NotReload', true)
            addS3Change({
              variables: {
                message: dbName,
                userId: userId
              }
            })
            dispatch(fetchPendingUnlockFiles(userId))
            setSelectedRecord()
            setSelectedRecordType('')
            message.success(t('SUCCESSFULLY_DELETED_ITEM'))
          } catch (err) {
            message.error(t('FAILED_TO_DELETE_ITEM'))
            onError(err)
          }
        }
      })
    }
  }

  const handlePermanentlyDeleteDocuments = e => {
    Modal.confirm({
      title: t('PERMANENTLY_DELETE'),
      content: t('PERMANENTLY_DELETE_ITEMS_CONFIRM'),
      async onOk() {
        try {
          await permanentlyDeleteDocuments(
            userId,
            selectedRows,
            deletedDocuments,
            masterKey
          )
          dispatch(fetchPendingUnlockFiles(userId))
          setSelectedRows([])
          setSelectedRecord()
          setSelectedRecordType('')
          localStorage.setItem('NotReload', true)
          addS3Change({
            variables: {
              message: 'documents',
              userId: userId
            }
          })
          message.success(
            <Trans
              i18nKey="SUCCESSFULLY_DELETED_ITEMS"
              values={{ count: selectedRows.length }}
            ></Trans>
          )
        } catch (err) {
          message.error(t('FAILED_TO_DELETE_ITEMS'))
          onError(err)
        }
      }
    })
  }

  const actionsDropdown = (dbName, record) => {
    return (
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item>
              <Button
                type="link"
                onClick={e => handleRestore(e, dbName, [record])}
              >
                {t('RESTORE')}
              </Button>
            </Menu.Item>
            <Menu.Item>
              <Button
                type="link"
                onClick={e => handlePermanentlyDelete(e, dbName, record)}
              >
                {t('DELETE')}
              </Button>
            </Menu.Item>
          </Menu>
        }
        trigger={['click']}
      >
        <Icon type="ellipsis" onClick={e => e.stopPropagation()} />
      </Dropdown>
    )
  }

  const showModalDeleteAll = dbName => {
    Modal.confirm({
      title: t('PERMANENTLY_DELETE_ALL'),
      content: t('PERMANENTLY_DELETE_ALL_CONFIRM'),
      async onOk() {
        try {
          if (dbName === 'documents') {
            await permanentlyDeleteDocuments(
              userId,
              deletedDocuments,
              deletedDocuments,
              masterKey
            )
          } else {
            await deletedRecordsHistory(
              dbName === 'contacts'
                ? deletedContacts
                : deletedAssetsLiabilities,
              userId,
              masterKey,
              dbName
            )
            await permanentlyDeleteItems(
              dbName,
              userId,
              dbName === 'contacts'
                ? deletedContacts
                : deletedAssetsLiabilities,
              masterKey
            )
          }

          dispatch(fetchPendingUnlockFiles(userId))
          setSelectedRows([])
          setSelectedRecord()
          setSelectedRecordType('')
          localStorage.setItem('NotReload', true)
          addS3Change({
            variables: {
              message: dbName,
              userId: userId
            }
          })
          message.success(t('SUCCESSFULLY_DELETED_ALL_ITEMS'))
        } catch (err) {
          message.error(t('FAILED_TO_DELETE_ITEMS'))
          onError(err)
        }
      }
    })
  }

  const showModalRestoreAll = dbName => {
    Modal.confirm({
      title: t('RESTORE_ALL'),
      content: t('RESTORE_ALL_CONFIRM'),
      async onOk() {
        try {
          await handleRestore(null, dbName)
          // message.success(`Successfully restored all items`)
        } catch (err) {
          // message.error('Failed to restore items')
          onError(err)
        }
      }
    })
  }

  const bulkActionsMenu = dbName => (
    <Menu>
      <Menu.Item key="1" onClick={() => showModalDeleteAll(dbName)}>
        {t('PERMANENTLY_DELETE_ALL')}
      </Menu.Item>
      <Menu.Item key="2" onClick={() => showModalRestoreAll(dbName)}>
        {t('RESTORE_ALL')}
      </Menu.Item>
    </Menu>
  )

  return (
    <Layout style={{ height: '100%' }}>
      <Content style={{ backgroundColor: '#fff', padding: 20 }}>
        <PageHeader
          title={
            <H4 display="inline-block">{t('DELETE_ASSETS_AND_LIABILITIES')}</H4>
          }
          subTitle={
            <H4 display="inline-block" color={theme.dark2}>
              {sourceAssetsLiabilities.length}
            </H4>
          }
          extra={
            <Flex className="auto-gap" style={{ flexWrap: 'wrap' }}>
              {!isReadonly && !!deletedAssetsLiabilities?.length && (
                <Dropdown overlay={() => bulkActionsMenu('assetsLiabilities')}>
                  <Button icon="down">{t('ACTIONS')}</Button>
                </Dropdown>
              )}

              <Input.Search
                placeholder={t('SEARCH_ASSET_OR_LIABILITY')}
                allowClear
                onSearch={value => {
                  const assetsLiabilities = search(
                    deletedAssetsLiabilities,
                    ['title'],
                    value
                  )
                  setSourceAssetsLiabilities(assetsLiabilities)
                }}
                style={{ width: 300 }}
              />
            </Flex>
          }
          style={{ padding: '0 0 20px' }}
        />
        <Table
          columns={
            isReadonly
              ? assetsLiabilitiesColumns.filter(col => col.key !== 'actions')
              : assetsLiabilitiesColumns
          }
          dataSource={sourceAssetsLiabilities}
          rowKey="_id"
          onRow={record => ({
            onClick: () => {
              setSelectedRecord(record)
              setSelectedRecordType(ENTITY_TYPES.ASSET_LIABILITY)
            }
          })}
          rowClassName={record =>
            `list-item ${
              selectedRecord && record._id === selectedRecord._id
                ? 'selected-item'
                : ''
            }`
          }
        />
        <Divider type="horizontal"></Divider>
        <PageHeader
          title={<H4 display="inline-block">{t('DELETED_CONTACTS')}</H4>}
          subTitle={
            <H4 display="inline-block" color={theme.dark2}>
              {sourceContacts.length}
            </H4>
          }
          extra={
            <Flex className="auto-gap" style={{ flexWrap: 'wrap' }}>
              {!isReadonly && !!deletedContacts?.length && (
                <Dropdown overlay={() => bulkActionsMenu('contacts')}>
                  <Button icon="down">{t('ACTIONS')}</Button>
                </Dropdown>
              )}

              <Input.Search
                placeholder={t('SEARCH_CONTACT')}
                allowClear
                onSearch={value => {
                  const contacts = search(deletedContacts, ['name'], value)
                  setSourceContacts(contacts)
                }}
                style={{ width: 300 }}
              />
            </Flex>
          }
          style={{ padding: '0 0 20px' }}
        />
        <Table
          columns={
            isReadonly
              ? contactsColumns.filter(col => col.key !== 'actions')
              : contactsColumns
          }
          dataSource={sourceContacts}
          rowKey="_id"
          onRow={record => ({
            onClick: () => {
              setSelectedRecord(record)
              setSelectedRecordType(ENTITY_TYPES.CONTACT)
            }
          })}
          rowClassName={record =>
            `list-item ${
              selectedRecord && record._id === selectedRecord._id
                ? 'selected-item'
                : ''
            }`
          }
        />

        <Divider type="horizontal"></Divider>
        <PageHeader
          title={
            <H4 display="inline-block">{t('DELETED_FILE_AND_FOLDERS')}</H4>
          }
          subTitle={
            <H4 display="inline-block" color={theme.dark2}>
              {filteredDocuments.length}
            </H4>
          }
          extra={
            <Flex className="auto-gap" style={{ flexWrap: 'wrap' }}>
              {!isReadonly && selectedRows.length > 1 && (
                <>
                  <Span>
                    {t('SELECTED_RECORDS')}: {selectedRows.length}
                  </Span>
                  <Button
                    type="primary"
                    onClick={handlePermanentlyDeleteDocuments}
                  >
                    {t('BULK_DELETE')}
                  </Button>
                </>
              )}

              {!isReadonly && !!deletedDocuments?.length && (
                <Dropdown overlay={() => bulkActionsMenu('documents')}>
                  <Button icon="down">{t('ACTIONS')}</Button>
                </Dropdown>
              )}

              <Input.Search
                placeholder={t('SEARCH_FIL_OR_FOLDER')}
                allowClear
                onSearch={value => {
                  const filteredDocuments = search(
                    sourceDocuments,
                    ['name'],
                    value
                  )
                  setFilteredDocuments(filteredDocuments)
                }}
                style={{ width: 300 }}
              />
            </Flex>
          }
          style={{ padding: '0 0 20px' }}
        />
        <Table
          rowSelection={{
            selectedRowKeys: selectedRows
          }}
          columns={
            isReadonly
              ? documentsColumns.filter(col => col.key !== 'actions')
              : documentsColumns
          }
          dataSource={filteredDocuments}
          scroll={{ x: true }}
          rowKey="_id"
          onRow={record => ({
            onClick: () => {
              const selected = selectedRows.find(sr => sr._id === record._id)
                ? selectedRows.filter(sr => sr._id !== record._id)
                : [...selectedRows, record]
              setSelectedRows(selected)

              if (record.type !== 'folder') {
                setSelectedRecord(record)
                setSelectedRecordType(ENTITY_TYPES.DOCUMENT)
              }
            }
          })}
          rowClassName={record =>
            `list-item ${
              selectedRows.find(sr => sr._id === record._id)
                ? 'selected-item'
                : ''
            }`
          }
        />
      </Content>
      {isUpMD && selectedRecord && (
        <Sider
          width={350}
          theme="light"
          style={{
            background: 'rgba(242, 243, 247, 0.5)',
            padding: 20
          }}
        >
          {selectedRecordType === ENTITY_TYPES.ASSET_LIABILITY && (
            <AssetLiabilityInformation record={selectedRecord} />
          )}
          {selectedRecordType === ENTITY_TYPES.CONTACT && (
            <ContactInformation record={selectedRecord} />
          )}
          {selectedRecordType === ENTITY_TYPES.DOCUMENT && (
            <FileInformation record={selectedRecord} />
          )}
        </Sider>
      )}
      <SubscriptionModal
        visible={subscriptionModalVisible}
        setVisible={setSubscriptionModalVisible}
      />
    </Layout>
  )
}

export default withRouter(Trash)
