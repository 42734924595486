import React, { forwardRef } from 'react'
import { Select } from 'antd'
//import { StringResources } from '../../share/StringResources'
import currencyData from 'world-currencies'
import { useTranslation } from 'react-i18next'

const { Option } = Select

const CurrencySelect = forwardRef(({ value, onChange }, ref) => {
  const { t } = useTranslation()
  return (
    <span ref={ref}>
      <Select
        allowClear
        showSearch
        showAction={['click', 'focus']}
        placeholder={t('SELECT_CURRENCY')}
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.props.children
            .toString()
            .toLowerCase()
            .includes(input.trim().toLowerCase())
        }
        style={{ width: '100%' }}
        value={value}
        onChange={onChange}
      >
        {Object.values(currencyData).map(c => (
          <Option key={c.iso.code} value={c.iso.code}>
            {c.iso.code} - {c.name}
          </Option>
        ))}
      </Select>
    </span>
  )
})
export default CurrencySelect
