import Asset, { assetColumns } from './AssetModel'
import { referenceColumns } from './AssetLiabilityModel'
import { ASSET_TYPES, IMPORT_COL_TYPES } from '../share/Constants'
import i18next from 'i18next'

class Insurance extends Asset {
  /**
   * Construct a new Insurance instance
   * @param {object} params - Insurance creation options:
   * @param {string} [params.nameAssured]
   * @param {string} [params.typeOfPolicy]
   * @param {number} [params.sumAssuredInAssetCurrency]
   * @param {number} [params.sumAssuredInBaseCurrency]
   * @param {any} [params.premium]
   * @param {string} [params.insurer]
   * @param {any} [params.insuranceAdvisor ]
   * @param {any} [params.policyOwner]
   * @param {string} [params.policyNumber]
   * @param {any} [params.beneficiaries]
   * @param {string} [params.entitlement]
   * @param {string} [params.otherNotes]
   */
  constructor({
    _id,
    _rev,
    _deleted,
    title,
    description,
    descriptionWithMarkup,
    percentageOwnership,
    purchaseDate,
    disposalDate,
    currency,
    valuationDate,
    valuationDateInBaseCurrency,
    valuationInAssetCurrency,
    valuationInBaseCurrency,
    contacts,
    references,
    nameAssured,
    typeOfPolicy,
    sumAssuredInAssetCurrency,
    sumAssuredInBaseCurrency,
    premium,
    insurer,
    insuranceAdvisor,
    policyOwner,
    policyNumber,
    beneficiaries,
    entitlement,
    otherNotes,
    links,
    documents,
    includeValueInNetWorth,
    status,
    events,
    passwords,
    tags
  }) {
    super({
      _id,
      _rev,
      _deleted,
      title,
      description,
      descriptionWithMarkup,
      percentageOwnership,
      purchaseDate,
      disposalDate,
      valuationDateInBaseCurrency,
      currency,
      valuationDate,
      valuationInAssetCurrency,
      valuationInBaseCurrency,
      contacts,
      references,
      links,
      documents,
      includeValueInNetWorth,
      status,
      events,
      passwords,
      tags
    })

    this.subType = ASSET_TYPES.INSURANCE
    this.nameAssured = nameAssured
    this.typeOfPolicy = typeOfPolicy
    this.sumAssuredInAssetCurrency = sumAssuredInAssetCurrency
    this.sumAssuredInBaseCurrency = sumAssuredInBaseCurrency
    this.premium = premium
    this.insurer = insurer
    this.insuranceAdvisor = insuranceAdvisor
    this.policyNumber = policyNumber
    this.beneficiaries = beneficiaries
    this.entitlement = entitlement
    this.otherNotes = otherNotes
    this.policyOwner = policyOwner
  }
}

export default Insurance

export const insuranceColumns = () => [
  ...assetColumns(),
  {
    key: 'purchaseDate',
    title: i18next.t('ASSET_PURCHASE_DATE'),
    type: IMPORT_COL_TYPES.DATE,
    required: true
  },
  {
    key: 'disposalDate',
    title: i18next.t('DISPOSAL_DATE'),
    type: IMPORT_COL_TYPES.DATE
  },
  {
    key: 'typeOfPolicy',
    title: i18next.t('TYPE_OF_POLICY'),
    required: true,
    type: IMPORT_COL_TYPES.LIST,
    isCustomList: true
  },
  {
    key: 'sumAssuredInAssetCurrency',
    title: i18next.t('SUM_ASSURED_IN_ASSET_CURRENCY'),
    type: IMPORT_COL_TYPES.CURRENCY
  },
  {
    key: 'premiumValue',
    title: i18next.t('PREMIUM_VALUE'),
    dataIndex: 'premium',
    objectKey: 'value',
    type: IMPORT_COL_TYPES.CURRENCY
  },
  {
    key: 'premiumUnit',
    title: i18next.t('PREMIUM_UNIT'),
    dataIndex: 'premium',
    objectKey: 'unit',
    type: IMPORT_COL_TYPES.LIST
  },
  {
    key: 'insurer',
    title: i18next.t('INSURER')
  },
  {
    key: 'policyNumber',
    title: i18next.t('POLICY_NUMBER')
  },
  {
    key: 'entitlement',
    title: i18next.t('ENTITLEMENT'),
    type: IMPORT_COL_TYPES.PERCENTAGE
  },
  {
    key: 'otherNotes',
    title: i18next.t('OTHER_NOTES')
  },
  ...referenceColumns()
]
