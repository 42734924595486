import React, { useState, useEffect, useContext } from 'react'
import { ACCESS_LEVEL, DATE_FORMAT } from '../../share/Constants'
import EventDetails from './EventDetails'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { useSelector } from 'react-redux'
import {
  Tag,
  Empty,
  Divider,
  Form,
  Input,
  DatePicker,
  AutoComplete,
  Modal
} from 'antd'
import Button from '../override/Button'
import { allValuesUndefined } from '../../share/helpers'
import { disabledDate } from './../../share/helpers'
import FileDetails from '../file/FileDetails'
import CustomTable from '../override/CustomTable'
import PasswordFolderModal from '../file/PasswordFolderModal'
import { uniq } from 'lodash'
import { AES, enc } from 'crypto-js'
import VaultContext from '../../contexts/VaultContext'
import { H3 } from '../override/Typography'
import AuthContext from '../../contexts/AuthContext'

function EventList(props) {
  const { isLoading, actionColumn, rejectColumn, eventDetails, form } = props
  const { privateFolderKey, isLockPrivateFolder, isReadonly } =
    useContext(VaultContext)
  const { isDelegateByPD, isProfessionalDeputy } = useContext(AuthContext)
  const { t } = useTranslation()
  const { activeEvents, pendingEvents, rejectedEvents } = useSelector(state =>
    isReadonly ? state.otherEvents : state.events
  )
  const { activePasswords, pendingPasswords } = useSelector(state =>
    isReadonly ? state.otherPasswords : state.passwords
  )
  const { activeAssetsLiabilities, pendingAssetsLiabilities } = useSelector(
    state =>
      isReadonly ? state.otherAssetsLiabilities : state.assetsLiabilities
  )
  const { activeContacts, pendingContacts } = useSelector(state =>
    isReadonly ? state.otherContacts : state.contacts
  )
  const { activeDocuments, pendingDocuments } = useSelector(state =>
    isReadonly ? state.otherDocuments : state.documents
  )
  const { accessLevel } = useSelector(state => state.settings)
  const { getFieldDecorator, getFieldsValue, getFieldValue } = form
  const [isDetailEventModal, setIsDetailEventModal] = useState(false)
  const [searchResults, setSearchResults] = useState(activeEvents)
  const [docItem, setDocItem] = useState({})
  const [isShowLinkedDocument, setIsShowLinkedDocument] = useState(false)
  const [passwordFolderModalVisible, setPasswordFolderModalVisible] =
    useState(false)
  const [fileDetailsVisible, setFileDetailsVisible] = useState(false)
  const privateFolder = activeDocuments.find(folder => folder.isPrivate)

  const privateFolderPassword = !!privateFolder?.password
    ? AES.decrypt(privateFolder.password, privateFolderKey).toString(enc.Latin1)
    : undefined

  useEffect(() => {
    setSearchResults(activeEvents)
  }, [activeEvents])

  const handleSearch = () => {
    form.validateFields((err, values) => {
      if (err) return
      if (
        !(
          values.description ||
          values.tags ||
          values.startDate ||
          values.endDate
        )
      ) {
        return
      }
      let filterdEvents = []
      if (values.description) {
        filterdEvents = activeEvents.filter(doc =>
          doc.description
            .toLowerCase()
            .includes(values.description.trim().toLowerCase())
        )
      }
      if (values.tags) {
        filterdEvents = activeEvents.filter(
          doc =>
            doc.tags &&
            doc.tags
              .map(tag => tag.toLowerCase())
              .includes(values.tags.trim().toLowerCase())
        )
      }

      if (values.startDate && values.endDate) {
        if (moment(values.startDate).isAfter(values.endDate)) {
          filterdEvents = []
        }
        if (moment(values.startDate).isSame(values.endDate)) {
          filterdEvents = activeEvents.filter(
            doc =>
              moment(doc.date).startOf('day').valueOf() ===
                moment(values.startDate).startOf('day').valueOf() &&
              moment(doc.date).startOf('day').valueOf() ===
                moment(values.endDate).startOf('day').valueOf()
          )
        }
        if (moment(values.startDate).isBefore(values.endDate)) {
          filterdEvents = activeEvents.filter(
            doc =>
              moment(doc.date).startOf('day').valueOf() >=
                moment(values.startDate).startOf('day').valueOf() &&
              moment(doc.date).startOf('day').valueOf() <=
                moment(values.endDate).startOf('day').valueOf()
          )
        }
      }

      if (values.startDate && !values.endDate) {
        filterdEvents = activeEvents.filter(
          doc =>
            moment(doc.date).startOf('day').valueOf() >=
            moment(values.startDate).startOf('day').valueOf()
        )
      }

      if (values.endDate && !values.startDate) {
        filterdEvents = activeEvents.filter(
          doc =>
            moment(doc.date).startOf('day').valueOf() <=
            moment(values.endDate).startOf('day').valueOf()
        )
      }

      setSearchResults(filterdEvents)
    })
  }

  const resetFields = () => {
    form.resetFields()
    setSearchResults(activeEvents)
  }

  const columns = [
    {
      key: 'description',
      width: 200,
      dataIndex: 'description',
      title: <span className="dragHandler">{t('DESCRIPTION')}</span>,
      render: (text, record) => <span>{record.description}</span>
    },
    {
      key: 'date',
      width: 100,
      dataIndex: 'date',
      title: <span className="dragHandler">{t('DATE')}</span>,
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
      defaultSortOrder: 'descend',
      render: (text, record) => (
        <span>{moment(record.date).format(DATE_FORMAT)}</span>
      )
    },
    {
      key: 'tags',
      width: 200,
      dataIndex: 'tags',
      title: <span className="dragHandler">{t('TAGS')}</span>,
      render: (text, record) => (
        <span className="item-name">
          {record.tags?.map(tag => (
            <Tag key={tag} color="green">
              {tag}
            </Tag>
          ))}
        </span>
      )
    },
    {
      key: 'assetsLiabilities',
      width: 200,
      dataIndex: 'assetsLiabilities',
      title: <span className="dragHandler">{t('ASSETS_LIABILITIES')}</span>,
      render: (text, record) => (
        <span className="item-name">
          {record.assetsLiabilities &&
            record.assetsLiabilities.map(alId =>
              (accessLevel === ACCESS_LEVEL.NEED_APPROVAL
                ? [...activeAssetsLiabilities, ...pendingAssetsLiabilities]
                : activeAssetsLiabilities
              ).map(al =>
                alId === al._id ? <Tag key={al._id}>{al.title}</Tag> : null
              )
            )}
        </span>
      )
    },
    {
      key: 'contacts',
      width: 200,
      dataIndex: 'contacts',
      title: <span className="dragHandler">{t('CONTACTS')}</span>,
      render: (text, record) => (
        <span className="item-name">
          {record.contacts &&
            record.contacts.map(cId =>
              (accessLevel === ACCESS_LEVEL.NEED_APPROVAL
                ? [...activeContacts, ...pendingContacts]
                : activeContacts
              ).map(contact =>
                cId === contact._id ? (
                  <Tag key={contact._id}>{contact.name}</Tag>
                ) : null
              )
            )}
        </span>
      )
    },
    {
      key: 'passwords',
      width: 200,
      dataIndex: 'passwords',
      title: <span className="dragHandler">{t('PASSWORDS')}</span>,
      render: (text, record) => (
        <span className="item-name">
          {record.passwords &&
            record.passwords.map(passwordId =>
              (accessLevel === ACCESS_LEVEL.NEED_APPROVAL
                ? [...activePasswords, ...pendingPasswords]
                : activePasswords
              ).map(password =>
                passwordId === password._id ? (
                  <Tag key={password._id}>{password.title}</Tag>
                ) : null
              )
            )}
        </span>
      )
    },
    {
      key: 'documents',
      width: 200,
      dataIndex: 'documents',
      title: <span className="dragHandler">{t('DOCUMENTS')}</span>,
      render: (text, record) => (
        <span className="item-name">
          {record.documents &&
            record.documents.map(dId =>
              (accessLevel === ACCESS_LEVEL.NEED_APPROVAL
                ? [...activeDocuments, ...pendingDocuments]
                : activeDocuments
              ).map(d => {
                if (dId === d._id) {
                  const item = {
                    id: d._id,
                    name: d.fileName,
                    ...d
                  }
                  return (
                    <Tag onClick={() => showFileDetails(item)} key={d._id}>
                      {d.fileName}
                    </Tag>
                  )
                } else {
                  return null
                }
              })
            )}
        </span>
      )
    }
  ]

  const dataSource = activeEvents.reduce((acc, currentEvent) => {
    if (currentEvent.tags?.length) {
      acc = [...acc, ...(currentEvent.tags || [])]
    }
    return acc
  }, [])

  const showFileDetails = item => {
    if (
      !!privateFolder?.password &&
      item.path.slice(0, privateFolder?.path.length) === privateFolder?.path
    ) {
      if (isLockPrivateFolder) {
        Modal.warning({
          title: t('WARNING_MSG'),
          content: t('LOCK_PRIVATE_FOLDER_MSG')
        })
      } else if (
        !!privateFolderPassword &&
        localStorage.getItem('privateFolderPassword') === privateFolderPassword
      ) {
        setDocItem(item)
        setFileDetailsVisible(true)
      } else {
        setIsShowLinkedDocument(true)
        setDocItem(item)
        setPasswordFolderModalVisible(true)
      }
    } else {
      setDocItem(item)
      setFileDetailsVisible(true)
    }
  }

  return (
    <>
      <Form
        layout="inline"
        onKeyUp={e => (e.keyCode === 13 || e.which === 13) && handleSearch()}
      >
        <Form.Item label={t('DESCRIPTION')}>
          {getFieldDecorator('description')(<Input />)}
        </Form.Item>
        <Form.Item label={t('START_DATE')}>
          {getFieldDecorator('startDate')(
            <DatePicker
              placeholder={t('SELECT_START_DATE')}
              disabledDate={startDate =>
                disabledDate(startDate, getFieldValue('endDate'))
              }
              format={DATE_FORMAT}
            />
          )}
        </Form.Item>
        <Form.Item label={t('END_DATE')}>
          {getFieldDecorator('endDate')(
            <DatePicker
              placeholder={t('SELECT_END_DATE')}
              disabledDate={endDate =>
                disabledDate(getFieldValue('startDate'), endDate)
              }
              format={DATE_FORMAT}
            />
          )}
        </Form.Item>
        <Form.Item label={t('TAGS')}>
          {getFieldDecorator('tags')(
            <AutoComplete
              dataSource={uniq(dataSource)}
              filterOption={(inputValue, option) =>
                option.props.children
                  .toUpperCase()
                  .indexOf(inputValue.toUpperCase()) !== -1
              }
            />
          )}
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            onClick={handleSearch}
            disabled={allValuesUndefined(getFieldsValue())}
          >
            {t('SEARCH')}
          </Button>
          &nbsp;
          <Button type="default" onClick={resetFields}>
            {t('RESET')}
          </Button>
        </Form.Item>
      </Form>
      <Divider />
      {searchResults?.length ? (
        <CustomTable
          rowKey={record => record._id.concat(record.date)}
          columns={[...columns, ...actionColumn]}
          dataSource={searchResults}
          scroll={{ x: true }}
        />
      ) : (
        <Empty />
      )}
      {!!pendingEvents?.length && accessLevel === ACCESS_LEVEL.NEED_APPROVAL && (
        <>
          <Divider type="horizontal" />
          <H3> {t('PENDING_EVENTS')} </H3>
          <CustomTable
            rowKey={record => record._id.concat(record.date)}
            scroll={{ x: true }}
            dataSource={pendingEvents}
            columns={[...columns, ...actionColumn]}
          />
        </>
      )}

      {!!rejectedEvents?.length &&
        ((isReadonly && isDelegateByPD) || isProfessionalDeputy) &&
        accessLevel === ACCESS_LEVEL.NEED_APPROVAL && (
          <>
            <Divider type="horizontal" />
            <H3> {t('REJECTED_EVENTS')} </H3>
            <CustomTable
              rowKey={record => record._id.concat(record.date)}
              scroll={{ x: true }}
              dataSource={rejectedEvents}
              columns={[...columns, ...rejectColumn, ...actionColumn]}
            />
          </>
        )}
      <EventDetails
        isLoading={isLoading}
        visible={isDetailEventModal}
        record={eventDetails}
        handleCancel={() => setIsDetailEventModal(false)}
      />
      <FileDetails
        visible={fileDetailsVisible}
        setVisible={setFileDetailsVisible}
        docItem={docItem}
      />
      <PasswordFolderModal
        visible={passwordFolderModalVisible}
        setVisible={setPasswordFolderModalVisible}
        isShowLinkedDocument={isShowLinkedDocument}
        isDeletePrivateFolder={false}
        docItem={docItem}
      />
    </>
  )
}

const WrappedEventListForm = Form.create({ name: 'eventListForm' })(EventList)

export default WrappedEventListForm
