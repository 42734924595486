import React from 'react'
import { DatePicker, Button } from 'antd'
import { disabledDate } from '../../share/helpers'
import { DATE_FORMAT } from '../../share/Constants'
import i18next from 'i18next'

export default function DateRangeFilter({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters
}) {
  return (
    <div style={{ padding: 8 }}>
      <DatePicker
        disabledDate={startDate =>
          disabledDate(startDate, selectedKeys[0] && selectedKeys[0].endDate)
        }
        format={DATE_FORMAT}
        placeholder={i18next.t('SELECT_START_DATE')}
        onChange={value =>
          setSelectedKeys([{ ...selectedKeys[0], startDate: value }])
        }
        value={selectedKeys[0] && selectedKeys[0].startDate}
        style={{ display: 'block', marginBottom: 8 }}
      />
      <DatePicker
        disabledDate={endDate =>
          disabledDate(selectedKeys[0] && selectedKeys[0].startDate, endDate)
        }
        format={DATE_FORMAT}
        placeholder={i18next.t('SELECT_END_DATE')}
        onChange={value =>
          setSelectedKeys([{ ...selectedKeys[0], endDate: value }])
        }
        value={selectedKeys[0] && selectedKeys[0].endDate}
        style={{ display: 'block', marginBottom: 8 }}
      />
      <Button
        type="primary"
        size="small"
        onClick={confirm}
        style={{ width: 60 }}
      >
        {i18next.t('OK')}
      </Button>
      <Button
        size="small"
        onClick={() => {
          clearFilters()
          setSelectedKeys([])
        }}
        style={{ width: 60, float: 'right' }}
      >
        {i18next.t('RESET')}
      </Button>
    </div>
  )
}
