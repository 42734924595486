import React, { useState, useContext, useEffect } from 'react'
import { Modal, Icon, Radio, message } from 'antd'
import { H4, Span, StatusText } from '../override/Typography'
import { ThemeContext } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import api from '../../lib/api'
import { fetchUser } from '../../features/user/userSlice'
import i18next from 'i18next'
import { checkSelectedLanguage } from './../../share/helpers'
import ReactCountryFlag from 'react-country-flag'

const SetupLanguageModal = props => {
  const theme = useContext(ThemeContext)
  const { user } = props
  const { defaultLanguage } = useSelector(state => state.user).user
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [visible, setVisible] = useState(false)
  const [language, setLanguage] = useState()
  const [isSaving, setIsSaving] = useState(false)

  useEffect(() => {
    setLanguage(defaultLanguage)
  }, [defaultLanguage])

  const handleSave = async () => {
    setIsSaving(true)
    try {
      await api.updateDefaultLanguage(
        user.username,
        JSON.stringify({
          language
        })
      )
      localStorage.setItem('updatedLanguage', language)
      i18next.changeLanguage(language)

      dispatch(fetchUser(user.username))
      setIsSaving(false)
    } catch (error) {
      message.error(t('FAIILED_TO_CHANGE_DEFAULT_LANGUAGE'))
    }
  }

  const handleCancel = () => {
    setVisible(false)
    setLanguage(defaultLanguage)
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 10
        }}
      >
        <H4>
          <Span>{t('CHANGE_DEFAULT_LANGUAGE')}</Span>
        </H4>
        <Span
          color={theme.primary}
          style={{ cursor: 'pointer' }}
          onClick={() => setVisible(true)}
        >
          <Icon type="setting" style={{ marginRight: 8 }} />
          {t('CHANGE')}
        </Span>
      </div>
      <StatusText color={theme.dark2}>
        {t('YOUR_DEFAULT_LANGUAGE_IS')}{' '}
        <b>{checkSelectedLanguage(defaultLanguage)}</b>
      </StatusText>
      <Modal
        width={600}
        visible={visible}
        title={t('CHANGE_DEFAULT_LANGUAGE')}
        okText={t('SAVE')}
        cancelText={t('CANCEL')}
        onOk={handleSave}
        onCancel={handleCancel}
        okButtonProps={{
          disabled: defaultLanguage === language,
          loading: isSaving
        }}
        maskClosable={false}
      >
        <div style={{ marginBottom: 10 }}>
          <Radio.Group
            name="language"
            defaultValue={defaultLanguage}
            onChange={e => setLanguage(e.target.value)}
          >
            <Radio value="en">
              <ReactCountryFlag countryCode="GB" svg /> English
            </Radio>
            <Radio value="vn">
              <ReactCountryFlag countryCode="VN" svg /> Tiếng Việt
            </Radio>
            <Radio value="id">
              <ReactCountryFlag
                countryCode="ID"
                svg
                style={{
                  width: 16,
                  height: 12,
                  border: '1px solid rgb(234, 229, 229, 0.7)',
                  borderRadius: 2
                }}
              />{' '}
              Bahasa Indonesia
            </Radio>
            <Radio value="cn">
              <ReactCountryFlag countryCode="CN" svg /> 简体中文
            </Radio>
          </Radio.Group>
        </div>
      </Modal>
    </>
  )
}

export default SetupLanguageModal
