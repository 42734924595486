import React, { useState, useEffect, useContext } from 'react'
import { Row, Col, Divider, Select, Spin } from 'antd'
import { H4, P, Span, B } from '../../components/override/Typography'
import { renderDate } from '../../share/helpers'
import VaultContext from '../../contexts/VaultContext'
import { getRecordVersions } from '../../lib/pouchDb'
import { DATE_TIME_FORMAT, CONTACT_TYPES } from '../../share/Constants'
import moment from 'moment'
//import { StringResources } from './../../share/StringResources'
import { onError } from '../../lib/sentry'
import { useTranslation } from 'react-i18next'

function Field({ title, value }) {
  return (
    <Row style={{ fontSize: 12 }} gutter={10}>
      <Col span={8}>
        <Span>{title}</Span>
      </Col>
      <Col span={16}>
        <B>{value}</B>
      </Col>
    </Row>
  )
}

export default function ContactInformation({ record, extra }) {
  const { userId, masterKey } = useContext(VaultContext)
  const [versions, setVersions] = useState([])
  const [version, setVersion] = useState({})
  const [versionId, setVersionId] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    async function fetchData() {
      if (record && record._id) {
        try {
          await getRecordVersions(
            'contactsHistory',
            userId,
            record._id,
            setVersions,
            setIsLoading,
            masterKey
          )
        } catch (e) {
          onError(e)
        }
      }
    }
    if (masterKey) {
      fetchData()
    }
  }, [userId, record, masterKey])

  useEffect(() => {
    if (versions.length) {
      setVersionId(versions[0]._id)
      setVersion(versions[0])
    }
  }, [versions])

  const handleVersionChange = versionId => {
    setVersionId(versionId)
    const selectedVersion = versions.find(version => version._id === versionId)
    setVersion(selectedVersion)
  }

  return (
    <Spin spinning={isLoading}>
      <Select
        placeholder={t('VERSION')}
        style={{ width: '100%' }}
        onChange={handleVersionChange}
        value={versionId}
      >
        {versions.map((version, index) => (
          <Select.Option key={index} value={version._id}>
            {t('VERSION')}: v{versions.length - index} -{' '}
            {moment(version.time).format(DATE_TIME_FORMAT)}
          </Select.Option>
        ))}
      </Select>
      <Divider />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 10
        }}
      >
        <H4>{t('CONTACT_INFORMATION')}</H4>
        {extra}
      </div>
      <P>{version.description}</P>
      <Divider />

      {/* Fields */}
      <Field title={t('TYPE')} value={version.type} />
      <Divider />

      <Field title={t('NAME')} value={version.name} />
      <Divider />
      {version.type === CONTACT_TYPES.INDIVIDUAL && (
        <>
          <Field
            title={t('DATE_OF_BIRTH')}
            value={renderDate(version.dateOfBirth)}
          />
          <Divider />
          <Field
            title={t('ID_PASSPORT_NUMBER')}
            value={version.idPassportNumber}
          />
        </>
      )}
      {version.type === CONTACT_TYPES.ORGANISATION && (
        <Field title={t('LEGAL_NAME')} value={version.legalName} />
      )}
      <Divider />

      <Field
        title={t('EMAILS')}
        value={version.emails?.map((email, index) => (
          <div key={index}>
            {email.name}: {email.value}
          </div>
        ))}
      />

      <Divider />
      <Field
        title={t('ADDRESSES')}
        value={version.addresses?.map((address, index) => (
          <div key={index}>
            {address.name}: {address.value}
          </div>
        ))}
      />

      <Divider />
      <Field
        title={t('PHONE')}
        value={version.phoneNumbers?.map((phoneNumber, index) => (
          <div key={index}>
            {phoneNumber.name}: {phoneNumber.value}
          </div>
        ))}
      />

      <Divider />
      <Field
        title={t('REFERENCES')}
        value={version.references?.map((reference, index) => (
          <div key={index}>
            {reference.name}: {reference.value}
          </div>
        ))}
      />

      <Divider />
      <Field title={t('URL')} value={version.url} />
    </Spin>
  )
}
