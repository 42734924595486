/**
 * Class representing a BaseModel.
 * This is the base class for almost every other class.
 */
class BaseModel {
  /**
   * Construct new BaseModel object
   * @param {object} params - BaseModel creation options:
   * @param {string} [params.createdBy] - ID of the user that created the BaseModel
   * @param {string} [params.updatedBy] - ID of the user that updated the BaseModel
   * @param {date} [params.createdTime] - Time that the BaseModel was created
   * @param {date} [params.updatedTime] - Time that the BaseModel was updated
   * @param {string} [params.version] - Version number of the BaseModel
   */
  constructor({
    createdBy = 'TEMP',
    updatedBy = 'TEMP',
    createdTime,
    updatedTime,
    version
  }) {
    this.createdBy = createdBy
    this.updatedBy = updatedBy
    this.createdTime = createdTime
    this.updatedTime = updatedTime
    this.version = version
  }
}

export default BaseModel
