import { Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { SketchPicker } from 'react-color'

const PieChartColorPickerModal = props => {
  const {
    visible,
    setVisible,
    subType,
    listAssetSubTypeColors,
    setListAssetSubTypeColors,
    listLiabilitySubtypeColor,
    setListLiabilitySubtypeColor
  } = props
  const [color, setColor] = useState('')

  useEffect(() => {
    if (visible && subType?.color) {
      setColor(subType.color)
    }
  }, [visible, subType])

  const handleOk = () => {
    if (subType.type === 'asset') {
      const newAssetSubTypeColors = listAssetSubTypeColors.map(st => {
        return st.name === subType.name ? { ...st, color: color } : st
      })
      setListAssetSubTypeColors(newAssetSubTypeColors)
    } else {
      const newLiabilitySubtypeColor = listLiabilitySubtypeColor.map(st => {
        return st.name === subType.name ? { ...st, color: color } : st
      })
      setListLiabilitySubtypeColor(newLiabilitySubtypeColor)
    }
    setVisible(false)
    setColor('')
  }

  return (
    <Modal
      title={subType?.name}
      visible={visible}
      onCancel={() => setVisible(false)}
      onOk={handleOk}
      bodyStyle={{ padding: '10px' }}
      width={240}
    >
      <SketchPicker
        color={color}
        onChangeComplete={color => setColor(color.hex)}
        disableAlpha={true}
        presetColors={[
          '#EB5A46',
          '#60BD4F',
          '#C99957',
          '#C80000',
          '#FF9F19',
          '#50E898',
          '#0279BF',
          '#C376E0',
          '#006D3E',
          '#2B5799',
          '#840163',
          '#0087FF'
        ]}
      />
    </Modal>
  )
}

export default PieChartColorPickerModal
