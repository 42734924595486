import { createSlice } from '@reduxjs/toolkit'
import { getRecords } from '../../lib/pouchDb'
import { onError } from '../../lib/sentry'

const initialState = {
  passwords: [],
  deletedPasswords: [],
  activePasswords: [],
  pendingPasswords: [],
  rejectedPasswords: [],
  passwordTags: [],
  pendingPasswordTags: [],
  isLoading: false,
  isPendingLoading: false,
  error: null,
  pendingError: null
}

const passwords = createSlice({
  name: 'passwords',
  initialState,
  reducers: {
    getPasswordsStart(state) {
      state.isLoading = true
      state.error = null
    },
    getPasswordsSuccess(state, action) {
      const { passwords } = action.payload
      state.passwords = passwords
      state.deletedPasswords = passwords.filter(item => item.deleted)
      state.activePasswords = passwords.filter(item => !item.deleted)
      state.passwordTags = passwords
        .filter(item => !item.deleted && !!item.tags?.length)
        .map(item => item.tags)
      state.isLoading = false
      state.error = null
    },
    getPasswordsFailure(state, action) {
      state.isLoading = false
      state.error = action.payload
    },
    getPendingPasswordsStart(state) {
      state.pendingLoading = true
      state.pendingError = null
    },
    getPendingPasswordsSuccess(state, action) {
      const { passwords } = action.payload
      state.pendingPasswords = passwords.filter(c => c.status === 'Draft')
      state.rejectedPasswords = passwords.filter(c => c.status === 'Rejected')
      state.pendingPasswordTags = passwords
        .filter(
          password => password.status === 'Draft' && !!password.tags?.length
        )
        .map(password => password.tags)
      state.isPendingLoading = false
      state.pendingError = null
    },
    getPendingPasswordsFailure(state, action) {
      state.isPendingLoading = false
      state.pendingError = action.payload
    }
  }
})

export const {
  getPasswordsStart,
  getPasswordsSuccess,
  getPasswordsFailure,
  getPendingPasswordsStart,
  getPendingPasswordsSuccess,
  getPendingPasswordsFailure
} = passwords.actions

export default passwords.reducer

export const fetchPasswords = (userId, masterKey) => async dispatch => {
  try {
    dispatch(getPasswordsStart())
    const passwords = await getRecords(userId, 'passwords', masterKey)
    dispatch(getPasswordsSuccess({ passwords }))
  } catch (err) {
    onError(err)
    dispatch(getPasswordsFailure(err.toString()))
  }
}

export const fetchPendingPasswords = (userId, masterKey) => async dispatch => {
  try {
    dispatch(getPendingPasswordsStart())
    const passwords = await getRecords(userId, 'pendingPasswords', masterKey)
    dispatch(getPendingPasswordsSuccess({ passwords }))
  } catch (err) {
    onError(err)
    dispatch(getPendingPasswordsFailure(err.toString()))
  }
}