import React, {
  useState,
  useEffect,
  Suspense,
  useContext,
  useCallback
} from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'
import Login from './components/pages/Login'
import Signup from './components/pages/Signup'
import Home from './components/pages/Home'
import Settings from './components/settings/Settings'
import './App.scss'
import {
  getCognitoUser,
  isValidSession,
  getUserSession,
  getUserPool
} from './lib/cognito'
import ForgotPassword from './components/pages/ForgotPassword'
import Contacts from './components/contacts/Contacts'
import Files from './components/file/Files'
import Starred from './components/file/Starred'
import Trash from './components/pages/Trash'
import TermsOfService from './components/pages/TermsOfService'
import RecoverData from './components/pages/RecoverData'
import AuthLayout from './components/layout/AuthLayout'
import UnauthLayout from './components/layout/UnauthLayout'
import Button from './components/override/Button'
import { Link } from 'react-router-dom'
import Rates from './components/settings/Rates'
import {
  setApiInterceptor,
  clearApiInterceptor,
  setProviderApiAuthorization
} from './lib/api'
import { loadRecords, destroyAllDbs } from './lib/pouchDb'
import { PREDEFINED_FOLDERS } from './share/Constants'
import { useDispatch } from 'react-redux'
import {
  fetchContacts,
  fetchPendingContacts
} from './features/contacts/contactsSlice'
import {
  fetchAssetsLiabilities,
  fetchPendingAssetsLiabilities,
  fetchValuations,
  getAssetsLiabilitiesStart
} from './features/assets-liabilities/assetsLiabilitiesSlice'
import {
  fetchDocuments,
  fetchPendingUnlockFiles,
  fetchPendingDocuments
} from './features/documents/documentsSlice'
import { fetchDeputies } from './features/deputies/deputiesSlice'
import { fetchCustomer } from './features/payment/customerSlice'
import { Span2 } from './components/override/Typography'
import AssetLiabilityDetails from './components/assets-liabilities/AssetLiabilityDetails'
import ConfirmSignUp from './components/pages/ConfirmSignup'
import AssetLiabilityAddEdit from './components/assets-liabilities/AssetLiabilityAddEdit'
import Deputy from './components/deputy/Deputy'
import Profile from './components/settings/Profile'
import Payment from './components/payment/Payment'
import ContactAddEdit from './components/contacts/ContactAddEdit'
import CalendarComponent from './components/calendar/Calendar'
import api from './lib/api'
import {
  getRatesSuccess,
  fetchBaseCurrency,
  fetchAccessLevel
} from './features/settings/settingsSlice'
import {
  getUserStart,
  getUserSuccess,
  getUserFailure
} from './features/user/userSlice'
import { Modal, Collapse } from 'antd'
import LegacyManagement from './components/legacy-management/LegacyManagement'
import ProfessionalDeputyTermsOfService from './components/pages/ProfessionalDeputyTermsOfService'
import WatigaTrustPDTermsOfService from './components/pages/WatigaTrustPDTermsOfService'
import Goodbye from './components/pages/Goodbye'
import AccountMigrated from './components/pages/AccountMigrated'
import { GAevent, initGA } from './lib/ga'
import { AES, enc } from 'crypto-js'
import AuthContext from './contexts/AuthContext'
import Gifts from './components/settings/Gifts'
import { onError } from './lib/sentry'
import { ThemeContextProvider } from './contexts/ThemeContext'
import { ThemeContext } from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { checkSelectedLanguage } from './share/helpers'
import EventsModal from './components/modals/EventsModal'
import { fetchEvents, fetchPendingEvents } from './features/events/eventsSlice'
import Pending from './components/file/Pending'
import moment from 'moment'
import Passwords from './components/passwords/Passwords'
import {
  fetchPasswords,
  fetchPendingPasswords
} from './features/passwords/passwordsSlice'
import SubscriptionData from './components/common/SubscriptionData'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import RedirectVault from './components/pages/Redirect'
import { CognitoUser } from 'amazon-cognito-identity-js'
import Client from 'aws-appsync'
import { ApolloProvider } from 'react-apollo'
import { Rehydrated } from 'aws-appsync-react'
import { ApolloProvider as ApolloHooksProvider } from 'react-apollo-hooks'
import config from './config'

const { Panel } = Collapse

let isInitingDBs = false

export default function App() {
  const [isAuthenticating, setIsAuthenticating] = useState(true)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [user, setUser] = useState({})
  const [isDeputyOnly, setIsDeputyOnly] = useState(false)
  const [isDelegateByPD, setIsDelegateByPD] = useState(false)
  const [isProfessionalDeputy, setIsProfessionalDeputy] = useState(undefined)
  const [eventsModalVisible, setEventsModalVisible] = useState(false)
  const [reminderEvents, setReminderEvents] = useState([])
  const [masterKey, setMasterKey] = useState()
  const theme = useContext(ThemeContext)
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
  const [userStorage, setUserStorage] = useState()
  const [isRemoveLanguage, setIsRemoveLanguage] = useState(true)
  const [showedMobilePromptModal, setShowedMobilePromptModal] = useState(false)
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const updatedLanguage = localStorage.getItem('updatedLanguage')
  const dontShowEventsModal = localStorage.getItem('dontShowEventsModal')
  const isNotReload = localStorage.getItem('NotReload')

  let idToken

  if (user.username) {
    const keyPrefix = `CognitoIdentityServiceProvider.${user.pool.getClientId()}.${
      user.username
    }`
    const idTokenKey = `${keyPrefix}.idToken`
    idToken = localStorage.getItem(idTokenKey)
  }

  const client = new Client({
    url: config.appSync.GRAPHQL_URL,
    region: config.appSync.REGION,
    auth: {
      type: config.appSync.AUTHENTICATION_TYPE,
      jwtToken: idToken
    },
    disableOffline: true
  })

  const resetAppStates = () => {
    setIsAuthenticated(false)
    setUser({})
    setIsDeputyOnly(false)
    setIsDelegateByPD(false)
    setIsProfessionalDeputy(undefined)
    setUserStorage(undefined)
  }

  useEffect(() => {
    if (isMdUp) {
      moment.updateLocale('en', {
        weekdaysMin: [
          t('SUN'),
          t('MON'),
          t('TUE'),
          t('WED'),
          t('THU'),
          t('FRI'),
          t('SAT')
        ]
      })

      moment.updateLocale('en', {
        monthsShort: [
          t('JAN'),
          t('FEB'),
          t('MAR'),
          t('APR'),
          t('MAY'),
          t('JUN'),
          t('JUL'),
          t('AUG'),
          t('SEP'),
          t('OCT'),
          t('NOV'),
          t('DEC')
        ]
      })
    } else {
      moment.updateLocale('en', {
        weekdaysMin: [
          t('SUN_MD'),
          t('MON_MD'),
          t('TUE_MD'),
          t('WED_MD'),
          t('THU_MD'),
          t('FRI_MD'),
          t('SAT_MD')
        ]
      })

      moment.updateLocale('en', {
        monthsShort: [
          t('JAN_MD'),
          t('FEB_MD'),
          t('MAR_MD'),
          t('APR_MD'),
          t('MAY_MD'),
          t('JUN_MD'),
          t('JUL_MD'),
          t('AUG_MD'),
          t('SEP_MD'),
          t('OCT_MD'),
          t('NOV_MD'),
          t('DEC_MD')
        ]
      })
    }
  }, [t, isMdUp])

  useEffect(() => {
    function onLoad() {
      initGA()

      const externalUser = localStorage.getItem('External_User')
      const user = getCognitoUser()
      if (!externalUser && !user) {
        setIsAuthenticating(false)
        destroyAllDbs()
        localStorage.clear()
        return
      }

      if (user) {
        getUserSession(user, (err, session) => {
          if (err || !isValidSession(session)) {
            user.signOut()
            destroyAllDbs()
            localStorage.clear()
            setIsAuthenticated(false)
            clearApiInterceptor()
            return
          }
          const isAuthenticated = !!localStorage.getItem(user.username)
          setIsAuthenticated(isAuthenticated)
          if (isAuthenticated) {
            setUser(user)
            setApiInterceptor(user)
          }
        })
        setIsAuthenticating(false)
      }

      if (externalUser) {
        const cognitoUser = new CognitoUser({
          Username: externalUser,
          Pool: getUserPool()
        })
        const idToken = localStorage.getItem('id_token')
        setIsAuthenticated(true)
        setUser(cognitoUser)
        setProviderApiAuthorization(idToken)
        setIsAuthenticating(false)
      }
    }

    onLoad()
  }, [])

  const initUserData = useCallback(() => {
    const encryptedKey = localStorage.getItem(user.username)
    const initDBs = async (userId, masterKey) => {
      isInitingDBs = true
      try {
        // as we will normally start from the Registry screen, dispatch this action
        // to have the loading state reflected correctly while loading records
        dispatch(getAssetsLiabilitiesStart())

        await Promise.all([
          loadRecords('assetsLiabilities', userId, masterKey),
          loadRecords('assetsLiabilitiesValuations', userId, masterKey),
          loadRecords('contacts', userId, masterKey),
          loadRecords('documents', userId, masterKey, PREDEFINED_FOLDERS),
          loadRecords('assetsLiabilitiesHistory', userId, masterKey),
          loadRecords('contactsHistory', userId, masterKey),
          loadRecords('pendingAssetsLiabilities', userId, masterKey),
          loadRecords('pendingContacts', userId, masterKey),
          loadRecords('pendingDocuments', userId, masterKey),
          loadRecords('events', userId, masterKey),
          loadRecords('passwords', userId, masterKey),
          loadRecords('pendingPasswords', userId, masterKey),
          loadRecords('pendingEvents', userId, masterKey)
        ])
        dispatch(fetchAssetsLiabilities(userId, masterKey))
        dispatch(fetchPendingAssetsLiabilities(userId, masterKey))
        dispatch(fetchPendingContacts(userId, masterKey))
        dispatch(fetchContacts(userId, masterKey))
        dispatch(fetchEvents(userId, masterKey))
        dispatch(fetchPendingEvents(userId, masterKey))
        dispatch(fetchDocuments(userId, masterKey))
        dispatch(fetchPendingDocuments(userId, masterKey))
        dispatch(fetchValuations(userId, masterKey))
        dispatch(fetchPasswords(userId, masterKey))
        dispatch(fetchPendingPasswords(userId, masterKey))
      } catch (e) {
        onError(e)
        Modal.error({
          title: t('FAILED_TO_LOAD_YOUR_VAULTBOX_DATA'),
          content: (
            <>
              <Collapse style={{ margin: '1.5em 0' }}>
                <Panel header={t('VIEW_DETAILS')}>{e.message}</Panel>
              </Collapse>
              <div>{t('PLEASE_LOG_IN_AGAIN_TO_RETRY')}</div>
            </>
          ),
          onOk() {
            user.signOut()
            destroyAllDbs()
            localStorage.clear()
            window.location.href = '/login'
          },
          okText: t('LOG_OUT'),
          keyboard: false
        })
      } finally {
        isInitingDBs = false
      }
    }

    if (user && user.username && isAuthenticated && encryptedKey) {
      dispatch(getUserStart())
      api
        .getUser(user.username)
        .then(async res => {
          const {
            isDeputyOnly,
            professionalDeputyId,
            extraKey,
            defaultLanguage,
            events,
            delegatedByProfessionalDeputies,
            usedStorage
          } = res.data
          const isProfessionalDeputy = !!(isDeputyOnly && professionalDeputyId)
          const masterKey = AES.decrypt(encryptedKey, extraKey).toString(
            enc.Latin1
          )
          setMasterKey(masterKey)
          if (
            delegatedByProfessionalDeputies?.length &&
            delegatedByProfessionalDeputies.some(
              dpd =>
                (!dpd.professionalDeputyId &&
                  !dpd.primaryUsers.includes(user.username)) ||
                dpd.professionalDeputyId !== professionalDeputyId
            )
          ) {
            setIsDelegateByPD(true)
          }
          setIsDeputyOnly(isDeputyOnly || false)
          setIsProfessionalDeputy(isProfessionalDeputy)

          if (!isProfessionalDeputy && !isInitingDBs) {
            await initDBs(user.username, masterKey)
          }

          dispatch(getUserSuccess(res.data || {}))
          dispatch(fetchCustomer(user.username))
          dispatch(fetchBaseCurrency(user.username))
          dispatch(getRatesSuccess(res.data || {}))

          if (!isDeputyOnly) {
            dispatch(fetchDeputies(user.username))
            dispatch(fetchPendingUnlockFiles(user.username))
            dispatch(fetchAccessLevel(user.username))
          }

          const selectedLanguage = i18next.language

          //if default language is false, update default language is selected language
          if (!defaultLanguage) {
            await api.updateDefaultLanguage(
              user.username,
              JSON.stringify({
                language: selectedLanguage
              })
            )

            i18next.changeLanguage(selectedLanguage)
            localStorage.setItem('updatedLanguage', selectedLanguage)
          } else {
            if (defaultLanguage !== selectedLanguage && !updatedLanguage) {
              Modal.confirm({
                title: t('CHANGE_DEFAULT_LANGUAGE'),
                content: (
                  <Trans
                    i18nKey="CHANGE_DEFAULT_LANGUAGE_SUMMARY"
                    values={{
                      selectedLanguage: checkSelectedLanguage(selectedLanguage),
                      defaultLanguage: checkSelectedLanguage(defaultLanguage)
                    }}
                  ></Trans>
                ),
                okText: t('SELECTED_LANGUAGE'),
                cancelText: t('DEFAULT_LANGUAGE'),
                onCancel: () => {
                  i18next.changeLanguage(defaultLanguage)
                  localStorage.setItem('updatedLanguage', defaultLanguage)
                },
                onOk: async () => {
                  i18next.changeLanguage(selectedLanguage)
                  localStorage.setItem('updatedLanguage', selectedLanguage)
                }
              })
            }

            if (defaultLanguage === selectedLanguage && !updatedLanguage) {
              localStorage.setItem('updatedLanguage', selectedLanguage)
            }
          }

          //show reminder events
          if (
            events?.length &&
            !dontShowEventsModal &&
            (defaultLanguage === selectedLanguage || updatedLanguage)
          ) {
            const time = new Date().getTime()
            const lstEvents = events
              ?.filter(
                e =>
                  e.date - time >= 0 &&
                  e.date - time <= e.reminderDays * 24 * 60 * 60 * 1000 &&
                  !e.dontShowAgain
              )
              .map(e => {
                return { id: e.id, date: e.date }
              })
            if (lstEvents?.length) {
              setReminderEvents(lstEvents)
              setEventsModalVisible(true)
            }
          }
          setUserStorage(usedStorage)
        })
        .catch(err => {
          onError(err)
          dispatch(getUserFailure(err.toString()))
        })

      if (
        window.screen.width < 992 &&
        !localStorage.getItem('showedMobilePrompt')
      ) {
        setShowedMobilePromptModal(true)
        Modal.info({
          content: t('SHOWED_MOBILE_PROMPT'),
          onOk: () => {
            setShowedMobilePromptModal(false)
          }
        })
        localStorage.setItem('showedMobilePrompt', true)
      }
    }
  }, [user, dispatch, isAuthenticated, t, updatedLanguage, dontShowEventsModal])

  useEffect(() => {
    initUserData()
  }, [initUserData, isNotReload])

  useEffect(() => {
    if (updatedLanguage) {
      i18next.changeLanguage(updatedLanguage)
    }
  }, [updatedLanguage])

  function PrivateRoute({ children, isPrimaryRoute, ...rest }) {
    return (
      <Route
        {...rest}
        render={({ location, history }) =>
          isAuthenticated ? (
            <AuthContext.Provider
              value={{
                user,
                isDeputyOnly,
                isProfessionalDeputy,
                isDelegateByPD,
                userStorage,
                showedMobilePromptModal
              }}
            >
              {isProfessionalDeputy && isPrimaryRoute ? (
                <Redirect
                  to={{
                    pathname: '/deputy',
                    state: { from: location }
                  }}
                />
              ) : (
                <AuthLayout
                  initUserData={initUserData}
                  resetAppStates={resetAppStates}
                  location={location}
                  history={history}
                  isPrimaryRoute={isPrimaryRoute}
                >
                  {children}
                </AuthLayout>
              )}
            </AuthContext.Provider>
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: location }
              }}
            />
          )
        }
      />
    )
  }

  const loginHeader = (
    <>
      <Span2>
        <Trans i18nKey="NEW_TO_VAULTBOX"></Trans>
      </Span2>
      <Button size="large">
        <Link
          to="/signup"
          onClick={() => {
            GAevent({
              category: 'Login',
              action: 'Clicked Register button'
            })
          }}
        >
          {t('REGISTER')}
        </Link>
      </Button>
    </>
  )

  const signupHeader = (
    <>
      <Span2>{t('ALREADY_HAVE_AN_ACCOUNT')}</Span2>
      <Button size="large">
        <Link to="/login">{t('SIGN_IN')}</Link>
      </Button>
    </>
  )

  return (
    !isAuthenticating && (
      <ApolloProvider client={client}>
        <ApolloHooksProvider client={client}>
          <Rehydrated>
            <Suspense fallback={null}>
              <ThemeContextProvider>
                <Router>
                  <Switch>
                    <Route path="/confirmsignup">
                      <UnauthLayout>
                        <ConfirmSignUp />
                      </UnauthLayout>
                    </Route>
                    <Route path="/signup">
                      <UnauthLayout header={signupHeader}>
                        <Signup />
                      </UnauthLayout>
                    </Route>

                    <Route path="/recover-data">
                      <UnauthLayout header={signupHeader}>
                        <RecoverData
                          setIsAuthenticated={setIsAuthenticated}
                          setUser={setUser}
                        />
                      </UnauthLayout>
                    </Route>

                    <Route path="/terms-of-service">
                      <UnauthLayout>
                        <TermsOfService />
                      </UnauthLayout>
                    </Route>

                    <Route path="/pd-terms-of-service">
                      <UnauthLayout>
                        <ProfessionalDeputyTermsOfService />
                      </UnauthLayout>
                    </Route>

                    <Route path="/wt-pd-terms-of-service">
                      <UnauthLayout>
                        <WatigaTrustPDTermsOfService />
                      </UnauthLayout>
                    </Route>

                    <Route path="/resetpassword">
                      <UnauthLayout header={signupHeader}>
                        <ForgotPassword
                          setIsAuthenticated={setIsAuthenticated}
                          setUser={setUser}
                        />
                      </UnauthLayout>
                    </Route>

                    <Route path="/goodbye">
                      <UnauthLayout>
                        <Goodbye />
                      </UnauthLayout>
                    </Route>

                    <Route path="/account-migrated">
                      <UnauthLayout>
                        <AccountMigrated />
                      </UnauthLayout>
                    </Route>

                    <Route path="/redirect-vault">
                      <UnauthLayout isRemoveLanguage={isRemoveLanguage}>
                        <RedirectVault
                          setIsRemoveLanguage={setIsRemoveLanguage}
                          setIsAuthenticated={setIsAuthenticated}
                          setUser={setUser}
                          user={user}
                        />
                      </UnauthLayout>
                    </Route>

                    <Route path="/login">
                      {isAuthenticated ? (
                        <Redirect
                          to={{
                            pathname: '/',
                            state: { from: '/login' }
                          }}
                        />
                      ) : (
                        <UnauthLayout header={loginHeader}>
                          <Login
                            setIsAuthenticated={setIsAuthenticated}
                            setUser={setUser}
                          />
                        </UnauthLayout>
                      )}
                    </Route>
                    <PrivateRoute path="/settings/payment">
                      <Settings>
                        <Payment />
                      </Settings>
                    </PrivateRoute>
                    <PrivateRoute path="/settings/currency">
                      <Settings>
                        <Rates />
                      </Settings>
                    </PrivateRoute>
                    <PrivateRoute path="/settings/gifts">
                      <Settings>
                        <Gifts />
                      </Settings>
                    </PrivateRoute>
                    <PrivateRoute path="/settings">
                      <Settings>
                        <Profile />
                      </Settings>
                    </PrivateRoute>
                    <PrivateRoute path="/trash/:userId">
                      <Trash />
                    </PrivateRoute>
                    <PrivateRoute path="/trash/:userId?">
                      <Trash />
                    </PrivateRoute>
                    <PrivateRoute path="/contacts/add/:userId?">
                      <ContactAddEdit />
                    </PrivateRoute>
                    <PrivateRoute path="/contacts/:userId?/:contactId/edit">
                      <ContactAddEdit />
                    </PrivateRoute>
                    <PrivateRoute path="/contacts/:userId">
                      <Contacts />
                    </PrivateRoute>
                    <PrivateRoute path="/calendar/:userId">
                      <CalendarComponent />
                    </PrivateRoute>
                    <PrivateRoute path="/calendar/:userId?">
                      <CalendarComponent />
                    </PrivateRoute>
                    <PrivateRoute path="/contacts/:userId?">
                      <Contacts />
                    </PrivateRoute>
                    <PrivateRoute path="/legacy-management" isPrimaryRoute>
                      <LegacyManagement />
                    </PrivateRoute>
                    <PrivateRoute path="/files/:userId/pending">
                      <Pending isPending={true} />
                    </PrivateRoute>
                    <PrivateRoute path="/files/:userId?/pending" isPrimaryRoute>
                      <Pending isPending={true} />
                    </PrivateRoute>
                    <PrivateRoute path="/files/:userId/rejected">
                      <Pending isRejected={true} />
                    </PrivateRoute>
                    <PrivateRoute
                      path="/files/:userId?/rejected"
                      isPrimaryRoute
                    >
                      <Pending isRejected={true} />
                    </PrivateRoute>
                    <PrivateRoute path="/files/:userId/starred">
                      <Starred />
                    </PrivateRoute>
                    <PrivateRoute path="/files/:userId?/starred">
                      <Starred />
                    </PrivateRoute>
                    <PrivateRoute path="/files/:userId">
                      <Files />
                    </PrivateRoute>
                    <PrivateRoute path="/files/:userId?">
                      <Files />
                    </PrivateRoute>
                    <PrivateRoute path="/passwords/:userId">
                      <Passwords />
                    </PrivateRoute>
                    <PrivateRoute path="/passwords/:userId?">
                      <Passwords />
                    </PrivateRoute>
                    <PrivateRoute path="/assets-liabilities/add/:userId?">
                      <AssetLiabilityAddEdit />
                    </PrivateRoute>
                    <PrivateRoute path="/assets-liabilities/:userId?/:assetLiabilityId/edit">
                      <AssetLiabilityAddEdit />
                    </PrivateRoute>
                    <PrivateRoute path="/assets-liabilities/:assetLiabilityId/:userId">
                      <AssetLiabilityDetails />
                    </PrivateRoute>
                    <PrivateRoute path="/assets-liabilities/:assetLiabilityId/:userId?">
                      <AssetLiabilityDetails />
                    </PrivateRoute>
                    <PrivateRoute path="/deputy">
                      <Deputy />
                    </PrivateRoute>
                    <PrivateRoute exact path="/:userId">
                      <Home />
                    </PrivateRoute>
                    <PrivateRoute exact path="/:userId?">
                      <Home />
                    </PrivateRoute>
                  </Switch>
                </Router>
              </ThemeContextProvider>
              <EventsModal
                visible={eventsModalVisible}
                setVisible={setEventsModalVisible}
                reminderEvents={reminderEvents}
                userId={user.username}
              />
              <SubscriptionData userId={user.username} masterKey={masterKey} />
            </Suspense>
          </Rehydrated>
        </ApolloHooksProvider>
      </ApolloProvider>
    )
  )
}
