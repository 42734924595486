import React, { useState, useContext, useEffect } from 'react'
import { currencyFormat } from '../../share/helpers'
import { ThemeContext } from 'styled-components'
import { useSelector } from 'react-redux'
import { H4 } from '../override/Typography'
import VaultContext from '../../contexts/VaultContext'
import { CORE_TYPES, CHART_GROUP_BY } from '../../share/Constants'
import GroupedPieChart from './GroupedPieChart'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

export default function NetworthByTypePieCharts({ type, chartGroupBy }) {
  const { isReadonly } = useContext(VaultContext)
  const theme = useContext(ThemeContext)
  const [total, setTotal] = useState(null)
  const [records, setRecords] = useState([])
  const { baseCurrency } = useSelector(state => state.settings)
  const { activeAssetsLiabilities } = useSelector(state =>
    isReadonly ? state.otherAssetsLiabilities : state.assetsLiabilities
  )

  const { t } = useTranslation()

  useEffect(() => {
    const records = activeAssetsLiabilities.filter(
      al => al.type === type && al.includeValueInNetWorth !== false
    )

    const total = records.reduce((total, item) => {
      return (
        (total || 0) +
        (type === CORE_TYPES.ASSET
          ? item.valuationInBaseCurrency || 0
          : item.outstandingValueInBaseCurrency || 0)
      )
    }, 0)

    setRecords(records)
    setTotal(total)
  }, [activeAssetsLiabilities, type])

  return (
    <>
      <div style={{ textAlign: 'center' }}>
        <H4>
          {t('TOTAL')}{' '}
          {type === CORE_TYPES.ASSET ? t('ASSETS') : t('LIABILITIES')}:{' '}
          <span style={{ color: theme.primary }}>
            {baseCurrency} {currencyFormat(total)}
          </span>
        </H4>
        <span>(as at {moment().format('DD/MM/YYYY')})</span>
      </div>
      <GroupedPieChart
        type={type}
        records={records}
        chartGroupBy={CHART_GROUP_BY.SUBTYPE}
      />
      <GroupedPieChart
        type={type}
        records={records}
        chartGroupBy={CHART_GROUP_BY.CURRENCY}
      />
    </>
  )
}
