import React from 'react'
import FilesFoldersList from './FilesFoldersList'
import { LEGACY_ACCESSED_BY } from '../../share/Constants'
import { useTranslation } from 'react-i18next'

const LegacyInfoSummary = ({
  permissions,
  selectedFilesFolders,
  accessedBy,
  effectiveUpon,
  otherPerson,
  isDeputyView,
  selectedPrimaryUser
}) => {
  const { t } = useTranslation()
  const requestByUserStr = isDeputyView
    ? t('REQUEST_BY_USER')
    : t('REQUEST_BY_YOU')
  const deathOrIncapacitatedStr = isDeputyView
    ? t('USER_DEATH_OR_INCAPACITATED_STATE')
    : t('YOUR_DEATH_OR_INCAPACITATED_STATE')

  return (
    <>
      <div>
        {isDeputyView ? t('WE_WILL_SHARE_USER') : t('WE_WILL_SHARE_YOUR')}{' '}
        {permissions?.fullAccess ? (
          <strong>{t('FULL_VAULTBOX')}</strong>
        ) : permissions?.registrySnapshot &&
          permissions?.selectedFilesFolders ? (
          <span>
            <strong>
              {t('FINAL_REGISTRY_SNAPSHOT_AND_SELECTED_FILES_FOLDERS')}:
            </strong>
            <div style={{ paddingLeft: 25 }}>
              <FilesFoldersList selectedFilesFolders={selectedFilesFolders}/>
            </div>
          </span>
        ) : permissions?.registrySnapshot ? (
          <strong>{t('FINAL_REGISTRY_SNAPSHOT')}</strong>
        ) : permissions?.selectedFilesFolders ? (
          <span>
            <strong>{t('SELECTED_FILES_FOLDERS')}:</strong>
            <div style={{ paddingLeft: 25 }}>
              <FilesFoldersList selectedFilesFolders={selectedFilesFolders}/>
            </div>
          </span>
        ) : (
          ''
        )}
      </div>
      <div>
        {t('WITH')}:{' '}
        <strong>
          {accessedBy === LEGACY_ACCESSED_BY.OTHER_SPECIFIC_PERSON
            ? otherPerson?.name
            : isDeputyView
            ? t('USER_PROFESSIONAL_DEPUTY')
            : t('YOUR_PROFESSIONAL_DEPUTY')}
        </strong>
      </div>
      <div>
        {t('UPON')}:{' '}
        <strong>
          {effectiveUpon?.requestByUser && effectiveUpon?.deathOrIncapacitated
            ? `${requestByUserStr} or ${deathOrIncapacitatedStr}`
            : effectiveUpon?.deathOrIncapacitated
            ? deathOrIncapacitatedStr
            : effectiveUpon?.requestByUser
            ? requestByUserStr
            : ''}
        </strong>
      </div>
    </>
  )
}

export default LegacyInfoSummary
