import PouchDB from 'pouchdb'
import AllDbs from 'pouchdb-all-dbs'
import CryptoPouch from 'crypto-pouch'
import uuidv4 from 'uuid/v4'
import api from './api'
import moment from 'moment'
import { END_OF_TIME } from '../share/Constants'
import AssetLiabilityValuation from '../model/AssetLiabilityValuationModel'
import { s3Put, s3Get } from './awsSDK'
import { mapAssetLiabilityModel } from '../components/assets-liabilities/assetLiabilityHelpers'
import { getFileParts, getFullFilename } from '../share/formHelpers'
import { uniq } from 'lodash'
import { onError } from './sentry'

PouchDB.plugin(AllDbs)
PouchDB.plugin(CryptoPouch)

const wrapPouchDbAction = async (dbName, userId, masterKey, action) => {
  const db = new PouchDB(`${userId}_${dbName}`)

  try {
    db.crypto(masterKey)

    await action(db)

    await uploadEncryptedData(db, userId, dbName)
  } catch (err) {
    onError(err)
    throw err
  }
}

const destroyAllDbs = async () => {
  try {
    const dbs = await PouchDB.allDbs()
    await Promise.all(
      dbs.map(async db => {
        await new PouchDB(db).destroy()
      })
    )
  } catch (e) {
    onError(e)
  }
}

const reloadRecords = async (dbName, userId, masterKey) => {
  return new Promise(async (resolve, reject) => {
    const db = new PouchDB(`${userId}_${dbName}`)

    try {
      const content = await s3Get(userId, dbName)
      try {
        if (content && content.message) {
          throw new Error(content.message)
        } else {
          if (!content) {
            resolve(`Reload ${dbName} completed`)
            return
          } else {
            const { docs, crypto } = content
            const existingDocs = await getRecords(userId, dbName, masterKey)
            if (docs?.length) {
              if (existingDocs?.length) {
                await Promise.all(existingDocs.map(doc => db.remove(doc)))

                await db.bulkDocs(docs)
              } else {
                await db.remove('_local/crypto', '0-1')
                await db.put(crypto)
                await db.bulkDocs(docs)
              }
            } else {
              if (existingDocs) {
                const deletedRecords = existingDocs.map(doc => {
                  return {
                    ...doc,
                    _deleted: true
                  }
                })

                await db.bulkDocs(deletedRecords)
              }
            }
          }
        }

        resolve(`Reload ${dbName} completed`)
      } catch (err) {
        onError(err)
        reject(
          Error(`Reload ${dbName} failed: ${err.message || 'Unknown error'}`)
        )
      }
    } catch (error) {
      resolve(`Reload ${dbName} completed`)
    }
  })
}

const loadRecords = (dbName, userId, masterKey, initialRecords?) => {
  return new Promise(async (resolve, reject) => {
    const db = new PouchDB(`${userId}_${dbName}`)
    try {
      const content = await s3Get(userId, dbName)
      db.get('_local/crypto', async err => {
        // TODO: this should be checked in a more robust way, should not reply on the existence of this local doc
        try {
          if (err) {
            // Empty files should be uploaded already when the user finished signing up (in post confirmation lambda trigger).
            // If for some reason (e.g. issue with the lambda) and the file is not available there, let's just ignore it
            // and upload the vault later when a file is uploaded.
            // TODO: consider adding the files in case they're not found, just for migration purpose.
            // TODO: If the file is available but can't be get from S3, then should throw error and stop user from continue using the app.
            // And probably better error handling (may requires changes on server side - lambda)
            if (content && content.message) {
              throw new Error(content.message)
            } else {
              //const content = Buffer.from(data, 'base64').toString('ascii')

              // empty content, no record yet
              if (!content) {
                if (!initialRecords) {
                  resolve(`Load ${dbName} completed`)
                  return
                }

                db.crypto(masterKey)

                // add _id for records to make them valid PouchDB docs
                const initialDocs = initialRecords.map(record => {
                  const id = uuidv4()
                  const doc = {
                    ...record,
                    _id: record.idPrefix ? `${record.idPrefix}_${id}` : id
                  }
                  // delete as we don't need to store this
                  delete doc.idPrefix
                  return doc
                })

                await db.bulkDocs(initialDocs)
                await uploadEncryptedData(db, userId, dbName)
              } else {
                const { docs, crypto } = content
                await Promise.all([db.put(crypto), db.bulkDocs(docs)])
              }
            }
          }

          resolve(`Load ${dbName} completed`)
        } catch (err) {
          onError(err)
          reject(
            Error(`Load ${dbName} failed: ${err.message || 'Unknown error'}`)
          )
        }
      })
    } catch (error) {
      onError(error)
      resolve(`Load ${dbName} completed`)
    }
  })
}

const getRecords = async (userId, dbName, masterKey, options = {}) => {
  try {
    const db = new PouchDB(`${userId}_${dbName}`)
    db.crypto(masterKey)
    const allDocs = await db.allDocs({ ...options, include_docs: true })
    if (allDocs.total_rows) {
      await db.removeCrypto()
    }
    return allDocs.rows.map(row => row.doc)
  } catch (err) {
    throw err
  }
}

const getRecord = async (userId, dbName, id, masterKey) => {
  try {
    const db = new PouchDB(`${userId}_${dbName}`)
    db.crypto(masterKey)
    const doc = await db.get(id)
    await db.removeCrypto()
    return doc
  } catch (err) {
    throw err
  }
}

const uploadEncryptedData = async (db, userId, fileId) => {
  try {
    await db.removeCrypto()
    const values = await Promise.all([
      db.allDocs({ include_docs: true, descending: true }),
      db.get('_local/crypto')
    ])

    const encryptedDocs = values[0].rows.map(row => {
      const { doc } = row
      delete doc._rev
      return doc
    })
    const crypto = { ...values[1] }
    delete crypto._rev

    const content = {
      docs: encryptedDocs,
      crypto
    }

    const uploadResult = await s3Put(userId, fileId, JSON.stringify(content))
    return uploadResult
  } catch (e) {
    throw e
  }
}

const getRecordVersions = async (
  dbName,
  userId,
  recordId,
  setVersions,
  setIsLoading,
  masterKey
) => {
  try {
    setIsLoading(true)
    const db = new PouchDB(`${userId}_${dbName}`)
    db.crypto(masterKey)
    const allDocs = await db.allDocs({
      include_docs: true,
      startkey: recordId,
      endkey: `${recordId}\ufff0`
    })
    setVersions(
      allDocs.rows
        .map(row => row.doc)
        .sort((a, b) => moment(b.time).unix() - moment(a.time).unix())
    )
    if (allDocs.total_rows) {
      db.removeCrypto()
    }
    setIsLoading(false)
  } catch (e) {
    throw new Error(e)
  }
}

const subscribeToDBChanges = (dbName, userId, onChange) => {
  const db = new PouchDB(`${userId}_${dbName}`)
  return db
    .changes({
      since: 'now',
      live: true
    })
    .on('change', function (change) {
      onChange(change)
    })
    .on('error', function (err) {
      onError(err)
    })
}

// link/unlink a document from an AL
const linkDocumentToAssetLiability = async (
  userId,
  linkedAssetLiabilityId,
  documentId,
  masterKey,
  isRemoved = false,
  isPending
) => {
  try {
    const alDb = new PouchDB(
      isPending
        ? `${userId}_pendingAssetsLiabilities`
        : `${userId}_assetsLiabilities`
    )
    const docDb = new PouchDB(`${userId}_documents`)

    alDb.crypto(masterKey)
    const al = await alDb.get(linkedAssetLiabilityId)
    const documents = isRemoved
      ? al.documents && al.documents.filter(d => d !== documentId)
      : [...(al.documents || []), documentId]

    await alDb.put({
      ...al,
      documents
    })

    await uploadEncryptedData(
      alDb,
      userId,
      isPending ? 'pendingAssetsLiabilities' : 'assetsLiabilities'
    )

    docDb.crypto(masterKey)
    const doc = await docDb.get(documentId)
    const assetsLiabilities = isRemoved
      ? doc.assetsLiabilities &&
        doc.assetsLiabilities.filter(d => d !== linkedAssetLiabilityId)
      : [...(doc.assetsLiabilities || []), linkedAssetLiabilityId]

    await docDb.put({ ...doc, assetsLiabilities })
    await uploadEncryptedData(docDb, userId, 'documents')

    return documents
  } catch (e) {
    onError(e)
  }
}

// link a list of ALs to an AL
const linkAssetsLiabilities = async (
  userId,
  assetLiabilityId,
  linkIds,
  masterKey
) => {
  try {
    const keys = [assetLiabilityId, ...linkIds]
    const db = new PouchDB(`${userId}_assetsLiabilities`)
    db.crypto(masterKey)
    const docs = await db.allDocs({ keys, include_docs: true })

    const updatedDocs = docs.rows
      .filter(row => row.doc)
      .map(row => {
        const { doc } = row
        return {
          ...doc,
          links: [
            ...(doc.links || []),
            ...(doc._id === assetLiabilityId ? linkIds : [assetLiabilityId])
          ]
        }
      })
    await db.bulkDocs(updatedDocs)

    await uploadEncryptedData(db, userId, 'assetsLiabilities')
  } catch (e) {
    onError(e)
  }
}

const unlinkEventFromAL = async (
  userId,
  recordId,
  event,
  masterKey,
  isPending
) => {
  try {
    const db = isPending
      ? new PouchDB(`${userId}_pendingAssetsLiabilities`)
      : new PouchDB(`${userId}_assetsLiabilities`)

    const eventDB = event.status
      ? new PouchDB(`${userId}_pendingEvents`)
      : new PouchDB(`${userId}_events`)

    db.crypto(masterKey)
    const record = await db.get(recordId)
    const events = record.events
      ? record.events.filter(id => id !== event._id)
      : []
    await db.put({ ...record, events })
    await uploadEncryptedData(
      db,
      userId,
      isPending ? 'pendingAssetsLiabilities' : 'assetsLiabilities'
    )

    eventDB.crypto(masterKey)
    const eventItem = await eventDB.get(event._id)
    const assetsLiabilities = eventItem.assetsLiabilities
      ? eventItem.assetsLiabilities.filter(al => al !== recordId)
      : []

    await eventDB.put({ ...eventItem, assetsLiabilities })
    await uploadEncryptedData(
      eventDB,
      userId,
      event.status ? 'pendingEvents' : 'events'
    )
  } catch (err) {
    throw err
  }
}

const unlinkEventFromContact = async (
  userId,
  recordId,
  event,
  masterKey,
  isPending
) => {
  try {
    const db = isPending
      ? new PouchDB(`${userId}_pendingContacts`)
      : new PouchDB(`${userId}_contacts`)

    const eventDB = event.status
      ? new PouchDB(`${userId}_pendingEvents`)
      : new PouchDB(`${userId}_events`)

    db.crypto(masterKey)
    const record = await db.get(recordId)
    const events = record.events
      ? record.events.filter(id => id !== event._id)
      : []
    await db.put({ ...record, events })
    await uploadEncryptedData(
      db,
      userId,
      isPending ? 'pendingContacts' : 'contacts'
    )

    eventDB.crypto(masterKey)
    const eventItem = await eventDB.get(event._id)
    const contacts = eventItem.contacts
      ? eventItem.contacts.filter(al => al !== recordId)
      : []

    await eventDB.put({ ...eventItem, contacts })
    await uploadEncryptedData(
      eventDB,
      userId,
      event.status ? 'pendingEvents' : 'events'
    )
  } catch (err) {
    throw err
  }
}

const unlinkPassword = async (
  userId,
  recordId,
  password,
  masterKey,
  isPending
) => {
  try {
    const db = isPending
      ? new PouchDB(`${userId}_pendingAssetsLiabilities`)
      : new PouchDB(`${userId}_assetsLiabilities`)

    const passwordsDB = password.status
      ? new PouchDB(`${userId}_pendingPasswords`)
      : new PouchDB(`${userId}_passwords`)
    db.crypto(masterKey)

    const record = await db.get(recordId)
    const passwords = record.passwords
      ? record.passwords.filter(id => id !== password._id)
      : []
    await db.put({ ...record, passwords })
    await uploadEncryptedData(
      db,
      userId,
      isPending ? 'pendingAssetsLiabilities' : 'assetsLiabilities'
    )

    passwordsDB.crypto(masterKey)
    const passwordItem = await passwordsDB.get(password._id)
    const assetsLiabilities = passwordItem.assetsLiabilities
      ? passwordItem.assetsLiabilities.filter(al => al !== recordId)
      : []

    await passwordsDB.put({ ...passwordItem, assetsLiabilities })
    await uploadEncryptedData(
      passwordsDB,
      userId,
      password.status ? 'pendingPasswords' : 'passwords'
    )
  } catch (err) {
    throw err
  }
}

// unlink an AL from another AL
const unlinkAssetLiability = async (
  userId,
  assetLiabilityId,
  linkedAssetLiabilityId,
  masterKey
) => {
  try {
    const db = new PouchDB(`${userId}_assetsLiabilities`)
    db.crypto(masterKey)
    const docs = await db.allDocs({
      keys: [assetLiabilityId, linkedAssetLiabilityId],
      include_docs: true
    })

    const updatedDocs = docs.rows
      .filter(row => row.doc)
      .map(row => {
        const { doc } = row
        const removedLink =
          doc._id === assetLiabilityId
            ? linkedAssetLiabilityId
            : assetLiabilityId
        return {
          ...doc,
          links: doc.links
            ? doc.links.filter(linkId => linkId !== removedLink)
            : []
        }
      })
    await db.bulkDocs(updatedDocs)

    await uploadEncryptedData(db, userId, 'assetsLiabilities')
  } catch (e) {
    onError(e)
  }
}

// link a list of contacts to an AL
const linkContacts = async (
  userId,
  assetLiabilityId,
  contactIds,
  masterKey
) => {
  try {
    const alDb = new PouchDB(`${userId}_assetsLiabilities`)
    const contactsDb = new PouchDB(`${userId}_contacts`)

    alDb.crypto(masterKey)
    const assetLiability = await alDb.get(assetLiabilityId)
    const contacts = [...(assetLiability.contacts || []), ...contactIds]
    await alDb.put({ ...assetLiability, contacts })
    await uploadEncryptedData(alDb, userId, 'assetsLiabilities')

    contactsDb.crypto(masterKey)
    const docs = await contactsDb.allDocs({
      keys: contactIds,
      include_docs: true
    })
    const updatedDocs = docs.rows
      .filter(row => row.doc)
      .map(row => {
        const { doc } = row
        const assetsLiabilities = [
          ...(doc.assetsLiabilities || []),
          assetLiabilityId
        ]
        return { ...doc, assetsLiabilities }
      })
    await contactsDb.bulkDocs(updatedDocs)
    await uploadEncryptedData(contactsDb, userId, 'contacts')
  } catch (err) {
    throw err
  }
}

// unlink a contact from an AL
const unlinkContact = async (
  userId,
  assetLiabilityId,
  contactId,
  masterKey,
  isPending
) => {
  try {
    const db = isPending
      ? new PouchDB(`${userId}_pendingAssetsLiabilities`)
      : new PouchDB(`${userId}_assetsLiabilities`)
    const contactsDb = new PouchDB(`${userId}_contacts`)

    db.crypto(masterKey)
    const assetLiability = await db.get(assetLiabilityId)
    const contacts = assetLiability.contacts
      ? assetLiability.contacts.filter(id => id !== contactId)
      : []
    await db.put({ ...assetLiability, contacts })
    await uploadEncryptedData(
      db,
      userId,
      isPending ? 'pendingAssetsLiabilities' : 'assetsLiabilities'
    )

    contactsDb.crypto(masterKey)
    const contact = await contactsDb.get(contactId)
    const assetsLiabilities = contact.assetsLiabilities
      ? contact.assetsLiabilities.filter(al => al !== assetLiabilityId)
      : []
    await contactsDb.put({ ...contact, assetsLiabilities })
    await uploadEncryptedData(contactsDb, userId, 'contacts')
  } catch (err) {
    throw err
  }
}

const updateLinkItemsForFile = async (
  newLinks,
  currentLinks,
  dbName,
  userId,
  masterKey,
  item
) => {
  const addedLinks = newLinks.filter(
    itemId => !currentLinks || !currentLinks.includes(itemId)
  )
  const removedLinks = currentLinks.filter(itemId => !newLinks.includes(itemId))
  const updatedLinkItems = [...addedLinks, ...removedLinks]

  if (updatedLinkItems.length) {
    const db = new PouchDB(`${userId}_${dbName}`)
    db.crypto(masterKey)
    const docs = await db.allDocs({
      keys: updatedLinkItems,
      include_docs: true
    })

    const updatedDocs = docs.rows
      .filter(row => row.doc)
      .map(row => {
        const { doc } = row
        if (addedLinks.includes(doc._id)) {
          const newDocuments = doc.documents
            ? [...doc.documents, item.id]
            : [item.id]
          return { ...doc, documents: newDocuments }
        } else if (removedLinks.includes(doc._id)) {
          const newDocuments = doc.documents
            ? doc.documents.filter(docId => docId !== item.id)
            : []
          return { ...doc, documents: newDocuments }
        } else {
          return { ...doc }
        }
      })

    await db.bulkDocs(updatedDocs)
    await uploadEncryptedData(db, userId, dbName)
  }
}

const createFolder = async (userId, path, masterKey, isPending = false) =>
  wrapPouchDbAction(
    isPending ? 'pendingDocuments' : 'documents',
    userId,
    masterKey,
    async db => {
      await db.put({
        path,
        _id: `folder_${uuidv4()}`,
        status: isPending ? 'Draft' : undefined
      })
    }
  )

const renameFolder = async (
  documents,
  item,
  userId,
  folderName,
  masterKey,
  dbName = 'documents',
  isPending = false
) =>
  wrapPouchDbAction(dbName, userId, masterKey, async db => {
    const subItems = documents.filter(d => d.path.indexOf(item.path) === 0)

    // Update all child items' path
    const newDocs = subItems.map(doc => {
      const parentPath = item.path.slice(0, -(item.name.length + 1))

      return {
        ...doc,
        path: doc.path.replace(
          `${parentPath}${item.name}`,
          `${parentPath}${folderName}`
        ),
        status: isPending ? 'Draft' : undefined,
        reasonReject: undefined
      }
    })
    await db.bulkDocs(newDocs)
  })

const rejectDocument = async (documents, item, userId, masterKey, reason) =>
  wrapPouchDbAction('pendingDocuments', userId, masterKey, async db => {
    const subItems = documents.filter(d =>
      item.fileId
        ? item.id === d._id
        : d.path.indexOf(item.path) === 0 &&
          (item.fileId ? d.fileName === item.name : true)
    )

    const newDocs = subItems.map(doc => {
      return {
        ...doc,
        tags: [],
        description: '',
        contacts: [],
        assetsLiabilities: [],
        events: [],
        status: 'Rejected',
        reasonReject: reason
      }
    })

    await db.bulkDocs(newDocs)
  })

const approveDocument = async (documents, item, userId, masterKey) =>
  wrapPouchDbAction('documents', userId, masterKey, async db => {
    const subItems = documents.filter(
      d =>
        d.path.indexOf(item.path) === 0 &&
        (item.fileId ? item.name === d.fileName : true)
    )
    const parentItems = documents.filter(
      d => !d.fileName && item.path.indexOf(d.path) === 0
    )

    const updatedItems = uniq([...subItems, ...parentItems])
    const newDocs = updatedItems.map(doc => {
      return {
        ...doc,
        status: undefined,
        _rev: undefined
      }
    })

    await db.bulkDocs(newDocs)
  })

const handleDocumentsRequests = async (
  documents,
  dbName,
  userId,
  masterKey,
  isApproved = false
) =>
  wrapPouchDbAction(dbName, userId, masterKey, async db => {
    const newDocs = documents.map(doc => {
      return {
        ...doc,
        status: !isApproved ? 'Rejected' : undefined,
        _rev: !isApproved ? doc._rev : undefined,
        contacts: !isApproved ? [] : doc.contacts,
        assetsLiabilities: !isApproved ? [] : doc.assetsLiabilities,
        events: !isApproved ? [] : doc.events,
        passwords: !isApproved ? [] : doc.passwords
      }
    })

    await db.bulkDocs(newDocs)
  })

const deleteFolder = async (documents, item, userId, masterKey) =>
  wrapPouchDbAction('documents', userId, masterKey, async db => {
    const subItems = documents.filter(d => d.path.indexOf(item.path) === 0)
    await db.bulkDocs(
      subItems.map(doc => ({
        ...doc,
        deleted: true,
        ...(doc._id !== item.id ? { deletedFolderId: item.id } : {})
      }))
    )
  })

// Delete a list of documents, including files and folders
const bulkDeleteDocuments = async (
  activeFolders,
  activeFiles,
  items,
  userId,
  masterKey
) => {
  wrapPouchDbAction('documents', userId, masterKey, async db => {
    let updatedData = []

    const fileItemIds = items
      .filter(i => i.type === 'file-text')
      ?.map(i => i.id)
    const folderItems = items.filter(i => i.type === 'folder')

    if (folderItems.length) {
      folderItems.forEach(folder => {
        const subItems = activeFolders.filter(
          d => d.path.indexOf(folder.path) === 0
        )
        updatedData.push(
          subItems.map(doc => ({
            ...doc,
            deleted: true,
            ...(doc._id !== folder.id ? { deletedFolderId: folder.id } : {})
          }))
        )
      })
    }

    if (fileItemIds.length) {
      const files = activeFiles.filter(f => fileItemIds.includes(f._id))
      updatedData.push(
        files.map(doc => ({
          ...doc,
          contacts: [],
          assetsLiabilities: [],
          events: [],
          passwords: [],
          deleted: true
        }))
      )
    }

    const updateDataArr = updatedData.flat()
    await db.bulkDocs(updateDataArr)
  })
}

const deleteDocument = async (userId, documentId, masterKey) =>
  wrapPouchDbAction('documents', userId, masterKey, async db => {
    const deletedDocument = await db.get(documentId)
    await db.put({
      ...deletedDocument,
      contacts: [],
      assetsLiabilities: [],
      events: [],
      passwords: [],
      deleted: true
    })
  })

// unlink a document from a list of other items
const unlinkDocument = async (
  userId,
  documentId,
  dbName,
  linkedItems,
  masterKey
) =>
  wrapPouchDbAction(dbName, userId, masterKey, async db => {
    const updatedItems = linkedItems.map(li => ({
      ...li,
      documents: li.documents?.filter(d => d !== documentId)
    }))

    await db.bulkDocs(updatedItems)
  })

// unlink a list of other items from a list of documents
const unlinkDocuments = async (
  userId,
  documentIds,
  dbName,
  linkedItems,
  masterKey
) => {
  wrapPouchDbAction(dbName, userId, masterKey, async db => {
    let allUpdatedItems = []

    linkedItems.forEach(li => {
      const updatedItems = {
        ...li,
        documents: li.documents?.filter(d => !documentIds.includes(d))
      }

      allUpdatedItems.push(updatedItems)
    })

    await db.bulkDocs(allUpdatedItems)
  })
}

const permanentlyDeleteItems = async (dbName, userId, records, masterKey) => {
  const db = new PouchDB(`${userId}_${dbName}`)
  try {
    db.crypto(masterKey)

    const deletedRecords = records.map(record => {
      return {
        ...record,
        _deleted: true
      }
    })
    await db.bulkDocs(deletedRecords)

    await uploadEncryptedData(db, userId, dbName)
  } catch (err) {
    throw err
  }
}

const permanentlyDeleteDocument = async (
  userId,
  record,
  deletedDocuments,
  masterKey
) => {
  const db = new PouchDB(`${userId}_documents`)
  try {
    db.crypto(masterKey)
    const deletedItems = deletedDocuments.filter(
      dd => dd.deletedFolderId === record._id || dd._id === record._id
    )
    if (!deletedItems.length) return

    await db.bulkDocs(deletedItems.map(item => ({ ...item, _deleted: true })))
    await uploadEncryptedData(db, userId, 'documents')

    if (record.type !== 'folder') {
      const res = await api.getFileStatus(userId, record.fileId)
      if (res.data.message) throw Error(res.data.message)

      res.data.isLocked
        ? await api.deleteLockedFile(userId, record.fileId)
        : await api.deleteFile(userId, record.fileId)
    }
  } catch (err) {
    throw err
  }
}

const permanentlyDeleteDocuments = async (
  userId,
  records,
  deletedDocuments,
  masterKey,
  dbName = 'documents'
) => {
  const db = new PouchDB(`${userId}_${dbName}`)

  try {
    db.crypto(masterKey)
    const recordIds = records.map(r => r._id)
    const dataToDelete = deletedDocuments.filter(
      dd => recordIds.includes(dd.deletedFolderId) || recordIds.includes(dd._id)
    )
    if (!dataToDelete.length) return
    await db.bulkDocs(dataToDelete.map(item => ({ ...item, _deleted: true })))
    await uploadEncryptedData(db, userId, dbName)

    const files = records.filter(r => r.type === 'file-text')

    if (files.length) {
      const lockedFileRes = await api.getLockedFiles(userId)
      let lockedFileIdsToDelete = []

      const selectedFileIds = files.map(f => f.fileId) || []

      if (lockedFileRes.data?.fileKeys?.length) {
        lockedFileIdsToDelete = lockedFileRes.data.fileKeys
          .filter(fk => fk.isSecretFile && selectedFileIds.includes(fk.fileId))
          .map(puf => puf.fileId)

        if (lockedFileIdsToDelete.length) {
          await api.bulkDeleteLockedFiles(
            userId,
            JSON.stringify({ keys: lockedFileIdsToDelete })
          )
        }
      }

      const fileKeys = selectedFileIds.filter(
        fileId => !lockedFileIdsToDelete.includes(fileId)
      )
      if (fileKeys?.length) {
        await api.bulkDeleteFiles(
          userId,
          JSON.stringify({
            keys: fileKeys
          })
        )
      }
    }
  } catch (err) {
    throw err
  }
}

const restoreItems = async (dbName, userId, records, masterKey) =>
  wrapPouchDbAction(dbName, userId, masterKey, async db => {
    // const restoredRecord = {
    //   ...record,
    //   deleted: false
    // }
    // await db.put(restoredRecord)
    const restoredRecords = records.map(record => {
      return {
        ...record,
        deleted: false
      }
    })
    await db.bulkDocs(restoredRecords)
  })

// const restoreDocument = async (
//   userId,
//   record,
//   deletedDocuments,
//   activeFolders
// ) => {
//   const db = new PouchDB(`${userId}_documents`)
//   const masterKey = localStorage.getItem(userId)

//   try {
//     db.crypto(masterKey)
//     let subItems = []
//     if (!record.length) {
//       subItems = deletedDocuments.filter(
//         dd => dd.deletedFolderId === record._id || dd._id === record._id
//       )
//     } else {
//       const recordIds = record.map(r => r._id)
//       subItems = deletedDocuments.filter(
//         dd =>
//           recordIds.includes(dd.deletedFolderId) || recordIds.includes(dd._id)
//       )
//     }
//     if (!subItems.length) return

//     // Find existing folder with the same path as item
//     const existingFolder = path => activeFolders.find(s => s.path === path)

//     // Auto-create parent folders of this item if they don't exist
//     let parentFolders = []
//     if (record.length) {
//       parentFolders = record.map(item => {
//         return item.path.split('/').slice(0, record.type === 'folder' ? -2 : -1)
//       })
//     } else {
//       parentFolders = record.path
//         .split('/')
//         .slice(0, record.type === 'folder' ? -2 : -1)
//     }
//     let createdParentFolders = []
//     parentFolders = [...new Set(parentFolders)]
//     while (parentFolders.length > 0) {
//       const parentPath = record.length
//         ? `${parentFolders[parentFolders.length - 1].join('/')}/`
//         : `${parentFolders.join('/')}/`
//       if (
//         !existingFolder(parentPath) &&
//         !createdParentFolders.some(item => item.path === parentPath) &&
//         !subItems.some(doc => !doc.fileId && doc.path === parentPath)
//       ) {
//         createdParentFolders.push({
//           path: parentPath,
//           _id: `folder_${uuidv4()}`,
//           deleted: undefined,
//           _deleted: undefined,
//           deletedFolderId: undefined
//         })
//       }
//       parentFolders = parentFolders.slice(0, -1)
//     }
//     await db.bulkDocs(createdParentFolders)

//     // if existingFolder found (i.e. after deleting a folder, re-create another with the same path),
//     // then delete the duplicate
//     let restoredItems = []
//     subItems.map(doc => {
//       restoredItems.push({
//         ...doc,
//         deleted: undefined,
//         deletedFolderId: undefined,
//         _deleted: (!doc.fileName && existingFolder(doc.path)) || undefined
//       })
//     })
//     await db.bulkDocs(restoredItems)
//     await uploadEncryptedData(db, userId, 'documents')
//   } catch (err) {
//     throw err
//   }
// }

// Restore all documents (might be used for restoring single/multiple documents)
const restoreDocuments = async (
  userId,
  deletedDocuments,
  activeFolders,
  folders, // means all folders in vaultbox, instead of active ones
  records = [],
  masterKey
) => {
  const db = new PouchDB(`${userId}_documents`)
  const privateFolder = activeFolders.find(folder => folder.isPrivate)
  try {
    db.crypto(masterKey)
    const selectedRecordIds = records.map(rc => rc._id) || []
    const subItems = selectedRecordIds.length
      ? deletedDocuments.filter(
          dd =>
            selectedRecordIds.includes(dd.deletedFolderId) ||
            selectedRecordIds.includes(dd._id)
        )
      : deletedDocuments

    if (!subItems.length) return

    const folderPathKeys = uniq(subItems.map(si => si.path))
    // Find existing folder with the same path as item
    const existingFolders = (path, source) =>
      !!source?.find(s => s?.path === path)

    let parentFoldersToCreate = []

    for (const path of folderPathKeys) {
      let parentFolders = path.split('/').filter(i => i !== '')

      while (parentFolders.length > 0) {
        const parentPath = `${parentFolders.join('/')}/`
        const document = subItems.find(s => s.path === parentPath)
        const deletedPrivateFolder = deletedDocuments.find(d => d.isPrivate)
        if (
          !existingFolders(
            parentPath,
            folders.filter(f => !f.deleted)
          ) &&
          !existingFolders(parentPath, parentFoldersToCreate)
        ) {
          if (!!privateFolder) {
            if (document?.isPrivate) {
              parentFoldersToCreate.push({
                path: privateFolder.path,
                isPrivate: true,
                password: privateFolder.password,
                _id: privateFolder._id
              })
            } else {
              parentFoldersToCreate.push({
                path: parentPath,
                _id: `folder_${uuidv4()}`
              })
            }
          } else {
            if (document?.isPrivate) {
              parentFoldersToCreate.push({
                path: parentPath,
                isPrivate: true,
                password: document.password,
                _id: `folder_${uuidv4()}`
              })
            } else if (parentPath === deletedPrivateFolder?.path) {
              parentFoldersToCreate.push({
                path: parentPath,
                isPrivate: true,
                password: deletedPrivateFolder.password,
                _id: `folder_${uuidv4()}`
              })
            } else {
              parentFoldersToCreate.push({
                path: parentPath,
                _id: `folder_${uuidv4()}`
              })
            }
          }
        }
        parentFolders = parentFolders.slice(0, -1)
      }
    }

    parentFoldersToCreate.length && (await db.bulkDocs(parentFoldersToCreate))

    // if existingFolder found (i.e. after deleting a folder, re-create another with the same path),
    // then delete the duplicate
    const restoredItems = subItems.map(doc => ({
      ...doc,
      deleted: undefined,
      deletedFolderId: undefined,
      _deleted:
        (!doc.fileName && existingFolders(doc.path, folders)) || undefined
    }))

    await db.bulkDocs(restoredItems)
    await uploadEncryptedData(db, userId, 'documents')
  } catch (err) {
    throw err
  }
}

const restoreValuation = async (userId, assetLiabilities = [], masterKey) =>
  wrapPouchDbAction(
    'assetsLiabilitiesValuations',
    userId,
    masterKey,
    async db => {
      const updatedValuationRecords = await Promise.all(
        assetLiabilities.map(async assetLiability => {
          const assetLiabilityId = assetLiability._id
          const allDocs = await db.allDocs({
            include_docs: true,
            startkey: assetLiabilityId,
            endkey: `${assetLiabilityId}\ufff0`
          })
          let valuationRecord
          if (allDocs.rows.length) {
            const latestValuation = allDocs.rows
              .map(row => row.doc)
              .sort(
                (a, b) => moment(b.validTo).unix() - moment(a.validTo).unix()
              )[0]

            valuationRecord = new AssetLiabilityValuation({
              ...latestValuation,
              validTo: END_OF_TIME
            })
          } else {
            valuationRecord = new AssetLiabilityValuation({
              ...assetLiability,
              _id: `${assetLiability._id}_${uuidv4()}`,
              _rev: undefined,
              validFrom: assetLiability.valuationDate,
              validTo: END_OF_TIME
            })
          }

          return valuationRecord
        })
      )

      await db.bulkDocs([...updatedValuationRecords])
    }
  )

const getLatestVersion = async (dbName, userId, recordId, masterKey) => {
  try {
    const db = new PouchDB(`${userId}_${dbName}`)
    db.crypto(masterKey)
    const allDocs = await db.allDocs({
      include_docs: true,
      startkey: recordId,
      endkey: `${recordId}\ufff0`
    })
    if (allDocs.total_rows) {
      db.removeCrypto()
    }
    return allDocs.rows
      .map(row => row.doc)
      .sort((a, b) => moment(b.time).unix() - moment(a.time).unix())[0]
  } catch (err) {
    throw err
  }
}

const getLatestValuation = async (
  userId,
  assetLiabilityId,
  masterKey,
  comparedDate = null,
  encryptedDb = null
) => {
  try {
    const db =
      encryptedDb || new PouchDB(`${userId}_assetsLiabilitiesValuations`)

    if (!encryptedDb) db.crypto(masterKey)

    const allDocs = await db.allDocs({
      include_docs: true,
      startkey: assetLiabilityId,
      endkey: `${assetLiabilityId}\ufff0`
    })

    if (!encryptedDb && allDocs.total_rows) {
      await db.removeCrypto()
    }

    return allDocs.rows
      .map(row => row.doc)
      .find(doc =>
        comparedDate
          ? moment(doc.validFrom).isSameOrBefore(comparedDate) &&
            moment(doc.validTo).isAfter(comparedDate)
          : doc.validTo === END_OF_TIME
      )
  } catch (err) {
    throw err
  }
}

const getOldestValuation = async (
  userId,
  assetLiabilityId,
  masterKey,
  encryptedDb = null
) => {
  try {
    const db =
      encryptedDb || new PouchDB(`${userId}_assetsLiabilitiesValuations`)

    if (!encryptedDb) db.crypto(masterKey)

    const allDocs = await db.allDocs({
      include_docs: true,
      startkey: assetLiabilityId,
      endkey: `${assetLiabilityId}\ufff0`
    })

    if (!encryptedDb && allDocs.total_rows) {
      await db.removeCrypto()
    }
    return allDocs.rows
      .map(row => row.doc)
      .sort(
        (a, b) => moment(a.validFrom).unix() - moment(b.validFrom).unix()
      )[0]
  } catch (err) {
    throw err
  }
}

const deleteContact = async (
  userId,
  contact,
  masterKey,
  isProfessionalDeputy?,
  activeAssetsLiabilities,
  pendingAssetsLiabilities
) => {
  const db = new PouchDB(
    isProfessionalDeputy ? `${userId}_pendingContacts` : `${userId}_contacts`
  )

  const documentsDb = new PouchDB(`${userId}_documents`)
  const deletedContact = {
    ...contact,
    documents: [],
    assetsLiabilities: [],
    events: [],
    links: [],
    deleted: true
  }
  const { assetsLiabilities, documents } = contact
  const updatedActivedAssetsLiabilities = assetsLiabilities?.filter(al =>
    activeAssetsLiabilities.map(aa => aa._id).includes(al)
  )

  const updatedPendingAssetsLiabilities = assetsLiabilities?.filter(al =>
    pendingAssetsLiabilities.map(pa => pa._id).includes(al)
  )
  try {
    db.crypto(masterKey)
    await db.put(deletedContact)

    if (isProfessionalDeputy) {
      const deletedRecords = await getRecords(
        userId,
        'pendingContacts',
        masterKey,
        { startkey: deletedContact._id, endkey: `${deletedContact._id}\ufff0` }
      )
      await permanentlyDeleteItems(
        'pendingContacts',
        userId,
        deletedRecords,
        masterKey
      )
    } else {
      await uploadEncryptedData(db, userId, 'contacts')
    }
    // update assetLiabilities
    if (updatedActivedAssetsLiabilities?.length) {
      const assetsLiabilitiesDb = new PouchDB(`${userId}_assetsLiabilities`)

      await unlinkContatsFromAssetsLiabilities(
        updatedActivedAssetsLiabilities,
        contact._id,
        assetsLiabilitiesDb,
        'assetsLiabilities',
        userId,
        masterKey
      )
    }

    if (updatedPendingAssetsLiabilities?.length) {
      const pendingAssetsLiabilitiesDb = new PouchDB(
        `${userId}_pendingAssetsLiabilities`
      )

      await unlinkContatsFromAssetsLiabilities(
        updatedPendingAssetsLiabilities,
        contact._id,
        pendingAssetsLiabilitiesDb,
        'pendingAssetsLiabilities',
        userId,
        masterKey
      )
    }

    //update documents
    if (documents && documents.length) {
      documentsDb.crypto(masterKey)
      const docs = await documentsDb.allDocs({
        keys: documents,
        include_docs: true
      })
      const updatedDocs = docs.rows
        .filter(row => row.doc)
        .map(row => {
          const { doc } = row
          const contacts = doc.contacts
            ? doc.contacts.filter(cId => cId !== contact._id)
            : []

          const markUp = new RegExp(
            `@\\[(((?!@\\[).)*)\\]\\(${contact._id}\\)`,
            'g'
          )
          const descriptionWithMarkup = doc.descriptionWithMarkup.replace(
            markUp,
            '$1'
          )

          return { ...doc, contacts, descriptionWithMarkup }
        })
      await documentsDb.bulkDocs(updatedDocs)
      await uploadEncryptedData(documentsDb, userId, 'documents')
    }

    //update linkedContacts
    if (contact.links?.length) {
      const contactArr = (await getRecords(userId, 'contacts', masterKey))
        .filter(c => contact.links.includes(c._id))
        .map(doc => doc._id)
      const pendingContactArr = (
        await getRecords(userId, 'pendingContacts', masterKey)
      )
        .filter(c => contact.links.includes(c._id))
        .map(doc => doc._id)

      if (contactArr?.length) {
        await unlinkContactsFromAnother(
          userId,
          contact._id,
          contactArr,
          masterKey,
          'contacts'
        )
      }

      if (pendingContactArr?.length) {
        await unlinkContactsFromAnother(
          userId,
          contact._id,
          pendingContactArr,
          masterKey,
          'pendingContacts'
        )
      }
    }
  } catch (err) {
    throw err
  }
}

const toggleStar = async (userId, documentId, masterKey) => {
  try {
    const db = new PouchDB(`${userId}_documents`)
    db.crypto(masterKey)

    const doc = await db.get(documentId)
    await db.put({
      ...doc,
      starred: !doc.starred
    })

    await uploadEncryptedData(db, userId, 'documents')
  } catch (err) {
    throw err
  }
}

const newFile = (sourceFile, newPath, newFileName?) => ({
  _id: uuidv4(),
  path: newPath,
  fileName: newFileName || sourceFile.fileName,
  uploadTime: new Date(),
  tags: sourceFile.tags || [],
  description: sourceFile.description || '',
  contacts: [],
  assetsLiabilities: [],
  file: sourceFile.file,
  sourceId: sourceFile.fileId,
  fileId: uuidv4(),
  sub: sourceFile.sub
})

const getNewPath = (activeFolders, destinationFolder, folderName) =>
  activeFolders.find(
    folder => folder.path === `${destinationFolder}${folderName}/`
  )
    ? `${destinationFolder}${folderName}-copy/`
    : `${destinationFolder}${folderName}/`

const getNewFileName = (activeFiles, destinationFolder, sourceFileName) => {
  if (
    activeFiles.find(
      af => af.fileName === sourceFileName && af.path === destinationFolder
    )
  ) {
    const fileParts = getFileParts(sourceFileName)
    return getFullFilename(fileParts.name, fileParts.extension, 'copy')
  }
  return sourceFileName
}

const newDocumentsToCopy = (
  items,
  activeFolders,
  activeFiles,
  destinationFolder
) => {
  let newFolders = [],
    newFiles = []

  const fileItems = items.filter(i => i.type === 'file-text')
  const folderItems = items.filter(i => i.type === 'folder')

  folderItems.length &&
    folderItems.forEach(item => {
      const newPath = getNewPath(activeFolders, destinationFolder, item.name)

      newFolders.push(
        activeFolders
          .filter(folder => folder.path.indexOf(item.path) === 0)
          .map(folder => ({
            _id: `folder_${uuidv4()}`,
            path: folder.path.replace(item.path, newPath)
          }))
      )

      newFiles.push(
        activeFiles
          .filter(file => file.path.indexOf(item.path) === 0)
          .map(file => newFile(file, file.path.replace(item.path, newPath)))
      )
    })

  fileItems.length &&
    fileItems.forEach(item => {
      const sourceFile = activeFiles.find(af => af._id === item.id)
      const newFileName = getNewFileName(
        activeFiles,
        destinationFolder,
        sourceFile.fileName
      )

      newFiles.push([newFile(sourceFile, destinationFolder, newFileName)])
    })

  return { newFolders: newFolders.flat(), newFiles: newFiles.flat() }
}

const copyItems = async (userId, newFiles, newFolders, masterKey) => {
  await wrapPouchDbAction('documents', userId, masterKey, async db => {
    //update new fileKeys to dynamoDB
    const lockedFilesRes = await api.getLockedFiles(userId)
    let lockedFiles = [],
      lockedFileIdsFromNewList

    if (lockedFilesRes.data?.fileKeys?.length) {
      lockedFiles = lockedFilesRes.data.fileKeys

      lockedFileIdsFromNewList =
        newFiles
          .filter(nf =>
            lockedFiles?.map(lf => lf.fileId)?.includes(nf.sourceId)
          )
          ?.map(nf => ({
            sourceFileId: nf.sourceId,
            newFileId: nf.fileId
          })) || []

      if (lockedFileIdsFromNewList.length) {
        let newLockedFiles = lockedFiles

        lockedFileIdsFromNewList.forEach(lf => {
          const sourceFileKey = lockedFiles.find(
            k => k.fileId === lf.sourceFileId
          )
          sourceFileKey &&
            newLockedFiles.push({ ...sourceFileKey, fileId: lf.newFileId })
        })

        await api.saveFileKeys(
          userId,
          JSON.stringify({ fileKeys: newLockedFiles })
        )
      }
    }

    await Promise.all(
      newFiles.map(async file => {
        await api.copyFile(
          userId,
          JSON.stringify({
            sourceFileId: file.sourceId,
            newFileId: file.fileId
          })
        )
      })
    )

    const newDocuments = newFolders.concat(
      newFiles.map(n => {
        delete n.sourceId
        return n
      })
    )

    await db.bulkDocs(newDocuments)
  })
}

const moveItem = async (
  userId,
  item,
  activeDocuments,
  activeFolders,
  activeFiles,
  destinationFolder,
  masterKey
) => {
  await wrapPouchDbAction('documents', userId, masterKey, async db => {
    if (item.type === 'folder') {
      const subItems = activeDocuments.filter(
        ad => ad.path.indexOf(item.path) === 0
      )

      const newPath = getNewPath(activeFolders, destinationFolder, item.name)

      const updatedSubItems = subItems.map(si => ({
        ...si,
        path: si.path.replace(item.path, newPath)
      }))

      await db.bulkDocs(updatedSubItems)
    } else {
      const sourceFile = await db.get(item.id)
      const newFileName = getNewFileName(
        activeFiles,
        destinationFolder,
        sourceFile.fileName
      )

      await db.put({
        ...sourceFile,
        fileName: newFileName,
        path: destinationFolder
      })
    }
  })
}

// move multiple documents
const moveItems = async (
  userId,
  items,
  activeDocuments,
  activeFolders,
  activeFiles,
  destinationFolder,
  masterKey
) => {
  await wrapPouchDbAction('documents', userId, masterKey, async db => {
    const fileItems = items.filter(i => i.type === 'file-text')
    const folderItems = items.filter(i => i.type === 'folder')
    let updatedSubItems = []

    folderItems.length &&
      folderItems.forEach(item => {
        const subItems = activeDocuments.filter(
          ad => ad.path.indexOf(item.path) === 0
        )

        const newPath = getNewPath(activeFolders, destinationFolder, item.name)

        updatedSubItems.push(
          subItems.map(si => ({
            ...si,
            path: si.path.replace(item.path, newPath)
          }))
        )
      })

    fileItems.length &&
      fileItems.forEach(item => {
        const sourceFile = activeFiles.find(af => af._id === item.id)
        const newFileName = getNewFileName(
          activeFiles,
          destinationFolder,
          sourceFile.fileName
        )

        updatedSubItems.push({
          ...sourceFile,
          fileName: newFileName,
          path: destinationFolder
        })
      })

    updatedSubItems.length && (await db.bulkDocs(updatedSubItems.flat()))
  })
}

const updateAssetsLiabilitiesValuation = async (
  userId,
  updatedAssetsLiabilities,
  updatedPendingAssetsLiabilities,
  allRates,
  masterKey,
  isChangeBaseCurrency
) => {
  try {
    const db = new PouchDB(`${userId}_assetsLiabilities`)
    const pendingDb = new PouchDB(`${userId}_pendingAssetsLiabilities`)
    db.crypto(masterKey)
    pendingDb.crypto(masterKey)

    const updatedDocs = updatedAssetsLiabilities
      .filter(record => record.currency && allRates[record.currency])
      .map(record => {
        const mapRate = allRates[record.currency]
        return {
          ...record,
          valuationInBaseCurrency:
            record.valuationInAssetCurrency &&
            record.valuationInAssetCurrency / mapRate,
          outstandingValueInBaseCurrency:
            record.outstandingValueInLiabilityCurrency &&
            record.outstandingValueInLiabilityCurrency / mapRate,
          sumAssuredInBaseCurrency:
            record.sumAssuredInAssetCurrency &&
            record.sumAssuredInAssetCurrency / mapRate,
          valuationDateInBaseCurrency: isChangeBaseCurrency
            ? record.valuationDateInBaseCurrency
            : moment().startOf('day'),
          valuationDate: isChangeBaseCurrency
            ? moment().startOf('day')
            : record.valuationDate
        }
      })

    const updatedPendingDocs = updatedPendingAssetsLiabilities
      .filter(record => record.currency && allRates[record.currency])
      .map(record => {
        const mapRate = allRates[record.currency]
        return {
          ...record,
          valuationInBaseCurrency:
            record.valuationInAssetCurrency &&
            record.valuationInAssetCurrency / mapRate,
          outstandingValueInBaseCurrency:
            record.outstandingValueInLiabilityCurrency &&
            record.outstandingValueInLiabilityCurrency / mapRate,
          sumAssuredInBaseCurrency:
            record.sumAssuredInAssetCurrency &&
            record.sumAssuredInAssetCurrency / mapRate,
          valuationDateInBaseCurrency: isChangeBaseCurrency
            ? record.valuationDateInBaseCurrency
            : moment().startOf('day'),
          valuationDate: isChangeBaseCurrency
            ? moment().startOf('day')
            : record.valuationDate
        }
      })

    const updatedResults = await db.bulkDocs([...updatedDocs])
    const updatedPendingResults = await pendingDb.bulkDocs([
      ...updatedPendingDocs
    ])

    const results = [...updatedResults, ...updatedPendingResults]

    const historyDb = new PouchDB(`${userId}_assetsLiabilitiesHistory`)
    historyDb.crypto(masterKey)

    const valuationDb = new PouchDB(`${userId}_assetsLiabilitiesValuations`)
    valuationDb.crypto(masterKey)

    await Promise.all(
      results.map(async result => {
        const doc = [...updatedDocs, ...updatedPendingDocs].find(
          d => d?._id === result.id
        )

        const latestVersion = await getLatestVersion(
          'assetsLiabilitiesHistory',
          userId,
          result.id,
          masterKey
        )

        if (latestVersion) {
          // update history version
          await historyDb.put({
            ...latestVersion,
            ...doc,
            _id: latestVersion._id,
            _rev: latestVersion._rev,
            time: new Date()
          })
        }

        // update valuation records
        const latestValuation = await getLatestValuation(
          userId,
          result.id,
          masterKey
        )
        if (!latestValuation) return

        const today = moment().startOf('day')

        if (moment(latestValuation.validFrom).isSame(today)) {
          const valuationRecord = new AssetLiabilityValuation({
            ...latestValuation,
            ...doc,
            _id: latestValuation._id,
            _rev: latestValuation._rev,
            updatedByRate: isChangeBaseCurrency ? undefined : true
          })
          await valuationDb.put(valuationRecord)
        } else {
          const newValuationRecord = new AssetLiabilityValuation({
            ...latestValuation,
            ...doc,
            _id: `${result.id}_${uuidv4()}`,
            _rev: undefined,
            validFrom: today.toJSON(),
            updatedByRate: isChangeBaseCurrency ? undefined : true
          })
          const updatedValuationRecord = new AssetLiabilityValuation({
            ...latestValuation,
            validTo: today.subtract(1, 'ms').toJSON()
          })
          await valuationDb.bulkDocs([
            newValuationRecord,
            updatedValuationRecord
          ])
        }
      })
    )

    await Promise.all([
      uploadEncryptedData(db, userId, 'assetsLiabilities'),
      uploadEncryptedData(historyDb, userId, 'assetsLiabilitiesHistory'),
      uploadEncryptedData(valuationDb, userId, 'assetsLiabilitiesValuations')
    ])
  } catch (err) {
    throw err
  }
}

const importAssetsLiabilities = async (userId, records, masterKey) => {
  try {
    const db = new PouchDB(`${userId}_assetsLiabilities`)
    db.crypto(masterKey)

    // only update the latest version if the change is not back-dated amend
    const mappedRecords = records
      .filter(r => !r.isBackdated)
      .map(r => mapAssetLiabilityModel(r, r.type, r.subType))
    const results = await db.bulkDocs([...mappedRecords])

    const historyDb = new PouchDB(`${userId}_assetsLiabilitiesHistory`)
    historyDb.crypto(masterKey)

    const valuationDb = new PouchDB(`${userId}_assetsLiabilitiesValuations`)
    valuationDb.crypto(masterKey)

    await Promise.all(
      records.map(async record => {
        const doc = mapAssetLiabilityModel(record, record.type, record.subType)
        const result = results.find(result => result.id === doc._id)

        // update history version (only when the record is newer, not back-dated amend)
        if (result) {
          await historyDb.put({
            ...doc,
            _id: `${result.id}_${result.rev}`,
            _rev: undefined,
            time: new Date()
          })
        }

        // update valuation records
        const latestValuation = await getLatestValuation(
          userId,
          doc._id,
          masterKey,
          doc.valuationDate,
          valuationDb
        )
        if (!latestValuation) {
          const oldestValuation = await getOldestValuation(
            userId,
            record._id,
            masterKey,
            valuationDb
          )
          const valuationRecord = new AssetLiabilityValuation({
            ...doc,
            _id: `${doc._id}_${uuidv4()}`,
            _rev: undefined,
            validFrom: doc.valuationDate.toJSON(),
            validTo: oldestValuation
              ? moment(oldestValuation.validFrom).subtract(1, 'ms').toJSON()
              : END_OF_TIME
          })
          await valuationDb.put(valuationRecord)
        } else if (
          moment(latestValuation.validFrom).isSame(doc.valuationDate)
        ) {
          const valuationRecord = new AssetLiabilityValuation({
            ...latestValuation,
            ...doc,
            _id: latestValuation._id,
            _rev: latestValuation._rev
          })
          await valuationDb.put(valuationRecord)
        } else {
          const newValuationRecord = new AssetLiabilityValuation({
            ...latestValuation,
            ...doc,
            _id: `${doc._id}_${uuidv4()}`,
            _rev: undefined,
            validFrom: doc.valuationDate.toJSON()
          })
          const updatedValuationRecord = new AssetLiabilityValuation({
            ...latestValuation,
            validTo: doc.valuationDate.clone().subtract(1, 'ms').toJSON()
          })
          await valuationDb.bulkDocs([
            newValuationRecord,
            updatedValuationRecord
          ])
        }
      })
    )

    await Promise.all([
      uploadEncryptedData(db, userId, 'assetsLiabilities'),
      uploadEncryptedData(historyDb, userId, 'assetsLiabilitiesHistory'),
      uploadEncryptedData(valuationDb, userId, 'assetsLiabilitiesValuations')
    ])
  } catch (err) {
    throw err
  }
}
const importContacts = async (userId, records, masterKey) => {
  try {
    const db = new PouchDB(`${userId}_contacts`)
    db.crypto(masterKey)

    const results = await db.bulkDocs([...records])

    const historyDb = new PouchDB(`${userId}_contactsHistory`)
    historyDb.crypto(masterKey)

    await Promise.all(
      records.map(async record => {
        const result = results.find(result => result.id === record._id)

        if (result) {
          await historyDb.put({
            ...record,
            _id: `${result.id}_${result.rev}`,
            _rev: undefined,
            time: new Date()
          })
        }
      })
    )

    await Promise.all([
      uploadEncryptedData(db, userId, 'contacts'),
      uploadEncryptedData(historyDb, userId, 'contactsHistory')
    ])
  } catch (err) {
    throw err
  }
}

const unlinkItemFromLinkedList = async (itemId, propName, keys, db) => {
  const docs = await db.allDocs({ keys, include_docs: true })
  const updatedDocs = docs.rows
    .filter(row => row.doc)
    .map(row => {
      const { doc } = row
      const links = doc[propName]
        ? doc[propName].filter(linkId => linkId !== itemId)
        : []
      return { ...doc, [propName]: links }
    })
  await db.bulkDocs(updatedDocs)
}

const removeAssetLiabilityRecord = async (userId, masterKey, record) => {
  try {
    const db = new PouchDB(`${userId}_assetsLiabilities`)
    const keys = record.links
    const contactIds = uniq([
      ...(record.contacts || []),
      ...(record.tenant || []),
      ...(record.insuranceAdvisor || []),
      ...(record.beneficiaries || []),
      ...(record.borrower ? [record.borrower] : []),
      ...(record.nameAssured ? [record.nameAssured] : []),
      ...(record.company ? [record.company] : []),
      ...(record.lender ? [record.lender] : [])
    ])
    const documentIds = record.documents || []
    const pendingDocs = await getRecords(userId, 'pendingDocuments', masterKey)

    const updatedPendingDocumentIds = pendingDocs
      .map(doc => doc._id)
      .filter(id => documentIds.includes(id))

    const updatedDocumentIds = documentIds.filter(
      id => !updatedPendingDocumentIds.includes(id)
    )

    const deletedRecord = {
      ...record,
      contacts: [],
      documents: [],
      events: [],
      links: [],
      passwords: [],
      deleted: true
    }

    db.crypto(masterKey)
    await db.put(deletedRecord)

    if (keys?.length) {
      await unlinkItemFromLinkedList(record._id, 'links', keys, db)
    }
    await uploadEncryptedData(db, userId, 'assetsLiabilities')

    const latestValuation = await getLatestValuation(
      userId,
      record._id,
      masterKey
    )
    if (latestValuation) {
      const valuationDb = new PouchDB(`${userId}_assetsLiabilitiesValuations`)
      valuationDb.crypto(masterKey)
      const endOfYesterday = moment().startOf('day').subtract(1, 'ms').toJSON()
      const updatedValuationRecord = new AssetLiabilityValuation({
        ...latestValuation,
        validTo: endOfYesterday
      })
      await valuationDb.put(updatedValuationRecord)
      await uploadEncryptedData(
        valuationDb,
        userId,
        'assetsLiabilitiesValuations'
      )
    }

    // update contacts
    if (contactIds?.length) {
      const contactsDb = new PouchDB(`${userId}_contacts`)
      contactsDb.crypto(masterKey)
      await unlinkItemFromLinkedList(
        record._id,
        'assetsLiabilities',
        contactIds,
        contactsDb
      )
      await uploadEncryptedData(contactsDb, userId, 'contacts')
    }

    // update documents
    if (updatedDocumentIds?.length) {
      const docDb = new PouchDB(`${userId}_documents`)
      docDb.crypto(masterKey)
      await unlinkItemFromLinkedList(
        record._id,
        'assetsLiabilities',
        updatedDocumentIds,
        docDb
      )
      await uploadEncryptedData(docDb, userId, 'documents')
    }

    // update passwords
    if (record.passwords?.length) {
      const passwordsDb = new PouchDB(`${userId}_passwords`)
      passwordsDb.crypto(masterKey)
      await unlinkItemFromLinkedList(
        record._id,
        'assetsLiabilities',
        record.passwords,
        passwordsDb
      )
      await uploadEncryptedData(passwordsDb, userId, 'passwords')
    }

    //update pending documents
    if (updatedPendingDocumentIds?.length) {
      const pedingDocDb = new PouchDB(`${userId}_pendingDocuments`)
      pedingDocDb.crypto(masterKey)
      await unlinkItemFromLinkedList(
        record._id,
        'assetsLiabilities',
        updatedPendingDocumentIds,
        pedingDocDb
      )
      await uploadEncryptedData(pedingDocDb, userId, 'pendingDocuments')
    }

    //update events
    if (record.events?.length) {
      const eventDb = new PouchDB(`${userId}_events`)
      eventDb.crypto(masterKey)
      await unlinkItemFromLinkedList(
        record._id,
        'assetsLiabilities',
        record.events,
        eventDb
      )
      await uploadEncryptedData(eventDb, userId, 'events')
    }
  } catch (e) {
    onError(e)
    throw e
  }
}

const removeAllDocs = async (userId, masterKey, dbName) => {
  const db = new PouchDB(`${userId}_${dbName}`)

  try {
    db.crypto(masterKey)
    const allDocs = await db.allDocs({ include_docs: true })
    if (allDocs.rows?.length) {
      const deletedDocs = allDocs.rows.map(row => ({
        ...row.doc,
        _deleted: true
      }))

      await db.bulkDocs(deletedDocs)
      await uploadEncryptedData(db, userId, dbName)
    }
  } catch (e) {
    throw e
  }
}

const removeValuationRecord = async (
  userId,
  masterKey,
  record,
  deleteHistorical = false,
  deleteFuture = false,
  isProfessionalDeputy = false
) =>
  wrapPouchDbAction(
    'assetsLiabilitiesValuations',
    userId,
    masterKey,
    async db => {
      await db.remove(record)
      const assetLiabilityId = record._id.split('_')[0]

      const allDocs = await db.allDocs({
        startkey: assetLiabilityId,
        endkey: `${assetLiabilityId}\ufff0`,
        include_docs: true
      })

      const docs = allDocs.rows.map(row => row.doc)

      if (!deleteHistorical && !deleteFuture) {
        const comparedDate = moment(record.validFrom).subtract(1, 'd')

        const before = docs.find(
          doc =>
            moment(doc.validFrom).isSameOrBefore(comparedDate) &&
            moment(doc.validTo).isAfter(comparedDate)
        )
        if (before) {
          await db.put({ ...before, validTo: record.validTo })
        }
      }

      if (deleteHistorical) {
        const beforeDocs = docs
          .filter(doc => moment(doc.validTo).isBefore(record.validFrom))
          .map(doc => ({ ...doc, _deleted: true }))

        beforeDocs.length > 0 && (await db.bulkDocs(beforeDocs))
      }

      if (deleteFuture) {
        const afterDocs = docs
          .filter(doc => moment(doc.validFrom).isAfter(record.validTo))
          .map(doc => ({ ...doc, _deleted: true }))

        afterDocs.length > 0 && (await db.bulkDocs(afterDocs))

        const alDb = new PouchDB(
          isProfessionalDeputy
            ? `${userId}_pendingAssetsLiabilities`
            : `${userId}_assetsLiabilities`
        )
        alDb.crypto(masterKey)
        const assetLiability = await alDb.get(assetLiabilityId)

        if (assetLiability) {
          const comparedDate = moment(record.validFrom).subtract(1, 'd')
          const before = docs.find(
            doc =>
              moment(doc.validFrom).isSameOrBefore(comparedDate) &&
              moment(doc.validTo).isAfter(comparedDate)
          )
          if (before) {
            await db.put({ ...before, validTo: END_OF_TIME })
            const updatedAssetLiability = {
              ...assetLiability,
              percentageOwnership: before.percentageOwnership,
              valuationInAssetCurrency: before.valuationInAssetCurrency,
              valuationInBaseCurrency: before.valuationInBaseCurrency,
              outstandingValueInLiabilityCurrency:
                before.outstandingValueInLiabilityCurrency,
              outstandingValueInBaseCurrency:
                before.outstandingValueInBaseCurrency,
              quantity: before.quantity
            }
            await alDb.put(updatedAssetLiability)
            await uploadEncryptedData(
              alDb,
              userId,
              isProfessionalDeputy
                ? 'pendingAssetsLiabilities'
                : 'assetsLiabilities'
            )
          } else {
            removeAssetLiabilityRecord(userId, masterKey, assetLiability)
          }
        } else {
          alDb.removeCrypto() // necessary?
        }
      }

      uploadEncryptedData(db, userId, 'assetsLiabilitiesValuations')
    }
  )

const linkDocumentsFromAssetsLiabilities = async (
  updatedDocuments,
  newRecord,
  addedDocuments,
  removedDocuments,
  dbName,
  userId,
  masterKey
) => {
  const db = new PouchDB(`${userId}_${dbName}`)
  db.crypto(masterKey)
  const docs = await db.allDocs({
    keys: updatedDocuments,
    include_docs: true
  })

  const updatedDocs = docs.rows
    .filter(row => row.doc)
    .map(row => {
      const { doc } = row

      if (addedDocuments.includes(doc._id)) {
        const ids = [newRecord._id]

        const newAssetsLiabilities = uniq([
          ...(doc.assetsLiabilities || []),
          ...ids
        ])

        return { ...doc, assetsLiabilities: newAssetsLiabilities }
      } else if (removedDocuments.includes(doc._id)) {
        const newAssetsLiabilities = doc.assetsLiabilities
          ? doc.assetsLiabilities.filter(alId => alId !== newRecord._id)
          : []
        return { ...doc, assetsLiabilities: newAssetsLiabilities }
      } else {
        return { ...doc }
      }
    })

  await db.bulkDocs(updatedDocs)
  await uploadEncryptedData(db, userId, dbName)
}

const unlinkContatsFromAssetsLiabilities = async (
  assetsLiabilities,
  contactId,
  db,
  dbName,
  userId,
  masterKey
) => {
  db.crypto(masterKey)
  const docs = await db.allDocs({
    keys: assetsLiabilities,
    include_docs: true
  })
  const updatedDocs = docs.rows
    .filter(row => row.doc)
    .map(row => {
      const { doc } = row
      const contacts = doc.contacts
        ? doc.contacts.filter(c => c !== contactId)
        : []

      const tenant = doc.tenant ? doc.tenant.filter(r => r !== contactId) : []

      const insuranceAdvisor = doc.insuranceAdvisor
        ? doc.insuranceAdvisor.filter(ia => ia !== contactId)
        : []

      const beneficiaries = doc.beneficiaries
        ? doc.beneficiaries.filter(b => b !== contactId)
        : []

      const typeOfTrustInterest = doc.typeOfTrustInterest && {
        ...doc.typeOfTrustInterest,
        values:
          doc.typeOfTrustInterest.values?.filter(
            value => value !== contactId
          ) || []
      }

      const borrower =
        doc.borrower && doc.borrower === contactId ? '' : doc.borrower

      const nameAssured =
        doc.nameAssured && doc.nameAssured === contactId ? '' : doc.nameAssured

      const company =
        doc.company && doc.company === contactId ? '' : doc.company

      const lender = doc.lender && doc.lender === contactId ? '' : doc.lender

      const markUp = new RegExp(`@\\[(((?!@\\[).)*)\\]\\(${contactId}\\)`, 'g')
      const descriptionWithMarkup = doc.descriptionWithMarkup.replace(
        markUp,
        '$1'
      )

      return {
        ...doc,
        contacts,
        tenant,
        insuranceAdvisor,
        beneficiaries,
        descriptionWithMarkup,
        borrower,
        nameAssured,
        company,
        lender,
        typeOfTrustInterest
      }
    })
  await db.bulkDocs(updatedDocs)
  await uploadEncryptedData(db, userId, dbName)
}

const updateLinkItemsForEvent = async (
  newLinks,
  currentLinks,
  dbName,
  userId,
  masterKey,
  item,
  parentId
) => {
  const addedLinks = parentId
    ? newLinks
    : newLinks.filter(itemId => !currentLinks || !currentLinks.includes(itemId))
  const removedLinks = parentId
    ? currentLinks
    : currentLinks.filter(itemId => !newLinks.includes(itemId))

  let updatedLinkItems = [...addedLinks, ...removedLinks]

  if (updatedLinkItems.length) {
    const db = new PouchDB(`${userId}_${dbName}`)
    db.crypto(masterKey)
    const docs = await db.allDocs({
      keys: updatedLinkItems,
      include_docs: true
    })

    const updatedDocs = docs.rows
      .filter(row => row.doc)
      .map(row => {
        const { doc } = row

        if (addedLinks.includes(doc._id)) {
          const newEvents = doc.events
            ? [...doc.events.filter(id => id !== parentId), item._id]
            : [item._id]
          return { ...doc, events: newEvents }
        } else if (removedLinks.includes(doc._id)) {
          const newEvents = doc.events
            ? doc.events.filter(
                docId => docId !== item._id && docId !== parentId
              )
            : []
          return { ...doc, events: newEvents }
        } else {
          return { ...doc }
        }
      })

    await db.bulkDocs(updatedDocs)
    await uploadEncryptedData(db, userId, dbName)
  }
}

// link a list of contact to another
const linkContactsToAnother = async (
  userId,
  contactId,
  linkIds,
  masterKey,
  dbName
) => {
  try {
    if (linkIds && linkIds.length) {
      const db = new PouchDB(`${userId}_${dbName}`)
      db.crypto(masterKey)
      const docs = await db.allDocs({ keys: linkIds, include_docs: true })

      const updatedDocs = docs.rows
        .filter(row => row.doc)
        .map(row => {
          const { doc } = row
          return {
            ...doc,
            links: [
              ...(doc.links?.length
                ? doc.links.filter(id => id !== contactId)
                : []),
              ...[contactId]
            ]
          }
        })
      await db.bulkDocs(updatedDocs)

      await uploadEncryptedData(db, userId, dbName)
    } else {
      return
    }
  } catch (e) {
    onError(e)
  }
}

const unlinkContactsFromAnother = async (
  userId,
  contactId,
  unlinkedContacts,
  masterKey,
  dbName
) => {
  try {
    if (unlinkedContacts && unlinkedContacts.length) {
      const db = new PouchDB(`${userId}_${dbName}`)
      db.crypto(masterKey)
      const docs = await db.allDocs({
        keys: unlinkedContacts,
        include_docs: true
      })
      const updatedDocs = docs.rows
        .filter(row => row.doc)
        .map(row => {
          const { doc } = row
          return {
            ...doc,
            links: doc.links?.length
              ? doc.links.filter(linkId => linkId !== contactId)
              : []
          }
        })
      await db.bulkDocs(updatedDocs)
      await uploadEncryptedData(db, userId, dbName)
    } else {
      return
    }
  } catch (e) {
    onError(e)
  }
}

const deletedRecordsHistory = async (records, userId, masterKey, dbName) => {
  try {
    const deletedRecords = (
      await Promise.all(
        records.map(record =>
          getRecords(userId, `${dbName}History`, masterKey, {
            startkey: record._id,
            endkey: `${record._id}\ufff0`
          })
        )
      )
    ).flat()
    await permanentlyDeleteItems(
      `${dbName}History`,
      userId,
      deletedRecords,
      masterKey
    )
  } catch (e) {
    onError(e)
  }
}

export {
  deletedRecordsHistory,
  destroyAllDbs,
  loadRecords,
  getRecords,
  getRecord,
  uploadEncryptedData,
  getRecordVersions,
  subscribeToDBChanges,
  linkDocumentToAssetLiability,
  linkAssetsLiabilities,
  unlinkAssetLiability,
  linkContacts,
  unlinkContact,
  permanentlyDeleteItems,
  permanentlyDeleteDocument,
  restoreItems,
  //restoreDocument,
  restoreValuation,
  getLatestVersion,
  getLatestValuation,
  getOldestValuation,
  deleteContact,
  toggleStar,
  createFolder,
  renameFolder,
  deleteFolder,
  deleteDocument,
  unlinkDocument,
  moveItem,
  updateAssetsLiabilitiesValuation,
  importAssetsLiabilities,
  permanentlyDeleteDocuments,
  bulkDeleteDocuments,
  moveItems,
  copyItems,
  unlinkDocuments,
  updateLinkItemsForFile,
  newDocumentsToCopy,
  restoreDocuments,
  importContacts,
  removeValuationRecord,
  removeAssetLiabilityRecord,
  removeAllDocs,
  unlinkItemFromLinkedList,
  unlinkContatsFromAssetsLiabilities,
  updateLinkItemsForEvent,
  unlinkPassword,
  rejectDocument,
  handleDocumentsRequests,
  approveDocument,
  linkDocumentsFromAssetsLiabilities,
  linkContactsToAnother,
  unlinkContactsFromAnother,
  reloadRecords,
  unlinkEventFromAL,
  unlinkEventFromContact
}
