import React, { useState, useContext } from 'react'
import VaultContext from '../../contexts/VaultContext'
import FormItem from '../override/FormItem'
import PouchDB from 'pouchdb'
import Button from '../override/Button'
import { Modal, Input, Form, message } from 'antd'
import { useTranslation } from 'react-i18next'
import { onError } from '../../lib/sentry'
import uuidv4 from 'uuid/v4'
import { uploadEncryptedData } from '../../lib/pouchDb'
import { AES, enc } from 'crypto-js'
import {
  compareToPassword,
  validateToConfirmPassword,
  compareToOldPassword
} from '../../share/formHelpers'
import { useMutation } from 'react-apollo-hooks'
import { createS3Change } from '../../graphql/mutations'
import { useSelector } from 'react-redux'

function CreatePrivateFolderModal(props) {
  const {
    docItem,
    visible,
    setVisible,
    isChangePassword,
    setIsChangePassword,
    isCreatePrivateFolder,
    setIsCreatePrivateFolder,
    form
  } = props
  const { getFieldDecorator } = form
  const { activeDocuments } = useSelector(state => state.documents)
  const privateFolder = activeDocuments.find(record => record.isPrivate)
  const { userId, masterKey, isReadonly, privateFolderKey } =
    useContext(VaultContext)
  const [confirmDirty, setConfirmDirty] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const { t } = useTranslation()
  const [addS3Change] = useMutation(createS3Change)
  const handleSave = () => {
    form.validateFields(async (err, values) => {
      if (err) {
        return
      }
      try {
        setIsSaving(true)
        const db = new PouchDB(`${userId}_documents`)
        db.crypto(masterKey)
        if (isChangePassword) {
          const record = await db.get(docItem.id)
          if (
            values.currentPwd ===
            AES.decrypt(record.password, privateFolderKey).toString(enc.Latin1)
          ) {
            const newRecord = {
              ...record,
              password: AES.encrypt(
                values.password,
                privateFolderKey
              ).toString()
            }
            await db.put(newRecord)
            await uploadEncryptedData(db, userId, 'documents')
            localStorage.removeItem('privateFolderPassword')
            localStorage.setItem('NotReload', true)
            addS3Change({
              variables: {
                message: 'documents',
                userId: userId
              }
            })
            message.success(t('SUCCESSFULLY_CHANGED_PASSWORD'))
            setIsSaving(false)
            form.resetFields()
            setVisible(false)
            setIsChangePassword(false)
          } else {
            setIsSaving(false)
            message.error(t('INCORRECT_PASSWORD'))
          }
        } else if (isCreatePrivateFolder) {
          await db.put({
            path: 'Private Folder/',
            _id: `folder_${uuidv4()}`,
            isPrivate: true,
            password: AES.encrypt(values.password, privateFolderKey).toString()
          })
          await uploadEncryptedData(db, userId, 'documents')
          localStorage.setItem('NotReload', true)
          addS3Change({
            variables: {
              message: 'documents',
              userId: userId
            }
          })
          message.success(t('SUCCESSFULLY_CREATED_FOLDER'))
          setIsCreatePrivateFolder(false)
          setIsSaving(false)
          form.resetFields()
          setVisible(false)
        } else {
          const newRecord = {
            ...privateFolder,
            password: AES.encrypt(values.password, privateFolderKey).toString()
          }
          await db.put(newRecord)
          await uploadEncryptedData(db, userId, 'documents')
          localStorage.setItem('NotReload', true)
          addS3Change({
            variables: {
              message: 'documents',
              userId: userId
            }
          })
          message.success(t('SUCCESSFULLY_SET_PASSWORD'))
          setIsSaving(false)
          form.resetFields()
          setVisible(false)
        }
      } catch (error) {
        onError(error)
        setIsSaving(false)
        form.resetFields()
        setVisible(false)
        if (isCreatePrivateFolder) {
          message.error(t('FAILED_TO_CREATE_FOLDER'))
          setIsCreatePrivateFolder(false)
        } else if (isChangePassword) {
          message.error(t('FAILED_TO_CHANGE_PASSWORD'))
          setIsChangePassword(false)
        } else {
          message.error(t('FAILED_TO_SET_PASSWORD'))
        }
      }
    })
  }

  const handleConfirmBlur = e => {
    const { value } = e.target
    setConfirmDirty(confirmDirty || !!value)
  }

  const handleCancel = () => {
    if (isChangePassword) {
      setIsChangePassword(false)
    }
    setVisible(false)
    form.resetFields()
  }

  return (
    <Modal
      title={isChangePassword ? t('CHANGE_PASSWORD') : t('SET_PASSWORD')}
      visible={visible}
      onCancel={handleCancel}
      maskClosable={false}
      footer={[
        <Button
          key="cancel"
          onClick={handleCancel}
        >
          {t('CANCEL')}
        </Button>,
        !isReadonly && (
          <Button
            key="save"
            type="primary"
            loading={isSaving}
            onClick={handleSave}
          >
            {t('SAVE')}
          </Button>
        )
      ]}
    >
      <Form layout="vertical" hideRequiredMark>
        {isChangePassword && (
          <FormItem label={t('CURRENT_PASSWORD')}>
            {getFieldDecorator('currentPwd', {
              rules: [{ required: true, message: t('INPUT_PASSWORD_MSG') }]
            })(<Input.Password placeholder={t('CURRENT_PASSWORD')} />)}
          </FormItem>
        )}

        <FormItem
          label={isChangePassword ? t('NEW_PASSWORD') : t('PASSWORD')}
          hasFeedback
        >
          {getFieldDecorator('password', {
            rules: [
              {
                required: true,
                message: t('INPUT_PASSWORD_MSG')
              },
              {
                validator: (rule, value, callback) =>
                  validateToConfirmPassword(
                    rule,
                    value,
                    callback,
                    form,
                    confirmDirty
                  )
              },
              {
                validator: (rule, value, callback) =>
                  compareToOldPassword(rule, value, callback, form)
              }
            ]
          })(
            <Input.Password
              autoComplete="new-password"
              placeholder={isChangePassword ? t('NEW_PASSWORD') : t('PASSWORD')}
            />
          )}
        </FormItem>

        <FormItem
          label={
            isChangePassword ? t('CONFIRM_NEW_PASSWORD') : t('CONFIRM_PASSWORD')
          }
          hasFeedback
        >
          {getFieldDecorator('confirm', {
            rules: [
              {
                required: true,
                message: t('CONFIRM_NEW_PASSWORD_MSG')
              },
              {
                validator: (rule, value, callback) =>
                  compareToPassword(rule, value, callback, form)
              }
            ]
          })(
            <Input.Password
              onBlur={handleConfirmBlur}
              placeholder={
                isChangePassword
                  ? t('CONFIRM_NEW_PASSWORD')
                  : t('CONFIRM_PASSWORD')
              }
            />
          )}
        </FormItem>
      </Form>
    </Modal>
  )
}

const WrappedPrivateForm = Form.create({ name: 'CreatePrivateFolderModal' })(
  CreatePrivateFolderModal
)

export default WrappedPrivateForm
