import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Modal, Tag, Icon } from 'antd'
import Button from '../override/Button'

const PasswordDetails = props => {
  const { visible, record, setVisible } = props
  const [showPassword, setShowPassword] = useState(false)

  const { activeAssetsLiabilities } = useSelector(
    state => state.assetsLiabilities
  )
  const { activeDocuments } = useSelector(state => state.documents)
  const { eventsFromPouchDB: events } = useSelector(state => state.events)
  const { t } = useTranslation()



  const passwordLength = record.password?.length
  let hidenPassword = ''
  for (let i = 0; i < passwordLength; i++) {
    hidenPassword += '*'
  }

  if (showPassword === true) {
    return (
      <Modal
        title={t('PASSWORD_DETAILS')}
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setVisible(false)}>
            {t('CANCEL')}
          </Button>
        ]}
      >
        <p>
          {t('TITLE')}
          {': '}
          {record.title}
        </p>
        <p>
          {t('USERNAME')}
          {': '}
          {record.username}
        </p>
        <p>
          {t('PASSWORD')}
          {': '}
          {record.password}{' '}
          <Icon onClick={() => setShowPassword(false)} type="eye-invisible" />
        </p>
        <p>
          {t('URL')}
          {': '}
          {record.url}
        </p>
        <p>
          {t('NOTE')}
          {': '}
          {record.note}
        </p>
        <p>
          {t('ASSETS_LIABILITIES')}:{' '}
          {record.assetsLiabilities &&
            record.assetsLiabilities.map(alId =>
              activeAssetsLiabilities.map(al =>
                alId === al._id ? <Tag key={al._id}>{al.title}</Tag> : null
              )
            )}
        </p>
        <p>
          {t('DOCUMENTS')}:{' '}
          {record.documents &&
            record.documents.map(dId =>
              activeDocuments.map(d =>
                dId === d._id ? <Tag key={d._id}>{d.fileName}</Tag> : null
              )
            )}
        </p>
        <p>
          {t('EVENTS')}:{' '}
          {record.events &&
            record.events.map(eventId =>
              events.map(event =>
                eventId === event._id ? (
                  <Tag key={event._id}>{event.description}</Tag>
                ) : null
              )
            )}
        </p>
      </Modal>
    )
  } else {
    return (
      <Modal
        title={t('PASSWORD_DETAILS')}
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setVisible(false)}>
            {t('CANCEL')}
          </Button>
        ]}
      >
        <p>
          {t('TITLE')}
          {': '}
          {record.title}
        </p>
        <p>
          {t('USERNAME')}
          {': '}
          {record.username}
        </p>
        <p>
          {t('PASSWORD')}
          {': '}
          {hidenPassword}{' '}
          <Icon onClick={() => setShowPassword(true)} type="eye" />
        </p>
        <p>
          {t('URL')}
          {': '}
          {record.url}
        </p>
        <p>
          {t('NOTE')}
          {': '}
          {record.note}
        </p>
        <p>
          {t('ASSETS_LIABILITIES')}:{' '}
          {record.assetsLiabilities &&
            record.assetsLiabilities.map(alId =>
              activeAssetsLiabilities.map(al =>
                alId === al._id ? <Tag key={al._id}>{al.title}</Tag> : null
              )
            )}
        </p>
        <p>
          {t('DOCUMENTS')}:{' '}
          {record.documents &&
            record.documents.map(dId =>
              activeDocuments.map(d =>
                dId === d._id ? <Tag key={d._id}>{d.fileName}</Tag> : null
              )
            )}
        </p>
        <p>
          {t('EVENTS')}:{' '}
          {record.events &&
            record.events.map(eventId =>
              events.map(event =>
                eventId === event._id ? (
                  <Tag key={event._id}>{event.description}</Tag>
                ) : null
              )
            )}
        </p>
      </Modal>
    )
  }
}

export default PasswordDetails
