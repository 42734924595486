import React, { useContext, useState } from 'react'
import { Popconfirm, Icon } from 'antd'
import CustomIcon from '../override/Icon'
import { H4 } from '../override/Typography'
import { ThemeContext } from 'styled-components'
import SimpleList from '../override/SimpleList'
import { useTranslation } from 'react-i18next'
import EventLinksModal from '../calendar/EventLinksModal'
import { DATE_FORMAT, ACCESS_LEVEL } from '../../share/Constants'
import moment from 'moment'
import { useSelector } from 'react-redux'
import AuthContext from '../../contexts/AuthContext'

export default function EventLinks({
  linkedEvents,
  handleUnlinkEvents,
  handleLinkEvents,
  setLinkedEvents,
  isReadonly
}) {
  const theme = useContext(ThemeContext)
  const { isProfessionalDeputy, isDelegateByPD } = useContext(AuthContext)
  const { accessLevel } = useSelector(state => state.settings)
  const [eventLinksModalVisible, setEventLinksModalVisible] = useState(false)
  const { t } = useTranslation()

  return (
    <>
      <SimpleList
        className="tour-existed-contacts-link"
        header={<H4>{t('EVENT_LINKS')}</H4>}
        extra={
          (!isReadonly ||
            (((isReadonly && isDelegateByPD) || isProfessionalDeputy) &&
              accessLevel === ACCESS_LEVEL.NEED_APPROVAL)) && (
            <CustomIcon
              type="add"
              style={{ fontSize: 30, color: theme.primary, cursor: 'pointer' }}
              onClick={() => setEventLinksModalVisible(true)}
            />
          )
        }
        data={linkedEvents}
        itemKey="_id"
        icon={e => (
          <Icon type="calendar" style={{ marginRight: 10, fontSize: 24 }} />
        )}
        mainInfo={record => record.description}
        subInfo={record => moment(record.date).format(DATE_FORMAT)}
        action={record =>
          ((!isReadonly && !record.status) ||
            (((isReadonly && isDelegateByPD) || isProfessionalDeputy) &&
              accessLevel === ACCESS_LEVEL.NEED_APPROVAL)) && (
            <Popconfirm
              title={t('UNLINK_EVENT_CONFIRM')}
              onConfirm={() => handleUnlinkEvents(record._id)}
              okText={t('YES')}
              cancelText={t('NO')}
              arrowPointAtCenter
              placement="bottomRight"
            >
              <CustomIcon
                type="trash"
                style={{ color: theme.red, fontSize: 12 }}
              />
            </Popconfirm>
          )
        }
      />

      {eventLinksModalVisible && (
        <EventLinksModal
          visible={eventLinksModalVisible}
          setVisible={setEventLinksModalVisible}
          handleClose={() => setEventLinksModalVisible(false)}
          handleLinkEvents={handleLinkEvents}
          linkedEvents={linkedEvents}
          setLinkedEvents={setLinkedEvents}
        />
      )}
    </>
  )
}
