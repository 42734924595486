import React, { useContext, useEffect } from 'react'
import { Result, Icon } from 'antd'
import { Link, useLocation } from 'react-router-dom'
import Button from '../override/Button'
import { ThemeContext } from 'styled-components'
import { useTranslation } from 'react-i18next'

const AccountMigrated = () => {
  const query = new URLSearchParams(useLocation().search)
  const lang = query.get('lang')
  const theme = useContext(ThemeContext)
  const { t, i18n } = useTranslation()

  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang)
    }
  }, [lang, i18n])

  return (
    <div className="submitted-result">
      <Result
        icon={
          <Icon
            type="check-circle"
            theme="filled"
            style={{ color: theme.primary }}
          />
        }
        title={"Account migrated"}
        subTitle={
          <span>
            Your account has been successfully migrated. Please login again to re-configure the Multi-factor authentication for your account.
          </span>
        }
        extra={
          <Button size="large" type="primary">
            <Link to="/login">{t('RETURN_TO_HOMEPAGE')}</Link>
          </Button>
        }
      />
    </div>
  )
}

export default AccountMigrated
