import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef
} from 'react'
import { withRouter, useLocation } from 'react-router-dom'
import {
  Icon,
  Tooltip,
  Table,
  Tabs,
  Input,
  Empty,
  DatePicker,
  Row,
  Col,
  Select,
  message,
  Modal,
  Checkbox,
  Divider,
  Badge
} from 'antd'
import {
  CORE_TYPES,
  DEFAULT_ASSET_COLUMNS,
  DEFAULT_LIABILITY_COLUMNS,
  DEFAULT_ASSET_LIABILITY_COLUMNS,
  COLUMNS_NOT_SHOW_IN_GROUP,
  DATE_FORMAT,
  ACCESS_LEVEL,
  END_OF_TIME
} from '../../share/Constants'
import { StringResources } from '../../share/StringResources'
import {
  currencyFormat,
  getAssetLiabilityValueWithSign,
  search,
  exportDomToPng
} from '../../share/helpers'
import RemoveAssetLiability from './RemoveAssetLiability'
import { sumBy, uniq, uniqBy, flatten } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import Button from '../override/Button'
import VaultContext from '../../contexts/VaultContext'
import { H3, H4, H5 } from '../override/Typography'
import { ThemeContext } from 'styled-components'
import CustomTable from '../override/CustomTable'
import {
  getAssetLiabilityColumns,
  assetValuationColumns,
  liabilityValuationColumns,
  assetLiabilityValuationColumns,
  assetValuationExportColumns,
  liabilityValuationExportColumns,
  assetLiabilityValuationExportColumns,
  currencyColumns,
  assetLiabilityColumnsSmScreen
} from './assetLiabilityHelpers'
import AssetLiabilityShowHideColumns from './AssetLiabilityShowHideColumns'
import { fetchLatestRatesAndUpdateValuation } from '../../features/settings/settingsSlice'
import {
  permanentlyDeleteItems,
  getRecords,
  removeValuationRecord,
  uploadEncryptedData,
  unlinkItemFromLinkedList
} from '../../lib/pouchDb'
import moment from 'moment'
import AssetLiabilityBackDatedAmend from './AssetLiabilityBackDatedAmend'
import NetworthChart from './NetworthChart'
import NetworthByTypePieCharts from './NetworthByTypePieCharts'
import BalanceSheet from './BalanceSheet'
import ImportCsvModal from './ImportCsvModal'
import ImportExcelModal from './ImportExcelModal'
import TourContext, { TOUR_STEP_INDEXES } from '../../contexts/TourContext'
import api from '../../lib/api'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Flex from '../override/Flex'
import ExportDropdown from './ExportDropdown'
import ImportDropdown from './ImportDropdown'
import { onError } from '../../lib/sentry'
import { useTranslation, Trans } from 'react-i18next'
import AuthContext from '../../contexts/AuthContext'
import PouchDB from 'pouchdb'
import { fetchPendingAssetsLiabilities } from '../../features/assets-liabilities/assetsLiabilitiesSlice'
import { fetchOtherPendingAssetsLiabilities } from '../../features/assets-liabilities/otherAssetsLiabilitiesSlice'
import RejectModal from '../modals/RejectModal'
import { fetchPendingContacts } from '../../features/contacts/contactsSlice'
import AssetLiabilityValuation from '../../model/AssetLiabilityValuationModel'
import uuidv4 from 'uuid/v4'
import { fetchValuations } from './../../features/assets-liabilities/assetsLiabilitiesSlice'
import { useMutation } from 'react-apollo-hooks'
import { createS3Change } from '../../graphql/mutations'
import PieChartColorModal from '../modals/PieChartColorModal'

const { TabPane } = Tabs

const getTotalValueInBaseCurrency = records =>
  sumBy(
    records.filter(r => r.includeValueInNetWorth !== false),
    record =>
      (getAssetLiabilityValueWithSign(record, true) || 0) *
      ((record.percentageOwnership || 100) / 100)
  )

const AssetsLiabilities = props => {
  const today = moment().startOf('day')
  const { history } = props
  const theme = useContext(ThemeContext)
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'))
  const isXsUp = useMediaQuery(theme.breakpoints.up('xs'))
  const { isReadonly, userId, masterKey, fullName } = useContext(VaultContext)
  const { isProfessionalDeputy, isDelegateByPD } = useContext(AuthContext)
  const { tourStepIndex, prevTourStep, tourRun } = useContext(TourContext)

  const {
    activeAssetsLiabilities,
    isLoading,
    pendingAssetsLiabilities,
    rejectedAssetsLiabilities,
    valuations
  } = useSelector(state =>
    isReadonly ? state.otherAssetsLiabilities : state.assetsLiabilities
  )
  // TODO: deputy view valuations of primary user
  //const { valuations } = useSelector(state => state.assetsLiabilities)
  const { activeContacts, pendingContacts } = useSelector(state =>
    isReadonly ? state.otherContacts : state.contacts
  )

  const { activePasswords, pendingPasswords } = useSelector(state =>
    isReadonly ? state.otherPasswords : state.passwords
  )
  const { activeFiles, activeDocuments, pendingDocuments } = useSelector(
    state => (isReadonly ? state.otherDocuments : state.documents)
  )

  const { eventsFromPouchDB: events, pendingEventsFromPouchDB: pendingEvents } =
    useSelector(state => (isReadonly ? state.otherEvents : state.events))

  const { baseCurrency, ratesLastUpdated, accessLevel } = useSelector(
    state => state.settings
  )
  const { t } = useTranslation()
  let rejectFormRef

  const [addS3Change] = useMutation(createS3Change)

  const [sourceAssetsLiabilities, setSourceAssetsLiabilities] = useState([])
  const [sourceAssets, setSourceAssets] = useState([])
  const [sourceLiabilities, setSourceLiabilities] = useState([])
  const [assetColumns, setAssetColumns] = useState([])
  const [selectedAssetColumns, setSelectedAssetColumns] = useState(
    DEFAULT_ASSET_COLUMNS
  )
  const [liabilityColumns, setLiabilityColumns] = useState([])
  const [selectedLiabilityColumns, setSelectedLiabilityColumns] = useState(
    DEFAULT_LIABILITY_COLUMNS
  )
  const [assetLiabilityColumns, setAssetLiabilityColumns] = useState([])
  const [selectedAssetLiabilityColumns, setSelectedAssetLiabilityColumns] =
    useState(DEFAULT_ASSET_LIABILITY_COLUMNS)
  const [registryDate, setRegistryDate] = useState(today)
  const [historicalView, setHistoricalView] = useState(false)
  const [amendedRecord, setAmendedRecord] = useState({})
  const [amendModalVisible, setAmendModalVisible] = useState(false)
  const [chartData, setChartData] = useState([])
  const [importModalVisible, setImportModalVisible] = useState(false)
  const [importExcelModalVisible, setImportExcelModalVisible] = useState(false)
  const [defaultColumns, setDefaultColumns] = useState({
    asset: DEFAULT_ASSET_COLUMNS,
    liability: DEFAULT_LIABILITY_COLUMNS,
    group: DEFAULT_ASSET_LIABILITY_COLUMNS
  })
  const [isGroupByCurrency, setIsGroupByCurrency] = useState(true)
  const [rejectModalVisible, setRejectModalVisible] = useState(false)
  const [rejectRecord, setRejectRecord] = useState({})
  const [rejecting, setRejecting] = useState(false)
  const [loading, setLoading] = useState(null)
  const urlParams = new URLSearchParams(useLocation().search)
  const tab = urlParams.get('tab')
  const [pieChartColorModalVisible, setPieChartColorModalVisible] =
    useState(false)

  const dispatch = useDispatch()

  const chartRef = useRef()

  // get unique types for filter
  // const types = [...new Set(activeAssetsLiabilities.map(d => d.type))].map(
  //   type => ({
  //     text: type,
  //     value: type
  //   })
  // )

  const pendingAssets = pendingAssetsLiabilities.filter(
    a => a.type === CORE_TYPES.ASSET
  )
  const pendingLiabilities = pendingAssetsLiabilities.filter(
    a => a.type === CORE_TYPES.LIABILITY
  )

  const rejectedAssets = rejectedAssetsLiabilities.filter(
    a => a.type === CORE_TYPES.ASSET
  )

  const rejectedLiabilities = rejectedAssetsLiabilities.filter(
    a => a.type === CORE_TYPES.LIABILITY
  )

  const getAllColumns = (source, defaultColumns, type = null) => {
    const subtypes = []
    source.forEach(record => {
      const key = `${record.type}_${record.subType || ''}`
      if (!subtypes.find(subtype => subtype.key === key)) {
        subtypes.push({ key, record })
      }
    })

    const uniqueKeys = uniq(
      subtypes.reduce((keys, subtype) => {
        return [...keys, ...Object.keys(subtype.record)]
      }, [])
    )

    let columns = getAssetLiabilityColumns().filter(
      col => uniqueKeys.includes(col.key) || defaultColumns.includes(col.key)
    )

    if (!type) {
      columns = columns.filter(c => !COLUMNS_NOT_SHOW_IN_GROUP.includes(c.key))
    }

    return columns
  }

  // group data by currency
  const sourceGroupedByCurrency = sourceAssetsLiabilities.reduce(
    (source, item) => {
      const currencyGroupIndex = source.findIndex(s =>
        item.currency ? s.currency === item.currency : s.currency === 'Unknown'
      )

      if (currencyGroupIndex > -1) {
        const currencyGroup = source[currencyGroupIndex]
        const newCurrencyGroup = {
          currency: currencyGroup.currency,
          dataByKey: [...currencyGroup.dataByKey, item],
          grossAssetValue:
            ((item.includeValueInNetWorth !== false &&
              +item.valuationInAssetCurrency) ||
              0) + currencyGroup.grossAssetValue,
          grossOutstandingValue:
            ((item.includeValueInNetWorth !== false &&
              +item.outstandingValueInLiabilityCurrency) ||
              0) + currencyGroup.grossOutstandingValue,
          netValueInBaseCurrency:
            (+getAssetLiabilityValueWithSign(item, true) || 0) +
            currencyGroup.netValueInBaseCurrency
        }

        return Object.assign([...source], {
          [currencyGroupIndex]: newCurrencyGroup
        })
      } else {
        const newCurrencyGroup = {
          currency: item.currency || 'Unknown',
          dataByKey: [item],
          grossAssetValue:
            (item.includeValueInNetWorth !== false &&
              +item.valuationInAssetCurrency) ||
            0,
          grossOutstandingValue:
            (item.includeValueInNetWorth !== false &&
              +item.outstandingValueInLiabilityCurrency) ||
            0,
          netValueInBaseCurrency:
            (item.includeValueInNetWorth !== false &&
              +getAssetLiabilityValueWithSign(item, true)) ||
            0
        }

        return [...source, newCurrencyGroup]
      }
    },
    []
  )

  const uniqueLinkedRecords = uniqBy(
    flatten(sourceAssetsLiabilities.map(sal => sal.links || 'no_links')),
    id => id
  )

  const sourceGroupedByLinks = uniqueLinkedRecords
    .sort((a, b) => a && a.localeCompare(b))
    .map(recordId => {
      const dataByKey =
        recordId === 'no_links'
          ? sourceAssetsLiabilities.filter(
              sal => !sal.links || !sal.links.length
            )
          : sourceAssetsLiabilities.filter(
              sal =>
                sal.links &&
                sal.links.length &&
                sal.links.find(linkId => linkId === recordId)
            )
      const record = sourceAssetsLiabilities.find(sal => sal._id === recordId)
      return {
        ...record,
        _id: recordId,
        title: record ? record.title : 'No links',
        dataByKey
      }
    })


  useEffect(() => {
    if (tourRun && tourStepIndex === TOUR_STEP_INDEXES.ASSET_OVERVIEW) {
      prevTourStep()
    }
  }, [tourRun, tourStepIndex, prevTourStep])

  useEffect(() => {
    if (userId) {
      api
        .getRegistryColumns(userId)
        .then(res => {
          if (!res.data?.registryColumns) return
          const { asset, liability, group } = res.data.registryColumns
          setDefaultColumns({
            asset: asset || DEFAULT_ASSET_COLUMNS,
            liability: liability || DEFAULT_LIABILITY_COLUMNS,
            group: group || DEFAULT_ASSET_LIABILITY_COLUMNS
          })
          setSelectedAssetColumns(asset)
          setSelectedLiabilityColumns(liability)
          setSelectedAssetLiabilityColumns(group)
        })
        .catch(err => {
          onError(err)
          message.error(
            <Trans i18nKey="FAILED_TO_GET_REGISTRY_COLUMNS"></Trans>
          )
        })
    }
  }, [userId])

  useEffect(() => {
    const today = moment().startOf('day')
    if (registryDate?.isSame(today)) {
      setSourceAssetsLiabilities(activeAssetsLiabilities)
      setSourceAssets(
        activeAssetsLiabilities.filter(al => al.type === CORE_TYPES.ASSET)
      )
      setSourceLiabilities(
        activeAssetsLiabilities.filter(al => al.type === CORE_TYPES.LIABILITY)
      )
      setHistoricalView(false)
    } else {
      const filteredValuations = valuations.filter(
        v =>
          moment(v.validFrom).isSameOrBefore(registryDate) &&
          moment(v.validTo).isAfter(registryDate)
      )
      setSourceAssetsLiabilities(filteredValuations)

      setSourceAssets(
        filteredValuations.filter(al => al.type === CORE_TYPES.ASSET)
      )
      setSourceLiabilities(
        filteredValuations.filter(al => al.type === CORE_TYPES.LIABILITY)
      )

      setHistoricalView(true)
    }
  }, [activeAssetsLiabilities, userId, registryDate, valuations])

  // TODO: move this to the NetworthChart component
  useEffect(() => {
    const getChartData = updatedByRate => {
      const times = uniq(
        valuations
          .filter(record => record.updatedByRate === updatedByRate)
          .map(record => moment(record.validFrom).valueOf())
      ).sort((a, b) => a - b)

      return times.map(time => {
        const netWorth = valuations
          .filter(v => v.includeValueInNetWorth !== false)
          .reduce((total, record) => {
            if (
              moment(record.validFrom).valueOf() <= time &&
              moment(record.validTo).valueOf() > time
            ) {
              const valuation =
                (getAssetLiabilityValueWithSign(record, true) || 0) *
                ((record.percentageOwnership || 100) / 100)
              return total + valuation
            }
            return total
          }, 0)

        return { time, netWorth, updatedByRate }
      })
    }

    const chartData = getChartData()
    const chartDataByRate = getChartData(true).filter(
      d => !chartData.map(d => d.time).includes(d.time)
    )

    setChartData(
      [...chartData, ...chartDataByRate]
        .filter(c => c.netWorth)
        .sort((a, b) => a.time - b.time)
    )
  }, [userId, valuations])

  useEffect(() => {
    if (!isReadonly && baseCurrency && ratesLastUpdated !== null && masterKey) {
      const today = moment().startOf('day')

      // Update rates to db if:
      // - user record have no ratesLastUpdated info
      // - or, ratesLastUpdated date is in the past
      if (
        ratesLastUpdated === undefined ||
        today.diff(moment(ratesLastUpdated * 1000).startOf('day'), 'day') > 0
      ) {
        const updateRatesAndValuation = async () => {
          const assetsLiabilities = await getRecords(
            userId,
            'assetsLiabilities',
            masterKey
          )
          const pendingAssetsLiabilities = await getRecords(
            userId,
            'pendingAssetsLiabilities',
            masterKey
          )

          dispatch(
            fetchLatestRatesAndUpdateValuation(
              userId,
              baseCurrency,
              masterKey,
              assetsLiabilities.filter(al => !al.deleted && al.currency),
              pendingAssetsLiabilities.filter(pal => pal.currency)
            )
          )
        }
        updateRatesAndValuation()
      }
    }
  }, [userId, baseCurrency, isReadonly, ratesLastUpdated, dispatch, masterKey])

  const memoizedSetColumns = useCallback(
    (selectedColumns, setColumns) => {
      const columns = getAssetLiabilityColumns(
        activeContacts,
        activeAssetsLiabilities,
        activeFiles,
        events,
        activePasswords,
        baseCurrency
      ).filter(col => selectedColumns.includes(col.key))

      setColumns(columns)
    },
    [
      baseCurrency,
      activeContacts,
      activeAssetsLiabilities,
      activeFiles,
      events,
      activePasswords
    ]
  )

  useEffect(() => {
    memoizedSetColumns(selectedAssetColumns, setAssetColumns)
  }, [selectedAssetColumns, memoizedSetColumns])

  useEffect(() => {
    memoizedSetColumns(selectedLiabilityColumns, setLiabilityColumns)
  }, [selectedLiabilityColumns, memoizedSetColumns])

  useEffect(() => {
    memoizedSetColumns(selectedAssetLiabilityColumns, setAssetLiabilityColumns)
  }, [selectedAssetLiabilityColumns, memoizedSetColumns])

  const handleAssetLiabilityRequest = async (record, isApproved) => {
    try {
      setLoading(record._id)
      if (isApproved) {
        const db = new PouchDB(`${userId}_assetsLiabilities`)
        db.crypto(masterKey)
        const newRecord = {
          ...record,
          status: undefined
        }
        delete newRecord._rev

        const putResult = await db.put(newRecord)
        await uploadEncryptedData(db, userId, 'assetsLiabilities')

        //add history valuation version
        const valuationDb = new PouchDB(`${userId}_assetsLiabilitiesValuations`)
        valuationDb.crypto(masterKey)
        const valuationRecord = new AssetLiabilityValuation({
          ...newRecord,
          _id: `${putResult.id}_${uuidv4()}`,
          validFrom: newRecord.valuationDate,
          validTo: END_OF_TIME
        })
        await valuationDb.put(valuationRecord)
        await uploadEncryptedData(
          valuationDb,
          userId,
          'assetsLiabilitiesValuations'
        )

        //Delete pending_record
        const pendingDb = new PouchDB(`${userId}_pendingAssetsLiabilities`)
        const deletedRecord = {
          ...record,
          deleted: true
        }

        pendingDb.crypto(masterKey)
        await pendingDb.put(deletedRecord)

        const deletedRecords = await getRecords(
          userId,
          'pendingAssetsLiabilities',
          masterKey,
          { startkey: record._id, endkey: `${record._id}\ufff0` }
        )

        await permanentlyDeleteItems(
          'pendingAssetsLiabilities',
          userId,
          deletedRecords,
          masterKey
        )
        await api.handleAddRecordRequest(
          JSON.stringify({
            isApproved,
            primaryUserId: userId,
            fullname: fullName,
            recordType: 'asset / liability'
          })
        )
        localStorage.setItem('NotReload', true)
        addS3Change({
          variables: {
            message: 'pendingAssetsLiabilities, assetsLiabilities',
            userId: userId
          }
        })
        dispatch(fetchValuations(userId, masterKey))
        dispatch(fetchPendingAssetsLiabilities(userId, masterKey))
      } else {
        rejectFormRef.props.form.resetFields()
        setRejectModalVisible(true)
        setRejectRecord(record)
      }
    } catch (error) {
      message.error(
        isApproved
          ? t('FAILED_TO_APPROVE_REQUEST')
          : t('FAILED_TO_REJECT_REQUEST')
      )
    } finally {
      setLoading(null)
    }
  }

  const handleRejectRecord = () => {
    setRejecting(true)
    rejectFormRef.props.form.validateFields(async (err, values) => {
      try {
        if (err) {
          setRejecting(false)
          return
        }

        const contactIds = uniq([
          ...(rejectRecord.contacts || []),
          ...(rejectRecord.tenant || []),
          ...(rejectRecord.insuranceAdvisor || []),
          ...(rejectRecord.beneficiaries || []),
          ...(rejectRecord.borrower ? [rejectRecord.borrower] : []),
          ...(rejectRecord.nameAssured ? [rejectRecord.nameAssured] : []),
          ...(rejectRecord.company ? [rejectRecord.company] : []),
          ...(rejectRecord.lender ? [rejectRecord.lender] : [])
        ])

        const activeContactIds = contactIds.filter(cid =>
          activeContacts.map(ac => ac._id).includes(cid)
        )

        const pendingContactIds = contactIds.filter(cid =>
          pendingContacts.map(pc => pc._id).includes(cid)
        )

        const pendingDb = new PouchDB(`${userId}_pendingAssetsLiabilities`)
        pendingDb.crypto(masterKey)
        const newRecord = {
          ...rejectRecord,
          contacts: [],
          documents: [],
          events: [],
          passwords: [],
          tenant: [],
          insuranceAdvisor: [],
          beneficiaries: [],
          borrower: undefined,
          nameAssured: undefined,
          company: undefined,
          lender: undefined,
          status: 'Rejected',
          reasonReject: values.reasonReject
        }

        await pendingDb.put(newRecord)
        await uploadEncryptedData(pendingDb, userId, 'pendingAssetsLiabilities')

        if (activeContactIds?.length) {
          const contactsDb = new PouchDB(`${userId}_contacts`)
          contactsDb.crypto(masterKey)
          await unlinkItemFromLinkedList(
            rejectRecord._id,
            'assetsLiabilities',
            activeContactIds,
            contactsDb
          )
          await uploadEncryptedData(contactsDb, userId, 'contacts')
        }

        if (pendingContactIds?.length) {
          const pendingContactsDb = new PouchDB(`${userId}_pendingContacts`)
          pendingContactsDb.crypto(masterKey)
          await unlinkItemFromLinkedList(
            rejectRecord._id,
            'assetsLiabilities',
            pendingContactIds,
            pendingContactsDb
          )
          await uploadEncryptedData(
            pendingContactsDb,
            userId,
            'pendingContacts'
          )
        }

        // update documents
        if (rejectRecord.documents?.length) {
          const activeDocumentIds = rejectRecord.documents.filter(id =>
            activeDocuments.map(ad => ad._id).includes(id)
          )
          const pendingDocumentIds = rejectRecord.documents.filter(id =>
            pendingDocuments.map(pd => pd._id).includes(id)
          )
          if (activeDocumentIds?.length) {
            const documentsDb = new PouchDB(`${userId}_documents`)
            documentsDb.crypto(masterKey)
            await unlinkItemFromLinkedList(
              rejectRecord._id,
              'assetsLiabilities',
              activeDocumentIds,
              documentsDb
            )
            await uploadEncryptedData(documentsDb, userId, 'documents')
          }

          if (pendingDocumentIds?.length) {
            const pendingDocumentsDb = new PouchDB(`${userId}_pendingDocuments`)
            pendingDocumentsDb.crypto(masterKey)
            await unlinkItemFromLinkedList(
              rejectRecord._id,
              'assetsLiabilities',
              pendingDocumentIds,
              pendingDocumentsDb
            )
            await uploadEncryptedData(
              pendingDocumentsDb,
              userId,
              'pendingDocuments'
            )
          }
        }

        //update passwords
        if (rejectRecord.passwords?.length) {
          const activePasswordIds = rejectRecord.passwords.filter(id =>
            activePasswords.map(ad => ad._id).includes(id)
          )
          const pendingPasswordIds = rejectRecord.passwords.filter(id =>
            pendingPasswords.map(pd => pd._id).includes(id)
          )
          if (activePasswordIds?.length) {
            const passwordDb = new PouchDB(`${userId}_passwords`)
            passwordDb.crypto(masterKey)
            await unlinkItemFromLinkedList(
              rejectRecord._id,
              'assetsLiabilities',
              activePasswordIds,
              passwordDb
            )
            await uploadEncryptedData(passwordDb, userId, 'passwords')
          }

          if (pendingPasswordIds?.length) {
            const pendingPasswordDb = new PouchDB(`${userId}_pendingPasswords`)
            pendingPasswordDb.crypto(masterKey)
            await unlinkItemFromLinkedList(
              rejectRecord._id,
              'assetsLiabilities',
              pendingPasswordIds,
              pendingPasswordDb
            )
            await uploadEncryptedData(
              pendingPasswordDb,
              userId,
              'pendingPasswords'
            )
          }
        }

        //update events
        if (rejectRecord.events?.length) {
          const activeEventIds = rejectRecord.events.filter(id =>
            events.map(ad => ad._id).includes(id)
          )
          const pendingEventIds = rejectRecord.events.filter(id =>
            pendingEvents.map(pd => pd._id).includes(id)
          )
          if (activeEventIds?.length) {
            const eventsDb = new PouchDB(`${userId}_events`)
            eventsDb.crypto(masterKey)
            await unlinkItemFromLinkedList(
              rejectRecord._id,
              'assetsLiabilities',
              activeEventIds,
              eventsDb
            )
            await uploadEncryptedData(eventsDb, userId, 'events')
          }

          if (pendingEventIds?.length) {
            const pendingEventsDb = new PouchDB(`${userId}_pendingEvents`)
            pendingEventsDb.crypto(masterKey)
            await unlinkItemFromLinkedList(
              rejectRecord._id,
              'assetsLiabilities',
              pendingEventIds,
              pendingEventsDb
            )
            await uploadEncryptedData(pendingEventsDb, userId, 'pendingEvents')
          }
        }

        await api.handleAddRecordRequest(
          JSON.stringify({
            isApproved: false,
            primaryUserId: userId,
            fullname: fullName,
            recordType: 'asset / liability'
          })
        )
        localStorage.setItem('NotReload', true)
        addS3Change({
          variables: {
            message:
              'assetsLiabilities, pendingAssetsLiabilities, documents, pendingDocuments, contacts, pendingContacts, events, pendingEvents, passwords, pendingPasswords',
            userId: userId
          }
        })
        setRejecting(false)
        setRejectModalVisible(false)
        dispatch(fetchPendingContacts(userId, masterKey))
        dispatch(fetchPendingAssetsLiabilities(userId, masterKey))
      } catch (error) {
        setRejecting(false)
        message.error(t('FAILED_TO_REJECT_REQUEST'))
      }
    })
  }

  const rejectedReasonColumn = [
    {
      key: 'reasonReject',
      width: 120,
      dataIndex: 'reasonReject',
      title: <span className="dragHandler">{t('REJECTION_REASON')}</span>
    }
  ]

  const actionsColumn =
    (isReadonly && !isDelegateByPD && !isProfessionalDeputy) ||
    (tourRun && tourStepIndex === TOUR_STEP_INDEXES.CREATED_ASSET)
      ? []
      : [
          {
            key: 'actions',
            width: 120,
            dataIndex: 'actions',
            render: (text, record) => (
              <div
                className="asset-liability-actions"
                onClick={e => e.stopPropagation()}
                style={{ minWidth: 40, textAlign: 'right' }}
              >
                {record.status === 'Draft' &&
                !isProfessionalDeputy &&
                (!isReadonly || !isDelegateByPD) ? (
                  <>
                    <Button
                      loading={loading === record._id}
                      type="primary"
                      onClick={() => handleAssetLiabilityRequest(record, true)}
                    >
                      {t('APPROVE')}
                    </Button>
                    <Divider type="vertical" />
                    <Button onClick={() => handleAssetLiabilityRequest(record)}>
                      {t('REJECT')}
                    </Button>
                  </>
                ) : !record.status &&
                  ((isDelegateByPD && isReadonly) || isProfessionalDeputy) ? (
                  []
                ) : (
                  <>
                    <Tooltip
                      title={
                        historicalView
                          ? t('BACK_DATED_AMEND')
                          : t('EDIT_ASSET_LIABILITY')
                      }
                      arrowPointAtCenter
                      placement="topRight"
                    >
                      <Icon
                        theme="twoTone"
                        type="edit"
                        onClick={() => {
                          if (historicalView) {
                            setAmendedRecord(record)
                            setAmendModalVisible(true)
                          } else {
                            history.push(
                              `/assets-liabilities/${userId}/${record._id}/edit`
                            )
                          }
                        }}
                      />
                    </Tooltip>
                    {historicalView && registryDate?.isSame(record.validFrom) && (
                      <Tooltip
                        title={t('REMOVE_ASSET_LIABILITY')}
                        arrowPointAtCenter
                        placement="topRight"
                      >
                        <Icon
                          onClick={() => removeAssetLiabDateEvent(record)}
                          theme="twoTone"
                          twoToneColor="#eb4444"
                          type="delete"
                        />
                      </Tooltip>
                    )}
                    {!historicalView &&
                      ((isDelegateByPD && isReadonly) ||
                      isProfessionalDeputy ? (
                        <Tooltip
                          title={t('REMOVE_ASSET_LIABILITY')}
                          arrowPointAtCenter
                          placement="topRight"
                        >
                          <Icon
                            onClick={() =>
                              handleDeletePendingRejectedRecord(record)
                            }
                            theme="twoTone"
                            twoToneColor="#eb4444"
                            type="delete"
                          />
                        </Tooltip>
                      ) : (
                        <RemoveAssetLiability record={record} />
                      ))}
                  </>
                )}
              </div>
            )
          }
        ]

  const groupedByCurrencyColumns = [
    {
      key: 'currency',
      width: 120,
      dataIndex: 'currency',
      title: <span className="dragHandler">{t('CURRENCY')}</span>
    },
    {
      key: 'grossAssetValue',
      width: 120,
      dataIndex: 'grossAssetValue',
      title: <span className="dragHandler">{t('GROSS_ASSET_VALUE')}</span>,
      render: currencyFormat
    },
    {
      key: 'grossOutstandingValue',
      width: 120,
      dataIndex: 'grossOutstandingValue',
      title: (
        <span className="dragHandler">{t('GROSS_OUTSTANDING_VALUE')}</span>
      ),
      render: currencyFormat
    },
    {
      key: 'netValue',
      width: 120,
      title: <span className="dragHandler">{t('NET_VALUE')}</span>,
      render: (text, record) => (
        <>
          <strong>
            {currencyFormat(
              record.grossAssetValue - record.grossOutstandingValue
            )}
          </strong>{' '}
          <i>
            ({baseCurrency} {currencyFormat(record.netValueInBaseCurrency)})
          </i>
        </>
      )
    }
  ]

  const removeAssetLiabDateEvent = async record => {
    let deleteHistorical = false
    let deleteFuture = false

    Modal.confirm({
      title: `Delete valuation change`,
      content: (
        <>
          <p>
            {t('DELETE_VALUATION_CHANGE_CONFIRM')}{' '}
            <b>{registryDate?.format('DD/MM/YYYY')}</b>?
          </p>
          <p>
            <Checkbox
              onChange={e => {
                deleteHistorical = e.target.checked
              }}
            >
              <Trans i18nKey="DELETE_VALUATION_BEFORE_THIS_DATE"></Trans>
            </Checkbox>
          </p>
          <p>
            <Checkbox
              onChange={e => {
                deleteFuture = e.target.checked
              }}
            >
              <Trans i18nKey="DELETE_VALUATION_AFTER_THIS_DATE"></Trans>
            </Checkbox>
          </p>
        </>
      ),
      onOk: async () => {
        try {
          await removeValuationRecord(
            userId,
            masterKey,
            record,
            deleteHistorical,
            deleteFuture
          )
          message.success(t('SUCCESSFULLY_DELETED_ASSET_LIABILITY'))
        } catch {
          message.error(t('FAILED_TO_DELETE_ASSET_LIABILITY'))
        }
      }
    })
  }

  const handleDeletePendingRejectedRecord = async record => {
    const contactIds = uniq([
      ...(record.contacts || []),
      ...(record.tenant || []),
      ...(record.insuranceAdvisor || []),
      ...(record.beneficiaries || []),
      ...(record.borrower ? [rejectRecord.borrower] : []),
      ...(record.nameAssured ? [rejectRecord.nameAssured] : []),
      ...(record.company ? [rejectRecord.company] : []),
      ...(record.lender ? [rejectRecord.lender] : [])
    ])

    const activeContactIds = contactIds.filter(cid =>
      activeContacts.map(ac => ac._id).includes(cid)
    )

    const pendingContactIds = contactIds.filter(cid =>
      pendingContacts.map(pc => pc._id).includes(cid)
    )

    let linkedItemsMessages = []

    if (contactIds.length)
      linkedItemsMessages.push(`${contactIds.length} ${t('CONTACT_RECORDS')}`)

    Modal.confirm({
      title: t('DELETE_ASSET_LIABILITY'),
      content: (
        <>
          <p>
            {!!linkedItemsMessages.length &&
              `${linkedItemsMessages.join(' & ')} ${t(
                'WILL_BE_UNLINKED_FROM_THIS_ITEM'
              )}.`}
          </p>
          <p>{t('ARE_YOU_SURE_TO_DELETE_THIS_RECORD')}</p>
        </>
      ),
      onOk: async () => {
        try {
          //Delete pending_record
          const pendingDb = new PouchDB(`${userId}_pendingAssetsLiabilities`)
          const deletedRecord = {
            ...record,
            deleted: true
          }

          pendingDb.crypto(masterKey)
          await pendingDb.put(deletedRecord)

          const deletedRecords = await getRecords(
            userId,
            'pendingAssetsLiabilities',
            masterKey,
            { startkey: record._id, endkey: `${record._id}\ufff0` }
          )

          await permanentlyDeleteItems(
            'pendingAssetsLiabilities',
            userId,
            deletedRecords,
            masterKey
          )

          //Unlink for contact
          if (activeContactIds?.length) {
            const contactsDb = new PouchDB(`${userId}_contacts`)
            contactsDb.crypto(masterKey)
            await unlinkItemFromLinkedList(
              record._id,
              'assetsLiabilities',
              activeContactIds,
              contactsDb
            )
            await uploadEncryptedData(contactsDb, userId, 'contacts')
          }

          if (pendingContactIds?.length) {
            const pendingContactsDb = new PouchDB(`${userId}_pendingContacts`)
            pendingContactsDb.crypto(masterKey)
            await unlinkItemFromLinkedList(
              record._id,
              'assetsLiabilities',
              pendingContactIds,
              pendingContactsDb
            )
            await uploadEncryptedData(
              pendingContactsDb,
              userId,
              'pendingContacts'
            )
          }

          // update documents
          if (record.documents?.length) {
            const activeDocumentIds = record.documents.filter(id =>
              activeDocuments.map(ad => ad._id).includes(id)
            )
            const pendingDocumentIds = record.documents.filter(id =>
              pendingDocuments.map(pd => pd._id).includes(id)
            )
            if (activeDocumentIds?.length) {
              const documentsDb = new PouchDB(`${userId}_documents`)
              documentsDb.crypto(masterKey)
              await unlinkItemFromLinkedList(
                record._id,
                'assetsLiabilities',
                activeDocumentIds,
                documentsDb
              )
              await uploadEncryptedData(documentsDb, userId, 'documents')
            }

            if (pendingDocumentIds?.length) {
              const pendingDocumentsDb = new PouchDB(
                `${userId}_pendingDocuments`
              )
              pendingDocumentsDb.crypto(masterKey)
              await unlinkItemFromLinkedList(
                record._id,
                'assetsLiabilities',
                pendingDocumentIds,
                pendingDocumentsDb
              )
              await uploadEncryptedData(
                pendingDocumentsDb,
                userId,
                'pendingDocuments'
              )
            }
          }

          //update passwords
          if (record.passwords?.length) {
            const activePasswordIds = record.passwords.filter(id =>
              activePasswords.map(ad => ad._id).includes(id)
            )
            const pendingPasswordIds = record.passwords.filter(id =>
              pendingPasswords.map(pd => pd._id).includes(id)
            )
            if (activePasswordIds?.length) {
              const passwordDb = new PouchDB(`${userId}_passwords`)
              passwordDb.crypto(masterKey)
              await unlinkItemFromLinkedList(
                record._id,
                'assetsLiabilities',
                activePasswordIds,
                passwordDb
              )
              await uploadEncryptedData(passwordDb, userId, 'passwords')
            }

            if (pendingPasswordIds?.length) {
              const pendingPasswordDb = new PouchDB(
                `${userId}_pendingPasswords`
              )
              pendingPasswordDb.crypto(masterKey)
              await unlinkItemFromLinkedList(
                record._id,
                'assetsLiabilities',
                pendingPasswordIds,
                pendingPasswordDb
              )
              await uploadEncryptedData(
                pendingPasswordDb,
                userId,
                'pendingPasswords'
              )
            }
          }

          //update events
          if (record.events?.length) {
            const activeEventIds = record.events.filter(id =>
              events.map(ad => ad._id).includes(id)
            )
            const pendingEventIds = record.events.filter(id =>
              pendingEvents.map(pd => pd._id).includes(id)
            )
            if (activeEventIds?.length) {
              const eventsDb = new PouchDB(`${userId}_events`)
              eventsDb.crypto(masterKey)
              await unlinkItemFromLinkedList(
                record._id,
                'assetsLiabilities',
                activeEventIds,
                eventsDb
              )
              await uploadEncryptedData(eventsDb, userId, 'events')
            }

            if (pendingEventIds?.length) {
              const pendingEventsDb = new PouchDB(`${userId}_pendingEvents`)
              pendingEventsDb.crypto(masterKey)
              await unlinkItemFromLinkedList(
                record._id,
                'assetsLiabilities',
                pendingEventIds,
                pendingEventsDb
              )
              await uploadEncryptedData(
                pendingEventsDb,
                userId,
                'pendingEvents'
              )
            }
          }

          localStorage.setItem('NotReload', true)
          addS3Change({
            variables: {
              message:
                'assetsLiabilities, pendingAssetsLiabilities, documents, pendingDocuments, contacts, pendingContacts, events, pendingEvents, passwords, pendingPasswords',
              userId: userId
            }
          })

          dispatch(fetchOtherPendingAssetsLiabilities(userId, masterKey))

          message.success(t('SUCCESSFULLY_DELETED_ASSET_LIABILITY'))
        } catch {
          message.error(t('FAILED_TO_DELETE_ASSET_LIABILITY'))
        }
      }
    })
  }

  const handleClick = (record, isActived = true) => {
    const userIdParam = isReadonly ? `/${userId}` : ''
    const assetLiabilityId = historicalView
      ? record._id.split('_')[0]
      : record._id
    history.push(
      `/assets-liabilities/${assetLiabilityId}${userIdParam}?isActived=${isActived}`
    )
  }

  const renderTotalValue = (source, label) => {
    const totalValue = getTotalValueInBaseCurrency(source) || 0
    return (
      <div
        style={{
          textAlign: 'right',
          fontWeight: 'bold',
          marginTop: 30
        }}
      >
        {label}{' '}
        <Tooltip
          title={t(
            'VALUATION_IN_BASE_CURRENCY_WITH_PERCENTAGE_OWNERSHIP_TOOLTIP'
          )}
          overlayStyle={{ maxWidth: 260 }}
        >
          <Icon type="question-circle-o" />
        </Tooltip>{' '}
        :{' '}
        <span
          style={{
            color: totalValue < 0 ? theme.red : theme.primary,
            fontSize: 16
          }}
        >
          {baseCurrency} {currencyFormat(totalValue)}
        </span>
      </div>
    )
  }

  const onchangeTab = key => {
    const userIdParam = isReadonly ? `/${userId}` : ''
    history.push(`${userIdParam}/?tab=${key}`)
  }

  const searchAssetLiability = (value, type) => {
    const dataSource = registryDate?.isSame(today)
      ? activeAssetsLiabilities.filter(al => !type || al.type === type)
      : valuations.filter(
          v =>
            moment(v.validFrom).isSameOrBefore(registryDate) &&
            moment(v.validTo).isAfter(registryDate) &&
            (!type || v.type === type)
        )

    const searchResult = search(
      dataSource,
      ['title', 'description', 'tags'],
      value
    )

    type === CORE_TYPES.ASSET
      ? setSourceAssets(searchResult)
      : type === CORE_TYPES.LIABILITY
      ? setSourceLiabilities(searchResult)
      : setSourceAssetsLiabilities(searchResult)
  }

  return (
    <div className="main-wrapper">
      <Flex
        style={{ paddingTop: 20 }}
        flexDirection={isSmUp ? 'row' : 'column'}
        alignItems={isSmUp ? 'center' : 'flex-start'}
      >
        <H4 style={{ marginRight: 15 }}>{t('REGISTRY')}</H4>
        {!isReadonly && (
          <Flex>
            <DatePicker
              placeholder={t('SELECT_DATE')}
              format={DATE_FORMAT}
              value={registryDate}
              onChange={setRegistryDate}
              disabledDate={date => date.isAfter(today)}
              style={{ marginRight: 5, width: 'auto' }}
              allowClear
              showToday={false}
            />
            <Button onClick={() => setRegistryDate(today)}>{t('TODAY')}</Button>
          </Flex>
        )}
      </Flex>

      <Tabs
        defaultActiveKey={tab || t('ASSETS')}
        className="custom-tabs"
        onChange={onchangeTab}
      >
        <TabPane
          tab={
            <>
              <Badge
                style={{ marginRight: -15 }}
                count={
                  accessLevel === ACCESS_LEVEL.NEED_APPROVAL
                    ? pendingAssets?.length
                    : 0
                }
              >
                {t('ASSETS')}
              </Badge>
            </>
          }
          key={StringResources.ASSETS}
        >
          <div className="table-actions">
            <Input.Search
              placeholder={t('ENTER_THE_SEARCH_KEYWORD')}
              allowClear
              onSearch={value => searchAssetLiability(value, CORE_TYPES.ASSET)}
              className="search-asset-liability-input"
            />

            <div className="actions-group">
              {((!isReadonly && !historicalView) ||
                (((isReadonly && isDelegateByPD) || isProfessionalDeputy) &&
                  accessLevel &&
                  accessLevel !== ACCESS_LEVEL.DENY)) && (
                <>
                  <Button
                    icon={isXsUp ? 'plus' : ''}
                    size={isSmUp ? 'default' : 'small'}
                    className="tour-asset-group-buttons"
                    onClick={() => {
                      history.push(
                        `/assets-liabilities/add/${userId}?type=${CORE_TYPES.ASSET}`
                      )
                    }}
                  >
                    {isXsUp ? t('CREATE_NEW') : t('CREATE')}
                  </Button>
                  <ImportDropdown
                    setImportModalVisible={setImportModalVisible}
                    setImportExcelModalVisible={setImportExcelModalVisible}
                  />
                </>
              )}
              <ExportDropdown
                fileName="Assets"
                columns={[
                  ...(historicalView
                    ? assetValuationExportColumns(baseCurrency)
                    : assetColumns.map(col => {
                        return col.key === 'tags'
                          ? { ...col, render: items => items?.join(', ') }
                          : col
                      })),
                  ...currencyColumns(CORE_TYPES.ASSET, baseCurrency)
                ]}
                source={sourceAssets}
                selectedColumns={assetColumns}
              />
            </div>
          </div>
          {sourceAssets.length ? (
            <>
              {!historicalView && isSmUp && (
                <AssetLiabilityShowHideColumns
                  allColumns={getAllColumns(
                    sourceAssets,
                    DEFAULT_ASSET_COLUMNS,
                    CORE_TYPES.ASSET
                  )}
                  selectedColumns={selectedAssetColumns}
                  setSelectedColumns={setSelectedAssetColumns}
                  type={CORE_TYPES.ASSET}
                  defaultSelectedColumns={defaultColumns.asset}
                  allDefaultColumns={defaultColumns}
                  setDefaultColumns={setDefaultColumns}
                />
              )}
              {!!assetColumns.length && (
                <CustomTable
                  className="tour-created-asset"
                  rowKey="_id"
                  scroll={{ x: true }}
                  dataSource={sourceAssets}
                  columns={[
                    ...(isSmUp
                      ? historicalView
                        ? assetValuationColumns(baseCurrency)
                        : assetColumns
                      : assetLiabilityColumnsSmScreen(baseCurrency)),
                    ...actionsColumn
                  ]}
                  loading={isLoading}
                  onRow={record => ({
                    style: {
                      fontStyle:
                        record.includeValueInNetWorth === false && 'italic',
                      fontWeight:
                        registryDate?.isSame(record.validFrom) && 'bold'
                    },
                    onClick: () => handleClick(record)
                  })}
                  pagination={sourceAssets.length > 10 && { pageSize: 10 }}
                  size={isSmUp ? '' : 'small'}
                />
              )}
              {renderTotalValue(sourceAssets, t('TOTAL'))}
            </>
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('NO_ASSETS')}
            />
          )}

          {!!pendingAssets?.length &&
            accessLevel === ACCESS_LEVEL.NEED_APPROVAL && (
              <>
                <Divider type="horizontal" />
                <H3> {t('PENDING_ASSETS')} </H3>
                <CustomTable
                  className="tour-created-asset"
                  rowKey="_id"
                  scroll={{ x: true }}
                  dataSource={pendingAssets}
                  columns={[
                    ...(isSmUp
                      ? assetColumns
                      : assetLiabilityColumnsSmScreen(baseCurrency)),
                    ...actionsColumn
                  ]}
                  loading={isLoading}
                  onRow={record => ({
                    style: {
                      fontStyle:
                        record.includeValueInNetWorth === false && 'italic',
                      fontWeight:
                        registryDate?.isSame(record.validFrom) && 'bold'
                    },
                    onClick: () => handleClick(record, false)
                  })}
                  pagination={pendingAssets.length > 10 && { pageSize: 10 }}
                  size={isSmUp ? '' : 'small'}
                />
              </>
            )}
          {!!rejectedAssets?.length &&
            ((isReadonly && isDelegateByPD) || isProfessionalDeputy) &&
            accessLevel === ACCESS_LEVEL.NEED_APPROVAL && (
              <>
                <Divider type="horizontal" />
                <H3> {t('REJECTED_ASSETS')} </H3>
                <CustomTable
                  className="tour-created-asset"
                  rowKey="_id"
                  scroll={{ x: true }}
                  dataSource={rejectedAssets}
                  columns={[
                    ...(isSmUp
                      ? assetColumns
                      : assetLiabilityColumnsSmScreen(baseCurrency)),
                    ...rejectedReasonColumn,
                    ...actionsColumn
                  ]}
                  loading={isLoading}
                  onRow={record => ({
                    style: {
                      fontStyle:
                        record.includeValueInNetWorth === false && 'italic',
                      fontWeight:
                        registryDate?.isSame(record.validFrom) && 'bold'
                    },
                    onClick: () => handleClick(record, false)
                  })}
                  pagination={rejectedAssets.length > 10 && { pageSize: 10 }}
                  size={isSmUp ? '' : 'small'}
                />
              </>
            )}
          <ImportCsvModal
            visible={importModalVisible}
            setVisible={setImportModalVisible}
            sourceAssetsLiabilities={sourceAssetsLiabilities}
          />
        </TabPane>
        <TabPane
          tab={
            <>
              <Badge
                style={{ marginRight: -15 }}
                count={
                  accessLevel === ACCESS_LEVEL.NEED_APPROVAL
                    ? pendingLiabilities?.length
                    : 0
                }
              >
                {t('LIABILITIES')}
              </Badge>
            </>
          }
          key="Liabilities"
        >
          <div className="table-actions">
            <Input.Search
              placeholder={t('ENTER_THE_SEARCH_KEYWORD')}
              allowClear
              onSearch={value =>
                searchAssetLiability(value, CORE_TYPES.LIABILITY)
              }
              className="search-asset-liability-input"
            />

            <div className="actions-group">
              {((!isReadonly && !historicalView) ||
                (((isReadonly && isDelegateByPD) || isProfessionalDeputy) &&
                  accessLevel &&
                  accessLevel !== ACCESS_LEVEL.DENY)) && (
                <>
                  <Button
                    icon={isXsUp ? 'plus' : ''}
                    size={isSmUp ? 'default' : 'small'}
                    onClick={() =>
                      history.push(
                        `/assets-liabilities/add/${userId}?type=${CORE_TYPES.LIABILITY}`
                      )
                    }
                  >
                    {isXsUp ? t('CREATE_NEW') : t('CREATE')}
                  </Button>
                  <ImportDropdown
                    setImportModalVisible={setImportModalVisible}
                    setImportExcelModalVisible={setImportExcelModalVisible}
                  />
                </>
              )}
              <ExportDropdown
                fileName="Liabilities"
                columns={[
                  ...(historicalView
                    ? liabilityValuationExportColumns(baseCurrency)
                    : liabilityColumns.map(col => {
                        return col.key === 'tags'
                          ? { ...col, render: items => items?.join(', ') }
                          : col
                      })),
                  ...currencyColumns(CORE_TYPES.LIABILITY, baseCurrency)
                ]}
                source={sourceLiabilities}
                selectedColumns={liabilityColumns}
              />
            </div>
          </div>
          {sourceLiabilities.length ? (
            <>
              {!historicalView && isSmUp && (
                <AssetLiabilityShowHideColumns
                  allColumns={getAllColumns(
                    sourceLiabilities,
                    DEFAULT_LIABILITY_COLUMNS,
                    CORE_TYPES.LIABILITY
                  )}
                  selectedColumns={selectedLiabilityColumns}
                  setSelectedColumns={setSelectedLiabilityColumns}
                  type={CORE_TYPES.LIABILITY}
                  defaultSelectedColumns={defaultColumns.liability}
                  allDefaultColumns={defaultColumns}
                  setDefaultColumns={setDefaultColumns}
                />
              )}
              {!!liabilityColumns.length && (
                <CustomTable
                  rowKey="_id"
                  scroll={{ x: true }}
                  dataSource={sourceLiabilities}
                  columns={[
                    ...(isSmUp
                      ? historicalView
                        ? liabilityValuationColumns(baseCurrency)
                        : liabilityColumns
                      : assetLiabilityColumnsSmScreen(baseCurrency)),
                    ...actionsColumn
                  ]}
                  loading={isLoading}
                  onRow={record => ({
                    style:
                      record.includeValueInNetWorth === false
                        ? { fontStyle: 'italic' }
                        : {},
                    onClick: () => handleClick(record)
                  })}
                  pagination={sourceLiabilities.length > 10 && { pageSize: 10 }}
                  size={isSmUp ? '' : 'small'}
                />
              )}
              {renderTotalValue(sourceLiabilities, t('TOTAL'))}
            </>
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('NO_LIABILITIES')}
            />
          )}
          {!!pendingLiabilities?.length &&
            accessLevel === ACCESS_LEVEL.NEED_APPROVAL && (
              <>
                <Divider type="horizontal" />
                <H3> {t('PENDING_LIABILITIES')} </H3>
                <CustomTable
                  className="tour-created-asset"
                  rowKey="_id"
                  scroll={{ x: true }}
                  dataSource={pendingLiabilities}
                  columns={[
                    ...(isSmUp
                      ? historicalView
                        ? liabilityValuationColumns(baseCurrency)
                        : liabilityColumns
                      : assetLiabilityColumnsSmScreen(baseCurrency)),
                    ...actionsColumn
                  ]}
                  loading={isLoading}
                  onRow={record => ({
                    style: {
                      fontStyle:
                        record.includeValueInNetWorth === false && 'italic',
                      fontWeight:
                        registryDate?.isSame(record.validFrom) && 'bold'
                    },
                    onClick: () => handleClick(record, false)
                  })}
                  pagination={
                    pendingLiabilities.length > 10 && { pageSize: 10 }
                  }
                  size={isSmUp ? '' : 'small'}
                />
              </>
            )}
          {!!rejectedLiabilities?.length &&
            ((isReadonly && isDelegateByPD) || isProfessionalDeputy) &&
            accessLevel === ACCESS_LEVEL.NEED_APPROVAL && (
              <>
                <Divider type="horizontal" />
                <H3> {t('REJECTED_LIABILITIES')} </H3>
                <CustomTable
                  className="tour-created-asset"
                  rowKey="_id"
                  scroll={{ x: true }}
                  dataSource={rejectedLiabilities}
                  columns={[
                    ...(isSmUp
                      ? liabilityColumns
                      : assetLiabilityColumnsSmScreen(baseCurrency)),
                    ...rejectedReasonColumn,
                    ...actionsColumn
                  ]}
                  loading={isLoading}
                  onRow={record => ({
                    style: {
                      fontStyle:
                        record.includeValueInNetWorth === false && 'italic',
                      fontWeight:
                        registryDate?.isSame(record.validFrom) && 'bold'
                    },
                    onClick: () => handleClick(record, false)
                  })}
                  pagination={rejectedAssets.length > 10 && { pageSize: 10 }}
                  size={isSmUp ? '' : 'small'}
                />
              </>
            )}
        </TabPane>
        <TabPane tab={t('BALANCE_SHEET')} key="Balance sheet">
          <BalanceSheet
            sourceAssets={sourceAssets}
            sourceLiabilities={sourceLiabilities}
            sourceAssetsLiabilities={sourceAssetsLiabilities}
            registryDate={registryDate}
            renderTotalValue={renderTotalValue}
            getTotalValueInBaseCurrency={getTotalValueInBaseCurrency}
          />
        </TabPane>
        <TabPane key="Group" tab={t('GROUP')}>
          <div className="table-actions">
            <Input.Search
              placeholder={t('ENTER_THE_SEARCH_KEYWORD')}
              allowClear
              onSearch={value => searchAssetLiability(value)}
              className="search-asset-liability-input"
            />

            <div className="group-by-selection">
              <H5 display="inline-block">{t('GROUP_BY')}:</H5>{' '}
              <Select
                style={{ width: 100 }}
                onChange={value => setIsGroupByCurrency(value === 'Currency')}
                value={isGroupByCurrency ? t('CURRENCY') : t('LINKS')}
              >
                <Select.Option value="Currency">{t('CURRENCY')}</Select.Option>
                <Select.Option value="Links">{t('LINKS')}</Select.Option>
              </Select>
            </div>
            <div className="actions-group">
              {((!isReadonly && !historicalView) ||
                (((isReadonly && isDelegateByPD) || isProfessionalDeputy) &&
                  accessLevel &&
                  accessLevel !== ACCESS_LEVEL.DENY)) && (
                <Button
                  icon="plus"
                  size={isSmUp ? 'default' : 'small'}
                  onClick={() =>
                    history.push(`/assets-liabilities/add/${userId}`)
                  }
                >
                  {isXsUp ? t('CREATE_NEW') : t('CREATE')}
                </Button>
              )}
              <ExportDropdown
                fileName="Assets-Liabilities"
                columns={[
                  ...(historicalView
                    ? assetLiabilityValuationExportColumns(baseCurrency)
                    : assetLiabilityColumns.map(col => {
                        return col.key === 'tags'
                          ? { ...col, render: items => items?.join(', ') }
                          : col
                      })),
                  ...currencyColumns(null, baseCurrency)
                ]}
                source={sourceAssetsLiabilities}
                selectedColumns={assetLiabilityColumns}
              />
            </div>
          </div>
          {sourceAssetsLiabilities.length ? (
            <>
              {!historicalView && isSmUp && (
                <AssetLiabilityShowHideColumns
                  allColumns={getAllColumns(
                    sourceAssetsLiabilities,
                    DEFAULT_ASSET_LIABILITY_COLUMNS
                  )}
                  selectedColumns={selectedAssetLiabilityColumns}
                  setSelectedColumns={setSelectedAssetLiabilityColumns}
                  defaultSelectedColumns={defaultColumns.group}
                  allDefaultColumns={defaultColumns}
                  setDefaultColumns={setDefaultColumns}
                />
              )}
              {!!assetLiabilityColumns.length && (
                <Table
                  size={isSmUp ? '' : 'small'}
                  scroll={{ x: true }}
                  rowKey={isGroupByCurrency ? i => i.currency : '_id'}
                  columns={
                    isGroupByCurrency
                      ? groupedByCurrencyColumns
                      : isSmUp
                      ? assetLiabilityColumns
                      : assetLiabilityColumnsSmScreen(baseCurrency)
                  }
                  dataSource={
                    isGroupByCurrency
                      ? sourceGroupedByCurrency.filter(k => k.currency)
                      : sourceGroupedByLinks
                  }
                  expandedRowRender={e => (
                    <CustomTable
                      // keep the inner table at middle size,
                      // because the small one has ugly borders
                      size="middle"
                      rowKey="_id"
                      columns={[
                        ...(isSmUp
                          ? historicalView
                            ? assetLiabilityValuationColumns(baseCurrency)
                            : assetLiabilityColumns
                          : assetLiabilityColumnsSmScreen(baseCurrency)),
                        ...actionsColumn
                      ]}
                      onRow={record => ({
                        style:
                          record.includeValueInNetWorth === false
                            ? { fontStyle: 'italic' }
                            : {}
                      })}
                      pagination={false}
                      dataSource={e.dataByKey}
                    />
                  )}
                  onRow={record => ({
                    style:
                      record.includeValueInNetWorth === false
                        ? { fontStyle: 'italic' }
                        : {}
                  })}
                  pagination={
                    (isGroupByCurrency
                      ? sourceGroupedByCurrency
                      : sourceGroupedByLinks
                    ).length > 10 && { pageSize: 10 }
                  }
                />
              )}
              {renderTotalValue(sourceAssetsLiabilities, t('NET_WORTH'))}
            </>
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('NO_ASSETS_LIABILITIES')}
            />
          )}
        </TabPane>
        {/* {!isReadonly && ( */}
        <TabPane tab={t('REPORTS')} key="Reports">
          <div style={{ textAlign: 'right' }}>
            {!isReadonly && (
              <>
                <Button onClick={() => setPieChartColorModalVisible(true)}>
                  {t('COLORS_CUSTOMIZATION')}
                </Button>
                <Divider type="vertical" />
              </>
            )}

            <Button
              onClick={() => {
                try {
                  exportDomToPng(
                    chartRef.current,
                    theme.white,
                    'Vaultbox - Net worth'
                  )
                } catch (err) {
                  message.error(t('FAILED_TO_EXPORT_TO_PNG'))
                }
              }}
            >
              {t('EXPORT_TO_PNG')}
            </Button>
          </div>
          <div ref={chartRef}>
            <NetworthChart chartData={chartData} />
            <Row style={{ marginTop: 16 }}>
              <Col xxl={12}>
                <NetworthByTypePieCharts type={CORE_TYPES.ASSET} />
              </Col>
              <Col xxl={12}>
                <NetworthByTypePieCharts type={CORE_TYPES.LIABILITY} />
              </Col>
            </Row>
          </div>
        </TabPane>
        {/* )} */}
      </Tabs>
      <AssetLiabilityBackDatedAmend
        record={amendedRecord}
        visible={amendModalVisible}
        setVisible={setAmendModalVisible}
        registryDate={registryDate?.clone()}
      />
      <RejectModal
        wrappedComponentRef={fr => (rejectFormRef = fr)}
        visible={rejectModalVisible}
        handleOk={handleRejectRecord}
        handleCancel={() => setRejectModalVisible(false)}
        rejecting={rejecting}
      />
      <ImportExcelModal
        visible={importExcelModalVisible}
        setVisible={setImportExcelModalVisible}
        sourceAssetsLiabilities={sourceAssetsLiabilities}
      />
      <PieChartColorModal
        visible={pieChartColorModalVisible}
        setVisible={setPieChartColorModalVisible}
      />
    </div>
  )
}

export default withRouter(AssetsLiabilities)
