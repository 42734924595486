import React, { useContext } from 'react'
import { Dropdown, Icon, Menu } from 'antd'
import Button from '../override/Button'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { ThemeContext } from 'styled-components'
import { useTranslation } from 'react-i18next'

export default function ExportDropdown({
  setImportModalVisible,
  setImportExcelModalVisible
}) {
  const theme = useContext(ThemeContext)
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'))
  const { t } = useTranslation()

  return (
    <Dropdown
      trigger={['hover', 'click']}
      overlay={
        <Menu>
          <Menu>
            <Menu.Item onClick={() => setImportModalVisible(true)}>
              {t('FROM_CSV')}
            </Menu.Item>
            <Menu.Item onClick={() => setImportExcelModalVisible(true)}>
              {t('FROM_EXCEL')}
            </Menu.Item>
          </Menu>
        </Menu>
      }
    >
      <Button size={isSmUp ? 'default' : 'small'}>
        {t('IMPORT')} <Icon type="down" />
      </Button>
    </Dropdown>
  )
}
