import React from 'react'
//import { StringResources } from './StringResources'
import { ID_TYPES } from './Constants'
import { debounce, last } from 'lodash'
import { Trans } from 'react-i18next'
import i18next from 'i18next'

const compareToPassword = (rule, value, callback, form) => {
  if (value && value !== form.getFieldValue('password')) {
    debounce(
      callback,
      800
    )(<Trans i18nKey="PASSWORDS_DO_NOT_MATCH_MSG"></Trans>)
  } else {
    debounce(callback, 800)()
  }
}

const validateToConfirmPassword = (
  rule,
  value,
  callback,
  form,
  confirmDirty
) => {
  if (value && confirmDirty) {
    form.validateFields(['confirm'], { force: true })
  }
  callback()
}

const compareToOldPassword = (rule, value, callback, form) => {
  if (value && value === form.getFieldValue('currentPwd')) {
    callback(<Trans i18nKey="SAME_PASSWORD_MSG"></Trans>)
  } else {
    callback()
  }
}

const validateInputCode = (rule, value, callback) => {
  // Code must be 6-digit number
  const codeRegex = /^[0-9]{1,6}$/
  if (value && !value.match(codeRegex)) {
    callback(<Trans i18nKey="INVALID_CODE_TYPE"></Trans>)
  } else {
    callback()
  }
}

const validatePassword = (rule, value, callback) => {
  // Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character:
  const passwordReg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
  if (value && !value.match(passwordReg)) {
    callback(<Trans i18nKey="PASSWORD_RULES"></Trans>)
  } else {
    callback()
  }
}

const validateGovernmentId = (idType, value) => {
  const multiples = [2, 7, 6, 5, 4, 3, 2]
  if (!value || value === '' || value.length !== 9) {
    return false
  }

  let total = 0,
    count = 0,
    numericPart

  let first = value[0],
    last = value[value.length - 1]

  numericPart = value.slice(1, value.length - 1)

  if (isNaN(numericPart)) {
    return false
  }

  let outputs
  if (idType === ID_TYPES.NRIC) {
    if (!['S', 'T'].includes(first)) return false

    outputs =
      first === 'S'
        ? ['J', 'Z', 'I', 'H', 'G', 'F', 'E', 'D', 'C', 'B', 'A']
        : ['G', 'F', 'E', 'D', 'C', 'B', 'A', 'J', 'Z', 'I', 'H']
  } else if (idType === ID_TYPES.FIN) {
    if (!['F', 'G'].includes(first)) return false

    outputs =
      first === 'F'
        ? ['X', 'W', 'U', 'T', 'R', 'Q', 'P', 'N', 'M', 'L', 'K']
        : ['R', 'Q', 'P', 'N', 'M', 'L', 'K', 'X', 'W', 'U', 'T']
  }

  while (numericPart !== 0) {
    total += (numericPart % 10) * multiples[multiples.length - (1 + count++)]
    numericPart /= 10
    numericPart = Math.floor(numericPart)
  }

  return last === outputs[total % 11]
}

const validateNRICNumber = (rule, value, callback) => {
  if (value && !validateGovernmentId(ID_TYPES.NRIC, value)) {
    callback(<Trans i18nKey="NRIC_IS_INVALID"></Trans>)
  } else {
    callback()
  }
}

const validateFINNumber = (rule, value, callback) => {
  if (value && !validateGovernmentId(ID_TYPES.FIN, value)) {
    callback(<Trans i18nKey="FIN_IS_INVALID"></Trans>)
  } else {
    callback()
  }
}

const validateMinValue = (min, value, callback) => {
  if (value && value <= min) {
    callback(
      <Trans i18nKey="VALUE_MUST_BE_GREATER_THAN" values={{ min }}></Trans>
    )
  } else {
    callback()
  }
}

const validateFilename = (rule, value, callback, activeFiles, destination) => {
  if (
    activeFiles.find(af => af.fileName === value && af.path === destination)
  ) {
    callback(<Trans i18nKey="FILE_NAME_ALREADY_EXISTS"></Trans>)
  } else {
    callback()
  }
}

const validateFilenames = (rule, values, callback) => {
  if (!!values?.length) {
    callback(
      `${i18next.t('FILE_NAMES')} (${values.join(', ')}) ${i18next.t(
        'ALREADY_EXISTS_IN_THE_CURRENT_FOLDER'
      )}`
    )
  } else {
    callback()
  }
}

const compareWithCurrentUserEmail = (
  rule,
  value,
  callback,
  currentUserEmail
) => {
  if (value && value === currentUserEmail) {
    callback(<Trans i18nKey="ENTER_ANOTHER_EAMIL_NOT_YOUR_OWN_ONE"></Trans>)
  } else {
    callback()
  }
}

const compareWithProfessionalDeputyEmails = (
  rule,
  value,
  callback,
  defaultEmails
) => {
  if (value && defaultEmails.find(d => d === value)) {
    callback(
      <Trans i18nKey="ENTER_AN_EMAIL_WHICH_IS_DEFFETENT_FROM_PD_EMAIL"></Trans>
    )
  } else {
    callback()
  }
}

const getFileParts = filename => {
  const parts = filename.split('.')
  return parts.length > 1
    ? { name: parts.slice(0, -1).join('.'), extension: last(parts) }
    : { name: filename, extension: '' }
}

const getFullFilename = (name, extension, postfix?) => {
  const fullname = postfix ? `${name}-${postfix}` : name
  return extension ? `${fullname}.${extension}` : fullname
}

const draggerProps = {
  beforeUpload: () => {
    // this is for preventing the upload action, so we can customize the upload flow and upload to S3 later when the form is saved
    return false
  }
}

const normFile = e => {
  // Allow uploading only a single file at once (the last selected file)
  if (Array.isArray(e)) {
    return e.slice(-1)
  }
  return e && e.fileList.slice(-1)
}

export {
  compareToPassword,
  validateToConfirmPassword,
  validateInputCode,
  validatePassword,
  validateNRICNumber,
  validateFINNumber,
  validateMinValue,
  compareToOldPassword,
  validateFilename,
  compareWithCurrentUserEmail,
  compareWithProfessionalDeputyEmails,
  getFileParts,
  getFullFilename,
  validateFilenames,
  draggerProps,
  normFile
}
