import React, { useState, useContext, useEffect, useRef } from 'react'
import { withRouter } from 'react-router-dom'
import {
  Layout,
  Modal,
  message,
  Icon,
  Dropdown,
  Menu,
  Radio,
  Divider,
  Select,
  PageHeader,
  Button as Btn
} from 'antd'
import { H3, H4 } from '../override/Typography'
import Button from '../../components/override/Button'
import { useTranslation } from 'react-i18next'
import { Calendar, Badge, Empty } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import VaultContext from '../../contexts/VaultContext'
import { onError } from '../../lib/sentry'
import AddEventModal from './AddEventModal'
import EventDetails from './EventDetails'
import EventList from './EventList'
import {
  fetchEvents,
  fetchPendingEvents
} from './../../features/events/eventsSlice'
import ExportDropdown from '../assets-liabilities/ExportDropdown'
import './Calendar.scss'
import moment from 'moment'
import {
  permanentlyDeleteItems,
  uploadEncryptedData,
  getRecords,
  getRecord
} from '../../lib/pouchDb'
import { ACCESS_LEVEL, DATE_FORMAT, RECURRING } from '../../share/Constants'
import PouchDB from 'pouchdb'
import api from '../../lib/api'
import '../common/Common.scss'
import EditDeleteEventTypeModal from '../modals/EditDeleteEventTypeModal'
import { EDIT_DELETE_EVENT_TYPE } from './../../share/Constants'
import { uniq } from 'lodash'
import {
  eventColumns,
  unlinkEventFromDbs
} from './eventHelpers'
import { ThemeContext } from 'styled-components'
import { useMutation } from 'react-apollo-hooks'
import { createS3Change } from '../../graphql/mutations'
import { exportHTMLToPng } from '../../share/helpers'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import AuthContext from '../../contexts/AuthContext'
import CustomTable from '../override/CustomTable'
import { fetchOtherPendingEvents } from '../../features/events/otherEventsSlice'
import RejectModal from '../modals/RejectModal'

const { Content } = Layout

function CalendarComponent() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const theme = useContext(ThemeContext)
  const { userId, masterKey, isReadonly, fullName } = useContext(VaultContext)
  const { isDelegateByPD, isProfessionalDeputy } = useContext(AuthContext)
  const { activeEvents, pendingEvents, rejectedEvents } = useSelector(state =>
    isReadonly ? state.otherEvents : state.events
  )
  const { activeContacts } = useSelector(state =>
    isReadonly ? state.otherContacts : state.contacts
  )
  const { activeAssetsLiabilities } = useSelector(
    state =>
      isReadonly ? state.otherAssetsLiabilities : state.assetsLiabilities
  )
  const { activePasswords } = useSelector(state =>
    isReadonly ? state.otherPasswords : state.passwords
  )
  const { activeFiles } = useSelector(
    state => (isReadonly ? state.otherDocuments : state.documents)
  )
  const { accessLevel } = useSelector(state => state.settings)
  const [isListModalVisible, setIsListModalVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isEventModalVisible, setIsEventModalVisible] = useState(false)
  const [isDetailEventModal, setIsDetailEventModal] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [viewMode, setViewMode] = useState('grid')
  const [selectedValue, setSelectedValue] = useState('')
  const [eventDetails, setEventDetails] = useState({})
  const [changeMonth, setChangeMonth] = useState(0)
  const [isPopUpScroll, setIsPopUpScroll] = useState(false)
  const [data, setData] = useState([])
  const [pendingData, setPendingData] = useState([])
  const [rejectedData, setRejectedData] = useState([])
  const [deleteTypeVisible, setDeleteTypeVisible] = useState(false)
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
  const [type, setType] = useState()
  const [small, setSmall] = useState(false)
  const [size, setSize] = useState('default')
  const [rejectModalVisible, setRejectModalVisible] = useState(false)
  const [rejecting, setRejecting] = useState(false)
  const [rejectRecord, setRejectRecord] = useState({})

  const calendarRef = useRef()
  const [modeCalendar, setModeCalendar] = useState('month')

  const [addS3Change] = useMutation(createS3Change)

  let rejectFormRef

  useEffect(() => {
    dispatch(fetchEvents(userId, masterKey))
  }, [userId, masterKey, dispatch])

  useEffect(() => {
    if (!isMdUp) {
      setSmall(true)
      setSize('small')
    } else {
      setSmall(false)
      setSize('default')
    }
  }, [isMdUp])

  const handleClose = () => {
    setIsListModalVisible(false)
    setIsEventModalVisible(false)
    setIsEditMode(false)
    setEventDetails({})
    setData([])
    setPendingData([])
    setRejectedData([])
    setType()
  }

  const showDetailEventModal = record => {
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
    }, 1500)
    setEventDetails(record)
    setIsDetailEventModal(true)
  }

  const showEventsByDate = value => {
    setData(getEventByDate(value).filter(event => !event.status))
    setPendingData(
      getEventByDate(value).filter(event => event.status === 'Draft')
    )
    setRejectedData(
      getEventByDate(value).filter(event => event.status === 'Rejected')
    )
    setIsListModalVisible(true)
  }

  const showEventsByMonth = value => {
    setData(getEventByMonth(value).filter(event => !event.status))
    setPendingData(
      getEventByMonth(value).filter(event => event.status === 'Draft')
    )
    setRejectedData(
      getEventByMonth(value).filter(event => event.status === 'Rejected')
    )
    setIsListModalVisible(true)
  }

  const onPanelChange = (value, mode) => {
    setModeCalendar(mode)
  }

  const onSelect = value => {
    setSelectedValue(value)
    if (modeCalendar === 'month') {
      if (
        !isReadonly ||
        (((isReadonly && isDelegateByPD) || isProfessionalDeputy) &&
          accessLevel === ACCESS_LEVEL.NEED_APPROVAL)
      ) {
        getEventByDate(value).length > 0
          ? showEventsByDate(value)
          : showAddEventModal()
      } else {
        showEventsByDate(value)
      }
    } else {
      if (
        !isReadonly ||
        (((isReadonly && isDelegateByPD) || isProfessionalDeputy) &&
          accessLevel === ACCESS_LEVEL.NEED_APPROVAL)
      ) {
        getEventByMonth(value).length > 0
          ? showEventsByMonth(value)
          : showAddEventModal()
      } else {
        showEventsByMonth(value)
      }
    }
  }

  const showAddEventModal = () => {
    setIsEventModalVisible(true)
    setIsListModalVisible(false)
  }

  const handleReturn = () => {
    setIsEventModalVisible(false)
    setIsListModalVisible(true)
    setIsEditMode(false)
  }

  const getEventByDate = date => {
    return isReadonly
      ? [...activeEvents, ...pendingEvents, ...rejectedEvents].filter(
          item =>
            moment(item.date).format(DATE_FORMAT) ===
            moment(date).format(DATE_FORMAT)
        )
      : [...activeEvents, ...pendingEvents].filter(
          item =>
            moment(item.date).format(DATE_FORMAT) ===
            moment(date).format(DATE_FORMAT)
        )
  }

  const dateCellRender = value => {
    const listData = getEventByDate(value)

    return (
      <ul className="events" onClick={() => showEventsByDate(value)}>
        {listData.map(item => (
          <li key={`${item.id}_${item.date}`}>
            {item.status ? (
              <i>
                <Badge color={item.color || 'red'} text={item.description} />
              </i>
            ) : (
              <Badge color={item.color || 'red'} text={item.description} />
            )}
          </li>
        ))}
      </ul>
    )
  }

  const getEventByMonth = value => {
    return isReadonly
      ? [...activeEvents, ...pendingEvents, ...rejectedEvents].filter(
          item =>
            moment(item.date).month() === value.month() &&
            moment(item.date).year() === value.year()
        )
      : [...activeEvents, ...pendingEvents].filter(
          item =>
            moment(item.date).month() === value.month() &&
            moment(item.date).year() === value.year()
        )
  }

  const monthCellRender = value => {
    const listData = getEventByMonth(value)

    return (
      <ul className="events" onClick={() => showEventsByMonth(value)}>
        {listData.map(item => (
          <li key={item.id.concat(item.date)}>
            {item.status ? (
              <i>
                <Badge color={item.color || 'red'} text={item.description} />
              </i>
            ) : (
              <Badge color={item.color || 'red'} text={item.description} />
            )}
          </li>
        ))}
      </ul>
    )
  }

  const handleDeleteItem = item => {
    Modal.confirm({
      title: t('CONFIRM_DELETE'),
      content: <>{t('CONFIRM_DELETE_ITEM_MSG')}</>,
      async onOk() {
        if (item.recurringValue === RECURRING.NONE) {
          deleteItems(item)
        } else {
          setEventDetails(item)
          setDeleteTypeVisible(true)
        }
      },
      onCancel() {}
    })
  }

  const deleteItems = async (item, type = null) => {
    try {
      const response = await api.getEvents(userId)
      const lstEvents = response.data?.events || []
      let newReminderEvents
      if (!type) {
        newReminderEvents = lstEvents.filter(event => event.id !== item._id)
      } else {
        if (type === EDIT_DELETE_EVENT_TYPE.THIS_EVENT) {
          newReminderEvents = lstEvents.filter(
            e => e.id !== item._id || e.date !== item.date
          )
        } else if (type === EDIT_DELETE_EVENT_TYPE.THIS_AND_FOLLOWING_EVENTS) {
          newReminderEvents = lstEvents.filter(
            e => e.id !== item._id || e.date < item.date
          )
        } else {
          newReminderEvents = lstEvents.filter(e =>
            item.parentId
              ? e.id !== item.parentId &&
                e.parentId !== item.parentId &&
                e.id !== item._id &&
                e.parentId !== item._id
              : e.id !== item._id && e.parentId !== item._id
          )
        }
      }

      await api.updateEvents(
        userId,
        JSON.stringify({
          events: newReminderEvents
        })
      )

      //get events from PouchDB
      if (type === EDIT_DELETE_EVENT_TYPE.ALL_EVENTS || !type) {
        const events = await getRecords(
          userId,
          isReadonly ? 'pendingEvents' : 'events',
          masterKey
        )
        const deletedItems =
          events?.filter(
            e => !uniq(newReminderEvents.map(ne => ne.id)).includes(e._id)
          ) || []

        if (deletedItems?.length) {
          await permanentlyDeleteItems(
            isReadonly ? 'pendingEvents' : 'events',
            userId,
            deletedItems,
            masterKey
          )

          await Promise.all(
            deletedItems.map(async item => {
              await unlinkEventFromDbs(item, userId, masterKey)
            })
          )

          localStorage.setItem('NotReload', true)
          addS3Change({
            variables: {
              message:
                'assetsLiabilities, pendingAssetsLiabilities, documents, pendingDocuments, contacts, pendingContacts, events, pendingEvents, passwords, pendingPasswords',
              userId: userId
            }
          })
        }
      }
      if (isProfessionalDeputy || (isDelegateByPD && isReadonly)) {
        dispatch(fetchOtherPendingEvents(userId, masterKey))
      }
      message.success(t('SUCCESSFULLY_DELETED'))
      dispatch(fetchEvents(userId, masterKey))
      setIsListModalVisible(false)
      setIsDetailEventModal(false)
      setEventDetails({})
      setData([])
    } catch (err) {
      message.error(t('FAILED_TO_DELETE_ITEM'))
      onError(err)
    }
  }

  const handleEditItem = item => {
    setEventDetails(item)
    if (item.recurringValue === RECURRING.NONE) {
      showAddEventModal()
    } else {
      setDeleteTypeVisible(true)
    }

    setIsEditMode(true)
  }

  const handleEventRequest = async (record, isApproved = false) => {
    try {
      const eventRecord = record.recurringTimes
        ? await getRecord(userId, 'pendingEvents', record._id, masterKey)
        : record

      if (isApproved) {
        const db = new PouchDB(`${userId}_events`)
        db.crypto(masterKey)
        const newRecord = {
          ...eventRecord,
          status: undefined
        }
        delete newRecord._rev
        await db.put(newRecord)
        await uploadEncryptedData(db, userId, 'events')

        //remove pending password
        const pendingDb = new PouchDB(`${userId}_pendingEvents`)
        const deletedRecord = {
          ...eventRecord,
          deleted: true
        }

        pendingDb.crypto(masterKey)
        await pendingDb.put(deletedRecord)
        const deletedRecords = await getRecords(
          userId,
          'pendingEvents',
          masterKey,
          {
            startkey: eventRecord._id,
            endkey: `${eventRecord._id}\ufff0`
          }
        )

        await permanentlyDeleteItems(
          'pendingEvents',
          userId,
          deletedRecords,
          masterKey
        )

        await api.handleAddRecordRequest(
          JSON.stringify({
            isApproved,
            primaryUserId: userId,
            fullname: fullName,
            recordType: 'event'
          })
        )

        localStorage.setItem('NotReload', true)
        addS3Change({
          variables: {
            message: 'events, pendingEvents',
            userId: userId
          }
        })
        handleClose()
        dispatch(fetchPendingEvents(userId, masterKey))
        dispatch(fetchEvents(userId, masterKey))
      } else {
        rejectFormRef.props.form.resetFields()
        setRejectModalVisible(true)
        setRejectRecord(eventRecord)
      }
    } catch (error) {
      message.error(
        isApproved
          ? t('FAILED_TO_APPROVE_REQUEST')
          : t('FAILED_TO_REJECT_REQUEST')
      )
    }
  }

  const handleRejectRecord = () => {
    setRejecting(true)

    rejectFormRef.props.form.validateFields(async (err, values) => {
      try {
        if (err) {
          setRejecting(false)
          return
        }

        await unlinkEventFromDbs(rejectRecord, userId, masterKey)

        const pendingDb = new PouchDB(`${userId}_pendingEvents`)
        pendingDb.crypto(masterKey)
        const newRecord = {
          ...rejectRecord,
          assetsLiabilities: [],
          documents: [],
          passwords: [],
          contacts: [],
          status: 'Rejected',
          reasonReject: values.reasonReject
        }
        await pendingDb.put(newRecord)
        await uploadEncryptedData(pendingDb, userId, 'pendingEvents')

        await api.handleAddRecordRequest(
          JSON.stringify({
            isApproved: false,
            primaryUserId: userId,
            fullname: fullName,
            recordType: 'password'
          })
        )

        setRejecting(false)
        setRejectModalVisible(false)
        dispatch(fetchEvents(userId, masterKey))
        dispatch(fetchPendingEvents(userId, masterKey))
        handleClose()
        localStorage.setItem('NotReload', true)
        addS3Change({
          variables: {
            message:
              'assetsLiabilities, pendingAssetsLiabilities, documents, pendingDocuments, events, pendingEvents, passwords, pendingPasswords, contacts, pendingContacts',
            userId: userId
          }
        })
      } catch (error) {
        setRejecting(false)
        onError(error)
        message.error(t('FAILED_TO_REJECT_REQUEST'))
      }
    })
  }

  const columns = [
    {
      key: 'description',
      dataIndex: 'description',
      title: <span className="dragHandler">{t('DESCRIPTION')}</span>,
      render: (text, record) => (
        <span
          className="item-name"
          onClick={() => showDetailEventModal(record)}
        >
          {record.description}
        </span>
      )
    },
    {
      key: 'date',
      dataIndex: 'date',
      title: <span className="dragHandler">{t('DATE')}</span>,
      render: (text, record) => (
        <span>{moment(record.date).format(DATE_FORMAT)}</span>
      )
    }
  ]

  const rejectColumn = [
    {
      key: 'reasonReject',
      width: 200,
      dataIndex: 'reasonReject',
      title: <span className="dragHandler">{t('REJECTED_REASON')}</span>,
      render: text => text
    }
  ]

  const actionColumn =
    isReadonly && !isDelegateByPD && !isProfessionalDeputy
      ? []
      : [
          {
            key: 'actions',
            width: 46,
            align: 'right',
            render: (text, record) => (
              <Dropdown
                overlay={
                  record.status === 'Draft' &&
                  !isProfessionalDeputy &&
                  (!isReadonly || !isDelegateByPD) ? (
                    <Menu>
                      <Menu.Item
                        style={{
                          textAlign: 'center',
                          display: 'block',
                          padding: '15px 25px'
                        }}
                        onClick={() => handleEventRequest(record, true)}
                      >
                        {t('APPROVE')}
                      </Menu.Item>
                      <Menu.Item
                        style={{
                          textAlign: 'center',
                          display: 'block',
                          padding: '15px 25px'
                        }}
                        onClick={() => handleEventRequest(record)}
                      >
                        {t('REJECT')}
                      </Menu.Item>
                    </Menu>
                  ) : !record.status &&
                    ((isDelegateByPD && isReadonly) || isProfessionalDeputy) ? (
                    <></>
                  ) : (
                    <Menu>
                      <Menu.Item
                        style={{
                          textAlign: 'center',
                          display: 'block',
                          padding: '15px 25px'
                        }}
                        onClick={() => handleEditItem(record)}
                      >
                        {t('EDIT')}
                      </Menu.Item>
                      <Menu.Item
                        style={{
                          textAlign: 'center',
                          display: 'block',
                          padding: '15px 25px'
                        }}
                        onClick={() => handleDeleteItem(record)}
                      >
                        {t('DELETE')}
                      </Menu.Item>
                    </Menu>
                  )
                }
                placement="bottomRight"
                trigger={['click']}
              >
                <Icon type="ellipsis" />
              </Dropdown>
            )
          }
        ]

  const onWheel = e => {
    if (
      viewMode === 'grid' &&
      !isListModalVisible &&
      !isEventModalVisible &&
      !isDetailEventModal &&
      !deleteTypeVisible &&
      !isPopUpScroll
    ) {
      setSelectedValue('')
      if (e.deltaY < 0) {
        setTimeout(() => {
          setChangeMonth(changeMonth - 1)
        }, 300)
      } else {
        setTimeout(() => {
          setChangeMonth(changeMonth + 1)
        }, 300)
      }
    }
  }

  const onChange = value => {
    setChangeMonth(value.endOf('day').diff(moment().endOf('day'), 'M', true))
  }

  const setDateValue = () => {
    if (selectedValue) {
      return selectedValue
    } else {
      return moment().add(changeMonth, 'M')
    }
  }

  return (
    <Layout
      style={{
        height: '100%',
        padding: '0 20px 20px',
        backgroundColor: '#fff'
      }}
    >
      <PageHeader
        style={{ padding: '10px 0' }}
        title={
          <H4 display="inline-block">
            {viewMode === 'grid' ? t('CALENDAR') : t('EVENTS')}
          </H4>
        }
        extra={
          <>
            {viewMode === 'list' && (
              <ExportDropdown
                style={{ height: 33 }}
                fileName="Events"
                columns={eventColumns(
                  activeContacts,
                  activeAssetsLiabilities,
                  activeFiles,
                  activePasswords
                )}
                source={activeEvents}
              />
            )}
            {viewMode === 'grid' && (
              <Btn
                size={size}
                onClick={() => {
                  try {
                    exportHTMLToPng(
                      calendarRef.current,
                      theme.white,
                      'Calendar'
                    )
                  } catch (err) {
                    message.error(t('FAILED_TO_EXPORT_TO_PNG'))
                  }
                }}
              >
                {t('EXPORT_TO_PNG')}
              </Btn>
            )}
            {!small && <Divider type="vertical" />}
            <Radio.Group
              size={viewMode === 'list' ? 'default' : size}
              defaultValue={viewMode}
              onChange={e => {
                setViewMode(e.target.value)
                setModeCalendar('month')
              }}
            >
              <Radio.Button value="grid">
                <Icon type="appstore" style={{ fontSize: 16 }} />
              </Radio.Button>
              <Radio.Button value="list">
                <Icon type="bars" style={{ fontSize: 16 }} />
              </Radio.Button>
            </Radio.Group>
            {viewMode === 'grid' && small && (
              <Btn
                size={size}
                onClick={() => {
                  setSelectedValue('')
                  const today = moment()
                  onChange(today)
                }}
              >
                {t('TODAY')}
              </Btn>
            )}
          </>
        }
      />
      <Content onWheel={onWheel}>
        {viewMode === 'grid' && (
          <div ref={calendarRef}>
            <Calendar
              style={{ maxHeight: 'calc(100vh - 160px)' }}
              monthCellRender={monthCellRender}
              dateCellRender={dateCellRender}
              onSelect={onSelect}
              onPanelChange={onPanelChange}
              onChange={onChange}
              value={setDateValue()}
              headerRender={({ value, type, onChange, onTypeChange }) => {
                const start = 0
                const end = 12
                const monthOptions = []

                const current = value.clone()
                const localeData = value.localeData()
                const months = []
                for (let i = 0; i < 12; i++) {
                  current.month(i)
                  months.push(localeData.monthsShort(current))
                }

                for (let index = start; index < end; index++) {
                  monthOptions.push(
                    <Select.Option className="month-item" key={`${index}`}>
                      {months[index]}
                    </Select.Option>
                  )
                }
                const month = value.month()

                const year = value.year()
                const options = []
                for (let i = year - 10; i < year + 10; i += 1) {
                  options.push(
                    <Select.Option key={i} value={i} className="year-item">
                      {i}
                    </Select.Option>
                  )
                }
                return (
                  <div className={small ? 'nav-small' : 'nav'}>
                    {!small && (
                      <>
                        <Btn
                          size={size}
                          onClick={() => {
                            setSelectedValue('')
                            const today = moment()
                            onChange(today)
                          }}
                        >
                          {t('TODAY')}
                        </Btn>
                        <Divider type="vertical" />
                      </>
                    )}
                    <Select
                      className={small ? 'nav-item' : ''}
                      size={size}
                      dropdownMatchSelectWidth={false}
                      onChange={newYear => {
                        setSelectedValue('')
                        const now = value.clone().year(newYear)
                        onChange(now)
                      }}
                      value={String(year)}
                      onDropdownVisibleChange={open => {
                        open ? setIsPopUpScroll(true) : setIsPopUpScroll(false)
                      }}
                    >
                      {options}
                    </Select>
                    {!small && <Divider type="vertical" />}
                    <Select
                      className={small ? 'nav-item' : ''}
                      size={size}
                      dropdownMatchSelectWidth={false}
                      value={String(month)}
                      onChange={selectedMonth => {
                        setSelectedValue('')
                        const newValue = value.clone()
                        newValue.month(parseInt(selectedMonth, 10))
                        onChange(newValue)
                      }}
                      onDropdownVisibleChange={open => {
                        open ? setIsPopUpScroll(true) : setIsPopUpScroll(false)
                      }}
                    >
                      {monthOptions}
                    </Select>
                    {!small && <Divider type="vertical" />}
                    <Radio.Group
                      className={small ? 'nav-item' : ''}
                      size={size}
                      onChange={e => onTypeChange(e.target.value)}
                      value={type}
                    >
                      <Radio.Button className="bigger" value="month">
                        {t('MONTH')}
                      </Radio.Button>
                      <Radio.Button className="bigger" value="year">
                        {t('YEAR')}
                      </Radio.Button>
                    </Radio.Group>
                  </div>
                )
              }}
            />
          </div>
        )}
        {viewMode === 'list' && (
          <EventList
            handleEditItem={handleEditItem}
            handleClose={handleClose}
            isLoading={isLoading}
            actionColumn={actionColumn}
            rejectColumn={rejectColumn}
            showDetailEventModal={showDetailEventModal}
            eventDetails={eventDetails}
          />
        )}
        <Modal
          title={t('EVENTS')}
          visible={isListModalVisible}
          onCancel={handleClose}
          footer={[
            <Button key="close" onClick={handleClose}>
              {t('CANCEL')}
            </Button>,
            (!isReadonly ||
              (((isReadonly && isDelegateByPD) || isProfessionalDeputy) &&
                accessLevel &&
                accessLevel !== ACCESS_LEVEL.DENY)) && (
              <Button
                key="add"
                type="primary"
                onClick={() => showAddEventModal()}
              >
                {t('ADD')}
              </Button>
            )
          ]}
        >
          {data?.length ? (
            <CustomTable
              rowKey={record => record._id.concat(record.date)}
              columns={[...columns, ...actionColumn]}
              dataSource={data}
            />
          ) : (
            <Empty />
          )}

          {!!pendingData?.length && accessLevel === ACCESS_LEVEL.NEED_APPROVAL && (
            <>
              <Divider type="horizontal" />
              <H3> {t('PENDING_EVENTS')} </H3>
              <CustomTable
                rowKey={record => record._id.concat(record.date)}
                scroll={{ x: true }}
                dataSource={pendingData}
                columns={[...columns, ...actionColumn]}
              />
            </>
          )}

          {!!rejectedData?.length &&
            ((isReadonly && isDelegateByPD) || isProfessionalDeputy) &&
            accessLevel === ACCESS_LEVEL.NEED_APPROVAL && (
              <>
                <Divider type="horizontal" />
                <H3> {t('REJECTED_EVENTS')} </H3>
                <CustomTable
                  rowKey={record => record._id.concat(record.date)}
                  scroll={{ x: true }}
                  dataSource={rejectedData}
                  columns={[...columns, ...rejectColumn, ...actionColumn]}
                />
              </>
            )}
        </Modal>

        {/* Event Details */}
        <EventDetails
          isLoading={isLoading}
          handleEditItem={handleEditItem}
          handleDeleteItem={handleDeleteItem}
          visible={isDetailEventModal}
          setVisible={setIsDetailEventModal}
          record={eventDetails}
          isViewMode={
            !isReadonly
              ? eventDetails.status
              : (isReadonly && isDelegateByPD) || isProfessionalDeputy
              ? !eventDetails.status
              : false
          }
        />

        {/* Add Modal */}
        <AddEventModal
          isEditMode={isEditMode}
          isFullModal={true}
          visible={isEventModalVisible}
          setVisible={setIsEventModalVisible}
          handleClose={handleClose}
          data={data}
          handleReturn={handleReturn}
          eventDetails={eventDetails}
          selectedValue={selectedValue}
          linkedEvents={[]}
          setLinkedEvents={() => {}}
          type={type}
        />
        <EditDeleteEventTypeModal
          visible={deleteTypeVisible}
          setVisible={setDeleteTypeVisible}
          handleOk={isEditMode ? showAddEventModal : deleteItems}
          eventDetails={eventDetails}
          isEditMode={isEditMode}
          type={type}
          setType={setType}
        />
        <RejectModal
          wrappedComponentRef={fr => (rejectFormRef = fr)}
          visible={rejectModalVisible}
          handleOk={handleRejectRecord}
          handleCancel={() => setRejectModalVisible(false)}
          rejecting={rejecting}
        />
      </Content>
    </Layout>
  )
}

export default withRouter(CalendarComponent)
