import React from 'react'
import { Breadcrumb, Icon, Menu, Dropdown } from 'antd'

const ResponsiveBreadCrumb = ({
  path,
  icon = 'folder',
  separator = '>',
  homeLink,
  navigateTo,
  suffix,
  linkStyle
}) => {
  const arr = path.split('/').slice(0, -1) // remove the empty string "" at the end of path

  const hiddenLinks = (
    <Menu>
      {[...arr].slice(1, -1).map((link, index) => (
        <Menu.Item key={index} onClick={e => navigateTo(link, e)}>
          <span className="text-ellipsis">
            <Icon type={icon} style={{ paddingRight: 8 }} />
            {link}
          </span>
        </Menu.Item>
      ))}
    </Menu>
  )

  return (
    <Breadcrumb separator={separator}>
      {homeLink}

      {arr?.length >= 5 ? (
        <>
          <Breadcrumb.Item href="" onClick={e => navigateTo(arr[0], e)}>
            <span style={linkStyle}>{arr[0]}</span>
          </Breadcrumb.Item>

          <Breadcrumb.Item>
            <Dropdown
              overlay={hiddenLinks}
              placement="bottomCenter"
              trigger={['click', 'hover']}
            >
              <Icon type="ellipsis" />
            </Dropdown>
          </Breadcrumb.Item>

          {[...arr].slice(-1).map((link, index) => (
            <Breadcrumb.Item
              href=""
              key={index}
              onClick={e => navigateTo(link, e)}
            >
              <span style={linkStyle}>{link}</span>
            </Breadcrumb.Item>
          ))}
        </>
      ) : (
        arr?.map((link, index) => (
          <Breadcrumb.Item
            href=""
            onClick={e => navigateTo(link, e)}
            key={index}
          >
            <span style={linkStyle}>{link}</span>
          </Breadcrumb.Item>
        ))
      )}

      {suffix}
    </Breadcrumb>
  )
}

export default ResponsiveBreadCrumb
