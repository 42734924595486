import React, { useContext, useState, useEffect } from 'react'
import {
  Modal,
  Form,
  Input,
  DatePicker,
  message,
  InputNumber,
  Select,
  List,
  Icon
} from 'antd'
import { ACCESS_LEVEL, RECURRING } from '../../share/Constants'
import FormItem from '../override/FormItem'
import AuthContext from '../../contexts/AuthContext'
import VaultContext from '../../contexts/VaultContext'
import Button from '../../components/override/Button'
import ContactSelect from '../common/ContactSelect'
import AssetLiabilitySelect from '../assets-liabilities/AssetLiabilitySelect'
import DocumentsLinksModal from '../assets-liabilities/DocumentsLinksModal'
import FileTags from '../file/FileTags'
import { useSelector, useDispatch } from 'react-redux'
import { DATE_FORMAT } from '../../share/Constants'
import { useTranslation } from 'react-i18next'
import api from '../../lib/api'
import uuidv4 from 'uuid/v4'
import PouchDB from 'pouchdb'
import moment from 'moment'
import {
  uploadEncryptedData,
  updateLinkItemsForEvent,
  getRecords,
  permanentlyDeleteItems
} from '../../lib/pouchDb'
import { onError } from '../../lib/sentry'
import { EDIT_DELETE_EVENT_TYPE } from './../../share/Constants'
import { uniq, isEqual } from 'lodash'
import { fetchEvents } from './../../features/events/eventsSlice'
// import { fetchLinkedEvents } from './../../features/events/linkedEventsSlice'
import { Span } from '../override/Typography'
import PasswordSelect from '../common/PasswordSelect'
import { useMutation } from 'react-apollo-hooks'
import { createS3Change } from '../../graphql/mutations'
import { CirclePicker } from 'react-color'
import SubscriptionModal from '../payment/SubscriptionModal'
import { showUpgradeSubscriptionPlanConfirm } from '../../share/helpers'
import {
  fetchOtherEvents,
  fetchOtherPendingEvents
} from '../../features/events/otherEventsSlice'

const { TextArea } = Input
const { Option } = Select

const AddEventModal = props => {
  const {
    //props from Calendar Component
    data,
    isEditMode,
    isFullModal,
    handleReturn,
    selectedValue,
    eventDetails,
    //props from all Component
    visible,
    setVisible,
    handleClose,
    linkedEvents,
    setLinkedEvents,
    eventDate,
    eventDescription,
    eventRecurringValue,
    type,
    form,
    //props from EventSelect Component
    onAddComplete
  } = props
  const [isSaving, setIsSaving] = useState(false)
  const [tags, setTags] = useState([])
  const [listEvent, setListEvent] = useState([])
  const [documentsLinksVisible, setDocumentsLinksVisible] = useState(false)
  const [linkedDocuments, setLinkedDocuments] = useState([])
  const [value, setValue] = useState()
  const [color, setColor] = useState('')
  const [subscriptionModalVisible, setSubscriptionModalVisible] = useState()

  const dispatch = useDispatch()
  const { isDelegateByPD, isProfessionalDeputy } = useContext(AuthContext)
  const { userId, masterKey, isReadonly, limitedRecord } =
    useContext(VaultContext)
  const { activeContacts, pendingContacts } = useSelector(state =>
    isReadonly ? state.otherContacts : state.contacts
  )
  const { activePasswords, pendingPasswords } = useSelector(state =>
    isReadonly ? state.otherPasswords : state.passwords
  )
  const { activeAssetsLiabilities, pendingAssetsLiabilities } = useSelector(
    state =>
      isReadonly ? state.otherAssetsLiabilities : state.assetsLiabilities
  )
  const { activeDocuments, pendingDocuments } = useSelector(state =>
    isReadonly ? state.otherDocuments : state.documents
  )
  const handleContactDeselect = id => {
    activeContacts.find(record => record._id === id)
  }
  const { limit } = useSelector(state => state.customer)
  const { accessLevel } = useSelector(state => state.settings)

  const { getFieldDecorator, getFieldValue, setFieldsValue } = form
  const { t } = useTranslation()

  const [addS3Change] = useMutation(createS3Change, {
    variables: {
      message:
        'events, pendingEvents, assetsLiabilities, pendingAssetsLiabilities, documents, pendingDocuments, contacts, pendingContacts, passwords, pendingPasswords',
      userId: userId
    }
  })

  useEffect(() => {
    setTags(eventDetails?.tags || [])
    setColor(eventDetails?.color || '#e91e63')
    setValue(
      eventRecurringValue
        ? eventRecurringValue
        : eventDetails?.recurringValue
        ? eventDetails.recurringValue
        : RECURRING.NONE
    )
  }, [eventDetails, eventRecurringValue, visible])

  useEffect(() => {
    const getEvents = async () => {
      try {
        const response = await api.getEvents(userId)
        if (response.data?.events) {
          setListEvent(response.data.events)
        }
      } catch (error) {
        onError(error)
      }
    }

    getEvents()
  }, [userId, visible])

  useEffect(() => {
    const fetchLinkedDocuments = async () => {
      try {
        const record = eventDetails ? eventDetails : {}
        const linkedDocuments =
          record.documents && record.documents.length
            ? [
                ...(await getRecords(userId, 'documents', masterKey)).filter(
                  c => record.documents?.includes(c._id)
                ),
                ...(
                  await getRecords(userId, 'pendingDocuments', masterKey)
                ).filter(c => record.documents?.includes(c._id))
              ]
            : []
        setLinkedDocuments(linkedDocuments)
      } catch (err) {
        onError(err)
      }
    }
    if (masterKey) {
      fetchLinkedDocuments()
    }
  }, [
    eventDetails,
    userId,
    masterKey,
    accessLevel,
    isDelegateByPD,
    isProfessionalDeputy,
    isReadonly
  ])

  const handelSaveEvent = () => {
    form.validateFields(async (err, values) => {
      if (err) {
        return
      }

      if (
        !!values.recurringTimes
          ? limitedRecord + values.recurringTimes > limit
          : limitedRecord >= limit
      ) {
        showUpgradeSubscriptionPlanConfirm(setSubscriptionModalVisible)
        return
      }

      try {
        setIsSaving(true)
        const db =
          ((isReadonly && isDelegateByPD) || isProfessionalDeputy) &&
          accessLevel === ACCESS_LEVEL.NEED_APPROVAL
            ? new PouchDB(`${userId}_pendingEvents`)
            : new PouchDB(`${userId}_events`)

        db.crypto(masterKey)
        const record = isEditMode
          ? await db.get(eventDetails._id)
          : {
              _id: uuidv4(),
              date: '',
              description: '',
              tags: [],
              contacts: [],
              assetsLiabilities: [],
              documents: [],
              passwords: [],
              reminderDays: values.reminderDays,
              recurringValue: '',
              recurringTimes: '',
              endDate: ''
            }

        let newRecord
        if (!type) {
          newRecord = {
            ...record,
            date: moment(values.date).startOf('day').valueOf(),
            description: values.description,
            tags: tags,
            contacts: values.contacts,
            assetsLiabilities: values.assetsLiabilities,
            documents: linkedDocuments.map(doc => doc._id),
            passwords: values.passwords,
            reminderDays: values.reminderDays,
            recurringValue: value,
            recurringTimes: values.recurringTimes,
            endDate: values.endDate,
            color,
            status:
              ((isReadonly && isDelegateByPD) || isProfessionalDeputy) &&
              accessLevel === ACCESS_LEVEL.NEED_APPROVAL
                ? 'Draft'
                : undefined
          }
        } else {
          newRecord = {
            ...record,
            date: moment(values.date).startOf('day').valueOf(),
            description: values.description,
            tags: tags,
            contacts: values.contacts,
            assetsLiabilities: values.assetsLiabilities,
            documents: linkedDocuments.map(doc => doc._id),
            passwords: values.passwords,
            reminderDays: values.reminderDays,
            recurringValue: value,
            recurringTimes: values.recurringTimes,
            endDate: values.endDate,
            color,
            _id:
              type === EDIT_DELETE_EVENT_TYPE.ALL_EVENTS
                ? record._id
                : uuidv4(),
            parentId:
              type !== EDIT_DELETE_EVENT_TYPE.ALL_EVENTS
                ? record.parentId
                  ? record.parentId
                  : record._id
                : null,
            status:
              ((isReadonly && isDelegateByPD) || isProfessionalDeputy) &&
              accessLevel === ACCESS_LEVEL.NEED_APPROVAL
                ? 'Draft'
                : undefined
          }

          if (type !== EDIT_DELETE_EVENT_TYPE.ALL_EVENTS) {
            delete newRecord._rev
          }
        }

        await db.put(newRecord)

        await uploadEncryptedData(
          db,
          userId,
          ((isReadonly && isDelegateByPD) || isProfessionalDeputy) &&
            accessLevel === ACCESS_LEVEL.NEED_APPROVAL
            ? 'pendingEvents'
            : 'events'
        )

        if (isProfessionalDeputy || (isDelegateByPD && isReadonly)) {
          if (accessLevel === ACCESS_LEVEL.NEED_APPROVAL) {
            await api.sendAddRecordNotification(
              JSON.stringify({
                userId,
                recordType: 'event'
              })
            )
            dispatch(fetchOtherPendingEvents(userId, masterKey))
          } else {
            dispatch(fetchOtherEvents(userId, masterKey))
          }
        }

        //update document
        const pendingDocumentIds =
          linkedDocuments
            .map(d => d._id)
            .filter(id => pendingDocuments.map(c => c._id).includes(id)) || []
        const activeDocumentIds =
          linkedDocuments
            .map(d => d._id)
            .filter(id => activeDocuments.map(pa => pa._id).includes(id)) || []

        if (
          (linkedDocuments &&
            !isEqual(
              linkedDocuments.map(doc => doc._id),
              record.documents
            )) ||
          type === EDIT_DELETE_EVENT_TYPE.ALL_EVENTS
        ) {
          if (
            (isProfessionalDeputy || (isDelegateByPD && isReadonly)) &&
            accessLevel === ACCESS_LEVEL.NEED_APPROVAL
          ) {
            await updateLinkItemsForEvent(
              pendingDocumentIds,
              record.documents.filter(id =>
                pendingDocuments.map(pa => pa._id).includes(id)
              ) || [],
              'pendingDocuments',
              userId,
              masterKey,
              newRecord,
              record.parentId
            )
          }

          await updateLinkItemsForEvent(
            activeDocumentIds,
            record.documents.filter(id =>
              pendingDocuments.map(pa => pa._id).includes(id)
            ) || [],
            'documents',
            userId,
            masterKey,
            newRecord,
            record.parentId
          )
        }

        //update contact
        const pendingContactIds =
          values.contacts?.filter(id =>
            pendingContacts.map(c => c._id).includes(id)
          ) || []
        const activeContactIds =
          values.contacts?.filter(id =>
            activeContacts.map(pa => pa._id).includes(id)
          ) || []

        if (
          ((values.contacts || []) &&
            !isEqual(values.contacts, record.contacts)) ||
          type === EDIT_DELETE_EVENT_TYPE.ALL_EVENTS
        ) {
          if (
            (isProfessionalDeputy || (isDelegateByPD && isReadonly)) &&
            accessLevel === ACCESS_LEVEL.NEED_APPROVAL
          ) {
            await updateLinkItemsForEvent(
              pendingContactIds,
              record.contacts.filter(id =>
                pendingContacts.map(pa => pa._id).includes(id)
              ) || [],
              'pendingContacts',
              userId,
              masterKey,
              newRecord,
              record.parentId
            )
          }

          await updateLinkItemsForEvent(
            activeContactIds,
            record.contacts.filter(id =>
              activeContacts.map(pa => pa._id).includes(id)
            ) || [],
            'contacts',
            userId,
            masterKey,
            newRecord,
            record.parentId
          )
        }

        //update assetsLiabilities
        const pendingALIds =
          values.assetsLiabilities?.filter(id =>
            pendingAssetsLiabilities.map(c => c._id).includes(id)
          ) || []
        const activeALIds =
          values.assetsLiabilities?.filter(id =>
            activeAssetsLiabilities.map(pa => pa._id).includes(id)
          ) || []

        if (
          ((values.assetsLiabilities || []) &&
            !isEqual(values.assetsLiabilities, record.assetsLiabilities)) ||
          type === EDIT_DELETE_EVENT_TYPE.ALL_EVENTS
        ) {
          if (
            (isProfessionalDeputy || (isDelegateByPD && isReadonly)) &&
            accessLevel === ACCESS_LEVEL.NEED_APPROVAL
          ) {
            await updateLinkItemsForEvent(
              pendingALIds,
              record.assetsLiabilities.filter(id =>
                pendingAssetsLiabilities.map(pa => pa._id).includes(id)
              ) || [],
              'pendingAssetsLiabilities',
              userId,
              masterKey,
              newRecord,
              record.parentId
            )
          }

          await updateLinkItemsForEvent(
            activeALIds,
            record.assetsLiabilities.filter(id =>
              activeAssetsLiabilities.map(pa => pa._id).includes(id)
            ) || [],
            'assetsLiabilities',
            userId,
            masterKey,
            newRecord,
            record.parentId
          )
        }

        //update passwords
        const pendingPasswordIds =
          values.passwords?.filter(id =>
            pendingPasswords.map(c => c._id).includes(id)
          ) || []
        const activePasswordIds =
          values.passwords?.filter(id =>
            activePasswords.map(pa => pa._id).includes(id)
          ) || []

        if (
          ((values.passwords || []) &&
            !isEqual(values.passwords, record.passwords)) ||
          type === EDIT_DELETE_EVENT_TYPE.ALL_EVENTS
        ) {
          if (
            (isProfessionalDeputy || (isDelegateByPD && isReadonly)) &&
            accessLevel === ACCESS_LEVEL.NEED_APPROVAL
          ) {
            await updateLinkItemsForEvent(
              pendingPasswordIds,
              record.passwords.filter(id =>
                pendingPasswords.map(pa => pa._id).includes(id)
              ) || [],
              'pendingPasswords',
              userId,
              masterKey,
              newRecord,
              record.parentId
            )
          }

          await updateLinkItemsForEvent(
            activePasswordIds,
            record.passwords.filter(id =>
              activePasswords.map(pa => pa._id).includes(id)
            ) || [],
            'passwords',
            userId,
            masterKey,
            newRecord,
            record.parentId
          )
        }

        let recurringEvents = []
        let i = 0
        while (recurringEvents.length < newRecord.recurringTimes) {
          const startDate = moment(newRecord.date)
          const dayOfMonth = moment(newRecord.date).date()
          let newDate
          if (newRecord.recurringValue === RECURRING.WEEKLY) {
            newDate = startDate.startOf('day').add(i, 'week')
          }
          if (
            newRecord.recurringValue === RECURRING.MONTHLY ||
            newRecord.recurringValue === RECURRING.QUARTERLY
          ) {
            if (
              newRecord.recurringValue === RECURRING.MONTHLY
                ? moment(startDate).startOf('day').add(i, 'M').date() ===
                  dayOfMonth
                : moment(startDate).startOf('day').add(i, 'Q').date() ===
                  dayOfMonth
            ) {
              newRecord.recurringValue === RECURRING.MONTHLY
                ? (newDate = startDate.startOf('day').add(i, 'M'))
                : (newDate = startDate.startOf('day').add(i, 'Q'))
            }
          }
          if (newRecord.recurringValue === RECURRING.YEARLY) {
            if (
              moment(startDate).date() === 29 &&
              moment(startDate).month() === 1
            ) {
              newDate = startDate.startOf('day').add(4 * i, 'Y')
            } else {
              newDate = startDate.startOf('day').add(i, 'Y')
            }
          }

          if (newDate) {
            const recurringObject = {
              id: newRecord._id,
              date: moment(newDate).startOf('day').valueOf(),
              reminderDays: newRecord.reminderDays,
              recurringValue: newRecord.recurringValue,
              recurringTimes: newRecord.recurringTimes,
              endDate: newRecord.endDate,
              parentId: newRecord.parentId
            }
            recurringEvents.push(recurringObject)
          }
          i++
        }
        let events

        if (newRecord.recurringValue === RECURRING.NONE) {
          events = [
            ...listEvent.filter(e => e.id !== newRecord._id),
            {
              id: newRecord._id,
              date: newRecord.date,
              reminderDays: newRecord.reminderDays
            }
          ]
        } else {
          if (!isEditMode) {
            events = [
              ...listEvent.filter(e => e.id !== newRecord._id),
              ...recurringEvents
            ]
          } else {
            if (type === EDIT_DELETE_EVENT_TYPE.THIS_EVENT) {
              events = [
                ...listEvent.filter(
                  e => e.id !== newRecord._id && e.date !== newRecord.date
                ),
                {
                  id: newRecord._id,
                  date: newRecord.date,
                  reminderDays: newRecord.reminderDays,
                  recurringValue: newRecord.recurringValue,
                  recurringTimes: newRecord.recurringTimes,
                  endDate: newRecord.endDate,
                  parentId: newRecord.parentId
                }
              ]
            } else if (
              type === EDIT_DELETE_EVENT_TYPE.THIS_AND_FOLLOWING_EVENTS
            ) {
              if (
                value !== eventDetails.recurringValue ||
                values.recurringTimes !== eventDetails.recurringTimes
              ) {
                events = [
                  ...listEvent.filter(
                    e => e.id !== record._id || e.date < newRecord.date
                  ),
                  ...recurringEvents
                ]
              } else {
                const updateEvents = listEvent
                  .filter(e => e.id === record._id && e.date >= newRecord.date)
                  .map(e => {
                    return {
                      ...e,
                      id: newRecord._id,
                      reminderDays: newRecord.reminderDays,
                      recurringValue: newRecord.recurringValue,
                      recurringTimes: newRecord.recurringTimes,
                      endDate: newRecord.endDate,
                      parentId: newRecord.parentId
                    }
                  })

                events = [
                  ...listEvent.filter(
                    e => e.id !== record._id || e.date < newRecord.date
                  ),
                  ...updateEvents
                ]
              }
            } else {
              if (
                value !== eventDetails.recurringValue ||
                values.recurringTimes !== eventDetails.recurringTimes
              ) {
                events = [
                  ...listEvent.filter(e =>
                    e.parentId
                      ? e.id !== record._id &&
                        e.id !== record.parentId &&
                        e.parentId !== record._id &&
                        e.parentId !== record.parentId
                      : e.id !== record._id && e.id !== record.parentId
                  ),
                  ...recurringEvents
                ]
              } else {
                const updatedEvents = listEvent
                  .filter(e =>
                    e.parentId
                      ? e.id === record._id ||
                        e.parentId === record._id ||
                        e.parentId === record.parentId ||
                        e.id === record.parentId
                      : e.id === record._id || e.id === record.parentId
                  )
                  .map(e => {
                    return {
                      ...e,
                      id: newRecord._id,
                      reminderDays: newRecord.reminderDays,
                      recurringValue: newRecord.recurringValue,
                      recurringTimes: newRecord.recurringTimes,
                      parentId: undefined
                    }
                  })

                events = [
                  ...listEvent.filter(e =>
                    e.parentId
                      ? e.id !== record._id &&
                        e.id !== record.parentId &&
                        e.parentId !== record._id &&
                        e.parentId !== record.parentId
                      : e.id !== record._id && e.id !== record.parentId
                  ),
                  ...updatedEvents
                ]
              }
            }
          }
        }

        if (type === EDIT_DELETE_EVENT_TYPE.ALL_EVENTS) {
          const eventsFromPouchDB = await getRecords(
            userId,
            'events',
            masterKey
          )
          const deletedItems =
            eventsFromPouchDB?.filter(
              event =>
                !uniq([
                  ...events.map(e => e.id),
                  ...events.map(e => e.parentId)
                ]).includes(event._id)
            ) || []

          if (deletedItems?.length) {
            await permanentlyDeleteItems(
              'events',
              userId,
              deletedItems,
              masterKey
            )
          }
        }

        setLinkedEvents([...linkedEvents, newRecord])
        await api.updateEvents(
          userId,
          JSON.stringify({
            events
          })
        )
        message.success(t('SUCCESSFULLY_SAVE_THE_EVENT'))
        onAddComplete && onAddComplete(record._id)
        dispatch(fetchEvents(userId, masterKey))
        // if ((isReadonly && isDelegateByPD) || isProfessionalDeputy) {
        //   dispatch(fetchOtherPendingEvents(userId, masterKey))
        // }
        // dispatch(fetchLinkedEvents(userId, masterKey))
        setVisible(false)
        setListEvent(events)
        localStorage.setItem('NotReload', true)
        addS3Change()
      } catch (error) {
        onError(error)
        setIsSaving(false)
        form.resetFields()
      } finally {
        form.resetFields()
        setValue(RECURRING.NONE)
        setIsSaving(false)
        setLinkedDocuments([])
      }

      handleClose()
    })
  }

  const handleCancel = () => {
    form.resetFields()
    setValue(RECURRING.NONE)
    setLinkedDocuments([])
    data?.length ? handleReturn() : handleClose()
  }

  const startDate = getFieldValue('date')
    ? getFieldValue('date')
    : moment(selectedValue)

  const disabledDate = current => {
    if (value === RECURRING.YEARLY) {
      return (
        current < moment(startDate).startOf('day') ||
        current > moment(startDate).endOf('day').add(9, 'Y')
      )
    } else if (value === RECURRING.MONTHLY) {
      return (
        current < moment(startDate).startOf('day') ||
        current > moment(startDate).endOf('day').add(119, 'M')
      )
    } else if (value === RECURRING.QUARTERLY) {
      return (
        current < moment(startDate).startOf('day') ||
        current > moment(startDate).endOf('day').add(39, 'Q')
      )
    } else {
      return (
        current < moment(startDate).startOf('day') ||
        current > moment(startDate).endOf('day').add(299, 'week')
      )
    }
  }

  const setEndDate = recurringTimes => {
    if (recurringTimes) {
      if (value === RECURRING.WEEKLY) {
        setFieldsValue({
          endDate: moment(startDate)
            .startOf('day')
            .add(recurringTimes - 1, 'week')
        })
      }
      if (value === RECURRING.MONTHLY || value === RECURRING.QUARTERLY) {
        let i = 0,
          count = 0,
          dayOfMonth = moment(startDate).date()
        while (count <= recurringTimes) {
          if (
            value === RECURRING.MONTHLY
              ? moment(startDate).startOf('day').add(i, 'M').date() ===
                dayOfMonth
              : moment(startDate).startOf('day').add(i, 'Q').date() ===
                dayOfMonth
          ) {
            count++
          }
          if (count < recurringTimes) {
            i++
          }
          if (count === recurringTimes) {
            value === RECURRING.MONTHLY
              ? setFieldsValue({
                  endDate: moment(startDate).startOf('day').add(i, 'M')
                })
              : setFieldsValue({
                  endDate: moment(startDate).startOf('day').add(i, 'Q')
                })
          }
        }
      }
      if (value === RECURRING.YEARLY) {
        if (
          moment(startDate).date() === 29 &&
          moment(startDate).month() === 1
        ) {
          setFieldsValue({
            endDate: moment(startDate)
              .startOf('day')
              .add(4 * (recurringTimes - 1), 'Y')
          })
        } else {
          setFieldsValue({
            endDate: moment(startDate)
              .startOf('day')
              .add(recurringTimes - 1, 'Y')
          })
        }
      }
    } else {
      setFieldsValue({
        endDate: null
      })
    }
  }

  const setRecurringTimes = endDate => {
    if (endDate) {
      if (value === RECURRING.WEEKLY) {
        setFieldsValue({
          recurringTimes:
            parseInt(
              moment(endDate)
                .endOf('day')
                .diff(startDate.startOf('day'), 'weeks', true)
            ) + 1
        })
      }
      if (value === RECURRING.MONTHLY || value === RECURRING.QUARTERLY) {
        let i = 0,
          count = 0,
          dayOfMonth = moment(startDate).date()
        while (
          value === RECURRING.MONTHLY
            ? moment(startDate).add(i, 'M').isBefore(moment(endDate))
            : moment(startDate).add(i, 'Q').isBefore(moment(endDate))
        ) {
          if (
            value === RECURRING.MONTHLY
              ? moment(startDate).add(i, 'M').endOf('month').date() >=
                dayOfMonth
              : moment(startDate).add(i, 'Q').endOf('month').date() >=
                dayOfMonth
          ) {
            count++
          }
          if (
            value === RECURRING.MONTHLY
              ? moment(startDate).add(i, 'M').isBefore(moment(endDate))
              : moment(startDate).add(i, 'Q').isBefore(moment(endDate))
          ) {
            i++
          }
          if (
            value === RECURRING.MONTHLY
              ? moment(startDate).add(i, 'M').isAfter(moment(endDate))
              : moment(startDate).add(i, 'Q').isAfter(moment(endDate))
          ) {
            setFieldsValue({
              recurringTimes: count + 1
            })
          }
        }
      }
      if (value === RECURRING.YEARLY) {
        if (
          moment(startDate).date() === 29 &&
          moment(startDate).month() === 1
        ) {
          setFieldsValue({
            recurringTimes:
              parseInt(
                moment(endDate)
                  .endOf('day')
                  .diff(startDate.startOf('day'), 'Y', true) / 4
              ) + 1
          })
        } else {
          setFieldsValue({
            recurringTimes:
              parseInt(
                moment(endDate)
                  .endOf('day')
                  .diff(startDate.startOf('day'), 'years', true)
              ) + 1
          })
        }
      }
    } else {
      setFieldsValue({
        recurringTimes: null
      })
    }
  }

  const onResetRecurring = () => {
    setFieldsValue({
      recurringTimes: null,
      endDate: null
    })
  }

  const onChangeRecurringValue = value => {
    setValue(value)
    onResetRecurring()
  }

  const unlinkDocument = itemId => {
    setLinkedDocuments(linkedDocuments.filter(doc => doc._id !== itemId))
  }

  const linkDocument = async documentId => {
    try {
      const documentIds = linkedDocuments.map(doc => doc._id).concat(documentId)
      const newLinkedDocuments =
        documentIds && documentIds.length
          ? ((isReadonly && isDelegateByPD) || isProfessionalDeputy) &&
            accessLevel === ACCESS_LEVEL.NEED_APPROVAL
            ? [
                ...(await getRecords(userId, 'documents', masterKey)).filter(
                  c => documentIds?.includes(c._id)
                ),
                ...(
                  await getRecords(userId, 'pendingDocuments', masterKey)
                ).filter(c => documentIds?.includes(c._id))
              ]
            : await getRecords(userId, 'documents', masterKey, {
                keys: documentIds
              })
          : []
      setLinkedDocuments(newLinkedDocuments)
    } catch (err) {
      onError(err)
    }
  }
  return (
    <>
      <Modal
        title={isEditMode ? t('EDIT_EVENT') : t('ADD_EVENT')}
        visible={visible}
        onCancel={handleCancel}
        maskClosable={false}
        footer={[
          <Button key="return" onClick={handleCancel}>
            {t('CANCEL')}
          </Button>,
          <Button
            key="save"
            type="primary"
            loading={isSaving}
            onClick={handelSaveEvent}
          >
            {t('SAVE')}
          </Button>
        ]}
      >
        <Form>
          <FormItem label={t('DESCRIPTION')}>
            {getFieldDecorator('description', {
              initialValue:
                eventDescription ||
                (isEditMode ? eventDetails.description : ''),
              rules: [{ required: true, message: t('INPUT_DESCRIPTION_MSG') }]
            })(<TextArea rows={4} maxLength={2000} />)}
          </FormItem>

          <FormItem label={t('DATE')}>
            {getFieldDecorator('date', {
              initialValue:
                eventDate ||
                (isEditMode ? moment(eventDetails.date) : selectedValue),
              rules: [
                {
                  required: true,
                  message: t('SELECT_DATE')
                }
              ]
            })(
              <DatePicker
                placeholder={t('SELECT_DATE')}
                disabled={
                  isEditMode && eventDetails.recurringValue !== RECURRING.NONE
                }
                onChange={onResetRecurring}
                format={DATE_FORMAT}
              />
            )}
          </FormItem>

          <FormItem label={t('RECURRING_EVENT')}>
            <Select
              showAction={['click', 'focus']}
              defaultValue={value}
              value={value}
              onChange={value => onChangeRecurringValue(value)}
              disabled={
                (isEditMode &&
                  eventDetails.recurringValue !== RECURRING.NONE &&
                  type === EDIT_DELETE_EVENT_TYPE.THIS_EVENT) ||
                eventRecurringValue
              }
            >
              {Object.values(RECURRING).map(item => (
                <Option
                  key={item}
                  value={item}
                  disabled={
                    item === 'None' &&
                    type === EDIT_DELETE_EVENT_TYPE.THIS_AND_FOLLOWING_EVENTS
                  }
                >
                  {t(item)}
                </Option>
              ))}
            </Select>
          </FormItem>

          {value !== RECURRING.NONE && (
            <>
              <FormItem label={t('RECURRING_TIMES')}>
                {getFieldDecorator('recurringTimes', {
                  initialValue: isEditMode ? eventDetails.recurringTimes : '',
                  rules: [
                    {
                      required: true,
                      message: t('SELECT_RECURRING_TIMES')
                    }
                  ]
                })(
                  <InputNumber
                    onBlur={e => setEndDate(parseInt(e.target.value))}
                    min={1}
                    max={
                      value === RECURRING.YEARLY
                        ? 10
                        : value === RECURRING.MONTHLY
                        ? 120
                        : value === RECURRING.QUARTERLY
                        ? 40
                        : 300
                    }
                    disabled={
                      isEditMode && type === EDIT_DELETE_EVENT_TYPE.THIS_EVENT
                    }
                  />
                )}
              </FormItem>
              <FormItem label={t('END_DATE_OF_RECURRING')}>
                {getFieldDecorator('endDate', {
                  initialValue: isEditMode
                    ? moment(eventDetails.endDate)
                    : null,
                  rules: [
                    {
                      required: true,
                      message: t('SELECT_END_DATE')
                    }
                  ]
                })(
                  <DatePicker
                    placeholder={t('SELECT_END_DATE')}
                    onChange={endDate => setRecurringTimes(endDate)}
                    disabledDate={disabledDate}
                    format={DATE_FORMAT}
                    disabled={
                      isEditMode && type === EDIT_DELETE_EVENT_TYPE.THIS_EVENT
                    }
                  />
                )}
              </FormItem>
            </>
          )}

          <FormItem label={t('REMINDER_DAYS')}>
            {getFieldDecorator('reminderDays', {
              initialValue: eventDetails?.reminderDays || 3
            })(<InputNumber min={1} />)}
          </FormItem>

          <FormItem
            label={t('TAGS')}
            initialValue={isEditMode ? eventDetails.tags : []}
          >
            <FileTags tags={tags} setTags={setTags} />
          </FormItem>

          <FormItem label={t('COLOR')}>
            <CirclePicker
              color={color}
              onChangeComplete={color => setColor(color.hex)}
            />
          </FormItem>

          {isFullModal && (
            <>
              <ContactSelect
                label={t('CONTACTS')}
                getFieldDecorator={getFieldDecorator}
                fieldName="contacts"
                initialValue={isEditMode ? eventDetails.contacts : []}
                onDeselect={handleContactDeselect}
                mode="multiple"
                onAddComplete={contactId =>
                  setFieldsValue({
                    contacts: [...(getFieldValue('contacts') || []), contactId]
                  })
                }
                disabled={
                  eventDetails.recurringValue !== RECURRING.NONE &&
                  isEditMode &&
                  type !== EDIT_DELETE_EVENT_TYPE.ALL_EVENTS
                }
              />
              <AssetLiabilitySelect
                label={t('ASSETS_LIABILITIES')}
                placeholder={t('SELECT_ASSETS_LIABILITIES')}
                required={false}
                getFieldDecorator={getFieldDecorator}
                fieldName="assetsLiabilities"
                initialValue={isEditMode ? eventDetails.assetsLiabilities : []}
                mode="multiple"
                onAddComplete={alId =>
                  setFieldsValue({
                    assetsLiabilities: [
                      ...(getFieldValue('assetsLiabilities') || []),
                      alId
                    ]
                  })
                }
                disabled={
                  eventDetails.recurringValue !== RECURRING.NONE &&
                  isEditMode &&
                  type !== EDIT_DELETE_EVENT_TYPE.ALL_EVENTS
                }
              />

              <PasswordSelect
                label={t('PASSWORDS')}
                placeholder={t('SELECT_PASSWORDS')}
                required={false}
                getFieldDecorator={getFieldDecorator}
                fieldName="passwords"
                initialValue={isEditMode ? eventDetails.passwords : []}
                mode="multiple"
                onAddComplete={passwordId =>
                  setFieldsValue({
                    passwords: [
                      ...(getFieldValue('passwords') || []),
                      passwordId
                    ]
                  })
                }
                disabled={
                  eventDetails.recurringValue !== RECURRING.NONE &&
                  isEditMode &&
                  type !== EDIT_DELETE_EVENT_TYPE.ALL_EVENTS
                }
              />

              <List
                header={
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    <Span>{t('LINKED_DOCUMENTS')}:</Span>
                    <Button
                      icon="plus"
                      onClick={() => setDocumentsLinksVisible(true)}
                      disabled={
                        eventDetails.recurringValue !== RECURRING.NONE &&
                        isEditMode &&
                        type !== EDIT_DELETE_EVENT_TYPE.ALL_EVENTS
                      }
                    >
                      {t('ADD')}
                    </Button>
                  </div>
                }
                dataSource={linkedDocuments}
                renderItem={item => (
                  <List.Item className="linked-document-item">
                    <span>
                      <Icon type="file-text" style={{ marginRight: 10 }} />
                      {item.fileName}
                    </span>
                    {(!isEditMode ||
                      eventDetails.recurringValue === RECURRING.NONE ||
                      type === EDIT_DELETE_EVENT_TYPE.ALL_EVENTS) && (
                      <Icon
                        type="delete"
                        onClick={() => unlinkDocument(item._id)}
                      />
                    )}
                  </List.Item>
                )}
                locale={{ emptyText: t('NO_DOCUMENTS') }}
              />
            </>
          )}
        </Form>
      </Modal>
      <DocumentsLinksModal
        visible={documentsLinksVisible}
        setVisible={setDocumentsLinksVisible}
        record={{}}
        linkDocument={linkDocument}
        filteredDocIds={linkedDocuments.map(doc => doc._id)}
      />
      <SubscriptionModal
        visible={subscriptionModalVisible}
        setVisible={setSubscriptionModalVisible}
      />
    </>
  )
}
const WrappedAddEventForm = Form.create({ name: 'AddEventModal' })(
  AddEventModal
)

export default WrappedAddEventForm
