import { renderItems, renderDate, pngToBase64 } from '../../share/helpers'
import {
  CONTACTS_SAMPLE_DATA,
  RENDER_TYPES,
  CONTACT_TYPES
} from '../../share/Constants'
//import { StringResources } from './../../share/StringResources'
import { Workbook } from 'exceljs'
import { CONTACT_COLUMNS_BASE_TYPE } from '../../model/ContactModel'
import { saveAs } from 'file-saver'
import { addWorksheetContent } from '../../share/importHelpers'
import banner from '../../assets/banner.png'
import { countries } from 'country-data'
import i18next from 'i18next'

export const contactCommonColumns = () => [
  {
    key: 'emails',
    title: i18next.t('EMAIL'),
    type: RENDER_TYPES.ITEMS,
    exportWidth: 40,
    style: { alignment: { wrapText: true } },
    render: renderItems
  },
  {
    key: 'addresses',
    title: i18next.t('ADDRESS'),
    type: RENDER_TYPES.ITEMS,
    exportWidth: 50,
    style: { alignment: { wrapText: true } },
    render: renderItems
  },
  {
    key: 'phoneNumbers',
    title: i18next.t('PHONE_NUMBER'),
    type: RENDER_TYPES.ITEMS,
    exportWidth: 30,
    style: { alignment: { wrapText: true } },
    render: renderItems
  },
  {
    key: 'references',
    title: i18next.t('REFERENCES'),
    type: RENDER_TYPES.ITEMS,
    exportWidth: 30,
    style: { alignment: { wrapText: true } },
    render: renderItems
  },
  {
    key: 'url',
    dataIndex: 'url',
    title: i18next.t('URL')
  },

  {
    key: 'tags',
    dataIndex: 'tags',
    title: i18next.t('TAGS'),
    exportWidth: 30,
    style: { alignment: { wrapText: true } },
    render: value => value?.join('\r\n')
  },
  // { key: 'name', dataIndex: 'name', title: i18next.t('NAME') },
  // { key: 'type', dataIndex: 'type', title: i18next.t('TYPE') },
  {
    key: 'description',
    dataIndex: 'description',
    title: i18next.t('DESCRIPTION'),
    exportWidth: 30,
    style: { alignment: { wrapText: true } }
  }
]

export const contactColumnsByType = () => ({
  [CONTACT_TYPES.INDIVIDUAL]: [
    {
      key: 'givenName',
      dataIndex: 'givenName',
      title: i18next.t('GIVEN_NAME')
    },
    {
      key: 'surName',
      dataIndex: 'surName',
      title: i18next.t('SURNAME')
    },
    {
      key: 'dateOfBirth',
      dataIndex: 'dateOfBirth',
      title: i18next.t('DATE_OF_BIRTH'),
      render: renderDate
    },
    {
      key: 'idPassportNumber',
      dataIndex: 'idPassportNumber',
      title: i18next.t('ID_PASSPORT_NUMBER')
    }
  ],
  [CONTACT_TYPES.ORGANISATION]: [
    {
      key: 'displayName',
      dataIndex: 'displayName',
      title: i18next.t('DISPLAY_NAME')
    },
    {
      key: 'legalName',
      dataIndex: 'legalName',
      title: i18next.t('LEGAL_NAME')
    },
    {
      key: 'companyRegistrationNumber',
      dataIndex: 'companyRegistrationNumber',
      title: i18next.t('COMPANY_REGISTRATION_NUMBER')
    },
    {
      key: 'countryTerritoryOfIncorporation',
      dataIndex: 'countryTerritoryOfIncorporation',
      title: i18next.t('COUNTRY_TERRITORY_OF_INCORPORATION')
    }
  ]
})

export const contactAllColumns = () => [
  ...contactCommonColumns(),
  ...contactColumnsByType()[CONTACT_TYPES.INDIVIDUAL],
  ...contactColumnsByType()[CONTACT_TYPES.ORGANISATION]
]

export const downloadExcelImportTemplate = async () => {
  const workbook = new Workbook()

  const base64Banner = await pngToBase64(banner)

  CONTACTS_SAMPLE_DATA.forEach(data => {
    addWorksheetContent(
      base64Banner,
      workbook,
      data,
      data.type,
      CONTACT_COLUMNS_BASE_TYPE(data.type)
    )
  })

  const countryRefWs = workbook.addWorksheet('Countries')
  countryRefWs.columns = [
    {
      header: 'Country',
      key: 'name',
      width: 30
    }
  ]
  countryRefWs.addRows(countries.all.filter(c => c.status === 'assigned'))

  const data = await workbook['xlsx'].writeBuffer()

  const blob = new Blob([data])
  saveAs(blob, 'import_contacts_template.xlsx')
}
