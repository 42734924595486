export const CRYPTO_EXCHANGES = [
  'Binance',
  'Coinbase Pro',
  'Huobi Global',
  'Kraken',
  'Upbit',
  'Bitfinex',
  'Bithumb',
  'KuCoin',
  'FTX',
  'Bitstamp',
  'OKEx',
  'bitFlyer',
  'Gate.io',
  'Poloniex',
  'Bittrex',
  'BtcTurk Pro',
  'Coinone',
  'Binance.US',
  'Zaif',
  'Luno',
  'Ledger Nano X',
  'Ledger Nano S',
  'Trezor',
  'Exodus ',
  'Electrum',
  'Mycelium'
]

export const CRYPTO_CURRENCIES = [
  { code: 'BTC', name: 'Bitcoin' },
  { code: 'ETH', name: 'Ethereum' },
  { code: 'USDT', name: 'Tether' },
  { code: 'XRP', name: 'XRP' },
  { code: 'LINK', name: 'Chainlink' },
  { code: 'BCH', name: 'Bitcoin Cash' },
  { code: 'BNB', name: 'Binance Coin' },
  { code: 'LTC', name: 'Litecoin' },
  { code: 'DOT', name: 'Polkadot' },
  { code: 'ADA', name: 'Cardano' },
  { code: 'BSV', name: 'Bitcoin SV' },
  { code: 'USDC', name: 'USD Coin' },
  { code: 'EOS', name: 'EOS' },
  { code: 'XMR', name: 'Monero' },
  { code: 'WBTC', name: 'Wrapped Bitcoin' },
  { code: 'TRX', name: 'TRON' },
  { code: 'XLM', name: 'Stellar' },
  { code: 'CRO', name: 'Crypto.com Coin' },
  { code: 'XTZ', name: 'Tezos' },
  { code: 'LEO', name: 'UNUS SED LEO' }
]
