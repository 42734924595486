export const StringResourcesId = {
  INPUT_TOTP_CODE:
    'Masukkan kode autentikasi dari applikasi Auntentikator anda.',
  INPUT_SMS_CODE:
    'Masukkan kode verifikasi dari SMS yang anda terima di nomor ponsel yang sudah terdaftar.',
  SETUP_MFA: 'Pengaturan autentikasi multifaktor',
  SAME_PASSWORD_MSG:
    'Kata sandi yang baru harus berbeda dengan kata sandi yang lama!',
  PASSWORDS_DO_NOT_MATCH_MSG: 'Kata sandi anda tidak benar.',
  INPUT_CURRENT_PASSWORD_MSG: 'Masukkan kata sandi!',
  INPUT_NEW_PASSWORD_MSG: 'Masukkan kata sandi yang baru!',
  CONFIRM_NEW_PASSWORD_MSG: 'Masukkan kata sandi anda yang baru lagi!',
  CHANGE_PASSWORD: 'Ubah kata sandi',
  CURRENT_PASSWORD: 'Kata sandi',
  NEW_PASSWORD: 'Kata sandi baru',
  CONFIRM_NEW_PASSWORD: 'Masukkan kata sandi anda yang baru lagi!',
  CLOSE_ACCOUNT: 'Hapus akun',
  CONFIRM_CLOSE_ACCOUNT_MSG:
    'Anda yakin ingin menghapus akun anda? Semua data akan dihapus secara permanen dan tidak dapat dipulihkan lagi.',
  SETUP_MFA_INSTRUCTION:
    'Anda perlu mengatur autentikasi multifaktor untuk melindungi akun vaultbox anda. ',
  INPUT_PASSWORD_OR_EMAIL_MSG: 'Masukkan nama pengguna atau email!',
  INPUT_PASSWORD_MSG: 'Masukkan kata sandi!',
  INPUT_EXISTING_DEPUTY_EMAIL_MSG: 'Email ini sudah ada di daftar Deputi!',
  INPUT_CURRENT_USER_EMAIL_MSG: 'Masukkan email lain yang bukan milik anda!',
  FORGOT_PASSWORD: 'Lupa kata sandi',
  LOGIN: 'Masuk',
  REMEMBER_ME: 'Ingat',
  SECRET_CODE: 'Kode rahasia',
  FRIENDLY_DEVICE_NAME: 'Perangkat TOTP saya',
  USERNAME_OR_EMAIL: 'Nama pengguna / Email',
  SETTINGS: 'Pengaturan',
  FILES: 'Files',
  SIGN_OUT: 'Keluar',
  INVALID_CODE_TYPE: 'Kode harus terdiri dari 6 angka!',
  PASSWORD_RULES:
    'Kata sandi harus terdiri dari minimum delapan karakter, dengan satu huruf besar, satu huruf kecil, satu angka dan satu karakter spesial. ',
  ACTION: 'Tindakan',
  PENDING_ACCEPTANCE_OF_DEPUTY: 'Menunggu penerimaan',
  DEPUTY_MANAGAMENT: 'Pengaturan Deputi',
  PROFILE_AND_SETTINGS: 'Profil & Pengaturan',
  REQUESTS: 'Permintaan',
  PRIMARY_USERS: 'Pengguna utama',
  RESEND: 'Kirim ulang',
  REVOKE: 'Batalkan',
  REACTIVATE: 'Aktifkan kembali',
  PAYMENT: 'Pembayaran',
  SIGN_IN: 'Masuk',
  INPUT_FULLNAME_MSG: 'Masukkan nama lengkap anda!',
  USE_SAME_NAME_AS_PASSPORT_TOOLTIP: 'Gunakan nama lengkap sesuai KTP / paspor',
  INPUT_PHONE_NUMBER_MSG: 'Masukkan nomor ponsel anda!',
  INVALID_EMAIL_MSG: 'Masukkan email yang benar!',
  INPUT_EMAIL_MSG: 'Masukkan email anda!',
  CONFIRM_PASSWORD_MSG: 'Silakan konfirmasi kata sandi anda!',
  FULLNAME: 'Nama lengkap',
  PREFERRED_NAME: 'Nama pilihan',
  PHONE_NUMBER: 'Nomor ponsel',
  EMAIL: 'Alamat email',
  SHARE_KEY_STATUS: 'Bagi status kunci',
  UNLOCK_STATUS: 'Status pembukaan',
  PASSWORD: 'Kata sandi',
  CONFIRM_PASSWORD: 'Konfirmasi kata sandi',
  VERIFICATION_CODE: 'Kode verifikasi',
  CONTACT_NUMBER: 'Nomor kontak',
  ID_PASSPORT_NUMBER: 'Nomor KTP / paspor',
  NATIONALITY: 'Kewarganegaraan',
  RESIDENTIAL_ADDRESS: 'Alamat tempat tinggal',
  COUNTRY_TERRITORY: 'Negara / Wilayah',
  RESET_PASSWORD: 'Reset kata sandi',
  RESET_PASSWORD_INSTRUCTION:
    'Untuk mereset kata sandi anda, silakan masukkan kode verifikasi yang telah dikirim ke email anda, lalu masukkan kata sandi baru anda.',
  INPUT_VERIFICATION_CODE_MSG: 'Masukkan kode verifikasi',
  FILE_NAME: 'Nama file',
  DESCRIPTION: 'Deskripsi',
  TAGS: 'Tag',
  DOWNLOAD: 'Unduh',
  VIEW: 'Tampilkan',
  EDIT: 'Edit',
  SAVE: 'Simpan',
  CANCEL: 'Batalkan',
  UPLOAD: 'Unggah',
  OR: 'atau',
  INPUT_FILE_NAME_MSG: 'Masukkan nama file',
  SAVE_AS_TYPE: 'Simpan sebagai tipe',
  INPUT_SHORT_DESCRIPTION_MSG: 'Masukkan deskripsi singkat dari file',
  FILE_DETAILS: 'Detail file',
  PATH: 'Lokasi',
  RENAME: 'Ubah nama',
  SEARCH: 'Cari',
  RESET: 'Reset',
  START_TIME: 'Waktu mulai',
  END_TIME: 'Akhir waktu',
  SELECT_START_DATE: 'Pilih tanggal mulai',
  SELECT_END_DATE: 'Pilih tanggal akhir',
  FILL_IN_SEARCH_FIELD_MSG: 'Silakan isi minimal satu bidang pencarian',
  SEARCH_RESULTS: 'Hasil pencarian',
  UPLOAD_FILE: 'Unggah file',
  FILE: 'File',
  SELECT_FILE_TO_UPLOAD_MSG: 'Pilih file untuk diunggah',
  UPLOAD_FILE_INSTRUCTION: 'Klik atau tarik file ke area ini untuk diunggah',
  MOVE: 'Pindah',
  MOVE_FILE: 'Pindah file',
  BROWSE: 'Telusuri',
  DESTINATION: 'Tujuan',
  LINK: 'Tautan',
  UNLINK: 'Batalkan tautan',
  FAVOURITES: 'Favorit',
  TITLE: 'Judul',
  TYPE: 'Tipe',
  ASSET: 'Aset',
  LIABILITY: 'Kewajiban',
  DESCRIPTION_COL: 'Deskripsi',
  PERCENTAGE_OWNERSHIP: 'Persentase kepemilikan',
  PURCHASE_DATE: 'Tanggal pembelian',
  OK: 'Ok',
  ACTIONS: 'Tindakan',
  EDIT_ASSET_LIABILITY: 'Edit aset/kewajiban',
  REMOVE_ASSET_LIABILITY: 'Hapus aset/kewajiban',
  LINK_TO_ASSETS_LIABILITIES_TOOLTIP:
    'Hubungkan dengan satu aset atau kewajiban, atau lebih',
  LINK_TO_DOCUMENTS_TOOLTIP: 'Hubungkan dengan satu dokumen atau lebih',
  VIEW_PREVIOUS_VERSIONS_ASSET_LIABILITY:
    'Tampilkan versi aset/kewajiban sebelumnya',
  ADD_ASSET_LIABILITY_SUCCESS: 'Berhasil menambahkan aset/kewajiban',
  UPDATE_ASSET_LIABILITY_SUCCESS: 'Berhasil memperbarui aset/kewajiban',
  REACTIVATE_SUBSCRIPTION_SUCCESS:
    'Berhasil mengaktifkan kembali paket langganan anda',
  REACTIVATE_SUBSCRIPTION_FAIL:
    'Gagal mengaktifkan kembali paket langganan anda',
  ADD_ASSET_LIABILITY: 'Tambahkan aset / kewajiban',
  INPUT_TITLE_MSG: 'Masukkan judul!',
  INPUT_DESCRIPTION_MSG: 'Masukkan deskripsi!',
  INPUT_PURCHASE_DATE_MSG: 'Masukkan tanggal pembelian!',
  CURRENCY: 'Mata uang',
  CCY: 'Mata uang',
  VALUATION_ASSET_LIABILITY_CURRENCY:
    'Penilaian dalam mata uang aset / kewajiban',
  ASSETS_CURRENCY: 'Mata uang aset',
  VALUATION_ASSET_CURRENCY: 'Penilaian dalam mata uang aset',
  VALUATION_BASE_CURRENCY: 'Penilaian dalam mata uang dasar',
  START_DATE: 'Tanggal mulai',
  INPUT_START_DATE: 'Masukkan tanggal mulai!',
  MATURITY_DATE: 'Tanggal jatuh tempo',
  LIABILITIES_CURRENCY: 'Mata uang kewajiban',
  SUCCESSFULLY_SAVED_COLUMNS: 'Berhasil menyimpan kolom',
  SHOW_HIDE_COLUMNS: 'Tampilkan / sembunyikan kolom',
  FAILED_TO_SAVE_COLUMNS: 'Gagal menyimpan kolom',
  SAVE_COLUMNS_TOOLTIP:
    'Simpan kolom yang saat ini dipilih sebagai tampilan default',
  SAVE_COLUMNS: 'Simpan kolom',
  RESET_TOOLTIP: 'Reset ke kolom default anda',
  OUTSTANDING_VALUE_LIABILITY_CURRENCY: 'Jumlah dalam mata uang kewajiban',
  OUTSTANDING_VALUE_BASE_CURRENCY: 'Jumlah dalam mata uang dasar',
  LINK_ASSETS_LIABILITIES: 'Hubungkan aset/kewajiban',
  LINK_DOCUMENTS: 'Hubungkan dokumen',
  SELECT_ASSETS_LIABILITIES_TO_LINK: 'Pilih aset/kewajiban untuk dihubungkan',
  ASSETS_LIABILITIES: 'Aset/Kewajiban',
  SELECT_ASSETS_LIABILITIES: 'Pilih aset/kewajiban',
  LINK_ASSETS_LIABILITIES_SUCCESS: 'Berhasil memperbarui tautan aset/kewajiban',
  VIEW_LINKED_RECORDS: 'Tampilkan catatan terkait',
  SET_BASE_CURRENCY: 'Tetapkan mata uang dasar',
  SELECT_CURRENCY: 'Pilih mata uang',
  BASE_CURRENCY: 'Mata Uang Dasar',
  GROSS_ASSET_VALUE: 'Nilai aset bruto',
  GROSS_OUTSTANDING_VALUE: 'Nilai bruto',
  NET_VALUE: 'Nilai bersih',
  GROUP_BY_CURRENCY: 'Kelompokkan menurut mata uang',
  TIME: 'Waktu',
  ASSET_PURCHASE_DATE: 'Tanggal pembelian aset',
  LIABILITY_START_DATE: 'Tanggal mulai kewajiban',
  LIABILITY_MATURITY_DATE: 'Tanggal jatuh tempo kewajiban',
  VALUATION_IN_ASSET_LIABILITY_CURRENCY:
    'Penilaian dalam mata uang aset / kewajiban',
  OTHER_ASSET_TYPE: 'Jenis aset lain',
  VALUATION_DATE: 'Tanggal penilaian',
  TYPE_OF_INTEREST_RATE: 'Jenis suku bunga',
  SECURITY_COLLATERAL: 'Agunan yang diberikan kepada pemberi pinjaman',
  RECURRING_PAYMENT_DUE_DAY: 'Tanggal jatuh tempo pembayaran berulang',
  ISSUING_BANK: 'Bank penerbit',
  CREDIT_LIMIT: 'Batas kredit',
  STATEMENT_DATE: 'Tanggal pernyataan',
  CLASS_OF_SHARES: 'Kelas saham',
  SHARE_CERTIFICATE_NUMBER: 'Nomor sertifikat saham',
  PERCENTAGE_OF_SHARES: 'Persentase saham dalam kelas ini',
  DISPOSAL_DATE: 'Tanggal pelepasan',
  ACCOUNT_TYPE: 'Jenis akun',
  BALANCE_AMOUNT: 'Jumlah saldo',
  BUILDING_TYPE: 'Jenis bangunan',
  BUILD_DATE: 'Tanggal dibangun',
  FLOOR_SIZE: 'Luas lantai',
  FLOOR_SIZE_VALUE: 'Ukuran lantai',
  FLOOR_SIZE_UNIT: 'Unit ukuran lantai',
  PROPERTY_ADDRESS: 'Alamat properti',
  PROPERTY_ADDRESS_MSG: 'Masukkan alamat properti',
  HOLDING_TYPE: 'Jenis kepemilikan',
  LEASEHOLD_DURATION: 'Durasi sewa',
  TENANT: 'Penyewa',
  RENT_AMOUNT: 'Jumlah sewa',
  RENT_AMOUNT_VALUE: 'Nilai harga sewa',
  RENT_AMOUNT_UNIT: 'Unit harga sewa',
  RENTAL_START_DATE: 'Tanggal mulai sewa',
  RENTAL_END_DATE: 'Tanggal akhir sewa',
  EXCHANGE: 'Bursa efek',
  NUMBER_OF_SHARES: 'Jumlah saham',
  EXPIRY_DATE: 'Tanggal kadaluarsa',
  PAYMENT_DUE_DATE: 'Tanggal jatuh tempo pembayaran',
  LENDER: 'Pemberi pinjaman',
  BORROWER: 'Peminjam',
  LOAN_PERIOD: 'Jangka waktu pinjaman',
  LOAN_PERIOD_VALUE: 'Nilai periode pinjaman',
  LOAN_PERIOD_UNIT: 'Unit periode pinjaman',
  LENGTH_OF_LOAN_VALUE: 'Nilai periode pinjaman',
  LENGTH_OF_LOAN_UNIT: 'Unit periode pinjaman',
  NUMBER_OF_REPAYMENTS: 'Jumlah angsuran',
  MONTHLY_PAYMENT: 'Pembayaran bulanan',
  ACCOUNT_NUMBER: 'Nomor akun',
  LENGTH_OF_LOAN: 'Jangka waktu pinjaman',
  YEAR_OF_LOAN: 'Tahun pinjaman',
  OTHER_NOTES: 'Catatan lainnya',
  COMPANY: 'Perusahaan',
  BENEFICIARIES: 'Penerima manfaat',
  INSURANCE_ADVISOR: 'Penasihat asuransi',
  ENTITLEMENT: '% Hak',
  POLICY_NUMBER: 'Nomor polis',
  INSURER: 'Penjamin asuransi',
  PREMIUM: 'Premi',
  PREMIUM_VALUE: 'Nilai premi',
  PREMIUM_UNIT: 'Unit premi',
  SUM_ASSURED_IN_BASE_CURRENCY: 'Nilai objek asuransi dalam mata uang dasar',
  SUM_ASSURED_IN_ASSET_CURRENCY: 'Nilai objek asuransi dalam mata uang aset',
  TYPE_OF_POLICY: 'Jenis polis',
  LIFE_ASSURED: 'Nama tertanggung',
  LIFE_ASSURED_TOOLTIP: 'Orang yang mendapat pertanggungan dalam kontrak polis',
  POLICY_OWNER: 'Pemilik polis',
  POLICY_OWNER_TOOLTIP:
    'Orang yang membayar premi polis dan memiliki kontrak polis',
  BANK: 'Bank',
  INTEREST_RATE: 'Suku bunga',
  INTEREST_RATE_VALUE: 'Nilai suku bunga',
  INTEREST_RATE_UNIT: 'Unit suku bunga',
  SUBTYPE: 'Subjenis',
  AMOUNT_BORROWED: 'Jumlah yang dipinjam',
  ASSET_LINKS: 'Tautan aset',
  SEARCH_ASSETS: 'Cari aset',
  LIABILITY_LINKS: 'Tautan kewajiban',
  SEARCH_LIABILITIES: 'Cari kewajiban',
  POSITION_DATE: 'Tanggal posisi',
  BALANCE_DATE: 'Tanggal saldo',
  ASSETS: 'Aset',
  LIABILITIES: 'Kewajiban',
  INPUT_BALANCE_DATE_MSG: 'Masukan tanggal saldo',
  INPUT_POSITION_DATE_MSG: 'Masukkan tanggal posisi',
  QUANTITY: 'Kuantitas',
  BROKERAGE_NAME: 'Nama broker',
  JOINT_ACCOUNT: 'Akun bersama',
  VEHICLE_REGISTRATION_NUMBER: 'Nomor polisi kendaraan',
  RETIREMENT_ACCOUNT_TYPE: 'Jenis akun pensiunan',
  EXPORT_TO_PDF: 'Ekspor ke PDF',
  EXPORT_TO_PDF_WARNING:
    'Anda telah memilih lebih dari 10 kolom. Agar data ditampilkan dengan baik dalam file PDF yang diekspor, disarankan untuk memilih kurang dari 10 kolom.',
  INCLUDE_IN_NETWORTH: 'Termasuk bagian dari kekayaan bersih',
  TRUST_TYPE: 'Jenis Trust',
  TYPE_OF_TRUST_INTEREST: 'Relasi dengan Trust',
  TRUST_REVOCABILITY: 'Status pencabutan Trust',
  CONTACTS: 'Kontak',
  ADD_CONTACT: 'Tambahkan kontak',
  NAME: 'Nama',
  ADD_CONTACT_SUCCESS: 'Berhasil menambah kontak',
  INPUT_NAME_MSG: 'Masukkan nama!',
  INPUT_GIVEN_NAME_MSG: 'Masukkan nama depan!',
  INPUT_DISPLAY_NAME_MSG: 'Masukkan nama untuk ditampilkan!',
  INPUT_LEGAL_NAME_MSG: 'Masukkan nama resmi!',
  ORGANISATION: 'Organisasi',
  INDIVIDUAL: 'Individu',
  GIVEN_NAME: 'Nama depan',
  SURNAME: 'Nama keluarga',
  DATE_OF_BIRTH: 'Tanggal lahir',
  DISPLAY_NAME: 'Nama yang ditampilkan',
  LEGAL_NAME: 'Nama resmi',
  EMAILS: 'Email',
  CONTACT_INFO: 'Info kontak',
  ADDRESSES: 'Alamat',
  REFERENCES: 'Referensi',
  URL: 'Situs web',
  EDIT_CONTACT: 'Edit kontak',
  REMOVE_CONTACT: 'Hapus kontak',
  EDIT_CONTACT_SUCCESS: 'Berhasil mengedit kontak',
  REMOVE_CONTACT_SUCCESS: 'Berhasil menghapus kontak',
  REMOVE_CONTACT_CONFIRM: 'Anda yakin ingin menghapus kontak ini?',
  VIEW_PREVIOUS_VERSIONS_CONTACT: 'Tampilkan kontak versi sebelumnya',
  CREATE_NEW_FOLDER: 'Buat folder baru',
  RENAME_FOLDER: 'Ubah nama folder',
  INPUT_FOLDER_NAME_MSG: 'Masukkan nama folder',
  FOLDER_NAME: 'Nama folder',
  NEW_FOLDER: 'Folder baru',
  DELETE: 'Hapus',
  CONFIRM_DELETE: 'Konfirmasi hapus',
  CONFIRM_DELETE_ITEM_MSG: 'Anda yakin ingin menghapus objek ini?',
  CONFIRM_DELETE_ITEMS_MSG: 'Anda yakin ingin menghapus objek ini?',
  PERCENTAGE_OWNERSHIP_TOOLTIP: 'Masukkan persentase kepemilikan?',
  VALUATION_IN_BASE_CURRENCY_TOOLTIP:
    'Jika dibiarkan kosong, ini akan secara otomatis dikonversi dari mata uang aset ke mata uang dasar anda sesuai dengan nilai tukar yang telah anda tetapkan di pengaturan',
  OUTSTANDING_AMOUNT_IN_BASE_CURRENCY_TOOLTIP:
    'Jika dibiarkan kosong, ini akan secara otomatis dikonversi dari mata uang kewajiban ke mata uang dasar anda sesuai dengan nilai tukar yang telah anda tetapkan di pengaturan',
  LIABILITY_VALUATION_DATE_TOOLTIP:
    'Ini adalah tanggal spesifik dari jumlah terutang di bawah ',
  LOW_STORAGE_SPACE: 'Ruang penyimpanan tinggal sedikit',
  LOW_STORAGE_SPACE_CONTENT:
    'Ruang penyimpanan tidak cukup. Kosongkan sebagian ruang dengan menghapus file yang tidak lagi anda perlukan atau tingkatkan paket langganan anda untuk penyimpanan tambahan.',
  COUNTRY_TERRITORY_OF_INCORPORATION: 'Negara / wilayah pendirian',
  COMPANY_REGISTRATION_NUMBER: 'Nomor registrasi perusahaan',
  PHONE: 'Nomor telpon',
  REGISTRY: 'Register',
  DEPUTY: 'Deputi',
  TRASH: 'Sampah',
  DOCUMENTS: 'Dokumen',
  DETAILS: 'Detail',
  EXPORT_TO_EXCEL: 'Ekspor ke Excel',
  PASSPORT_NUMBER: 'Nomor paspor',
  PROFILE_INFORMATION: 'Informasi profil',
  EXCHANGE_RATES: 'Kurs',
  MY_DEPUTIES: 'Deputi saya',
  PHONE_NUMBERS: 'Nomor telpon',
  ALL_FILES: 'Semua file',
  CRYPTO_CURRENCY: 'Mata uang kripto',
  WALLET_ADDRESS: 'Alamat dompet',
  PD_NAME: 'Didelegasikan oleh Deputi professional',
  PRICE: 'Harga',
  WALLET_PROVIDER: 'Penyedia dompet',
  PENDING_RECOVERY: 'Menunggu pengembalian',
  RESET_ACCOUNT_NOW: 'Reset akun sekarang',
  WILL_WAIT: 'Ok, saya menunggu',
  RECOVER_DATA_FIRST_CONTENT:
    'Anda harus menunggu persetujuan dari Deputi anda untuk memulihkan data Anda.',
  RECOVER_DATA_SECOND_CONTENT:
    'Jika Anda tidak menetapkan Deputi, atau jika Deputi anda tidak lagi tersedia, data anda yang ada tidak dapat dipulihkan dan anda perlu mengatur ulang akun Anda untuk memulai lagi.',
  RESET_ACCOUNT_CONFIRMATION: 'Konfirmasi reset akun',
  RESET_ACCOUNT_MODAL_CONTENT: 'Anda yakin ingin mereset akun anda?',
  SUCCESSFULLY_RESET_ACCOUNT: 'Berhasil mereset akun.',
  FAILED_TO_RESET_ACCOUNT: 'Gagal mereset akun',
  WELL_COME_TO_VAULTBOX: 'Selamat datang di',
  BY_CONTINUING: 'Dengan melanjutkan, anda menyetujui',
  TERMS_OF_SERVICE: 'Persyaratan',
  RESET_MFA: 'Reset MFA',
  RESET_MFA_FIRST_CONTENT:
    '\nJika ponsel anda hilang atau direset sehingga anda tidak bisa lagi mendapatkan kode autentikasi dari aplikasi Autentikator, anda dapat mereset MFA untuk melakukan pengaturan lagi.',
  RESET_MFA_SECOND_CONTENT:
    '\nSaat anda mereset MFA, SMS OTP akan dikirimkan ke nomor ponsel Anda yang telah terdaftar. Masukkan kode tersebut untuk menyelesaikan proses reset.',
  RESET_MFA_WITH_PHONE_NUMBER: 'Reset MFA denga nomor ponsel baru',
  RESET_MFA_WITH_PHONE_NUMBER_CONTENT:
    '\nJika anda kehilangan ponsel dan nomornya, anda dapat mereset MFA dengan nomor ponsel baru untuk melakukan pengaturan lagi. Tindakan ini membutuhkan persetujuan dari Deputi anda',
  FAIL_TO_GET_COUNTRY_CODE: 'Gagal mendapatkan kode negara',
  GIFT_INFO_NOT_FOUND: 'Info hadiah tidak ditemukan',
  GIFT_RECEIVER_EMAIL_MISSMATCH:
    'Alamat email penerima hadiah tidak sesuai. Silakan mendaftar dengan alamat email yang menerima hadiah.',
  GIFT_PLAN_MISSMATCH: 'Paket hadiah tidak sesuai',
  SUBSCRIPTION_PLAN_EMPTY_MSG: 'Silakan pilih paket langganan',
  INVALID_PROMOTION_CODE: 'Kode promosi tidak valid',
  PROMOTION_CODE_MSG:
    'Kode promosi ini tidak dapat diterapkan ke paket yang dipilih.',
  FAILED_TO_CHECK_CODE_VALIDATE: 'Gagal memeriksa validitas kode.',
  PERSONAL_INFO_DESCRIPTION:
    '\nHarap ingat kata sandi ini karena tidak ada orang lain selain anda yang dapat mengakses <strong> vaultbox </strong> anda hingga anda masuk dan menunjuk Deputi.',
  SUBSCRIPTION_PLANS: 'Paket berlangganan',
  REVIEW: 'Ulas',
  PERSONAL_INFORMATION: 'Informasi pribadi',
  SUBSCRIPTION: 'Berlangganan',
  PLAN: 'Paket',
  FREE: 'Gratis',
  APPLIED_REFERRAL_CODE: 'Kode referal yang diterapkan',
  APPLIED_PROMOTION: 'Promosi yang diterapkan',
  PROMOTION_NAME: 'Nama promosi',
  PERCENTAGE_DISCOUNT: 'Persentase diskon',
  FIXED_AMOUNT_DISCOUNT: 'Diskon jumlah tetap',
  DURATION: 'Durasi',
  ONCE: 'Sekali',
  FOREVER: 'Selamanya',
  READ_AND_AGREE_TERM_OF_SERVICE: 'Saya telah membaca dan menyetujui',
  MARKETING_CONSENT_FROM_VAULTBOX:
    '\nSaya setuju untuk menerima materi pemasaran tentang <strong> vaultbox </strong> dari Sircured, afiliasi dan mitranya.',
  MARKETING_CONSENT_FROM_THIRT_PARTY:
    'Saya setuju untuk menerima materi pemasaran mengenai hal-hal yang mungkin menarik minat saya dari Sircured, afiliasinya, mitra.',
  NOT_AGREE_TERMS_OF_SERVICE_MSG: '\nHarap setujui Persyaratan Layanan',
  PREVIOUS: 'Sebelumnya',
  SUBMIT: 'Kirimkan',
  NEXT: 'Berikutnya',
  REGISTER: 'Daftar',
  REGISTER_DESCRIPTION: 'Mengelola dan melindungi semua yang penting',
  CREATE_A_NEW_ACCOUNT: 'Buat akun baru',
  ACCOUNT_CREATED: 'Akun telah dibuat',
  ACCOUNT_CREATED_SUCCESSFULLY_MSG:
    '\nEmail telah dikirim ke <strong> {{email}} </strong> untuk memverifikasi alamat email anda. Silakan ikuti instruksi di email tersebut untuk mengaktifkan akun anda.',
  RETURN_TO_HOMEPAGE: 'Kembali ke beranda',
  INPUT_PASSPORT_NUMBER_MSG: 'Masukkan nomor paspor!',
  MOBLIE_NUMBER_WITH_CODE: 'Nomor ponsel (pilih kode negara)',
  INPUT_BASE_CURRENCY_MSG: 'Pilih mata uang dasar!',
  BASE_CURRENCY_TOOLTIP:
    'Ini adalah mata uang default untuk aset dan kewajiban anda untuk dicatat di Register. Anda juga dapat mengubahnya nanti di Pengaturan.',
  MONTHS: 'bulan',
  SELECT_COUNTRY_CODE: 'Pilih kode negara',
  INVALID_MOBLIE_NUMBER: 'Nomor ponsel tidak valid',
  iS_LONG_ENOUGHT: 'Minimum 8 karakter',
  HAS_NUMBER: 'Minimum 1 angka',
  HAS_SPECIAL_CHAR: 'Minimum 1 karakter spesial',
  HAS_UPPERCASE: 'Minimum 1 huruf besar',
  HAS_LOWERCASE: 'Minimum 1 huruf kecil',
  INVALID_PASSWORD: 'Kata sandi tidak valid',
  THANKS_AND_GOODBYE: 'Terima kasih dan sampai jumpa',
  DELETED_ACCOUNT_SUCCESSFULLY_MSG:
    '\nAkun <strong> vaultbox </strong> anda telah dihapus.',
  RESET_PASSWORD_BY_EAMIL: 'Reset kata sandi melalui email',
  COMPLETE: 'Selesai',
  ENTER_YOUR_EMAIL_ADDRESS: 'Masukkan alamat email anda',
  RESET_PASSWORD_WITH_DEPUTIES_DESCRIPTION:
    '\nPermintaan pemulihan akun telah dikirim ke Deputi anda. Anda akan dapat masuk dengan kata sandi baru setelah Deputi tersebut membuka akun anda.',
  RESET_PASSWORD_DESCRIPTION:
    'Akun anda telah direset karena anda tidak memiliki cukup Deputi untuk membantu pengembalian data anda.',
  CLICK_TO_GO_TO_HOMEPAGE: 'Klik <i>Finish</i> untuk kembali ke Beranda.',
  ACCOUNT_RECOVERY: 'Pemulihan akun',
  RECOVER_ACCESS_TO_YOUR_ACCOUNT: 'Pulihkan akses ke akun anda',
  FINISH: 'Selesai',
  ABOUT_US: 'Tentang kami',
  HELP: 'Bantuan',
  PRIVACY: 'Privasi',
  ALREADY_HAVE_AN_ACCOUNT: 'Sudah memiliki akun?',
  NEW_TO_VAULTBOX: 'Baru di <strong>vaultbox</strong>?',
  RESET_PASSWORD_TITLE: 'Kata sandi baru.',
  ACCOUNT_ACTIVATION_FAILED: 'Aktivasi akun gagal.',
  ACCOUNT_ACTIVATION_SUCCESSED:
    'Akun <strong>vaultbox</strong> anda telah diaktifkan.',
  ACTIVATING: 'Mengaktifkan...',
  DATA_RECOVERY: 'Pemulihan data',
  DATA_RECOVERY_SUMMARY:
    '\nMasukkan kata sandi Anda untuk memperbarui enkripsi data',
  SUCCESSFULLY_RECOVERED_DATA: '\nBerhasil memulihkan data',
  FAILED_TO_RECOVER_DATA: 'Gagal memulihkan data',
  UPDATE_PHONE_NUMBER_REQUEST_SUCCESSFULLY:
    '\nPermintaan telah dikirim ke Deputi Anda. Mohon menunggu persetujuannya.',
  YOU_DO_NOT_HAVE_ANY_DEPUTIES: 'Anda tidak memiliki Deputi',
  RESET_PHONE_NUMBER: 'Reset nomor ponsel',
  RESET_PHONE_NUMBER_SUMMARY:
    'Ubah nomor ponsel dengan bantuan dari Deputi anda',
  NEW_PHONE_NUMBER: 'Nomor ponsel baru (pilih kode negara)',
  SUCCESSFULLY_RESTORED_ITEMS: 'Berhasil memulihkan objek',
  FAILED_TO_RESTORE_ITEMS: 'Gagal memulihkan objek',
  PERMANENTLY_DELETE: 'Hapus secara permanen',
  PERMANENTLY_DELETE_ITEM_CONFIRM:
    'Apakah anda yakin ingin menghapus objek ini secara permanen?\n',
  PERMANENTLY_DELETE_ITEMS_CONFIRM:
    'Apakah anda yakin ingin menghapus objek ini secara permanen?\n',
  SUCCESSFULLY_DELETED_ITEM: 'Berhasil menghapus objek',
  SUCCESSFULLY_DELETED_ITEMS: 'Berhasil menghapus {{count}} objek',
  FAILED_TO_DELETE_ITEM: 'Gagal menghapus objek',
  FAILED_TO_DELETE_ITEMS: 'Gagal menghapus objek',
  PERMANENTLY_DELETE_ALL: 'Hapus semua secara permanen',
  PERMANENTLY_DELETE_ALL_CONFIRM:
    'Anda yakin ingin menghapus semua objek secara permanen?',
  SUCCESSFULLY_DELETED_ALL_ITEMS: 'Berhasil menghapus semua objek',
  RESTORE_ALL: 'Pulihkan semua',
  RESTORE_ALL_CONFIRM: 'Apakah anda yakin ingin memulihkan semua objek?',
  DELETE_ASSETS_AND_LIABILITIES: 'Aset & Kewajiban yang dihapus',
  DELETED_CONTACTS: 'Kontak yang dihapus',
  DELETED_FILE_AND_FOLDERS: 'File & Folder yang dihapus',
  SELECTED_RECORDS: 'Catatan yang dipilih',
  BULK_DELETE: 'Hapus massal',
  SEARCH_FIL_OR_FOLDER: 'Cari file atau folder',
  SEARCH_CONTACT: 'Cari kontak',
  SEARCH_ASSET_OR_LIABILITY: 'Cari aset atau kewajiban',
  TOUR_INTRO:
    '\n<strong> vaultbox </strong> - brankas digital Anda. Perinci aset dan kewajiban anda, monitor kekayaan bersih Anda, unggah dokumen penting dan banyak lagi. Mari mulai mengelola dan melindungi semua yang penting bagi Anda.',
  GENERAL_MENU:
    'Anda dapat dengan mudah menambahkan aset dan kewajiban di <strong> Register </strong>, mengunggah dokumen ke <strong> File </strong>, menambahkan teman, penyedia layanan, dan detail anggota keluarga anda di <strong> Kontak </strong>, tentukan siapa yang akan menjadi deputi anda untuk mengakses <strong> vaultbox </strong> anda setelah kematian atau selama ketidakmampuan dalam <strong> Deputi </strong> dan gunakan <strong> Manajemen Warisan </strong> untuk merencanakan perihal warisan anda.',
  ASSET_MENU:
    'Navigasikan ke <strong> Register </strong> untuk menggabungkan, melihat, dan mengelola semua aset dan kewajiban anda.',
  CREATE_NEW_ASSET_BUTTON:
    'Klik "Buat baru" untuk menambahkan aset atau kewajiban baru. Atau, anda dapat mengklik tombol + hijau di kanan bawah untuk melakukannya.',
  CREATE_ASSET_SELECT_TYPE:
    '\nPilih aset atau kewajiban yang berlaku untuk mengisi <strong> Register </strong> anda.',
  CREATE_ASSET_FORM:
    'Contoh informasi telah diisi sebelumnya di bidang yang relevan untuk memandu anda. Coba masukkan informasi anda sendiri dan klik "Lanjut" setelah anda selesai.',
  SAVE_ASSET_BUTTON:
    '\nSetelah anda siap untuk menyimpan aset anda ke <strong> Register </strong>, klik "Simpan". Jika anda ingin mengedit detail anda, klik "Kembali".',
  SAVE_ASSET_SUCCESS: 'Anda berhasil menambahkan aset baru.',
  FILE_MENU:
    'Navigasikan ke <strong> File </strong> untuk menyimpan dan mengatur file anda dengan aman dan sistematis.',
  CREATE_FILE_BUTTON: '\nKlik "Unggah" untuk mulai mengunggah file baru.',
  ADD_FILE_UPLOAD:
    'Klik atau tarik file anda ke area ini untuk diunggah ke <strong> File </strong>.',
  ADD_FILE_INFO:
    'Beri label, kategorikan, dan tandai file anda atau buat folder baru dengan mudah. Folder default di <strong> File </strong> membantu Anda mengatur dengan cepat.',
  SAVE_FILE_BUTTON:
    'Setelah anda siap untuk menyimpan file anda, klik "Simpan". Jika anda ingin mengubah detailnya, klik "Kembali".',
  UPLOAD_FILE_SUCCESS: 'Anda telah berhasil mengunggah file baru.',
  CONTACT_MENU:
    '\nNavigasikan ke <strong> Kontak </strong> untuk menambahkan detail kontak anggota keluarga, rekan bisnis, atau penyedia layanan anda.',
  CREATE_CONTACT_BUTTON: 'Klik "Buat baru" untuk membuat kontak baru.',
  CREATE_CONTACT_FORM:
    '\nIsi semua informasi penting untuk kontak anda, individu atau organisasi di sini. Anda dapat menandai kontak dengan aset atau kewajiban tertentu di <strong> Register </strong>.',
  SAVE_CONTACT_BUTTON:
    'Setelah anda siap untuk menyimpan kontak, klik "Simpan". Jika anda perlu mengubah detailnya, klik "Kembali".',
  SAVE_CONTACT_SUCCESS: 'Anda berhasil menambahkan kontak baru.',
  REVIEW_REGISTRY:
    '\nNavigasikan ke <strong> Register </strong> untuk meninjau aset anda dan memperbarui detailnya.',
  CREATED_ASSET:
    '\nPilih salah satu aset anda untuk meninjau dan memperbarui detail.',
  ASSET_OVERVIEW:
    'Lihat dengan jelas bagaimana kinerja aset anda dari waktu ke waktu dengan mudah',
  EDIT_ASSET_BUTTON: 'Klik "Edit" untuk mengubah informasi apa pun.',
  LINK_EXISTED_DOCS:
    '\nKlik tombol "+" untuk menghubungkan file apa pun yang terkait dengan aset ini.',
  SELECT_DOCS_TO_LINK:
    '\nDari <strong> File </strong>, pilih file apa saja untuk dihubungkan. Klik "Berikutnya" untuk menghubungkannya.',
  SAVE_SELECTED_DOCS:
    '\nJika sudah siap, klik "Pilih". Untuk memilih file lain, klik "Kembali".',
  NEW_DOCS_LINK: 'Hubungan file dengan aset sekarang sudah selesai.',
  LINK_EXISTED_CONTACTS:
    '\nUntuk menghubungkan kontak dengan aset tertentu, klik "+" untuk menghubungkan kontak apa pun yang terkait dengan aset ini.',
  CONTACTS_LINK_MODAL:
    '\nPilih kontak dari <strong> Kontak </strong>, lalu klik "Simpan".',
  NEW_CONTACTS_LINK: 'Hubungan kontak dengan aset ini sekarang sudah selesai.',
  SAVE_LINKED_ASSET: 'Klik "Simpan" setelah selesai',
  SAVE_LINKED_ASSET_SUCCESS: 'Perubahan tersimpan.',
  DEPUTIES_MENU:
    'Anda memiliki opsi untuk menunjuk Deputi untuk mengakses <strong> vaultbox </strong> anda. Memiliki Deputi membantu anda dengan manajemen warisan.',
  CURRENT_DEPUTIES:
    '\nTampilkan, cabut, atau perbarui Deputi yang anda di sini.',
  PENDING_DEPUTIES:
    '\nPeriksa apakah semua Deputi yang anda pilih telah menerima permintaan anda untuk menjadi Deputi anda di sini.',
  PENDING_FILE_UNLOCK:
    'Anda dapat menandai semua file anda sebagai rahasia di <strong> File </strong>. Mereka hanya dapat dibuka setelah Deputi membukanya. Lihat status buka kunci file terbaru di sini.',
  COMPLETE_TOUR_MODAL:
    '\nAnda telah sampai di akhir tur <strong> vaultbox </strong>. Anda dapat mengulangi tur ini dengan membuka menu "Akses cepat", lalu memulai tur <strong> vaultbox </strong> lagi.',
  VAULTBOX_TOUR_STOPPED: '\nTur vaultbox dihentikan',
  VAULTBOX_TOUR_STOPPED_SUMMARY:
    "Anda telah menghentikan tur vaultbox. Anda dapat mengikuti tur lagi kapan saja di 'Menu akses cepat > Tur Vaultbox'.",
  COMPLETE_TOUR: '\nTur lengkap',
  START_TOUR: 'Mulai tur',
  STOP_TOUR: 'Hentikan tur',
  SKIP: 'Lewati',
  VAULTBOX_TOUR: 'Tur vaultbox',
  CONTINUE_WHERE_YOU_LEFT_OFF:
    'Lanjutkan dari bagian terakhir yang anda tinggalkan',
  START_FROM_BEGINNING: 'Mulai dari awal',
  FAILED_TO_LOAD_YOUR_VAULTBOX_DATA: '\nGagal memuat data vaultbox anda',
  VIEW_DETAILS: 'Tampilkan detail',
  PLEASE_LOG_IN_AGAIN_TO_RETRY: '\nHarap masuk untuk mencoba lagi.',
  LOG_OUT: 'Keluar',
  SHOWED_MOBILE_PROMPT:
    '\nTerima kasih telah membuka vaultbox anda di ponsel. Untuk pengalaman yang optimal dan informasi yang lebih detail, silakan masuk melalui browser komputer anda',
  BACK_DATED_AMEND_CONFIRMATION: 'Konfirmasi perubahan di tanggal sebelumnya',
  BACK_DATED_AMEND_SUMMARY:
    '\nPerubahan posisi dan penilaian akan dicatat sesuai pilihan',
  ARE_YOU_SURE_YOU_WANT_TO_CONTINUTE: 'Apakah anda yakin ingin melanjutkan?',
  UPLOAD_DOCUMENT: 'Unggah dokumen',
  INFORMATION: 'Informasi',
  CREATE_NEW: 'Buat baru',
  ASSET_LIABILITY_RECORD_NOT_FOUND: 'Catatan aset / kewajiban tidak ditemukan.',
  SUCCESSFULLY_LINKED_OR_UNLINKED_DOCUMENT:
    'Berhasil {{linkedOrUnlinked}} dokumen',
  LINKED: 'Dihubungkan',
  UNLINKED: 'Dipisahkan',
  SUCCESSFULLY_LINKED_ASSET_LIABILITY: 'Berhasil menghubungkan aset/kewajiban',
  SUCCESSFULLY_UNLINKED_ASSET_LIABILITY: '\nBerhasil memisahkan aset/kewajiban',
  SUCCESSFULLY_UNLINKED_CONTACT: 'Berhasil memisahkan kontak',
  FAILED_TO_UNLINK_CONTACT: 'Gagal memisahkan kontak',
  SUCCESSFULLY_LINKED_CONTACT: 'Berhasil menghubungkan kontak',
  FAILED_TO_LINK_CONTACT: 'Gagal menghubungkan kontak',
  DETAIL: 'Detail',
  ASSET_NAME: 'Nama aset',
  LIABILITY_NAME: 'Nama kewajiban',
  OVERVIEW: 'Gambaran',
  FAILED_TO_EXPORT_TO_PNG: 'Gagal untuk diekspor ke PNG',
  EXPORT_TO_PNG: 'Ekspor ke PNG',
  TIME_RANGE: 'Rentang waktu',
  DATA: 'Data',
  FILES_AND_FOLDERS: 'File & Folder',
  FOLDERS_COUNT_AND_FILES_COUNT:
    '{{foldersCount}} folder & {{filesCount}} file',
  SEARCH_FILE_OR_FOLDER_NAME: 'Cari nama file atau folder',
  LINK_NEW_FILE: 'Hubungkan file baru',
  LINK_ASSETS: 'Hubungkan aset',
  LINK_LIABILITIES: 'Hubungkan kewajiban',
  CUSTOM: 'Nonstandar',
  SELECT_EXCHANGE: 'Pilih bursa',
  EXCHANGE_NAME: 'Nama bursa',
  EXCHANGE_CODE: 'Kode bursa',
  CURRENCY_NAME: 'Nama mata uang',
  CURRENCY_CODE: 'Kode mata uang',
  ASSET_LIABILITY_CURRENCY: 'Mata uang aset / kewajiban',
  TYPE_CURRENCY: '{{type}} mata uang',
  LINK_TO_DOCUMENTS: 'Hubungkan ke dokumen',
  LINK_TO_ASSETS_LIABILITIES: 'Hubungkan ke aset / kewajiban',
  INCLUDE_VAULE_IN_NETWORTH: 'Sertakan nilai dalam kekayaan bersih',
  BACK_DATED_AMEND: 'Perubahan mundur tanggal',
  FAILED_TO_GET_REGISTRY_COLUMNS: 'Gagal mendapatkan kolom Register',
  DELETE_VALUATION_CHANGE: 'Hapus perubahan nilai',
  DELETE_VALUATION_CHANGE_CONFIRM:
    'Apakah anda yakin ingin menghapus perubahan penilaian objek ini pada',
  DELETE_VALUATION_BEFORE_THIS_DATE:
    'Hapus juga penilaian <strong> sebelum </strong> tanggal ini\n',
  DELETE_VALUATION_AFTER_THIS_DATE:
    'Hapus juga penilaian <strong> setelah </strong> tanggal ini\n',
  SUCCESSFULLY_DELETED_ASSET_LIABILITY: 'Berhasil menghapus aset/kewajiban',
  FAILED_TO_DELETE_ASSET_LIABILITY: 'Gagal menghapus aset/kewajiban',
  VALUATION_IN_BASE_CURRENCY_WITH_PERCENTAGE_OWNERSHIP_TOOLTIP:
    '\nJumlah penilaian dalam mata uang dasar anda dengan memperhitungkan persentase kepemilikan.',
  TOTAL: 'Total',
  NO_ASSETS: 'Tidak ada aset',
  ENTER_THE_SEARCH_KEYWORD: 'Masukkan kata kunci pencarian',
  CREATE: 'Buat',
  NO_LIABILITIES: 'Tidak ada kewajiban',
  BALANCE_SHEET: 'Neraca',
  GROUP: 'Kelompok',
  GROUP_BY: 'Kelompokkan menurut',
  NET_WORTH: 'Kekayaan Bersih',
  NO_ASSETS_LIABILITIES: 'Tidak ada aset / kewajiban',
  REPORTS: 'Laporan',
  NO_DOCUMENTS: 'Tidak ada dokumen',
  ENTER_LABEL: 'Masukkan {{label}}',
  SELECT_LABEL: 'Pilih {{label}}',
  ADD_NEW: 'Tambah baru',
  TO_EXCEL: 'Ke Excel',
  TO_PDF: 'Ke PDF',
  EXPORT: 'Ekspor',
  TOTAL_LIABILITIES: 'Total Kewajiban',
  EXCLUDED_FROM_THE_TOTAL_NET_WORTH:
    'Tidak termasuk dari total Kekayaan Bersih',
  CONTACT_LINKS: 'Hubungan kontak',
  UNLINK_CONTACT_CONFIRM:
    'Apakah anda yakin akan membatalkan hubungan kontak ini?',
  YES: 'Iya',
  NO: 'Tidak',
  LINK_CONTACTS: 'Hubungkan kontak',
  SELECT_CONTACT: 'Pilih kontak',
  ADD_NEW_CONTACT: 'Tambahkan kontak baru',
  DOCUMENT_LINKS: 'Hubungan dokumen',
  UNLINK_DOCUMENT_CONFIRM:
    'Apakah anda yakin akan membatalkan hubungan dokumen ini?',
  Select: 'Pilih',
  MORE_THAN_TEN_COLUMNS_SELECTED: 'Lebih dari 10 kolom dipilih',
  NO_DATA: 'Tidak ada data',
  INVALID_FILE_MISSING_THE_HEADER_ROW:
    'File tidak valid: judul baris tidak ada',
  INVALID_FILE_MISSING_THE_STATEMENT_HEADER_ROW:
    'File tidak valid: judul baris pernyataan tidak ada',
  INVALID_FILE_MISSING_COLUMN: 'File tidak valid: kolom {{name}} tidak ada',
  INVALID_FILE_MISSING_VALUATION_DATE:
    'File tidak valid: tanggal penilaian tidak ada',
  UPDATING_ASSETS_LIABILITIES: 'Perbarui aset / kewajiban?',
  UPDATING_ASSETS_LIABILITIES_SUMMARY:
    '{{updatedRecordsCount}} objek di register anda akan diperbarui',
  SUCCESSFULLY_IMPORTED_DATA: 'Berhasil mengimpor data',
  FAILED_TO_IMPORT_DATA: 'Gagal mengimpor data',
  IMPORT_FROM_CSV: 'Impor dari CSV',
  TEMPLATE: 'Template',
  DOWNLOAD_SAMPLE_TEMPLATE: 'Unduh template',
  SELECT_A_TEMPLATE: 'Pilih template',
  FROM_CSV: 'Dari CSV',
  FROM_EXCEL: 'Dari Excel',
  IMPORT: 'Impor',
  IMPORT_FROM_EXCEL: 'Impor dari Excel',
  INVALID_FILE_TYPE: 'Jenis file tidak valid',
  ASSET_LIABILITY_RECORDS: 'catatan aset / kewajiban',
  CONTACT_RECORDS: 'kontak',
  EVENT_RECORDS: 'acara',
  DOCUMENT_RECORDS: 'dokumen',
  FILE_RECORDS: 'File',
  WILL_BE_UNLINKED_FROM_THIS_ITEM:
    'akan dibatalkan hubungannya dengan objek ini',
  DELETE_ALL_HISTORICAL_VALUATIONS_DATA: 'Hapus semua data penilaian historis',
  ENTER: 'Masuk',
  SELECT: 'Pilih',
  SELECTED: 'Terpilih',
  ADD: 'Tambahkan',
  AUTHENTICATOR_APP: 'Aplikasi Autentikator',
  BRIEF_STEPS: 'Langkah singkat',
  GOOGLE_AUTHENTICATOR: 'Google Authenticator',
  OPEN_THE_APP: 'Buka aplikasi.',
  TAP_BEGIN_SETUP: 'Pilih "Mulai pengaturan."',
  TAP_SCAN_BARCODE: 'Pilih "Pindai kode batang."',
  SCAN_THE_QR_CODE: 'Pindai kode QR',
  TAP_PLUS_BUTTON: 'Pilih tombol "+"',
  TAP_OTHER: 'Pilih "Lainnya (Google, Facebook, dll)"',
  ENTER_THE_AUTHENTICATION_CODE:
    'Masukkan kode autentikasi ke dalam kotak di bawah pada layar ini.',
  LASTPASS_AUTHENTICATOR: 'LastPass Authenticator',
  MICROSOFT_AUTHENTICATOR: 'Microsoft Authenticator',
  MFA_FIRST_CONTENT:
    '\nIni adalah langkah singkat untuk memindai kode QR menggunakan beberapa aplikasi autentikator yang umum digunakan.',
  MFA_SECOND_CONTENT:
    '\nInformasi yang diberikan di atas hanya untuk kenyamanan dan mungkin berbeda dari penggunaan sebenarnya.',
  IPHONE: 'iPhone',
  ANDROID: 'Android',
  APP_STORE: 'App store',
  PLAY_STORE: 'Play store',
  MFA_TABLE_SUMMARY:
    'Aplikasi dan tautan ini disediakan hanya untuk kenyamanan dan bukan merupakan dukungan atau jaminan atas kesesuaiannya.',
  CODE: 'Kode',
  CODE_IS_REQUIRED: '\nKode diperlukan!',
  FAILED_TO_ADD_OTHER_CRYPTO: '\nGagal menambahkan kripto lainnya',
  SELECT_CRYPTOCURRENCY: 'Pilih mata uang kripto',
  OTHER: 'Lain',
  AN_ERROR_HAS_OCCURRED: 'Telah terjadi kesalahan',
  MULTI_FACTOR_AUTHENTICATION: 'Autentikasi multifaktor',
  DID_NOT_RECEIVE_THE_SMS: 'Belum menerima SMS?',
  RESEND_SMS: 'Kirim ulang SMS',
  SETUP_MFA_AUTHENTICATION_APP_FISRT_CONTENT:
    'Untuk mengatur autentikasi multifaktor di vaultbox, anda harus memiliki aplikasi autentikator yang kompatibel di ponsel anda.',
  SETUP_MFA_AUTHENTICATION_APP_SECOND_CONTENT:
    '\nJika anda tidak memilikinya, anda dapat mempertimbangkan untuk menginstalnya dari toko aplikasi khusus untuk jenis ponsel anda.',
  SETUP_MFA_AUTHENTICATION_APP_THIRD_CONTENT:
    '\nSaat anda siap dengan aplikasi autentikator yang telah diinstal, klik Berikutnya.',
  SETUP_MFA_AUTHENTICATION_APP_HEADER:
    '\nLihat daftar aplikasi autentikator yang umum digunakan',
  ADD_VAULTBOX_TO_YOUR_AUTHENTICATOR_APP:
    '\nTambahkan vaultbox ke aplikasi autentikator anda',
  USE_YOUR_AUTHENTICATOR_APP_TO_SCAN_THIS_QR_CODE:
    '\nGunakan aplikasi autentikator anda untuk memindai kode QR ini.',
  NEED_AN_AUTHENTICATOR_APP: '\nButuh aplikasi autentikator?',
  HOW_DO_I_SCAN_THE_QR_CODE_FROM_MY_AUTHENTICATOR_APP:
    '\nBagaimana cara memindai kode QR dari aplikasi autentikator saya?',
  SHOW_CODE_FOR_MANUAL_CONFIGURATION: 'Tampilkan kode untuk konfigurasi manual',
  ADD_VAULTBOX_TO_YOUR_AUTHENTICATOR_APP_FIRST_CONTENT:
    '\nPastikan anda hanya memiliki satu profil vaultbox di aplikasi autentikator. Jika anda memiliki lebih, kami sarankan anda menghapusnya dan mulai lagi.',
  ADD_VAULTBOX_TO_YOUR_AUTHENTICATOR_APP_SECOND_CONTENT:
    '\nUntuk melanjutkan, masukkan kode autentikasi dari aplikasi autentikator anda. Kode ini akan diperbarui secara otomatis, jadi masukkan lalu klik Berikutnya sehingga kami dapat memverifikasinya.',
  BACK: 'Kembali',
  SETUP_MFA_REVIEW_SUMMARY:
    'Aplikasi autentikator anda untuk vaultbox sudah siap. Saat anda masuk lagi, selain daripada alamat email dan kata sandi, anda akan diminta untuk memasukkan kode autentikasi dari aplikasi autentikator anda.',
  SETUP_MFA_SUMMARY:
    '\nUntuk melindungi vaultbox, anda perlu mengatur autentikasi multifaktor sebelum melanjutkan.',
  LEARN_MORE_ABOUT_SETTING_UP_MULTI_FACTOR_AUTHENTICATION:
    'Pelajari lebih lanjut tentang mengatur autentikasi multifaktor.',
  MINOR_EDIT: 'Perubahan kecil',
  MINOR_EDIT_TOOLTIP:
    'Jika perubahan itu kecil maka perubahan itu tidak ditampilkan di daftar versi',
  GENERAL_INFO: 'Informasi umum',
  ADDRESS: 'Alamat',
  LABEL: 'Label',
  DOCUMENTS_DETAIL: '{{foldersCount}} folder & {{filesCount}} file',
  CONTACT_USED_IN_LEGACY:
    '\nKontak ini tidak dapat dihapus karena digunakan dalam manajemen warisan.',
  FAILED_TO_DELETE_CONTACT: 'Gagal menghapus kontak',
  WILL_BE_UNLINKED_FROM_THIS_CONTACT:
    '\nakan dibatalkan hubungannya dari kontak ini.',
  GROUP_INFORMATION: 'Informasi kelompok',
  PEOPLE: 'Orang-orang',
  CONTACT_INFORMATION: 'Informasi kontak',
  NOTE: 'Catatan',
  VERSION: 'Versi',
  SELECT_CONTACTS_TO_IMPORT: 'Pilih kontak untuk diimpor',
  FAILED_TO_IMPORT_CONTACTS: 'Kontak gagal diimpor',
  INVALID_RECORDS_CANT_BE_IMPORTED:
    '\nCatatan tidak valid (nama yang diberikan tidak ada) tidak dapat diimpor.',
  ALL: 'Semua',
  NO_CONTACTS_TO_IMPORT: 'Tidak ada kontak untuk diimpor',
  REFERENCE_LABEL: 'Label referensi',
  REFERENCE_INFO: 'Info referensi',
  FROM_VCARD: 'Dari vCard',
  FAILED_TO_EXPORT_TO_EXCEL: 'Gagal diekspor ke Excel',
  FAILED_TO_EXPORT_TO_PDF: 'Gagal diekspor ke PDF',
  TAG: 'Tanda',
  NO_CONTACTS_RESULTS: 'Tidak ada hasil kontak',
  SEARCH_CONTACT_FROM_LEFT_SIDEBAR:
    '\nSilakan cari nama kontak atau pilih kontak dari menu kiri untuk mendapatkan informasi',
  SEARCH_CONTACT_FROM_CONTACTS_LIST:
    '\nSilakan cari nama kontak atau pilih kontak dari daftar Kontak untuk mendapatkan informasi',
  NO_CONTACTS: 'Tidak ada kontak',
  INVALID_TEMPLATE: 'Template tidak valid',
  SHEET: 'Lembar',
  ROW: 'Baris',
  MISSING_COLUMN: 'Kolom hilang',
  IS_MANDATORY: 'adalah wajib',
  SUCCESSFULLY_IMPORT_CONTACTS:
    '\nBerhasil mengimpor {{selectedContacts}} kontak',
  IMPORT_FROM_VCARD: 'Impor dari vCard',
  UPDATE_PENDING_ACCEPTANCE_FROM_DEPUTY:
    'Perbarui status penerimaan dari Deputi',
  UPDATE_MY_INFO: 'Perbarui info saya',
  STATUS: 'Status',
  BACKUP_KEY_DISTRIBUTED: 'Kunci cadangan didistribusikan',
  ACCEPTED: 'Diterima',
  CANNOT_REVOKE_DEPUTY: 'Deputi tidak dapat dicabut',
  ARE_YOU_SURE_TO_REVOKE_THIS_DEPUTY: 'Anda yakin akan mencabut Deputi ini?',
  CANNOT_REVOKE_DEPUTY_SUMMARY:
    '\nJumlah deputi yang diperlukan untuk membuka kunci vaultbox anda adalah <strong> {shareThreshold} </strong>. Untuk dapat mencabut deputi, anda perlu mengurangi angka ini.',
  WHY_DO_I_NEED_TO_APPOINT_DEPUTIES: 'Mengapa saya perlu menunjuk Deputi?',
  DEPUTY_SUMMARY:
    '\nDeputi bersifat opsional. Tetapi jika anda kehilangan kata sandi atau akses ke <strong> vaultbox </strong>, anda harus memiliki Deputi untuk mereset kata sandi atau memulihkan akses ke <strong> vaultbox </strong> anda. Kami melakukan ini dengan membuat kunci pemulihan untuk disimpan dengan aman oleh Deputi anda.',
  ADD_A_DEPUTY: 'Tambahkan Deputi',
  SHARES_THRESHOLD_SUMMARY:
    'Jumlah deputi yang diperlukan untuk membuka vaultbox anda',
  CLICK_HERE_TO_RETRY: '\nKlik di sini untuk mencoba lagi',
  MY_CURRENT_DEPUTIES: 'Deputi saya sekarang',
  NO_DEPUTIES: 'Tidak ada Deputi',
  MY_PENDING_DEPUTIES: 'Calon Deputi saya',
  NO_PENDING_DEPUTIES: 'Tidak ada calon Deputi',
  MY_PENDING_FILE_UNLOCKS: 'File saya dalam proses pembukaan',
  NO_PENDING_FILE_UNLOCKS: 'Tidak ada file dalam proses pembukaan',
  DEPUTIES: 'Deputi',
  PRIMARY_USERS_WHO_I_AM_A_DEPUTY_FOR:
    'Pengguna utama di mana saya adalah Deputi',
  YOU_HAVE_BEEN_ENTRUSTED_AS_DEPUTY:
    'Anda telah dipercaya sebagai Deputi untuk Pengguna Utama berikut.',
  LEARN_MORE_ABOUT_BEING_A_DEPUTY:
    'Pelajari lebih lanjut tentang menjadi Deputi.',
  ID_CONTAINS_RESIDENTIAL_ADDRESS: 'KTP berisi alamat tempat tinggal.',
  ADDRESS_PROOF: 'Bukti alamat',
  ID_PASSPORT: 'KTP / Paspor',
  PRIMARY_USER_DETAILS: 'Detail pengguna utama',
  DECLINE: 'Tolak',
  ACCEPT: 'Terima',
  REJECT: 'Tidak setuju',
  APPROVE: 'Setuju',
  CURRENT_DETAILS: 'Detail terkini',
  PENDING_UPDATE_DETAILS: 'Menunggu pembaruan detail',
  LEGACY_MANAGEMENT: 'Manajemen warisan',
  INSTRUCTION: 'Instruksi',
  RECEIVED_BACKUP_KEY: 'Kunci cadangan diterima',
  NOT_YET_RECEIVED_BACKUP_KEY: 'Kunci cadangan belum diterima',
  LEGACY_MANAGEMENT_ENABLED: 'Manajemen warisan diaktifkan',
  FROM: 'Dari',
  PENDING: 'Menunggu',
  REQUEST_ACCEPTED: 'Permintaan diterima',
  REQUEST_REJECTED: 'Permintaan ditolak',
  FAILED_TO_ACCEPT_REQUEST: 'Gagal menerima permintaan',
  FAILED_TO_APPROVE_REQUEST: 'Gagal menyetujui permintaan',
  FAILED_TO_REJECT_REQUEST: 'Gagal menolak permintaan',
  FAILED_TO_HANDLE_DEPUTY_REQUEST: 'Gagal menangani permintaan Deputi',
  FAILED_TO_HANDLE_UPDATE_USER_DETAILS_REQUEST:
    'Gagal menangani permintaan pembaruan detail pengguna',
  PROFESSIONAL_DEPUTY_ACCESS_DELEGATIONS: 'Delegasi akses deputy profesional',
  PENDING_MY_ACCEPTANCE_TO_BE_DEPUTY: 'Menunggu penerimaan saya menjadi Deputi',
  NO_PENDING_REQUESTS: 'Tidak ada permintaan yang menunggu keputusan',
  I_AM_CURRENTLY_A_DEPUTY_FOR_THESE_PRIMARY_USERS:
    'Saat ini saya menjadi Deputi untuk Pengguna Utama ini',
  NO_PRIMARY_USERS: 'Tidak ada pengguna utama',
  REQUEST_TO_UPDATE_THEIR_DETAILS: 'Permintaan untuk memperbarui detail mereka',
  NO_REQUESTS: 'Tidak ada permintaan',
  APPROVAL_SATUS: 'Status persetujuan',
  NEW_PHONE_NUMBER_DEPUTY: 'Nomor ponsel baru',
  REQUEST_APPROVED: 'Permintaan disetujui',
  REQUESTS_TO_RESET_THEIR_PASSWORD:
    'Permintaan untuk mereset kata sandi mereka',
  REQUEST_TO_UNLOCK_THEIR_FILES: 'Permintaan untuk membuka file mereka',
  REQUEST_TO_CHANGE_THEIR_PHONE_NUMBER:
    'Permintaan untuk mengubah nomor ponsel mereka',
  SUCCESSFULLY_UPDATED_NUMBER_OF_DEPUTIES_REQUIRED:
    'Berhasil memperbarui jumlah deputi yang diperlukan',
  FAILED_TO_UPDATE_NUMBER_OF_DEPUTIES_REQUIRED:
    'Gagal memperbarui jumlah deputi yang diperlukan',
  NUMBER_OF_DEPUTIES_REQUIRED_TO_UNLOCK_VAULTBOX:
    'Jumlah deputi yang diperlukan untuk membuka vaultbox',
  ENTER_NUMBER_OF_DEPUTIES: 'Masukkan jumlah deputi',
  FAILDED_TO_LOAD_USER_DETAILS: 'Gagal memuat detail pengguna',
  SUCCESSFULLY_UPDATE_DETAILS: 'Berhasil memperbarui detail',
  FAILED_TO_UPDATE_DETAILS: 'Gagal memperbarui detail',
  USER_DETAILS: 'Detail pengguna',
  OWNER: 'Pemilik',
  UNLOCK: 'Membuka',
  LOCK: 'Mengunci',
  SUCCESSFULLY_UNLOCKED_VAULT: 'Berhasil membuka brankas',
  FAILED_TO_UNLOCK_VAULT: 'Gagal membuka brankas',
  SUCCESSFULLY_LOCKED_VAULT: 'Berhasil mengunci brankas',
  FAILED_TO_LOCK_VAULT: 'Gagal mengunci brankas',
  VAULTBOXES_OF_PRIMARY_USERS: 'vaultbox milik Pengguna Utama',
  NO_VAULTBOXES: 'Tidak ada vaultbox',
  FOLDER_NAME_EXISTS: 'Nama folder sudah ada',
  FAILED_TO_GET_FILE: 'Gagal mendapatkan file.',
  MARKING_FILE_AS_SECRET: 'Menandai file sebagai rahasia?',
  MARKING_FILE_AS_SECRET_SUMMARY:
    'Setelah ditandai sebagai rahasia, file hanya dapat dilihat atau diunduh setelah Deputi membukanya.',
  SUCCESSFULLY_MARKED_DOCUMENT_AS_SECRET:
    'Berhasil menandai dokumen sebagai rahasia',
  FAILED_TO_MARK_DOCUMENT_AS_SECRET: 'Gagal menandai dokumen sebagai rahasia',
  REQUEST_TO_UNLOCK_HAS_BEEN_SENT:
    'Permintaan untuk membuka kunci telah dikirim',
  RESEND_UNLOCK_REQUEST: 'Kirim ulang permintaan buka kunci',
  REQUEST_TO_UNLOCK: 'Minta untuk membuka kunci',
  MARK_SECRET_FILE_FIRST_TOOLTIP:
    'Jika sebuah file ditandai sebagai rahasia, itu hanya dapat dilihat atau diunduh setelah Deputi membukanya.',
  MARK_SECRET_FILE_SECOND_TOOLTIP:
    'Untuk dapat menandai file sebagai rahasia, diperlukan setidaknya satu Deputi.',
  MARK_SECRET: 'Tandai rahasia',
  SECRET_FILE: 'File rahasia',
  SECRET_FILE_SUMMARY:
    'File ini telah ditandai sebagai rahasia dan tidak dapat dilihat atau diunduh. Untuk mendapatkan kembali akses file, harap minta Deputi untuk membantu membukanya.',
  PENDING_UNLOCK: 'Menunggu buka kunci',
  PENDING_UNLOCK_SUMMARY: 'Permintaan buka kunci file telah dikirim ke Deputi.',
  FILE_INFORMATION: 'Informasi file',
  ORIGINAL_PATH: 'Lokasi awal',
  CREATED: 'Dibuat',
  SIZE: 'Ukuran',
  SUCCESSFULLY_UPLOADED_FILE: 'Berhasil mengunggah file',
  FAILED_TO_UPLOAD_FILE: 'Gagal mengunggah file',
  REMOVE_FROM_FAVOURITES: 'Hapus dari Favorit',
  ADD_TO_FAVOURITES: 'Tambahkan ke Favorit',
  FILE_USED_IN_LEGACY_MANAGEMENT:
    'File ini tidak dapat dihapus karena digunakan dalam manajemen warisan.',
  SUCCESSFULLY_DELETED: 'Berhasil dihapus',
  FAILED_TO_DELETE: 'Gagal dihapus',
  FOLDER: 'Folder',
  CANNOT_DELETE_FOLDERS_WITH_FILES_INSIDE:
    'Tidak dapat menghapus folder dengan file di dalamnya',
  CANNOT_BE_DELETED_BECAUSE_IT_USED_IN_LEGACY_MANAGEMENT:
    'File "{{name}}" tidak dapat dihapus karena digunakan dalam manajemen warisan.',
  SUCCESSFULLY_RENAMED_FOLDER: 'Berhasil mengubah nama folder',
  FAILED_TO_RENAME_FOLDER: 'Gagal mengubah nama folder',
  FAILED_TO: 'Gagal untuk',
  THE_DESTINATION_IS_A_SUBFOLDER:
    '\nFolder tujuan adalah subfolder dari folder asal.',
  SUCCESSFULLY_COPIED: 'Berhasil disalin',
  ITEM: 'Objek',
  ITEMS: 'Objek',
  FAILED_TO_COPY: 'Gagal menyalin',
  SUCCESSFULLY_MOVED_ITEM: 'Berhasil memindahkan objek',
  FAILED_TO_MOVE_ITEM: 'Gagal memindahkan objek',
  SUCCESSFULLY_MOVED_ITEMS: 'Berhasil memindahkan objek',
  FAILED_TO_MOVE_ITEMS: 'Gagal memindahkan objek',
  COPY: 'Salin',
  EDIT_CONTENT: 'Edit konten',
  CANT_DELETE_FOLDER_WITH_FILES_INSIDE:
    'Tidak dapat menghapus folder dengan file di dalamnya',
  FOLDERS: 'Folder',
  SUCCESSFULLY_CREATED_FOLDER: 'Berhasil membuat folder',
  FAILED_TO_CREATE_FOLDER: 'Gagal membuat folder',
  TEXT_FILE: 'File Teks',
  HOME: 'Beranda',
  SELECT_FOLDER: 'Pilih folder',
  SHOW_FOLDER_TREE: 'Tampilkan susunan folder',
  STORAGE: 'Penyimpanan',
  YOUR_STORAGE_INCLUDES_IN_TRASH:
    '\nPenyimpanan anda termasuk {{deletedStorage}} di Sampah. Kosongkan sampah anda untuk memberikan lebih banyak penyimpanan.',
  OF: 'dari',
  USED: 'Tersisa',
  SELECT_FILE_OR_FOLDER_TO_SEE_DETAILS:
    'Pilih file atau folder untuk melihat detailnya',
  NEW_TAG: 'Tag baru',
  CANT_BE_ENCRYPTED_AND_UPLOADED: 'tidak dapat dienkripsi dan diunggah',
  FILE_SIZE_LIMIT_IS: 'Batas ukuran file adalah',
  SAVE_TO_VAULTBOX_FOLDER: 'Simpan ke folder <strong> vaultbox </strong>',
  SAVE_TEXT_FILE: 'Simpan file teks',
  FILE_NAME_IS_REQUIRED: 'File name is required!',
  SUCCESSFULLY_CREATED_FILE: 'Berhasil membuat file',
  FAILED_TO_CREATE_FILE: 'Gagal membuat file',
  THE_SELECTED_FILE_IS_LOCK:
    'File yang dipilih terkunci dan tidak dapat digunakan',
  REMOVE_FILE: 'Hapus file',
  SELECT_FROM_UPLOADED_FILES: 'Pilih dari File yang telah diunggah',
  UPLOAD_OR_SELECT_FROM_UPLOADED_FILES:
    'Unggah {{labelForErrorMsg}} atau pilih dari File yang telah diunggah',
  QUICK_ACCESS: 'Akses cepat',
  DOWNLOAD_VAULTBOX: 'Unduh vaultbox',
  IMPORT_TEMPLATE: 'Impor contoh dokumen',
  FAILED_TO_DOWNLOAD_VAULTBOX: 'Gagal mengunduh vaultbox',
  SESSION_TIMEOUT: 'Batas waktu sesi telah lewat',
  SESSION_TIMEOUT_SUMMARY: 'Silakan masuk lagi untuk melanjutkan',
  FAILE_TO_GET_USER_DATA: 'Gagal mendapatkan data pengguna',
  FAILE_TO_GET_PRIMARY_USERS: 'Gagal mendapatkan pengguna utama',
  ARE_YOU_SURE_YOU_WANT_TO_SIGN_OUT: 'Anda yakin ingin keluar?',
  BACK_TO_MY_VAULTBOX: 'Kembali ke vaultbox saya',
  VIEWING_VAULTBOX_OF: 'Melihat vaultbox dari',
  PLEASE_APPOINT_YOUR_DEPUTIES:
    '\nHarap tunjuk Deputi anda, yang dapat membantu membuka <strong> vaultbox </strong> jika anda lupa kata sandi. Jika tidak, semua konten <strong> vaultbox </strong> anda akan dihapus ketika anda mereset kata sandi anda.',
  APPOINT_DEPUTIES_NOW: 'Tunjuk Deputi sekarang',
  REMIND_ME_LATER: 'Ingatkan saya nanti',
  DO_NOT_REMIND_ME_AGAIN: 'Jangan ingatkan saya lagi',
  YOU_HAVENT_SET_BASE_CURRENCY:
    'Anda belum menetapkan mata uang dasar anda. Mohon ditetapkan.',
  HERE: 'sini',
  SUBSCRIPTION_HAS_BEEN_CANCEL_AT:
    'Langganan <strong> vaultbox </strong> anda telah dibatalkan. Paket langganan {{nickname}} anda akan berakhir pada <strong> {{cancel_at}} </strong>',
  YOU_STILL_CAN_REACTIVATE_SUBSCRIPTION:
    'Anda masih dapat <2>mengaktifkan kembali</2> langganan anda sebelum berakhir.',
  REACTIVATE_SUBSCRIPTION: 'Aktifkan kembali langganan',
  REACTIVATE_SUBSCRIPTION_CONFIRM:
    'Anda yakin ingin mengaktifkan kembali langganan {{nickname}} anda?',
  THE_PAYMENT_BECAME_PAST_DUE:
    'Pembayaran untuk langganan anda gagal dan telah lewat jatuh tempo pada {{billing_cycle_anchor}}.',
  UPDATE_PAYMENT_INFORMATION:
    'Harap perbarui <2> informasi pembayaran </2> anda dalam waktu satu minggu, jika tidak, langganan anda saat ini akan dibatalkan dan diturunkan ke paket vaultbox Essential.',
  YOU_HAVENT_ADDED_ANY_PAYMENT_METHOD:
    'Anda belum menambahkan <2>metode pembayaran</2> apa pun. Harap tambahkan setidaknya satu <5> metode pembayaran </5>.',
  YOU_NEED_TO_ADD_MORE_DEPUTIES:
    'Jumlah deputi yang diperlukan untuk membuka kunci vaultbox anda adalah <strong> {{shareThreshold}} </strong> tetapi saat ini anda hanya memiliki <strong> {{acceptedDeputies}} </strong>. Anda perlu menambah deputi atau mengurangi jumlah deputi yang diperlukan.',
  UPDATE: 'Memperbarui',
  WHO_WILL_RECEIVE_THE_ACCESS_TO_YOUR_VAULTBOX:
    'Siapa yang akan menerima akses ke vaultbox anda?',
  LEGACY_ACCESSED_BY_PROFESSIONAL_DEPUTY:
    'Deputi Profesional Anda akan membuka kunci vaultbox anda dan memiliki akses ke seluruh konten. Mereka akan memutuskan apa yang harus dilakukan dengan konten berdasarkan instruksi anda (yang diunggah di langkah "Izin konten")',
  LEGACY_ACCESSED_BY_OTHER_SPECIFIC_PERSON:
    'Deputi Profesional anda akan membuka kunci vaultbox anda dan memberikan kepada orang tersebut konten yang ditentukan dalam langkah "Izin konten". Orang tersebut perlu membuktikan identitas dan hubungannya dengan Anda.',
  OTHER_PERSON: 'Orang lain',
  THE_SELECTED_FILE_IS_LOCK_AND_CANNOT_BE_USED:
    'File yang dipilih terkunci dan tidak dapat digunakan',
  SUCCESSFULLY_SAVED_LEGACY_MANAGEMENT_CONFIGURATION:
    'Berhasil menyimpan konfigurasi manajemen warisan',
  SUMMARY: 'Ringkasan',
  HERE_IS_A_SUMMARY_OF_YOUR_CONFIGURATION: 'Berikut ringkasan konfigurasi anda',
  LEGACY_MANAGEMENT_CONFIGURATION: 'Konfigurasi manajemen warisan',
  REQUEST_BY_USER: 'Permintaan oleh pengguna',
  REQUEST_BY_YOU: 'Permintaan oleh anda',
  USER_DEATH_OR_INCAPACITATED_STATE:
    'Kematian pengguna atau keadaan tidak berdaya',
  YOUR_DEATH_OR_INCAPACITATED_STATE: 'Kematian anda atau keadaan tidak berdaya',
  WE_WILL_SHARE_USER: 'Kami akan membagikan pengguna:',
  WE_WILL_SHARE_YOUR: 'Kami akan membagikan:',
  FULL_VAULTBOX: 'vaultbox penuh',
  FINAL_REGISTRY_SNAPSHOT_AND_SELECTED_FILES_FOLDERS:
    'Snapshot Register akhir dan file / folder yang dipilih',
  FINAL_REGISTRY_SNAPSHOT: 'Snapshot Register akhir',
  FINAL_SNAPSHOT_OF_YOUR_REGISTRY: 'Snapshot terakhir dari Register anda',
  SELECTED_FILES_FOLDERS: 'File / folder yang dipilih',
  WITH: 'Dengan',
  UPON: 'Atas',
  USER_PROFESSIONAL_DEPUTY: 'Deputi Profesional pengguna',
  YOUR_PROFESSIONAL_DEPUTY: 'Deputi Profesional anda',
  FAILED_TO_GET_LEGACY_MANAGEMENT_INFO:
    'Gagal mendapatkan informasi manajemen warisan',
  FAILED_TO_GET_LEGACY_MANAGEMENT_STATUS:
    'Gagal mendapatkan status manajemen warisan',
  FAILED_TO_CHANGE_LEGACY_MANAGEMENT: 'Gagal mengubah manajemen warisan',
  DISABLE_THE_LEGACY_MANAGEMENT: 'Nonaktifkan manajemen warisan?',
  ENABLE_THE_LEGACY_MANAGEMENT: 'Aktifkan manajemen warisan',
  SUCCESSFULLY_SENT_REQUEST_TO_RELEASE_YOUR_VAULTBOX:
    'Berhasil mengirim permintaan untuk membuka vaultbox anda',
  FAILED_TO_SENT_REQUEST_TO_RELEASE_YOUR_VAULTBOX:
    'Gagal mengirim permintaan untuk membuka vaultbox anda',
  REQUEST_TO_RELEASE_YOUR_VAULTBOX: 'Minta untuk membuka vaultbox anda?',
  REQUEST_TO_RELEASE_YOUR_VAULTBOX_SUMNARY:
    'Deputi Profesional anda akan diminta untuk membuka vaultbox anda dan merilis kontennya mengikuti konfigurasi manajemen warisan anda saat ini. Apakah anda yakin ingin melanjutkan?',
  LEGACY_MANAGEMENT_SUMMARY:
    'Sebagai pelanggan <strong> vaultbox </strong>, anda dapat memiliki serangkaian instruksi pelaksanaan bila anda meninggal atau dalam keadaan tidak mampu sehingga Deputi Profesional yang telah anda tetapkan dapat memproses konten <strong> vaultbox </strong> anda.',
  START: 'Mulai',
  TOGGLE_THE_LEGACY_MANAGEMENT: 'Pengalihan manajemen warisan',
  REQUEST_TO_RELEASE_NOW: 'Minta rilis sekarang',
  UPDATE_CONFIGURATION: 'Perbarui konfigurasi',
  YOU_ARE_PENDING_ACCEPTANCE_FROM_YOUR_DEPUITES:
    'Menunggu penerimaan dari Deputi Anda.',
  YOU_NEED_TO_HAVE_A_PROFESSIONAL_DEPUTY_TO_USE_THIS_FEATURE:
    'Anda harus memiliki Deputi Profesional untuk menggunakan fitur ini.',
  APPOINT_PROFESSIONAL_DEPUTY_NOW: 'Tunjuk Deputi Profesional sekarang',
  PLEASE_SELECT_AT_LEAST_ONE_OPTION: 'Pilih setidaknya satu opsi',
  PLEASE_SELECT_AT_LEAST_ONE_FILE_FOLDER: 'Pilih setidaknya satu file / folder',
  CONTENT_PERMISSION: 'Izin konten',
  ACCESSED_BY_OTHER_SPECIFIC_PERSON_FIRST_SUMMARY:
    'Anda telah memilih untuk memberikan akses bagi <strong> orang tertentu lainnya </strong>',
  ACCESSED_BY_OTHER_SPECIFIC_PERSON_SECOND_SUMMARY:
    'Anda dapat memberikan akses penuh <strong> vaultbox </strong> anda, atau hanya konten dari register dan / atau file dan folder yang dipilih.',
  ACCESSED_BY_PROFESSIONAL_DEPUTY_FIRST_SUMMARY:
    'Anda telah memilih untuk memberikan akses untuk <strong> Deputi Profesional </strong>.',
  ACCESSED_BY_PROFESSIONAL_DEPUTY_SECOND_SUMMARY:
    'Deputi Profesional anda akan memiliki akses penuh ke <strong> vaultbox </strong> anda.',
  CHOOSE_FILES_OR_FOLDERS_TO_SHARE: 'Pilih file atau folder untuk dibagikan',
  ADD_FILE_FOLDERS: 'Tambahkan file / folder',
  INSTRUCTIONS: 'Instruksi',
  INSTRUCTIONS_SUMMARY:
    'Unggah dokumen tertulis tentang instruksi lain untuk Deputi Profesional anda. Misalnya README.TXT di mana file tertentu disimpan, kepada siapa Deputi Profesional harus memberikan file tersebut.',
  INSTRUCTION_FILE: 'File instruksi',
  WHEN_DO_YOU_WANT_TO_RELEASE_YOUR_VAULTBOX_CONTENT:
    'Kapan anda ingin merilis konten vaultbox Anda?',
  UPON_REQUEST_BY_ME: 'Atas permintaan saya',
  UPON_MY_DEATH_OR_INCAPACITATED_SATE:
    'Setelah kematian saya atau keadaan tidak berdaya',
  INACTIVITY_STATE_CONFIGURATION:
    'Untuk memilih opsi ini, anda perlu menyelesaikan konfigurasi status tidak aktif.',
  CONFIGURE: 'Konfigurasi',
  CHOSSE_FILES_FOLDERS: 'Pilih file / folder',
  NEW_CRYPTOCURRENCY: 'Mata uang kripto baru',
  THE_SAME_NAME_ALREADY_EXISTS: 'Nama yang sama sudah ada!',
  SYMBOL: 'Simbol',
  ENTER_SYMBOL: 'Masukkan simbol',
  THE_SAME_SYMBOL_ALREADY_EXISTS: 'Simbol yang sama sudah ada!',
  NEW_ITEM: '{{item}} baru',
  ENTER_NEW_ITEM: 'Masukkan {{item}} baru!',
  THE_SAME_ITEM_ALREADY_EXISTS: '{{Item}} yang sama sudah ada!',
  SUCCESSFULLY_ADDED_CARD: 'Berhasil menambahkan kartu',
  FAILED_TO_ADD_CARD: 'Gagal menambahkan kartu',
  ADD_A_CARD: 'Tambahkan kartu',
  SUPPORTED_CARD_TYPES: 'Jenis kartu yang didukung:',
  TESTING_NOTE:
    'Note: Anda dapat memasukkan nomor kartu berikut untuk pengujian',
  SUCCESSFULLY_DELETED_CARD: 'Berhasil menghapus kartu',
  FAILED_TO_DELETED_CARD: 'Gagal menghapus kartu',
  ARE_YOU_SURE_TO_DELETE_THIS_CARD: 'Anda yakin ingin menghapus kartu ini?',
  DELETE_CARD: 'Hapus kartu',
  FAILED_TO_GET_INVOICES: 'Gagal mendapatkan faktur',
  AMOUNT: 'Jumlah',
  VIEW_INVOICE: 'Lihat faktur',
  VIEW_RECEIPT: 'Lihat tanda terima',
  CARD_NO: 'Nomor kartu',
  EXPIRES_ON: 'Kadaluarsa pada tanggal',
  CURRENT_SUBSCRIPTION: 'Paket berlangganan saat ini',
  CURRENT_SUBSCRIPTION_APPLIED_PROMOTION_CODE:
    'Paket berlangganan anda saat ini sudah pernah menerapkan kode promosi dan tidak dapat menerapkan yang lain.',
  PROMOTION_CODE: 'Kode promosi',
  CHANGE_SUBSCRIPTION: 'Ubah langganan',
  APPLIED_DATE: 'Tanggal diterapkan',
  PAYMENT_METHOD: 'Cara Pembayaran',
  NO_CARDS: 'Tidak Ada Kartu',
  PROMOTION_CODE_CANT_APPLIED:
    'Kode promosi ini tidak dapat diterapkan ke paket ini.',
  SUCCESSFULLY_APPLIED_PROMOTION_CODE: 'Berhasil menerapkan kode promosi',
  FAILED_TO_APPLY_PROMOTION_CODE: 'Gagal menerapkan kode promosi',
  PROMOTION_CODE_IS_REQUIRED: 'Kode promosi diperlukan',
  ENTER_PROMOTION_CODE: 'Masukkan kode promosi',
  SUCCESSFULLY_SET_DEFAULT_CARD: 'Berhasil menetapkan kartu default',
  FAIL_TO_SET_DEFAULT_CARD: 'Gagal menetapkan kartu default',
  ARE_YOU_SURE_TO_SET_THIS_CARD_AS_DEFAULT:
    'Apakah Anda yakin menetapkan kartu ini sebagai default?',
  SET_DEFAULT_CARD: 'Tetapkan kartu default',
  INVALID_SUBSCRIPTION: 'Paket berlangganan tidak valid',
  INVALID_SUBSCRIPTION_SUMMARY:
    'Ruang penyimpanan anda telah melebihi penyimpanan yang diizinkan dari paket ini. Silakan kosongkan sebagian ruang dengan menghapus file yang tidak lagi anda perlukan.',
  INVALID_REFERRAL_CODE: 'Kode referral tidak valid',
  CODE_USED:
    'Anda telah menggunakan kode ini sebelumnya. Silakan coba yang lain, atau biarkan kosong untuk melanjutkan.',
  TRY_ANOTHER_ONE:
    'Silakan coba yang lain, atau biarkan kosong untuk melanjutkan.',
  FAILED_TO_SUBSCRIBE: 'Gagal berlangganan\n',
  FAILED_TO_GET_PROMOTION_CODE_INFO: 'Gagal mendapatkan info kode promosi',
  CHANGE_SUBSCRIPTION_PLAN: 'Ubah paket berlangganan',
  CHANGE_SUBSCRIPTION_PLAN_FIRST_SUMMARY:
    'Anda dapat melanjutkan paket saat ini hingga {{current_period_end}} dan akan beralih ke paket baru setelah perpanjangan.',
  CHANGE_SUBSCRIPTION_PLAN_SECOND_SUMMARY:
    'Kode aktif promosi anda <strong> {{removePromoCode}} </strong> hanya dapat diterapkan ke paket <strong> {{nickname}} </strong>. Jika Anda mengubah paket berlangganan anda, diskon promosi akan dihapus.',
  FAILED_TO_GET_DEPUTIES_LIST_TO_CHECK_PROFESSTIONAL_DEPUTY_PLAN:
    'Gagal mendapatkan daftar Deputi untuk memeriksa paket Deputi Profesional. Silakan coba lagi.',
  FAILED_TO_SAVE_SUBSCRIPTION: 'Gagal menyimpan paket berlangganan',
  CANCEL_SUBSCRIPTION: 'Batalkan paket langganan?',
  CANCEL_SUBSCRIPTION_FIRST_SUMMARY:
    'Tindakan ini akan menurunkan paket langganan akun anda ke paket vaultbox Essential di akhir periode langganan.',
  CANCEL_SUBSCRIPTION_SECOND_SUMMARY:
    'Setelah paket langganan anda berakhir, anda hanya akan dapat menyimpan maksimum 20 item dalam 2GB. <2><strong>Silakan mengunduh salinannya terlebih dahulu.</strong> </2>',
  YOU_CAN_STILL_USE_YOUR_ACCOUNT_AS_DEPUTY:
    'Anda masih dapat menggunakan akun anda untuk menjadi Deputi bagi pengguna <strong>vaultbox </strong> lain.',
  ARE_YOU_SURE_YOU_WISH_TO_CANCEL_SUBSCRIPTION:
    'Apakah ada yakin ingin membatalkan paket langganan Anda?',
  KEEP_SUBSCRIPTION: 'Tetap berlangganan',
  SUBSCRIPTION_CANCELLED: 'Paket berlangganan dibatalkan',
  SUBSCRIPTION_CANCELLED_SUMMARY:
    'Paket langganan {{nickname}} anda akan berakhir pada {{current_period_end}}',
  FAILED_TO_CANCEL_SUBSCRIPTION: 'Gagal membatalkan langganan',
  SUCCESSFULLY_CANCELLED_SUBSCRIPTION_CHANGE:
    'Berhasil membatalkan perubahan langganan',
  FAILED_TO_CANCEL_SUBSCRIPTION_CHANGE: 'Gagal membatalkan perubahan langganan',
  CANCEL_CHANGE: 'Batalkan perubahan',
  SUBSCRIPTION_TESTING_NOTE:
    'Note: Paket  di bawah ini hanyalah data tiruan. Anda dapat memilih salah satu dari mereka untuk melanjutkan dan tidak perlu membayar.',
  SUBSCRIBE: 'Langganan',
  CHANGE_SUBSCRIPTION_PLAN_ON:
    'Paket langganan anda akan berubah ke paket ini pada {{changeDate}}. Batalkan perubahan ini jika anda tetap ingin menggunakan paket berlangganan ini.',
  REFERRAL_CODE: 'Kode referal',
  REFERRAL_CODE_TOOLTIP_FIRST_SUMMARY:
    'Jika Anda dirujuk oleh pengguna vaultbox lain, masukkan kode referal mereka di sini.',
  REFERRAL_CODE_TOOLTIP_SECOND_SUMMARY:
    'Untuk pendaftaran yang berhasil, anda akan mendapatkan diskon US$ 4,99 dan perujuk akan mendapatkan kredit US$ 4,99 ke akun mereka yang dapat digunakan pada perpanjangan berikutnya.',
  THIS_IS_NOT_APPLIED_TO_THE_FREE_PLAN:
    'Ini tidak berlaku untuk paket vaultbox Essential.',
  ENTER_REFERRAL_CODE: 'Masukkan kode referal (opsional)',
  PLEASE_SELECT_A_CURRENCY: 'Pilih mata uang!',
  SUCCESSFULLY_CHANGED_PASSWORD: 'Berhasil mengubah kata sandi',
  SUCCESSFULLY_SAVED_INACTIVIY_STATE:
    'Berhasil menyimpan konfigurasi status tidak aktif',
  FAILED_TO_SAVE_INACTIVIY_STATE:
    'Gagal menyimpan konfigurasi status tidak aktif',
  INACTIVITY_STATE_CONFIG: 'Konfigurasi Status Tidak Aktif',
  WHEN_YOU_ARE_INACTIVE_FOR: 'Saat anda tidak aktif selama\n',
  WE_WILL_SEND_YOU_REMINDER: 'kami akan mengirimkan peringatan',
  BUT_IF_YOU_DONOT_RESPOND_WITHIN:
    'tetapi jika anda tidak menanggapi dalam waktu',
  OF_THE_REMINDER: 'dari peringatan,',
  WE_WILL_INFORM_YOUR_DEPUTIES_ABOUT_YOUR_INACTIVITY:
    'kami akan menginformasikan Deputi anda mengenai ketidakaktifan anda.',
  INACTIVITY_STATE_CONFIGURATION_TITLE: 'Konfigurasi Status Tidak Aktif',
  INACTIVITY_STATE_CONFIGURATION_FIRST_SUMMARY:
    'Konfigurasi untuk menginformasikan Deputi anda jika anda tidak aktif untuk waktu yang lama.',
  INACTIVITY_STATE_CONFIGURATION_SECOND_SUMMARY:
    'Kami menyarankan agar anda sesekali meninjau konfigurasi anda setiap kali ada perubahan besar dalam peristiwa hidup anda, atau setidaknya setahun sekali.',
  YOU_NEED_TO_HAVE_DEPUTIES_TO_ABLE_TO_USE_THIS_FEATURE:
    'Anda harus memiliki Deputi untuk dapat menggunakan fitur ini.',
  EMAIL_ADDRESS_UPDATED_AND_VERIFIED:
    'Alamat email anda telah diperbarui dan diverifikasi. Silakan atur ulang autentikasi multifaktor agar alamat email yang ditampilkan di aplikasi autentikator anda juga diperbarui.',
  EMAIL_ADDRESS_VERIFICATION: 'Verifikasi alamat email',
  ENTER_THE_VERIFICATION_CODE:
    'Masukkan kode verifikasi yang dikirimkan ke alamat email yang baru',
  RECEIVER: 'Penerima',
  SUBSCRIPTION_PLAN: 'Paket berlangganan',
  SENT_DATE: 'Tanggal pengiriman',
  RECEIVED: 'Diterima',
  GIFTS_I_HAVE_SENT: 'Hadiah yang telah saya kirim',
  YOU_NEED_TO_ADD_A_PAYMENT_METHOD_TO_SENT_GIFT:
    'Anda perlu menambahkan metode pembayaran untuk dapat mengirim hadiah',
  SEND_GIFT: 'Kirim hadiah',
  NO_GIFTS: 'Tidak ada hadiah',
  SETUP_AGAIN: 'Setel ulang',
  MULTI_FACTOR_AUTHENTICATION_FIRST_SUMMARY:
    'Anda telah menyiapkan autentikasi multifaktor vaultbox menggunakan aplikasi autentikator anda di <strong> {{setupMfaDate}} </strong>.',
  MULTI_FACTOR_AUTHENTICATION_SECOND_SUMMARY:
    'Jika anda mengganti ponsel atau aplikasi autentikator, anda perlu mengaturnya lagi. Saat Anda melakukannya, kode autentikasi lama tidak akan dapat digunakan lagi.',
  RESEND_CODE: 'Kirim ulang kode',
  ENTER_YOUR_PASSWORD_TO_PROCEED: 'Masukkan kata sandi untuk melanjutkan',
  FAILED_TO_EDIT_PROFILE: 'Gagal mengubah profil',
  SUCCESSFULLY_EDITED_PROFILE: 'Berhasil mengubah profil',
  VERIFY_EMAIL_ADDRESS: 'Verifikasi alamat email',
  SET_A_STRONG_PASSWORD_TO_PROTECT_YOUR_ACCOUNT:
    'Tetapkan kata sandi yang kuat untuk melindungi akun anda',
  QUOTE_CURRENCY: 'Mata Uang Lain',
  RATE: 'Nilai',
  LAST_UPDATE: 'Terakhir Diperbarui',
  FAILE_TO_SET_CURRENCY: 'Gagal menetapkan mata uang',
  FAILED_TO_UPDATE_BASE_CURRENCY: 'Gagal memperbarui mata uang dasar',
  BASE_CURRENCY_MEANS_YOUR_REPORTING_CURRENCY_OR_FUNCTIONAL_CURRENCY:
    'Mata uang dasar artinya mata uang pelaporan atau mata uang fungsional Anda',
  SELECT_QUOTE_CURRENCY: 'Pilih mata uang lain',
  PLEASE_ENTER_AT_LEAST_ONE_EMAIL_ADDRESS:
    'Silakan masukkan paling tidak 1 alamat email',
  SUCCESSFULLY_SENT_INVITATION: 'Berhasil mengirim undangan',
  THIS_EMAIL_HAS_APPLIED_YOUR_REFERRAL_CODE:
    'Akun vaultbox dengan email address ini telah menerapkan kode referal anda',
  REFER: 'Rujukan',
  REFERRAL_CODE_SUMMARY:
    'Kode referral adalah unik untuk anda dan telah ditetapkan ke akun anda. Anda dapat merujuk rekan bisnis, keluarga, dan teman anda ke vaultbox dan mendapatkan imbalan. Untuk pendaftaran yang berhasil, mereka akan mendapatkan\ndiskon US$ 5,00 dan anda akan mendapatkan kredit US$ 5,00 ke akun anda yang dapat digunakan untuk perpanjangan akun anda.',
  SEND_REFERRAL_LINK: 'Kirim tautan referal',
  SEND: 'Kirim',
  EMAIL_ADDRESSES: 'Alamat email',
  YOU_HAVE_SENT_A_GIFT_TO_THIS_EMAIL:
    'Kamu sudah mengirimkan hadiah ke alamat email ini sebelumnya dan masih belum diterima.',
  EXISTING_VAULTBOX_USER_CAN_NOT_RECEIVE_GIFT:
    'Pengguna vaultbox dengan alamat email ini sudah ada. Pengguna vaultbox yang sudah terdaftar tidak dapat menerima hadiah berlangganan.',
  SUCCESS_PURCHASED: 'Pembelian sukses! Hadiahnya sedang dikirim!',
  FAILED_TO_PURCHASE_GIFT: 'Gagal membeli hadiah',
  PURCHASE: 'Pembelian',
  ENTER_EMAIL_ADDRESS: 'Masukan alamat email',
  SELECT_A_PLAN: 'Paket harga',
  ENTER_QUANTITY: 'Masukan jumlah',
  GIFT_MESSAGE: 'Pesan hadiah',
  GIFT_MESSAGE_SUMMARY:
    'Saya menggunakan vaultbox dan menurut saya ini akan berguna untuk anda! Ini langganan vaultbox dari saya untuk anda agar dapat memulai.',
  INPUT_GIFT_MESSAGE: 'Masukan pesan hadiah',
  GIFTS: 'Hadiah',
  RESET_REGISTRY: 'Reset register',
  SUCCESSFULLY_RESET_REGISTRY: 'Berhasil mereset register',
  FAILED_TO_DELETE_USER: 'Gagal menghapus pengguna',
  FAILED_TO_RESET_REGISTRY: 'Gagal mereset register',
  INVALID_FORM: 'Formulir tidak valid',
  ACTIVITIES: 'Aktivitas',
  DEPUTY_ACCESSES_ONLY: 'Hanya akses Deputi',
  ACCESSED_YOUR_VAULTBOX: '{{name}} ({{email}}) mengakses vaultbox anda',
  DELETE_ACCOUNT: 'Hapus akun',
  DOWNLOAD_DATA_AND_DELETE_ACCOUNT: 'Unduh data dan hapus akun',
  ARE_YOU_SURE_YOU_WANT_TO_RESET_YOUR_REGISTRY:
    'Apakah anda yakin ingin mereset register anda? Semua data anda akan dihapus dan tidak dapat diambil lagi di masa mendatang.',
  ENTER_PASSWORD: 'Masukkan kata sandi',
  ENTER_A_NEW_EMAIL_WHICH_WAS_NOT_SPECIFIED_BEFORE:
    'Masukkan email baru yang belum ditentukan sebelumnya.',
  SUCCESSFULLY_SPECIFIED_DEPUTIES: 'Berhasil menentukan Deputi',
  FAILED_TO_SPECIFY_DEPUTY: 'Gagal menentukan Deputi',
  PLEASE_ADD_AT_LEAST_ONE_DEPUTY: 'Silakan tambahkan setidaknya 1 Deputi',
  SELECT_A_DEPUTY_TYPE: 'Pilih tipe Deputi',
  APPOINT_SOMEBODY_ELSE: 'Tunjuk orang lain',
  WATIGA_TRUST: 'Watiga Trust',
  WATIGA_TRUST_FIRST_SUMMARY:
    'Kami telah bermitra dengan Watiga Trust Ltd ("Watiga Trust"), sebuah perusahaan Trust berlisensi di Singapura, untuk menyediakan layanan Deputi profesional untuk anda.',
  WATIGA_TRUST_SECOND_SUMMARY:
    'Watiga Trust akan menyimpan kunci pemulihan yang dapat digunakan untuk memulihkan akses ke <strong> vaultbox </strong> untuk anda atau perwakilan hukum setelah anda wafat.',
  WATIGA_TRUST_TERMS_OF_SERVICE:
    'Persyaratan Layanan Deputi Profesional Watiga Trust',
  OTHER_PD_TERMS_OF_SERVICE: 'Persyaratan Layanan Deputi Profesional',
  PROFESSIONAL_DEPUTY_SETUP_IS_NOT_YET_COMPLETED:
    'Pengaturan Deputi Profesional belum selesai.',
  YOU_CANT_HAVE_BOTH_NOMARL_AND_PD_DEPUTY:
    'Anda tidak dapat memiliki Deputi normal dan Deputi Profesional. Untuk dapat menambah Deputi Profesional, harap membatalkan Deputi normal Anda',
  YOU_HAVE_ADDED_PD:
    'Anda telah menambahkan Deputi Profesional sebelumnya dan tidak dapat menambahkan yang lain.',
  PD_ACCOUNTS_ARE_NOT_YET_AVAILABLE: 'Akun Deputi Profesional belum tersedia.',
  YOU_CANT_HAVE_BOTH_PD_AND_NOMARL_DEPUTY:
    'Anda tidak dapat memiliki Deputi normal dan Deputi Profesional. Untuk dapat menambah Deputi normal, harap membatalkan Deputi Profesional Anda',
  WATIGA_TRUST_DETAIL_FIRST_SUMMARY:
    'Watiga Trust adalah perusahaan Trust berlisensi yang diatur oleh Monetary Authority of Singapore. Sebagai lembaga keuangan berlisensi di Singapura, Watiga Trust tunduk pada\npersyaratan kepatuhan, termasuk yang terkait dengan anti pencucian uang dan melawan pendanaan terorisme. ',
  WATIGA_TRUST_DETAIL_SECOND_SUMMARY:
    'Agar Watiga Trust bertindak sebagai Deputi Anda, harap sediakan hal berikut:',
  OTHER_PD_SUMMARY:
    'Agar <strong> {{professionalDeputyName}} </strong> dapat bertindak sebagai Deputi Anda, harap sediakan hal berikut:',
  WATIGA_TRUST_DETAIL_THIRD_SUMMARY:
    'Kami akan menyimpan informasi dan file ini di <strong>vaultbox</strong> anda dan mengirimkannya ke Watiga Trust dengan aman. Watiga Trust akan menjaga kerahasiaan informasi anda dan menggunakannya untuk uji tuntas tujuan verifikasi sesuai dengan <5> Persyaratan Layanan Deputi Profesional Watiga Trust </5>. Jika dokumen atau informasi tidak akurat atau tidak lengkap, Watiga Trust akan menghubungi anda untuk memverifikasi.',
  NORMAL_DEPUTY_SUMMARY:
    'Untuk menunjuk orang lain sebagai Deputi Anda, masukkan alamat email mereka di bawah ini. Mereka akan dikirimi tautan untuk mengatur penunjukan Deputi. Jika mereka sudah memiliki akun <strong> vaultbox </strong>, penunjukan ini\nakan disimpan di akun <strong>vaultbox</strong> mereka. Jika tidak, mereka dapat membuat akun vaultbox Essential gratis untuk menjadi Deputi Anda. Anda membutuhkan setidaknya 2 Deputi. ',
  EMAIL_ADDRESS_OF_DEPUTY: 'Alamat email Deputi',
  DOCUMENTS_UPLOADED: 'Dokumen diungah',
  MY_ID_CONTAINS_MY_RESIDENTIAL_ADDRESS:
    'ID saya berisi alamat tempat tinggal saya.',
  WATIGA_TRUST_REVIEW_SUMMARY:
    'Saya setuju untuk menunjuk Watiga Trust sebagai Deputi saya sesuai dengan <2> Persyaratan Layanan Deputi Profesional Watiga Trust </2>',
  OTHER_PD_REVIEW_SUMMARY:
    'Saya setuju untuk menunjuk {{professionalDeputyName}} sebagai Deputi saya sesuai dengan <5> Persyaratan Layanan Deputi Profesional </5>',
  WE_WILL_EMAIL_YOUR_DEPUTY_FOR_THEIR_ACCEPTANCE:
    'Kami akan mengirim email kepada Deputi Anda untuk penerimaan mereka.',
  ENTER_FULL_NAME: 'Masukkan nama lengkap',
  ENTER_CONTACT_NUMBER: 'Masukkan nomor kontak',
  ENTER_ID_PASSPORT_NUMBER: 'Masukkan nomor KTP/ Passport',
  SELECT_NATIONALITY: 'Pilih kebangsaan',
  ENTER_RESIDENTIAL_ADDRESS: 'Masukkan alamat tempat tinggal',
  YOUR_ID_PASSPORT_FILENAME_IS: 'Nama file KTP/ Passport anda adalah',
  YOUR_PROOF_OF_ADDRESS_FILENAME_IS: 'Nama file bukti alamat anda adalah',
  UPLOAD_ADDRESS_PROOF_DATED_WITHIN_THE_LAST_SIX_MONTHS:
    'Unggah bukti alamat tertanggal enam (6) bulan terakhir:',
  ID_CARD: 'KTP',
  UTILITY_BILL: 'Rekening air listrik',
  CORRESPONDENCE_FROM_TAX_OFFICE_OR_OTHER_GOVERMENT_AUTHORITY:
    'Korespondensi dari kantor pajak atau otoritas pemerintah lainnya',
  USERNAME_IS_NULL: 'Nama pengguna kosong. Tidak dapat mengambil sesi baru',
  INVALID_SESSION: 'Sesi tidak valid. Harap autentikasi.',
  LOCAL_STORAGE_IS_MISSING_AN_ID_TOKEN:
    'Penyimpanan lokal tidak memiliki ID Token. Harap autentikasi',
  ENTER_ANOTHER_EAMIL_NOT_YOUR_OWN_ONE:
    'Masukkan email lain, bukan email anda sendiri',
  ENTER_AN_EMAIL_WHICH_IS_DEFFETENT_FROM_PD_EMAIL:
    'Masukkan email yang berbeda dari email Deputi Profesional',
  FILE_NAME_ALREADY_EXISTS:
    'nama file sudah ada di folder ini, silakan isi nama lain',
  VALUE_MUST_BE_GREATER_THAN: 'Nilai harus lebih besar dari {{min}}',
  NRIC_IS_INVALID: 'NRIC tidak valid',
  FIN_IS_INVALID: 'FIN tidak valid',
  REFERENCES_TOOLTIP_SUMMARY:
    'Referensi dapat digunakan untuk berbagai tujuan. Setiap referensi memiliki label dan informasi yang cocok.',
  ASSET_LIABILITY_USE_CASE_EXAMPLES: 'Contoh kasus penggunaan aset/kewajiban',
  EQITY_LISTED_INFORMATION_OF_TICKER_ISIN:
    'Ekuitas terdaftar: informasi Ticker atau ISIN',
  INSURANCE_SURRENDER_VALUE: 'Asuransi: nilai penyerahan',
  CONTACT_USE_CASE_EXAMPLES: 'Contoh kasus penggunaan kontak',
  INDIVIDUAL_CONTACT_INFORMATION:
    'Kontak individu: informasi anggota keluarga atau kolega yang dapat digunakan untuk kontak darurat (misalnya Label: Istri, Info: +6512345678)',
  ORGANISATION_CONTACT_INFORMATION:
    'Kontak organisasi: informasi penanggung jawab (misalnya Label: John (PIC), Info: +6512345678 - john@example.com)',
  ENTER_ALPHANUMERIC_CHARACTERS_AND_DASH_ONLY:
    'Masukan karakter alfanumerik dan tanda hubung saja',
  INVALID_CODE_PLEASE_TRY_AGAIN: 'Kode salah. Silakan coba lagi.',
  INVALID_CODE_FAILED_TO_SETUP_MFA:
    'Kode Salah. Gagal mengaktifkan autentikasi multifaktor. Silakan coba lagi.',
  USER_IS_NOT_CONFIRMED:
    'Pengguna belum dikonfirmasi - silakan check email aktivasi anda',
  UNKNOWN_ERROR_DETECTED: 'Terdeteksi error yang tidak diketahui',
  DOWNLOADING_VAULTBOX: 'Mengunduh vaultbox',
  DOWNLOAD_STEP: 'Mengunduh {{index}} dari {{size}} file',
  SAVING_ZIP_PART: 'Simpan sebagian zip',
  SAVING_ZIP: 'Simpan zip',
  FAILED_TO_DOWNLOAD_THE_FOLLOWING_FILES: 'Gagal mengunduh file berikut',
  PLEASE_DOWNLOAD_THEM_MANUALLY: 'Silakan unduh secara manual',
  DOWNLOADED_VAULTBOX: 'vaultbox sudah terunduh',
  FAILED_TO_DOWNLOAD_ALL_VAULTBOX: 'Gagal mengunduh semua vaultbox',
  FILE_NAMES: 'Nama file',
  ALREADY_EXISTS_IN_THE_CURRENT_FOLDER:
    'sudah ada di folder ini, silakan isi nama lain',
  ASSETS_AND_LIABILITY_REGISTRY: 'Register aset dan kewajiban',
  TRACKING_NETWORTH: 'Melacak kekayaan bersih',
  QUANTITY_OF_SECURE_FILE_STORAGE: '50 GB penyimpanan file yang aman',
  SAVE_YOUR_IMPORTANT_CONTACT: 'Simpan kontak penting anda',
  APPOINT_DEPUTIES: 'Tunjuk Deputi',
  BE_APPOINTED_AS_A_DEPUTY: 'Ditunjuk sebagai Deputi',
  ABILITY_TO_APPOINT_PD_AS_ADDON_AT:
    'Kemampuan untuk menunjuk Deputi Profesional sebagai tambahan di',
  INCLUDE_PROPERTY_LOAN: 'Termasuk pinjaman properti',
  PROPERTY_LOAN_INFORMATION: 'Informasi pinjaman properti',
  LINKED_DOCUMENTS: 'Dokumen terkait',
  SECRET_FILE_ALERT_CONTENT:
    'File ini telah ditandai sebagai rahasia dan tidak dapat dilihat atau diunduh. Untuk mendapatkan kembali akses file, harap minta Deputi untuk membantu membuka kunci.',
  LINKS: 'Tautan',
  TOTAL_ASSETS: 'Total Aset',
  TODAY: 'Hari ini',
  ALL_TIME: 'Sepanjang Waktu',
  W_AS_WEEK: 'M',
  M_AS_MONTH: 'B',
  Y_AS_YEAR: 'T',
  SECURITY_CODE: 'Kode keamanan',
  MARKET_VALUE: 'Nilai Pasar',
  LAST_PRICE_DATE: 'Tanggal Harga Terakhir',
  REFERENCE_NAME_WITH_INDEX: 'Nama referensi {{index}}',
  REFERENCE_LABEL_WITH_INDEX: 'Label referensi {{index}}',
  REFERENCE_VALUE_WITH_INDEX: 'Nilai referensi {{index}}',
  EMAIL_LABEL_WITH_INDEX: 'Label email {{index}}',
  EMAIL_VALUE_WITH_INDEX: 'Nilai email {{index}}',
  ADDRESS_LABEL_WITH_INDEX: 'Label address {{index}}',
  ADDRESS_VALUE_WITH_INDEX: 'Nilai address {{index}}',
  PHONE_NUMBER_LABEL_WITH_INDEX: 'Label nomor telepon {{index}}',
  PHONE_NUMBER_VALUE_WITH_INDEX: 'Nilai nomor telepon {{index}}',
  FIELD_NAME: 'Nama field',
  FIELD_VALUE: 'Nilai field',
  OTHER_PROFESSIONAL_DEPUTY: 'Deputi Profesional lain',
  ENTER_PROFESSIONAL_DEPUTY_REFERRAL_CODE:
    'Masukkan kode referal Deputi Profesional',
  PROFESSIONAL_DEPUTY_NOT_FOUND: 'Deputi Profesional tidak ditemukan',
  THE_PROFESSIONAL_DEPUTY_IS_DEFAULT:
    'Deputi Profesional yang anda cari sudah menjadi default anda.',
  FAILED_TO_LOAD_DEPUTIES: 'Gagal memuat Deputi',
  FAILED_TO_LOAD_DISTRIBUTE_KEYS:
    'Gagal mendistribusikan kunci ke Deputi anda.',
  SUCCESSFULLY_REVOKED_DEPUTY: 'Berhasil mencabut Deputi',
  FAILED_TO_REVOKE_DEPUTY: 'Gagal mencabut Deputi',
  SUCCESSFULLY_RESENT_DEPUTY_REQUEST:
    'Berhasil mengirim ulang permintaan Deputi',
  FAILED_TO_RESENT_DEPUTY_REQUEST: 'Gagal mengirim ulang permintaan Deputi',
  SUCCESSFULLY_RESENT_UNLOCK_REQUEST:
    'Berhasil mengirim ulang permintaan buka kunci',
  FAILED_TO_RESENT_UNLOCK_REQUEST: 'Gagal mengirim ulang permintaan buka kunci',
  FETCHING_LASTEST_EXCHANGE_RATES: 'Mengambil nilai tukar terbaru ...',
  FAILED_TO_FETCH_LASTEST_EXCHANGE_RATES:
    'Gagal mengambil nilai tukar terbaru.',
  RETRY: 'Mencoba kembali',
  SUCCESSFULLY_FETCHED_LASTEST_EXCHANGE_RATES:
    'Berhasil mengambil nilai tukar terbaru.',
  FAILED_TO_FETCH_SAVE_EXCHANGE_RATES: 'Gagal menyimpan nilai tukar terbaru.',
  UPDATING_VALUATION_IN_BASE_CURRENCY:
    'Memperbarui penilaian aset / kewajiban dalam mata uang dasar...',
  SUCCESS_UPDATED_VALUATION_IN_BASE_CURRENCY:
    'Berhasil memperbarui penilaian aset / kewajiban dalam mata uang dasar.',
  FAILED_TO_UPDATE_VALUATION_IN_BASE_CURRENCY:
    'Gagal memperbarui penilaian aset / kewajiban dalam mata uang dasar.',
  FAILED_TO_GET_INSTRUCTION_FILE: 'Gagal mendapatkan file instruksi.',
  FAILED_TO_SAVE_LEGACY_MANAGEMENT_CONFIGURATION:
    'Gagal menyimpan konfigurasi manajemen warisan.',
  FAILED_TO_SET_USER_MFA_PREFERENCE: 'Gagal mengatur preferensi MFA pengguna.',
  FAILED_TO_RESET_PASSWORD: 'Gagal mereset kata sandi.',
  FAILED_TO_REGISTER_USER: 'Gagal mendaftarkan pengguna.',
  FAILED_TO_VERIFY_EMAIL_ADDRESS: 'Gagal memverifikasi alamat email.',
  FAILED_TO_AUTHENTICATE_USER: 'Gagal mengautentikasi pengguna.',
  FAILED_TO_GET_PLAN: 'Gagal mendapatkan paket',
  FAILED_TO_SENT_INVITATION: 'Gagal mengirimkan undangan',
  FAILED_TO_UPDATE_USER_ATTRIBUTES: 'Gagal memperbarui atribut pengguna',
  FAILED_TO_CHANGE_PASSWORD: 'Gagal mengubah kata sandi',
  INVALID_PARAMETER_EXCEPTION: 'Parameter tidak valid. Silakan coba lagi',
  DID_NOT_RECEIVE_THE_EMAIL: 'Belum menerima email?',
  RESEND_EMAIL: 'Kirim ulang email',
  INPUT_EMAIL_CODE:
    'Masukkan kode verifikasi yang dikirim ke alamat email anda',
  FAILED_TO_UPDATE_AUTH_FLOW: 'Gagal memperbarui jenis MFA.',
  AUTHENTiCATION_FLOW_TYPE: 'Jenis MFA',
  SELECT_A_TYPE_TO_SETUP_FLOW: 'Pilih jenis autentikasi multifaktor',
  CHANGE_DEFAULT_LANGUAGE: 'Ubah bahasa default',
  CHANGE: 'Ubah',
  FAIILED_TO_CHANGE_DEFAULT_LANGUAGE: 'Gagal mengubah bahasa default',
  YOUR_DEFAULT_LANGUAGE_IS: 'Bahasa default anda adalah',
  CHANGE_DEFAULT_LANGUAGE_SUMMARY:
    'Bahasa default anda adalah {{defaultLanguage}}. Tetapi bahasa yang anda pilih adalah {{selectedLanguage}}. Jadi bahasa apa yang ingin anda gunakan?',
  ENGLISH: 'Inggris',
  VIETNAMESE: 'Bahasa Vietnam',
  SELECTED_LANGUAGE: 'Bahasa yang dipilih',
  DEFAULT_LANGUAGE: 'Bahasa default',
  MFA_CODE: 'OTP digital',
  EMAIL_CODE: 'OTP email',
  FAILED_TO_GET_AUTHENTICATION_FLOW_TYPE: 'Gagal mendapatkan jenis MFA',
  INCORRECT_USERNAME_OR_PASSWORD: 'Nama pengguna atau kata sandi salah.',
  EMAIL_OTP_SUMMARY:
    'Anda telah mengatur autentikasi multifaktor vaultbox menggunakan alamat email anda di <strong> {{setupMfaDate}} </strong>.',
  CHANGE_AUTH_FLOW_TYPE: 'Ubah jenis MFA',
  PENDING_ASSETS: 'Perubahan Aset',
  PENDING_LIABILITIES: 'Perubahan Kewajiban',
  ACTIVE: 'Aktif',
  REJECTED_LIABILITIES: 'Kewajiban yang ditolak',
  REJECTED_ASSETS: 'Aset yang ditolak',
  REJECTED: 'Ditolak',
  REJECTED_REASON: 'Alasan ditolak',
  REJECTED_REASON_INPUT_MSG: 'Silakan masukkan alasan penolakan',
  BAHASA_INDONESIA: 'Bahasa Indonesia',
  RESTORE: 'Mengembalikan',
  PENDING_CONTACT_WARNING_COUNT:
    '{{type}} ini memiliki {{contactCount}} tautan kontak yang menunggu',
  DELETE_ASSET_LIABILITY: 'Hapus aset / kewajiban',
  ARE_YOU_SURE_TO_DELETE_THIS_RECORD:
    'Apakah anda yakin untuk menghapus catatan ini?',
  FAILED_TO_UPDATE_ACCESS_LEVEL: 'Gagal memperbarui tingkat akses',
  PROFESSIONAL_DEPUTY_ACCESS_LEVEL: 'Tingkat akses Deputi Profesional',
  CHANGE_ACCESS_LEVEL: 'Ubah tingkat akses',
  REJECTION_REASON: 'Alasan penolakan',
  ALLOW:
    'Telah disetujui sebelumnya untuk membuat semua perubahan pada data saya',
  NEED_APPROVAL: 'Perlu persetujuan',
  DENY: 'Tidak diizinkan untuk melakukan perubahan',
  DEPUTY_ACCESS: 'Akses deputi',
  CALENDAR: 'Kalender',
  FAILED_TO_SAVE_THE_EVENT: 'Gagal menyimpan acara ',
  EVENTS: 'Acara',
  ADD_EVENT: 'Tambahkan acara',
  EVENT_DETAILS: 'Detil acara',
  EDIT_EVENT: 'Edit acara',
  SUCCESSFULLY_SAVE_THE_EVENT: 'Berhasil menyimpan acara',
  EVENT_LINKS: 'Tautan acara',
  EVENT: 'Acara',
  UNLINK_EVENT_CONFIRM: 'Apakah anda yakin ingin membatalkan tautan acara ini?',
  SELECT_DOCUMENTS: 'Pilih dokumen',
  PENDING_FOLDES: 'Folder menunggu ',
  PENDING_FILES: 'File menunggu',
  SUCCESSFULLY_DELETED_DOCUMENTS: 'Dokumen berhasil dihapus',
  FAILED_TO_DELETE_DOCUMENTS: 'Gagal menghapus dokumen',
  FAILED_TO_UNLINK_FROM_FILE: 'Gagal membatalkan tautan dari file',
  SUCCESSFULLY_UPDATED_PENDING_DOCUMENTS: 'Berhasil memperbarui dokumen',
  FAILED_TO_UPDATE_PENDING_DOCUMENTS: 'Gagal memperbarui dokumen',
  SUCCESSFULLY_REJECTED_DOCUMENTS: 'Berhasil menolak dokumen',
  FAILED_TO_REJECT_DOCUMENTS: 'Gagal menolak dokumen',
  SUCCESSFULLY_APPROVED_DOCUMENTS: 'Berhasil menyetujui dokumen',
  FAILED_TO_APPROVE_DOCUMENTS: 'Gagal menyetujui dokumen',
  FREE_ACCOUNT_SUMMARY:
    'Akun vaultbox Essential hanya dapat berfungsi sebagai Deputi untuk pengguna <strong> vaultbox </strong> lainnya. Upgrade paket langganan Anda untuk menikmati fitur  <strong> vaultbox </strong> yang lengkap.',
  NOT_NOW: 'Jangan sekarang',
  UPGRADE: 'Upgrade',
  PENDING_DOCUMENT_WARNING_COUNT:
    '{{type}} ini memiliki {{documentCount}} tautan dokumen yang menunggu',
  PENDING_ASSET_LIABILITY_WARNING_COUNT:
    '{{type}} ini memiliki {{assetLiabilityCount}} tautan aset / kewajiban yang menunggu',
  CONTACT: 'Kontak',
  UNLINK_CONFIRM: 'Konfirmasi batalkan tautan',
  DATE: 'Tanggal',
  ASSET_LIABILITY: 'Aset/Kewajiban',
  REMINDER_DAYS: 'Jumlah hari peringatan',
  SUCCESSFULLY_UNLINKED_EVENT: 'Acara berhasil dibatalkan tautannya',
  RECURRING_EVENT: 'Acara berulang',
  END_DATE: 'Tanggal akhir',
  ACCESS_LEVEL: 'Tingkat akses',
  UPCOMING_EVENTS: 'Acara mendatang',
  DONT_SHOW_AGAIN: 'Jangan tampilkan lagi',
  RECURRING_TIMES: 'Jumlah pengulangan',
  END_DATE_OF_RECURRING: 'Tanggal akhir pengulangan',
  SELECT_DATE: 'Silakan pilih tanggal',
  SELECT_RECURRING_TIMES: 'Pilih jumlah pengulangan',
  THIS_EVENT: 'Acara ini',
  THIS_AND_FOLLOWING_EVENTS: 'Acara ini dan acara berikutnya',
  ALL_EVENTS: 'Semua acara',
  EDIT_RECURRING_EVENT: 'Edit acara rutin',
  DELETE_RECURRING_EVENT: 'Hapus acara rutin',
  CONFIRM_DOWNLOAD_VAULTBOX: 'Konfirmasi unduh vaultbox',
  CONFIRM_DOWNLOAD_VAULTBOX_MSG: 'Apakah anda yakin akan mengunduh vaultbox?',
  WARNING_DOWNLOAD_VAULTBOX_MSG:
    'Pengunduhan mungkin membutuhkan waktu beberapa menit',
  TOTAL_SIZE: 'Ukuran',
  YOUR_PASSWORD_HAS_BEEN_RESET: 'Kata sandi anda telah direset',
  PASSWORDS: 'Kata sandi',
  USERNAME: 'Nama pengguna',
  INPUT_USERNAME_MSG: 'Masukkan nama pengguna Anda!',
  INPUT_URL_MSG:
    'Harap masukan alamat situs web yang lengkap (contoh: http://example.com)',
  EDIT_PASSWORD: 'Edit kata sandi',
  REMOVE_PASSWORD: 'Hapus kata sandi',
  SUCCESSFULLY_SAVED_THE_PASSWORD: 'Berhasil menyimpan kata sandi',
  FAILED_TO_SAVE_THE_PASSWORD: 'Gagal menyimpan kata sandi',
  PASSWORD_LINKS: 'Tautan kata sandi',
  SELECT_PASSWORD: 'Pilih kata sandi',
  ADD_NEW_PASSWORD: 'Tambahkan kata sandi baru',
  LINK_PASSWORD: 'Hubungkan kata sandi',
  SUCCESSFULLY_DELETED_THE_PASSWORD: 'Berhasil menghapus kata sandi',
  FAILED_TO_DELETE_THE_PASSWORD: 'Gagal menghapus kata sandi',
  UNLINK_PASSWORD: 'Batalkan hubungan kata sandi',
  SUCCESSFULLY_UNLINKED_PASSWORD: 'Berhasil membatalkan hubungan kata sandi',
  FAILED_TO_UNLINK_PASSWORD: 'Gagal membatalkan hubungan kata sandi',
  PASSWORD_DETAILS: 'Detail kata sandi',
  UNLINK_PASSWORD_CONFIRM:
    'Apakah Anda yakin untuk memutuskan hubungan kata sandi ini?',
  PASSWORD_RECORDS: 'Kata sandi',
  SELECT_PASSWORDS: 'Memilih kata sandi',
  TOO_SHORT: 'Terlalu singkat',
  TOO_WEAK: 'Terlalu lemah',
  WEAK: 'Lemah',
  FAIR: 'Cukup baik',
  GOOD: 'Baik',
  STRONG: 'Kuat',
  WAITING_FOR_DISCONNECTION: 'Menunggu pemutusan hubungan kerja',
  FAILED_TO_DISCONNECT_USER: 'Gagal memutuskan pengguna',
  ARE_YOU_SURE_TO_DISCONNECT_THIS_USER:
    'Apakah Anda yakin akan memutuskan pengguna ini?',
  DISCONNECT: 'Memutuskan',
  DISCONNECT_USER_REQUESTS: 'Putuskan permintaan pengguna',
  REQUEST_TO_DISCONNECT_ACCOUNT:
    'Deputi Anda telah dikirimi permintaan untuk memutuskan akun Anda',
  APPROVE_TO_DISCONNECT: 'Setujui untuk memutuskan hubungan',
  TRANSFER_TO_AN_OTHER_DEPUTY: 'Pindah ke deputi lain',
  THE_PD_IS_WAITING_FOR_DISCONNECTION:
    'Deputi Profesional sedang menunggu pemutusan hubungan',
  NO_OF_YEARS_REPAYMENT_COMPLETED: 'Jumlah tahun / pembayaran selesai',
  USE_EMAIL_OTP_CODE: 'Gunakan kode OTP email',
  COLOR: 'Warna',
  INCORRECT_PASSWORD: 'Kata kunci Salah!',
  RELEASE_PASSWORD: 'Lepaskan kata sandi',
  SUCCESSFULLY_RELEASE_PASSWORD: 'Berhasil melepaskan kata sandi',
  FAILED_TO_RELEASE_PASSWORD: 'Gagal melepaskan kata sandi',
  PRIVATE_FOLDER: 'Folder Private',
  CREATE_PRIVATE_FOLDER: 'Buat folder Private',
  WARNING_MSG: 'Pesan peringatan',
  PERMANENTLY_DELETE_PRIVATE_FOLDER_WARNING:
    'Anda harus menghapus semua file pribadi sebelum menghapus folder Private',
  PASSWORD_OF_THE_PRIVATE_FOLDER: 'Kata sandi folder Private',
  DOWNLOAD_PRIVATE_FOLDER_MSG:
    'Masukkan <strong>sandi Private Folder</strong> untuk mengunduh Vaultbox, termasuk Private Folder',
  SET_PASSWORD: 'Tetapkan kata sandi',
  SUCCESSFULLY_SET_PASSWORD: 'Tetapkan kata sandi berhasil',
  FAILED_TO_SET_PASSWORD: 'Mengatur kata sandi gagal',
  OTHER_PERSON_WARNING_MSG:
    'Anda tidak dapat memilih seseorang yang tidak memiliki email dan nomor telepon',
  RELEASE_PASSWORD_NOTES:
    'Jika pengguna memiliki Folder Pribadi, Rekanan harus membuka kunci pengguna untuk melepaskan kata sandi',
  YOU_CANNOT_NAME_THE_FOLDER_PRIVATE:
    'Anda tidak dapat memberi nama folder "Private Folder"',
  SUN: 'Minggu',
  MON: 'Senin',
  TUE: 'Selasa',
  WED: 'Rabu',
  THU: 'Kamis',
  FRI: 'Jumat',
  SAT: 'Sabtu',
  SUN_MD: 'Min',
  MON_MD: 'Sen',
  TUE_MD: 'Sel',
  WED_MD: 'Rab',
  THU_MD: 'Kam',
  FRI_MD: 'Jum',
  SAT_MD: 'Sab',
  JAN: 'Januari',
  FEB: 'Februari',
  MAR: 'Maret',
  APR: 'April',
  MAY: 'Mei',
  JUN: 'Juni',
  JUL: 'Juli',
  AUG: 'Agustus',
  SEP: 'September',
  OCT: 'Oktober',
  NOV: 'November',
  DEC: 'Desember',
  JAN_MD: 'Jan',
  FEB_MD: 'Feb',
  MAR_MD: 'Mar',
  APR_MD: 'Apr',
  MAY_MD: 'Mei',
  JUN_MD: 'Jun',
  JUL_MD: 'Jul',
  AUG_MD: 'Agu',
  SEP_MD: 'Sep',
  OCT_MD: 'Okt',
  NOV_MD: 'Nov',
  DEC_MD: 'Des',
  MONTH: 'Bulan',
  YEAR: 'Tahun',
  SELECT_EVENT: 'Pilih acara',
  ADD_NEW_EVENT: 'Tambahkan acara baru',
  TO: 'Ke',
  LOGIN_WITH_GOOGLE: 'Masuk dengan Google',
  LOGIN_WITH_FACEBOOK: 'Masuk dengan Facebook',
  VALUATION_DATE_IN_BASE_CURRENCY: 'Tanggal penilaian dalam mata uang dasar',
  CREATE_TEXT_FILE: 'Buat file teks baru',
  EDIT_TEXT_FILE: 'Edit file teks',
  PASSWORD_COPIED: 'Kata sandi disalin!',
  COPY_PASSWORD: 'Salin kata sandi',
  WEBSITE: 'Situs web',
  UPGRAGE_PLAN_SUMMARY:
    'Batas Paket vaultbox Esensial 20 item telah tercapai. Harap upgrade paket langganan Anda untuk menyimpan lebih banyak data.',
  LIMITED_STORAGE_VAULTBOX_LITE: '2 GB penyimpanan file yang aman',
  LIMITED_RECORD_VAULTBOX_LITE:
    '20 item adalah batas maksimum keseluruhan untuk: asset/liability, files, contacts, passwords dan events',
  MARKETING_MATERIALS: 'Materi pemasaran',
  MARKETING_MATERIALS_STATUS_TEXT:
    'Anda dapat memilih untuk menerima atau tidak menerima materi pemasaran',
  INPUT_COUNTRY_MSG: 'Pilih negaramu!',
  APPLY: 'Menerapkan',
  WAITING_FOR_REDIRECT: 'Menunggu pengalihan',
  ACCESS_DENIED: 'Akses ditolak',
  ARE_YOU_SURE_YOU_WANT_TO_RESET_PASSWORD_OF_PRIVATE_FOLDER:
    'Apakah Anda yakin ingin mengatur ulang kata sandi Private Folder?',
  RESET_PASSWORD_OF_PRIVATE_FOLDER: 'Reset ulang kata sandi Private Folder',
  SUCCESSFULLY_RESET_PASSWORD_OF_PRIVATE_FOLDER:
    'Berhasil reset ulang kata sandi Private Folder',
  FAILED_TO_RESET_PASSWORD_OF_PRIVATE_FOLDER:
    'Gagal reset kata sandi Private Folder!',
  SKIP_PRIVATE_FOLDER: 'Lewati Private Folder',
  CANNOT_MOVE_LOCK_FILE_TO_PRIVATE_FOLDER:
    'Tidak dapat memindahkan file yang dikunci ke Private Folder',
  CANNOT_COPY_LOCK_FILE_TO_PRIVATE_FOLDER:
    'Tidak dapat menyalin file yang dikunci ke Private Folder',
  CANNOT_CHOOSE_FILE_FROM_PRIVATE_FOLDER:
    'Tidak dapat memilih file di Private Folder',
  RESET_PASSWORD_MSG:
    'Masukkan kata sandi akun vaultbox Anda untuk melanjutkan',
  LOCK_PRIVATE_FOLDER_MSG:
    'Akses Anda ke Private Folder akan ditolak selama 24 jam, silakan coba lagi nanti.',
  RECORD: 'Catatan',
  YOU_NEED_TO_UPGRADE_YOUR_PLAN:
    'Anda akan mencapai batas penyimpanan <strong>vaultbox Essential</strong>, harap upgrade untuk mendapatkan pengalaman yang lebih baik',
  DIGITAL_VAULT: 'Brankas digital',
  DIGITAL_VAULT_CONTENT:
    'Simpan surat wasiat Anda, polis asuransi, dan dokumen penting lainnya dengan enkripsi kualitas bank. Penyimpanan gratis sebesar 2 GB memenuhi kebutuhan dasar sebagian besar pengguna.',
  ASSETS_AND_LIABILITY_REGISTRY_CONTENT:
    'Monitor kekayaan bersih Anda & buat laporan keuangan',
  PASSWORD_VAULT: 'Penyimpanan kata sandi',
  PASSWORD_VAULT_CONTENT:
    'Amankan semua kata sandi Anda di satu lokasi terpusat',
  CONTACT_MANAGEMENT: 'Pengelolaan kontak',
  CONTACT_MANAGEMENT_CONTENT:
    'Simpan semua kontak penting Anda (mis. Pengacara, agen asuransi, petugas bank)',
  CALENDAR_FEATURE: 'Fitur Kalender',
  CALENDAR_FEATURE_CONTENT: 'Monitor pembayaran dan langganan Anda',
  ESSENTIAL_VAULT_STORAGE: 'Penyimpanan 2 GB (hingga 20 item)',
  INCLUDE_EVERYTHING_IN_VAULTBOX_ESSENTIAL:
    'Termasuk semua fitur dari vaultbox Essential',
  DEPUTIES_CONTENT:
    'Menunjuk anggota keluarga dan individu tepercaya sebagai bagian dari "manajemen warisan cerdas"',
  PROFESSIONAL_DEPUTY: 'Deputi Profesional',
  PRO_STORAGE: 'Penyimpanan 50GB',
  ADD_ON_THIS_SERVICE_AT: 'Tambahkan layanan ini di',
  FREE_TO_USE_GET_STARTED_NOW_UPGRADE_ANYTIME:
    'Gratis. Mulai sekarang. Upgrade kapan saja.',
  ULTIMATE_FINANCIAL_AND_LEGACY_PLAN: 'Rencana keuangan dan warisan terbaik.',
  IDEAL_FOR_SMALL_ENTERPRISES_AND_FAMILY:
    'Ideal untuk perusahaan UMKM dan keluarga.',
  RECORDS: 'Catatan',
  ADD_PASSWORD: 'Tambahkan kata sandi',
  COPY_USERNAME: 'Salin nama pengguna',
  USERNAME_COPIED: 'Nama pengguna disalin!',
  AVATAR: 'Avatar',
  SUCCESSFULLY_UPLOADED_AVATAR: 'Avatar berhasil diunggah',
  EDIT_AVATAR: 'Edit avatar',
  UPLOAD_AVATAR: 'Unggah avatar',
  FAILED_UPLOAD_AVATAR_MSG:
    'Gambar gagal diunggah. Hanya mendukung PNG, JPEG dengan ukuran maksimal 2MB. Silakan coba lagi.',
  DRAG_AND_DROP_IMAGE_HERE_OR: 'Seret & lepas gambar di sini atau',
  BROWSE_FROM_YOUR_COMPUTER: 'elajahi dari komputer Anda',
  ZOOM: 'Perbesar',
  ADD_MEMBERS: 'Tambahkan anggota',
  SUCCESSFULLY_ADDED_MEMBERS: 'Berhasil menambahkan anggota',
  SUCCESSFULLY_LEFT_GROUP: 'Berhasil keluar dari grup',
  SUCCESSFULLY_REMOVED_MEMBERS: 'Berhasil menghapus anggota',
  FAILED_TO_ADD_MEMBERS: 'Gagal menambahkan anggota',
  FAILED_TO_LEAVE_GROUP: 'Gagal keluar dari grup',
  FAILED_TO_REMOVE_MEMBER: 'Gagal menghapus anggota',
  PLEASE_ADD_AT_LEAST_ONE_EMAIL: 'Harap tambahkan setidaknya satu email',
  LIMIT_MEMBER_MSG:
    'Hingga 5 pengguna (termasuk pengguna utama) dapat ditambahkan. Harap periksa dan coba lagi.',
  ONE_OR_MORE_EMAIL_ALREADY_EXIST:
    'Satu atau beberapa email sudah ada di grup. Silakan pilih email lain',
  ARE_YOU_SURE_TO_REMOVE_THIS_MEMBER:
    'Apakah Anda yakin akan menghapus anggota ini?',
  ARE_YOU_SURE_TO_LEFT_GROUP: 'Apakah Anda yakin akan keluar dari grup?',
  LEAVE_GROUP: 'Meninggalkan grup',
  MEMBERS: 'Anggota',
  NOT_BE_INVITED_TO_GROUP_MSG:
    'Anda perlu diundang untuk bergabung dengan Paket Grup vaultbox ini',
  REQUESTED_TO_RELEASE: 'Diminta untuk melepaskan',
  MAIN_USER_CHANGE_SUBSCRIPTION_MSG:
    'Anda tidak dapat mengubah langganan Anda ketika masih ada anggota dalam grup',
  LOADING: 'Memuat',
  SUCCESSFULLY_ACCESSED_YOUR_PRIVATE_FOLDER: '{{name}} ({{email}}) Berhasil mengakses Private Folder Anda',
  ATTEMPT_TO_OPEN_THE_LOCKED_PRIVATE_FOLDER: '{{name}} ({{email}}) Coba buka Private Folder yang terkunci',
  PLEASE_MAKE_SURE_THAT_YOUR_FACEBOOK_ACCOUNT_HAS_AN_EMAIL: 'Pastikan akun Facebook Anda memiliki email',
  SUCCESSFULLY_SET_COLOR: 'Berhasil mengatur warna',
  FAILED_TO_SET_COLOR: 'Gagal menyetel warna',
  COLORS_CUSTOMIZATION: 'Penyesuaian warna',
  RESET_TO_DEFAULT: 'Setel ulang ke default',
  FAILED_TO_RESET_COLOR: 'Gagal mengatur ulang warna',
  PENDING_PASSWORDS: 'Kata sandi tertunda',
  REJECTED_PASSWORDS: 'Kata sandi ditolak',
  PENDING_EVENTS: 'Acara tertunda',
  REJECTED_EVENTS: 'Acara yang ditolak',
  UNLINK_EVENT: 'Batalkan tautan acara',
  CHANGE_VAULTBOX_PRO_TO_VAULTBOX_GROUP_MSG:
    'Anda akan segera beralih ke Grup vaultbox. Apakah Anda yakin untuk mengubah langganan?',
  INVITATIONS: 'Undangan',
  SUCCESSFULLY_ACCEPTED_INVITATION: 'Undangan berhasil diterima',
  FAILED_TO_ACCEPT_INVITATION: 'Gagal menerima undangan!',
  SUCCESSFULLY_DECLINED_INVITATION: 'Undangan berhasil ditolak',
  FAILED_TO_DECLINE_INVITATION: 'Gagal menolak undangan!',
  GROUP_OWNER: 'Pemilik grup',
  LOGOUT_AND_ADD_CARD_LATER: 'Keluar dan tambahkan kartu nanti',
  INCLUDE_EVERYTHING_IN_VAULTBOX_PRO: 'Sertakan semuanya di vaultbox Pro',
  DEPUTIES_FOR_ALL_MEMBERS: 'Deputi untuk semua anggota',
  FOR_UP_TO_MEMBERS: 'Untuk maksimal 5 anggota',
  None: 'Tidak ada',
  Weekly: 'Mingguan',
  Monthly: '1 bulan',
  Quarterly: '3 bulan',
  Yearly: '12 bulan',
  Annually: '12 bulan',
  day: 'hari',
  month: 'bulan',
  year: 'tahun',
  days: 'hari',
  Months: 'Bulan',
  months: 'bulan',
  Years: 'Tahun',
  years: 'tahun',
  Cash: 'Tunai',
  Property: 'Properti',
  Vehicle: 'Kendaraan',
  Insurance: 'Asuransi',
  Crypto: 'Kripto',
  Trust: 'Trust',
  NRIC: 'NIK',
  FIN: 'FIN',
  Passport: 'Paspor',
  Personal: 'Pribadi',
  Other: 'Lain',
  Mobile: 'Ponsel',
  Work: 'Kerja',
  Home: 'Rumah',
  Hospitalisation: 'Kesehatan',
  Travel: 'Perjalanan',
  House: 'Rumah',
  Apartment: 'Apartemen',
  Commercial: 'Komersial',
  Freehold: 'Hak milik',
  Leasehold: 'Penyewaan',
  name: 'nama',
  value: 'nilai',
  Currency: 'Mata uang',
  Items: 'Item',
  Valuation: 'Penilaian',
  Ticker: 'Ticker',
  ISIN: 'ISIN',
  Individual: 'Individu',
  Organisation: 'Organisasi',
  Savings: 'Tabungan',
  Current: 'Giro',
  Jewellery: 'Perhiasan',
  Furnishing: 'Perabotan',
  Antiques: 'Barang antik',
  Art: 'Seni',
  Collectible: 'Koleksi',
  Settlor: 'Settlor',
  Trustee: 'Wali',
  Beneficiary: 'Penerima manfaat',
  Protector: 'Pelindung',
  Settlors: 'Settlors',
  Trustees: 'Wali',
  Beneficiaries: 'Penerima manfaat',
  Protectors: 'Pelindung',
  Irrevocable: 'Tidak dapat dibatalkan',
  Revocable: 'Dapat dibatalkan',
  Private: 'Pribadi',
  Bare: 'Bare',
  Charitable: 'Sosial',
  Investment: 'Investasi',
  Fixed: 'Tetap',
  Variable: 'Variabel',
  Ordinary: 'Biasa',
  Preference: 'Preferensi',
  Convertible: 'Konversi',
  CPF: 'CPF',
  SRS: 'SRS',
  Superannuation: 'Superannuation',
  Pension: 'Pensiun',
  Education: 'Pendidikan',
  'Total and Permanent Disability': 'Cacat total and tetap',
  'Investment-Linked Policy': 'Polis Terkait Investasi',
  'Income/Endowment/Savings': 'Pendapatan/Tabungan',
  'Annuity/Retirement': 'Dana hari tua/Pensiun',
  'Life – Whole': 'Seumur hidup',
  'Personal Accident': 'Kecelakaan diri',
  'Precious stone': 'Batu berharga',
  'Structured deposit': 'Deposito terstruktur',
  'Fixed deposit': 'Deposito berjangka',
  'Plain Text': 'Teks Biasa',
  'Word Document': 'Dokumen Word',
  'Title with icon': 'Judul dengan ikon',
  'Other medical': 'Medis lainnya',
  'Critical Illness': 'Penyakit Kritis',
  'Life - Universal': 'Jiwa - Universal',
  'Life - Term': 'Jiwa - Jangka',
  'Other assets': 'Aset lainnya',
  'Property loan': 'Pinjaman properti',
  'Vehicle loan': 'Pinjaman kendaraan',
  'Credit card': 'Kartu kredit',
  'Loan from others': 'Pinjaman dari orang lain',
  'Loan to others': 'Pinjaman kepada orang lain',
  'Other liability': 'Kewajiban lainnya',
  'Retirement account': 'Akun pensiun',
  'Brokerage account': 'Akun broker',
  'Equity - listed': 'Ekuitas - tercatat',
  'Equity - unlisted': 'Ekuitas - tidak tercatat',
  'per month': 'per bulan',
  'per year': 'per tahun',
  'Free Sign Up': 'Pendaftaran gratis',
  'No Credit Card Required': 'Tidak Perlu Kartu Kredit'
}
