import React, { useState, useContext } from 'react'
import { Modal, Form, message, InputNumber } from 'antd'
import FormItem from '../override/FormItem'
import api from '../../lib/api'
import AuthContext from '../../contexts/AuthContext'
import { onError } from '../../lib/sentry'
import { useSelector, useDispatch } from 'react-redux'
import { fetchUser } from '../../features/user/userSlice'
import { distributeShareKeys } from '../../features/deputies/deputiesSlice'
import VaultContext from '../../contexts/VaultContext'
import { useTranslation } from 'react-i18next'

const SharesThresholdModal = props => {
  const { visible, setVisible, maxSharesThreshold, form } = props
  const { user } = useContext(AuthContext)
  const { masterKey } = useContext(VaultContext)
  const [isSaving, setIsSaving] = useState(false)
  const { sharesThreshold } = useSelector(state => state.user).user
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const handleSave = () => {
    form.validateFields(async (err, values) => {
      if (err) {
        return
      }
      setIsSaving(true)
      try {
        await api.updateSharesThreshold(
          user.username,
          JSON.stringify({
            sharesThreshold: values.sharesThreshold
          })
        )

        message.success(t('SUCCESSFULLY_UPDATED_NUMBER_OF_DEPUTIES_REQUIRED'))
        dispatch(fetchUser(user.username))
        dispatch(
          distributeShareKeys(user.username, masterKey, values.sharesThreshold)
        )

        resetModal()
        setIsSaving(false)
      } catch (error) {
        message.error(t('FAILED_TO_UPDATE_NUMBER_OF_DEPUTIES_REQUIRED'))
        onError(error)
        setIsSaving(false)
      }
    })
  }

  const resetModal = () => {
    setVisible(false)
    form.resetFields()
  }

  return (
    <Modal
      title={t('NUMBER_OF_DEPUTIES_REQUIRED_TO_UNLOCK_VAULTBOX')}
      visible={visible}
      onCancel={resetModal}
      onOk={handleSave}
      okText={t('SAVE')}
      cancelText={t('CANCEL')}
      maskClosable={false}
      okButtonProps={{ loading: isSaving }}
    >
      <Form>
        <FormItem>
          {form.getFieldDecorator('sharesThreshold', {
            initialValue: sharesThreshold || 2,
            rules: [
              {
                required: true,
                message: t('ENTER_NUMBER_OF_DEPUTIES')
              }
            ]
          })(<InputNumber min={2} max={maxSharesThreshold} />)}
        </FormItem>
      </Form>
    </Modal>
  )
}

const WrappedUpdateSharesThresholdForm = Form.create({
  name: 'SharesThresholdModal'
})(SharesThresholdModal)

export default WrappedUpdateSharesThresholdForm
