import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { H4, H5 } from '../override/Typography'

export default function TableHeader({ title, count }) {
  const theme = useContext(ThemeContext)
  return (
    <div
      style={{ margin: '30px 0 10px', display: 'flex', alignItems: 'center' }}
    >
      <H4 style={{ marginRight: 10 }}>{title}</H4>
      <H5 color={theme.dark2}>{count}</H5>
    </div>
  )
}
