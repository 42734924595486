import gql from 'graphql-tag'

export const createS3Change = gql`
  mutation ($message: String!, $userId: String!) {
    createS3Change(input: { message: $message, userId: $userId }) {
      id
      message
      userId
    }
  }
`

export const deleteS3Change = gql`
  mutation ($id: ID!) {
    deleteS3Change(input: { id: $id }) {
      id
    }
  }
`
