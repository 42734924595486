import React, { useState, useContext } from 'react'
import {
  Form,
  Modal,
  Upload,
  Icon,
  message,
  Alert,
  Button,
  Tooltip
} from 'antd'
//import { StringResources } from '../../share/StringResources'
import FormItem from '../override/FormItem'
import { ASSET_TYPES, LIABILITY_TYPES } from '../../share/Constants'
import moment from 'moment'
import { useSelector } from 'react-redux'
import uuidv4 from 'uuid/v4'
import AuthContext from '../../contexts/AuthContext'
import VaultContext from '../../contexts/VaultContext'
import { importAssetsLiabilities } from '../../lib/pouchDb'
import { Workbook } from 'exceljs'
import {
  downloadExcelImportTemplate,
  getColumnsFromSubtype
} from './assetLiabilityHelpers'
import { onError } from '../../lib/sentry'
import { parseValue } from '../../share/importHelpers'
import { draggerProps, normFile } from '../../share/formHelpers'
import { useTranslation, Trans } from 'react-i18next'
import {
  removeHtmlTags,
  showUpgradeSubscriptionPlanConfirm
} from './../../share/helpers'
import { useMutation } from 'react-apollo-hooks'
import { createS3Change } from '../../graphql/mutations'
import SubscriptionModal from '../payment/SubscriptionModal'

const { Dragger } = Upload

const valueUnitObj = (value, unit) => ({ value, unit })

function ImportExcelModal({
  visible,
  setVisible,
  sourceAssetsLiabilities,
  form
}) {
  const [isSaving, setIsSaving] = useState(false)
  const [errMsg, setErrMsg] = useState('')
  const [subscriptionModalVisible, setSubscriptionModalVisible] = useState()
  const { t } = useTranslation()

  const { user } = useContext(AuthContext)
  const { masterKey, limitedRecord } = useContext(VaultContext)

  const { allRates } = useSelector(state => state.settings)
  const { limit } = useSelector(state => state.customer)

  const { getFieldDecorator } = form

  const [addS3Change] = useMutation(createS3Change)

  const handleOk = () => {
    setErrMsg('')
    form.validateFields(async (err, values) => {
      if (err) {
        return
      }
      const allSubTypes = [
        ...Object.values(ASSET_TYPES),
        ...Object.values(LIABILITY_TYPES)
      ]

      setIsSaving(true)

      const file = values.file[0].originFileObj

      const workbook = new Workbook()
      const fileReader = new FileReader()
      fileReader.onload = e => {
        const buffer = e.target.result
        let updatedRecordsCount = 0,
          backdatedAmendRecordsCount = 0
        let records = []

        workbook.xlsx
          .load(buffer)
          .then(async wb => {
            let allErrors = []
            wb.eachSheet((ws, id) => {
              if (allSubTypes.includes(ws.name)) {
                let errors = []
                let headerRow = []
                let rowIndex

                const columns = getColumnsFromSubtype(ws.name)
                const columnsTitles = columns
                  .filter(col => !col.ignore)
                  .map(col => col.title)
                ws.eachRow({ includeEmpty: false }, (row, index) => {
                  const titles = ws.getRow(index).values
                  if (
                    columnsTitles.every(colTitle => titles.includes(colTitle))
                  ) {
                    rowIndex = index
                    headerRow = titles
                  }
                })

                const requiredColumns = columns.filter(col => col.required)
                requiredColumns.forEach(col => {
                  if (!headerRow.includes(col.title)) {
                    errors.push(
                      `${t('SHEET')} ${ws.name}: ${t('MISSING_COLUMN')} ${
                        col.title
                      }`
                    )
                  }
                })
                if (errors.length) {
                  allErrors = allErrors.concat(errors)
                  return
                }

                ws.eachRow({ includeEmpty: false }, (row, rowNumber) => {
                  if (rowNumber <= rowIndex) return

                  let rowData = {}
                  headerRow.forEach((val, index) => {
                    const mappingColumn = columns.find(col => col.title === val)

                    if (mappingColumn) {
                      if (mappingColumn.required && !row.values[index]) {
                        errors.push(
                          `${t('SHEET')} ${ws.name} - ${t(
                            'ROW'
                          )} ${rowNumber}: ${mappingColumn.title} ${t(
                            'IS_MANDATORY'
                          )}.`
                        )
                      } else {
                        rowData[mappingColumn.key] = parseValue(
                          mappingColumn.type,
                          row.values[index]
                        )
                      }
                    }
                  })
                  if (errors.length) {
                    allErrors = allErrors.concat(errors)
                    return
                  }

                  const existingRecord = sourceAssetsLiabilities.find(
                    rc => rc.title === rowData.title && rc.subType === ws.name
                  )

                  let record
                  if (existingRecord) {
                    record = { ...existingRecord, ...rowData }
                    updatedRecordsCount++
                  } else {
                    record = {
                      _id: uuidv4(),
                      ...rowData
                    }
                  }

                  const valuationDate = rowData.valuationDate
                    ? moment(rowData.valuationDate)
                    : moment()
                  const isBackdated =
                    existingRecord?.valuationDate &&
                    valuationDate.isBefore(existingRecord.valuationDate)
                  if (isBackdated) backdatedAmendRecordsCount++

                  const rate = record.currency
                    ? allRates[record.currency.toUpperCase()]
                    : undefined

                  const references = [
                    {
                      name: record.reference1Name,
                      value: record.reference1Value
                    },
                    {
                      name: record.reference2Name,
                      value: record.reference2Value
                    },
                    {
                      name: record.reference3Name,
                      value: record.reference3Value
                    }
                  ].filter(data => data.name && data.value)

                  const newRecord = {
                    ...record,
                    currency: record.currency?.toUpperCase(),
                    exchange: record.exchange?.toUpperCase(),
                    subType: ws.name,
                    valuationDate,
                    descriptionWithMarkup: record.description || '',
                    valuationInBaseCurrency: record.valuationInAssetCurrency
                      ? record.valuationInAssetCurrency / +rate
                      : undefined,
                    outstandingValueInBaseCurrency:
                      record.outstandingValueInLiabilityCurrency
                        ? record.outstandingValueInLiabilityCurrency / +rate
                        : undefined,
                    sumAssuredInBaseCurrency: record.sumAssuredInAssetCurrency
                      ? +record.sumAssuredInAssetCurrency / +rate
                      : undefined,
                    rentAmount: record.rentAmountValue
                      ? valueUnitObj(
                          record.rentAmountValue,
                          record.rentAmountUnit
                        )
                      : undefined,
                    floorSize: record.floorSizeValue
                      ? valueUnitObj(
                          record.floorSizeValue,
                          record.floorSizeUnit
                        )
                      : undefined,
                    premium: record.premiumValue
                      ? valueUnitObj(record.premiumValue, record.premiumUnit)
                      : undefined,
                    interestRate: record.interestRateValue
                      ? valueUnitObj(
                          record.interestRateValue,
                          record.interestRateUnit
                        )
                      : undefined,
                    // lengthOfLoan: record.loanPeriodValue
                    // ? valueUnitObj(
                    //     record.loanPeriodValue,
                    //     record.loanPeriodUnit
                    //   )
                    // : undefined,
                    loanPeriod: record.loanPeriodValue
                      ? valueUnitObj(
                          record.loanPeriodValue,
                          record.loanPeriodUnit
                        )
                      : undefined,
                    references: references.length
                      ? references
                      : existingRecord?.references || [],
                    typeOfTrustInterest: record.typeOfTrustInterestKey
                      ? {
                          key: record.typeOfTrustInterestKey
                        }
                      : undefined,
                    isBackdated
                  }

                  removeHtmlTags(newRecord)

                  records.push(newRecord)
                })
              }
            })

            if (allErrors.length) {
              throw Error(allErrors.join('\n'))
            }

            if (updatedRecordsCount) {
              Modal.confirm({
                title: t('UPDATING_ASSETS_LIABILITIES'),
                content: (
                  <>
                    <div>
                      <Trans
                        i18nKey="UPDATING_ASSETS_LIABILITIES_SUMMARY"
                        values={{ updatedRecordsCount: updatedRecordsCount }}
                      ></Trans>{' '}
                      {!!backdatedAmendRecordsCount && (
                        <span>
                          ({backdatedAmendRecordsCount}{' '}
                          {t('BACK_DATED_AMEND'.toLowerCase())}))
                        </span>
                      )}
                    </div>
                    <div>{t('ARE_YOU_SURE_YOU_WANT_TO_CONTINUTE')}</div>
                  </>
                ),
                onOk: async () => {
                  if (limitedRecord + records.length > limit) {
                    setIsSaving(false)
                    showUpgradeSubscriptionPlanConfirm(
                      setSubscriptionModalVisible
                    )
                    return
                  }
                  await importAssetsLiabilities(
                    user.username,
                    records,
                    masterKey
                  )
                  setIsSaving(false)
                  resetModal()
                  localStorage.setItem('NotReload', true)
                  addS3Change({
                    variables: {
                      message: 'assetsLiabilities',
                      userId: user.username
                    }
                  })
                  message.success(t('SUCCESSFULLY_IMPORTED_DATA'))
                },
                onCancel: () => {
                  setIsSaving(false)
                }
              })
            } else {
              if (records.length) {
                if (limitedRecord + records.length > limit) {
                  setIsSaving(false)
                  showUpgradeSubscriptionPlanConfirm(
                    setSubscriptionModalVisible
                  )
                  return
                }
                await importAssetsLiabilities(user.username, records, masterKey)
                localStorage.setItem('NotReload', true)
                addS3Change({
                  variables: {
                    message: 'assetsLiabilities',
                    userId: user.username
                  }
                })
                message.success(t('SUCCESSFULLY_IMPORTED_DATA'))
              }

              setIsSaving(false)
              resetModal()
            }
          })

          .catch(err => {
            onError(err)
            setErrMsg(t('FAILED_TO_IMPORT_DATA'))
            setIsSaving(false)
          })
      }
      fileReader.readAsArrayBuffer(file)
    })
  }

  const resetModal = () => {
    form.resetFields()
    setVisible(false)
    setErrMsg('')
  }

  return (
    <>
      <Modal
        title={t('IMPORT_FROM_EXCEL')}
        visible={visible}
        okText={t('SAVE')}
        cancelText={t('CANCEL')}
        onOk={handleOk}
        onCancel={resetModal}
        okButtonProps={{ loading: isSaving }}
        maskClosable={false}
      >
        <Form className="upload-form">
          <FormItem label={t('FILE')}>
            {getFieldDecorator('file', {
              valuePropName: 'fileList',
              getValueFromEvent: normFile,
              rules: [
                {
                  required: true,
                  message: t('SELECT_FILE_TO_UPLOAD_MSG')
                },
                {
                  validator: (rule, value, callback) => {
                    const fileType = value && value[0]?.originFileObj.type
                    if (
                      fileType &&
                      fileType !==
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    ) {
                      callback(t('INVALID_FILE_TYPE'))
                    } else {
                      callback()
                    }
                  }
                }
              ]
            })(
              <Dragger {...draggerProps}>
                <p className="ant-upload-drag-icon">
                  <Icon type="upload" />
                </p>
                <p className="ant-upload-hint">
                  {t('UPLOAD_FILE_INSTRUCTION')}
                </p>
              </Dragger>
            )}
          </FormItem>
          <FormItem
            label={
              <span>
                Template:{' '}
                <Tooltip title={t('DOWNLOAD_SAMPLE_TEMPLATE')}>
                  <Button
                    icon="download"
                    type="link"
                    onClick={downloadExcelImportTemplate}
                  />
                </Tooltip>
              </span>
            }
            colon={false}
          ></FormItem>
        </Form>
        {errMsg && (
          <Alert
            style={{ marginTop: 10, whiteSpace: 'pre-wrap' }}
            description={errMsg}
            closable
            afterClose={() => setErrMsg('')}
            type="error"
          />
        )}
      </Modal>
      <SubscriptionModal
        visible={subscriptionModalVisible}
        setVisible={setSubscriptionModalVisible}
      />
    </>
  )
}

const WrappedImportExcelForm = Form.create({ name: 'ImportExcelModal' })(
  ImportExcelModal
)
export default WrappedImportExcelForm
