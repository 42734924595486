import React, { useState, useContext, useEffect } from 'react'
import { Modal, Alert, message } from 'antd'
import AddMultipleFields from '../common/AddMultipleFields'
import FormItem from '../override/FormItem'
import WrappedForm from '../common/WrappedForm'
import AuthContext from '../../contexts/AuthContext'
import { useTranslation } from 'react-i18next'
import api from '../../lib/api'
import { compareWithCurrentUserEmail } from '../../share/formHelpers'
import { getUserAttributeValue, getUserData } from '../../lib/cognito'
import { onError } from '../../lib/sentry'
import { useSelector } from 'react-redux'
import { getExternalUserAttributes } from '../../share/helpers'

function AddMembersModal(props) {
  const { allowedMembers, membersCode } = useSelector(state => state.customer)

  const { visible, setVisible, members, fetchUser } = props
  const { user } = useContext(AuthContext)

  const [errMsg, setErrMsg] = useState('')
  const [isSharing, setIsSharing] = useState(false)
  const [email, setEmail] = useState('')
  const { t } = useTranslation()

  let formRef

  useEffect(() => {
    const externalUser = localStorage.getItem('External_User')
    if (externalUser) {
      const userAttributes = getExternalUserAttributes()
      setEmail(userAttributes.email)
    } else {
      getUserData(
        user,
        async (err, data) => {
          if (err) {
            onError(err)
            return
          }
          setEmail(getUserAttributeValue(data.UserAttributes, 'email'))
        },
        { bypassCache: true }
      )
    }
  }, [user])

  const handleCancel = e => {
    formRef && formRef.props.form.resetFields()
    setErrMsg('')
    setVisible(false)
    setIsSharing(false)
  }

  const handleSave = () => {
    formRef.props.form.validateFields(async (err, values) => {
      if (err) return
      if (!values.emails?.length) {
        setErrMsg(t('PLEASE_ADD_AT_LEAST_ONE_EMAIL'))
        return
      }

      const existedEmails = members?.filter(m =>
        values.emails.find(e => e.email === m.email)
      )

      if (!!existedEmails?.length) {
        setErrMsg(t('ONE_OR_MORE_EMAIL_ALREADY_EXIST'))
        return
      }

      if ((members?.length || 0) + values.emails.length > allowedMembers) {
        setErrMsg(t('LIMIT_MEMBER_MSG'))
        return
      }

      setIsSharing(true)
      try {
        const data = {
          membersCode,
          emails: values.emails
        }

        await api.addMembers(user.username, JSON.stringify(data))
        fetchUser()
        setIsSharing(false)
        setVisible(false)
        message.success(t('SUCCESSFULLY_ADDED_MEMBERS'))
      } catch (err) {
        setErrMsg(err.message)
        setIsSharing(false)
        message.error(t('FAILED_TO_ADD_MEMBERS'))
      }
    })
  }

  return (
    <Modal
      title={t('ADD_MEMBERS')}
      visible={visible}
      onCancel={handleCancel}
      onOk={handleSave}
      className="add-deputy-modal"
      okButtonProps={{
        loading: isSharing
      }}
    >
      <div className="steps-content">
        {errMsg && (
          <Alert
            type="error"
            message={errMsg}
            showIcon
            afterClose={() => setErrMsg('')}
            closable
            style={{ marginTop: '1em' }}
          />
        )}

        <WrappedForm wrappedComponentRef={fr => (formRef = fr)}>
          {getFieldDecorator =>
            visible && (
              <FormItem label={t('EMAILS')}>
                <AddMultipleFields
                  name="emails"
                  title={t('EMAIL')}
                  fields={[{ key: 'email', placeholder: 'email@example.com' }]}
                  getFieldDecorator={getFieldDecorator}
                  customRules={[
                    {
                      fieldKey: 'email',
                      rules: [
                        {
                          type: 'email',
                          message: t('INVALID_EMAIL_MSG')
                        },
                        {
                          validator: (rule, value, callback) =>
                            compareWithCurrentUserEmail(
                              rule,
                              value,
                              callback,
                              email
                            )
                        }
                      ]
                    }
                  ]}
                />
              </FormItem>
            )
          }
        </WrappedForm>
      </div>
    </Modal>
  )
}

export default AddMembersModal
