import { useMediaQuery } from '@material-ui/core'
import { Empty, message, Table } from 'antd'
import React, { Fragment, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { ThemeContext } from 'styled-components'
import VaultContext from '../../contexts/VaultContext'
import { fetchCustomer } from '../../features/payment/customerSlice'
import { fetchUser } from '../../features/user/userSlice'
import api from '../../lib/api'
import { onError } from '../../lib/sentry'
import Button from '../override/Button'
import SimpleHeader from '../override/SimpleHeader'
import { H4 } from '../override/Typography'

const GroupInvitations = props => {
  const { groupInvitations, fetchGroupInvitations, mainUserId } = props

  const { userId } = useContext(VaultContext)
  const theme = useContext(ThemeContext)

  const [isAccept, setIsAccept] = useState(null)
  const [isDecline, setIsDecline] = useState(null)

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))

  const handleAccept = async record => {
    setIsAccept(record.userId)
    try {
      await api.acceptGroupInvitation(
        userId,
        JSON.stringify({
          userId: record.userId,
          email: record.email,
          fullName: record.fullName,
          promotionCode: record.promotionCode
        })
      )
      await fetchGroupInvitations()
      dispatch(fetchCustomer(userId))
      dispatch(fetchUser(userId))
      message.success(t('SUCCESSFULLY_ACCEPTED_INVITATION'))
    } catch (error) {
      message.error(t('FAILED_TO_ACCEPT_INVITATION'))
      onError(error)
    } finally {
      setIsAccept(null)
    }
  }

  const handleDecline = async record => {
    setIsDecline(record.userId)
    try {
      await api.declineGroupInvitation(
        userId,
        JSON.stringify({
          userId: record.userId,
          email: record.email,
          fullName: record.fullName,
          promotionCode: record.promotionCode
        })
      )
      await fetchGroupInvitations()
      message.success(t('SUCCESSFULLY_DECLINED_INVITATION'))
    } catch (error) {
      message.error(t('FAILED_TO_DECLINE_INVITATION'))
      onError(error)
    } finally {
      setIsDecline(null)
    }
  }

  const groupInvitationColumns = [
    {
      key: 'email',
      dataIndex: 'email',
      title: t('EMAIL')
    },
    {
      key: 'fullName',
      dataIndex: 'fullName',
      title: t('NAME')
    },
    {
      key: 'actions',
      render: (text, record) => (
        <div style={{ textAlign: 'right' }}>
          <Button
            disabled={mainUserId}
            loading={isAccept === record.userId}
            onClick={() => handleAccept(record)}
          >
            {t('ACCEPT')}
          </Button>
          <Button
            loading={isDecline === record.userId}
            onClick={() => handleDecline(record)}
            style={{ color: theme.red, marginLeft: 8 }}
          >
            {t('DECLINE')}
          </Button>
        </div>
      )
    }
  ]

  return (
    <Fragment>
      <SimpleHeader title={<H4>{t('INVITATIONS')}</H4>} size="small" />
      <Table
        dataSource={groupInvitations}
        columns={groupInvitationColumns}
        rowKey="userId"
        pagination={false}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('Empty')}
            />
          )
        }}
        size={isMdUp ? '' : 'middle'}
      />
    </Fragment>
  )
}

export default GroupInvitations
