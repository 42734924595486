import { createSlice } from '@reduxjs/toolkit'
import api from '../../lib/api'
import { PRODUCT_NAME } from './../../share/Constants'

const initialState = {
  customer: {},
  subscription: {},
  allowedStorage: 0,
  isLoading: false,
  error: null,
  schedule: null,
  limit: undefined,
  allowedMembers: undefined,
  membersCode: undefined,
  vaultboxSubscription: {}
}

const customer = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    getCustomerStart(state) {
      state.isLoading = true
      state.error = null
    },
    getCustomerSuccess(state, action) {
      const { customer, schedule, productId } = action.payload

      state.customer = customer || {}
      state.subscription = customer?.subscriptions?.data[0] || {}
      state.schedule = schedule
      state.vaultboxSubscription = state.subscription?.items?.data.find(
        d => d.plan.product === productId
      )
      const storageFromPlan = state.vaultboxSubscription?.plan?.metadata?.storage

      if (storageFromPlan) {
        state.allowedStorage = storageFromPlan * 1024 * 1024 * 1024
        state.limit = undefined
      } else {
        state.allowedStorage = 2 * 1024 * 1024 * 1024
        state.limit = 20
      }

      const membersFromPlan = state.vaultboxSubscription?.plan?.metadata?.members
      if (membersFromPlan) {
        state.allowedMembers = membersFromPlan * 1
      }

      state.membersCode = state.vaultboxSubscription?.plan?.metadata?.membersCode
      state.isLoading = false
      state.error = null
    },
    getCustomerFailure(state, action) {
      state.isLoading = false
      state.error = action.payload
    }
  }
})

export const { getCustomerStart, getCustomerSuccess, getCustomerFailure } =
  customer.actions

export default customer.reducer

export const fetchCustomer = userId => async dispatch => {
  try {
    dispatch(getCustomerStart())
    const productId = (await api.getProducts()).data.data?.find(
      d => d.name === PRODUCT_NAME.VAULTBOX
    )?.id

    const res = await api.getCustomer(userId)
    dispatch(
      getCustomerSuccess(res.data.message ? {} : { ...res.data, productId })
    )
  } catch (err) {
    dispatch(getCustomerFailure(err.toString()))
  }
}

export const updateCancelSchedule = async (
  subscriptionId,
  userId,
  dispatch,
  cancelAtPeriodEnd = false
) => {
  try {
    const res = await api.updateSubscription(
      subscriptionId,
      JSON.stringify({
        cancel_at_period_end: cancelAtPeriodEnd
      })
    )
    if (res.data.message) throw res.data

    dispatch(fetchCustomer(userId))
  } catch (err) {
    throw err
  }
}
