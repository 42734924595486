import React from 'react'
import { Modal, Form } from 'antd'
//import { StringResources } from '../../share/StringResources'
import FormItem from '../override/FormItem'
import TextInput from '../common/TextInput'
import { withTranslation } from 'react-i18next'

class CreateFolderModal extends React.Component {
  render() {
    const {
      form,
      visible,
      handleSaveFolder,
      handleCancel,
      isEditFolder,
      folderName,
      folders,
      breadcrumb,
      isSaving,
      t
    } = this.props
    const { getFieldDecorator, setFieldsValue } = form

    return (
      <Modal
        visible={visible}
        title={isEditFolder ? t('RENAME_FOLDER') : t('CREATE_NEW_FOLDER')}
        okText={t('SAVE')}
        cancelText={t('CANCEL')}
        onOk={handleSaveFolder}
        onCancel={handleCancel}
        maskClosable={false}
        okButtonProps={{ loading: isSaving }}
        keyboard={false}
      >
        <Form>
          <FormItem>
            {getFieldDecorator('folderName', {
              initialValue: isEditFolder ? folderName : '',
              rules: [
                {
                  required: true,
                  message: t('INPUT_FOLDER_NAME_MSG')
                },
                {
                  validator: (rule, value, callback) => {
                    if (
                      value &&
                      folders.find(
                        f => f.path === `${breadcrumb}${value.trim()}/`
                      )
                    ) {
                      callback(t('FOLDER_NAME_EXISTS'))
                    } else {
                      callback()
                    }
                  }
                },
                {
                  validator: (rule, value, callback) => {
                    if (
                      value &&
                      value.trim() === 'Private Folder' &&
                      breadcrumb === ''
                    ) {
                      callback(t('YOU_CANNOT_NAME_THE_FOLDER_PRIVATE'))
                    } else {
                      callback()
                    }
                  }
                }
              ]
            })(
              <TextInput
                onKeyUp={event => {
                  event.key === 'Enter' && handleSaveFolder()
                }}
                placeholder={t('FOLDER_NAME')}
                name="folderName"
                setFieldsValue={setFieldsValue}
              />
            )}
          </FormItem>
        </Form>
      </Modal>
    )
  }
}
const WrappedCreateFolderForm = Form.create({ name: 'CreateFolderModal' })(
  CreateFolderModal
)
export default withTranslation()(WrappedCreateFolderForm)
