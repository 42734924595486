import React, { useState, useContext } from 'react'
import Button from '../override/Button'
import PasswordAddEdit from '../passwords/PasswordAddEdit'
import FormItem from '../override/FormItem'
import { useTranslation } from 'react-i18next'
import { Select, Tooltip } from 'antd'
import { useSelector } from 'react-redux'
import VaultContext from '../../contexts/VaultContext'
import { ACCESS_LEVEL } from './../../share/Constants'

export default function PasswordSelect({
  label,
  placeholder,
  required,
  getFieldDecorator,
  fieldName,
  initialValue,
  mode,
  onAddComplete,
  disabled
}) {
  const [visible, setVisible] = useState(false)
  const { isReadonly } = useContext(VaultContext)
  const { activePasswords, pendingPasswords } = useSelector(state =>
    isReadonly ? state.otherPasswords : state.passwords
  )
  const { accessLevel } = useSelector(state => state.settings)
  const { t } = useTranslation()

  const data =
    accessLevel === ACCESS_LEVEL.NEED_APPROVAL
      ? [...activePasswords, ...pendingPasswords]
      : activePasswords
  const sortedData = [...data].sort((a, b) => a.title.localeCompare(b.title))

  return (
    <div>
      <FormItem label={label}>
        <div style={{ display: 'flex' }}>
          <div style={{ width: 'calc(100% - 58px)' }}>
            {getFieldDecorator(fieldName, {
              initialValue,
              rules: [
                {
                  required: required,
                  message: label && `${t('ENTER')} ${label.toLowerCase()}`
                }
              ]
            })(
              <Select
                mode={mode}
                placeholder={
                  placeholder || `${t('SELECT')} ${label.toLowerCase()}`
                }
                allowClear={!mode}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toString()
                    .toLowerCase()
                    .includes(input.trim().toLowerCase())
                }
                disabled={disabled}
              >
                {sortedData.map(item => (
                  <Select.Option
                    key={item._id}
                    value={item._id}
                    disabled={!isReadonly && !!item.status}
                  >
                    {item.title}
                  </Select.Option>
                ))}
              </Select>
            )}
          </div>
          <Tooltip title={t('ADD_NEW')}>
            <Button
              size="large"
              onClick={() => setVisible(true)}
              className={`${label ? '' : 'no-label'}`}
              style={{ marginLeft: 8 }}
              icon="plus"
              disabled={disabled}
            />
          </Tooltip>
        </div>
      </FormItem>
      <PasswordAddEdit
        selectedPassword={{}}
        visible={visible}
        setVisible={setVisible}
        onAddComplete={onAddComplete}
      />
    </div>
  )
}
