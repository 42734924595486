import React from 'react'
import { Modal, Form, Select, Button } from 'antd'
//import { StringResources } from '../../share/StringResources'
import FormItem from '../override/FormItem'
import { onError } from '../../lib/sentry'
import { useTranslation } from 'react-i18next'

const AssetsLiabilitiesLinksModal = props => {
  const { Option } = Select
  const {
    visible,
    setVisible,
    assetsLiabilities,
    handleLinkAssetsLiabilities,
    record,
    form
  } = props
  const { getFieldDecorator } = form
  const initialLinks = record.links || []
  const { t } = useTranslation()

  const handleOk = () => {
    form.validateFields(async (err, values) => {
      if (err) {
        return
      }

      try {
        await handleLinkAssetsLiabilities(values.links)
        form.resetFields()
        setVisible(false)
      } catch (e) {
        onError(e)
      }
    })
  }

  const handleCancel = () => {
    form.resetFields()
    setVisible(false)
  }

  return (
    <Modal
      title={t('LINK_ASSETS_LIABILITIES')}
      visible={visible}
      onCancel={handleCancel}
      footer={[
        <Button key="cancel" type="default" onClick={handleCancel}>
          {t('CANCEL')}
        </Button>,
        <Button key="save" type="primary" onClick={handleOk}>
          {t('SAVE')}
        </Button>
      ]}
      maskClosable={false}
    >
      <Form>
        <FormItem>
          {getFieldDecorator('links')(
            <Select
              mode="multiple"
              placeholder={t('SELECT_ASSETS_LIABILITIES_TO_LINK')}
            >
              {assetsLiabilities.map(
                d =>
                  d._id !== record._id && (
                    <Option key={d._id} disabled={initialLinks.includes(d._id)}>
                      {d.title}
                    </Option>
                  )
              )}
            </Select>
          )}
        </FormItem>
      </Form>
    </Modal>
  )
}

const WrappedAssetsLiabilitiesLinksForm = Form.create({
  name: 'AssetsLiabilitiesLinksModal'
})(AssetsLiabilitiesLinksModal)
export default WrappedAssetsLiabilitiesLinksForm
