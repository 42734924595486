import React, { Fragment, useState } from 'react'
import JSZip from 'jszip'
import { Modal, Form, Input, message } from 'antd'
import { downloadData, getMemberDeputies } from '../../share/helpers'
import { useTranslation, Trans } from 'react-i18next'
import FormItem from '../override/FormItem'
import Button from '../override/Button'
import { onError } from '../../lib/sentry'
import { s3Get } from '../../lib/awsSDK'
import { decryptFilePromise } from '../../lib/crypto'
import api from '../../lib/api'
import { destroyAllDbs } from '../../lib/pouchDb'
import { AES, enc } from 'crypto-js'
import { useContext } from 'react'
import VaultContext from '../../contexts/VaultContext'
import { revokeDeputy } from '../../features/deputies/deputiesSlice'
import { useDispatch, useSelector } from 'react-redux'

const PasswordDownloadModal = props => {
  const {
    visible,
    setVisible,
    userId,
    activeAssetsLiabilities,
    activeContacts,
    activeFolders,
    masterKey,
    fullName,
    filteredActiveFiles,
    currentZipSize,
    user,
    customer,
    isDeleteUser,
    form,
    membersCode,
    mainUserId,
    members,
    email,
    professionalDeputies,
    deputies,
    appointedProfessionalDeputyId
  } = props
  const { t, i18n } = useTranslation()
  const [isSaving, setIsSaving] = useState(false)
  const externalUser = localStorage.getItem('External_User')
  const { privateFolderKey } = useContext(VaultContext)
  const dispatch = useDispatch()

  const { memberCode } = useSelector(state => state.user).user

  const { getFieldDecorator } = form

  let zip = new JSZip()
  let failedFiles = []
  const privateFolder = activeFolders.find(folder => folder.isPrivate)

  const getFileAndDecrypt = async (file, userId, masterKey) => {
    try {
      const resBody = await s3Get(
        userId,
        file.fileId,
        { sub: file.sub },
        { responseType: 'blob' }
      )
      return await decryptFilePromise(resBody, masterKey)
    } catch (err) {
      onError(err)
      failedFiles.push(file)
    }
  }

  const handleDeleteAccount = async () => {
    //remove member from group when deleting a member's account
    if (mainUserId) {
      if (deputies?.length) {
        const allDeputies = await getMemberDeputies(
          deputies,
          professionalDeputies,
          appointedProfessionalDeputyId
        )

        if (allDeputies?.length) {
          await Promise.all(
            allDeputies.map(deputy =>
              dispatch(revokeDeputy(user.username, deputy, fullName, masterKey))
            )
          )
        }
      }

      await api.removeMember(
        mainUserId,
        JSON.stringify({
          email,
          memberId: user.username,
          membersCode: membersCode || memberCode
        })
      )
    }

    // delete Group when deleting main user's account
    if (members?.length) {
      for (const member of members) {
        //revoke deputies
        let memberUserDeputies = []
        let pendingJoinGroup = false

        const res = await api.getUsersByEmails(member.email)
        if (res.data?.length) {
          memberUserDeputies = res.data[0].deputies
          pendingJoinGroup = res.data[0].pendingJoinGroup
        }

        if (memberUserDeputies?.length && !pendingJoinGroup) {
          const allDeputies = await getMemberDeputies(
            memberUserDeputies,
            professionalDeputies,
            member.professionalDeputyId
          )

          if (allDeputies?.length) {
            await Promise.all(
              allDeputies.map(deputy =>
                dispatch(
                  revokeDeputy(
                    member.userId,
                    deputy,
                    fullName,
                    member.professionalDeputyId ? null : masterKey
                  )
                )
              )
            )
          }
        }

        //remove member
        await api.removeMember(
          user.username,
          JSON.stringify({
            email: member.email,
            memberId: member.userId,
            isDeleteMainUser: true,
            membersCode
          })
        )
      }
    }

    const deletionTasks = [
      api.deleteAccount(user.username),
      api.deleteAccessAttempts(user.username)
    ]
    if (customer.id) deletionTasks.push(api.deleteCustomer(customer.id))

    await Promise.all(deletionTasks)

    // delete user account
    if (externalUser) {
      await api.deleteExternalUser(user.username)
      user.signOut()
      destroyAllDbs()
      localStorage.clear()
      window.location.href = `/goodbye?lang=${i18n.language}`
    } else {
      user.deleteUser(async (err, res) => {
        if (err) {
          throw Error(err)
        } else {
          message.info(t('SUCCESSFULLY_DELETED'))
          user.signOut()

          // delete all Pouch Dbs
          destroyAllDbs()
          localStorage.clear()

          window.location.href = `/goodbye?lang=${i18n.language}`
        }
      })
    }
  }

  const handleSave = () => {
    form.validateFields(async (err, values) => {
      if (err) {
        return
      }
      try {
        setIsSaving(true)
        if (
          values.password ===
          AES.decrypt(privateFolder.password, privateFolderKey).toString(
            enc.Latin1
          )
        ) {
          await downloadData(
            userId,
            masterKey,
            fullName,
            activeContacts,
            activeAssetsLiabilities,
            filteredActiveFiles,
            currentZipSize,
            zip,
            getFileAndDecrypt,
            failedFiles
          )
          if (isDeleteUser) {
            await handleDeleteAccount()
          }

          setIsSaving(false)
          form.resetFields()
          setVisible(false)
        } else {
          setIsSaving(false)
          message.error(t('INCORRECT_PASSWORD'))
        }
      } catch (error) {
        onError(error)
        setIsSaving(false)
        form.resetFields()
        setVisible(false)
      }
    })
  }

  const handleSkip = async () => {
    try {
      const downloadFiles = filteredActiveFiles.filter(
        file =>
          !(
            file.path.slice(0, privateFolder.path.length) === privateFolder.path
          )
      )
      await downloadData(
        userId,
        masterKey,
        fullName,
        activeContacts,
        activeAssetsLiabilities,
        downloadFiles,
        currentZipSize,
        zip,
        getFileAndDecrypt,
        failedFiles
      )
      if (isDeleteUser) {
        await handleDeleteAccount()
      }
      form.resetFields()
      setVisible(false)
    } catch (error) {
      onError(error)
    }
  }

  return (
    <Fragment>
      <Modal
        title={t('PASSWORD')}
        visible={visible}
        onCancel={() => {
          setVisible(false)
          form.resetFields()
        }}
        maskClosable={false}
        footer={[
          <Button key="return" onClick={handleSkip}>
            {t('SKIP_PRIVATE_FOLDER')}
          </Button>,
          <Button
            key="ok"
            type="primary"
            loading={isSaving}
            onClick={handleSave}
          >
            {t('OK')}
          </Button>
        ]}
      >
        <Form>
          <Trans i18nKey="DOWNLOAD_PRIVATE_FOLDER_MSG"></Trans>
          <FormItem label={t('PASSWORD_OF_THE_PRIVATE_FOLDER')}>
            {getFieldDecorator('password', {
              rules: [{ required: true, message: t('INPUT_PASSWORD_MSG') }]
            })(<Input.Password />)}
          </FormItem>
        </Form>
      </Modal>
    </Fragment>
  )
}

const WrappedPasswordDownloadModal = Form.create({
  name: 'PasswordDownloadModal'
})(PasswordDownloadModal)

export default WrappedPasswordDownloadModal
