import React, { useContext, useState } from 'react'
import { Modal, Form, Select, Tooltip } from 'antd'
import FormItem from '../override/FormItem'
import Button from '../override/Button'
import { onError } from '../../lib/sentry'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import PasswordAddEdit from '../passwords/PasswordAddEdit'
import VaultContext from '../../contexts/VaultContext'
import { ACCESS_LEVEL } from '../../share/Constants'

const PasswordLinksModal = props => {
  const { Option } = Select
  const { visible, setVisible, handleLinkPasswords, linkedPasswords, form } =
    props
  const { getFieldDecorator, setFieldsValue, getFieldValue } = form
  const { isReadonly } = useContext(VaultContext)
  const [isSaving, setIsSaving] = useState(false)
  const [passwordAddEditVisible, setPasswordAddEditVisible] = useState(false)
  const { t } = useTranslation()

  const { activePasswords, pendingPasswords } = useSelector(state =>
    isReadonly ? state.otherPasswords : state.passwords
  )
  const { accessLevel } = useSelector(state => state.settings)

  const data =
    accessLevel === ACCESS_LEVEL.NEED_APPROVAL
      ? [...activePasswords, ...pendingPasswords]
      : activePasswords
  const sortedData = [...data].sort((a, b) => a.title.localeCompare(b.title))

  const handleOk = () => {
    form.validateFields(async (err, values) => {
      if (err) {
        return
      }

      setIsSaving(true)
      try {
        if (values.links && values.links.length) {
          await handleLinkPasswords(values.links)
        }

        setIsSaving(false)
        form.resetFields()
        setVisible(false)
      } catch (e) {
        setIsSaving(false)
        onError(e)
      }
    })
  }

  const handleCancel = () => {
    form.resetFields()
    setVisible(false)
  }

  return (
    <Modal
      title={t('LINK_PASSWORD')}
      visible={visible}
      onCancel={handleCancel}
      onOk={handleOk}
      okText="Save"
      okButtonProps={{ loading: isSaving }}
      maskClosable={false}
    >
      <Form>
        <FormItem style={{ flexGrow: 1 }}>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '100%' }}>
              {getFieldDecorator('links')(
                <Select mode="multiple" placeholder={t('SELECT_PASSWORD')}>
                  {sortedData.map(password => (
                    <Option
                      key={password._id}
                      disabled={
                        !!linkedPasswords.find(l => l._id === password._id) ||
                        (!isReadonly && !!password.status)
                      }
                    >
                      {password.title}
                    </Option>
                  ))}
                </Select>
              )}
            </div>
            <Tooltip title={t('ADD_NEW_PASSWORD')}>
              <Button
                size="large"
                onClick={() => setPasswordAddEditVisible(true)}
                style={{ marginLeft: 8 }}
                icon="plus"
              />
            </Tooltip>
          </div>
        </FormItem>
      </Form>
      <PasswordAddEdit
        selectedPassword={{}}
        visible={passwordAddEditVisible}
        setVisible={setPasswordAddEditVisible}
        onAddComplete={passwordId =>
          setFieldsValue({
            links: [...(getFieldValue('links') || []), passwordId]
          })
        }
      />
    </Modal>
  )
}

const WrappedPasswordLinksForm = Form.create({
  name: 'PasswordLinksModal'
})(PasswordLinksModal)

export default WrappedPasswordLinksForm
