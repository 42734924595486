import React, { useState, useContext, useEffect } from 'react'
import { withRouter, useLocation } from 'react-router-dom'
import {
  Layout,
  Result,
  Select,
  Menu,
  Dropdown,
  Icon,
  PageHeader,
  Collapse,
  message,
  Badge,
  Tabs
} from 'antd'
import { exportContactToExcel, exportToPDF } from '../../share/helpers'
import { CONTACT_TYPES, TAB_KEY, ACCESS_LEVEL } from '../../share/Constants'
import VaultContext from '../../contexts/VaultContext'
import { useSelector } from 'react-redux'
import Button from '../override/Button'
import { P, H4, H5 } from '../override/Typography'
import CustomIcon from '../override/Icon'
import { ThemeContext } from 'styled-components'
import ContactDetails from './ContactDetails'
import { groupBy, uniq } from 'lodash'
import { contactCommonColumns, contactColumnsByType } from './contactHelpers'
import ImportExcelModal from './ImportExcelModal'
import ImportVCardModal from './ImportVCardModal'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import ContactsList from './ContactsList'
import Flex from '../override/Flex'
import logo from '../../assets/dark.png'
import { onError } from '../../lib/sentry'
import { useTranslation } from 'react-i18next'
import AuthContext from '../../contexts/AuthContext'

const { Content, Sider } = Layout
const { Option } = Select
const { Panel } = Collapse
const { TabPane } = Tabs

const Contacts = ({ history }) => {
  const { isReadonly, fullName, userId } = useContext(VaultContext)
  const { isProfessionalDeputy, isDelegateByPD } = useContext(AuthContext)
  const theme = useContext(ThemeContext)
  const colors = [
    theme.secondaryYellow,
    theme.secondaryRed,
    theme.secondaryBlue,
    theme.secondaryOrange,
    theme.secondaryLightGreen,
    theme.secondaryCyan,
    theme.secondaryGreenMedium,
    theme.secondaryPurple
  ]
  const { t } = useTranslation()
  const query = new URLSearchParams(useLocation().search)
  const tabKey = query.get('activeKey')

  const {
    isLoading,
    activeContacts,
    pendingContacts,
    rejectedContacts
  } = useSelector(state => (isReadonly ? state.otherContacts : state.contacts))

  const { accessLevel } = useSelector(state => state.settings)

  const [filteredType, setFilteredType] = useState('')
  const [filteredTag, setFilteredTag] = useState('')
  const [importExcelModalVisible, setImportExcelModalVisible] = useState(false)
  const [importVCardModalVisible, setImportVCardModalVisible] = useState(false)
  const [contactsListCollapsed, setContactsListCollapsed] = useState(false)
  const [activeKey, setActiveKey] = useState('')

  useEffect(() => {
    setActiveKey(tabKey || TAB_KEY.ACTIVED)
  }, [tabKey])

  const decoratedContacts = (activeKey === TAB_KEY.PENDING
    ? pendingContacts
    : activeKey === TAB_KEY.REJECTED
    ? rejectedContacts
    : activeContacts
  )
    .filter(
      ac =>
        (!filteredType || ac.type === filteredType) &&
        (!filteredTag || ac.tags?.includes(filteredTag))
    )
    .map((c, index) => ({
      ...c,
      shortName:
        c.type === CONTACT_TYPES.INDIVIDUAL
          ? c.givenName?.charAt(0).toUpperCase() +
            c.surName?.charAt(0).toUpperCase()
          : c.displayName?.charAt(0).toUpperCase(),
      background: colors[index % colors.length]
    }))

  const [contactId, setContactId] = useState('')
  const [searchValue, setSearchValue] = useState([])
  const [filteredContacts, setFilteredContacts] = useState([])
  const [isGroupView, setIsGroupView] = useState(false)

  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))

  const allTags = uniq(
    activeContacts
      .filter(ac => !filteredType || ac.type === filteredType)
      .reduce((tags, contact) => [...tags, ...(contact.tags || [])], [])
  )

  const contactsGroupedByFirstChar = groupBy(decoratedContacts, item =>
    item.name.charAt(0).toUpperCase()
  )
  const contactsGroupsByFirstChar = Object.keys(contactsGroupedByFirstChar)
    .map(key => ({
      key,
      dataByKey: contactsGroupedByFirstChar[key]
    }))
    .sort((a, b) => a.key.localeCompare(b.key))

  const handleChange = value => {
    setSearchValue(value)
    setFilteredContacts(
      decoratedContacts.filter(
        c =>
          value.includes(c._id) &&
          (activeKey === TAB_KEY.PENDING
            ? c.status === 'Draft'
            : activeKey === TAB_KEY.REJECTED
            ? c.status === 'Rejected'
            : !c.status)
      )
    )

    if (value.length > 1) {
      setIsGroupView(true)
      setContactId('')
    } else {
      setIsGroupView(false)
    }
    if (!value.includes(contactId)) {
      setContactId('')
    }
  }

  useEffect(() => {
    if (contactId) {
      setIsGroupView(false)
    }
  }, [contactId])

  useEffect(() => {
    if (isMdUp) return

    setContactsListCollapsed(contactId || isGroupView)
  }, [isMdUp, contactId, isGroupView])

  const importMenu = (
    <Menu>
      <Menu.Item onClick={() => setImportExcelModalVisible(true)}>
        {t('FROM_EXCEL')}
      </Menu.Item>
      <Menu.Item onClick={() => setImportVCardModalVisible(true)}>
        {t('FROM_VCARD')}
      </Menu.Item>
    </Menu>
  )

  const exportMenu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          try {
            exportContactToExcel(activeContacts, fullName)
          } catch (err) {
            onError(err)
            message.error(t('FAILED_TO_EXPORT_TO_EXCEL'))
          }
        }}
      >
        {t('TO_EXCEL')}
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          try {
            const filteredContacts = activeContacts.filter(
              ac => !filteredTag || ac.tags?.includes(filteredTag)
            )

            if (!filteredType || filteredType === CONTACT_TYPES.INDIVIDUAL) {
              exportToPDF(
                [
                  ...contactColumnsByType()[CONTACT_TYPES.INDIVIDUAL],
                  ...contactCommonColumns()
                ].filter(c => c.key !== 'type'),
                filteredContacts
                  .filter(ac => ac.type === CONTACT_TYPES.INDIVIDUAL)
                  .sort((a, b) => a.name.localeCompare(b.name)),
                'Individual Contacts',
                logo,
                fullName,
                true
              )
            }

            if (!filteredType || filteredType === CONTACT_TYPES.ORGANISATION) {
              exportToPDF(
                [
                  ...contactColumnsByType()[CONTACT_TYPES.ORGANISATION],
                  ...contactCommonColumns()
                ].filter(c => c.key !== 'type'),
                filteredContacts
                  .filter(ac => ac.type === CONTACT_TYPES.ORGANISATION)
                  .sort((a, b) => a.name.localeCompare(b.name)),
                'Organisation Contacts',
                logo,
                fullName,
                true
              )
            }
          } catch (err) {
            onError(err)
            message.error(t('FAILED_TO_EXPORT_TO_PDF'))
          }
        }}
      >
        {t('TO_PDF')}
      </Menu.Item>
    </Menu>
  )

  return (
    <Layout
      style={{
        height: '100%',
        padding: '0 20px 20px',
        backgroundColor: '#fff'
      }}
    >
      <PageHeader
        title={
          <>
            <H4 display="inline-block">{t('CONTACTS')}</H4>
            <H5
              display="inline-block"
              style={{ marginLeft: 10, color: theme.dark2 }}
            >
              {activeKey === TAB_KEY.PENDING
                ? pendingContacts.length
                : activeKey === TAB_KEY.REJECTED
                ? rejectedContacts.length
                : activeContacts.length}
            </H5>
          </>
        }
        style={{
          boxShadow: theme.underlineShadow
        }}
        className="contacts-header"
        extra={
          <>
            {activeKey === TAB_KEY.ACTIVED && (
              <>
                <Select
                  allowClear
                  placeholder={t('TYPE')}
                  onChange={setFilteredType}
                >
                  {Object.values(CONTACT_TYPES).map(type => (
                    <Option key={type} value={type}>
                      {type}
                    </Option>
                  ))}
                </Select>
                <Select
                  showSearch
                  allowClear
                  placeholder={t('TAG')}
                  onChange={setFilteredTag}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toString()
                      .toLowerCase()
                      .includes(input.trim().toLowerCase())
                  }
                >
                  {allTags.map(tag => (
                    <Option key={tag} value={tag}>
                      {tag}
                    </Option>
                  ))}
                </Select>
                <Select
                  mode="multiple"
                  value={searchValue}
                  placeholder={t('SEARCH_CONTACT')}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toString()
                      .toLowerCase()
                      .includes(input.trim().toLowerCase())
                  }
                  onChange={handleChange}
                  className="contacts-search"
                >
                  {activeContacts
                    .filter(
                      ac =>
                        (!filteredType || ac.type === filteredType) &&
                        (!filteredTag || ac.tags?.includes(filteredTag))
                    )
                    .map(c => (
                      <Option key={c._id}>{c.name}</Option>
                    ))}
                </Select>
              </>
            )}

            {(!isReadonly ||
              ((isProfessionalDeputy || (isDelegateByPD && isReadonly)) &&
                accessLevel &&
                accessLevel !== ACCESS_LEVEL.DENY)) && (
              <>
                <Button
                  icon="plus"
                  onClick={() => history.push(`/contacts/add/${userId}`)}
                  className="tour-create-contact-button"
                >
                  {t('CREATE_NEW')}
                </Button>
                <Dropdown overlay={importMenu} trigger={['click', 'hover']}>
                  <Button>
                    {t('IMPORT')} <Icon type="down" />
                  </Button>
                </Dropdown>
              </>
            )}
            <Dropdown overlay={exportMenu} trigger={['click', 'hover']}>
              <Button>
                {t('EXPORT')} <Icon type="down" />
              </Button>
            </Dropdown>
          </>
        }
      />

      <Layout style={{ height: '100%' }}>
        {isMdUp && (
          <Sider
            width={350}
            theme="light"
            style={{
              background: '#fff',
              borderRight: '1px solid #E6E7EC'
            }}
          >
            {accessLevel === ACCESS_LEVEL.NEED_APPROVAL ? (
              <Tabs
                defaultActiveKey={activeKey}
                onChange={key => {
                  setActiveKey(key)
                  setContactId()
                }}
              >
                <TabPane tab={t('ACTIVE')} key={TAB_KEY.ACTIVED}>
                  <ContactsList
                    isLoading={isLoading}
                    activeContacts={activeContacts}
                    filteredContacts={filteredContacts}
                    isGroupView={isGroupView}
                    setContactId={setContactId}
                    setIsGroupView={setIsGroupView}
                    contactId={contactId}
                    contactsGroupsByFirstChar={contactsGroupsByFirstChar}
                    history={history}
                  />
                </TabPane>

                <TabPane
                  tab={
                    <>
                      <Badge
                        style={{ marginRight: -15 }}
                        count={pendingContacts?.length}
                      >
                        {t('PENDING')}
                      </Badge>
                    </>
                  }
                  key="Pending"
                >
                  <ContactsList
                    isLoading={isLoading}
                    activeContacts={pendingContacts}
                    filteredContacts={filteredContacts}
                    isGroupView={isGroupView}
                    setContactId={setContactId}
                    setIsGroupView={setIsGroupView}
                    contactId={contactId}
                    contactsGroupsByFirstChar={contactsGroupsByFirstChar}
                    history={history}
                  />
                </TabPane>

                {(isProfessionalDeputy || (isDelegateByPD && isReadonly)) && (
                  <TabPane
                    tab={
                      <>
                        <Badge
                          style={{ marginRight: -15 }}
                          count={rejectedContacts?.length}
                        >
                          {t('REJECTED')}
                        </Badge>
                      </>
                    }
                    key={TAB_KEY.REJECTED}
                  >
                    <ContactsList
                      isLoading={isLoading}
                      activeContacts={rejectedContacts}
                      filteredContacts={filteredContacts}
                      isGroupView={isGroupView}
                      setContactId={setContactId}
                      setIsGroupView={setIsGroupView}
                      contactId={contactId}
                      contactsGroupsByFirstChar={contactsGroupsByFirstChar}
                      history={history}
                    />
                  </TabPane>
                )}
              </Tabs>
            ) : (
              <ContactsList
                isLoading={isLoading}
                activeContacts={activeContacts}
                filteredContacts={filteredContacts}
                isGroupView={isGroupView}
                setContactId={setContactId}
                setIsGroupView={setIsGroupView}
                contactId={contactId}
                contactsGroupsByFirstChar={contactsGroupsByFirstChar}
                history={history}
              />
            )}
          </Sider>
        )}
        <Content className="contacts-content">
          {!isMdUp && (
            <Collapse
              activeKey={contactsListCollapsed ? [] : [0]}
              onChange={value => setContactsListCollapsed(!value.length)}
            >
              <Panel header="Contacts">
                <ContactsList
                  isLoading={isLoading}
                  activeContacts={
                    activeKey === TAB_KEY.PENDING
                      ? pendingContacts
                      : activeKey === TAB_KEY.REJECTED
                      ? rejectedContacts
                      : activeContacts
                  }
                  filteredContacts={filteredContacts}
                  isGroupView={isGroupView}
                  setContactId={setContactId}
                  setIsGroupView={setIsGroupView}
                  contactId={contactId}
                  contactsGroupsByFirstChar={contactsGroupsByFirstChar}
                  history={history}
                />
              </Panel>
            </Collapse>
          )}
          {contactId || isGroupView ? (
            <ContactDetails
              contactId={contactId}
              contactIds={isGroupView && searchValue}
              setContactId={setContactId}
              activeKey={activeKey}
            />
          ) : (
            <Flex className="contacts-result" justifyContent="center">
              <Result
                icon={
                  <div
                    style={{
                      width: 150,
                      height: 150,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: theme.gray,
                      borderRadius: '50%',
                      margin: 'auto'
                    }}
                  >
                    <CustomIcon
                      type="contact"
                      style={{ fontSize: 64, color: 'transparent' }}
                    />
                  </div>
                }
                title={<H4>{t('NO_CONTACTS_RESULTS')}</H4>}
                subTitle={
                  <P color={theme.dark1}>
                    {isMdUp
                      ? t('SEARCH_CONTACT_FROM_LEFT_SIDEBAR')
                      : t('SEARCH_CONTACT_FROM_CONTACTS_LIST')}
                  </P>
                }
                style={{ width: 460 }}
              />
            </Flex>
          )}
        </Content>
      </Layout>

      <ImportExcelModal
        visible={importExcelModalVisible}
        setVisible={setImportExcelModalVisible}
        sourceContacts={activeContacts}
        setContactId={setContactId}
      />

      <ImportVCardModal
        visible={importVCardModalVisible}
        setVisible={setImportVCardModalVisible}
        sourceContacts={activeContacts}
        setContactId={setContactId}
      />

      {/* <ContactHistoryModal
        visible={versionsHistoryModalVisible}
        setVisible={setVersionsHistoryModalVisible}
        record={selectedRecord}
      /> */}
    </Layout>
  )
}

export default withRouter(Contacts)
