import React, { useState, useContext, useEffect } from 'react'
import FileUploadForm from './FileUploadForm'
import PouchDB from 'pouchdb'
import uuidv4 from 'uuid/v4'
import { uploadEncryptedData } from '../../lib/pouchDb'
import { Modal, Progress, message, notification } from 'antd'
import AuthContext from '../../contexts/AuthContext'
import VaultContext from '../../contexts/VaultContext'
import { useSelector } from 'react-redux'
import api from '../../lib/api'
import { encryptFilePromise } from '../../lib/crypto'
import { sum } from 'lodash'
import { getFullFilename } from '../../share/formHelpers'
import TourContext, { TOUR_STEP_INDEXES } from '../../contexts/TourContext'
import { onError } from '../../lib/sentry'
import { useTranslation } from 'react-i18next'
import {
  removeHtmlTags,
  showUpgradeSubscriptionPlanConfirm
} from './../../share/helpers'
import { ACCESS_LEVEL } from './../../share/Constants'
import { useMutation } from 'react-apollo-hooks'
import { createS3Change } from '../../graphql/mutations'
import SubscriptionModal from '../payment/SubscriptionModal'

export default function FileModal(props) {
  // TODO: add loading state for modal (when saving, and later for the details modal also when getting data)
  const {
    visible,
    setVisible,
    folderKey,
    contactDataSource,
    onUploadComplete
  } = props
  const { isProfessionalDeputy, isDelegateByPD } = useContext(AuthContext)
  const { masterKey, userId, isReadonly, limitedRecord, privateFolderKey } =
    useContext(VaultContext)
  // const userId = user.username
  const [tags, setTags] = useState([])
  const [descriptionValue, setDescriptionValue] = useState('')
  const [isSaving, setIsSaving] = useState(false)
  const [subscriptionModalVisible, setSubscriptionModalVisible] = useState()
  const { accessLevel } = useSelector(state => state.settings)
  const { usedStorage, activeFiles, activeFolders, pendingFiles } = useSelector(
    state =>
      (isProfessionalDeputy || (isDelegateByPD && isReadonly)) &&
      accessLevel === ACCESS_LEVEL.NEED_APPROVAL
        ? state.otherDocuments
        : state.documents
  )
  const { activeContacts, pendingContacts } = useSelector(state =>
    (isProfessionalDeputy || (isDelegateByPD && isReadonly)) &&
    accessLevel === ACCESS_LEVEL.NEED_APPROVAL
      ? state.otherContacts
      : state.contacts
  )
  const { limit } = useSelector(state => state.customer)
  const { activeAssetsLiabilities, pendingAssetsLiabilities } = useSelector(
    state =>
      (isProfessionalDeputy || (isDelegateByPD && isReadonly)) &&
      accessLevel === ACCESS_LEVEL.NEED_APPROVAL
        ? state.otherAssetsLiabilities
        : state.assetsLiabilities
  )
  const { activePasswords, pendingPasswords } = useSelector(state =>
    isReadonly ? state.otherPasswords : state.passwords
  )
  const { eventsFromPouchDB: events, pendingEventsFromPouchDB: pendingEvents } =
    useSelector(state => (isReadonly ? state.otherEvents : state.events))
  const { allowedStorage } = useSelector(state => state.customer)
  const { tourStepIndex, nextTourStep, setTourRun, tourRun } =
    useContext(TourContext)
  const { t } = useTranslation()

  const [addS3Change] = useMutation(createS3Change)
  let formRef

  // TODO: have a db service to implement the following:
  // - Instantiate the db when the app start (after user sign in)
  // - Destroy the DB when the app is terminated (After session end: user sign out or session time out) to avoid conflict when inserting new record
  // - Currently need to instantiate a new one whenever this Modal is rendered, otherwise then upon uploading a file & saving file record to Pouch,
  // it will be encrypted multiple times (equals the number of times handleSave is called)
  // This may cause memory leak? So probably should find another approach, or at least should clean it up when the modal instance is destroyed.
  const db =
    (isProfessionalDeputy || (isDelegateByPD && isReadonly)) &&
    accessLevel === ACCESS_LEVEL.NEED_APPROVAL
      ? new PouchDB(`${userId}_pendingDocuments`)
      : new PouchDB(`${userId}_documents`)
  const contactDb = new PouchDB(`${userId}_contacts`)
  const pendingContactDb = new PouchDB(`${userId}_pendingContacts`)
  const assetsLiabilitiesDb = new PouchDB(`${userId}_assetsLiabilities`)
  const eventsDb = new PouchDB(`${userId}_events`)
  const pendingEventsDb = new PouchDB(`${userId}_pendingEvents`)
  const passwordsDb = new PouchDB(`${userId}_passwords`)
  const pendingPasswordsDb = new PouchDB(`${userId}_pendingPasswords`)
  const pendingAssetsLiabilitiesDb = new PouchDB(
    `${userId}_pendingAssetsLiabilities`
  )

  useEffect(() => {
    if (
      visible &&
      tourRun &&
      tourStepIndex === TOUR_STEP_INDEXES.CREATE_FILE_BUTTON
    ) {
      setTimeout(() => {
        nextTourStep()
      }, 300)
    }
  }, [visible, tourRun, tourStepIndex, nextTourStep])



  const handleSave = () => {
    const { form } = formRef.props
    let uploadedFileIds = []

    form.validateFields(async (err, values) => {
      if (err) {
        return
      }

      removeHtmlTags(values)
      if (tourStepIndex === TOUR_STEP_INDEXES.SAVE_FILE_BUTTON) {
        setTourRun(false)
      }

      const files = values.file
      const totalFilesSize = sum(files?.map(f => f.size))

      if (
        (!allowedStorage || usedStorage + totalFilesSize > allowedStorage) &&
        !tourRun
      ) {
        Modal.warn({
          title: t('LOW_STORAGE_SPACE'),
          content: t('LOW_STORAGE_SPACE_CONTENT')
        })
        return
      }

      if (limitedRecord + files.length > limit) {
        showUpgradeSubscriptionPlanConfirm(setSubscriptionModalVisible)
        return
      }

      try {
        await db.crypto(masterKey)

        setIsSaving(true)
        let encryptedFiles = []

        for (let i = 0; i < files.length; i++) {
          files[i] = { ...files[i], status: 'uploading' }
          form.setFieldsValue({ file: [...files] })

          const encryptedFile = await encryptFilePromise(
            files[i].originFileObj,
            masterKey,
            percent => {
              files[i].percent = percent
              form.setFieldsValue({ file: [...files] })
            }
          )

          files[i] = { ...files[i], status: 'done' }
          form.setFieldsValue({ file: [...files] })
          encryptedFiles.push({ file: files[i], encryptedFile })
        }

        // uploading large file can take long time,
        // so as soon as the encryption is complete and the file is ready to be uploaded,
        // just clear the saving state, reset and close the modal,
        // Showing only the progress notification is enough
        setIsSaving(false)
        form.resetFields()
        setVisible(false)

        await Promise.all(
          encryptedFiles.map(async ef => {
            const id = uuidv4() // PouchDB record id, is not encrypted by crypto-pouch
            const fileId = uuidv4() // used as S3 file path, is encrypted
            const sub = uuidv4() // used for matching with S3 file metadata, is encrypted

            const uploadConfig = {
              onUploadProgress: ({ loaded, total }) => {
                const percent = Math.floor((loaded / total) * 100)
                notification.open({
                  key: id,
                  message: (
                    <>
                      <span>{values.fileName || ef.file.name}</span>
                      <Progress type="circle" percent={percent} width={24} />
                    </>
                  ),
                  duration: percent === 100 ? 3 : null,
                  className: 'upload-progress-noti'
                })
              }
            }

            const urlRes = await api.getSignedUrl(userId, fileId, 'putObject', {
              sub
            })
            if (urlRes.data.message) throw Error(urlRes.data.message)

            await api.uploadToS3Url(urlRes.data, ef.encryptedFile, uploadConfig)
            await db.put({
              _id: id,
              fileId,
              file: [{ ...ef.file }],
              uploadTime: new Date(),
              fileName:
                values.file.length > 1
                  ? ef.file.name
                  : getFullFilename(values.fileName, values.extension),
              sub,
              path: values.path || folderKey || '',
              tags,
              description: values.description,
              descriptionWithMarkup: descriptionValue,
              contacts: values.contacts,
              events: values.events,
              assetsLiabilities: values.assetsLiabilities,
              passwords: values.passwords,
              status:
                (isProfessionalDeputy || (isDelegateByPD && isReadonly)) &&
                accessLevel === ACCESS_LEVEL.NEED_APPROVAL
                  ? 'Draft'
                  : undefined
            })
            uploadedFileIds.push(id)
          })
        )

        if (onUploadComplete) await onUploadComplete(uploadedFileIds)

        await uploadEncryptedData(
          db,
          userId,
          (isProfessionalDeputy || (isDelegateByPD && isReadonly)) &&
            accessLevel === ACCESS_LEVEL.NEED_APPROVAL
            ? 'pendingDocuments'
            : 'documents'
        )

        //update contacts
        const addedContacts = values.contacts

        const updatedActivedContacts = addedContacts.filter(cid =>
          activeContacts.map(ac => ac._id).includes(cid)
        )

        const updatedPendingContacts = addedContacts.filter(cid =>
          pendingContacts.map(pc => pc._id).includes(cid)
        )

        if (updatedActivedContacts?.length) {
          await updateLinkedItems(
            contactDb,
            'contacts',
            updatedActivedContacts,
            uploadedFileIds
          )
        }

        if (updatedPendingContacts?.length) {
          await updateLinkedItems(
            pendingContactDb,
            'pendingContacts',
            updatedPendingContacts,
            uploadedFileIds
          )
        }

        //update events
        const pendingEventIds =
          values.events.filter(id =>
            pendingEvents.map(c => c._id).includes(id)
          ) || []
        const activeEventIds =
          values.events.filter(id => events.map(pa => pa._id).includes(id)) ||
          []

        if (activeEventIds?.length) {
          updateLinkedItems(eventsDb, 'events', activeEventIds, uploadedFileIds)
        }

        if (pendingEventIds?.length) {
          updateLinkedItems(
            pendingEventsDb,
            'pendingEvents',
            pendingEventIds,
            uploadedFileIds
          )
        }

        //update passwords
        const pendingPasswordIds =
          values.passwords.filter(id =>
            pendingPasswords.map(c => c._id).includes(id)
          ) || []
        const activePasswordIds =
          values.passwords.filter(id =>
            activePasswords.map(pa => pa._id).includes(id)
          ) || []

        if (pendingPasswordIds?.length) {
          updateLinkedItems(
            pendingPasswordsDb,
            'pendingPasswords',
            pendingPasswordIds,
            uploadedFileIds
          )
        }

        if (activePasswordIds?.length) {
          updateLinkedItems(
            passwordsDb,
            'passwords',
            activePasswordIds,
            uploadedFileIds
          )
        }

        //update assets/liabilities
        const addedAssetsLiabilities = values.assetsLiabilities
        const updatedActivedAssetsLiabilities = addedAssetsLiabilities.filter(
          cid => activeAssetsLiabilities.map(ac => ac._id).includes(cid)
        )

        const updatedPendingAssetsLiabilities = addedAssetsLiabilities.filter(
          cid => pendingAssetsLiabilities.map(pc => pc._id).includes(cid)
        )

        if (updatedActivedAssetsLiabilities?.length) {
          updateLinkedItems(
            assetsLiabilitiesDb,
            'assetsLiabilities',
            updatedActivedAssetsLiabilities,
            uploadedFileIds
          )
        }

        if (updatedPendingAssetsLiabilities?.length) {
          updateLinkedItems(
            pendingAssetsLiabilitiesDb,
            'pendingAssetsLiabilities',
            updatedPendingAssetsLiabilities,
            uploadedFileIds
          )
        }

        if (tourStepIndex === TOUR_STEP_INDEXES.SAVE_FILE_BUTTON) {
          nextTourStep()
          setTourRun(true)
        }

        if (
          (isProfessionalDeputy || (isDelegateByPD && isReadonly)) &&
          accessLevel === ACCESS_LEVEL.NEED_APPROVAL
        ) {
          await api.sendAddRecordNotification(
            JSON.stringify({
              userId,
              recordType: 'document'
            })
          )
          // dispatch(fetchOtherPendingDocuments(userId, masterKey))
        } else {
          // dispatch(fetchOtherDocuments(userId, masterKey))
        }

        message.success(t('SUCCESSFULLY_UPLOADED_FILE'))
        localStorage.setItem('NotReload', true)
        addS3Change({
          variables: {
            message:
              'assetsLiabilities, pendingAssetsLiabilities, contacts, pendingContacts, documents, pendingDocuments, events, pendingEvents, passwords, pendingPasswords',
            userId: userId
          }
        })
        setTags([])
      } catch (err) {
        onError(err)
        message.error(t('FAILED_TO_UPLOAD_FILE'))
        setIsSaving(false)
      }
    })
  }

  const updateLinkedItems = async (db, dbName, linkedItems, fileIds) => {
    await db.crypto(masterKey)
    const docs = await db.allDocs({
      keys: linkedItems,
      include_docs: true
    })

    const updatedDocs = docs.rows
      .filter(row => row.doc)
      .map(row => {
        const { doc } = row
        if (linkedItems.includes(doc._id)) {
          const newDocuments = doc.documents
            ? [...doc.documents, ...fileIds]
            : fileIds || []
          return { ...doc, documents: newDocuments }
        } else {
          return { ...doc }
        }
      })

    await db.bulkDocs(updatedDocs)
    await uploadEncryptedData(db, userId, dbName)
  }

  const saveFormRef = fr => {
    formRef = fr
  }

  const handleCancel = () => {
    const { form } = formRef.props
    setTags([])
    setDescriptionValue('')
    form.resetFields()
    setVisible(false)
  }

  return (
    <>
      <Modal
        visible={visible}
        title={t('UPLOAD_FILE')}
        okText={t('SAVE')}
        cancelText={t('CANCEL')}
        onCancel={handleCancel}
        onOk={handleSave}
        okButtonProps={{
          loading: isSaving,
          className: 'tour-save-file-button'
        }}
        maskClosable={false}
      >
        <FileUploadForm
          isReadonly={isReadonly}
          wrappedComponentRef={saveFormRef}
          tags={tags}
          setTags={setTags}
          contactDataSource={contactDataSource}
          setDescriptionValue={setDescriptionValue}
          descriptionValue={descriptionValue}
          folderKey={folderKey}
          activeFiles={activeFiles}
          activeFolders={activeFolders}
          pendingFiles={pendingFiles}
          privateFolderKey={privateFolderKey}
          isShowPending={
            (isProfessionalDeputy || (isDelegateByPD && isReadonly)) &&
            accessLevel === ACCESS_LEVEL.NEED_APPROVAL
          }
        />
      </Modal>
      <SubscriptionModal
        visible={subscriptionModalVisible}
        setVisible={setSubscriptionModalVisible}
      />
    </>
  )
}
