import React, { forwardRef } from 'react'
import { Input } from 'antd'
import { sanitizeValue } from '../../share/helpers'

const TextInput = forwardRef((props, ref) => {
  const { name, setFieldsValue } = props
  return (
    <Input
      ref={ref}
      onBlur={e =>
        setFieldsValue({
          [`${name}`]: sanitizeValue(e.target.value).trim()
        })
      }
      {...props}
    />
  )
})

TextInput.defaultProps = {
  minLength: 0,
  maxLength: 100,
  setFieldsValue: () => {},
  name: '',
  size: 'default'
}
export default TextInput
