import { createSlice } from '@reduxjs/toolkit'
import { getRecords } from '../../lib/pouchDb'
import { onError } from '../../lib/sentry'

const initialState = {
  contacts: [],
  deletedContacts: [],
  activeContacts: [],
  pendingContacts: [],
  rejectedContacts: [],
  contactTags: [],
  pendingContactTags: [],
  pendingLoading: false,
  pendingError: null,
  isLoading: false,
  error: null
}

const contacts = createSlice({
  name: 'passwords',
  initialState,
  reducers: {
    getContactsStart(state) {
      state.isLoading = true
      state.error = null
    },
    getContactsSuccess(state, action) {
      const { contacts } = action.payload
      state.contacts = contacts
      state.deletedContacts = contacts.filter(contact => contact.deleted)
      state.activeContacts = contacts.filter(contact => !contact.deleted)
      state.contactTags = contacts
        .filter(contact => !!contact.tags?.length)
        .map(contact => contact.tags)
      state.isLoading = false
      state.error = null
    },
    getContactsFailure(state, action) {
      state.isLoading = false
      state.error = action.payload
    },
    getPendingContactsStart(state) {
      state.pendingLoading = true
      state.pendingError = null
    },
    getPendingContactsSuccess(state, action) {
      const { contacts } = action.payload
      state.pendingContacts = contacts.filter(c => c.status === 'Draft')
      state.rejectedContacts = contacts.filter(c => c.status === 'Rejected')
      state.pendingContactTags = contacts
        .filter(contact => contact.status === 'Draft' && !!contact.tags?.length)
        .map(contact => contact.tags)
      state.pendingLoading = false
      state.pendingError = null
    },
    getPendingContactsFailure(state, action) {
      state.pendingLoading = false
      state.pendingError = action.payload
    }
  }
})

export const {
  getContactsStart,
  getContactsSuccess,
  getContactsFailure,
  getPendingContactsStart,
  getPendingContactsSuccess,
  getPendingContactsFailure
} = contacts.actions

export default contacts.reducer

export const fetchContacts = (userId, masterKey) => async dispatch => {
  try {
    dispatch(getContactsStart())
    const contacts = await getRecords(userId, 'contacts', masterKey)
    dispatch(getContactsSuccess({ contacts }))
  } catch (err) {
    onError(err)
    dispatch(getContactsFailure(err.toString()))
  }
}

export const fetchPendingContacts = (userId, masterKey) => async dispatch => {
  try {
    dispatch(getPendingContactsStart())
    const contacts = await getRecords(userId, 'pendingContacts', masterKey)
    dispatch(getPendingContactsSuccess({ contacts }))
  } catch (err) {
    onError(err)
    dispatch(getPendingContactsFailure(err.toString()))
  }
}
