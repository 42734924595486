import { createSlice } from '@reduxjs/toolkit'
import api from '../../lib/api'
import sss from 'shamirs-secret-sharing'
import NodeRSA from 'node-rsa'
import { message } from 'antd'
import { fetchCustomer } from '../payment/customerSlice'
import { onError } from '../../lib/sentry'
import i18next from 'i18next'
import PouchDB from 'pouchdb'
import {
  removeAllDocs,
  getRecords,
  uploadEncryptedData
} from '../../lib/pouchDb'
import { fetchPendingAssetsLiabilities } from '../assets-liabilities/assetsLiabilitiesSlice'
import { fetchPendingContacts } from '../contacts/contactsSlice'
import { fetchPendingDocuments } from './../documents/documentsSlice'

const initialState = {
  deputies: [],
  error: null,
  isLoading: false,
  distributeError: null
}

const deputies = createSlice({
  name: 'deputies',
  initialState,
  reducers: {
    getDeputiesStart(state) {
      state.isLoading = true
      state.error = null
    },
    getDeputiesSuccess(state, action) {
      state.deputies = action.payload.deputies
      state.isLoading = false
      state.error = null
    },
    getDeputiesFailure(state, action) {
      state.isLoading = false
      state.error = action.payload
    },
    distributeShareKeysStart(state) {
      state.isLoading = true
      state.distributeError = null
    },
    distributeShareKeysSuccess(state) {
      state.isLoading = false
      state.distributeError = null
    },
    distributeShareKeysFailure(state, action) {
      state.isLoading = false
      state.distributeError = action.payload
    },
    deputyActionStart(state) {
      state.isLoading = true
    },
    deputyActionSuccess(state, action) {
      state.isLoading = false
      message.success(action.payload)
    },
    deputyActionFailure(state, action) {
      state.isLoading = false
      message.error(action.payload)
    }
  }
})

export const {
  getDeputiesStart,
  getDeputiesSuccess,
  getDeputiesFailure,
  distributeShareKeysStart,
  distributeShareKeysSuccess,
  distributeShareKeysFailure,
  deputyActionStart,
  deputyActionSuccess,
  deputyActionFailure
} = deputies.actions

export default deputies.reducer

export const fetchDeputies = userId => async dispatch => {
  try {
    dispatch(getDeputiesStart())
    const response = await api.getDeputies(userId)
    const { deputies } = response.data
    dispatch(getDeputiesSuccess({ deputies }))
  } catch (err) {
    onError(err)
    dispatch(getDeputiesFailure(i18next.t('FAILED_TO_LOAD_DEPUTIES')))
  }
}

export const distributeShareKeys =
  (userId, masterKey, sharesThreshold = 2, deputiesInfo = []) =>
  async dispatch => {
    try {
      dispatch(distributeShareKeysStart())
      const getKeysResponse = await api.getPublicKeys(userId)
      const publicKeyMaps = [
        ...deputiesInfo,
        ...(getKeysResponse?.data?.length ? getKeysResponse.data : [])
      ]

      if (publicKeyMaps.length < 2) {
        return
      }
      const shares = sss.split(masterKey, {
        shares: publicKeyMaps.length,
        threshold: sharesThreshold
      })
      const shareMaps = publicKeyMaps.map((map, index) => {
        const key = new NodeRSA()
        key.importKey(map.publicKey, 'public')
        const encryptedShare = key.encrypt(
          shares[index].toString('hex'),
          'base64'
        )
        return { ...map, share: encryptedShare }
      })

      await api.distributeShares(userId, JSON.stringify({ shareMaps }))

      // whenever share keys are distributed, need to clear the current unlockedShares from primaryUser
      await api.removeUnlockedShares(userId)

      dispatch(distributeShareKeysSuccess())
      dispatch(fetchDeputies(userId))
    } catch (err) {
      onError(err)
      dispatch(
        distributeShareKeysFailure(i18next.t('FAILED_TO_LOAD_DISTRIBUTE_KEYS'))
      )
    }
  }

const unlinkAllItems = async (dbName, masterKey, userId, props, removedIds) => {
  const db = new PouchDB(`${userId}_${dbName}`)
  db.crypto(masterKey)
  const allDocs = await db.allDocs({ include_docs: true })
  const docsToUpdate = allDocs.rows?.filter(row => row.doc[props]?.length)
  if (docsToUpdate?.length) {
    const updatedDocs = docsToUpdate.map(row => {
      const { doc } = row
      return {
        ...doc,
        [props]: doc[props]?.filter(id => !removedIds.includes(id))
      }
    })

    await db.bulkDocs(updatedDocs)
    await uploadEncryptedData(db, userId, dbName)
  } else {
    db.removeCrypto()
  }
}

export const revokeDeputy =
  (userId, deputy, fullname, masterKey, isDisconnected = false) =>
  async dispatch => {
    try {
      dispatch(deputyActionStart())

      const revokeData = {
        email: deputy?.accounts ? undefined : deputy.email,
        professionalDeputyId: deputy?.accounts ? deputy.id : undefined,
        fullname,
        isDisconnected
      }

      const response = await api.revokeDeputy(
        userId,
        JSON.stringify(revokeData)
      )
      if (!response.data || !response.data.success) {
        throw response.data.message
      }
      //remove all pending db
      await Promise.all(
        [
          'pendingAssetsLiabilities',
          'pendingContacts',
          'pendingDocuments',
          'pendingEvents',
          'pendingPasswords'
        ].map(async dbName => await removeAllDocs(userId, masterKey, dbName))
      )

      //unlink all pending records
      const pendingAssets = await getRecords(
        userId,
        'pendingAssetsLiabilities',
        masterKey
      )
      const pendingContacts = await getRecords(
        userId,
        'pendingContacts',
        masterKey
      )
      const pendingDocuments = await getRecords(
        userId,
        'pendingDocuments',
        masterKey
      )
      const pendingEvents = await getRecords(userId, 'pendingEvents', masterKey)
      const pendingPasswords = await getRecords(
        userId,
        'pendingPasswords',
        masterKey
      )

      if (pendingAssets?.length) {
        await Promise.all(
          ['contacts', 'documents', 'events', 'passwords'].map(async dbName => {
            await unlinkAllItems(
              dbName,
              masterKey,
              userId,
              'assetsLiabilities',
              pendingAssets
            )
          })
        )
      }

      if (pendingContacts?.length) {
        await Promise.all(
          ['assetliabilities', 'documents', 'events'].map(async dbName => {
            await unlinkAllItems(
              dbName,
              masterKey,
              userId,
              'contacts',
              pendingContacts
            )
          })
        )
      }

      if (pendingDocuments?.length) {
        await Promise.all(
          ['contacts', 'assetsLiabilities', 'passwords', 'events'].map(
            async dbName => {
              await unlinkAllItems(
                dbName,
                masterKey,
                userId,
                'documents',
                pendingDocuments
              )
            }
          )
        )
      }

      if (pendingEvents?.length) {
        await Promise.all(
          ['contacts', 'assetsLiabilities', 'documents', 'passwords'].map(
            async dbName => {
              await unlinkAllItems(
                dbName,
                masterKey,
                userId,
                'documents',
                pendingDocuments
              )
            }
          )
        )
      }

      if (pendingPasswords?.length) {
        await Promise.all(
          ['documents', 'assetsLiabilities', 'events'].map(async dbName => {
            await unlinkAllItems(
              dbName,
              masterKey,
              userId,
              'documents',
              pendingDocuments
            )
          })
        )
      }

      if (revokeData.professionalDeputyId && deputy.accounts?.length) {
        // delete legacy details of primary user on S3
        await api.bulkDeleteFiles(
          userId,
          JSON.stringify({
            keys: [`legacy/details`]
          })
        )

        // delete legacy details of primary user stored in professional deputies folders on S3
        const allProfessionalDeputies = deputy.accounts.concat(
          deputy.delegatedAccounts?.filter(da => da.isAccepted) || []
        )

        const dataToDelete = [
          `legacy/${userId}/details`,
          `legacy/${userId}/instruction`
        ]

        await Promise.all(
          allProfessionalDeputies.map(async acc => {
            await api.bulkDeleteFiles(
              acc.userId,
              JSON.stringify({
                keys: dataToDelete
              })
            )
          })
        )

        // disable legacy management, so when the user adds the PD again will setup again
        await api.updateLegacyManagementStatus(
          userId,
          JSON.stringify({
            legacyManagementEnabled: null
          })
        )
      }

      dispatch(deputyActionSuccess(i18next.t('SUCCESSFULLY_REVOKED_DEPUTY')))
      dispatch(fetchDeputies(userId))
      dispatch(fetchCustomer(userId))
      dispatch(fetchPendingDocuments(userId, masterKey))
      dispatch(fetchPendingAssetsLiabilities(userId, masterKey))
      dispatch(fetchPendingContacts(userId, masterKey))
    } catch (err) {
      onError(err)
      dispatch(deputyActionFailure(i18next.t('FAILED_TO_REVOKE_DEPUTY')))
    }
  }

export const resendDeputyRequest =
  (userId, emails, fullname) => async dispatch => {
    try {
      dispatch(deputyActionStart())
      const requestData = {
        emails: emails,
        primaryUserName: fullname
      }
      await api.requestDeputy(userId, JSON.stringify(requestData))

      dispatch(
        deputyActionSuccess(i18next.t('SUCCESSFULLY_RESENT_DEPUTY_REQUEST'))
      )
    } catch (err) {
      onError(err)
      dispatch(
        deputyActionFailure(i18next.t('FAILED_TO_RESENT_DEPUTY_REQUEST'))
      )
    }
  }

export const resendUnlockRequest = (userId, requestData) => async dispatch => {
  try {
    dispatch(deputyActionStart())
    const res = await api.requestUnlock(userId, JSON.stringify(requestData))
    if (res.data.message) throw Error(res.data.message)

    dispatch(
      deputyActionSuccess(i18next.t('SUCCESSFULLY_RESENT_UNLOCK_REQUEST'))
    )
  } catch (err) {
    onError(err)
    dispatch(deputyActionFailure(i18next.t('FAILED_TO_RESENT_UNLOCK_REQUEST')))
  }
}
