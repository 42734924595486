import React, { useContext, useState } from 'react'
import { Modal, Form, InputNumber, Row, Col, message } from 'antd'
//import { StringResources } from '../../share/StringResources'
import FormItem from '../override/FormItem'
import CurrencyInputWithCode from '../common/CurrencyInputWithCode'
import { CORE_TYPES, END_OF_TIME, ASSET_TYPES } from '../../share/Constants'
import { currencyParse } from '../../share/helpers'
import { useSelector } from 'react-redux'
import PouchDB from 'pouchdb'
import AssetLiabilityValuation from '../../model/AssetLiabilityValuationModel'
import VaultContext from '../../contexts/VaultContext'
import moment from 'moment'
import uuidv4 from 'uuid/v4'
import { uploadEncryptedData, getLatestVersion } from '../../lib/pouchDb'
import { onError } from '../../lib/sentry'
import { useTranslation } from 'react-i18next';
import { removeHtmlTags } from './../../share/helpers';

const AssetLiabilityBackDatedAmend = ({
  visible,
  setVisible,
  record,
  registryDate,
  form
}) => {
  const { getFieldDecorator } = form
  const { baseCurrency, allRates } = useSelector(state => state.settings)
  const { userId, masterKey } = useContext(VaultContext)
  const [isSaving, setIsSaving] = useState(false)
  const {t} = useTranslation()

  const handleOk = () => {
    form.validateFields(async (err, values) => {
      if (err) {
        return
      }

      removeHtmlTags(values)

      setIsSaving(true)
      try {
        const valuationDb = new PouchDB(`${userId}_assetsLiabilitiesValuations`)
        valuationDb.crypto(masterKey)
        const assetLiabilityId = record._id.split('_')[0]

        if (moment(record.validFrom).isSame(registryDate)) {
          const newRecord = new AssetLiabilityValuation({
            ...record,
            ...values
          })
          await valuationDb.put(newRecord)
        } else {
          const newRecord = new AssetLiabilityValuation({
            ...record,
            ...values,
            _id: `${assetLiabilityId}_${uuidv4()}`,
            _rev: undefined,
            validFrom: registryDate.toJSON()
          })
          const updatedRecord = new AssetLiabilityValuation({
            ...record,
            validTo: registryDate.subtract(1, 'ms').toJSON()
          })
          await valuationDb.bulkDocs([newRecord, updatedRecord])
        }

        await uploadEncryptedData(
          valuationDb,
          userId,
          'assetsLiabilitiesValuations'
        )

        if (record.validTo === END_OF_TIME) {
          const db = new PouchDB(`${userId}_assetsLiabilities`)
          db.crypto(masterKey)
          const assetLiability = await db.get(assetLiabilityId)
          const newAssetLiability = {
            ...assetLiability,
            percentageOwnership: values.percentageOwnership,
            quantity: values.quantity,
            valuationInAssetCurrency: values.valuationInAssetCurrency,
            valuationInBaseCurrency: values.valuationInBaseCurrency,
            outstandingValueInLiabilityCurrency:
              values.outstandingValueInLiabilityCurrency,
            outstandingValueInBaseCurrency:
              values.outstandingValueInBaseCurrency
          }
          const putResult = await db.put(newAssetLiability)

          const historyDb = new PouchDB(`${userId}_assetsLiabilitiesHistory`)
          historyDb.crypto(masterKey)

          const latestVersion = await getLatestVersion(
            'assetsLiabilitiesHistory',
            userId,
            putResult.id,
            masterKey
          )

          await historyDb.put({
            ...latestVersion,
            ...newAssetLiability,
            _id: latestVersion._id,
            _rev: latestVersion._rev,
            time: new Date()
          })

          await Promise.all([
            uploadEncryptedData(db, userId, 'assetsLiabilities'),
            uploadEncryptedData(historyDb, userId, 'assetsLiabilitiesHistory')
          ])
        }

        form.resetFields()
        setVisible(false)
        message.success('Successfully amended')
      } catch (e) {
        onError(e)
        message.success('Failed to amend')
      } finally {
        setIsSaving(false)
      }
    })
  }

  const handleCancel = () => {
    form.resetFields()
    setVisible(false)
  }

  const getRate = quoteCurrency => {
    if (quoteCurrency === baseCurrency) return 1
    return allRates[quoteCurrency]
  }

  const handleCurrencyValueChange = e => {
    if (!baseCurrency) return

    const currencyValue = currencyParse(e.target.value)
    if (!currencyValue) return

    if (!record.currency) return

    const rate = getRate(record.currency)
    if (!rate) return

    const value = +currencyValue / +rate
    setBaseCurrencyValue(value)
  }

  const setBaseCurrencyValue = value => {
    form.setFieldsValue({
      [record.type === CORE_TYPES.ASSET
        ? 'valuationInBaseCurrency'
        : 'outstandingValueInBaseCurrency']: value
    })
  }

  return (
    <Modal
      title="Back-dated amend"
      visible={visible}
      onCancel={handleCancel}
      onOk={handleOk}
      okText="Save"
      maskClosable={false}
      okButtonProps={{ loading: isSaving }}
    >
      <Form>
        <Row gutter={20}>
          <Col span={12}>
            <FormItem label={t('PERCENTAGE_OWNERSHIP')}>
              {getFieldDecorator('percentageOwnership', {
                initialValue: record.percentageOwnership || 100
              })(<InputNumber min={0} max={100} />)}
            </FormItem>
          </Col>
          {(record.subType === ASSET_TYPES.EQUITY_LISTED ||
            record.subType === ASSET_TYPES.EQUITY_UNLISTED) && (
            <Col span={12}>
              <FormItem label={t('QUANTITY')}>
                {getFieldDecorator('quantity', {
                  initialValue: record.quantity
                })(<InputNumber min={1} />)}
              </FormItem>
            </Col>
          )}
        </Row>

        {record.type === CORE_TYPES.ASSET && (
          <>
            <CurrencyInputWithCode
              currency={record.currency}
              label={t('VALUATION_ASSET_CURRENCY')}
              fieldName="valuationInAssetCurrency"
              initialValue={record.valuationInAssetCurrency}
              getFieldDecorator={getFieldDecorator}
              onBlur={handleCurrencyValueChange}
            />
            <CurrencyInputWithCode
              currency={baseCurrency}
              label={t('VALUATION_BASE_CURRENCY')}
              fieldName="valuationInBaseCurrency"
              initialValue={record.valuationInBaseCurrency}
              getFieldDecorator={getFieldDecorator}
            />
          </>
        )}
        {record.type === CORE_TYPES.LIABILITY && (
          <>
            <CurrencyInputWithCode
              currency={record.currency}
              label={t('OUTSTANDING_VALUE_LIABILITY_CURRENCY')}
              fieldName="outstandingValueInLiabilityCurrency"
              initialValue={record.outstandingValueInLiabilityCurrency}
              onBlur={handleCurrencyValueChange}
              getFieldDecorator={getFieldDecorator}
            />
            <CurrencyInputWithCode
              currency={baseCurrency}
              fieldName="outstandingValueInBaseCurrency"
              label={t('OUTSTANDING_VALUE_BASE_CURRENCY')}
              initialValue={record.outstandingValueInBaseCurrency}
              getFieldDecorator={getFieldDecorator}
            />
          </>
        )}
      </Form>
    </Modal>
  )
}

const WrappedAssetLiabilityBackDatedAmend = Form.create({
  name: 'AssetLiabilityBackDatedAmend'
})(AssetLiabilityBackDatedAmend)
export default WrappedAssetLiabilityBackDatedAmend
