import React, { useContext } from 'react'
import { Card, Alert, Tooltip, Icon } from 'antd'
import { H4 } from '../override/Typography'
import Button from '../override/Button'
import CustomIcon from '../override/Icon'
import { ThemeContext } from 'styled-components'
import config from '../../config'
import FormItem from '../override/FormItem'
//import { StringResources } from './../../share/StringResources'
import moment from 'moment'
import { GAevent } from '../../lib/ga'
import { useTranslation, Trans } from 'react-i18next'
import TextInput from './../common/TextInput'
import { sanitizeValue } from '../../share/helpers'
import { onError } from '../../lib/sentry'
import { getUserData, getUserAttributeValue } from '../../lib/cognito'
import proLogo from '../../assets/ic/pro_symbol.png'

export default function SubscriptionPlans(props) {
  const {
    showReferralCodeInput,
    showPromoCodeInput,
    plans,
    currentPlanId,
    handleCancelSubscription,
    handleApplyReferralCode,
    handleSubscribe,
    subscriptionStatus,
    defaultSource,
    referralCode,
    setReferralCode,
    promoCode,
    setPromoCode,
    setSelectedPlan,
    isCancelAtEnd,
    handleReactivate,
    handleRelease,
    nextPhase,
    isModal,
    isApplyReferralCode
  } = props
  const { defaultPlans, user } = plans
  const theme = useContext(ThemeContext)
  const { Meta } = Card
  const { t } = useTranslation()
  const externalUser = localStorage.getItem('External_User')
  const monthlyPlan = defaultPlans.find(
    plan => plan.interval === 'month' && !plan.metadata.membersCode
  )
  const yearlyPlan = defaultPlans.find(
    plan => plan.interval === 'year' && !plan.metadata.membersCode
  )

  const groupMonthlyPlan = defaultPlans.find(
    plan => plan.interval === 'month' && plan.metadata.membersCode
  )
  const groupYearlyPlan = defaultPlans.find(
    plan => plan.interval === 'year' && plan.metadata.membersCode
  )

  // const getPlanDescription = plan => {
  //   const customPdPrice = customPdPlans.find(
  //     p => p.nickname === plan.nickname
  //   )?.amount
  //   const defaultPdPrice = defaultPdPlans.find(
  //     p => p.nickname === plan.nickname
  //   )?.amount

  //   return [
  //     t('THIRTY_DAYS_FREE_TRIAL'),
  //     t('ASSETS_AND_LIABILITY_REGISTRY'),
  //     t('TRACKING_NETWORTH'),
  //     t('QUANTITY_OF_SECURE_FILE_STORAGE'),
  //     t('SAVE_YOUR_IMPORTANT_CONTACT'),
  //     t('APPOINT_DEPUTIES'),
  //     t('BE_APPOINTED_AS_A_DEPUTY'),
  //     <span>
  //       {t('ABILITY_TO_APPOINT_PD_AS_ADDON_AT')}
  //       {plan.metadata.discountCode && customPdPrice !== undefined ? (
  //         <>
  //           <span style={{ textDecoration: 'line-through' }}>
  //             US$ {defaultPdPrice / 100}
  //           </span>{' '}
  //           <span>US$ {customPdPrice / 100}</span>
  //         </>
  //       ) : (
  //         <span>US$ {defaultPdPrice / 100}</span>
  //       )}{' '}
  //       / {t(plan.interval)}
  //     </span>
  //   ]
  // }

  const PlanCard = ({ title, metaTitle, description, cardButton, isFree }) => {
    return (
      <Card
        title={
          <>
            <H4 display="inline" color={theme.white}>
              {title}
            </H4>
            {/* <span>{!isSignupPage && isCurrentPlan && ' (current)'}</span>
            <span>
              {!isSignupPage &&
                !!nextPhase?.plans?.find(pl => pl.plan === plan?.id) &&
                ' (next)'}
            </span> */}
          </>
        }
        className="plan__cards"
        headStyle={{
          textAlign: 'center',
          backgroundColor: theme.primary,
          borderBottomColor: theme.primary,
          color: theme.white
        }}
        bodyStyle={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Meta
          style={{ flexGrow: 1 }}
          title={
            <div
              style={{
                textAlign: 'center',
                whiteSpace: 'pre-wrap',
                marginBottom: 25
              }}
            >
              {metaTitle}
              {cardButton}
            </div>
          }
          description={description}
        />
      </Card>
    )
  }

  const CardDescription = ({ content }) => {
    return (
      <div style={{ paddingBottom: 12, paddingLeft: 22 }}>
        <span style={{ color: 'black', fontSize: 12 }}>{content}</span>
      </div>
    )
  }

  const NormalHeaderDescription = ({ content }) => {
    return (
      <div>
        <CustomIcon
          type="tick"
          style={{ color: theme.primary, marginRight: 8 }}
        />
        <span style={{ fontWeight: 'bold', color: 'black', fontSize: 12 }}>
          {content}
        </span>
      </div>
    )
  }

  const ProHeaderDescription = ({ content }) => {
    return (
      <div>
        <CustomIcon type="pro" style={{ marginRight: 4, fontSize: 18 }} />
        <span style={{ fontWeight: 'bold', color: 'black', fontSize: 12 }}>
          {content}
        </span>
      </div>
    )
  }

  const ModalAction = ({ isCurrentPlan, plan, isCancelAtEnd, changeDate }) => {
    return isCurrentPlan ? (
      isCancelAtEnd ? (
        <Button type="primary" onClick={handleReactivate}>
          {t('REACTIVATE')}
        </Button>
      ) : (
        <Button style={{ color: theme.red }} onClick={handleCancelSubscription}>
          {t('CANCEL')}
        </Button>
      )
    ) : changeDate ? (
      <Tooltip
        title={
          <Trans
            i18nKey="CHANGE_SUBSCRIPTION_PLAN_ON"
            values={{ changeDate: moment(changeDate * 1000).format('LL') }}
          ></Trans>
        }
      >
        <Button onClick={handleRelease}>{t('CANCEL_CHANGE')}</Button>
      </Tooltip>
    ) : (
      <Button
        type="default"
        onClick={() => handleSubscribe(plan)}
        disabled={subscriptionStatus === 'past_due' && !defaultSource}
      >
        {plan.nickname}
      </Button>
    )
  }

  const Action = ({ isCurrentPlan, plan }) => (
    <Button
      style={{ fontSize: !isCurrentPlan && !!plan?.nickname ? 14 : 12 }}
      type={isCurrentPlan ? 'primary' : 'default'}
      onClick={() => {
        if (!isCurrentPlan) {
          GAevent({
            category: 'Register',
            action: `Clicked ${plan?.nickname || 'vaultbox Essential'}`,
            label: 'Subscription plans'
          })
        }
        setSelectedPlan(
          isCurrentPlan
            ? {
                id: '',
                nickname: '',
                amount: 0,
                interval: '',
                currency: '',
                metadata: {}
              }
            : plan
            ? {
                id: plan.id,
                nickname: plan.nickname,
                amount: plan.amount,
                interval: plan.interval,
                currency: plan.currency,
                metadata: plan.metadata
              }
            : {
                id: 'free',
                nickname: 'vaultbox Essential'
              }
        )
      }}
      icon={isCurrentPlan ? 'check' : null}
    >
      {/* {plan?.nickname} */}
      {isCurrentPlan && !plan
        ? t('SELECTED')
        : plan
        ? t(plan.nickname)
        : t('SELECT')}
    </Button>
  )

  return (
    <>
      {config.env !== 'prod' && (
        <Alert
          style={{
            marginBottom: 20
          }}
          description={<div>{t('SUBSCRIPTION_TESTING_NOTE')}</div>}
          type="info"
          showIcon
        />
      )}

      {showReferralCodeInput && (
        <FormItem
          label={
            <span>
              {t('REFERRAL_CODE')}{' '}
              <Tooltip
                title={
                  <>
                    <div>{t('REFERRAL_CODE_TOOLTIP_FIRST_SUMMARY')}</div>
                    <div>{t('REFERRAL_CODE_TOOLTIP_SECOND_SUMMARY')}</div>
                    <div>{t('THIS_IS_NOT_APPLIED_TO_THE_FREE_PLAN')}</div>
                  </>
                }
              >
                <Icon type="question-circle-o" />
              </Tooltip>
            </span>
          }
        >
          <div style={isModal ? { display: 'flex' } : {}}>
            <div
              style={
                isModal
                  ? { width: 'calc(100% - 58px)', paddingRight: '10px' }
                  : {}
              }
            >
              <TextInput
                type="text"
                placeholder={t('ENTER_REFERRAL_CODE')}
                onChange={e => setReferralCode(e.target.value)}
                onBlur={e =>
                  setReferralCode(sanitizeValue(e.target.value).trim())
                }
                value={referralCode}
                allowClear
              />
            </div>
            {isModal && (
              <Button
                type="primary"
                size="large"
                loading={isApplyReferralCode}
                disabled={!referralCode}
                onClick={() => {
                  if (externalUser) {
                    const userAttributes = JSON.parse(
                      localStorage.getItem('UserAttributes')
                    )
                    const email =
                      userAttributes.find(a => a.Name === 'email')?.Value || ''
                    handleApplyReferralCode(email, true)
                  } else {
                    getUserData(user, async (err, data) => {
                      if (err) {
                        onError(err)
                        return
                      }
                      const email = getUserAttributeValue(
                        data.UserAttributes,
                        'email'
                      )
                      handleApplyReferralCode(email, true)
                    })
                  }
                }}
              >
                {t('APPLY')}
              </Button>
            )}
          </div>
        </FormItem>
      )}
      {showPromoCodeInput && (
        <FormItem label={t('PROMOTION_CODE')}>
          <TextInput
            type="text"
            placeholder={t('ENTER_PROMOTION_CODE')}
            onChange={e => setPromoCode(e.target.value)}
            onBlur={e => setPromoCode(sanitizeValue(e.target.value).trim())}
            value={promoCode}
            allowClear
          />
        </FormItem>
      )}
      <div className="plan">
        {!!defaultPlans?.length && (
          <PlanCard
            key="free"
            title={'vaultbox Essential'}
            isFree={true}
            metaTitle={
              <div>
                <h5 style={{ marginBottom: 40, fontWeight: 'bold' }}>
                  {t('FREE_TO_USE_GET_STARTED_NOW_UPGRADE_ANYTIME')}
                </h5>
                <H4
                  style={{
                    marginBottom: !!setSelectedPlan
                      ? !!externalUser
                        ? 55
                        : 35
                      : currentPlanId === 'free'
                      ? 48
                      : 93
                  }}
                >
                  {t('FREE')}
                </H4>
              </div>
            }
            cardButton={
              !!setSelectedPlan && (
                <Action
                  isCurrentPlan={currentPlanId === 'free'}
                  plan={undefined}
                />
              )
            }
            description={
              <>
                <NormalHeaderDescription content={t('DIGITAL_VAULT')} />
                <CardDescription content={t('DIGITAL_VAULT_CONTENT')} />
                <NormalHeaderDescription
                  content={t('ASSETS_AND_LIABILITY_REGISTRY')}
                />
                <CardDescription
                  content={t('ASSETS_AND_LIABILITY_REGISTRY_CONTENT')}
                />
                <NormalHeaderDescription content={t('PASSWORD_VAULT')} />
                <CardDescription content={t('PASSWORD_VAULT_CONTENT')} />
                <NormalHeaderDescription content={t('CONTACT_MANAGEMENT')} />
                <CardDescription content={t('CONTACT_MANAGEMENT_CONTENT')} />
                <NormalHeaderDescription content={t('CALENDAR_FEATURE')} />
                <CardDescription content={t('CALENDAR_FEATURE_CONTENT')} />
                <NormalHeaderDescription
                  content={t('ESSENTIAL_VAULT_STORAGE')}
                />
              </>
            }
          />
        )}
        {!!defaultPlans?.length && (
          <PlanCard
            key={'Vaultbox Pro'}
            title={
              <span>
                {'vaultbox Pro'} <img src={proLogo} alt="pro logo" width={25} />
              </span>
            }
            metaTitle={
              <div>
                <h5 style={{ marginBottom: 40, fontWeight: 'bold' }}>
                  {t('ULTIMATE_FINANCIAL_AND_LEGACY_PLAN')}
                </h5>
                <div
                  style={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    textAlign: 'left',
                    justifyContent: 'center',
                    gap: 15,
                    marginBottom: 20
                  }}
                >
                  <div>
                    <div>
                      <H4
                        className={
                          monthlyPlan.id === currentPlanId
                            ? 'plan-selected'
                            : ''
                        }
                      >
                        US$ {monthlyPlan.amount / 100}
                      </H4>
                      <h5
                        className={
                          monthlyPlan.id === currentPlanId
                            ? 'plan-selected'
                            : ''
                        }
                        style={{ fontWeight: 'bold' }}
                      >
                        /{t(monthlyPlan.interval)}
                      </h5>
                    </div>
                    {!!monthlyPlan.metadata.discountCode && (
                      <div
                        style={{
                          textDecoration: 'line-through',
                          color: theme.dark2
                        }}
                      >
                        US$ {monthlyPlan.amount / 100} /{' '}
                        {t(monthlyPlan.interval)}
                      </div>
                    )}
                  </div>
                  <div>
                    <div>
                      <H4
                        className={
                          yearlyPlan.id === currentPlanId ? 'plan-selected' : ''
                        }
                      >
                        US$ {yearlyPlan.amount / 100}
                      </H4>
                      <h5
                        className={
                          yearlyPlan.id === currentPlanId ? 'plan-selected' : ''
                        }
                        style={{ fontWeight: 'bold' }}
                      >
                        /{t(yearlyPlan.interval)}
                      </h5>
                    </div>
                    {!!yearlyPlan.metadata.discountCode && (
                      <div
                        style={{
                          textDecoration: 'line-through',
                          color: theme.dark2
                        }}
                      >
                        US$ {yearlyPlan.amount / 100} / {t(yearlyPlan.interval)}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            }
            cardButton={
              !!setSelectedPlan ? (
                <div>
                  <Action
                    isCurrentPlan={monthlyPlan.id === currentPlanId}
                    plan={monthlyPlan}
                  />
                  <Action
                    isCurrentPlan={yearlyPlan.id === currentPlanId}
                    plan={yearlyPlan}
                  />
                </div>
              ) : (
                <div>
                  <ModalAction
                    isCurrentPlan={monthlyPlan.id === currentPlanId}
                    plan={monthlyPlan}
                    isCancelAtEnd={isCancelAtEnd}
                    changeDate={
                      !!nextPhase?.plans?.find(
                        pl => pl.plan === monthlyPlan.id
                      ) && nextPhase?.start_date
                    }
                  />
                  <ModalAction
                    isCurrentPlan={yearlyPlan.id === currentPlanId}
                    plan={yearlyPlan}
                    isCancelAtEnd={isCancelAtEnd}
                    changeDate={
                      !!nextPhase?.plans?.find(
                        pl => pl.plan === yearlyPlan.id
                      ) && nextPhase?.start_date
                    }
                  />
                </div>
              )
            }
            description={
              <>
                <NormalHeaderDescription
                  content={t('INCLUDE_EVERYTHING_IN_VAULTBOX_ESSENTIAL')}
                />
                <div style={{ paddingBottom: 12 }}></div>
                <ProHeaderDescription content={t('DEPUTIES')} />
                <CardDescription content={t('DEPUTIES_CONTENT')} />
                <ProHeaderDescription
                  content={
                    <span>
                      {t('PROFESSIONAL_DEPUTY')} {'+'} {t('LEGACY_MANAGEMENT')}
                    </span>
                  }
                />
                <CardDescription
                  content={
                    <span>
                      {t('ADD_ON_THIS_SERVICE_AT')}{' '}
                      {yearlyPlan.id === currentPlanId
                        ? `US$ ${yearlyPlan.amount / 100} / ${t(
                            yearlyPlan.interval
                          )}`
                        : `US$ ${monthlyPlan.amount / 100} / ${t(
                            monthlyPlan.interval
                          )}`}
                    </span>
                  }
                />
                <ProHeaderDescription content={t('PRO_STORAGE')} />
              </>
            }
            isCancelAtEnd={isCancelAtEnd}
          />
        )}
        {!!defaultPlans?.length && (
          <PlanCard
            key="Vaultbox Group"
            title={'vaultbox Group'}
            metaTitle={
              <div>
                <h5 style={{ marginBottom: 40, fontWeight: 'bold' }}>
                  {t('IDEAL_FOR_SMALL_ENTERPRISES_AND_FAMILY')}
                </h5>
                <div
                  style={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    textAlign: 'left',
                    justifyContent: 'center',
                    gap: 15,
                    marginBottom: 20
                  }}
                >
                  <div>
                    <div>
                      <H4
                        className={
                          groupMonthlyPlan.id === currentPlanId
                            ? 'plan-selected'
                            : ''
                        }
                      >
                        US$ {groupMonthlyPlan.amount / 100}
                      </H4>
                      <h5
                        className={
                          groupMonthlyPlan.id === currentPlanId
                            ? 'plan-selected'
                            : ''
                        }
                        style={{ fontWeight: 'bold' }}
                      >
                        /{t(groupMonthlyPlan.interval)}
                      </h5>
                    </div>
                  </div>
                  <div>
                    <div>
                      <H4
                        className={
                          groupYearlyPlan.id === currentPlanId
                            ? 'plan-selected'
                            : ''
                        }
                      >
                        US$ {groupYearlyPlan.amount / 100}
                      </H4>
                      <h5
                        className={
                          groupYearlyPlan.id === currentPlanId
                            ? 'plan-selected'
                            : ''
                        }
                        style={{ fontWeight: 'bold' }}
                      >
                        /{t(groupYearlyPlan.interval)}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            }
            cardButton={
              !!setSelectedPlan ? (
                <div>
                  <Action
                    isCurrentPlan={groupMonthlyPlan.id === currentPlanId}
                    plan={groupMonthlyPlan}
                  />
                  <Action
                    isCurrentPlan={groupYearlyPlan.id === currentPlanId}
                    plan={groupYearlyPlan}
                  />
                </div>
              ) : (
                <div>
                  <ModalAction
                    isCurrentPlan={groupMonthlyPlan.id === currentPlanId}
                    plan={groupMonthlyPlan}
                    isCancelAtEnd={isCancelAtEnd}
                    changeDate={
                      !!nextPhase?.plans?.find(
                        pl => pl.plan === groupMonthlyPlan.id
                      ) && nextPhase?.start_date
                    }
                  />
                  <ModalAction
                    isCurrentPlan={groupYearlyPlan.id === currentPlanId}
                    plan={groupYearlyPlan}
                    isCancelAtEnd={isCancelAtEnd}
                    changeDate={
                      !!nextPhase?.plans?.find(
                        pl => pl.plan === groupYearlyPlan.id
                      ) && nextPhase?.start_date
                    }
                  />
                </div>
              )
            }
            description={
              <>
                <NormalHeaderDescription
                  content={t('INCLUDE_EVERYTHING_IN_VAULTBOX_PRO')}
                />
                <div style={{ paddingBottom: 12 }}></div>
                <ProHeaderDescription content={t('DEPUTIES_FOR_ALL_MEMBERS')} />
                <CardDescription content={t('DEPUTIES_CONTENT')} />
                <ProHeaderDescription
                  content={
                    <span>
                      {t('PROFESSIONAL_DEPUTY')} {'+'} {t('LEGACY_MANAGEMENT')}
                    </span>
                  }
                />
                <CardDescription
                  content={
                    <span>
                      {t('ADD_ON_THIS_SERVICE_AT')}{' '}
                      {yearlyPlan.id === currentPlanId
                        ? `US$ ${yearlyPlan.amount / 100} / ${t(
                            yearlyPlan.interval
                          )}`
                        : `US$ ${monthlyPlan.amount / 100} / ${t(
                            monthlyPlan.interval
                          )}`}
                    </span>
                  }
                />
                <ProHeaderDescription content={t('PRO_STORAGE')} />
                <div style={{ paddingBottom: 12 }}></div>
                <ProHeaderDescription content={t('FOR_UP_TO_MEMBERS')} />
              </>
            }
            isCancelAtEnd={isCancelAtEnd}
          />
        )}
      </div>
    </>
  )
}
