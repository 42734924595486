import React, { useState, useContext, useCallback, useEffect } from 'react'
import FormItem from '../override/FormItem'
import Button from '../override/Button'
import { Modal, Input, Form, message } from 'antd'
import { useTranslation } from 'react-i18next'
import VaultContext from '../../contexts/VaultContext'
import AuthContext from '../../contexts/AuthContext'
import { onError } from '../../lib/sentry'
import { deleteFolder } from '../../lib/pouchDb'
import { useSelector } from 'react-redux'
import { Fragment } from 'react'
import FileDetails from './FileDetails'
import { AES, enc } from 'crypto-js'
import { getUserData } from '../../lib/cognito'
import { logAccessAttempt } from '../../share/logs'
import { decryptFilePromise } from '../../lib/crypto'
import { s3Get } from '../../lib/awsSDK'
import api from '../../lib/api'
import { LEGACY_ACCESSED_BY } from '../../share/Constants'

function PasswordFolderModal(props) {
  const {
    docItem,
    visible,
    setVisible,
    setBreadcrumb,
    showStarredDocsOnly,
    isDeletePrivateFolder,
    isShowLinkedDocument,
    setShowStarredDocsOnly,
    handleMoveOrCopy,
    isMovingOrCopying,
    isMoveOrCopy,
    handleUploadFile,
    isUploadToPrivateFolder,
    closePasswordModal,
    form
  } = props
  const { getFieldDecorator } = form
  const [isSaving, setIsSaving] = useState(false)
  const [legacyInfo, setLegacyInfo] = useState()
  const {
    isReadonly,
    userId,
    masterKey,
    privateFolderKey,
    isLegacyRequest,
    isReleasePassword,
    setIsReleasePassword,
    setIsLockPrivateFolder
  } = useContext(VaultContext)
  const { user, isProfessionalDeputy, isDelegateByPD } = useContext(AuthContext)
  const [isReleasing, setIsReleasing] = useState(false)
  const [selectedDoc, setSelectedDoc] = useState({})
  const [fileDetailsVisible, setFileDetailsVisible] = useState(false)
  const { t } = useTranslation()
  const { activeDocuments } = useSelector(state =>
    isReadonly ? state.otherDocuments : state.documents
  )
  const privateFolder = activeDocuments.find(folder => folder.isPrivate)
  const privateFolderPassword = !!privateFolder?.password
    ? AES.decrypt(privateFolder.password, privateFolderKey).toString(enc.Latin1)
    : undefined

  const fetchLegacyInfo = useCallback(
    async masterKey => {
      try {
        const res = await s3Get(
          userId,
          `legacy/details`,
          {},
          { responseType: 'blob' }
        )
        const data = await decryptFilePromise(res, masterKey)
        const fileContent = String.fromCharCode(...data)

        setLegacyInfo(JSON.parse(fileContent))
      } catch (error) {
        onError(error)
        message.error(t('FAILED_TO_GET_LEGACY_MANAGEMENT_INFO'))
      }
    },
    [userId, t]
  )

  useEffect(() => {
    if (masterKey && isLegacyRequest && isProfessionalDeputy) {
      fetchLegacyInfo(masterKey)
    }
  }, [fetchLegacyInfo, masterKey, isLegacyRequest, isProfessionalDeputy])

  const handleSave = () => {
    form.validateFields(async (err, values) => {
      if (err) {
        return
      }
      try {
        setIsSaving(true)
        if (
          values.password ===
          AES.decrypt(privateFolder.password, privateFolderKey).toString(
            enc.Latin1
          )
        ) {
          if (isProfessionalDeputy || (isDelegateByPD && isReadonly)) {
            getUserData(user, async (err, userData) => {
              if (err) {
                onError(err)
                return
              }
              await logAccessAttempt(
                userId,
                userData.UserAttributes,
                user.username,
                true
              )
            })
          }
          localStorage.removeItem('incorrect_password')
          if (isDeletePrivateFolder) {
            try {
              await deleteFolder(activeDocuments, docItem, userId, masterKey)
              setIsSaving(false)
              form.resetFields()
              setVisible(false)
              message.success(
                `${t('SUCCESSFULLY_DELETED')} ${t('FOLDER').toLowerCase()}`
              )
            } catch (error) {
              message.error(
                `${t('FAILED_TO_DELETE')} ${t('FOLDER').toLowerCase()}`
              )
              onError(error)
              setIsSaving(false)
            }
          } else if (isShowLinkedDocument) {
            try {
              localStorage.setItem(
                'privateFolderPassword',
                privateFolderPassword
              )
              setSelectedDoc({
                id: docItem._id || docItem.id,
                name: docItem.fileName,
                fileId: docItem.fileId,
                sub: docItem.sub,
                status: docItem.status
              })
              setFileDetailsVisible(true)
              setIsSaving(false)
              form.resetFields()
              setVisible(false)
            } catch (error) {
              onError(error)
              setIsSaving(false)
            }
          } else if (isMoveOrCopy) {
            try {
              localStorage.setItem(
                'privateFolderPassword',
                privateFolderPassword
              )
              await handleMoveOrCopy()
              setIsSaving(false)
              form.resetFields()
              setVisible(false)
            } catch (error) {
              onError(error)
              setIsSaving(false)
            }
          } else if (isUploadToPrivateFolder) {
            try {
              localStorage.setItem(
                'privateFolderPassword',
                privateFolderPassword
              )
              await handleUploadFile()
              setIsSaving(false)
              form.resetFields()
              closePasswordModal()
            } catch (error) {
              onError(error)
              setIsSaving(false)
            }
          } else {
            localStorage.setItem('privateFolderPassword', privateFolderPassword)
            setBreadcrumb(docItem.path)
            showStarredDocsOnly && setShowStarredDocsOnly(false)
            setIsSaving(false)
            form.resetFields()
            setVisible(false)
          }
        } else {
          if (isProfessionalDeputy || (isDelegateByPD && isReadonly)) {
            const incorrectTimes =
              localStorage.getItem('incorrect_password') || 0
            if (incorrectTimes < 2) {
              localStorage.setItem(
                'incorrect_password',
                parseInt(incorrectTimes) + 1
              )
              setIsSaving(false)
              message.error(t('INCORRECT_PASSWORD'))
            } else {
              getUserData(user, async (err, userData) => {
                if (err) {
                  onError(err)
                  return
                }
                await logAccessAttempt(
                  userId,
                  userData.UserAttributes,
                  user.username,
                  false
                )
              })
              message.error(t('INCORRECT_PASSWORD'))
              await api.lockPrivateFolder(
                user.username,
                JSON.stringify({
                  userId: userId
                })
              )
              localStorage.removeItem('incorrect_password')
              setIsSaving(false)
              form.resetFields()
              setIsLockPrivateFolder(true)
              setVisible(false)
            }
          } else {
            setIsSaving(false)
            message.error(t('INCORRECT_PASSWORD'))
          }
        }
      } catch (error) {
        onError(error)
        setIsSaving(false)
        form.resetFields()
        isUploadToPrivateFolder ? closePasswordModal() : setVisible(false)
      }
    })
  }

  const handleReleasePassword = async () => {
    setIsReleasing(true)
    try {
      if (
        !!legacyInfo.otherPerson &&
        legacyInfo.accessedBy === LEGACY_ACCESSED_BY.OTHER_SPECIFIC_PERSON
      ) {
        const data = {
          emails: legacyInfo.otherPerson.emails.map(email => email['value']),
          password: AES.decrypt(
            privateFolder.password,
            privateFolderKey
          ).toString(enc.Latin1)
        }
        const apiRes = await api.releasePasswordEmail(
          userId,
          JSON.stringify(data)
        )
        if (apiRes.data.message) throw Error(apiRes.data.message)
      } else {
        const res = await api.getDeputies(userId)
        const data = {
          emails: res.data.deputies.map(d => d.email),
          password: AES.decrypt(
            privateFolder.password,
            privateFolderKey
          ).toString(enc.Latin1)
        }
        const apiRes = await api.releasePasswordEmail(
          userId,
          JSON.stringify(data)
        )
        if (apiRes.data.message) throw Error(apiRes.data.message)
      }

      await api.setIsReleasePassword(
        userId,
        JSON.stringify({
          isReleasePassword: true
        })
      )
      setIsReleasePassword(true)

      message.success(t('SUCCESSFULLY_RELEASE_PASSWORD'))
    } catch (error) {
      message.success(t('FAILED_TO_RELEASE_PASSWORD'))
      onError(error)
    } finally {
      setIsReleasing(false)
    }
  }

  return (
    <Fragment>
      <Modal
        title={t('PASSWORD')}
        visible={visible}
        onCancel={() => {
          isUploadToPrivateFolder ? closePasswordModal() : setVisible(false)
          form.resetFields()
        }}
        maskClosable={false}
        footer={[
          <Button
            key="return"
            onClick={() => {
              isUploadToPrivateFolder ? closePasswordModal() : setVisible(false)
              form.resetFields()
            }}
          >
            {t('CANCEL')}
          </Button>,
          <Button
            key="ok"
            type="primary"
            loading={isMoveOrCopy ? isMovingOrCopying : isSaving}
            onClick={handleSave}
          >
            {t('OK')}
          </Button>,
          isProfessionalDeputy &&
            !!privateFolder &&
            !!privateFolder.password &&
            isLegacyRequest && (
              <Button
                key="ok"
                type="primary"
                loading={isReleasing}
                onClick={handleReleasePassword}
                disabled={isReleasePassword}
              >
                {t('RELEASE_PASSWORD')}
              </Button>
            )
        ]}
      >
        <Form>
          <FormItem label={t('PASSWORD')}>
            {getFieldDecorator('password', {
              rules: [{ required: true, message: t('INPUT_PASSWORD_MSG') }]
            })(<Input.Password placeholder={t('PASSWORD')} />)}
          </FormItem>
        </Form>
      </Modal>
      <FileDetails
        visible={fileDetailsVisible}
        setVisible={setFileDetailsVisible}
        docItem={selectedDoc}
      />
    </Fragment>
  )
}

const WrappedPasswordFolderModal = Form.create({ name: 'PasswordFolderModal' })(
  PasswordFolderModal
)

export default WrappedPasswordFolderModal
