import { Modal, message } from 'antd'
import React, { Fragment, useEffect, useState, useContext } from 'react'
import {
  getFileSize,
  formatBytes,
  downloadAllDataAsZip
} from '../../share/helpers'
import api from '../../lib/api'
import VaultContext from '../../contexts/VaultContext'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { onError } from '../../lib/sentry'
import PasswordDownloadModal from '../modals/PasswordDownloadModal'

const DownloadVaultboxModal = props => {
  const { visible, setVisible, setIsDownloading } = props
  const [fileRes, setFileRes] = useState('')
  const [passwordDownloadModalVisible, setPasswordDownloadModalVisible] =
    useState(false)
  const { userId, masterKey, fullName, isReadonly } = useContext(VaultContext)
  const { t } = useTranslation()
  const { activeFolders, activeFiles } = useSelector(state =>
    isReadonly ? state.otherDocuments : state.documents
  )
  const { activeContacts } = useSelector(state =>
    isReadonly ? state.otherContacts : state.contacts
  )
  const { activeAssetsLiabilities } = useSelector(state =>
    isReadonly ? state.otherAssetsLiabilities : state.assetsLiabilities
  )
  let currentZipSize = 0
  let filteredActiveFiles = activeFiles

  useEffect(() => {
    const getFileRes = async () => {
      const fileRes = await api.getLockedFiles(userId)
      setFileRes(fileRes)
    }
    if (userId) {
      getFileRes()
    }
  }, [userId])

  if (fileRes.data?.fileKeys?.length) {
    const pendingUnlockFileIds = fileRes.data.fileKeys
      .filter(fk => fk.isSecretFile)
      .map(puf => puf.fileId)

    filteredActiveFiles = filteredActiveFiles
      .filter(af => !pendingUnlockFileIds.includes(af.fileId))
      .sort((x, y) => {
        return getFileSize(x) - getFileSize(y)
      })
  }

  const privateFolder = activeFolders.find(folder => folder.isPrivate)
  const privateFiles = filteredActiveFiles.filter(
    file =>
      file.path.slice(0, privateFolder?.path.length) === privateFolder?.path
  )

  for (let i = 0; i < filteredActiveFiles.length; i++) {
    const file = filteredActiveFiles[i]
    currentZipSize += getFileSize(file)
  }

  const handleOk = () => {
    setIsDownloading(true)
    try {
      if (privateFiles.length && privateFolder?.password) {
        setPasswordDownloadModalVisible(true)
      } else {
        downloadAllDataAsZip(
          userId,
          activeAssetsLiabilities,
          activeContacts,
          masterKey,
          fullName,
          currentZipSize,
          filteredActiveFiles
        )
      }
      setIsDownloading(false)
      setVisible(false)
    } catch (error) {
      message.error(t('FAILED_TO_DOWNLOAD_VAULTBOX'))
      onError(error)
      setIsDownloading(false)
    }
  }

  return (
    <Fragment>
      <Modal
        visible={visible}
        width={500}
        title={t('CONFIRM_DOWNLOAD_VAULTBOX')}
        onOk={handleOk}
        onCancel={() => setVisible(false)}
      >
        {t('TOTAL_SIZE')}
        {': '}
        {formatBytes(currentZipSize)}
        <br />
        {t('WARNING_DOWNLOAD_VAULTBOX_MSG')} <br />
        {t('CONFIRM_DOWNLOAD_VAULTBOX_MSG')}
      </Modal>
      <PasswordDownloadModal
        visible={passwordDownloadModalVisible}
        setVisible={setPasswordDownloadModalVisible}
        userId={userId}
        activeAssetsLiabilities={activeAssetsLiabilities}
        activeContacts={activeContacts}
        activeFolders={activeFolders}
        masterKey={masterKey}
        fullName={fullName}
        filteredActiveFiles={filteredActiveFiles}
        currentZipSize={currentZipSize}
      />
    </Fragment>
  )
}

export default DownloadVaultboxModal
