import React from 'react'
import { InputNumber, Input } from 'antd'
import FormItem from '../override/FormItem'
import { currencyInputFormat, currencyParse } from '../../share/helpers'

export default function CurrencyInputWithCode({
  currency,
  initialValue,
  label,
  fieldName,
  getFieldDecorator,
  onBlur,
  hasAddonAfter
}) {
  return (
    <FormItem label={label} style={{ flexGrow: 1 }}>
      <div style={{ display: 'flex' }}>
        <Input
          value={currency}
          className="custom-addon-before"
          readOnly
          style={{ width: 55 }}
        />
        {getFieldDecorator(fieldName, {
          initialValue
        })(
          <InputNumber
            style={
              hasAddonAfter
                ? { borderRadius: 'unset' }
                : {
                    borderTopLeftRadius: 'unset',
                    borderBottomLeftRadius: 'unset'
                  }
            }
            onBlur={onBlur}
            formatter={currencyInputFormat}
            parser={currencyParse}
            precision={2}
            min={0}
          />
        )}
      </div>
    </FormItem>
  )
}
