import React, { useState, useContext, Fragment } from 'react'
import { Tooltip, Popconfirm } from 'antd'
import AuthContext from '../../contexts/AuthContext'
import Button from '../override/Button'
import SimpleHeader from '../override/SimpleHeader'
import { H4 } from '../override/Typography'
import SubscriptionModal from './SubscriptionModal'
import { ThemeContext } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { fetchCustomer } from '../../features/payment/customerSlice'
import moment from 'moment'
import PromoCodeModal from './PromoCodeModal'
import { useTranslation } from 'react-i18next'
import { getPlanNickname } from '../../share/helpers'
import { fetchUser } from '../../features/user/userSlice'

export default function PaymentHeader(props) {
  const { removeMember, isLeaving, setGetInvoices } = props
  const { user, isDeputyOnly } = useContext(AuthContext)
  const theme = useContext(ThemeContext)
  const { t } = useTranslation()
  const [subscriptionModalVisible, setSubscriptionModalVisible] =
    useState(false)
  const [promoCodeModalVisible, setPromoCodeModalVisible] = useState(false)
  const { subscription, vaultboxSubscription } = useSelector(
    state => state.customer
  )
  const { mainUserId, email } = useSelector(state => state.user).user

  const discount = subscription?.discount
  const promotionInfo = discount?.coupon

  const dispatch = useDispatch()

  return (
    <Fragment>
      <SimpleHeader
        className="payment-header"
        title={
          <>
            <H4 display="inline-block">
              {t('CURRENT_SUBSCRIPTION')}:{' '}
              <span style={{ color: theme.primary }}>
                {getPlanNickname(
                  vaultboxSubscription?.plan?.nickname,
                  vaultboxSubscription?.plan?.metadata?.membersCode
                )}
              </span>
            </H4>
            {subscription?.current_period_start &&
              subscription?.current_period_end && (
                <span style={{ marginLeft: 5 }}>
                  (
                  {moment(subscription.current_period_start * 1000).format(
                    'LL'
                  )}{' '}
                  -{' '}
                  {moment(subscription.current_period_end * 1000).format('LL')})
                </span>
              )}
          </>
        }
        extra={
          <Fragment>
            {mainUserId && vaultboxSubscription?.plan?.metadata?.membersCode ? (
              <Popconfirm
                title={t('ARE_YOU_SURE_TO_LEFT_GROUP')}
                onConfirm={() => removeMember(email, user.username, mainUserId)}
                okText={t('YES')}
                cancelText={t('NO')}
                arrowPointAtCenter
                placement="bottomLeft"
              >
                <Button loading={isLeaving} style={{ marginRight: 8 }}>
                  {t('LEAVE_GROUP')}{' '}
                </Button>
              </Popconfirm>
            ) : !isDeputyOnly ? (
              <Tooltip
                title={
                  promotionInfo && !mainUserId ? (
                    <div>
                      {t('CURRENT_SUBSCRIPTION_APPLIED_PROMOTION_CODE')}
                    </div>
                  ) : null
                }
              >
                <Button
                  onClick={() => setPromoCodeModalVisible(true)}
                  style={{ marginRight: 8 }}
                  disabled={subscription.discount}
                >
                  {t('PROMOTION_CODE')}
                </Button>
              </Tooltip>
            ) : (
              <Fragment></Fragment>
            )}
            <Button onClick={() => setSubscriptionModalVisible(true)}>
              {t('CHANGE_SUBSCRIPTION')}
            </Button>
          </Fragment>
        }
        size="small"
      />

      <SubscriptionModal
        setGetInvoices={setGetInvoices}
        visible={subscriptionModalVisible}
        setVisible={setSubscriptionModalVisible}
        removeMember={removeMember}
      />
      <PromoCodeModal
        visible={promoCodeModalVisible}
        setVisible={setPromoCodeModalVisible}
        fetchCustomer={() => dispatch(fetchCustomer(user.username))}
        fetchUser={() => dispatch(fetchUser(user.username))}
      />
    </Fragment>
  )
}
