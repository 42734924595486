import React, { useState, useContext } from 'react'
import {
  Icon,
  Breadcrumb,
  Popover,
  Divider,
  Menu,
  Dropdown,
  Radio,
  message
} from 'antd'
import FileModal from './FileModal'
//import { StringResources } from '../../share/StringResources'
import CreateFolderModal from '../file/CreateFolderModal'
import CreatePrivateFolderModal from './CreatePrivateFolderModal'
import { createFolder } from '../../lib/pouchDb'
import AuthContext from '../../contexts/AuthContext'
import VaultContext from '../../contexts/VaultContext'
import { useSelector, useDispatch } from 'react-redux'
import Button from '../override/Button'
import { onError } from '../../lib/sentry'
import ResponsiveBreadCrumb from './ResponsiveBreadCrumb'
import { useTranslation } from 'react-i18next'
import {
  removeHtmlTags,
  showUpgradeSubscriptionPlanConfirm
} from './../../share/helpers'
import { ACCESS_LEVEL } from './../../share/Constants'
import { fetchOtherPendingDocuments } from '../../features/documents/otherDocumentsSlice'
import { fetchOtherContacts } from './../../features/contacts/otherContactsSlice'
import { useMutation } from 'react-apollo-hooks'
import { createS3Change } from '../../graphql/mutations'
import SubscriptionModal from '../payment/SubscriptionModal'

export default function FileNav(props) {
  const [createFolderVisible, setCreateFolderVisible] = useState(false)
  const [createPrivateFolderVisible, setCreatePrivateFolderVisible] =
    useState(false)
  const [fileUploadVisible, setFileUploadVisible] = useState(false)
  const [isCreatePrivateFolder, setIsCreatePrivateFolder] = useState(false)
  const [subscriptionModalVisible, setSubscriptionModalVisible] = useState()
  const [isSaving, setIsSaving] = useState(false)
  const {
    breadcrumb,
    setBreadcrumb,
    searchPopover,
    searchResults,
    isSearchMode,
    isReadonly,
    filesCount,
    foldersCount,
    pendingFilesCount,
    pendingFoldersCount,
    viewMode,
    setViewMode,
    contactDataSource,
    showStarredDocsOnly,
    setShowStarredDocsOnly,
    isStarred,
    setEnableCreateFile,
    exportMenu
  } = props

  const { isProfessionalDeputy, isDelegateByPD } = useContext(AuthContext)
  const { masterKey, userId, limitedRecord } = useContext(VaultContext)
  // const userId = user.username
  const { activeFolders, pendingFolders } = useSelector(state =>
    isReadonly ? state.otherDocuments : state.documents
  )
  const { limit } = useSelector(state => state.customer)
  const privateFolder = activeFolders.find(record => record.isPrivate)
  const { accessLevel } = useSelector(state => state.settings)
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const isPendingFolder =
    !isProfessionalDeputy &&
    (!isDelegateByPD || !isReadonly) &&
    pendingFolders.some(f => f.path === breadcrumb)

  let createForm
  const createFormRef = fr => {
    createForm = fr
  }

  const [addS3Change] = useMutation(createS3Change)

  const navigateTo = (link, e) => {
    e?.preventDefault && e.preventDefault()

    const end = link ? breadcrumb.indexOf(link) + link.length + 1 : 0
    const newBreadcrumb = breadcrumb.substring(0, end)
    setBreadcrumb(newBreadcrumb)
  }

  const handleCreateFolder = () => {
    const { form } = createForm.props
    form.validateFields(async (err, values) => {
      if (err) {
        return
      }

      removeHtmlTags(values)
      setIsSaving(true)
      try {
        const isPending =
          (isProfessionalDeputy || (isDelegateByPD && isReadonly)) &&
          accessLevel === ACCESS_LEVEL.NEED_APPROVAL
        // folderPath is a combination of breadcrumb and folder name
        const folderPath = `${breadcrumb}${values.folderName}/`
        await createFolder(userId, folderPath, masterKey, isPending)
        if (isPending) {
          dispatch(fetchOtherPendingDocuments(userId, masterKey))
        } else {
          dispatch(fetchOtherContacts(userId, masterKey))
        }

        message.success(t('SUCCESSFULLY_CREATED_FOLDER'))
        form.resetFields()
        localStorage.setItem('NotReload', true)
        addS3Change({
          variables: {
            message: 'documents, pendingDocuments',
            userId: userId
          }
        })
        setCreateFolderVisible(false)
      } catch (err) {
        message.error(t('FAILED_TO_CREATE_FOLDER'))
        onError(err)
      } finally {
        setIsSaving(false)
      }
    })
  }

  const handleCancel = () => {
    setCreateFolderVisible(false)
    createForm.props.form.resetFields()
  }

  const handleCreateTextFile = () => {
    if (limitedRecord > limit) {
      showUpgradeSubscriptionPlanConfirm(setSubscriptionModalVisible)
      return
    }
    setEnableCreateFile(true)
  }

  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={() => setCreateFolderVisible(true)}>
        <Icon type="folder" />
        {t('FOLDER')}
      </Menu.Item>
      <Menu.Item key="2" onClick={handleCreateTextFile}>
        <Icon type="file-text" />
        {t('TEXT_FILE')}
      </Menu.Item>
      {!activeFolders.find(folder => folder.isPrivate) && !isReadonly && (
        <Menu.Item
          key="3"
          onClick={() => {
            setCreatePrivateFolderVisible(true)
            setIsCreatePrivateFolder(true)
          }}
        >
          <Icon type="lock" />
          {t('PRIVATE_FOLDER')}
        </Menu.Item>
      )}
    </Menu>
  )

  const handleModeChange = e => {
    setViewMode(e.target.value)
  }

  return (
    <div
      className="file-nav"
      style={{
        padding: '20px 0',
        margin: '0 20px',
        border: 'solid #efefef',
        borderWidth: '1px 0'
      }}
    >
      <div>
        <ResponsiveBreadCrumb
          path={breadcrumb}
          separator=">"
          navigateTo={navigateTo}
          homeLink={
            isStarred ? (
              <Breadcrumb.Item
                href=""
                onClick={e => {
                  setShowStarredDocsOnly(true)
                  navigateTo('', e)
                }}
              >
                <Icon
                  type="star"
                  style={{ fontSize: 16, color: '#FFC54E' }}
                  theme={'filled'}
                />
                <span>{t('FAVOURITES')}</span>
              </Breadcrumb.Item>
            ) : (
              <Breadcrumb.Item href="" onClick={e => navigateTo('', e)}>
                <Icon type="home" />
                <span>{t('HOME')}</span>
              </Breadcrumb.Item>
            )
          }
        />

        <div className="sub-title" style={{ marginLeft: 18 }}>
          <div>
            {foldersCount} {t('FOLDERS').toLowerCase()} & {filesCount}{' '}
            {t('FILES').toLowerCase()}
          </div>
          <div>
            {pendingFoldersCount} {t('PENDING_FOLDES').toLowerCase()} &{' '}
            {pendingFilesCount} {t('PENDING_FILES').toLowerCase()}
          </div>
        </div>
      </div>

      <div className="file-actions">
        {isSearchMode && (
          <span>
            {t('SEARCH_RESULTS')}: <strong>{searchResults.length}</strong>{' '}
            {t('FILES').toLowerCase()}
            <Divider type="vertical" />
          </span>
        )}

        <Dropdown overlay={exportMenu} trigger={['click', 'hover']}>
          <Button>
            {t('EXPORT')} <Icon type="down" />
          </Button>
        </Dropdown>
        <Divider type="vertical" />

        <Popover
          placement="bottomRight"
          title={t('SEARCH')}
          content={searchPopover}
          trigger="click"
        >
          <Button type="default">{t('SEARCH')}</Button>
        </Popover>

        {((!isReadonly && !showStarredDocsOnly && !isProfessionalDeputy) ||
          ((isProfessionalDeputy || (isDelegateByPD && isReadonly)) &&
            !showStarredDocsOnly &&
            breadcrumb?.slice(0, privateFolder?.path.length) !==
              privateFolder?.path &&
            accessLevel &&
            accessLevel !== ACCESS_LEVEL.DENY)) && (
          <>
            <Divider type="vertical" />
            <Button
              disabled={isPendingFolder}
              onClick={() => {
                setFileUploadVisible(true)
              }}
              type="primary"
              icon="cloud-upload"
              className="tour-create-file-button"
            >
              {t('UPLOAD')}
            </Button>
            <Divider type="vertical" />
            <Dropdown
              disabled={isPendingFolder}
              overlay={menu}
              trigger={['click', 'hover']}
            >
              <Button type="primary">
                {t('CREATE_NEW')} <Icon type="down" />
              </Button>
            </Dropdown>
          </>
        )}
        <Divider type="vertical" />
        <Radio.Group defaultValue={viewMode} onChange={handleModeChange}>
          <Radio.Button value="list">
            <Icon type="bars" style={{ fontSize: 16 }} />
          </Radio.Button>
          <Radio.Button value="grid">
            <Icon type="appstore" style={{ fontSize: 16 }} />
          </Radio.Button>
        </Radio.Group>
      </div>
      <CreateFolderModal
        wrappedComponentRef={createFormRef}
        visible={createFolderVisible}
        handleSaveFolder={handleCreateFolder}
        handleCancel={handleCancel}
        folders={activeFolders}
        breadcrumb={breadcrumb}
        isSaving={isSaving}
      />
      <FileModal
        visible={fileUploadVisible}
        setVisible={setFileUploadVisible}
        folderKey={breadcrumb}
        contactDataSource={contactDataSource}
      />
      <CreatePrivateFolderModal
        visible={createPrivateFolderVisible}
        setVisible={setCreatePrivateFolderVisible}
        setIsCreatePrivateFolder={setIsCreatePrivateFolder}
        isCreatePrivateFolder={isCreatePrivateFolder}
      />
      <SubscriptionModal
        visible={subscriptionModalVisible}
        setVisible={setSubscriptionModalVisible}
      />
    </div>
  )
}
