import React, { useState, useContext } from 'react'
import { Form, Alert, Modal } from 'antd'
import AuthContext from '../../contexts/AuthContext'
import CodeInput from '../common/CodeInput'
import Button from '../override/Button'
import { H4, P1 } from '../override/Typography'
import { useTranslation } from 'react-i18next'
import api from '../../lib/api'
import { onError } from '../../lib/sentry'
import { Trans } from 'react-i18next'
function EmailVerificationModal(props) {
  const { form, visible, setVisible, getUserInfo, onVerificationComplete } =
    props

  const { t } = useTranslation()
  const { user } = useContext(AuthContext)
  const { getFieldDecorator } = form
  const [errMsg, setErrMsg] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isResendEmailCode, setIsResendEmailCode] = useState(false)

  const handleSubmit = e => {
    e.preventDefault()
    setErrMsg('')

    form.validateFields((err, values) => {
      if (err) {
        return
      }

      setIsSubmitting(true)
      user.verifyAttribute('email', values.code, {
        onSuccess: function (result) {
          form.resetFields()
          setIsSubmitting(false)
          setVisible(false)
          getUserInfo()
          onVerificationComplete()
          Modal.info({
            content: t('EMAIL_ADDRESS_UPDATED_AND_VERIFIED')
          })
        },

        onFailure: function (err) {
          setIsSubmitting(false)
          setErrMsg(t('FAILED_TO_VERIFY_EMAIL_ADDRESS'))
        }
      })
    })
  }

  const handleResend = async () => {
    setIsResendEmailCode(true)
    try {
      await api.resendEmailVerification(
        JSON.stringify({
          userId: user.username,
          accessToken: user.signInUserSession.accessToken.jwtToken
        })
      )
      setIsResendEmailCode(false)
    } catch (err) {
      onError(err)
    }
  }

  return (
    <Modal visible={visible} footer={null} closable={false}>
      <Form layout="vertical" onSubmit={handleSubmit}>
        {errMsg && (
          <Alert
            message={errMsg}
            type="error"
            closable
            style={{ marginBottom: 16 }}
          />
        )}
        <H4>{t('EMAIL_ADDRESS_VERIFICATION')}</H4>
        <P1 style={{ marginBottom: '1em' }}>
          {t('ENTER_THE_VERIFICATION_CODE')}
        </P1>
        <CodeInput
          getFieldDecorator={getFieldDecorator}
          style={{
            marginRight: 10,
            width: 'calc(100% - 60px)'
          }}
          extra={
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              icon="right"
              style={{ fontSize: 14 }}
              loading={isSubmitting}
            />
          }
        />
        <span>
          <Trans i18nKey="DID_NOT_RECEIVE_THE_EMAIL"></Trans>
        </span>
        <Button
          type="link"
          style={{ padding: '0 0 0 0.5em' }}
          onClick={handleResend}
          loading={isResendEmailCode}
        >
          <Trans i18nKey={'RESEND_EMAIL'}></Trans>
        </Button>
      </Form>
    </Modal>
  )
}

const WrappedEmailVerificationModal = Form.create({
  name: 'EmailVerificationModal'
})(EmailVerificationModal)
export default WrappedEmailVerificationModal
