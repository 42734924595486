import React, { useState, useContext } from 'react'
import { Modal, Spin, Alert, message, Tooltip, Button } from 'antd'
//import { StringResources } from '../../share/StringResources'
import { CardElement, injectStripe } from 'react-stripe-elements'
import api from '../../lib/api'
import config from '../../config'
import mastercard from '../../assets/ic/payment/mastercard.svg'
import visa from '../../assets/ic/payment/visa.svg'
import amex from '../../assets/ic/payment/amex.svg'
import diners from '../../assets/ic/payment/diners.svg'
import discover from '../../assets/ic/payment/discover.svg'
import jcb from '../../assets/ic/payment/jcb.svg'
import unionpay from '../../assets/ic/payment/unionpay.svg'
import { ThemeContext } from 'styled-components'
import { onError } from '../../lib/sentry'
import { useTranslation } from 'react-i18next'
import { destroyAllDbs } from '../../lib/pouchDb'
import AuthContext from '../../contexts/AuthContext'

const acceptedCards = [
  {
    name: 'Visa',
    url: visa
  },
  {
    name: 'Mastercard',
    url: mastercard
  },
  {
    name: 'American Express',
    url: amex
  },
  {
    name: 'Diners Club',
    url: diners
  },
  {
    name: 'Discover',
    url: discover
  },
  {
    name: 'JCB',
    url: jcb
  },
  {
    name: 'UnionPay',
    url: unionpay
  }
]

function CardModal(props) {
  const {
    visible,
    setVisible,
    customerId,
    handleOkComplete,
    handleCancelComplete,
    stripe,
    isRequired = false
  } = props
  const [isCardComplete, setIsCardComplete] = useState(false)
  const [element, setElement] = useState({})
  const [loading, setLoading] = useState(true)
  const [isSaving, setIsSaving] = useState(false)
  const theme = useContext(ThemeContext)
  const { t } = useTranslation()
  const { user } = useContext(AuthContext)

  const handleOk = async () => {
    if (!customerId) return

    try {
      setIsSaving(true)
      const { token } = await stripe.createToken()

      const res = await api.createCard(customerId, token.id)
      if (res.data.message) throw Error(res.data.message)

      message.success(t('SUCCESSFULLY_ADDED_CARD'))
      element.clear()
      setIsSaving(false)
      setVisible(false)

      handleOkComplete && handleOkComplete()
    } catch (e) {
      message.error(e.message || t('FAILED_TO_ADD_CARD'))
      setIsSaving(false)
      onError(e)
    }
  }

  const handleCancel = () => {
    element.clear()
    setVisible(false)
    handleCancelComplete && handleCancelComplete()
  }

  const handleLogout = () => {
    user.signOut()
    destroyAllDbs()
    localStorage.clear()
    window.location.reload()
  }

  const onReady = el => {
    setElement(el)
    setLoading(false)
  }

  return (
    <Modal
      visible={visible}
      title={t('ADD_A_CARD')}
      onCancel={!isRequired ? handleCancel : null}
      footer={[
        <Button
          key="cancel"
          type="default"
          onClick={isRequired ? handleLogout : handleCancel}
        >
          {isRequired ? t('LOGOUT_AND_ADD_CARD_LATER') : t('CANCEL')}
        </Button>,
        <Button
          key="save"
          type="primary"
          onClick={handleOk}
          loading={isSaving}
          disabled={!isCardComplete}
        >
          {t('SAVE')}
        </Button>
      ]}
      closable={!isRequired}
      maskClosable={!isRequired}
      zIndex={2000}
    >
      <Spin spinning={loading}>
        <h4>{t('SUPPORTED_CARD_TYPES')}</h4>
        <div
          style={{
            marginBottom: 15,
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(60px, 1fr))',
            gridGap: '5px'
          }}
        >
          {acceptedCards.map(ac => (
            <Tooltip placement="top" title={ac.name} key={ac.name}>
              <img
                src={ac.url}
                alt={ac.name}
                width={'100%'}
                height={40}
                style={{
                  border: `1px solid ${theme.dark3}`,
                  borderRadius: 4,
                  padding: 5
                }}
                title={ac.name}
              />
            </Tooltip>
          ))}
        </div>
        {config.env !== 'prod' && (
          <Alert
            style={{
              marginBottom: 20
            }}
            description={
              <div>
                {t('TESTING_NOTE')} <b>4000007020000003</b>
              </div>
            }
            type="info"
            showIcon
          />
        )}
        <CardElement
          onChange={e => setIsCardComplete(e.complete)}
          onReady={onReady}
        />
        {!!isRequired && (
          <div style={{ marginTop: 20, fontSize: 11 }}>
            *If you used a valid promo code that entitles you free months/years,
            your credit card will not be charged during that period. Regular
            fees will apply after the free period, unless you choose to cancel
            your subscription before the end of the free period.
          </div>
        )}
      </Spin>
    </Modal>
  )
}

export default injectStripe(CardModal)
