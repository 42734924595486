import React, { useState } from 'react'
import { debounce } from 'lodash'
import { Input } from 'antd'
//import { StringResources } from '../../share/StringResources'
import FormItem from '../override/FormItem'
import {
  validateToConfirmPassword,
  compareToOldPassword
} from '../../share/formHelpers'
import CustomIcon from '../override/Icon'
import {
  NUMBER_REGEX,
  SPECIAL_CHAR_REGEX,
  UPPERCASE_REGEX,
  LOWERCASE_REGEX
} from '../../share/Constants'
import styled from 'styled-components'
import { useTranslation, Trans } from 'react-i18next'

const passwordRules = {
  isLongEnough: <Trans i18nKey="iS_LONG_ENOUGHT"></Trans>,
  hasNumber: <Trans i18nKey="HAS_NUMBER"></Trans>,
  hasSpecialChar: <Trans i18nKey="HAS_SPECIAL_CHAR"></Trans>,
  hasUppercase: <Trans i18nKey="HAS_UPPERCASE"></Trans>,
  hasLowercase: <Trans i18nKey="HAS_LOWERCASE"></Trans>
}

const PasswordInputWithRules = props => {
  const { form, password, title, confirmDirty, className } = props
  const { getFieldDecorator } = form
  const [passwordValidation, setPasswordValidation] = useState({})
  const [passwordRulesVisible, setPasswordRulesVisible] = useState(false)
  const { t } = useTranslation()

  const validatePasswordRules = (rule, value, callback) => {
    // TODO: string length should be checked more properly (e.g. multiple spaces)
    const isLongEnough = value && value.length >= 8

    const hasNumber = NUMBER_REGEX.test(value)
    const hasSpecialChar = SPECIAL_CHAR_REGEX.test(value)
    const hasUppercase = UPPERCASE_REGEX.test(value)

    // testing undefined also return true, so need to convert to empty string
    const hasLowercase = LOWERCASE_REGEX.test(value || '')

    setPasswordValidation({
      isLongEnough,
      hasNumber,
      hasSpecialChar,
      hasUppercase,
      hasLowercase
    })

    debounce(
      callback,
      800
    )(
      !value ||
        (isLongEnough &&
          hasNumber &&
          hasSpecialChar &&
          hasUppercase &&
          hasLowercase)
        ? undefined
        : t('INVALID_PASSWORD')
    )
  }

  return (
    <FormItem label={title} hasFeedback className={className}>
      {getFieldDecorator('password', {
        initialValue: password,
        rules: [
          {
            required: true,
            message: t('INPUT_PASSWORD_MSG')
          },
          {
            validator: (rule, value, callback) =>
              validateToConfirmPassword(
                rule,
                value,
                callback,
                form,
                confirmDirty
              )
          },
          {
            validator: validatePasswordRules
          },
          {
            validator: (rule, value, callback) =>
              compareToOldPassword(rule, value, callback, form)
          }
        ]
      })(
        <Input.Password
          onFocus={() => setPasswordRulesVisible(true)}
          onBlur={() => setPasswordRulesVisible(false)}
          autoComplete="new-password"
        />
      )}
      {passwordRulesVisible && (
        <div className="password-rules">
          {Object.keys(passwordRules).map(key => (
            <div
              key={key}
              className={`password-rule ${
                passwordValidation[key] ? 'valid' : ''
              }`}
            >
              <CustomIcon type="valid" />
              {passwordRules[key]}
            </div>
          ))}
        </div>
      )}
    </FormItem>
  )
}

const StyledPasswordInputWithRules = styled(PasswordInputWithRules)`
  .password-rules {
    border: 1px solid ${props => props.theme.dark3};
    padding: 20px;
    border-radius: 5px;
    z-index: 99;
    box-shadow: ${props => props.theme.boxShadow};
    position: absolute;
    background: ${props => props.theme.white};
    top: 40px;
    left: 0;
  }
  .password-rule {
    color: ${props => props.theme.dark2};
    padding: 3px 0;
    &.valid {
      color: ${props => props.theme.primary};
    }
    .anticon {
      margin-right: 10px;
    }
  }
  .ant-form-item-control.has-error {
    .password-rules {
      top: 55px;
    }
  }
`

export default StyledPasswordInputWithRules
