import React, { useContext, useEffect, useState } from 'react'
import { Avatar, Checkbox, Empty, Modal, Alert, message } from 'antd'
import SimpleList from '../override/SimpleList'
import { ThemeContext } from 'styled-components'
import { groupBy } from 'lodash'
import { CONTACT_TYPES } from '../../share/Constants'
import { onError } from '../../lib/sentry'
import { useTranslation } from 'react-i18next'

const ContactListPreviewModal = ({
  visible,
  contacts = [],
  invalidRecordsCount,
  handleOk,
  handleCancel
}) => {
  const [selectedContactIds, setSelectedContactIds] = useState(contacts)
  const [isSaving, setIsSaving] = useState(false)
  const theme = useContext(ThemeContext)
  const { t } = useTranslation()

  const colors = [
    theme.secondaryYellow,
    theme.secondaryRed,
    theme.secondaryBlue,
    theme.secondaryOrange,
    theme.secondaryLightGreen,
    theme.secondaryCyan,
    theme.secondaryGreenMedium,
    theme.secondaryPurple
  ]

  const groupRecordsByFirstChar = records => {
    const decoratedContacts = records.map((c, index) => ({
      ...c,
      shortName:
        c.type === CONTACT_TYPES.INDIVIDUAL
          ? c.givenName.charAt(0).toUpperCase() +
            c.surName.charAt(0).toUpperCase()
          : c.displayName.charAt(0).toUpperCase(),
      background: colors[index % colors.length]
    }))

    const contactsGroupedByFirstChar = groupBy(decoratedContacts, item =>
      item.name.charAt(0).toUpperCase()
    )

    const groupData = Object.keys(contactsGroupedByFirstChar)
      .map(key => ({
        key,
        dataByKey: contactsGroupedByFirstChar[key]
      }))
      .sort((a, b) => a.key.localeCompare(b.key))

    return groupData
  }

  const groupData = groupRecordsByFirstChar(contacts)

  const isSelected = contact => {
    return selectedContactIds.includes(contact._id)
  }

  const select = contact => {
    setSelectedContactIds([...selectedContactIds, contact._id])
  }

  const deselect = contact => {
    setSelectedContactIds(selectedContactIds.filter(id => id !== contact._id))
  }

  const toggleItem = contact => {
    isSelected(contact) ? deselect(contact) : select(contact)
  }

  useEffect(() => {
    setSelectedContactIds(contacts.map(c => c._id))
  }, [contacts])

  return (
    <Modal
      title={t('SELECT_CONTACTS_TO_IMPORT')}
      visible={visible}
      onOk={async () => {
        try {
          setIsSaving(true)
          await handleOk(
            contacts.filter(contact => selectedContactIds.includes(contact._id))
          )
        } catch (err) {
          onError(err)
          message.error(t('FAILED_TO_IMPORT_CONTACTS'))
        } finally {
          setIsSaving(false)
        }
      }}
      onCancel={handleCancel}
      okButtonProps={{ loading: isSaving }}
    >
      {invalidRecordsCount > 0 && (
        <Alert
          type="warning"
          message={`${invalidRecordsCount} ${t(
            'INVALID_RECORDS_CANT_BE_IMPORTED'
          )}`}
          showIcon
        />
      )}
      {groupData.length ? (
        <>
          <div style={{ textAlign: 'right' }}>
            <span>{t('ALL')}</span>
            <Checkbox
              style={{ padding: 10 }}
              checked={contacts.every(contact => !!isSelected(contact))}
              indeterminate={
                !contacts.every(contact => !!isSelected(contact)) &&
                contacts.some(contact => !!isSelected(contact))
              }
              onChange={e => {
                e.target.checked
                  ? setSelectedContactIds(contacts.map(x => x._id))
                  : setSelectedContactIds([])
              }}
            />
          </div>
          {groupData.map(group => (
            <div key={group.key}>
              <div
                style={{
                  backgroundColor: theme.dark4,
                  borderRadius: 3,
                  padding: '6px 12px',
                  color: theme.dark2
                }}
              >
                {group.key}
              </div>
              <div style={{ padding: 10 }}>
                <SimpleList
                  data={group.dataByKey}
                  itemKey="_id"
                  icon={contact => (
                    <Avatar
                      size={36}
                      style={{
                        backgroundColor: contact.background,
                        fontSize: 14,
                        minWidth: 36
                      }}
                    >
                      {contact.shortName}
                    </Avatar>
                  )}
                  mainInfo={contact => contact.name}
                  subInfo={contact =>
                    contact.emails &&
                    !!contact.emails.length &&
                    contact.emails[0].value
                  }
                  actionVisible={true}
                  action={contact => {
                    return (
                      <Checkbox
                        checked={!!isSelected(contact)}
                        onChange={() => {
                          toggleItem(contact)
                        }}
                      />
                    )
                  }}
                  setSelectedItem={id =>
                    toggleItem(contacts.find(x => x._id === id))
                  }
                />
              </div>
            </div>
          ))}
        </>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
          }}
        >
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={t('NO_CONTACTS_TO_IMPORT')}
          />
        </div>
      )}
    </Modal>
  )
}

export default ContactListPreviewModal
