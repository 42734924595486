import React, { useState, useEffect, useContext } from 'react'
import {
  CORE_TYPES,
  ASSET_TYPES,
  LIABILITY_TYPES,
  HOLDING_TYPES,
  ASSET_TYPES_WITHOUT_PURCHASE_DATE,
  ASSET_TYPES_WITHOUT_DISPOSAL_DATE,
  TYPE_OF_TRUST_INTEREST_PLURAL_MAP
} from '../../share/Constants'
import { Row, Col, Divider, Select, Spin, Tag } from 'antd'
import { H4, P, Span, B } from '../../components/override/Typography'
import {
  renderDate,
  currencyFormat,
  purchaseDateLabel,
  valuationDateLabel
} from '../../share/helpers'
//import { StringResources } from '../../share/StringResources'
import VaultContext from '../../contexts/VaultContext'
import { getRecordVersions } from '../../lib/pouchDb'
import { DATE_TIME_FORMAT } from '../../share/Constants'
import { getRecords } from '../../lib/pouchDb'
import moment from 'moment'
//import { uniq } from 'lodash'
import { onError } from '../../lib/sentry'
import { useTranslation } from 'react-i18next'
import AuthContext from '../../contexts/AuthContext'

function Field({ title, value }) {
  return (
    <Row style={{ fontSize: 12, padding: '3px 0' }} gutter={8}>
      <Col span={12}>
        <Span>{title}</Span>
      </Col>
      <Col span={12} style={{ textAlign: 'right' }}>
        <B>{value}</B>
      </Col>
    </Row>
  )
}

export default function AssetLiabilityInformation({ record, extra }) {
  const { userId, masterKey } = useContext(VaultContext)
  const { isProfessionalDeputy } = useContext(AuthContext)
  const [versions, setVersions] = useState([])
  const [version, setVersion] = useState({})
  const [versionId, setVersionId] = useState()
  const [linkedContacts, setLinkedContacts] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    async function fetchData() {
      if (record && record._id) {
        try {
          await getRecordVersions(
            'assetsLiabilitiesHistory',
            userId,
            record._id,
            setVersions,
            setIsLoading,
            masterKey
          )

          const allLinkedContactIds = [
            ...(record.tenant || []),
            ...(record.insuranceAdvisor || []),
            ...(record.beneficiaries || []),
            ...(record.borrower ? [record.borrower] : []),
            ...(record.nameAssured ? [record.nameAssured] : []),
            ...(record.company ? [record.company] : []),
            ...(record.lender ? [record.lender] : []),
            ...(record.typeOfTrustInterest?.values || [])
          ]

          if (allLinkedContactIds.length) {
            const linkedContacts = [
              ...(await getRecords(userId, 'contacts', masterKey)).filter(c =>
                allLinkedContactIds.includes(c._id)
              ),
              ...(
                await getRecords(userId, 'pendingContacts', masterKey)
              ).filter(c => allLinkedContactIds.includes(c._id))
            ]

            setLinkedContacts(linkedContacts)
          }
        } catch (e) {
          onError(e)
        }
      }
    }

    if (masterKey) {
      fetchData()
    }
  }, [userId, record, masterKey, isProfessionalDeputy])

  useEffect(() => {
    if (versions.length) {
      setVersionId(versions[0]._id)
      setVersion(versions[0])
    }
  }, [versions])

  const handleVersionChange = versionId => {
    setVersionId(versionId)
    const selectedVersion = versions.find(version => version._id === versionId)
    setVersion(selectedVersion)
  }

  const renderItemsToString = items =>
    linkedContacts
      .filter(contact => items && items.includes(contact._id))
      .map(contact => contact.name)
      .join(', ')

  return (
    <Spin spinning={isLoading}>
      <Select
        placeholder="Version"
        style={{ width: '100%' }}
        onChange={handleVersionChange}
        value={versionId}
      >
        {versions.map((version, index) => (
          <Select.Option key={index} value={version._id}>
            Version: v{versions.length - index} -{' '}
            {moment(version.time).format(DATE_TIME_FORMAT)}
          </Select.Option>
        ))}
      </Select>
      <Divider />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 10
        }}
      >
        <H4>{t('INFORMATION')}</H4>
        {extra}
      </div>
      <P className="pre-area">{version.description}</P>
      <Divider />
      <Field title={t('TYPE')} value={version.subType || version.type} />
      <Field
        title={
          version.subType === ASSET_TYPES.CRYPTO
            ? t('CRYPTO_CURRENCY')
            : t('CURRENCY')
        }
        value={version.currency}
      />

      <Field
        title={valuationDateLabel(version.subType)}
        value={renderDate(version.valuationDate)}
      />
      <Field
        title={t('Valuation date in basecurrency')}
        value={renderDate(version.valuationDateInBaseCurrency)}
      />

      {version.type === CORE_TYPES.ASSET && (
        <>
          {!ASSET_TYPES_WITHOUT_PURCHASE_DATE.includes(version.subType) && (
            <Field
              title={purchaseDateLabel(version.subType)}
              value={renderDate(version.purchaseDate)}
            />
          )}
          {!ASSET_TYPES_WITHOUT_DISPOSAL_DATE.includes(version.subType) && (
            <Field
              title={
                version.subType === ASSET_TYPES.LOAN_TO_OTHERS
                  ? t('MATURITY_DATE')
                  : t('DISPOSAL_DATE')
              }
              value={renderDate(version.disposalDate)}
            />
          )}
          <Field
            title={
              version.subType === ASSET_TYPES.CASH ||
              version.subType === ASSET_TYPES.CRYPTO
                ? t('BALANCE_AMOUNT')
                : t('VALUATION_ASSET_CURRENCY')
            }
            value={currencyFormat(version.valuationInAssetCurrency)}
          />
          <Field
            title={t('VALUATION_BASE_CURRENCY')}
            value={currencyFormat(version.valuationInBaseCurrency)}
          />
        </>
      )}
      {version.type === CORE_TYPES.LIABILITY && (
        <>
          <Field
            title={t('START_DATE')}
            value={renderDate(version.startDate)}
          />
          <Field
            title={t('MATURITY_DATE')}
            value={renderDate(version.maturityDate)}
          />
          <Field
            title={
              version.subType === LIABILITY_TYPES.PROPERTY_LOAN ||
              version.subType === LIABILITY_TYPES.VEHICLE_LOAN
                ? t('AMOUNT_BORROWED')
                : t('OUTSTANDING_VALUE_LIABILITY_CURRENCY')
            }
            value={currencyFormat(version.outstandingValueInLiabilityCurrency)}
          />
          <Field
            title={t('OUTSTANDING_VALUE_BASE_CURRENCY')}
            value={currencyFormat(version.outstandingValueInBaseCurrency)}
          />
        </>
      )}
      {version.subType !== ASSET_TYPES.CASH && (
        <Field
          title={t('PERCENTAGE_OWNERSHIP')}
          value={
            version.percentageOwnership && `${version.percentageOwnership}%`
          }
        />
      )}

      {version.subType === ASSET_TYPES.CASH && (
        <>
          <Field title={t('BANK')} value={version.bank} />
          <Field title={t('ACCOUNT_NUMBER')} value={version.accountNumber} />
          <Field title={t('ACCOUNT_TYPE')} value={version.accountType} />
        </>
      )}
      {version.subType === ASSET_TYPES.BROKERAGE_ACCOUNT && (
        <>
          <Field title={t('BROKERAGE_NAME')} value={version.brokerageName} />
          <Field title={t('ACCOUNT_NUMBER')} value={version.accountNumber} />
          <Field title={t('ACCOUNT_TYPE')} value={version.accountType} />
          <Field
            title={t('JOINT_ACCOUNT')}
            value={version.jointAccount ? 'Yes' : 'No'}
          />
        </>
      )}
      {version.subType === ASSET_TYPES.VEHICLE && (
        <Field
          title={t('VEHICLE_REGISTRATION_NUMBER')}
          value={version.vehicleRegistrationNumber}
        />
      )}

      {(version.subType === ASSET_TYPES.EQUITY_LISTED ||
        version.subType === ASSET_TYPES.EQUITY_UNLISTED) && (
        <>
          <Field title={t('PRICE')} value={version.price} />
          <Field title={t('NUMBER_OF_SHARES')} value={version.quantity} />
        </>
      )}

      {version.subType === ASSET_TYPES.EQUITY_LISTED && (
        <Field title={t('EXCHANGE')} value={version.exchange} />
      )}

      {version.subType === ASSET_TYPES.EQUITY_UNLISTED && (
        <>
          <Field
            title={t('COMPANY')}
            value={renderItemsToString([version.company])}
          />
          <Field title={t('CLASS_OF_SHARES')} value={version.classOfShares} />
          <Field
            title={t('SHARE_CERTIFICATE_NUMBER')}
            value={version.shareCertificateNumber}
          />
          <Field
            title={t('PERCENTAGE_OF_SHARES')}
            value={
              version.percentageOfShares && `${version.percentageOfShares}%`
            }
          />
        </>
      )}

      {version.subType === ASSET_TYPES.PROPERTY && (
        <>
          <Field title={t('BUILDING_TYPE')} value={version.buildingType} />
          <Field
            title={t('BUILD_DATE')}
            value={renderDate(version.buildDate)}
          />
          <Field
            title={t('FLOOR_SIZE')}
            value={
              version.floorSize?.value &&
              `${version.floorSize.value} ${version.floorSize.unit}`
            }
          />
          <Field title={t('PROPERTY_ADDRESS')} value={version.address} />
          <Field title={t('HOLDING_TYPE')} value={version.holdingType} />
          {version.holdingType === HOLDING_TYPES.LEASEHOLD && (
            <Field
              title={t('LEASEHOLD_DURATION')}
              value={version.leaseholdDuration}
            />
          )}
          <Field
            title={t('TENANT')}
            value={renderItemsToString(version.tenant)}
          />
          <Field
            title={t('RENT_AMOUNT')}
            value={
              version.rentAmount?.value &&
              `${currencyFormat(version.rentAmount.value)} ${
                version.rentAmount.unit
              }`
            }
          />
          <Field
            title={t('RENTAL_START_DATE')}
            value={renderDate(version.rentalStartDate)}
          />
          <Field
            title={t('RENTAL_END_DATE')}
            value={renderDate(version.rentalEndDate)}
          />
        </>
      )}

      {version.subType === ASSET_TYPES.LOAN_TO_OTHERS && (
        <Field
          title={t('BORROWER')}
          value={renderItemsToString([version.borrower])}
        />
      )}
      {version.subType === LIABILITY_TYPES.LOAN_FROM_OTHERS && (
        <>
          <Field
            title={t('LENDER')}
            value={renderItemsToString([version.lender])}
          />
          <Field
            title={t('SECURITY_COLLATERAL')}
            value={version.securityCollateral}
          />
        </>
      )}
      {(version.subType === LIABILITY_TYPES.LOAN_FROM_OTHERS ||
        version.subType === ASSET_TYPES.LOAN_TO_OTHERS) && (
        <>
          <Field
            title={t('INTEREST_RATE')}
            value={
              version.interestRate?.value &&
              `${currencyFormat(version.interestRate.value)}% ${
                version.interestRate.unit
              }`
            }
          />
          <Field
            title={t('LOAN_PERIOD')}
            value={
              version.loanPeriod?.value &&
              `${version.loanPeriod.value} ${version.loanPeriod.unit}`
            }
          />
          <Field
            title={t('NUMBER_OF_REPAYMENTS')}
            value={version.numberOfRepayments}
          />
        </>
      )}

      {version.subType === ASSET_TYPES.INSURANCE && (
        <>
          <Field
            title={t('LIFE_ASSURED')}
            value={renderItemsToString([version.nameAssured])}
          />
          <Field title={t('TYPE_OF_POLICY')} value={version.typeOfPolicy} />
          <Field
            title={t('SUM_ASSURED_IN_ASSET_CURRENCY')}
            value={currencyFormat(version.sumAssuredInAssetCurrency)}
          />
          <Field
            title={t('SUM_ASSURED_IN_BASE_CURRENCY')}
            value={currencyFormat(version.sumAssuredInBaseCurrency)}
          />
          <Field
            title={t('PREMIUM')}
            value={
              version.premium?.value &&
              `${currencyFormat(version.premium.value)} ${version.premium.unit}`
            }
          />
          <Field title={t('INSURER')} value={version.insurer} />
          <Field
            title={t('POLICY_OWNER')}
            value={renderItemsToString([version.policyOwner])}
          />
          <Field title={t('POLICY_NUMBER')} value={version.policyNumber} />
          <Field
            title={t('ENTITLEMENT')}
            value={version.entitlement && `${version.entitlement}%`}
          />
          <Field
            title={t('INSURANCE_ADVISOR')}
            value={renderItemsToString(version.insuranceAdvisor)}
          />
          <Field
            title={t('BENEFICIARIES')}
            value={renderItemsToString(version.beneficiaries)}
          />
          <Field title={t('OTHER_NOTES')} value={version.otherNotes} />
        </>
      )}
      {version.subType === ASSET_TYPES.RETIREMENT_ACCOUNT && (
        <Field
          title={t('RETIREMENT_ACCOUNT_TYPE')}
          value={version.retirementAccountType}
        />
      )}
      {version.subType === ASSET_TYPES.CRYPTO && (
        <>
          <Field title={t('WALLET_PROVIDER')} value={version.cryptoExchange} />
          <Field title={t('WALLET_ADDRESS')} value={version.walletAddress} />
        </>
      )}

      {version.subType === ASSET_TYPES.TRUST && (
        <>
          <Field
            title={t('TRUST_REVOCABILITY')}
            value={version.trustRevocability}
          />
          <Field title={t('TRUST_TYPE')} value={version.trustType} />
          {version.typeOfTrustInterest?.key && (
            <>
              <Field
                title={t('TYPE_OF_TRUST_INTEREST')}
                value={version.typeOfTrustInterest.key}
              />
              <Field
                title={
                  TYPE_OF_TRUST_INTEREST_PLURAL_MAP[
                    version.typeOfTrustInterest.key
                  ] || version.typeOfTrustInterest.key
                }
                value={renderItemsToString(
                  version.typeOfTrustInterest?.values || []
                )}
              />
            </>
          )}
        </>
      )}
      {version.subType === ASSET_TYPES.OTHER_ASSETS && (
        <Field title={t('OTHER_ASSET_TYPE')} value={version.otherAssetType} />
      )}

      {version.subType === LIABILITY_TYPES.PROPERTY_LOAN && (
        <>
          <Field
            title={t('LENDER')}
            value={renderItemsToString([version.lender])}
          />
          <Field
            title={t('INTEREST_RATE')}
            value={
              version.interestRate?.value &&
              `${currencyFormat(version.interestRate.value)}% ${
                version.interestRate.unit
              }`
            }
          />
          <Field
            title={t('MONTHLY_PAYMENT')}
            value={currencyFormat(version.monthlyPayment)}
          />
          <Field
            title={t('TYPE_OF_INTEREST_RATE')}
            value={version.typeOfInterestRate}
          />
          <Field title={t('ACCOUNT_NUMBER')} value={version.accountNumber} />
          <Field
            title={t('LOAN_PERIOD')}
            value={
              version.loanPeriod?.value &&
              `${version.loanPeriod.value} ${version.loanPeriod.unit}`
            }
          />
          <Field
            title={t('NO_OF_YEARS_REPAYMENT_COMPLETED')}
            value={version.yearOfLoan}
          />
          <Field title={t('PROPERTY_ADDRESS')} value={version.address} />
        </>
      )}
      {version.subType === LIABILITY_TYPES.VEHICLE_LOAN && (
        <>
          <Field
            title={t('LENDER')}
            value={renderItemsToString([version.lender])}
          />
          <Field
            title={t('INTEREST_RATE')}
            value={
              version.interestRate?.value &&
              `${currencyFormat(version.interestRate.value)}% ${
                version.interestRate.unit
              }`
            }
          />
          <Field
            title={t('LOAN_PERIOD')}
            value={
              version.loanPeriod?.value &&
              `${version.loanPeriod.value} ${version.loanPeriod.unit}`
            }
          />
          <Field
            title={t('VEHICLE_REGISTRATION_NUMBER')}
            value={version.vehicleRegistrationNumber}
          />
          <Field
            title={t('SECURITY_COLLATERAL')}
            value={version.securityCollateral}
          />
        </>
      )}
      {version.subType === LIABILITY_TYPES.CREDIT_CARD && (
        <>
          <Field
            title={t('EXPIRY_DATE')}
            value={renderDate(version.expiryDate)}
          />
          <Field
            title={t('PAYMENT_DUE_DATE')}
            value={renderDate(version.paymentDueDate)}
          />
          <Field
            title={t('RECURRING_PAYMENT_DUE_DAY')}
            value={version.recurringPaymentDueDay}
          />
          <Field title={t('ISSUING_BANK')} value={version.issuingBank} />
          <Field
            title={t('CREDIT_LIMIT')}
            value={currencyFormat(version.creditLimit)}
          />
          <Field
            title={t('INTEREST_RATE')}
            value={
              version.interestRate?.value &&
              `${version.interestRate.value}% ${version.interestRate.unit}`
            }
          />
        </>
      )}
      <Field
        title={t('TAGS')}
        value={version.tags?.map(tag => (
          <Tag key={tag}>{tag}</Tag>
        ))}
      />
      <Field
        title={t('INCLUDE_VAULE_IN_NETWORTH')}
        value={version.includeValueInNetWorth === false ? 'No' : 'Yes'}
      />
    </Spin>
  )
}
