import React, { useContext } from 'react'
import { Workbook } from 'exceljs'
import { saveAs } from 'file-saver'
import { useSelector } from 'react-redux'
import Button from '../override/Button'
import {
  CORE_TYPES,
  DATE_FORMAT,
  EXPORT_DATE_TIME_FORMAT,
  ASSET_TYPES,
  LIABILITY_TYPES,
  CURRENCY_NUM_FMT,
  CURRENCY_WITH_SYMBOL_NUM_FMT
} from '../../share/Constants'
import { balanceSheetColumns } from './assetLiabilityHelpers'
import { Empty, Divider, Dropdown, Menu, Icon } from 'antd'
import { currencyFormat } from '../../share/helpers'
import VaultContext from '../../contexts/VaultContext'
import logo from '../../assets/dark.png'
import moment from 'moment'
import jsPDF from 'jspdf'
import { useMediaQuery } from '@material-ui/core'
import { ThemeContext } from 'styled-components'
import { useTranslation } from 'react-i18next'
import CustomTable from '../override/CustomTable'

const groupBySubtype = (sourceRecords, subtypes) => {
  return [...sourceRecords]
    .sort((a, b) => a.title.localeCompare(b.title))
    .reduce(
      (source, item) => {
        const subTypeGroupIndex = source.findIndex(
          s => s.title === item.subType
        )
        const subtypeGroup = source[subTypeGroupIndex]
        const newSubtypeGroup = {
          _id: subtypeGroup._id,
          title: subtypeGroup.title,
          subType: subtypeGroup.subType,
          children: [...subtypeGroup.children, item]
        }
        return Object.assign([...source], {
          [subTypeGroupIndex]: newSubtypeGroup
        })
      },
      Object.values(subtypes).map(at => ({
        _id: at,
        title: at,
        subType: at,
        children: []
      }))
    )
    .filter(group => group.children?.length)
}

const currencyColumnStyle = {
  font: {
    name: 'Arial',
    size: 8
  },
  alignment: {
    vertical: 'middle',
    horizontal: 'right'
  }
}

const titleStyle = fontSize => ({
  alignment: {
    vertical: 'middle',
    horizontal: 'center'
  },
  font: {
    name: 'Arial',
    size: fontSize,
    bold: true
  }
})

const columnHeaderStyle = {
  border: {
    bottom: { style: 'thin' }
  },
  font: {
    size: 9,
    name: 'Arial',
    bold: true
  },
  alignment: {
    vertical: 'middle',
    horizontal: 'center'
  }
}

const totalCellStyle = {
  border: {
    top: { style: 'thin' }
  },
  alignment: {
    vertical: 'middle',
    horizontal: 'right'
  },
  font: {
    bold: true,
    name: 'Arial',
    size: 8
  }
}

const addDataToWs = (
  balanceSheetData,
  registryDate,
  baseCurrency,
  worksheet,
  imageId,
  exportDate,
  fullName,
  isDualCurrency = false
) => {
  worksheet.headerFooter.oddFooter =
    worksheet.headerFooter.evenFooter = `&L&"Arial"&14&Bvaultbox &R&I${exportDate}`
  worksheet.columns = ['A', 'B', 'C', 'D', 'E'].map(key => ({
    key,
    width: 18
  }))

  worksheet.getColumn('A').font = {
    bold: true,
    name: 'Arial',
    size: 8
  }
  worksheet.getColumn('D').style = currencyColumnStyle
  worksheet.getColumn('E').style = currencyColumnStyle

  worksheet.addRows([[''], ['']])
  worksheet.addImage(imageId, {
    tl: { col: 2, row: 0.4 },
    br: { col: 3, row: 1.6 }
  })

  worksheet.addRow([fullName]).getCell(1).style = titleStyle(14)

  worksheet.addRow(['Balance Sheet']).getCell(1).style = titleStyle(14)

  worksheet
    .addRow([`As of ${registryDate.format(DATE_FORMAT)}`])
    .getCell(1).style = titleStyle(10)

  worksheet.mergeCells('A1', 'E2')
  worksheet.mergeCells('A3', 'E3')
  worksheet.mergeCells('A4', 'E4')
  worksheet.mergeCells('A5', 'E5')

  worksheet.addRow({ E: baseCurrency }).getCell(5).style = {
    alignment: {
      vertical: 'middle',
      horizontal: 'center'
    },
    font: {
      name: 'Arial',
      italic: true,
      size: 11
    }
  }

  const headerRow = worksheet.addRow({ E: 'Total' })
  headerRow.getCell(5).style = columnHeaderStyle
  if (isDualCurrency) headerRow.getCell(4).style = columnHeaderStyle

  let hasExcludedRecord = false
  balanceSheetData.forEach(bsd => {
    worksheet.addRow([bsd.type === CORE_TYPES.ASSET ? 'Assets' : 'Liabilities'])

    if (bsd.data?.length) {
      bsd.data.forEach(d => {
        worksheet.addRow([d.subType]).getCell(1).alignment = { indent: 1 }

        if (d.children?.length) {
          d.children.forEach(record => {
            const recordRow = worksheet.addRow({
              A: record.title,
              D: isDualCurrency
                ? bsd.type === CORE_TYPES.ASSET
                  ? `${record.currency} ${currencyFormat(
                      record.valuationInAssetCurrency
                    )}`
                  : `${record.currency} ${currencyFormat(
                      record.outstandingValueInLiabilityCurrency
                    )}`
                : '',
              E:
                (bsd.type === CORE_TYPES.ASSET
                  ? record.valuationInBaseCurrency
                  : record.outstandingValueInBaseCurrency) +
                (record.includeValueInNetWorth === false ? ' *' : '')
            })
            recordRow.getCell(1).alignment = { indent: 2 }
            recordRow.getCell(5).style = {
              ...currencyColumnStyle,
              numFmt: CURRENCY_NUM_FMT
            }

            if (record.includeValueInNetWorth === false) {
              hasExcludedRecord = true
              recordRow.font = { name: 'Arial', size: 8, italic: true }
              recordRow.getCell(1).font = {
                name: 'Arial',
                size: 8,
                italic: true,
                bold: true
              }
            }
          })
        }
      })
    }

    const totalRow = worksheet.addRow({
      A: bsd.type === CORE_TYPES.ASSET ? 'Total Assets' : 'Total Liabilities',
      E: bsd.total
    })
    totalRow.getCell(5).style = {
      numFmt: CURRENCY_WITH_SYMBOL_NUM_FMT,
      ...totalCellStyle
    }
    if (isDualCurrency) totalRow.getCell(4).style = totalCellStyle

    worksheet.addRow([''])
  })

  const nwRow = worksheet.addRow({
    A: 'Net Worth',
    E: balanceSheetData[0].total - balanceSheetData[1].total
  })

  if (hasExcludedRecord) {
    worksheet.addRow([''])
    worksheet
      .addRow({ A: '* Excluded from the total Net Worth' })
      .getCell(1).font = {
      italic: true,
      size: 9
    }
  }

  nwRow.getCell(5).style = {
    numFmt: CURRENCY_WITH_SYMBOL_NUM_FMT,
    ...totalCellStyle
  }
  if (isDualCurrency) nwRow.getCell(4).style = totalCellStyle

  for (let i = 6; i <= worksheet.rowCount; i++) {
    worksheet.mergeCells(`A${i}:${isDualCurrency ? 'C' : 'D'}${i}`)
  }
}

const addDataToPage = (
  doc,
  balanceSheetData,
  registryDate,
  baseCurrency,
  fullName,
  pageHeight,
  pageWidth,
  isDualCurrency = false
) => {
  const lineHeight = 4.5
  const margin = 20
  const initialYPosition = margin
  const xPosition = margin
  const footerHeight = 25
  const currencyColumnWidth = 35
  const indent = 2.25
  let cursorY = initialYPosition

  // set cursorY to new line or new page
  const setCursorY = () => {
    if (cursorY > pageHeight - footerHeight) {
      doc.addPage()
      cursorY = initialYPosition
    } else {
      cursorY += lineHeight
    }
  }

  const addTotalLine = () => {
    doc.line(
      pageWidth - margin - currencyColumnWidth * (isDualCurrency ? 2 : 1),
      cursorY + lineHeight / 3,
      pageWidth - margin,
      cursorY + lineHeight / 3
    )
  }

  const addTotalValue = (label, value) => {
    doc.text(label, xPosition, cursorY, 'left')
    doc.text(
      '$',
      pageWidth - margin - currencyColumnWidth + indent,
      cursorY,
      'left'
    )
    doc.text(`${currencyFormat(value)}`, pageWidth - margin, cursorY, 'right')
  }

  doc.setFont('helvetica')
  doc.setFontSize(12)
  doc.setFontStyle('bold')
  doc.text(fullName, pageWidth / 2, cursorY, 'center')
  doc.text('Balance Sheet', pageWidth / 2, (cursorY += 5.5), 'center')
  doc.setFontSize(9)
  doc.text(
    `As of ${registryDate.format(DATE_FORMAT)}`,
    pageWidth / 2,
    (cursorY += lineHeight),
    'center'
  )
  doc.setFontStyle('italic')
  doc.text(
    baseCurrency,
    pageWidth - margin - currencyColumnWidth / 2,
    (cursorY += lineHeight),
    'center'
  )
  doc.setFontStyle('bold')
  doc.setFontSize(7)
  doc.text(
    'Total',
    pageWidth - margin - currencyColumnWidth / 2,
    (cursorY += lineHeight),
    'center'
  )
  addTotalLine()

  // main content: listing assets & liabilities
  doc.setFontSize(7)

  let hasExcludedRecord = false

  balanceSheetData.forEach(bsd => {
    setCursorY()
    doc.setFont('helvetica')
    doc.setFontStyle('bold')
    doc.text(
      bsd.type === CORE_TYPES.ASSET ? 'Assets' : 'Liabilities',
      xPosition,
      cursorY,
      'left'
    )

    if (bsd.data?.length) {
      bsd.data.forEach(d => {
        setCursorY()
        doc.setFont('helvetica')
        doc.setFontStyle('bold')
        doc.text(d.subType, xPosition + indent, cursorY, 'left')

        if (d.children?.length) {
          d.children.forEach(record => {
            setCursorY()
            doc.setFont('helvetica')
            record.includeValueInNetWorth === false
              ? doc.setFontType('italic')
              : doc.setFontType('bold')
            doc.text(record.title, xPosition + indent * 2, cursorY, 'left')

            doc.setFontStyle('normal')
            doc.setFont('helvetica')

            if (isDualCurrency) {
              if (record.includeValueInNetWorth === false) {
                doc.setFontType('italic')
              }
              doc.text(
                bsd.type === CORE_TYPES.ASSET
                  ? `${record.currency} ${currencyFormat(
                      record.valuationInAssetCurrency
                    )}`
                  : `${record.currency} ${currencyFormat(
                      record.outstandingValueInLiabilityCurrency
                    )}`,
                pageWidth - margin - currencyColumnWidth,
                cursorY,
                'right'
              )
            }
            if (record.includeValueInNetWorth === false) {
              doc.setFontType('italic')
              hasExcludedRecord = true
            }
            doc.text(
              `${
                bsd.type === CORE_TYPES.ASSET
                  ? currencyFormat(record.valuationInBaseCurrency)
                  : currencyFormat(record.outstandingValueInBaseCurrency)
              }${record.includeValueInNetWorth === false ? ' *' : ''}`,
              pageWidth - margin,
              cursorY,
              'right'
            )
          })
        }
      })
    }
    doc.setFont('helvetica')
    doc.setFontStyle('bold')
    addTotalLine()
    setCursorY()
    addTotalValue(
      bsd.type === CORE_TYPES.ASSET ? 'Total Assets' : 'Total Liabilities',
      bsd.total
    )
    setCursorY()
  })

  addTotalLine()
  setCursorY()
  addTotalValue(
    'Net Worth',
    balanceSheetData[0].total - balanceSheetData[1].total
  )

  if (hasExcludedRecord) {
    setCursorY()
    doc.setFontStyle('italic')
    doc.text('* Excluded from the total Net Worth', xPosition, cursorY, 'left')
  }
}

const BalanceSheet = props => {
  const { fullName } = useContext(VaultContext)
  const theme = useContext(ThemeContext)
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'))
  const { t } = useTranslation()
  const {
    sourceAssets,
    sourceLiabilities,
    sourceAssetsLiabilities,
    registryDate,
    renderTotalValue,
    getTotalValueInBaseCurrency
  } = props
  const { baseCurrency } = useSelector(state => state.settings)

  const assetsGroupedBySubtype = groupBySubtype(sourceAssets, ASSET_TYPES)
  const liabilitiesGroupedBySubtype = groupBySubtype(
    sourceLiabilities,
    LIABILITY_TYPES
  )

  const balanceSheetData = [
    {
      type: CORE_TYPES.ASSET,
      data: assetsGroupedBySubtype,
      total: getTotalValueInBaseCurrency(sourceAssets) || 0
    },
    {
      type: CORE_TYPES.LIABILITY,
      data: liabilitiesGroupedBySubtype,
      total: -(getTotalValueInBaseCurrency(sourceLiabilities) || 0)
    }
  ]

  const exportToPDF = () => {
    const doc = new jsPDF()
    const pageHeight =
      doc.internal.pageSize.height || doc.internal.pageSize.getHeight()
    const pageWidth =
      doc.internal.pageSize.width || doc.internal.pageSize.getWidth()
    const exportDate = new Date()

    addDataToPage(
      doc,
      balanceSheetData,
      registryDate,
      baseCurrency,
      fullName,
      pageHeight,
      pageWidth
    )

    doc.addPage()

    addDataToPage(
      doc,
      balanceSheetData,
      registryDate,
      baseCurrency,
      fullName,
      pageHeight,
      pageWidth,
      true
    )

    const totalPages = doc.internal.getNumberOfPages()
    for (let page = 1; page <= totalPages; page++) {
      doc.setPage(page)
      doc.addImage(logo, 'png', 20, pageHeight - 15)
      doc.setTextColor(100)
      doc.setFontSize(8)
      doc.setFontType('italic')
      doc.text(exportDate.toString(), pageWidth - 20, pageHeight - 10, 'right')
    }

    doc.save(
      `Vaultbox Balance Sheet - ${moment(exportDate).format(
        EXPORT_DATE_TIME_FORMAT
      )}.pdf`
    )
  }

  const exportData = () => {
    const workbook = new Workbook()
    const imageId = workbook.addImage({
      base64: logo,
      extension: 'png'
    })

    const baseCurrencyWs = workbook.addWorksheet('Base Currency Balance Sheet')
    const dualCurrencyWs = workbook.addWorksheet('Dual Currency Balance Sheet')

    const exportDate = new Date()
    addDataToWs(
      balanceSheetData,
      registryDate,
      baseCurrency,
      baseCurrencyWs,
      imageId,
      exportDate,
      fullName
    )
    addDataToWs(
      balanceSheetData,
      registryDate,
      baseCurrency,
      dualCurrencyWs,
      imageId,
      exportDate,
      fullName,
      true
    )

    workbook.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data])
      saveAs(
        blob,
        `Vaultbox Balance Sheet - ${moment(exportDate).format(
          EXPORT_DATE_TIME_FORMAT
        )}.xlsx`
      )
    })
  }

  return (
    <>
      <div style={{ textAlign: 'right' }}>
        <Dropdown
          trigger={['hover', 'click']}
          overlay={
            <Menu>
              <Menu.Item onClick={() => exportData()}>
                {t('TO_EXCEL')}
              </Menu.Item>
              <Menu.Item onClick={() => exportToPDF()}>{t('TO_PDF')}</Menu.Item>
            </Menu>
          }
        >
          <Button size={isSmUp ? 'default' : 'small'}>
            {t('EXPORT')} <Icon type="down" />
          </Button>
        </Dropdown>
      </div>
      <h3>{t('ASSETS')}</h3>
      {sourceAssets.length ? (
        <>
          <CustomTable
            size={isSmUp ? '' : 'small'}
            scroll={{ x: true }}
            rowKey="_id"
            defaultExpandAllRows
            columns={balanceSheetColumns(baseCurrency)}
            dataSource={assetsGroupedBySubtype}
            pagination={false}
            onRow={record => ({
              style:
                record.includeValueInNetWorth === false
                  ? { fontStyle: 'italic' }
                  : {}
            })}
          />
          {renderTotalValue(sourceAssets, t('TOTAL_ASSETS'))}
        </>
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={t('NO_ASSETS')}
        />
      )}
      <Divider />
      <h3>{t('LIABILITIES')}</h3>
      {sourceLiabilities.length ? (
        <>
          <CustomTable
            size={isSmUp ? '' : 'small'}
            scroll={{ x: true }}
            rowKey="_id"
            defaultExpandAllRows
            columns={balanceSheetColumns(baseCurrency)}
            dataSource={liabilitiesGroupedBySubtype}
            pagination={false}
            onRow={record => ({
              style:
                record.includeValueInNetWorth === false
                  ? { fontStyle: 'italic' }
                  : {}
            })}
          />
          {renderTotalValue(sourceLiabilities, t('TOTAL_LIABILITIES'))}
        </>
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={t('NO_LIABILITIES')}
        />
      )}
      <Divider />
      {renderTotalValue(sourceAssetsLiabilities, t('NET_WORTH'))}
      {sourceAssetsLiabilities.some(
        al => al.includeValueInNetWorth === false
      ) && (
        <div style={{ fontStyle: 'italic' }}>
          * {t('EXCLUDED_FROM_THE_TOTAL_NET_WORTH')}
        </div>
      )}
    </>
  )
}

export default BalanceSheet
