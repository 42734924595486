import React, { useState, useEffect } from 'react'
import { Form, Checkbox, Icon, Tooltip } from 'antd'
import FormItem from '../override/FormItem'
import { P } from '../override/Typography'
import SelectFilesFoldersModal from './SelectFilesFoldersModal'
import FilesFoldersList from './FilesFoldersList'
import { LEGACY_ACCESSED_BY } from '../../share/Constants'
import UploadOrSelectFile from '../file/UploadOrSelectFile'
import Button from '../override/Button'
import { useTranslation, Trans } from 'react-i18next'

const LegacyPermissions = props => {
  const {
    form,
    selectedFilesFolders,
    setSelectedFilesFolders,
    instructionData,
    setInstructionData,
    prevStep,
    accessedBy,
    nextStep,
    setErrMsg,
    setPermissions,
    permissions,
    setInstructionFileName
  } = props
  const { getFieldDecorator, getFieldValue, setFieldsValue } = form
  const [documentsModalVisible, setDocumentsModalVisible] = useState(false)
  const [hasFullAccess, setHasFullAccess] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    setHasFullAccess(
      accessedBy === LEGACY_ACCESSED_BY.PROFESSIONAL_DEPUTY ||
        permissions?.fullAccess
    )
  }, [permissions, accessedBy])

  const handleFullAccessChange = e => {
    setHasFullAccess(e.target.checked)
    setFieldsValue({
      'permissions.registrySnapshot': false,
      'permissions.selectedFilesFolders': false
    })
  }

  const handleLegacyInfoNext = () => {
    form.validateFieldsAndScroll((err, values) => {
      if (err) return

      if (
        !values.permissions.selectedFilesFolders &&
        !values.permissions.registrySnapshot &&
        !values.permissions.fullAccess
      ) {
        setErrMsg(t('PLEASE_SELECT_AT_LEAST_ONE_OPTION'))
        return
      }

      if (
        values.permissions.selectedFilesFolders &&
        !selectedFilesFolders.length
      ) {
        setErrMsg(t('PLEASE_SELECT_AT_LEAST_ONE_FILE_FOLDER'))
        return
      }

      setInstructionFileName(values.instructionFileName)
      setPermissions(values.permissions)
      nextStep()
    })
  }

  return (
    <>
      <b>{t('CONTENT_PERMISSION')}</b>
      <br />
      {accessedBy === LEGACY_ACCESSED_BY.OTHER_SPECIFIC_PERSON ? (
        <>
          <P>
            <Trans i18nKey="ACCESSED_BY_OTHER_SPECIFIC_PERSON_FIRST_SUMMARY"></Trans>
            .
          </P>
          <P>
            <Trans i18nKey="ACCESSED_BY_OTHER_SPECIFIC_PERSON_SECOND_SUMMARY"></Trans>
          </P>
        </>
      ) : (
        <>
          <P>
            <Trans i18nKey="ACCESSED_BY_PROFESSIONAL_DEPUTY_FIRST_SUMMARY"></Trans>
          </P>
          <P>
            <Trans i18nKey="ACCESSED_BY_PROFESSIONAL_DEPUTY_SECOND_SUMMARY"></Trans>
          </P>
        </>
      )}

      <br />
      <Form>
        <FormItem style={{ marginBottom: 0 }}>
          {getFieldDecorator('permissions.fullAccess', {
            valuePropName: 'checked',
            initialValue: hasFullAccess
          })(
            <Checkbox
              disabled={accessedBy === LEGACY_ACCESSED_BY.PROFESSIONAL_DEPUTY}
              onChange={handleFullAccessChange}
            >
              {t('FULL_VAULTBOX')}
            </Checkbox>
          )}
        </FormItem>
        <P>Or:</P>
        <FormItem style={{ marginBottom: 0 }}>
          {getFieldDecorator('permissions.registrySnapshot', {
            valuePropName: 'checked',
            initialValue: !hasFullAccess && permissions?.registrySnapshot
          })(
            <Checkbox disabled={hasFullAccess}>
              {t('FINAL_SNAPSHOT_OF_YOUR_REGISTRY')}
            </Checkbox>
          )}
        </FormItem>
        <FormItem style={{ marginBottom: 0 }}>
          {getFieldDecorator('permissions.selectedFilesFolders', {
            valuePropName: 'checked',
            initialValue: !hasFullAccess && permissions?.selectedFilesFolders
          })(
            <Checkbox disabled={hasFullAccess}>
              {t('CHOOSE_FILES_OR_FOLDERS_TO_SHARE')}
            </Checkbox>
          )}
          {getFieldValue('permissions.selectedFilesFolders') && (
            <Tooltip title={t('ADD_FILE_FOLDERS')}>
              <Button
                size="small"
                onClick={() => setDocumentsModalVisible(true)}
                type="primary"
              >
                <Icon type="plus" />
              </Button>
            </Tooltip>
          )}
        </FormItem>
        {getFieldValue('permissions.selectedFilesFolders') &&
          !!selectedFilesFolders?.length && (
            <div style={{ paddingLeft: 25 }}>
              <FilesFoldersList
                selectedFilesFolders={selectedFilesFolders}
                setSelectedFilesFolders={setSelectedFilesFolders}
              />
            </div>
          )}
        <br />

        <P>
          <strong>{t('INSTRUCTIONS')}</strong>
        </P>
        <P>{t('INSTRUCTIONS_SUMMARY')}</P>
        <br />
        <UploadOrSelectFile
          label={t('INSTRUCTION_FILE')}
          fieldName="instructionFileName"
          fileData={instructionData}
          setFileData={setInstructionData}
          getFieldDecorator={getFieldDecorator}
          setFieldsValue={setFieldsValue}
          required={accessedBy === LEGACY_ACCESSED_BY.PROFESSIONAL_DEPUTY}
          labelForErrorMsg={t('INSTRUCTION_FILE')}
          isShowCreateTextFile={true}
        />
      </Form>
      <SelectFilesFoldersModal
        visible={documentsModalVisible}
        setVisible={setDocumentsModalVisible}
        setSelectedFilesFolders={setSelectedFilesFolders}
        selectedFilesFolders={selectedFilesFolders}
      />

      <div className="steps-actions">
        <Button size="large" onClick={prevStep}>
          {t('BACK')}
        </Button>
        <div style={{ float: 'right' }}>
          <Button size="large" type="primary" onClick={handleLegacyInfoNext}>
            {t('NEXT')}
          </Button>
        </div>
      </div>
    </>
  )
}

const WrapLegacyPermissions = Form.create({
  name: 'LegacyPermissions'
})(LegacyPermissions)
export default WrapLegacyPermissions
