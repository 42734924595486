import { createSlice } from '@reduxjs/toolkit'
import { getRecords } from '../../lib/pouchDb'
import { onError } from '../../lib/sentry'

const initialState = {
  assetsLiabilities: [],
  deletedAssetsLiabilities: [],
  activeAssetsLiabilities: [],
  draftAssetsLiabilities: [],
  valuations: [],
  pendingAssetsLiabilities: [],
  rejectedAssetsLiabilities: [],
  assetsLiabilitiesTags: [],
  pendingAssetsLiabilitiesTags: [],
  pendingLoading: false,
  pendingError: null,
  isLoading: false,
  error: null
}

const otherAssetsLiabilities = createSlice({
  name: 'otherAssetsLiabilities',
  initialState,
  reducers: {
    getOtherAssetsLiabilitiesStart(state) {
      state.isLoading = true
      state.error = null
    },
    getOtherAssetsLiabilitiesSuccess(state, action) {
      const { assetsLiabilities } = action.payload
      state.assetsLiabilities = assetsLiabilities
      state.deletedAssetsLiabilities = assetsLiabilities.filter(
        al => al.deleted
      )
      state.activeAssetsLiabilities = assetsLiabilities.filter(
        al => !al.deleted
      )
      state.assetsLiabilitiesTags = assetsLiabilities
        .filter(al => !al.deleted && !!al.tags?.length)
        .map(al => al.tags)
      // state.draftAssetsLiabilities = assetsLiabilities.filter(
      //   al => !al.deleted && al.status === 'Draft'
      // )
      state.isLoading = false
      state.error = null
    },
    getOtherAssetsLiabilitiesFailure(state, action) {
      state.isLoading = false
      state.error = action.payload
    },
    getOtherPendingAssetsLiabilitiesStart(state) {
      state.pendingLoading = true
      state.pendingError = null
    },
    getOtherPendingAssetsLiabilitiesSuccess(state, action) {
      const { assetsLiabilities } = action.payload
      state.pendingAssetsLiabilities = assetsLiabilities.filter(
        al => al.status === 'Draft'
      )
      state.rejectedAssetsLiabilities = assetsLiabilities.filter(
        al => al.status === 'Rejected'
      )
      state.pendingAssetsLiabilitiesTags = assetsLiabilities
        .filter(al => al.status === 'Draft' && !!al.tags?.length)
        .map(al => al.tags)
      state.pendingLoading = false
      state.pendingError = null
    },
    getOtherPendingAssetsLiabilitiesFailure(state, action) {
      state.pendingLoading = false
      state.pendingError = action.payload
    },
    getOtherValuationsStart(state) {
      state.valuationsLoading = true
      state.valuationsError = null
    },
    getOtherValuationsSuccess(state, action) {
      const { valuations } = action.payload
      state.valuations = valuations
      state.valuationsLoading = false
      state.valuationsError = null
    },
    getOtherValuationsFailure(state, action) {
      state.valuationsLoading = false
      state.valuationsError = action.payload
    }
  }
})

export const {
  getOtherAssetsLiabilitiesStart,
  getOtherAssetsLiabilitiesSuccess,
  getOtherAssetsLiabilitiesFailure,
  getOtherPendingAssetsLiabilitiesStart,
  getOtherPendingAssetsLiabilitiesSuccess,
  getOtherPendingAssetsLiabilitiesFailure,
  getOtherValuationsStart,
  getOtherValuationsSuccess,
  getOtherValuationsFailure
} = otherAssetsLiabilities.actions

export default otherAssetsLiabilities.reducer

export const fetchOtherAssetsLiabilities = (
  userId,
  masterKey
) => async dispatch => {
  try {
    dispatch(getOtherAssetsLiabilitiesStart())
    const assetsLiabilities = await getRecords(
      userId,
      'assetsLiabilities',
      masterKey
    )
    dispatch(getOtherAssetsLiabilitiesSuccess({ assetsLiabilities }))
  } catch (err) {
    onError(err)
    dispatch(getOtherAssetsLiabilitiesFailure(err.toString()))
  }
}

export const fetchOtherPendingAssetsLiabilities = (
  userId,
  masterKey
) => async dispatch => {
  try {
    dispatch(getOtherPendingAssetsLiabilitiesStart())
    const assetsLiabilities = await getRecords(
      userId,
      'pendingAssetsLiabilities',
      masterKey
    )

    dispatch(getOtherPendingAssetsLiabilitiesSuccess({ assetsLiabilities }))
  } catch (err) {
    onError(err)
    dispatch(getOtherPendingAssetsLiabilitiesFailure(err.toString()))
  }
}

export const fetchOtherValuations = (userId, masterKey) => async dispatch => {
  try {
    dispatch(getOtherValuationsStart())
    const valuations = await getRecords(
      userId,
      'assetsLiabilitiesValuations',
      masterKey
    )
    dispatch(getOtherValuationsSuccess({ valuations }))
  } catch (err) {
    onError(err)
    dispatch(getOtherValuationsFailure(err.toString()))
  }
}
