import React, { useState, useEffect, useContext } from 'react'
import { Modal, Form, Select, Tooltip } from 'antd'
import FormItem from '../override/FormItem'
import { useSelector } from 'react-redux'
import ContactModal from '../contacts/ContactModal'
import Button from '../override/Button'
import TourContext, { TOUR_STEP_INDEXES } from '../../contexts/TourContext'
import { onError } from '../../lib/sentry'
import { useTranslation } from 'react-i18next'
import AuthContext from '../../contexts/AuthContext'
import VaultContext from '../../contexts/VaultContext'
import { ACCESS_LEVEL } from './../../share/Constants'

const ContactLinksModal = props => {
  const { isReadonly } = useContext(VaultContext)
  const { isProfessionalDeputy, isDelegateByPD } = useContext(AuthContext)
  const { Option } = Select
  const {
    visible,
    setVisible,
    handleLinkContacts,
    linkedContacts,
    isDisableAdd,
    record,
    form
  } = props
  const { getFieldDecorator, setFieldsValue, getFieldValue } = form
  const { activeContacts, pendingContacts } = useSelector(state =>
    isProfessionalDeputy || (isDelegateByPD && isReadonly)
      ? state.otherContacts
      : state.contacts
  )
  const { accessLevel } = useSelector(state => state.settings)
  const [isSaving, setIsSaving] = useState(false)
  const [contactModalVisibile, setContactModalVisibile] = useState(false)
  const { tourRun, nextTourStep, tourStepIndex } = useContext(TourContext)
  const { t } = useTranslation()

  useEffect(() => {
    if (
      visible &&
      tourRun &&
      tourStepIndex === TOUR_STEP_INDEXES.LINK_EXISTED_CONTACTS
    ) {
      setTimeout(() => {
        nextTourStep()
      }, 300)
    }
  }, [tourStepIndex, nextTourStep, tourRun, visible])

  const handleOk = () => {
    form.validateFields(async (err, values) => {
      if (err) {
        return
      }

      setIsSaving(true)
      try {
        if (values.links && values.links.length) {
          await handleLinkContacts(values.links)
        }

        setIsSaving(false)
        form.resetFields()
        setVisible(false)
        nextTourStep()
      } catch (e) {
        setIsSaving(false)
        onError(e)
      }
    })
  }

  const handleCancel = () => {
    form.resetFields()
    setVisible(false)
  }

  const data =
    accessLevel === ACCESS_LEVEL.NEED_APPROVAL
      ? record
        ? [
            ...activeContacts.filter(item => item._id !== record._id),
            ...pendingContacts.filter(item => item._id !== record._id)
          ]
        : [...activeContacts, ...pendingContacts]
      : record
      ? activeContacts.filter(item => item._id !== record._id)
      : activeContacts

  const sortedData = [...data].sort((a, b) => a.name.localeCompare(b.name))

  return (
    <Modal
      title={t('LINK_CONTACTS')}
      visible={visible}
      onCancel={handleCancel}
      onOk={handleOk}
      okText="Save"
      okButtonProps={{ loading: isSaving }}
      maskClosable={false}
      className="tour-contacts-link-modal"
    >
      <Form>
        <FormItem style={{ flexGrow: 1 }}>
          <div style={{ display: 'flex' }}>
            <div
              style={
                isDisableAdd
                  ? { width: '100%' }
                  : { width: 'calc(100% - 58px)' }
              }
            >
              {getFieldDecorator('links')(
                <Select mode="multiple" placeholder={t('SELECT_CONTACT')}>
                  {sortedData.map(c => (
                    <Option
                      key={c._id}
                      disabled={
                        !!linkedContacts.find(l => l._id === c._id) ||
                        (!isReadonly && !!c.status)
                      }
                    >
                      {c.name}
                    </Option>
                  ))}
                </Select>
              )}
            </div>
            {!isDisableAdd && (
              <Tooltip title={t('ADD_NEW_CONTACT')}>
                <Button
                  size="large"
                  onClick={() => setContactModalVisibile(true)}
                  style={{ marginLeft: 8 }}
                  icon="plus"
                />
              </Tooltip>
            )}
          </div>
        </FormItem>
      </Form>
      <ContactModal
        visible={contactModalVisibile}
        setVisible={setContactModalVisibile}
        onAddComplete={contactId =>
          setFieldsValue({
            links: [...(getFieldValue('links') || []), contactId]
          })
        }
        selectedRecord={{}}
      />
    </Modal>
  )
}

const WrappedContactLinksForm = Form.create({
  name: 'ContactLinksModal'
})(ContactLinksModal)

export default WrappedContactLinksForm
