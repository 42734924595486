import * as Sentry from '@sentry/react'
import config from '../config'

const isLocal = window.location.hostname === 'localhost'

const dsn =
  config.env === 'dev'
    ? 'https://efde8864d3d843888448c405cca8ff4f@o453946.ingest.sentry.io/5443158'
    : 'https://6b4c3d0147ed4e5d983b56325a9c078e@o455063.ingest.sentry.io/5447359'

export function initSentry() {
  if (isLocal) {
    return
  }

  Sentry.init({
    dsn,
    environment: config.env
  })
}

export function setSentryUser(userInfo) {
  if (isLocal) {
    return
  }

  Sentry.setUser(userInfo)
}

export function logError(error, errorInfo = null) {
  if (isLocal) {
    return
  }

  Sentry.withScope(scope => {
    errorInfo && scope.setExtras(errorInfo)
    Sentry.captureException(error)
  })
}

export function onError(error) {
  if (isLocal) {
    console.log(error)
    return
  }

  let errorInfo = {}

  if (!(error instanceof Error) && error.message) {
    errorInfo = error
    error = new Error(error.message)
  } else if (error.config && error.config.url) {
    errorInfo.url = error.config.url
  }

  logError(error, errorInfo)
}
