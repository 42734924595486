import React from 'react'
import styled from 'styled-components'

function SimpleListItem({ className, children, isSelected, handleClick }) {
  return (
    <div
      className={`${className} item ${isSelected ? 'selected' : ''}`}
      onClick={handleClick}
    >
      {children}
    </div>
  )
}

const StyledSimpleListItem = styled(SimpleListItem)`
  display: flex;
  align-items: center;
  padding: 10px;
  margin: 0 -10px;
  border: 1px solid transparent;
  cursor: pointer;
  &:hover,
  &.selected {
    background: ${props => props.theme.white};
    border-color: ${props => props.theme.dark3};
    box-shadow: ${props => props.theme.boxShadow};
    border-radius: 5px;
  }
`

export default StyledSimpleListItem
