import React, { useState, useContext } from 'react'
import { Select, Tooltip } from 'antd'
import Button from '../override/Button'
import FormItem from '../override/FormItem'
import AddEventModal from '../calendar/AddEventModal'
import { useTranslation } from 'react-i18next'
import { DATE_FORMAT, ACCESS_LEVEL } from '../../share/Constants'
import moment from 'moment'
import VaultContext from '../../contexts/VaultContext'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchEvents,
  fetchPendingEvents
} from '../../features/events/eventsSlice'

export default function EventSelect({
  label,
  placeholder,
  required,
  getFieldDecorator,
  fieldName,
  initialValue,
  mode,
  onAddComplete,
  linkedEvents,
  setLinkedEvents
}) {
  const { userId, masterKey, isReadonly } = useContext(VaultContext)
  const { eventsFromPouchDB: events, pendingEventsFromPouchDB: pendingEvents } =
    useSelector(state => (isReadonly ? state.otherEvents : state.events))
  const { accessLevel } = useSelector(state => state.settings)
  const [eventModalVisibile, setEventModalVisibile] = useState(false)
  const { t } = useTranslation()
  const dispatch = useDispatch()



  const data =
    accessLevel === ACCESS_LEVEL.NEED_APPROVAL
      ? [...events, ...pendingEvents]
      : events
  const sortedData = [...data].sort(
    (a, b) => new Date(a.date) - new Date(b.date)
  )

  return (
    <div>
      <FormItem label={label}>
        <div style={{ display: 'flex' }}>
          <div style={{ width: 'calc(100% - 58px)' }}>
            {getFieldDecorator(fieldName, {
              initialValue,
              rules: [
                {
                  required: required,
                  message: label && `${t('ENTER')} ${label.toLowerCase()}`
                }
              ]
            })(
              <Select
                mode={mode}
                placeholder={
                  placeholder || `${t('SELECT')} ${label.toLowerCase()}`
                }
                allowClear={!mode}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toString()
                    .toLowerCase()
                    .includes(input.trim().toLowerCase())
                }
              >
                {sortedData.map(item => (
                  <Select.Option
                    key={item._id}
                    value={item._id}
                    disabled={!isReadonly && !!item.status}
                  >
                    {moment(item.date).format(DATE_FORMAT)} - {item.description}
                  </Select.Option>
                ))}
              </Select>
            )}
          </div>
          <Tooltip title={t('ADD_NEW')}>
            <Button
              size="large"
              onClick={() => setEventModalVisibile(true)}
              className={`${label ? '' : 'no-label'}`}
              style={{ marginLeft: 8 }}
              icon="plus"
            />
          </Tooltip>
        </div>
      </FormItem>
      <AddEventModal
        setLinkedEvents={setLinkedEvents}
        linkedEvents={linkedEvents}
        visible={eventModalVisibile}
        setVisible={setEventModalVisibile}
        handleClose={() => {
          dispatch(fetchEvents(userId, masterKey))
          dispatch(fetchPendingEvents(userId, masterKey))
          setEventModalVisibile(false)
        }}
        onAddComplete={onAddComplete}
        eventDetails={{}}
      />
    </div>
  )
}
