import React, { useState, useContext } from 'react'
import { Input, message, Icon, Tooltip, Modal } from 'antd'
import Button from '../override/Button'
import FormItem from '../override/FormItem'
import FileModal from './FileModal'
import DocumentsLinksModal from '../assets-liabilities/DocumentsLinksModal'
import { useSelector } from 'react-redux'
import AuthContext from '../../contexts/AuthContext'
import VaultContext from '../../contexts/VaultContext'
import PouchDB from 'pouchdb'
import { s3Get } from '../../lib/awsSDK'
import api from '../../lib/api'
import { decryptFile } from '../../lib/crypto'
import { ThemeContext } from 'styled-components'
import { onError } from '../../lib/sentry'
import { useTranslation, Trans } from 'react-i18next'
import TextFileEditor from '../../components/file/TextFileEditor'

export default function UploadOrSelectFile({
  label,
  fieldName,
  fileData,
  setFileData,
  getFieldDecorator,
  setFieldsValue,
  required,
  labelForErrorMsg,
  isShowCreateTextFile
}) {
  const [fileModalVisible, setFileModalVisible] = useState(false)
  const [createTextFileVisible, setCreateTextFileVisible] = useState(false)
  const [documentsLinksVisible, setDocumentsLinksVisible] = useState(false)
  const { activeContacts } = useSelector(state => state.contacts)
  const { activeDocuments } = useSelector(state => state.documents)
  const theme = useContext(ThemeContext)
  const { t } = useTranslation()
  const { user } = useContext(AuthContext)
  const { masterKey } = useContext(VaultContext)
  const privateFolder = activeDocuments.find(record => record.isPrivate)

  // TODO: should allow upload or select only a single file here
  const handleUploadOrSelectComplete = async docId => {
    try {
      const userId = user.username

      const db = new PouchDB(`${userId}_documents`)
      db.crypto(masterKey)
      const doc = await db.get(docId)
      if (
        !!privateFolder &&
        doc.path?.slice(0, privateFolder?.path.lenght) === privateFolder?.path
      ) {
        Modal.warning({
          title: t('WARNING_MSG'),
          content: t('CANNOT_CHOOSE_FILE_FROM_PRIVATE_FOLDER')
        })
      } else {
        const { fileName, fileId, sub } = doc
        db.removeCrypto()

        const statusRes = await api.getFileStatus(userId, fileId)
        if (statusRes.data && statusRes.data.isLocked) {
          message.error(t('THE_SELECTED_FILE_IS_LOCK'))
          return
        }

        const resBody = await s3Get(
          userId,
          fileId,
          { sub },
          { responseType: 'blob' }
        )

        decryptFile(resBody, masterKey, uint8Array => {
          const blob = new Blob([uint8Array])

          setFieldsValue({
            [fieldName]: fileName
          })
          setFileData({
            id: fileId,
            name: fileName,
            content: blob
          })
        })
      }
    } catch (err) {
      onError(err)
    }
  }

  const handleRemoveFile = () => {
    setFileData({})
    setFieldsValue({
      [fieldName]: undefined
    })
  }

  return (
    <div>
      <FormItem
        label={
          <span>
            {label}:{' '}
            <strong style={{ paddingLeft: 5 }}>{fileData?.name}</strong>{' '}
            {fileData?.name && (
              <Tooltip title={t('REMOVE_FILE')}>
                <Icon
                  style={{ paddingLeft: 5, color: theme.dark2 }}
                  onClick={handleRemoveFile}
                  type="close-circle"
                />
              </Tooltip>
            )}
          </span>
        }
        colon={false}
      >
        {getFieldDecorator(fieldName, {
          initialValue: fileData?.name,
          rules: [
            {
              required,
              message: (
                <Trans
                  i18nKey="UPLOAD_OR_SELECT_FROM_UPLOADED_FILES"
                  values={{ labelForErrorMsg }}
                ></Trans>
              )
            }
          ]
        })(<Input type="text" readOnly hidden />)}
        <Button
          size="large"
          icon="upload"
          onClick={() => setFileModalVisible(true)}
          style={{ marginTop: '0.5em' }}
        >
          {t('UPLOAD')}
        </Button>
        <span style={{ margin: '0 1em' }}>{t('OR')}</span>
        <Button
          size="large"
          onClick={() => setDocumentsLinksVisible(true)}
          style={{ marginTop: '0.5em' }}
        >
          {t('SELECT_FROM_UPLOADED_FILES')}
        </Button>
        {isShowCreateTextFile && (
          <>
            <span style={{ margin: '0 1em' }}>{t('OR')}</span>
            <Button
              size="large"
              onClick={() => setCreateTextFileVisible(true)}
              style={{ marginTop: '0.5em' }}
            >
              {t('CREATE_TEXT_FILE')}
            </Button>
          </>
        )}
      </FormItem>

      <FileModal
        visible={fileModalVisible}
        setVisible={setFileModalVisible}
        contactDataSource={activeContacts}
        onUploadComplete={fileIds => handleUploadOrSelectComplete(fileIds[0])}
      />
      <DocumentsLinksModal
        visible={documentsLinksVisible}
        setVisible={setDocumentsLinksVisible}
        record={{}}
        linkDocument={handleUploadOrSelectComplete}
        filteredDocIds={[]}
      />
      {isShowCreateTextFile && (
        <Modal
          width={700}
          visible={createTextFileVisible}
          title={t('CREATE_TEXT_FILE')}
          onCancel={() => setCreateTextFileVisible(false)}
          footer={null}
          maskClosable={false}
        >
          <TextFileEditor
            breadcrumb=""
            setEnableCreateFile={() => {}}
            setBreadcrumb={() => {}}
            selectedTextFile={{}}
            setSelectedTextFile={() => {}}
            onCreateComplete={handleUploadOrSelectComplete}
            setCreateTextFileVisible={setCreateTextFileVisible}
          ></TextFileEditor>
        </Modal>
      )}
    </div>
  )
}
